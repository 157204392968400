// Generated by purs bundle 0.12.3
var PS = {};

(function (exports) {
  "use strict";

  exports.arrayMap = function (f) {
    return function (arr) {
      var l = arr.length;
      var result = new Array(l);

      for (var i = 0; i < l; i++) {
        result[i] = f(arr[i]);
      }

      return result;
    };
  };
})(PS["Data.Functor"] = PS["Data.Functor"] || {});

(function (exports) {
  // Generated by purs version 0.12.3
  "use strict";

  var Semigroupoid = function Semigroupoid(compose) {
    this.compose = compose;
  };

  var semigroupoidFn = new Semigroupoid(function (f) {
    return function (g) {
      return function (x) {
        return f(g(x));
      };
    };
  });

  var compose = function compose(dict) {
    return dict.compose;
  };

  var composeFlipped = function composeFlipped(dictSemigroupoid) {
    return function (f) {
      return function (g) {
        return compose(dictSemigroupoid)(g)(f);
      };
    };
  };

  exports["compose"] = compose;
  exports["Semigroupoid"] = Semigroupoid;
  exports["composeFlipped"] = composeFlipped;
  exports["semigroupoidFn"] = semigroupoidFn;
})(PS["Control.Semigroupoid"] = PS["Control.Semigroupoid"] || {});

(function (exports) {
  // Generated by purs version 0.12.3
  "use strict";

  var Control_Semigroupoid = PS["Control.Semigroupoid"];

  var Category = function Category(Semigroupoid0, identity) {
    this.Semigroupoid0 = Semigroupoid0;
    this.identity = identity;
  };

  var identity = function identity(dict) {
    return dict.identity;
  };

  var categoryFn = new Category(function () {
    return Control_Semigroupoid.semigroupoidFn;
  }, function (x) {
    return x;
  });
  exports["Category"] = Category;
  exports["identity"] = identity;
  exports["categoryFn"] = categoryFn;
})(PS["Control.Category"] = PS["Control.Category"] || {});

(function (exports) {
  // Generated by purs version 0.12.3
  "use strict";

  var otherwise = true;
  exports["otherwise"] = otherwise;
})(PS["Data.Boolean"] = PS["Data.Boolean"] || {});

(function (exports) {
  "use strict";

  exports.refEq = function (r1) {
    return function (r2) {
      return r1 === r2;
    };
  };
})(PS["Data.Eq"] = PS["Data.Eq"] || {});

(function (exports) {
  "use strict";

  exports.boolConj = function (b1) {
    return function (b2) {
      return b1 && b2;
    };
  };

  exports.boolDisj = function (b1) {
    return function (b2) {
      return b1 || b2;
    };
  };

  exports.boolNot = function (b) {
    return !b;
  };
})(PS["Data.HeytingAlgebra"] = PS["Data.HeytingAlgebra"] || {});

(function (exports) {
  // Generated by purs version 0.12.3
  "use strict";

  var $foreign = PS["Data.Symbol"];

  var SProxy = function () {
    function SProxy() {}

    ;
    SProxy.value = new SProxy();
    return SProxy;
  }();

  var IsSymbol = function IsSymbol(reflectSymbol) {
    this.reflectSymbol = reflectSymbol;
  };

  var reflectSymbol = function reflectSymbol(dict) {
    return dict.reflectSymbol;
  };

  exports["IsSymbol"] = IsSymbol;
  exports["reflectSymbol"] = reflectSymbol;
  exports["SProxy"] = SProxy;
})(PS["Data.Symbol"] = PS["Data.Symbol"] || {});

(function (exports) {
  "use strict";

  exports.unit = {};
})(PS["Data.Unit"] = PS["Data.Unit"] || {});

(function (exports) {
  "use strict";

  exports.showIntImpl = function (n) {
    return n.toString();
  };

  exports.showNumberImpl = function (n) {
    var str = n.toString();
    return isNaN(str + ".0") ? str : str + ".0";
  };

  exports.showCharImpl = function (c) {
    var code = c.charCodeAt(0);

    if (code < 0x20 || code === 0x7F) {
      switch (c) {
        case "\x07":
          return "'\\a'";

        case "\b":
          return "'\\b'";

        case "\f":
          return "'\\f'";

        case "\n":
          return "'\\n'";

        case "\r":
          return "'\\r'";

        case "\t":
          return "'\\t'";

        case "\v":
          return "'\\v'";
      }

      return "'\\" + code.toString(10) + "'";
    }

    return c === "'" || c === "\\" ? "'\\" + c + "'" : "'" + c + "'";
  };

  exports.showStringImpl = function (s) {
    var l = s.length;
    return "\"" + s.replace(/[\0-\x1F\x7F"\\]/g, // eslint-disable-line no-control-regex
    function (c, i) {
      switch (c) {
        case "\"":
        case "\\":
          return "\\" + c;

        case "\x07":
          return "\\a";

        case "\b":
          return "\\b";

        case "\f":
          return "\\f";

        case "\n":
          return "\\n";

        case "\r":
          return "\\r";

        case "\t":
          return "\\t";

        case "\v":
          return "\\v";
      }

      var k = i + 1;
      var empty = k < l && s[k] >= "0" && s[k] <= "9" ? "\\&" : "";
      return "\\" + c.charCodeAt(0).toString(10) + empty;
    }) + "\"";
  };

  exports.showArrayImpl = function (f) {
    return function (xs) {
      var ss = [];

      for (var i = 0, l = xs.length; i < l; i++) {
        ss[i] = f(xs[i]);
      }

      return "[" + ss.join(",") + "]";
    };
  };

  exports.cons = function (head) {
    return function (tail) {
      return [head].concat(tail);
    };
  };

  exports.join = function (separator) {
    return function (xs) {
      return xs.join(separator);
    };
  };
})(PS["Data.Show"] = PS["Data.Show"] || {});

(function (exports) {
  "use strict";

  exports.unsafeGet = function (label) {
    return function (rec) {
      return rec[label];
    };
  };

  exports.unsafeSet = function (label) {
    return function (value) {
      return function (rec) {
        var copy = {};

        for (var key in rec) {
          if ({}.hasOwnProperty.call(rec, key)) {
            copy[key] = rec[key];
          }
        }

        copy[label] = value;
        return copy;
      };
    };
  };

  exports.unsafeDelete = function (label) {
    return function (rec) {
      var copy = {};

      for (var key in rec) {
        if (key !== label && {}.hasOwnProperty.call(rec, key)) {
          copy[key] = rec[key];
        }
      }

      return copy;
    };
  };
})(PS["Record.Unsafe"] = PS["Record.Unsafe"] || {});

(function (exports) {
  // Generated by purs version 0.12.3
  "use strict";

  var $foreign = PS["Record.Unsafe"];
  exports["unsafeGet"] = $foreign.unsafeGet;
  exports["unsafeSet"] = $foreign.unsafeSet;
  exports["unsafeDelete"] = $foreign.unsafeDelete;
})(PS["Record.Unsafe"] = PS["Record.Unsafe"] || {});

(function (exports) {
  // Generated by purs version 0.12.3
  "use strict";

  var RLProxy = function () {
    function RLProxy() {}

    ;
    RLProxy.value = new RLProxy();
    return RLProxy;
  }();

  exports["RLProxy"] = RLProxy;
})(PS["Type.Data.RowList"] = PS["Type.Data.RowList"] || {});

(function (exports) {
  // Generated by purs version 0.12.3
  "use strict";

  var $foreign = PS["Data.Show"];
  var Data_Symbol = PS["Data.Symbol"];
  var Record_Unsafe = PS["Record.Unsafe"];
  var Type_Data_RowList = PS["Type.Data.RowList"];

  var Show = function Show(show) {
    this.show = show;
  };

  var ShowRecordFields = function ShowRecordFields(showRecordFields) {
    this.showRecordFields = showRecordFields;
  };

  var showString = new Show($foreign.showStringImpl);
  var showRecordFieldsNil = new ShowRecordFields(function (v) {
    return function (v1) {
      return [];
    };
  });

  var showRecordFields = function showRecordFields(dict) {
    return dict.showRecordFields;
  };

  var showRecord = function showRecord(dictRowToList) {
    return function (dictShowRecordFields) {
      return new Show(function (record) {
        var v = showRecordFields(dictShowRecordFields)(Type_Data_RowList.RLProxy.value)(record);

        if (v.length === 0) {
          return "{}";
        }

        ;
        return $foreign.join(" ")(["{", $foreign.join(", ")(v), "}"]);
      });
    };
  };

  var showNumber = new Show($foreign.showNumberImpl);
  var showInt = new Show($foreign.showIntImpl);
  var showChar = new Show($foreign.showCharImpl);
  var showBoolean = new Show(function (v) {
    if (v) {
      return "true";
    }

    ;

    if (!v) {
      return "false";
    }

    ;
    throw new Error("Failed pattern match at Data.Show (line 20, column 1 - line 20, column 37): " + [v.constructor.name]);
  });

  var show = function show(dict) {
    return dict.show;
  };

  var showArray = function showArray(dictShow) {
    return new Show($foreign.showArrayImpl(show(dictShow)));
  };

  var showRecordFieldsCons = function showRecordFieldsCons(dictIsSymbol) {
    return function (dictShowRecordFields) {
      return function (dictShow) {
        return new ShowRecordFields(function (v) {
          return function (record) {
            var tail = showRecordFields(dictShowRecordFields)(Type_Data_RowList.RLProxy.value)(record);
            var key = Data_Symbol.reflectSymbol(dictIsSymbol)(Data_Symbol.SProxy.value);
            var focus = Record_Unsafe.unsafeGet(key)(record);
            return $foreign.cons($foreign.join(": ")([key, show(dictShow)(focus)]))(tail);
          };
        });
      };
    };
  };

  exports["Show"] = Show;
  exports["show"] = show;
  exports["ShowRecordFields"] = ShowRecordFields;
  exports["showRecordFields"] = showRecordFields;
  exports["showBoolean"] = showBoolean;
  exports["showInt"] = showInt;
  exports["showNumber"] = showNumber;
  exports["showChar"] = showChar;
  exports["showString"] = showString;
  exports["showArray"] = showArray;
  exports["showRecord"] = showRecord;
  exports["showRecordFieldsNil"] = showRecordFieldsNil;
  exports["showRecordFieldsCons"] = showRecordFieldsCons;
})(PS["Data.Show"] = PS["Data.Show"] || {});

(function (exports) {
  // Generated by purs version 0.12.3
  "use strict";

  var $foreign = PS["Data.Unit"];
  var Data_Show = PS["Data.Show"];
  var showUnit = new Data_Show.Show(function (v) {
    return "unit";
  });
  exports["showUnit"] = showUnit;
  exports["unit"] = $foreign.unit;
})(PS["Data.Unit"] = PS["Data.Unit"] || {});

(function (exports) {
  // Generated by purs version 0.12.3
  "use strict";

  var RProxy = function () {
    function RProxy() {}

    ;
    RProxy.value = new RProxy();
    return RProxy;
  }();

  exports["RProxy"] = RProxy;
})(PS["Type.Data.Row"] = PS["Type.Data.Row"] || {});

(function (exports) {
  // Generated by purs version 0.12.3
  "use strict";

  var $foreign = PS["Data.HeytingAlgebra"];
  var Data_Symbol = PS["Data.Symbol"];
  var Data_Unit = PS["Data.Unit"];
  var Record_Unsafe = PS["Record.Unsafe"];
  var Type_Data_Row = PS["Type.Data.Row"];
  var Type_Data_RowList = PS["Type.Data.RowList"];

  var HeytingAlgebra = function HeytingAlgebra(conj, disj, ff, implies, not, tt) {
    this.conj = conj;
    this.disj = disj;
    this.ff = ff;
    this.implies = implies;
    this.not = not;
    this.tt = tt;
  };

  var tt = function tt(dict) {
    return dict.tt;
  };

  var not = function not(dict) {
    return dict.not;
  };

  var implies = function implies(dict) {
    return dict.implies;
  };

  var ff = function ff(dict) {
    return dict.ff;
  };

  var disj = function disj(dict) {
    return dict.disj;
  };

  var heytingAlgebraBoolean = new HeytingAlgebra($foreign.boolConj, $foreign.boolDisj, false, function (a) {
    return function (b) {
      return disj(heytingAlgebraBoolean)(not(heytingAlgebraBoolean)(a))(b);
    };
  }, $foreign.boolNot, true);

  var conj = function conj(dict) {
    return dict.conj;
  };

  exports["HeytingAlgebra"] = HeytingAlgebra;
  exports["tt"] = tt;
  exports["ff"] = ff;
  exports["implies"] = implies;
  exports["conj"] = conj;
  exports["disj"] = disj;
  exports["not"] = not;
  exports["heytingAlgebraBoolean"] = heytingAlgebraBoolean;
})(PS["Data.HeytingAlgebra"] = PS["Data.HeytingAlgebra"] || {});

(function (exports) {
  // Generated by purs version 0.12.3
  "use strict";

  var $foreign = PS["Data.Eq"];
  var Data_HeytingAlgebra = PS["Data.HeytingAlgebra"];
  var Data_Symbol = PS["Data.Symbol"];
  var Data_Unit = PS["Data.Unit"];
  var Data_Void = PS["Data.Void"];
  var Record_Unsafe = PS["Record.Unsafe"];
  var Type_Data_RowList = PS["Type.Data.RowList"];

  var Eq = function Eq(eq) {
    this.eq = eq;
  };

  var Eq1 = function Eq1(eq1) {
    this.eq1 = eq1;
  };

  var eqString = new Eq($foreign.refEq);
  var eqInt = new Eq($foreign.refEq);
  var eqChar = new Eq($foreign.refEq);
  var eqBoolean = new Eq($foreign.refEq);

  var eq1 = function eq1(dict) {
    return dict.eq1;
  };

  var eq = function eq(dict) {
    return dict.eq;
  };

  exports["Eq"] = Eq;
  exports["eq"] = eq;
  exports["Eq1"] = Eq1;
  exports["eq1"] = eq1;
  exports["eqBoolean"] = eqBoolean;
  exports["eqInt"] = eqInt;
  exports["eqChar"] = eqChar;
  exports["eqString"] = eqString;
})(PS["Data.Eq"] = PS["Data.Eq"] || {});

(function (exports) {
  "use strict";

  exports.unsafeCompareImpl = function (lt) {
    return function (eq) {
      return function (gt) {
        return function (x) {
          return function (y) {
            return x < y ? lt : x === y ? eq : gt;
          };
        };
      };
    };
  };
})(PS["Data.Ord.Unsafe"] = PS["Data.Ord.Unsafe"] || {});

(function (exports) {
  "use strict";

  exports.concatString = function (s1) {
    return function (s2) {
      return s1 + s2;
    };
  };

  exports.concatArray = function (xs) {
    return function (ys) {
      if (xs.length === 0) return ys;
      if (ys.length === 0) return xs;
      return xs.concat(ys);
    };
  };
})(PS["Data.Semigroup"] = PS["Data.Semigroup"] || {});

(function (exports) {
  // Generated by purs version 0.12.3
  "use strict";

  var $foreign = PS["Data.Semigroup"];
  var Data_Symbol = PS["Data.Symbol"];
  var Data_Unit = PS["Data.Unit"];
  var Data_Void = PS["Data.Void"];
  var Record_Unsafe = PS["Record.Unsafe"];
  var Type_Data_RowList = PS["Type.Data.RowList"];

  var Semigroup = function Semigroup(append) {
    this.append = append;
  };

  var SemigroupRecord = function SemigroupRecord(appendRecord) {
    this.appendRecord = appendRecord;
  };

  var semigroupString = new Semigroup($foreign.concatString);
  var semigroupRecordNil = new SemigroupRecord(function (v) {
    return function (v1) {
      return function (v2) {
        return {};
      };
    };
  });
  var semigroupArray = new Semigroup($foreign.concatArray);

  var appendRecord = function appendRecord(dict) {
    return dict.appendRecord;
  };

  var semigroupRecord = function semigroupRecord(dictRowToList) {
    return function (dictSemigroupRecord) {
      return new Semigroup(appendRecord(dictSemigroupRecord)(Type_Data_RowList.RLProxy.value));
    };
  };

  var append = function append(dict) {
    return dict.append;
  };

  var semigroupFn = function semigroupFn(dictSemigroup) {
    return new Semigroup(function (f) {
      return function (g) {
        return function (x) {
          return append(dictSemigroup)(f(x))(g(x));
        };
      };
    });
  };

  exports["Semigroup"] = Semigroup;
  exports["append"] = append;
  exports["SemigroupRecord"] = SemigroupRecord;
  exports["appendRecord"] = appendRecord;
  exports["semigroupString"] = semigroupString;
  exports["semigroupFn"] = semigroupFn;
  exports["semigroupArray"] = semigroupArray;
  exports["semigroupRecord"] = semigroupRecord;
  exports["semigroupRecordNil"] = semigroupRecordNil;
})(PS["Data.Semigroup"] = PS["Data.Semigroup"] || {});

(function (exports) {
  // Generated by purs version 0.12.3
  "use strict";

  var Data_Eq = PS["Data.Eq"];
  var Data_Semigroup = PS["Data.Semigroup"];
  var Data_Show = PS["Data.Show"];

  var LT = function () {
    function LT() {}

    ;
    LT.value = new LT();
    return LT;
  }();

  var GT = function () {
    function GT() {}

    ;
    GT.value = new GT();
    return GT;
  }();

  var EQ = function () {
    function EQ() {}

    ;
    EQ.value = new EQ();
    return EQ;
  }();

  exports["LT"] = LT;
  exports["GT"] = GT;
  exports["EQ"] = EQ;
})(PS["Data.Ordering"] = PS["Data.Ordering"] || {});

(function (exports) {
  // Generated by purs version 0.12.3
  "use strict";

  var $foreign = PS["Data.Ord.Unsafe"];
  var Data_Ordering = PS["Data.Ordering"];
  var unsafeCompare = $foreign.unsafeCompareImpl(Data_Ordering.LT.value)(Data_Ordering.EQ.value)(Data_Ordering.GT.value);
  exports["unsafeCompare"] = unsafeCompare;
})(PS["Data.Ord.Unsafe"] = PS["Data.Ord.Unsafe"] || {});

(function (exports) {
  "use strict";

  exports.intSub = function (x) {
    return function (y) {
      /* jshint bitwise: false */
      return x - y | 0;
    };
  };
})(PS["Data.Ring"] = PS["Data.Ring"] || {});

(function (exports) {
  "use strict";

  exports.intAdd = function (x) {
    return function (y) {
      /* jshint bitwise: false */
      return x + y | 0;
    };
  };

  exports.intMul = function (x) {
    return function (y) {
      /* jshint bitwise: false */
      return x * y | 0;
    };
  };
})(PS["Data.Semiring"] = PS["Data.Semiring"] || {});

(function (exports) {
  // Generated by purs version 0.12.3
  "use strict";

  var $foreign = PS["Data.Semiring"];
  var Data_Symbol = PS["Data.Symbol"];
  var Data_Unit = PS["Data.Unit"];
  var Record_Unsafe = PS["Record.Unsafe"];
  var Type_Data_Row = PS["Type.Data.Row"];
  var Type_Data_RowList = PS["Type.Data.RowList"];

  var Semiring = function Semiring(add, mul, one, zero) {
    this.add = add;
    this.mul = mul;
    this.one = one;
    this.zero = zero;
  };

  var zero = function zero(dict) {
    return dict.zero;
  };

  var semiringInt = new Semiring($foreign.intAdd, $foreign.intMul, 1, 0);

  var one = function one(dict) {
    return dict.one;
  };

  var mul = function mul(dict) {
    return dict.mul;
  };

  var add = function add(dict) {
    return dict.add;
  };

  exports["Semiring"] = Semiring;
  exports["add"] = add;
  exports["zero"] = zero;
  exports["mul"] = mul;
  exports["one"] = one;
  exports["semiringInt"] = semiringInt;
})(PS["Data.Semiring"] = PS["Data.Semiring"] || {});

(function (exports) {
  // Generated by purs version 0.12.3
  "use strict";

  var $foreign = PS["Data.Ring"];
  var Data_Semiring = PS["Data.Semiring"];
  var Data_Symbol = PS["Data.Symbol"];
  var Data_Unit = PS["Data.Unit"];
  var Record_Unsafe = PS["Record.Unsafe"];
  var Type_Data_RowList = PS["Type.Data.RowList"];

  var Ring = function Ring(Semiring0, sub) {
    this.Semiring0 = Semiring0;
    this.sub = sub;
  };

  var sub = function sub(dict) {
    return dict.sub;
  };

  var ringInt = new Ring(function () {
    return Data_Semiring.semiringInt;
  }, $foreign.intSub);

  var negate = function negate(dictRing) {
    return function (a) {
      return sub(dictRing)(Data_Semiring.zero(dictRing.Semiring0()))(a);
    };
  };

  exports["Ring"] = Ring;
  exports["sub"] = sub;
  exports["negate"] = negate;
  exports["ringInt"] = ringInt;
})(PS["Data.Ring"] = PS["Data.Ring"] || {});

(function (exports) {
  // Generated by purs version 0.12.3
  "use strict";

  var $foreign = PS["Data.Ord"];
  var Data_Eq = PS["Data.Eq"];
  var Data_Ord_Unsafe = PS["Data.Ord.Unsafe"];
  var Data_Ordering = PS["Data.Ordering"];
  var Data_Ring = PS["Data.Ring"];
  var Data_Semiring = PS["Data.Semiring"];
  var Data_Symbol = PS["Data.Symbol"];
  var Data_Unit = PS["Data.Unit"];
  var Data_Void = PS["Data.Void"];
  var Record_Unsafe = PS["Record.Unsafe"];
  var Type_Data_RowList = PS["Type.Data.RowList"];

  var Ord = function Ord(Eq0, compare) {
    this.Eq0 = Eq0;
    this.compare = compare;
  };

  var Ord1 = function Ord1(Eq10, compare1) {
    this.Eq10 = Eq10;
    this.compare1 = compare1;
  };

  var ordInt = new Ord(function () {
    return Data_Eq.eqInt;
  }, Data_Ord_Unsafe.unsafeCompare);
  var ordChar = new Ord(function () {
    return Data_Eq.eqChar;
  }, Data_Ord_Unsafe.unsafeCompare);
  var ordBoolean = new Ord(function () {
    return Data_Eq.eqBoolean;
  }, Data_Ord_Unsafe.unsafeCompare);

  var compare1 = function compare1(dict) {
    return dict.compare1;
  };

  var compare = function compare(dict) {
    return dict.compare;
  };

  var greaterThan = function greaterThan(dictOrd) {
    return function (a1) {
      return function (a2) {
        var v = compare(dictOrd)(a1)(a2);

        if (v instanceof Data_Ordering.GT) {
          return true;
        }

        ;
        return false;
      };
    };
  };

  var greaterThanOrEq = function greaterThanOrEq(dictOrd) {
    return function (a1) {
      return function (a2) {
        var v = compare(dictOrd)(a1)(a2);

        if (v instanceof Data_Ordering.LT) {
          return false;
        }

        ;
        return true;
      };
    };
  };

  var lessThan = function lessThan(dictOrd) {
    return function (a1) {
      return function (a2) {
        var v = compare(dictOrd)(a1)(a2);

        if (v instanceof Data_Ordering.LT) {
          return true;
        }

        ;
        return false;
      };
    };
  };

  var max = function max(dictOrd) {
    return function (x) {
      return function (y) {
        var v = compare(dictOrd)(x)(y);

        if (v instanceof Data_Ordering.LT) {
          return y;
        }

        ;

        if (v instanceof Data_Ordering.EQ) {
          return x;
        }

        ;

        if (v instanceof Data_Ordering.GT) {
          return x;
        }

        ;
        throw new Error("Failed pattern match at Data.Ord (line 128, column 3 - line 131, column 12): " + [v.constructor.name]);
      };
    };
  };

  var min = function min(dictOrd) {
    return function (x) {
      return function (y) {
        var v = compare(dictOrd)(x)(y);

        if (v instanceof Data_Ordering.LT) {
          return x;
        }

        ;

        if (v instanceof Data_Ordering.EQ) {
          return x;
        }

        ;

        if (v instanceof Data_Ordering.GT) {
          return y;
        }

        ;
        throw new Error("Failed pattern match at Data.Ord (line 119, column 3 - line 122, column 12): " + [v.constructor.name]);
      };
    };
  };

  var abs = function abs(dictOrd) {
    return function (dictRing) {
      return function (x) {
        var $53 = greaterThanOrEq(dictOrd)(x)(Data_Semiring.zero(dictRing.Semiring0()));

        if ($53) {
          return x;
        }

        ;
        return Data_Ring.negate(dictRing)(x);
      };
    };
  };

  exports["Ord"] = Ord;
  exports["compare"] = compare;
  exports["Ord1"] = Ord1;
  exports["compare1"] = compare1;
  exports["lessThan"] = lessThan;
  exports["greaterThan"] = greaterThan;
  exports["greaterThanOrEq"] = greaterThanOrEq;
  exports["min"] = min;
  exports["max"] = max;
  exports["abs"] = abs;
  exports["ordBoolean"] = ordBoolean;
  exports["ordInt"] = ordInt;
  exports["ordChar"] = ordChar;
})(PS["Data.Ord"] = PS["Data.Ord"] || {});

(function (exports) {
  // Generated by purs version 0.12.3
  "use strict";

  var Control_Category = PS["Control.Category"];
  var Data_Boolean = PS["Data.Boolean"];
  var Data_Ord = PS["Data.Ord"];
  var Data_Ring = PS["Data.Ring"];

  var on = function on(f) {
    return function (g) {
      return function (x) {
        return function (y) {
          return f(g(x))(g(y));
        };
      };
    };
  };

  var flip = function flip(f) {
    return function (b) {
      return function (a) {
        return f(a)(b);
      };
    };
  };

  var $$const = function $$const(a) {
    return function (v) {
      return a;
    };
  };

  exports["flip"] = flip;
  exports["const"] = $$const;
  exports["on"] = on;
})(PS["Data.Function"] = PS["Data.Function"] || {});

(function (exports) {
  // Generated by purs version 0.12.3
  "use strict";

  var $foreign = PS["Data.Functor"];
  var Control_Semigroupoid = PS["Control.Semigroupoid"];
  var Data_Function = PS["Data.Function"];
  var Data_Unit = PS["Data.Unit"];

  var Functor = function Functor(map) {
    this.map = map;
  };

  var map = function map(dict) {
    return dict.map;
  };

  var mapFlipped = function mapFlipped(dictFunctor) {
    return function (fa) {
      return function (f) {
        return map(dictFunctor)(f)(fa);
      };
    };
  };

  var $$void = function $$void(dictFunctor) {
    return map(dictFunctor)(Data_Function["const"](Data_Unit.unit));
  };

  var voidLeft = function voidLeft(dictFunctor) {
    return function (f) {
      return function (x) {
        return map(dictFunctor)(Data_Function["const"](x))(f);
      };
    };
  };

  var voidRight = function voidRight(dictFunctor) {
    return function (x) {
      return map(dictFunctor)(Data_Function["const"](x));
    };
  };

  var functorFn = new Functor(Control_Semigroupoid.compose(Control_Semigroupoid.semigroupoidFn));
  var functorArray = new Functor($foreign.arrayMap);
  exports["Functor"] = Functor;
  exports["map"] = map;
  exports["mapFlipped"] = mapFlipped;
  exports["void"] = $$void;
  exports["voidRight"] = voidRight;
  exports["voidLeft"] = voidLeft;
  exports["functorFn"] = functorFn;
  exports["functorArray"] = functorArray;
})(PS["Data.Functor"] = PS["Data.Functor"] || {});

(function (exports) {
  // Generated by purs version 0.12.3
  "use strict";

  var Data_Functor = PS["Data.Functor"];
  var Data_Semigroup = PS["Data.Semigroup"];

  var Alt = function Alt(Functor0, alt) {
    this.Functor0 = Functor0;
    this.alt = alt;
  };

  var alt = function alt(dict) {
    return dict.alt;
  };

  exports["Alt"] = Alt;
  exports["alt"] = alt;
})(PS["Control.Alt"] = PS["Control.Alt"] || {});

(function (exports) {
  // Generated by purs version 0.12.3
  "use strict";

  var $foreign = PS["Control.Apply"];
  var Control_Category = PS["Control.Category"];
  var Data_Function = PS["Data.Function"];
  var Data_Functor = PS["Data.Functor"];

  var Apply = function Apply(Functor0, apply) {
    this.Functor0 = Functor0;
    this.apply = apply;
  };

  var applyFn = new Apply(function () {
    return Data_Functor.functorFn;
  }, function (f) {
    return function (g) {
      return function (x) {
        return f(x)(g(x));
      };
    };
  });

  var apply = function apply(dict) {
    return dict.apply;
  };

  var applyFirst = function applyFirst(dictApply) {
    return function (a) {
      return function (b) {
        return apply(dictApply)(Data_Functor.map(dictApply.Functor0())(Data_Function["const"])(a))(b);
      };
    };
  };

  var applySecond = function applySecond(dictApply) {
    return function (a) {
      return function (b) {
        return apply(dictApply)(Data_Functor.map(dictApply.Functor0())(Data_Function["const"](Control_Category.identity(Control_Category.categoryFn)))(a))(b);
      };
    };
  };

  var lift2 = function lift2(dictApply) {
    return function (f) {
      return function (a) {
        return function (b) {
          return apply(dictApply)(Data_Functor.map(dictApply.Functor0())(f)(a))(b);
        };
      };
    };
  };

  exports["Apply"] = Apply;
  exports["apply"] = apply;
  exports["applyFirst"] = applyFirst;
  exports["applySecond"] = applySecond;
  exports["lift2"] = lift2;
  exports["applyFn"] = applyFn;
})(PS["Control.Apply"] = PS["Control.Apply"] || {});

(function (exports) {
  // Generated by purs version 0.12.3
  "use strict";

  var Control_Apply = PS["Control.Apply"];
  var Data_Functor = PS["Data.Functor"];
  var Data_Unit = PS["Data.Unit"];

  var Applicative = function Applicative(Apply0, pure) {
    this.Apply0 = Apply0;
    this.pure = pure;
  };

  var pure = function pure(dict) {
    return dict.pure;
  };

  var unless = function unless(dictApplicative) {
    return function (v) {
      return function (v1) {
        if (!v) {
          return v1;
        }

        ;

        if (v) {
          return pure(dictApplicative)(Data_Unit.unit);
        }

        ;
        throw new Error("Failed pattern match at Control.Applicative (line 62, column 1 - line 62, column 65): " + [v.constructor.name, v1.constructor.name]);
      };
    };
  };

  var liftA1 = function liftA1(dictApplicative) {
    return function (f) {
      return function (a) {
        return Control_Apply.apply(dictApplicative.Apply0())(pure(dictApplicative)(f))(a);
      };
    };
  };

  exports["Applicative"] = Applicative;
  exports["pure"] = pure;
  exports["liftA1"] = liftA1;
  exports["unless"] = unless;
})(PS["Control.Applicative"] = PS["Control.Applicative"] || {});

(function (exports) {
  // Generated by purs version 0.12.3
  "use strict";

  var Control_Alt = PS["Control.Alt"];
  var Data_Functor = PS["Data.Functor"];

  var Plus = function Plus(Alt0, empty) {
    this.Alt0 = Alt0;
    this.empty = empty;
  };

  var empty = function empty(dict) {
    return dict.empty;
  };

  exports["Plus"] = Plus;
  exports["empty"] = empty;
})(PS["Control.Plus"] = PS["Control.Plus"] || {});

(function (exports) {
  // Generated by purs version 0.12.3
  "use strict";

  var Control_Alt = PS["Control.Alt"];
  var Control_Applicative = PS["Control.Applicative"];
  var Control_Apply = PS["Control.Apply"];
  var Control_Plus = PS["Control.Plus"];
  var Data_Functor = PS["Data.Functor"];

  var Alternative = function Alternative(Applicative0, Plus1) {
    this.Applicative0 = Applicative0;
    this.Plus1 = Plus1;
  };

  exports["Alternative"] = Alternative;
})(PS["Control.Alternative"] = PS["Control.Alternative"] || {});

(function (exports) {
  // Generated by purs version 0.12.3
  "use strict";

  var $foreign = PS["Control.Bind"];
  var Control_Applicative = PS["Control.Applicative"];
  var Control_Apply = PS["Control.Apply"];
  var Control_Category = PS["Control.Category"];
  var Data_Function = PS["Data.Function"];
  var Data_Functor = PS["Data.Functor"];
  var Data_Unit = PS["Data.Unit"];

  var Bind = function Bind(Apply0, bind) {
    this.Apply0 = Apply0;
    this.bind = bind;
  };

  var Discard = function Discard(discard) {
    this.discard = discard;
  };

  var discard = function discard(dict) {
    return dict.discard;
  };

  var bind = function bind(dict) {
    return dict.bind;
  };

  var bindFlipped = function bindFlipped(dictBind) {
    return Data_Function.flip(bind(dictBind));
  };

  var composeKleisli = function composeKleisli(dictBind) {
    return function (f) {
      return function (g) {
        return function (a) {
          return bind(dictBind)(f(a))(g);
        };
      };
    };
  };

  var discardUnit = new Discard(function (dictBind) {
    return bind(dictBind);
  });
  exports["Bind"] = Bind;
  exports["bind"] = bind;
  exports["bindFlipped"] = bindFlipped;
  exports["Discard"] = Discard;
  exports["discard"] = discard;
  exports["composeKleisli"] = composeKleisli;
  exports["discardUnit"] = discardUnit;
})(PS["Control.Bind"] = PS["Control.Bind"] || {});

(function (exports) {
  // Generated by purs version 0.12.3
  "use strict";

  var Data_Unit = PS["Data.Unit"];

  var Lazy = function Lazy(defer) {
    this.defer = defer;
  };

  var defer = function defer(dict) {
    return dict.defer;
  };

  exports["defer"] = defer;
  exports["Lazy"] = Lazy;
})(PS["Control.Lazy"] = PS["Control.Lazy"] || {});

(function (exports) {
  // Generated by purs version 0.12.3
  "use strict";

  var Control_Applicative = PS["Control.Applicative"];
  var Control_Apply = PS["Control.Apply"];
  var Control_Bind = PS["Control.Bind"];
  var Data_Functor = PS["Data.Functor"];
  var Data_Unit = PS["Data.Unit"];

  var Monad = function Monad(Applicative0, Bind1) {
    this.Applicative0 = Applicative0;
    this.Bind1 = Bind1;
  };

  var ap = function ap(dictMonad) {
    return function (f) {
      return function (a) {
        return Control_Bind.bind(dictMonad.Bind1())(f)(function (v) {
          return Control_Bind.bind(dictMonad.Bind1())(a)(function (v1) {
            return Control_Applicative.pure(dictMonad.Applicative0())(v(v1));
          });
        });
      };
    };
  };

  exports["Monad"] = Monad;
  exports["ap"] = ap;
})(PS["Control.Monad"] = PS["Control.Monad"] || {});

(function (exports) {
  // Generated by purs version 0.12.3
  "use strict";

  var Control_Category = PS["Control.Category"];

  var Bifunctor = function Bifunctor(bimap) {
    this.bimap = bimap;
  };

  var bimap = function bimap(dict) {
    return dict.bimap;
  };

  var lmap = function lmap(dictBifunctor) {
    return function (f) {
      return bimap(dictBifunctor)(f)(Control_Category.identity(Control_Category.categoryFn));
    };
  };

  exports["bimap"] = bimap;
  exports["Bifunctor"] = Bifunctor;
  exports["lmap"] = lmap;
})(PS["Data.Bifunctor"] = PS["Data.Bifunctor"] || {});

(function (exports) {
  "use strict";

  exports.topInt = 2147483647;
  exports.bottomInt = -2147483648;
  exports.topChar = String.fromCharCode(65535);
  exports.bottomChar = String.fromCharCode(0);
})(PS["Data.Bounded"] = PS["Data.Bounded"] || {});

(function (exports) {
  // Generated by purs version 0.12.3
  "use strict";

  var $foreign = PS["Data.Bounded"];
  var Data_Ord = PS["Data.Ord"];
  var Data_Ordering = PS["Data.Ordering"];
  var Data_Unit = PS["Data.Unit"];

  var Bounded = function Bounded(Ord0, bottom, top) {
    this.Ord0 = Ord0;
    this.bottom = bottom;
    this.top = top;
  };

  var top = function top(dict) {
    return dict.top;
  };

  var boundedInt = new Bounded(function () {
    return Data_Ord.ordInt;
  }, $foreign.bottomInt, $foreign.topInt);
  var boundedChar = new Bounded(function () {
    return Data_Ord.ordChar;
  }, $foreign.bottomChar, $foreign.topChar);

  var bottom = function bottom(dict) {
    return dict.bottom;
  };

  exports["Bounded"] = Bounded;
  exports["bottom"] = bottom;
  exports["top"] = top;
  exports["boundedInt"] = boundedInt;
  exports["boundedChar"] = boundedChar;
})(PS["Data.Bounded"] = PS["Data.Bounded"] || {});

(function (exports) {
  "use strict";

  exports.foldrArray = function (f) {
    return function (init) {
      return function (xs) {
        var acc = init;
        var len = xs.length;

        for (var i = len - 1; i >= 0; i--) {
          acc = f(xs[i])(acc);
        }

        return acc;
      };
    };
  };

  exports.foldlArray = function (f) {
    return function (init) {
      return function (xs) {
        var acc = init;
        var len = xs.length;

        for (var i = 0; i < len; i++) {
          acc = f(acc)(xs[i]);
        }

        return acc;
      };
    };
  };
})(PS["Data.Foldable"] = PS["Data.Foldable"] || {});

(function (exports) {
  "use strict";

  exports.intDegree = function (x) {
    return Math.min(Math.abs(x), 2147483647);
  }; // See the Euclidean definition in
  // https://en.m.wikipedia.org/wiki/Modulo_operation.


  exports.intDiv = function (x) {
    return function (y) {
      if (y === 0) return 0;
      return y > 0 ? Math.floor(x / y) : -Math.floor(x / -y);
    };
  };

  exports.intMod = function (x) {
    return function (y) {
      if (y === 0) return 0;
      var yy = Math.abs(y);
      return (x % yy + yy) % yy;
    };
  };
})(PS["Data.EuclideanRing"] = PS["Data.EuclideanRing"] || {});

(function (exports) {
  // Generated by purs version 0.12.3
  "use strict";

  var Data_Ring = PS["Data.Ring"];
  var Data_Semiring = PS["Data.Semiring"];
  var Data_Symbol = PS["Data.Symbol"];
  var Data_Unit = PS["Data.Unit"];

  var CommutativeRing = function CommutativeRing(Ring0) {
    this.Ring0 = Ring0;
  };

  var commutativeRingInt = new CommutativeRing(function () {
    return Data_Ring.ringInt;
  });
  exports["CommutativeRing"] = CommutativeRing;
  exports["commutativeRingInt"] = commutativeRingInt;
})(PS["Data.CommutativeRing"] = PS["Data.CommutativeRing"] || {});

(function (exports) {
  // Generated by purs version 0.12.3
  "use strict";

  var $foreign = PS["Data.EuclideanRing"];
  var Data_BooleanAlgebra = PS["Data.BooleanAlgebra"];
  var Data_CommutativeRing = PS["Data.CommutativeRing"];
  var Data_Eq = PS["Data.Eq"];
  var Data_HeytingAlgebra = PS["Data.HeytingAlgebra"];
  var Data_Ring = PS["Data.Ring"];
  var Data_Semiring = PS["Data.Semiring"];

  var EuclideanRing = function EuclideanRing(CommutativeRing0, degree, div, mod) {
    this.CommutativeRing0 = CommutativeRing0;
    this.degree = degree;
    this.div = div;
    this.mod = mod;
  };

  var mod = function mod(dict) {
    return dict.mod;
  };

  var euclideanRingInt = new EuclideanRing(function () {
    return Data_CommutativeRing.commutativeRingInt;
  }, $foreign.intDegree, $foreign.intDiv, $foreign.intMod);

  var div = function div(dict) {
    return dict.div;
  };

  var degree = function degree(dict) {
    return dict.degree;
  };

  exports["EuclideanRing"] = EuclideanRing;
  exports["degree"] = degree;
  exports["div"] = div;
  exports["mod"] = mod;
  exports["euclideanRingInt"] = euclideanRingInt;
})(PS["Data.EuclideanRing"] = PS["Data.EuclideanRing"] || {});

(function (exports) {
  // Generated by purs version 0.12.3
  "use strict";

  var Data_Boolean = PS["Data.Boolean"];
  var Data_Eq = PS["Data.Eq"];
  var Data_EuclideanRing = PS["Data.EuclideanRing"];
  var Data_Ord = PS["Data.Ord"];
  var Data_Ordering = PS["Data.Ordering"];
  var Data_Semigroup = PS["Data.Semigroup"];
  var Data_Symbol = PS["Data.Symbol"];
  var Data_Unit = PS["Data.Unit"];
  var Record_Unsafe = PS["Record.Unsafe"];
  var Type_Data_RowList = PS["Type.Data.RowList"];

  var Monoid = function Monoid(Semigroup0, mempty) {
    this.Semigroup0 = Semigroup0;
    this.mempty = mempty;
  };

  var MonoidRecord = function MonoidRecord(SemigroupRecord0, memptyRecord) {
    this.SemigroupRecord0 = SemigroupRecord0;
    this.memptyRecord = memptyRecord;
  };

  var monoidString = new Monoid(function () {
    return Data_Semigroup.semigroupString;
  }, "");
  var monoidRecordNil = new MonoidRecord(function () {
    return Data_Semigroup.semigroupRecordNil;
  }, function (v) {
    return {};
  });

  var memptyRecord = function memptyRecord(dict) {
    return dict.memptyRecord;
  };

  var monoidRecord = function monoidRecord(dictRowToList) {
    return function (dictMonoidRecord) {
      return new Monoid(function () {
        return Data_Semigroup.semigroupRecord(dictRowToList)(dictMonoidRecord.SemigroupRecord0());
      }, memptyRecord(dictMonoidRecord)(Type_Data_RowList.RLProxy.value));
    };
  };

  var mempty = function mempty(dict) {
    return dict.mempty;
  };

  var monoidFn = function monoidFn(dictMonoid) {
    return new Monoid(function () {
      return Data_Semigroup.semigroupFn(dictMonoid.Semigroup0());
    }, function (v) {
      return mempty(dictMonoid);
    });
  };

  exports["Monoid"] = Monoid;
  exports["mempty"] = mempty;
  exports["MonoidRecord"] = MonoidRecord;
  exports["memptyRecord"] = memptyRecord;
  exports["monoidFn"] = monoidFn;
  exports["monoidString"] = monoidString;
  exports["monoidRecord"] = monoidRecord;
  exports["monoidRecordNil"] = monoidRecordNil;
})(PS["Data.Monoid"] = PS["Data.Monoid"] || {});

(function (exports) {
  // Generated by purs version 0.12.3
  "use strict";

  var Control_Alt = PS["Control.Alt"];
  var Control_Alternative = PS["Control.Alternative"];
  var Control_Applicative = PS["Control.Applicative"];
  var Control_Apply = PS["Control.Apply"];
  var Control_Bind = PS["Control.Bind"];
  var Control_Category = PS["Control.Category"];
  var Control_Extend = PS["Control.Extend"];
  var Control_Monad = PS["Control.Monad"];
  var Control_MonadZero = PS["Control.MonadZero"];
  var Control_Plus = PS["Control.Plus"];
  var Data_Bounded = PS["Data.Bounded"];
  var Data_Eq = PS["Data.Eq"];
  var Data_Function = PS["Data.Function"];
  var Data_Functor = PS["Data.Functor"];
  var Data_Functor_Invariant = PS["Data.Functor.Invariant"];
  var Data_Monoid = PS["Data.Monoid"];
  var Data_Ord = PS["Data.Ord"];
  var Data_Ordering = PS["Data.Ordering"];
  var Data_Semigroup = PS["Data.Semigroup"];
  var Data_Show = PS["Data.Show"];
  var Data_Unit = PS["Data.Unit"];
  var Prelude = PS["Prelude"];

  var Nothing = function () {
    function Nothing() {}

    ;
    Nothing.value = new Nothing();
    return Nothing;
  }();

  var Just = function () {
    function Just(value0) {
      this.value0 = value0;
    }

    ;

    Just.create = function (value0) {
      return new Just(value0);
    };

    return Just;
  }();

  var maybe = function maybe(v) {
    return function (v1) {
      return function (v2) {
        if (v2 instanceof Nothing) {
          return v;
        }

        ;

        if (v2 instanceof Just) {
          return v1(v2.value0);
        }

        ;
        throw new Error("Failed pattern match at Data.Maybe (line 217, column 1 - line 217, column 51): " + [v.constructor.name, v1.constructor.name, v2.constructor.name]);
      };
    };
  };

  var isNothing = maybe(true)(Data_Function["const"](false));
  var isJust = maybe(false)(Data_Function["const"](true));
  var functorMaybe = new Data_Functor.Functor(function (v) {
    return function (v1) {
      if (v1 instanceof Just) {
        return new Just(v(v1.value0));
      }

      ;
      return Nothing.value;
    };
  });

  var fromMaybe = function fromMaybe(a) {
    return maybe(a)(Control_Category.identity(Control_Category.categoryFn));
  };

  var fromJust = function fromJust(dictPartial) {
    return function (v) {
      if (v instanceof Just) {
        return v.value0;
      }

      ;
      throw new Error("Failed pattern match at Data.Maybe (line 268, column 1 - line 268, column 46): " + [v.constructor.name]);
    };
  };

  var eqMaybe = function eqMaybe(dictEq) {
    return new Data_Eq.Eq(function (x) {
      return function (y) {
        if (x instanceof Nothing && y instanceof Nothing) {
          return true;
        }

        ;

        if (x instanceof Just && y instanceof Just) {
          return Data_Eq.eq(dictEq)(x.value0)(y.value0);
        }

        ;
        return false;
      };
    });
  };

  var applyMaybe = new Control_Apply.Apply(function () {
    return functorMaybe;
  }, function (v) {
    return function (v1) {
      if (v instanceof Just) {
        return Data_Functor.map(functorMaybe)(v.value0)(v1);
      }

      ;

      if (v instanceof Nothing) {
        return Nothing.value;
      }

      ;
      throw new Error("Failed pattern match at Data.Maybe (line 67, column 1 - line 67, column 35): " + [v.constructor.name, v1.constructor.name]);
    };
  });
  var bindMaybe = new Control_Bind.Bind(function () {
    return applyMaybe;
  }, function (v) {
    return function (v1) {
      if (v instanceof Just) {
        return v1(v.value0);
      }

      ;

      if (v instanceof Nothing) {
        return Nothing.value;
      }

      ;
      throw new Error("Failed pattern match at Data.Maybe (line 125, column 1 - line 125, column 33): " + [v.constructor.name, v1.constructor.name]);
    };
  });
  var applicativeMaybe = new Control_Applicative.Applicative(function () {
    return applyMaybe;
  }, Just.create);
  var altMaybe = new Control_Alt.Alt(function () {
    return functorMaybe;
  }, function (v) {
    return function (v1) {
      if (v instanceof Nothing) {
        return v1;
      }

      ;
      return v;
    };
  });
  var plusMaybe = new Control_Plus.Plus(function () {
    return altMaybe;
  }, Nothing.value);
  exports["Nothing"] = Nothing;
  exports["Just"] = Just;
  exports["maybe"] = maybe;
  exports["fromMaybe"] = fromMaybe;
  exports["isJust"] = isJust;
  exports["isNothing"] = isNothing;
  exports["fromJust"] = fromJust;
  exports["functorMaybe"] = functorMaybe;
  exports["applyMaybe"] = applyMaybe;
  exports["applicativeMaybe"] = applicativeMaybe;
  exports["altMaybe"] = altMaybe;
  exports["plusMaybe"] = plusMaybe;
  exports["bindMaybe"] = bindMaybe;
  exports["eqMaybe"] = eqMaybe;
})(PS["Data.Maybe"] = PS["Data.Maybe"] || {});

(function (exports) {
  // Generated by purs version 0.12.3
  "use strict";

  var Control_Applicative = PS["Control.Applicative"];
  var Control_Apply = PS["Control.Apply"];
  var Control_Bind = PS["Control.Bind"];
  var Control_Monad = PS["Control.Monad"];
  var Data_Bounded = PS["Data.Bounded"];
  var Data_Eq = PS["Data.Eq"];
  var Data_Functor = PS["Data.Functor"];
  var Data_HeytingAlgebra = PS["Data.HeytingAlgebra"];
  var Data_Monoid = PS["Data.Monoid"];
  var Data_Ord = PS["Data.Ord"];
  var Data_Semigroup = PS["Data.Semigroup"];
  var Data_Semiring = PS["Data.Semiring"];
  var Data_Show = PS["Data.Show"];
  var Prelude = PS["Prelude"];

  var Conj = function Conj(x) {
    return x;
  };

  var semigroupConj = function semigroupConj(dictHeytingAlgebra) {
    return new Data_Semigroup.Semigroup(function (v) {
      return function (v1) {
        return Data_HeytingAlgebra.conj(dictHeytingAlgebra)(v)(v1);
      };
    });
  };

  var monoidConj = function monoidConj(dictHeytingAlgebra) {
    return new Data_Monoid.Monoid(function () {
      return semigroupConj(dictHeytingAlgebra);
    }, Data_HeytingAlgebra.tt(dictHeytingAlgebra));
  };

  exports["Conj"] = Conj;
  exports["semigroupConj"] = semigroupConj;
  exports["monoidConj"] = monoidConj;
})(PS["Data.Monoid.Conj"] = PS["Data.Monoid.Conj"] || {});

(function (exports) {
  // Generated by purs version 0.12.3
  "use strict";

  var Control_Applicative = PS["Control.Applicative"];
  var Control_Apply = PS["Control.Apply"];
  var Control_Bind = PS["Control.Bind"];
  var Control_Monad = PS["Control.Monad"];
  var Data_Bounded = PS["Data.Bounded"];
  var Data_Eq = PS["Data.Eq"];
  var Data_Functor = PS["Data.Functor"];
  var Data_HeytingAlgebra = PS["Data.HeytingAlgebra"];
  var Data_Monoid = PS["Data.Monoid"];
  var Data_Ord = PS["Data.Ord"];
  var Data_Semigroup = PS["Data.Semigroup"];
  var Data_Semiring = PS["Data.Semiring"];
  var Data_Show = PS["Data.Show"];
  var Prelude = PS["Prelude"];

  var Disj = function Disj(x) {
    return x;
  };

  var semigroupDisj = function semigroupDisj(dictHeytingAlgebra) {
    return new Data_Semigroup.Semigroup(function (v) {
      return function (v1) {
        return Data_HeytingAlgebra.disj(dictHeytingAlgebra)(v)(v1);
      };
    });
  };

  var monoidDisj = function monoidDisj(dictHeytingAlgebra) {
    return new Data_Monoid.Monoid(function () {
      return semigroupDisj(dictHeytingAlgebra);
    }, Data_HeytingAlgebra.ff(dictHeytingAlgebra));
  };

  exports["Disj"] = Disj;
  exports["semigroupDisj"] = semigroupDisj;
  exports["monoidDisj"] = monoidDisj;
})(PS["Data.Monoid.Disj"] = PS["Data.Monoid.Disj"] || {});

(function (exports) {
  // Generated by purs version 0.12.3
  "use strict";

  var Control_Semigroupoid = PS["Control.Semigroupoid"];
  var Data_Function = PS["Data.Function"];
  var Data_Functor = PS["Data.Functor"];
  var Data_Monoid_Additive = PS["Data.Monoid.Additive"];
  var Data_Monoid_Conj = PS["Data.Monoid.Conj"];
  var Data_Monoid_Disj = PS["Data.Monoid.Disj"];
  var Data_Monoid_Dual = PS["Data.Monoid.Dual"];
  var Data_Monoid_Endo = PS["Data.Monoid.Endo"];
  var Data_Monoid_Multiplicative = PS["Data.Monoid.Multiplicative"];
  var Data_Semigroup_First = PS["Data.Semigroup.First"];
  var Data_Semigroup_Last = PS["Data.Semigroup.Last"];
  var Prelude = PS["Prelude"];

  var Newtype = function Newtype(unwrap, wrap) {
    this.unwrap = unwrap;
    this.wrap = wrap;
  };

  var wrap = function wrap(dict) {
    return dict.wrap;
  };

  var unwrap = function unwrap(dict) {
    return dict.unwrap;
  };

  var over2 = function over2(dictNewtype) {
    return function (dictNewtype1) {
      return function (v) {
        return function (f) {
          return function ($76) {
            return function ($77) {
              return wrap(dictNewtype1)(Data_Function.on(f)(unwrap(dictNewtype))($76)($77));
            };
          };
        };
      };
    };
  };

  var newtypeDisj = new Newtype(function (v) {
    return v;
  }, Data_Monoid_Disj.Disj);
  var newtypeConj = new Newtype(function (v) {
    return v;
  }, Data_Monoid_Conj.Conj);

  var alaF = function alaF(dictFunctor) {
    return function (dictFunctor1) {
      return function (dictNewtype) {
        return function (dictNewtype1) {
          return function (v) {
            return function (f) {
              return function ($80) {
                return Data_Functor.map(dictFunctor1)(unwrap(dictNewtype1))(f(Data_Functor.map(dictFunctor)(wrap(dictNewtype))($80)));
              };
            };
          };
        };
      };
    };
  };

  exports["unwrap"] = unwrap;
  exports["wrap"] = wrap;
  exports["Newtype"] = Newtype;
  exports["alaF"] = alaF;
  exports["over2"] = over2;
  exports["newtypeConj"] = newtypeConj;
  exports["newtypeDisj"] = newtypeDisj;
})(PS["Data.Newtype"] = PS["Data.Newtype"] || {});

(function (exports) {
  // Generated by purs version 0.12.3
  "use strict";

  var Control_Alt = PS["Control.Alt"];
  var Control_Alternative = PS["Control.Alternative"];
  var Control_Applicative = PS["Control.Applicative"];
  var Control_Apply = PS["Control.Apply"];
  var Control_Bind = PS["Control.Bind"];
  var Control_Extend = PS["Control.Extend"];
  var Control_Monad = PS["Control.Monad"];
  var Control_MonadZero = PS["Control.MonadZero"];
  var Control_Plus = PS["Control.Plus"];
  var Data_Bounded = PS["Data.Bounded"];
  var Data_Eq = PS["Data.Eq"];
  var Data_Functor = PS["Data.Functor"];
  var Data_Functor_Invariant = PS["Data.Functor.Invariant"];
  var Data_Maybe = PS["Data.Maybe"];
  var Data_Monoid = PS["Data.Monoid"];
  var Data_Newtype = PS["Data.Newtype"];
  var Data_Ord = PS["Data.Ord"];
  var Data_Semigroup = PS["Data.Semigroup"];
  var Data_Show = PS["Data.Show"];
  var Prelude = PS["Prelude"];

  var First = function First(x) {
    return x;
  };

  var semigroupFirst = new Data_Semigroup.Semigroup(function (v) {
    return function (v1) {
      if (v instanceof Data_Maybe.Just) {
        return v;
      }

      ;
      return v1;
    };
  });
  var newtypeFirst = new Data_Newtype.Newtype(function (n) {
    return n;
  }, First);
  var monoidFirst = new Data_Monoid.Monoid(function () {
    return semigroupFirst;
  }, Data_Maybe.Nothing.value);
  exports["First"] = First;
  exports["newtypeFirst"] = newtypeFirst;
  exports["semigroupFirst"] = semigroupFirst;
  exports["monoidFirst"] = monoidFirst;
})(PS["Data.Maybe.First"] = PS["Data.Maybe.First"] || {});

(function (exports) {
  // Generated by purs version 0.12.3
  "use strict";

  var $foreign = PS["Data.Foldable"];
  var Control_Alt = PS["Control.Alt"];
  var Control_Applicative = PS["Control.Applicative"];
  var Control_Apply = PS["Control.Apply"];
  var Control_Bind = PS["Control.Bind"];
  var Control_Category = PS["Control.Category"];
  var Control_Plus = PS["Control.Plus"];
  var Control_Semigroupoid = PS["Control.Semigroupoid"];
  var Data_Eq = PS["Data.Eq"];
  var Data_Function = PS["Data.Function"];
  var Data_Functor = PS["Data.Functor"];
  var Data_HeytingAlgebra = PS["Data.HeytingAlgebra"];
  var Data_Maybe = PS["Data.Maybe"];
  var Data_Maybe_First = PS["Data.Maybe.First"];
  var Data_Maybe_Last = PS["Data.Maybe.Last"];
  var Data_Monoid = PS["Data.Monoid"];
  var Data_Monoid_Additive = PS["Data.Monoid.Additive"];
  var Data_Monoid_Conj = PS["Data.Monoid.Conj"];
  var Data_Monoid_Disj = PS["Data.Monoid.Disj"];
  var Data_Monoid_Dual = PS["Data.Monoid.Dual"];
  var Data_Monoid_Endo = PS["Data.Monoid.Endo"];
  var Data_Monoid_Multiplicative = PS["Data.Monoid.Multiplicative"];
  var Data_Newtype = PS["Data.Newtype"];
  var Data_Ord = PS["Data.Ord"];
  var Data_Ordering = PS["Data.Ordering"];
  var Data_Semigroup = PS["Data.Semigroup"];
  var Data_Semiring = PS["Data.Semiring"];
  var Data_Unit = PS["Data.Unit"];
  var Prelude = PS["Prelude"];

  var Foldable = function Foldable(foldMap, foldl, foldr) {
    this.foldMap = foldMap;
    this.foldl = foldl;
    this.foldr = foldr;
  };

  var foldr = function foldr(dict) {
    return dict.foldr;
  };

  var traverse_ = function traverse_(dictApplicative) {
    return function (dictFoldable) {
      return function (f) {
        return foldr(dictFoldable)(function ($195) {
          return Control_Apply.applySecond(dictApplicative.Apply0())(f($195));
        })(Control_Applicative.pure(dictApplicative)(Data_Unit.unit));
      };
    };
  };

  var for_ = function for_(dictApplicative) {
    return function (dictFoldable) {
      return Data_Function.flip(traverse_(dictApplicative)(dictFoldable));
    };
  };

  var foldl = function foldl(dict) {
    return dict.foldl;
  };

  var indexl = function indexl(dictFoldable) {
    return function (idx) {
      var go = function go(cursor) {
        return function (a) {
          if (cursor.elem instanceof Data_Maybe.Just) {
            return cursor;
          }

          ;
          var $109 = cursor.pos === idx;

          if ($109) {
            return {
              elem: new Data_Maybe.Just(a),
              pos: cursor.pos
            };
          }

          ;
          return {
            pos: cursor.pos + 1 | 0,
            elem: cursor.elem
          };
        };
      };

      return function ($196) {
        return function (v) {
          return v.elem;
        }(foldl(dictFoldable)(go)({
          elem: Data_Maybe.Nothing.value,
          pos: 0
        })($196));
      };
    };
  };

  var intercalate = function intercalate(dictFoldable) {
    return function (dictMonoid) {
      return function (sep) {
        return function (xs) {
          var go = function go(v) {
            return function (x) {
              if (v.init) {
                return {
                  init: false,
                  acc: x
                };
              }

              ;
              return {
                init: false,
                acc: Data_Semigroup.append(dictMonoid.Semigroup0())(v.acc)(Data_Semigroup.append(dictMonoid.Semigroup0())(sep)(x))
              };
            };
          };

          return foldl(dictFoldable)(go)({
            init: true,
            acc: Data_Monoid.mempty(dictMonoid)
          })(xs).acc;
        };
      };
    };
  };

  var foldableMaybe = new Foldable(function (dictMonoid) {
    return function (f) {
      return function (v) {
        if (v instanceof Data_Maybe.Nothing) {
          return Data_Monoid.mempty(dictMonoid);
        }

        ;

        if (v instanceof Data_Maybe.Just) {
          return f(v.value0);
        }

        ;
        throw new Error("Failed pattern match at Data.Foldable (line 129, column 1 - line 129, column 41): " + [f.constructor.name, v.constructor.name]);
      };
    };
  }, function (v) {
    return function (z) {
      return function (v1) {
        if (v1 instanceof Data_Maybe.Nothing) {
          return z;
        }

        ;

        if (v1 instanceof Data_Maybe.Just) {
          return v(z)(v1.value0);
        }

        ;
        throw new Error("Failed pattern match at Data.Foldable (line 129, column 1 - line 129, column 41): " + [v.constructor.name, z.constructor.name, v1.constructor.name]);
      };
    };
  }, function (v) {
    return function (z) {
      return function (v1) {
        if (v1 instanceof Data_Maybe.Nothing) {
          return z;
        }

        ;

        if (v1 instanceof Data_Maybe.Just) {
          return v(v1.value0)(z);
        }

        ;
        throw new Error("Failed pattern match at Data.Foldable (line 129, column 1 - line 129, column 41): " + [v.constructor.name, z.constructor.name, v1.constructor.name]);
      };
    };
  });

  var foldMapDefaultR = function foldMapDefaultR(dictFoldable) {
    return function (dictMonoid) {
      return function (f) {
        return foldr(dictFoldable)(function (x) {
          return function (acc) {
            return Data_Semigroup.append(dictMonoid.Semigroup0())(f(x))(acc);
          };
        })(Data_Monoid.mempty(dictMonoid));
      };
    };
  };

  var foldableArray = new Foldable(function (dictMonoid) {
    return foldMapDefaultR(foldableArray)(dictMonoid);
  }, $foreign.foldlArray, $foreign.foldrArray);

  var foldMap = function foldMap(dict) {
    return dict.foldMap;
  };

  var any = function any(dictFoldable) {
    return function (dictHeytingAlgebra) {
      return Data_Newtype.alaF(Data_Functor.functorFn)(Data_Functor.functorFn)(Data_Newtype.newtypeDisj)(Data_Newtype.newtypeDisj)(Data_Monoid_Disj.Disj)(foldMap(dictFoldable)(Data_Monoid_Disj.monoidDisj(dictHeytingAlgebra)));
    };
  };

  var elem = function elem(dictFoldable) {
    return function (dictEq) {
      return function ($199) {
        return any(dictFoldable)(Data_HeytingAlgebra.heytingAlgebraBoolean)(Data_Eq.eq(dictEq)($199));
      };
    };
  };

  var notElem = function notElem(dictFoldable) {
    return function (dictEq) {
      return function (x) {
        return function ($200) {
          return !elem(dictFoldable)(dictEq)(x)($200);
        };
      };
    };
  };

  var all = function all(dictFoldable) {
    return function (dictHeytingAlgebra) {
      return Data_Newtype.alaF(Data_Functor.functorFn)(Data_Functor.functorFn)(Data_Newtype.newtypeConj)(Data_Newtype.newtypeConj)(Data_Monoid_Conj.Conj)(foldMap(dictFoldable)(Data_Monoid_Conj.monoidConj(dictHeytingAlgebra)));
    };
  };

  exports["Foldable"] = Foldable;
  exports["foldr"] = foldr;
  exports["foldl"] = foldl;
  exports["foldMap"] = foldMap;
  exports["foldMapDefaultR"] = foldMapDefaultR;
  exports["traverse_"] = traverse_;
  exports["for_"] = for_;
  exports["intercalate"] = intercalate;
  exports["all"] = all;
  exports["any"] = any;
  exports["elem"] = elem;
  exports["notElem"] = notElem;
  exports["indexl"] = indexl;
  exports["foldableArray"] = foldableArray;
  exports["foldableMaybe"] = foldableMaybe;
})(PS["Data.Foldable"] = PS["Data.Foldable"] || {});

(function (exports) {
  "use strict"; // jshint maxparams: 3

  exports.traverseArrayImpl = function () {
    function array1(a) {
      return [a];
    }

    function array2(a) {
      return function (b) {
        return [a, b];
      };
    }

    function array3(a) {
      return function (b) {
        return function (c) {
          return [a, b, c];
        };
      };
    }

    function concat2(xs) {
      return function (ys) {
        return xs.concat(ys);
      };
    }

    return function (apply) {
      return function (map) {
        return function (pure) {
          return function (f) {
            return function (array) {
              function go(bot, top) {
                switch (top - bot) {
                  case 0:
                    return pure([]);

                  case 1:
                    return map(array1)(f(array[bot]));

                  case 2:
                    return apply(map(array2)(f(array[bot])))(f(array[bot + 1]));

                  case 3:
                    return apply(apply(map(array3)(f(array[bot])))(f(array[bot + 1])))(f(array[bot + 2]));

                  default:
                    // This slightly tricky pivot selection aims to produce two
                    // even-length partitions where possible.
                    var pivot = bot + Math.floor((top - bot) / 4) * 2;
                    return apply(map(concat2)(go(bot, pivot)))(go(pivot, top));
                }
              }

              return go(0, array.length);
            };
          };
        };
      };
    };
  }();
})(PS["Data.Traversable"] = PS["Data.Traversable"] || {});

(function (exports) {
  // Generated by purs version 0.12.3
  "use strict";

  var $foreign = PS["Data.Traversable"];
  var Control_Applicative = PS["Control.Applicative"];
  var Control_Apply = PS["Control.Apply"];
  var Control_Category = PS["Control.Category"];
  var Data_Foldable = PS["Data.Foldable"];
  var Data_Functor = PS["Data.Functor"];
  var Data_Maybe = PS["Data.Maybe"];
  var Data_Maybe_First = PS["Data.Maybe.First"];
  var Data_Maybe_Last = PS["Data.Maybe.Last"];
  var Data_Monoid_Additive = PS["Data.Monoid.Additive"];
  var Data_Monoid_Conj = PS["Data.Monoid.Conj"];
  var Data_Monoid_Disj = PS["Data.Monoid.Disj"];
  var Data_Monoid_Dual = PS["Data.Monoid.Dual"];
  var Data_Monoid_Multiplicative = PS["Data.Monoid.Multiplicative"];
  var Data_Traversable_Accum = PS["Data.Traversable.Accum"];
  var Data_Traversable_Accum_Internal = PS["Data.Traversable.Accum.Internal"];
  var Prelude = PS["Prelude"];

  var Traversable = function Traversable(Foldable1, Functor0, sequence, traverse) {
    this.Foldable1 = Foldable1;
    this.Functor0 = Functor0;
    this.sequence = sequence;
    this.traverse = traverse;
  };

  var traverse = function traverse(dict) {
    return dict.traverse;
  };

  var sequenceDefault = function sequenceDefault(dictTraversable) {
    return function (dictApplicative) {
      return traverse(dictTraversable)(dictApplicative)(Control_Category.identity(Control_Category.categoryFn));
    };
  };

  var traversableArray = new Traversable(function () {
    return Data_Foldable.foldableArray;
  }, function () {
    return Data_Functor.functorArray;
  }, function (dictApplicative) {
    return sequenceDefault(traversableArray)(dictApplicative);
  }, function (dictApplicative) {
    return $foreign.traverseArrayImpl(Control_Apply.apply(dictApplicative.Apply0()))(Data_Functor.map(dictApplicative.Apply0().Functor0()))(Control_Applicative.pure(dictApplicative));
  });

  var sequence = function sequence(dict) {
    return dict.sequence;
  };

  exports["Traversable"] = Traversable;
  exports["traverse"] = traverse;
  exports["sequence"] = sequence;
  exports["sequenceDefault"] = sequenceDefault;
  exports["traversableArray"] = traversableArray;
})(PS["Data.Traversable"] = PS["Data.Traversable"] || {});

(function (exports) {
  // Generated by purs version 0.12.3
  "use strict";

  var Control_Alt = PS["Control.Alt"];
  var Control_Applicative = PS["Control.Applicative"];
  var Control_Apply = PS["Control.Apply"];
  var Control_Bind = PS["Control.Bind"];
  var Control_Extend = PS["Control.Extend"];
  var Control_Monad = PS["Control.Monad"];
  var Control_Semigroupoid = PS["Control.Semigroupoid"];
  var Data_Bifoldable = PS["Data.Bifoldable"];
  var Data_Bifunctor = PS["Data.Bifunctor"];
  var Data_Bitraversable = PS["Data.Bitraversable"];
  var Data_Bounded = PS["Data.Bounded"];
  var Data_Eq = PS["Data.Eq"];
  var Data_Foldable = PS["Data.Foldable"];
  var Data_FoldableWithIndex = PS["Data.FoldableWithIndex"];
  var Data_Function = PS["Data.Function"];
  var Data_Functor = PS["Data.Functor"];
  var Data_Functor_Invariant = PS["Data.Functor.Invariant"];
  var Data_FunctorWithIndex = PS["Data.FunctorWithIndex"];
  var Data_Maybe = PS["Data.Maybe"];
  var Data_Monoid = PS["Data.Monoid"];
  var Data_Ord = PS["Data.Ord"];
  var Data_Ordering = PS["Data.Ordering"];
  var Data_Semigroup = PS["Data.Semigroup"];
  var Data_Show = PS["Data.Show"];
  var Data_Traversable = PS["Data.Traversable"];
  var Data_TraversableWithIndex = PS["Data.TraversableWithIndex"];
  var Data_Unit = PS["Data.Unit"];
  var Prelude = PS["Prelude"];

  var Left = function () {
    function Left(value0) {
      this.value0 = value0;
    }

    ;

    Left.create = function (value0) {
      return new Left(value0);
    };

    return Left;
  }();

  var Right = function () {
    function Right(value0) {
      this.value0 = value0;
    }

    ;

    Right.create = function (value0) {
      return new Right(value0);
    };

    return Right;
  }();

  var showEither = function showEither(dictShow) {
    return function (dictShow1) {
      return new Data_Show.Show(function (v) {
        if (v instanceof Left) {
          return "(Left " + (Data_Show.show(dictShow)(v.value0) + ")");
        }

        ;

        if (v instanceof Right) {
          return "(Right " + (Data_Show.show(dictShow1)(v.value0) + ")");
        }

        ;
        throw new Error("Failed pattern match at Data.Either (line 163, column 1 - line 163, column 61): " + [v.constructor.name]);
      });
    };
  };

  var functorEither = new Data_Functor.Functor(function (f) {
    return function (m) {
      if (m instanceof Left) {
        return new Left(m.value0);
      }

      ;

      if (m instanceof Right) {
        return new Right(f(m.value0));
      }

      ;
      throw new Error("Failed pattern match at Data.Either (line 38, column 8 - line 38, column 52): " + [m.constructor.name]);
    };
  });

  var fromRight = function fromRight(dictPartial) {
    return function (v) {
      if (v instanceof Right) {
        return v.value0;
      }

      ;
      throw new Error("Failed pattern match at Data.Either (line 261, column 1 - line 261, column 52): " + [v.constructor.name]);
    };
  };

  var foldableEither = new Data_Foldable.Foldable(function (dictMonoid) {
    return function (f) {
      return function (v) {
        if (v instanceof Left) {
          return Data_Monoid.mempty(dictMonoid);
        }

        ;

        if (v instanceof Right) {
          return f(v.value0);
        }

        ;
        throw new Error("Failed pattern match at Data.Either (line 187, column 1 - line 187, column 47): " + [f.constructor.name, v.constructor.name]);
      };
    };
  }, function (v) {
    return function (z) {
      return function (v1) {
        if (v1 instanceof Left) {
          return z;
        }

        ;

        if (v1 instanceof Right) {
          return v(z)(v1.value0);
        }

        ;
        throw new Error("Failed pattern match at Data.Either (line 187, column 1 - line 187, column 47): " + [v.constructor.name, z.constructor.name, v1.constructor.name]);
      };
    };
  }, function (v) {
    return function (z) {
      return function (v1) {
        if (v1 instanceof Left) {
          return z;
        }

        ;

        if (v1 instanceof Right) {
          return v(v1.value0)(z);
        }

        ;
        throw new Error("Failed pattern match at Data.Either (line 187, column 1 - line 187, column 47): " + [v.constructor.name, z.constructor.name, v1.constructor.name]);
      };
    };
  });

  var either = function either(v) {
    return function (v1) {
      return function (v2) {
        if (v2 instanceof Left) {
          return v(v2.value0);
        }

        ;

        if (v2 instanceof Right) {
          return v1(v2.value0);
        }

        ;
        throw new Error("Failed pattern match at Data.Either (line 238, column 1 - line 238, column 64): " + [v.constructor.name, v1.constructor.name, v2.constructor.name]);
      };
    };
  };

  var bifunctorEither = new Data_Bifunctor.Bifunctor(function (v) {
    return function (v1) {
      return function (v2) {
        if (v2 instanceof Left) {
          return new Left(v(v2.value0));
        }

        ;

        if (v2 instanceof Right) {
          return new Right(v1(v2.value0));
        }

        ;
        throw new Error("Failed pattern match at Data.Either (line 46, column 1 - line 46, column 45): " + [v.constructor.name, v1.constructor.name, v2.constructor.name]);
      };
    };
  });
  exports["Left"] = Left;
  exports["Right"] = Right;
  exports["either"] = either;
  exports["fromRight"] = fromRight;
  exports["functorEither"] = functorEither;
  exports["bifunctorEither"] = bifunctorEither;
  exports["showEither"] = showEither;
  exports["foldableEither"] = foldableEither;
})(PS["Data.Either"] = PS["Data.Either"] || {});

(function (exports) {
  "use strict";

  exports.pureE = function (a) {
    return function () {
      return a;
    };
  };

  exports.bindE = function (a) {
    return function (f) {
      return function () {
        return f(a())();
      };
    };
  };
})(PS["Effect"] = PS["Effect"] || {});

(function (exports) {
  // Generated by purs version 0.12.3
  "use strict";

  var $foreign = PS["Effect"];
  var Control_Applicative = PS["Control.Applicative"];
  var Control_Apply = PS["Control.Apply"];
  var Control_Bind = PS["Control.Bind"];
  var Control_Monad = PS["Control.Monad"];
  var Data_Functor = PS["Data.Functor"];
  var Data_Monoid = PS["Data.Monoid"];
  var Data_Semigroup = PS["Data.Semigroup"];
  var Prelude = PS["Prelude"];
  var monadEffect = new Control_Monad.Monad(function () {
    return applicativeEffect;
  }, function () {
    return bindEffect;
  });
  var bindEffect = new Control_Bind.Bind(function () {
    return applyEffect;
  }, $foreign.bindE);
  var applyEffect = new Control_Apply.Apply(function () {
    return functorEffect;
  }, Control_Monad.ap(monadEffect));
  var applicativeEffect = new Control_Applicative.Applicative(function () {
    return applyEffect;
  }, $foreign.pureE);
  var functorEffect = new Data_Functor.Functor(Control_Applicative.liftA1(applicativeEffect));
  exports["functorEffect"] = functorEffect;
  exports["applyEffect"] = applyEffect;
  exports["applicativeEffect"] = applicativeEffect;
  exports["bindEffect"] = bindEffect;
  exports["monadEffect"] = monadEffect;
})(PS["Effect"] = PS["Effect"] || {});

(function (exports) {
  "use strict";

  exports.showErrorImpl = function (err) {
    return err.stack || err.toString();
  };

  exports.error = function (msg) {
    return new Error(msg);
  };

  exports.throwException = function (e) {
    return function () {
      throw e;
    };
  };
})(PS["Effect.Exception"] = PS["Effect.Exception"] || {});

(function (exports) {
  // Generated by purs version 0.12.3
  "use strict";

  var $foreign = PS["Effect.Exception"];
  var Control_Applicative = PS["Control.Applicative"];
  var Control_Semigroupoid = PS["Control.Semigroupoid"];
  var Data_Either = PS["Data.Either"];
  var Data_Functor = PS["Data.Functor"];
  var Data_Maybe = PS["Data.Maybe"];
  var Data_Show = PS["Data.Show"];
  var Effect = PS["Effect"];
  var Prelude = PS["Prelude"];

  var $$throw = function $$throw($1) {
    return $foreign.throwException($foreign.error($1));
  };

  var showError = new Data_Show.Show($foreign.showErrorImpl);
  exports["throw"] = $$throw;
  exports["showError"] = showError;
  exports["error"] = $foreign.error;
  exports["throwException"] = $foreign.throwException;
})(PS["Effect.Exception"] = PS["Effect.Exception"] || {});

(function (exports) {
  // Generated by purs version 0.12.3
  "use strict";

  var Control_Applicative = PS["Control.Applicative"];
  var Control_Bind = PS["Control.Bind"];
  var Control_Semigroupoid = PS["Control.Semigroupoid"];
  var Data_Either = PS["Data.Either"];
  var Data_Function = PS["Data.Function"];
  var Data_Functor = PS["Data.Functor"];
  var Data_Maybe = PS["Data.Maybe"];
  var Data_Unit = PS["Data.Unit"];
  var Effect = PS["Effect"];
  var Effect_Exception = PS["Effect.Exception"];
  var Prelude = PS["Prelude"];

  var MonadThrow = function MonadThrow(Monad0, throwError) {
    this.Monad0 = Monad0;
    this.throwError = throwError;
  };

  var MonadError = function MonadError(MonadThrow0, catchError) {
    this.MonadThrow0 = MonadThrow0;
    this.catchError = catchError;
  };

  var throwError = function throwError(dict) {
    return dict.throwError;
  };

  var monadThrowEffect = new MonadThrow(function () {
    return Effect.monadEffect;
  }, Effect_Exception.throwException);

  var catchError = function catchError(dict) {
    return dict.catchError;
  };

  var $$try = function $$try(dictMonadError) {
    return function (a) {
      return catchError(dictMonadError)(Data_Functor.map(dictMonadError.MonadThrow0().Monad0().Bind1().Apply0().Functor0())(Data_Either.Right.create)(a))(function ($21) {
        return Control_Applicative.pure(dictMonadError.MonadThrow0().Monad0().Applicative0())(Data_Either.Left.create($21));
      });
    };
  };

  exports["catchError"] = catchError;
  exports["throwError"] = throwError;
  exports["MonadThrow"] = MonadThrow;
  exports["MonadError"] = MonadError;
  exports["try"] = $$try;
  exports["monadThrowEffect"] = monadThrowEffect;
})(PS["Control.Monad.Error.Class"] = PS["Control.Monad.Error.Class"] || {});

(function (exports) {
  // Generated by purs version 0.12.3
  "use strict";

  var TypeEquals = function TypeEquals(from, to) {
    this.from = from;
    this.to = to;
  };

  var to = function to(dict) {
    return dict.to;
  };

  var refl = new TypeEquals(function (a) {
    return a;
  }, function (a) {
    return a;
  });

  var from = function from(dict) {
    return dict.from;
  };

  exports["TypeEquals"] = TypeEquals;
  exports["to"] = to;
  exports["from"] = from;
  exports["refl"] = refl;
})(PS["Type.Equality"] = PS["Type.Equality"] || {});

(function (exports) {
  // Generated by purs version 0.12.3
  "use strict";

  var Control_Applicative = PS["Control.Applicative"];
  var Control_Apply = PS["Control.Apply"];
  var Control_Biapplicative = PS["Control.Biapplicative"];
  var Control_Biapply = PS["Control.Biapply"];
  var Control_Bind = PS["Control.Bind"];
  var Control_Comonad = PS["Control.Comonad"];
  var Control_Extend = PS["Control.Extend"];
  var Control_Lazy = PS["Control.Lazy"];
  var Control_Monad = PS["Control.Monad"];
  var Control_Semigroupoid = PS["Control.Semigroupoid"];
  var Data_Bifoldable = PS["Data.Bifoldable"];
  var Data_Bifunctor = PS["Data.Bifunctor"];
  var Data_Bitraversable = PS["Data.Bitraversable"];
  var Data_BooleanAlgebra = PS["Data.BooleanAlgebra"];
  var Data_Bounded = PS["Data.Bounded"];
  var Data_CommutativeRing = PS["Data.CommutativeRing"];
  var Data_Distributive = PS["Data.Distributive"];
  var Data_Eq = PS["Data.Eq"];
  var Data_Foldable = PS["Data.Foldable"];
  var Data_FoldableWithIndex = PS["Data.FoldableWithIndex"];
  var Data_Function = PS["Data.Function"];
  var Data_Functor = PS["Data.Functor"];
  var Data_Functor_Invariant = PS["Data.Functor.Invariant"];
  var Data_FunctorWithIndex = PS["Data.FunctorWithIndex"];
  var Data_HeytingAlgebra = PS["Data.HeytingAlgebra"];
  var Data_Maybe = PS["Data.Maybe"];
  var Data_Maybe_First = PS["Data.Maybe.First"];
  var Data_Monoid = PS["Data.Monoid"];
  var Data_Newtype = PS["Data.Newtype"];
  var Data_Ord = PS["Data.Ord"];
  var Data_Ordering = PS["Data.Ordering"];
  var Data_Ring = PS["Data.Ring"];
  var Data_Semigroup = PS["Data.Semigroup"];
  var Data_Semigroup_Foldable = PS["Data.Semigroup.Foldable"];
  var Data_Semigroup_Traversable = PS["Data.Semigroup.Traversable"];
  var Data_Semiring = PS["Data.Semiring"];
  var Data_Show = PS["Data.Show"];
  var Data_Traversable = PS["Data.Traversable"];
  var Data_TraversableWithIndex = PS["Data.TraversableWithIndex"];
  var Data_Unit = PS["Data.Unit"];
  var Prelude = PS["Prelude"];
  var Type_Equality = PS["Type.Equality"];

  var Tuple = function () {
    function Tuple(value0, value1) {
      this.value0 = value0;
      this.value1 = value1;
    }

    ;

    Tuple.create = function (value0) {
      return function (value1) {
        return new Tuple(value0, value1);
      };
    };

    return Tuple;
  }();

  var uncurry = function uncurry(f) {
    return function (v) {
      return f(v.value0)(v.value1);
    };
  };

  var snd = function snd(v) {
    return v.value1;
  };

  var lookup = function lookup(dictFoldable) {
    return function (dictEq) {
      return function (a) {
        return function ($312) {
          return Data_Newtype.unwrap(Data_Maybe_First.newtypeFirst)(Data_Foldable.foldMap(dictFoldable)(Data_Maybe_First.monoidFirst)(function (v) {
            var $163 = Data_Eq.eq(dictEq)(a)(v.value0);

            if ($163) {
              return new Data_Maybe.Just(v.value1);
            }

            ;
            return Data_Maybe.Nothing.value;
          })($312));
        };
      };
    };
  };

  var fst = function fst(v) {
    return v.value0;
  };

  var bifunctorTuple = new Data_Bifunctor.Bifunctor(function (f) {
    return function (g) {
      return function (v) {
        return new Tuple(f(v.value0), g(v.value1));
      };
    };
  });
  exports["Tuple"] = Tuple;
  exports["fst"] = fst;
  exports["snd"] = snd;
  exports["uncurry"] = uncurry;
  exports["lookup"] = lookup;
  exports["bifunctorTuple"] = bifunctorTuple;
})(PS["Data.Tuple"] = PS["Data.Tuple"] || {});

(function (exports) {
  // Generated by purs version 0.12.3
  "use strict";

  var Data_Tuple = PS["Data.Tuple"];
  var Data_Unit = PS["Data.Unit"];
  var Prelude = PS["Prelude"];

  var MonadState = function MonadState(Monad0, state) {
    this.Monad0 = Monad0;
    this.state = state;
  };

  var state = function state(dict) {
    return dict.state;
  };

  var modify_ = function modify_(dictMonadState) {
    return function (f) {
      return state(dictMonadState)(function (s) {
        return new Data_Tuple.Tuple(Data_Unit.unit, f(s));
      });
    };
  };

  var gets = function gets(dictMonadState) {
    return function (f) {
      return state(dictMonadState)(function (s) {
        return new Data_Tuple.Tuple(f(s), s);
      });
    };
  };

  exports["state"] = state;
  exports["MonadState"] = MonadState;
  exports["gets"] = gets;
  exports["modify_"] = modify_;
})(PS["Control.Monad.State.Class"] = PS["Control.Monad.State.Class"] || {});

(function (exports) {
  // Generated by purs version 0.12.3
  "use strict";

  var Prelude = PS["Prelude"];

  var MonadTrans = function MonadTrans(lift) {
    this.lift = lift;
  };

  var lift = function lift(dict) {
    return dict.lift;
  };

  exports["lift"] = lift;
  exports["MonadTrans"] = MonadTrans;
})(PS["Control.Monad.Trans.Class"] = PS["Control.Monad.Trans.Class"] || {});

(function (exports) {
  // Generated by purs version 0.12.3
  "use strict";

  var Control_Category = PS["Control.Category"];
  var Control_Monad = PS["Control.Monad"];
  var Effect = PS["Effect"];

  var MonadEffect = function MonadEffect(Monad0, liftEffect) {
    this.Monad0 = Monad0;
    this.liftEffect = liftEffect;
  };

  var monadEffectEffect = new MonadEffect(function () {
    return Effect.monadEffect;
  }, Control_Category.identity(Control_Category.categoryFn));

  var liftEffect = function liftEffect(dict) {
    return dict.liftEffect;
  };

  exports["liftEffect"] = liftEffect;
  exports["MonadEffect"] = MonadEffect;
  exports["monadEffectEffect"] = monadEffectEffect;
})(PS["Effect.Class"] = PS["Effect.Class"] || {});

(function (exports) {
  // Generated by purs version 0.12.3
  "use strict";

  var Control_Alt = PS["Control.Alt"];
  var Control_Alternative = PS["Control.Alternative"];
  var Control_Applicative = PS["Control.Applicative"];
  var Control_Apply = PS["Control.Apply"];
  var Control_Bind = PS["Control.Bind"];
  var Control_Category = PS["Control.Category"];
  var Control_Monad = PS["Control.Monad"];
  var Control_Monad_Cont_Class = PS["Control.Monad.Cont.Class"];
  var Control_Monad_Error_Class = PS["Control.Monad.Error.Class"];
  var Control_Monad_Reader_Class = PS["Control.Monad.Reader.Class"];
  var Control_Monad_Rec_Class = PS["Control.Monad.Rec.Class"];
  var Control_Monad_State_Class = PS["Control.Monad.State.Class"];
  var Control_Monad_Trans_Class = PS["Control.Monad.Trans.Class"];
  var Control_Monad_Writer_Class = PS["Control.Monad.Writer.Class"];
  var Control_MonadPlus = PS["Control.MonadPlus"];
  var Control_MonadZero = PS["Control.MonadZero"];
  var Control_Plus = PS["Control.Plus"];
  var Control_Semigroupoid = PS["Control.Semigroupoid"];
  var Data_Either = PS["Data.Either"];
  var Data_Function = PS["Data.Function"];
  var Data_Functor = PS["Data.Functor"];
  var Data_Monoid = PS["Data.Monoid"];
  var Data_Newtype = PS["Data.Newtype"];
  var Data_Semigroup = PS["Data.Semigroup"];
  var Data_Tuple = PS["Data.Tuple"];
  var Effect_Class = PS["Effect.Class"];
  var Prelude = PS["Prelude"];

  var ExceptT = function ExceptT(x) {
    return x;
  };

  var withExceptT = function withExceptT(dictFunctor) {
    return function (f) {
      return function (v) {
        var mapLeft = function mapLeft(v1) {
          return function (v2) {
            if (v2 instanceof Data_Either.Right) {
              return new Data_Either.Right(v2.value0);
            }

            ;

            if (v2 instanceof Data_Either.Left) {
              return new Data_Either.Left(v1(v2.value0));
            }

            ;
            throw new Error("Failed pattern match at Control.Monad.Except.Trans (line 42, column 3 - line 42, column 32): " + [v1.constructor.name, v2.constructor.name]);
          };
        };

        return ExceptT(Data_Functor.map(dictFunctor)(mapLeft(f))(v));
      };
    };
  };

  var runExceptT = function runExceptT(v) {
    return v;
  };

  var monadTransExceptT = new Control_Monad_Trans_Class.MonadTrans(function (dictMonad) {
    return function (m) {
      return Control_Bind.bind(dictMonad.Bind1())(m)(function (v) {
        return Control_Applicative.pure(dictMonad.Applicative0())(new Data_Either.Right(v));
      });
    };
  });

  var mapExceptT = function mapExceptT(f) {
    return function (v) {
      return f(v);
    };
  };

  var functorExceptT = function functorExceptT(dictFunctor) {
    return new Data_Functor.Functor(function (f) {
      return mapExceptT(Data_Functor.map(dictFunctor)(Data_Functor.map(Data_Either.functorEither)(f)));
    });
  };

  var except = function except(dictApplicative) {
    return function ($96) {
      return ExceptT(Control_Applicative.pure(dictApplicative)($96));
    };
  };

  var monadExceptT = function monadExceptT(dictMonad) {
    return new Control_Monad.Monad(function () {
      return applicativeExceptT(dictMonad);
    }, function () {
      return bindExceptT(dictMonad);
    });
  };

  var bindExceptT = function bindExceptT(dictMonad) {
    return new Control_Bind.Bind(function () {
      return applyExceptT(dictMonad);
    }, function (v) {
      return function (k) {
        return Control_Bind.bind(dictMonad.Bind1())(v)(Data_Either.either(function ($97) {
          return Control_Applicative.pure(dictMonad.Applicative0())(Data_Either.Left.create($97));
        })(function (a) {
          var v1 = k(a);
          return v1;
        }));
      };
    });
  };

  var applyExceptT = function applyExceptT(dictMonad) {
    return new Control_Apply.Apply(function () {
      return functorExceptT(dictMonad.Bind1().Apply0().Functor0());
    }, Control_Monad.ap(monadExceptT(dictMonad)));
  };

  var applicativeExceptT = function applicativeExceptT(dictMonad) {
    return new Control_Applicative.Applicative(function () {
      return applyExceptT(dictMonad);
    }, function ($98) {
      return ExceptT(Control_Applicative.pure(dictMonad.Applicative0())(Data_Either.Right.create($98)));
    });
  };

  var monadStateExceptT = function monadStateExceptT(dictMonadState) {
    return new Control_Monad_State_Class.MonadState(function () {
      return monadExceptT(dictMonadState.Monad0());
    }, function (f) {
      return Control_Monad_Trans_Class.lift(monadTransExceptT)(dictMonadState.Monad0())(Control_Monad_State_Class.state(dictMonadState)(f));
    });
  };

  var monadThrowExceptT = function monadThrowExceptT(dictMonad) {
    return new Control_Monad_Error_Class.MonadThrow(function () {
      return monadExceptT(dictMonad);
    }, function ($102) {
      return ExceptT(Control_Applicative.pure(dictMonad.Applicative0())(Data_Either.Left.create($102)));
    });
  };

  var altExceptT = function altExceptT(dictSemigroup) {
    return function (dictMonad) {
      return new Control_Alt.Alt(function () {
        return functorExceptT(dictMonad.Bind1().Apply0().Functor0());
      }, function (v) {
        return function (v1) {
          return Control_Bind.bind(dictMonad.Bind1())(v)(function (v2) {
            if (v2 instanceof Data_Either.Right) {
              return Control_Applicative.pure(dictMonad.Applicative0())(new Data_Either.Right(v2.value0));
            }

            ;

            if (v2 instanceof Data_Either.Left) {
              return Control_Bind.bind(dictMonad.Bind1())(v1)(function (v3) {
                if (v3 instanceof Data_Either.Right) {
                  return Control_Applicative.pure(dictMonad.Applicative0())(new Data_Either.Right(v3.value0));
                }

                ;

                if (v3 instanceof Data_Either.Left) {
                  return Control_Applicative.pure(dictMonad.Applicative0())(new Data_Either.Left(Data_Semigroup.append(dictSemigroup)(v2.value0)(v3.value0)));
                }

                ;
                throw new Error("Failed pattern match at Control.Monad.Except.Trans (line 86, column 9 - line 88, column 49): " + [v3.constructor.name]);
              });
            }

            ;
            throw new Error("Failed pattern match at Control.Monad.Except.Trans (line 82, column 5 - line 88, column 49): " + [v2.constructor.name]);
          });
        };
      });
    };
  };

  exports["ExceptT"] = ExceptT;
  exports["runExceptT"] = runExceptT;
  exports["withExceptT"] = withExceptT;
  exports["mapExceptT"] = mapExceptT;
  exports["except"] = except;
  exports["functorExceptT"] = functorExceptT;
  exports["applyExceptT"] = applyExceptT;
  exports["applicativeExceptT"] = applicativeExceptT;
  exports["bindExceptT"] = bindExceptT;
  exports["monadExceptT"] = monadExceptT;
  exports["altExceptT"] = altExceptT;
  exports["monadTransExceptT"] = monadTransExceptT;
  exports["monadThrowExceptT"] = monadThrowExceptT;
  exports["monadStateExceptT"] = monadStateExceptT;
})(PS["Control.Monad.Except.Trans"] = PS["Control.Monad.Except.Trans"] || {});

(function (exports) {
  // Generated by purs version 0.12.3
  "use strict";

  var Control_Alt = PS["Control.Alt"];
  var Control_Applicative = PS["Control.Applicative"];
  var Control_Apply = PS["Control.Apply"];
  var Control_Bind = PS["Control.Bind"];
  var Control_Comonad = PS["Control.Comonad"];
  var Control_Extend = PS["Control.Extend"];
  var Control_Lazy = PS["Control.Lazy"];
  var Control_Monad = PS["Control.Monad"];
  var Data_BooleanAlgebra = PS["Data.BooleanAlgebra"];
  var Data_Bounded = PS["Data.Bounded"];
  var Data_CommutativeRing = PS["Data.CommutativeRing"];
  var Data_Eq = PS["Data.Eq"];
  var Data_EuclideanRing = PS["Data.EuclideanRing"];
  var Data_Foldable = PS["Data.Foldable"];
  var Data_FoldableWithIndex = PS["Data.FoldableWithIndex"];
  var Data_Functor = PS["Data.Functor"];
  var Data_Functor_Invariant = PS["Data.Functor.Invariant"];
  var Data_FunctorWithIndex = PS["Data.FunctorWithIndex"];
  var Data_HeytingAlgebra = PS["Data.HeytingAlgebra"];
  var Data_Monoid = PS["Data.Monoid"];
  var Data_Newtype = PS["Data.Newtype"];
  var Data_Ord = PS["Data.Ord"];
  var Data_Ring = PS["Data.Ring"];
  var Data_Semigroup = PS["Data.Semigroup"];
  var Data_Semigroup_Foldable = PS["Data.Semigroup.Foldable"];
  var Data_Semigroup_Traversable = PS["Data.Semigroup.Traversable"];
  var Data_Semiring = PS["Data.Semiring"];
  var Data_Show = PS["Data.Show"];
  var Data_Traversable = PS["Data.Traversable"];
  var Data_TraversableWithIndex = PS["Data.TraversableWithIndex"];
  var Data_Unit = PS["Data.Unit"];
  var Prelude = PS["Prelude"];

  var Identity = function Identity(x) {
    return x;
  };

  var newtypeIdentity = new Data_Newtype.Newtype(function (n) {
    return n;
  }, Identity);
  var functorIdentity = new Data_Functor.Functor(function (f) {
    return function (m) {
      return f(m);
    };
  });
  var applyIdentity = new Control_Apply.Apply(function () {
    return functorIdentity;
  }, function (v) {
    return function (v1) {
      return v(v1);
    };
  });
  var bindIdentity = new Control_Bind.Bind(function () {
    return applyIdentity;
  }, function (v) {
    return function (f) {
      return f(v);
    };
  });
  var applicativeIdentity = new Control_Applicative.Applicative(function () {
    return applyIdentity;
  }, Identity);
  var monadIdentity = new Control_Monad.Monad(function () {
    return applicativeIdentity;
  }, function () {
    return bindIdentity;
  });
  exports["Identity"] = Identity;
  exports["newtypeIdentity"] = newtypeIdentity;
  exports["functorIdentity"] = functorIdentity;
  exports["applyIdentity"] = applyIdentity;
  exports["applicativeIdentity"] = applicativeIdentity;
  exports["bindIdentity"] = bindIdentity;
  exports["monadIdentity"] = monadIdentity;
})(PS["Data.Identity"] = PS["Data.Identity"] || {});

(function (exports) {
  // Generated by purs version 0.12.3
  "use strict";

  var Control_Monad_Error_Class = PS["Control.Monad.Error.Class"];
  var Control_Monad_Except_Trans = PS["Control.Monad.Except.Trans"];
  var Control_Semigroupoid = PS["Control.Semigroupoid"];
  var Data_Either = PS["Data.Either"];
  var Data_Identity = PS["Data.Identity"];
  var Data_Newtype = PS["Data.Newtype"];
  var Prelude = PS["Prelude"];
  var withExcept = Control_Monad_Except_Trans.withExceptT(Data_Identity.functorIdentity);

  var runExcept = function runExcept($0) {
    return Data_Newtype.unwrap(Data_Identity.newtypeIdentity)(Control_Monad_Except_Trans.runExceptT($0));
  };

  exports["runExcept"] = runExcept;
  exports["withExcept"] = withExcept;
})(PS["Control.Monad.Except"] = PS["Control.Monad.Except"] || {});

(function (exports) {
  "use strict";

  exports.map_ = function (f) {
    return function (a) {
      return function () {
        return f(a());
      };
    };
  };

  exports.pure_ = function (a) {
    return function () {
      return a;
    };
  };

  exports.bind_ = function (a) {
    return function (f) {
      return function () {
        return f(a())();
      };
    };
  };
})(PS["Control.Monad.ST.Internal"] = PS["Control.Monad.ST.Internal"] || {});

(function (exports) {
  "use strict"; // module Partial.Unsafe

  exports.unsafePartial = function (f) {
    return f();
  };
})(PS["Partial.Unsafe"] = PS["Partial.Unsafe"] || {});

(function (exports) {
  "use strict"; // module Partial

  exports.crashWith = function () {
    return function (msg) {
      throw new Error(msg);
    };
  };
})(PS["Partial"] = PS["Partial"] || {});

(function (exports) {
  // Generated by purs version 0.12.3
  "use strict";

  var $foreign = PS["Partial"];
  exports["crashWith"] = $foreign.crashWith;
})(PS["Partial"] = PS["Partial"] || {});

(function (exports) {
  // Generated by purs version 0.12.3
  "use strict";

  var $foreign = PS["Partial.Unsafe"];
  var Partial = PS["Partial"];

  var unsafeCrashWith = function unsafeCrashWith(msg) {
    return $foreign.unsafePartial(function (dictPartial) {
      return Partial.crashWith(dictPartial)(msg);
    });
  };

  exports["unsafeCrashWith"] = unsafeCrashWith;
})(PS["Partial.Unsafe"] = PS["Partial.Unsafe"] || {});

(function (exports) {
  // Generated by purs version 0.12.3
  "use strict";

  var $foreign = PS["Control.Monad.ST.Internal"];
  var Control_Applicative = PS["Control.Applicative"];
  var Control_Apply = PS["Control.Apply"];
  var Control_Bind = PS["Control.Bind"];
  var Control_Monad = PS["Control.Monad"];
  var Control_Monad_Rec_Class = PS["Control.Monad.Rec.Class"];
  var Data_Functor = PS["Data.Functor"];
  var Data_Unit = PS["Data.Unit"];
  var Partial_Unsafe = PS["Partial.Unsafe"];
  var Prelude = PS["Prelude"];
  var functorST = new Data_Functor.Functor($foreign.map_);
  var monadST = new Control_Monad.Monad(function () {
    return applicativeST;
  }, function () {
    return bindST;
  });
  var bindST = new Control_Bind.Bind(function () {
    return applyST;
  }, $foreign.bind_);
  var applyST = new Control_Apply.Apply(function () {
    return functorST;
  }, Control_Monad.ap(monadST));
  var applicativeST = new Control_Applicative.Applicative(function () {
    return applyST;
  }, $foreign.pure_);
  exports["functorST"] = functorST;
  exports["applyST"] = applyST;
  exports["applicativeST"] = applicativeST;
  exports["bindST"] = bindST;
  exports["monadST"] = monadST;
})(PS["Control.Monad.ST.Internal"] = PS["Control.Monad.ST.Internal"] || {});

(function (exports) {
  // Generated by purs version 0.12.3
  "use strict";

  var Control_Alt = PS["Control.Alt"];
  var Control_Alternative = PS["Control.Alternative"];
  var Control_Applicative = PS["Control.Applicative"];
  var Control_Apply = PS["Control.Apply"];
  var Control_Bind = PS["Control.Bind"];
  var Control_Lazy = PS["Control.Lazy"];
  var Control_Monad = PS["Control.Monad"];
  var Control_Monad_Cont_Class = PS["Control.Monad.Cont.Class"];
  var Control_Monad_Error_Class = PS["Control.Monad.Error.Class"];
  var Control_Monad_Reader_Class = PS["Control.Monad.Reader.Class"];
  var Control_Monad_Rec_Class = PS["Control.Monad.Rec.Class"];
  var Control_Monad_State_Class = PS["Control.Monad.State.Class"];
  var Control_Monad_Trans_Class = PS["Control.Monad.Trans.Class"];
  var Control_Monad_Writer_Class = PS["Control.Monad.Writer.Class"];
  var Control_MonadPlus = PS["Control.MonadPlus"];
  var Control_MonadZero = PS["Control.MonadZero"];
  var Control_Plus = PS["Control.Plus"];
  var Control_Semigroupoid = PS["Control.Semigroupoid"];
  var Data_Function = PS["Data.Function"];
  var Data_Functor = PS["Data.Functor"];
  var Data_Newtype = PS["Data.Newtype"];
  var Data_Tuple = PS["Data.Tuple"];
  var Data_Unit = PS["Data.Unit"];
  var Effect_Class = PS["Effect.Class"];
  var Prelude = PS["Prelude"];

  var StateT = function StateT(x) {
    return x;
  };

  var runStateT = function runStateT(v) {
    return v;
  };

  var lazyStateT = new Control_Lazy.Lazy(function (f) {
    return function (s) {
      var v = f(Data_Unit.unit);
      return v(s);
    };
  });

  var functorStateT = function functorStateT(dictFunctor) {
    return new Data_Functor.Functor(function (f) {
      return function (v) {
        return function (s) {
          return Data_Functor.map(dictFunctor)(function (v1) {
            return new Data_Tuple.Tuple(f(v1.value0), v1.value1);
          })(v(s));
        };
      };
    });
  };

  var evalStateT = function evalStateT(dictFunctor) {
    return function (v) {
      return function (s) {
        return Data_Functor.map(dictFunctor)(Data_Tuple.fst)(v(s));
      };
    };
  };

  var monadStateT = function monadStateT(dictMonad) {
    return new Control_Monad.Monad(function () {
      return applicativeStateT(dictMonad);
    }, function () {
      return bindStateT(dictMonad);
    });
  };

  var bindStateT = function bindStateT(dictMonad) {
    return new Control_Bind.Bind(function () {
      return applyStateT(dictMonad);
    }, function (v) {
      return function (f) {
        return function (s) {
          return Control_Bind.bind(dictMonad.Bind1())(v(s))(function (v1) {
            var v3 = f(v1.value0);
            return v3(v1.value1);
          });
        };
      };
    });
  };

  var applyStateT = function applyStateT(dictMonad) {
    return new Control_Apply.Apply(function () {
      return functorStateT(dictMonad.Bind1().Apply0().Functor0());
    }, Control_Monad.ap(monadStateT(dictMonad)));
  };

  var applicativeStateT = function applicativeStateT(dictMonad) {
    return new Control_Applicative.Applicative(function () {
      return applyStateT(dictMonad);
    }, function (a) {
      return function (s) {
        return Control_Applicative.pure(dictMonad.Applicative0())(new Data_Tuple.Tuple(a, s));
      };
    });
  };

  var monadStateStateT = function monadStateStateT(dictMonad) {
    return new Control_Monad_State_Class.MonadState(function () {
      return monadStateT(dictMonad);
    }, function (f) {
      return StateT(function ($111) {
        return Control_Applicative.pure(dictMonad.Applicative0())(f($111));
      });
    });
  };

  exports["StateT"] = StateT;
  exports["runStateT"] = runStateT;
  exports["evalStateT"] = evalStateT;
  exports["functorStateT"] = functorStateT;
  exports["applyStateT"] = applyStateT;
  exports["applicativeStateT"] = applicativeStateT;
  exports["bindStateT"] = bindStateT;
  exports["monadStateT"] = monadStateT;
  exports["lazyStateT"] = lazyStateT;
  exports["monadStateStateT"] = monadStateStateT;
})(PS["Control.Monad.State.Trans"] = PS["Control.Monad.State.Trans"] || {});

(function (exports) {
  "use strict";

  exports.new = function (val) {
    return function () {
      return {
        value: val
      };
    };
  };

  exports.read = function (ref) {
    return function () {
      return ref.value;
    };
  };

  exports["modify'"] = function (f) {
    return function (ref) {
      return function () {
        var t = f(ref.value);
        ref.value = t.state;
        return t.value;
      };
    };
  };

  exports.write = function (val) {
    return function (ref) {
      return function () {
        ref.value = val;
        return {};
      };
    };
  };
})(PS["Effect.Ref"] = PS["Effect.Ref"] || {});

(function (exports) {
  // Generated by purs version 0.12.3
  "use strict";

  var $foreign = PS["Effect.Ref"];
  var Data_Function = PS["Data.Function"];
  var Data_Functor = PS["Data.Functor"];
  var Effect = PS["Effect"];
  var Prelude = PS["Prelude"];

  var modify = function modify(f) {
    return $foreign["modify'"](function (s) {
      var s$prime = f(s);
      return {
        state: s$prime,
        value: s$prime
      };
    });
  };

  var modify_ = function modify_(f) {
    return function (s) {
      return Data_Functor["void"](Effect.functorEffect)(modify(f)(s));
    };
  };

  exports["modify"] = modify;
  exports["modify_"] = modify_;
  exports["new"] = $foreign["new"];
  exports["read"] = $foreign.read;
  exports["write"] = $foreign.write;
})(PS["Effect.Ref"] = PS["Effect.Ref"] || {});

(function (exports) {
  // Generated by purs version 0.12.3
  "use strict";

  var Control_Alt = PS["Control.Alt"];
  var Control_Alternative = PS["Control.Alternative"];
  var Control_Applicative = PS["Control.Applicative"];
  var Control_Apply = PS["Control.Apply"];
  var Control_Bind = PS["Control.Bind"];
  var Control_Monad_Cont_Trans = PS["Control.Monad.Cont.Trans"];
  var Control_Monad_Except_Trans = PS["Control.Monad.Except.Trans"];
  var Control_Monad_Maybe_Trans = PS["Control.Monad.Maybe.Trans"];
  var Control_Monad_Reader_Trans = PS["Control.Monad.Reader.Trans"];
  var Control_Monad_Writer_Trans = PS["Control.Monad.Writer.Trans"];
  var Control_Plus = PS["Control.Plus"];
  var Control_Semigroupoid = PS["Control.Semigroupoid"];
  var Data_Either = PS["Data.Either"];
  var Data_Function = PS["Data.Function"];
  var Data_Functor = PS["Data.Functor"];
  var Data_Functor_Compose = PS["Data.Functor.Compose"];
  var Data_Maybe = PS["Data.Maybe"];
  var Data_Newtype = PS["Data.Newtype"];
  var Data_Unit = PS["Data.Unit"];
  var Effect_Class = PS["Effect.Class"];
  var Effect_Ref = PS["Effect.Ref"];
  var Prelude = PS["Prelude"];

  var Parallel = function Parallel(Applicative1, Monad0, parallel, sequential) {
    this.Applicative1 = Applicative1;
    this.Monad0 = Monad0;
    this.parallel = parallel;
    this.sequential = sequential;
  };

  var sequential = function sequential(dict) {
    return dict.sequential;
  };

  var parallel = function parallel(dict) {
    return dict.parallel;
  };

  exports["parallel"] = parallel;
  exports["sequential"] = sequential;
  exports["Parallel"] = Parallel;
})(PS["Control.Parallel.Class"] = PS["Control.Parallel.Class"] || {});

(function (exports) {
  // Generated by purs version 0.12.3
  "use strict";

  var Control_Alternative = PS["Control.Alternative"];
  var Control_Apply = PS["Control.Apply"];
  var Control_Category = PS["Control.Category"];
  var Control_Parallel_Class = PS["Control.Parallel.Class"];
  var Control_Semigroupoid = PS["Control.Semigroupoid"];
  var Data_Foldable = PS["Data.Foldable"];
  var Data_Traversable = PS["Data.Traversable"];
  var Prelude = PS["Prelude"];

  var parTraverse_ = function parTraverse_(dictParallel) {
    return function (dictFoldable) {
      return function (f) {
        return function ($17) {
          return Control_Parallel_Class.sequential(dictParallel)(Data_Foldable.traverse_(dictParallel.Applicative1())(dictFoldable)(function ($18) {
            return Control_Parallel_Class.parallel(dictParallel)(f($18));
          })($17));
        };
      };
    };
  };

  var parSequence_ = function parSequence_(dictParallel) {
    return function (dictFoldable) {
      return parTraverse_(dictParallel)(dictFoldable)(Control_Category.identity(Control_Category.categoryFn));
    };
  };

  exports["parTraverse_"] = parTraverse_;
  exports["parSequence_"] = parSequence_;
})(PS["Control.Parallel"] = PS["Control.Parallel"] || {});

(function (exports) {
  // module Control.Promise
  exports.promise = function (f) {
    return function () {
      return new Promise(function (success, error) {
        var succF = function succF(s) {
          return function () {
            return success(s);
          };
        };

        var failF = function failF(s) {
          return function () {
            return error(s);
          };
        }; // This indicates the aff was wrong?


        try {
          f(succF)(failF)();
        } catch (e) {
          error(e);
        }
      });
    };
  };

  exports.thenImpl = function (promise) {
    return function (errCB) {
      return function (succCB) {
        return function () {
          promise.then(succCB, errCB);
        };
      };
    };
  };
})(PS["Control.Promise"] = PS["Control.Promise"] || {});

(function (exports) {
  "use strict";

  exports.unfoldrArrayImpl = function (isNothing) {
    return function (fromJust) {
      return function (fst) {
        return function (snd) {
          return function (f) {
            return function (b) {
              var result = [];
              var value = b;

              while (true) {
                // eslint-disable-line no-constant-condition
                var maybe = f(value);
                if (isNothing(maybe)) return result;
                var tuple = fromJust(maybe);
                result.push(fst(tuple));
                value = snd(tuple);
              }
            };
          };
        };
      };
    };
  };
})(PS["Data.Unfoldable"] = PS["Data.Unfoldable"] || {});

(function (exports) {
  "use strict";

  exports.unfoldr1ArrayImpl = function (isNothing) {
    return function (fromJust) {
      return function (fst) {
        return function (snd) {
          return function (f) {
            return function (b) {
              var result = [];
              var value = b;

              while (true) {
                // eslint-disable-line no-constant-condition
                var tuple = f(value);
                result.push(fst(tuple));
                var maybe = snd(tuple);
                if (isNothing(maybe)) return result;
                value = fromJust(maybe);
              }
            };
          };
        };
      };
    };
  };
})(PS["Data.Unfoldable1"] = PS["Data.Unfoldable1"] || {});

(function (exports) {
  // Generated by purs version 0.12.3
  "use strict";

  var $foreign = PS["Data.Unfoldable1"];
  var Data_Boolean = PS["Data.Boolean"];
  var Data_Eq = PS["Data.Eq"];
  var Data_Maybe = PS["Data.Maybe"];
  var Data_Ord = PS["Data.Ord"];
  var Data_Ring = PS["Data.Ring"];
  var Data_Semigroup_Traversable = PS["Data.Semigroup.Traversable"];
  var Data_Semiring = PS["Data.Semiring"];
  var Data_Tuple = PS["Data.Tuple"];
  var Partial_Unsafe = PS["Partial.Unsafe"];
  var Prelude = PS["Prelude"];

  var Unfoldable1 = function Unfoldable1(unfoldr1) {
    this.unfoldr1 = unfoldr1;
  };

  var unfoldr1 = function unfoldr1(dict) {
    return dict.unfoldr1;
  };

  var unfoldable1Array = new Unfoldable1($foreign.unfoldr1ArrayImpl(Data_Maybe.isNothing)(Data_Maybe.fromJust())(Data_Tuple.fst)(Data_Tuple.snd));
  exports["Unfoldable1"] = Unfoldable1;
  exports["unfoldr1"] = unfoldr1;
  exports["unfoldable1Array"] = unfoldable1Array;
})(PS["Data.Unfoldable1"] = PS["Data.Unfoldable1"] || {});

(function (exports) {
  // Generated by purs version 0.12.3
  "use strict";

  var $foreign = PS["Data.Unfoldable"];
  var Data_Function = PS["Data.Function"];
  var Data_Functor = PS["Data.Functor"];
  var Data_Maybe = PS["Data.Maybe"];
  var Data_Ord = PS["Data.Ord"];
  var Data_Ring = PS["Data.Ring"];
  var Data_Traversable = PS["Data.Traversable"];
  var Data_Tuple = PS["Data.Tuple"];
  var Data_Unfoldable1 = PS["Data.Unfoldable1"];
  var Data_Unit = PS["Data.Unit"];
  var Partial_Unsafe = PS["Partial.Unsafe"];
  var Prelude = PS["Prelude"];

  var Unfoldable = function Unfoldable(Unfoldable10, unfoldr) {
    this.Unfoldable10 = Unfoldable10;
    this.unfoldr = unfoldr;
  };

  var unfoldr = function unfoldr(dict) {
    return dict.unfoldr;
  };

  var unfoldableArray = new Unfoldable(function () {
    return Data_Unfoldable1.unfoldable1Array;
  }, $foreign.unfoldrArrayImpl(Data_Maybe.isNothing)(Data_Maybe.fromJust())(Data_Tuple.fst)(Data_Tuple.snd));

  var replicate = function replicate(dictUnfoldable) {
    return function (n) {
      return function (v) {
        var step = function step(i) {
          var $7 = i <= 0;

          if ($7) {
            return Data_Maybe.Nothing.value;
          }

          ;
          return new Data_Maybe.Just(new Data_Tuple.Tuple(v, i - 1 | 0));
        };

        return unfoldr(dictUnfoldable)(step)(n);
      };
    };
  };

  exports["Unfoldable"] = Unfoldable;
  exports["unfoldr"] = unfoldr;
  exports["replicate"] = replicate;
  exports["unfoldableArray"] = unfoldableArray;
})(PS["Data.Unfoldable"] = PS["Data.Unfoldable"] || {});

(function (exports) {
  // Generated by purs version 0.12.3
  "use strict";

  var Control_Alt = PS["Control.Alt"];
  var Control_Alternative = PS["Control.Alternative"];
  var Control_Applicative = PS["Control.Applicative"];
  var Control_Apply = PS["Control.Apply"];
  var Control_Category = PS["Control.Category"];
  var Control_Plus = PS["Control.Plus"];
  var Control_Semigroupoid = PS["Control.Semigroupoid"];
  var Data_Eq = PS["Data.Eq"];
  var Data_Foldable = PS["Data.Foldable"];
  var Data_FoldableWithIndex = PS["Data.FoldableWithIndex"];
  var Data_Function = PS["Data.Function"];
  var Data_Functor = PS["Data.Functor"];
  var Data_FunctorWithIndex = PS["Data.FunctorWithIndex"];
  var Data_HeytingAlgebra = PS["Data.HeytingAlgebra"];
  var Data_Maybe = PS["Data.Maybe"];
  var Data_Ord = PS["Data.Ord"];
  var Data_Ordering = PS["Data.Ordering"];
  var Data_Semigroup = PS["Data.Semigroup"];
  var Data_Semigroup_Foldable = PS["Data.Semigroup.Foldable"];
  var Data_Show = PS["Data.Show"];
  var Data_Traversable = PS["Data.Traversable"];
  var Data_TraversableWithIndex = PS["Data.TraversableWithIndex"];
  var Data_Tuple = PS["Data.Tuple"];
  var Data_Unfoldable = PS["Data.Unfoldable"];
  var Data_Unfoldable1 = PS["Data.Unfoldable1"];
  var Prelude = PS["Prelude"];

  var NonEmpty = function () {
    function NonEmpty(value0, value1) {
      this.value0 = value0;
      this.value1 = value1;
    }

    ;

    NonEmpty.create = function (value0) {
      return function (value1) {
        return new NonEmpty(value0, value1);
      };
    };

    return NonEmpty;
  }();

  var singleton = function singleton(dictPlus) {
    return function (a) {
      return new NonEmpty(a, Control_Plus.empty(dictPlus));
    };
  };

  var showNonEmpty = function showNonEmpty(dictShow) {
    return function (dictShow1) {
      return new Data_Show.Show(function (v) {
        return "(NonEmpty " + (Data_Show.show(dictShow)(v.value0) + (" " + (Data_Show.show(dictShow1)(v.value1) + ")")));
      });
    };
  };

  var functorNonEmpty = function functorNonEmpty(dictFunctor) {
    return new Data_Functor.Functor(function (f) {
      return function (m) {
        return new NonEmpty(f(m.value0), Data_Functor.map(dictFunctor)(f)(m.value1));
      };
    });
  };

  exports["NonEmpty"] = NonEmpty;
  exports["singleton"] = singleton;
  exports["showNonEmpty"] = showNonEmpty;
  exports["functorNonEmpty"] = functorNonEmpty;
})(PS["Data.NonEmpty"] = PS["Data.NonEmpty"] || {});

(function (exports) {
  // Generated by purs version 0.12.3
  "use strict";

  var Control_Alt = PS["Control.Alt"];
  var Control_Alternative = PS["Control.Alternative"];
  var Control_Applicative = PS["Control.Applicative"];
  var Control_Apply = PS["Control.Apply"];
  var Control_Bind = PS["Control.Bind"];
  var Control_Category = PS["Control.Category"];
  var Control_Comonad = PS["Control.Comonad"];
  var Control_Extend = PS["Control.Extend"];
  var Control_Monad = PS["Control.Monad"];
  var Control_MonadPlus = PS["Control.MonadPlus"];
  var Control_MonadZero = PS["Control.MonadZero"];
  var Control_Plus = PS["Control.Plus"];
  var Control_Semigroupoid = PS["Control.Semigroupoid"];
  var Data_Eq = PS["Data.Eq"];
  var Data_Foldable = PS["Data.Foldable"];
  var Data_FoldableWithIndex = PS["Data.FoldableWithIndex"];
  var Data_Function = PS["Data.Function"];
  var Data_Functor = PS["Data.Functor"];
  var Data_FunctorWithIndex = PS["Data.FunctorWithIndex"];
  var Data_HeytingAlgebra = PS["Data.HeytingAlgebra"];
  var Data_Maybe = PS["Data.Maybe"];
  var Data_Monoid = PS["Data.Monoid"];
  var Data_Newtype = PS["Data.Newtype"];
  var Data_NonEmpty = PS["Data.NonEmpty"];
  var Data_Ord = PS["Data.Ord"];
  var Data_Ordering = PS["Data.Ordering"];
  var Data_Ring = PS["Data.Ring"];
  var Data_Semigroup = PS["Data.Semigroup"];
  var Data_Semigroup_Foldable = PS["Data.Semigroup.Foldable"];
  var Data_Semigroup_Traversable = PS["Data.Semigroup.Traversable"];
  var Data_Semiring = PS["Data.Semiring"];
  var Data_Show = PS["Data.Show"];
  var Data_Traversable = PS["Data.Traversable"];
  var Data_TraversableWithIndex = PS["Data.TraversableWithIndex"];
  var Data_Tuple = PS["Data.Tuple"];
  var Data_Unfoldable = PS["Data.Unfoldable"];
  var Data_Unfoldable1 = PS["Data.Unfoldable1"];
  var Prelude = PS["Prelude"];

  var Nil = function () {
    function Nil() {}

    ;
    Nil.value = new Nil();
    return Nil;
  }();

  var Cons = function () {
    function Cons(value0, value1) {
      this.value0 = value0;
      this.value1 = value1;
    }

    ;

    Cons.create = function (value0) {
      return function (value1) {
        return new Cons(value0, value1);
      };
    };

    return Cons;
  }();

  var NonEmptyList = function NonEmptyList(x) {
    return x;
  };

  var toList = function toList(v) {
    return new Cons(v.value0, v.value1);
  };

  var foldableList = new Data_Foldable.Foldable(function (dictMonoid) {
    return function (f) {
      return Data_Foldable.foldl(foldableList)(function (acc) {
        return function ($174) {
          return Data_Semigroup.append(dictMonoid.Semigroup0())(acc)(f($174));
        };
      })(Data_Monoid.mempty(dictMonoid));
    };
  }, function (f) {
    var go = function go($copy_b) {
      return function ($copy_v) {
        var $tco_var_b = $copy_b;
        var $tco_done = false;
        var $tco_result;

        function $tco_loop(b, v) {
          if (v instanceof Nil) {
            $tco_done = true;
            return b;
          }

          ;

          if (v instanceof Cons) {
            $tco_var_b = f(b)(v.value0);
            $copy_v = v.value1;
            return;
          }

          ;
          throw new Error("Failed pattern match at Data.List.Types (line 81, column 12 - line 83, column 30): " + [v.constructor.name]);
        }

        ;

        while (!$tco_done) {
          $tco_result = $tco_loop($tco_var_b, $copy_v);
        }

        ;
        return $tco_result;
      };
    };

    return go;
  }, function (f) {
    return function (b) {
      var rev = Data_Foldable.foldl(foldableList)(Data_Function.flip(Cons.create))(Nil.value);
      return function ($175) {
        return Data_Foldable.foldl(foldableList)(Data_Function.flip(f))(b)(rev($175));
      };
    };
  });
  var functorList = new Data_Functor.Functor(function (f) {
    return Data_Foldable.foldr(foldableList)(function (x) {
      return function (acc) {
        return new Cons(f(x), acc);
      };
    })(Nil.value);
  });
  var functorNonEmptyList = Data_NonEmpty.functorNonEmpty(functorList);
  var semigroupList = new Data_Semigroup.Semigroup(function (xs) {
    return function (ys) {
      return Data_Foldable.foldr(foldableList)(Cons.create)(ys)(xs);
    };
  });
  var monoidList = new Data_Monoid.Monoid(function () {
    return semigroupList;
  }, Nil.value);
  var semigroupNonEmptyList = new Data_Semigroup.Semigroup(function (v) {
    return function (as$prime) {
      return new Data_NonEmpty.NonEmpty(v.value0, Data_Semigroup.append(semigroupList)(v.value1)(toList(as$prime)));
    };
  });

  var showList = function showList(dictShow) {
    return new Data_Show.Show(function (v) {
      if (v instanceof Nil) {
        return "Nil";
      }

      ;
      return "(" + (Data_Foldable.intercalate(foldableList)(Data_Monoid.monoidString)(" : ")(Data_Functor.map(functorList)(Data_Show.show(dictShow))(v)) + " : Nil)");
    });
  };

  var showNonEmptyList = function showNonEmptyList(dictShow) {
    return new Data_Show.Show(function (v) {
      return "(NonEmptyList " + (Data_Show.show(Data_NonEmpty.showNonEmpty(dictShow)(showList(dictShow)))(v) + ")");
    });
  };

  var traversableList = new Data_Traversable.Traversable(function () {
    return foldableList;
  }, function () {
    return functorList;
  }, function (dictApplicative) {
    return Data_Traversable.traverse(traversableList)(dictApplicative)(Control_Category.identity(Control_Category.categoryFn));
  }, function (dictApplicative) {
    return function (f) {
      return function ($182) {
        return Data_Functor.map(dictApplicative.Apply0().Functor0())(Data_Foldable.foldl(foldableList)(Data_Function.flip(Cons.create))(Nil.value))(Data_Foldable.foldl(foldableList)(function (acc) {
          return function ($183) {
            return Control_Apply.lift2(dictApplicative.Apply0())(Data_Function.flip(Cons.create))(acc)(f($183));
          };
        })(Control_Applicative.pure(dictApplicative)(Nil.value))($182));
      };
    };
  });
  var unfoldable1List = new Data_Unfoldable1.Unfoldable1(function (f) {
    return function (b) {
      var go = function go($copy_source) {
        return function ($copy_memo) {
          var $tco_var_source = $copy_source;
          var $tco_done = false;
          var $tco_result;

          function $tco_loop(source, memo) {
            var v = f(source);

            if (v.value1 instanceof Data_Maybe.Just) {
              $tco_var_source = v.value1.value0;
              $copy_memo = new Cons(v.value0, memo);
              return;
            }

            ;

            if (v.value1 instanceof Data_Maybe.Nothing) {
              $tco_done = true;
              return Data_Foldable.foldl(foldableList)(Data_Function.flip(Cons.create))(Nil.value)(new Cons(v.value0, memo));
            }

            ;
            throw new Error("Failed pattern match at Data.List.Types (line 105, column 22 - line 107, column 61): " + [v.constructor.name]);
          }

          ;

          while (!$tco_done) {
            $tco_result = $tco_loop($tco_var_source, $copy_memo);
          }

          ;
          return $tco_result;
        };
      };

      return go(b)(Nil.value);
    };
  });
  var unfoldableList = new Data_Unfoldable.Unfoldable(function () {
    return unfoldable1List;
  }, function (f) {
    return function (b) {
      var go = function go($copy_source) {
        return function ($copy_memo) {
          var $tco_var_source = $copy_source;
          var $tco_done = false;
          var $tco_result;

          function $tco_loop(source, memo) {
            var v = f(source);

            if (v instanceof Data_Maybe.Nothing) {
              $tco_done = true;
              return Data_Foldable.foldl(foldableList)(Data_Function.flip(Cons.create))(Nil.value)(memo);
            }

            ;

            if (v instanceof Data_Maybe.Just) {
              $tco_var_source = v.value0.value1;
              $copy_memo = new Cons(v.value0.value0, memo);
              return;
            }

            ;
            throw new Error("Failed pattern match at Data.List.Types (line 112, column 22 - line 114, column 52): " + [v.constructor.name]);
          }

          ;

          while (!$tco_done) {
            $tco_result = $tco_loop($tco_var_source, $copy_memo);
          }

          ;
          return $tco_result;
        };
      };

      return go(b)(Nil.value);
    };
  });
  var eq1List = new Data_Eq.Eq1(function (dictEq) {
    return function (xs) {
      return function (ys) {
        var go = function go($copy_v) {
          return function ($copy_v1) {
            return function ($copy_v2) {
              var $tco_var_v = $copy_v;
              var $tco_var_v1 = $copy_v1;
              var $tco_done = false;
              var $tco_result;

              function $tco_loop(v, v1, v2) {
                if (!v2) {
                  $tco_done = true;
                  return false;
                }

                ;

                if (v instanceof Nil && v1 instanceof Nil) {
                  $tco_done = true;
                  return v2;
                }

                ;

                if (v instanceof Cons && v1 instanceof Cons) {
                  $tco_var_v = v.value1;
                  $tco_var_v1 = v1.value1;
                  $copy_v2 = v2 && Data_Eq.eq(dictEq)(v1.value0)(v.value0);
                  return;
                }

                ;
                $tco_done = true;
                return false;
              }

              ;

              while (!$tco_done) {
                $tco_result = $tco_loop($tco_var_v, $tco_var_v1, $copy_v2);
              }

              ;
              return $tco_result;
            };
          };
        };

        return go(xs)(ys)(true);
      };
    };
  });

  var eqList = function eqList(dictEq) {
    return new Data_Eq.Eq(Data_Eq.eq1(eq1List)(dictEq));
  };

  var ord1List = new Data_Ord.Ord1(function () {
    return eq1List;
  }, function (dictOrd) {
    return function (xs) {
      return function (ys) {
        var go = function go($copy_v) {
          return function ($copy_v1) {
            var $tco_var_v = $copy_v;
            var $tco_done = false;
            var $tco_result;

            function $tco_loop(v, v1) {
              if (v instanceof Nil && v1 instanceof Nil) {
                $tco_done = true;
                return Data_Ordering.EQ.value;
              }

              ;

              if (v instanceof Nil) {
                $tco_done = true;
                return Data_Ordering.LT.value;
              }

              ;

              if (v1 instanceof Nil) {
                $tco_done = true;
                return Data_Ordering.GT.value;
              }

              ;

              if (v instanceof Cons && v1 instanceof Cons) {
                var v2 = Data_Ord.compare(dictOrd)(v.value0)(v1.value0);

                if (v2 instanceof Data_Ordering.EQ) {
                  $tco_var_v = v.value1;
                  $copy_v1 = v1.value1;
                  return;
                }

                ;
                $tco_done = true;
                return v2;
              }

              ;
              throw new Error("Failed pattern match at Data.List.Types (line 55, column 5 - line 55, column 20): " + [v.constructor.name, v1.constructor.name]);
            }

            ;

            while (!$tco_done) {
              $tco_result = $tco_loop($tco_var_v, $copy_v1);
            }

            ;
            return $tco_result;
          };
        };

        return go(xs)(ys);
      };
    };
  });

  var ordList = function ordList(dictOrd) {
    return new Data_Ord.Ord(function () {
      return eqList(dictOrd.Eq0());
    }, Data_Ord.compare1(ord1List)(dictOrd));
  };

  var applyList = new Control_Apply.Apply(function () {
    return functorList;
  }, function (v) {
    return function (v1) {
      if (v instanceof Nil) {
        return Nil.value;
      }

      ;

      if (v instanceof Cons) {
        return Data_Semigroup.append(semigroupList)(Data_Functor.map(functorList)(v.value0)(v1))(Control_Apply.apply(applyList)(v.value1)(v1));
      }

      ;
      throw new Error("Failed pattern match at Data.List.Types (line 127, column 1 - line 127, column 33): " + [v.constructor.name, v1.constructor.name]);
    };
  });
  var applicativeList = new Control_Applicative.Applicative(function () {
    return applyList;
  }, function (a) {
    return new Cons(a, Nil.value);
  });
  var altList = new Control_Alt.Alt(function () {
    return functorList;
  }, Data_Semigroup.append(semigroupList));
  var plusList = new Control_Plus.Plus(function () {
    return altList;
  }, Nil.value);
  exports["Nil"] = Nil;
  exports["Cons"] = Cons;
  exports["NonEmptyList"] = NonEmptyList;
  exports["toList"] = toList;
  exports["showList"] = showList;
  exports["eqList"] = eqList;
  exports["eq1List"] = eq1List;
  exports["ordList"] = ordList;
  exports["ord1List"] = ord1List;
  exports["semigroupList"] = semigroupList;
  exports["monoidList"] = monoidList;
  exports["functorList"] = functorList;
  exports["foldableList"] = foldableList;
  exports["unfoldable1List"] = unfoldable1List;
  exports["unfoldableList"] = unfoldableList;
  exports["traversableList"] = traversableList;
  exports["applyList"] = applyList;
  exports["applicativeList"] = applicativeList;
  exports["altList"] = altList;
  exports["plusList"] = plusList;
  exports["showNonEmptyList"] = showNonEmptyList;
  exports["functorNonEmptyList"] = functorNonEmptyList;
  exports["semigroupNonEmptyList"] = semigroupNonEmptyList;
})(PS["Data.List.Types"] = PS["Data.List.Types"] || {});

(function (exports) {
  /* globals setImmediate, clearImmediate, setTimeout, clearTimeout */

  /* jshint -W083, -W098, -W003 */
  "use strict";

  var Aff = function () {
    // A unique value for empty.
    var EMPTY = {};
    /*
    An awkward approximation. We elide evidence we would otherwise need in PS for
    efficiency sake.
    data Aff eff a
    = Pure a
    | Throw Error
    | Catch (Aff eff a) (Error -> Aff eff a)
    | Sync (Eff eff a)
    | Async ((Either Error a -> Eff eff Unit) -> Eff eff (Canceler eff))
    | forall b. Bind (Aff eff b) (b -> Aff eff a)
    | forall b. Bracket (Aff eff b) (BracketConditions eff b) (b -> Aff eff a)
    | forall b. Fork Boolean (Aff eff b) ?(Fiber eff b -> a)
    | Sequential (ParAff aff a)
    */

    var PURE = "Pure";
    var THROW = "Throw";
    var CATCH = "Catch";
    var SYNC = "Sync";
    var ASYNC = "Async";
    var BIND = "Bind";
    var BRACKET = "Bracket";
    var FORK = "Fork";
    var SEQ = "Sequential";
    /*
    data ParAff eff a
    = forall b. Map (b -> a) (ParAff eff b)
    | forall b. Apply (ParAff eff (b -> a)) (ParAff eff b)
    | Alt (ParAff eff a) (ParAff eff a)
    | ?Par (Aff eff a)
    */

    var MAP = "Map";
    var APPLY = "Apply";
    var ALT = "Alt"; // Various constructors used in interpretation

    var CONS = "Cons"; // Cons-list, for stacks

    var RESUME = "Resume"; // Continue indiscriminately

    var RELEASE = "Release"; // Continue with bracket finalizers

    var FINALIZER = "Finalizer"; // A non-interruptible effect

    var FINALIZED = "Finalized"; // Marker for finalization

    var FORKED = "Forked"; // Reference to a forked fiber, with resumption stack

    var FIBER = "Fiber"; // Actual fiber reference

    var THUNK = "Thunk"; // Primed effect, ready to invoke

    function Aff(tag, _1, _2, _3) {
      this.tag = tag;
      this._1 = _1;
      this._2 = _2;
      this._3 = _3;
    }

    function AffCtr(tag) {
      var fn = function fn(_1, _2, _3) {
        return new Aff(tag, _1, _2, _3);
      };

      fn.tag = tag;
      return fn;
    }

    function nonCanceler(error) {
      return new Aff(PURE, void 0);
    }

    function runEff(eff) {
      try {
        eff();
      } catch (error) {
        setTimeout(function () {
          throw error;
        }, 0);
      }
    }

    function runSync(left, right, eff) {
      try {
        return right(eff());
      } catch (error) {
        return left(error);
      }
    }

    function runAsync(left, eff, k) {
      try {
        return eff(k)();
      } catch (error) {
        k(left(error))();
        return nonCanceler;
      }
    }

    var Scheduler = function () {
      var limit = 1024;
      var size = 0;
      var ix = 0;
      var queue = new Array(limit);
      var draining = false;

      function drain() {
        var thunk;
        draining = true;

        while (size !== 0) {
          size--;
          thunk = queue[ix];
          queue[ix] = void 0;
          ix = (ix + 1) % limit;
          thunk();
        }

        draining = false;
      }

      return {
        isDraining: function isDraining() {
          return draining;
        },
        enqueue: function enqueue(cb) {
          var i, tmp;

          if (size === limit) {
            tmp = draining;
            drain();
            draining = tmp;
          }

          queue[(ix + size) % limit] = cb;
          size++;

          if (!draining) {
            drain();
          }
        }
      };
    }();

    function Supervisor(util) {
      var fibers = {};
      var fiberId = 0;
      var count = 0;
      return {
        register: function register(fiber) {
          var fid = fiberId++;
          fiber.onComplete({
            rethrow: true,
            handler: function handler(result) {
              return function () {
                count--;
                delete fibers[fid];
              };
            }
          });
          fibers[fid] = fiber;
          count++;
        },
        isEmpty: function isEmpty() {
          return count === 0;
        },
        killAll: function killAll(killError, cb) {
          return function () {
            var killCount = 0;
            var kills = {};

            function kill(fid) {
              kills[fid] = fibers[fid].kill(killError, function (result) {
                return function () {
                  delete kills[fid];
                  killCount--;

                  if (util.isLeft(result) && util.fromLeft(result)) {
                    setTimeout(function () {
                      throw util.fromLeft(result);
                    }, 0);
                  }

                  if (killCount === 0) {
                    cb();
                  }
                };
              })();
            }

            for (var k in fibers) {
              if (fibers.hasOwnProperty(k)) {
                killCount++;
                kill(k);
              }
            }

            fibers = {};
            fiberId = 0;
            count = 0;
            return function (error) {
              return new Aff(SYNC, function () {
                for (var k in kills) {
                  if (kills.hasOwnProperty(k)) {
                    kills[k]();
                  }
                }
              });
            };
          };
        }
      };
    } // Fiber state machine


    var SUSPENDED = 0; // Suspended, pending a join.

    var CONTINUE = 1; // Interpret the next instruction.

    var STEP_BIND = 2; // Apply the next bind.

    var STEP_RESULT = 3; // Handle potential failure from a result.

    var PENDING = 4; // An async effect is running.

    var RETURN = 5; // The current stack has returned.

    var COMPLETED = 6; // The entire fiber has completed.

    function Fiber(util, supervisor, aff) {
      // Monotonically increasing tick, increased on each asynchronous turn.
      var runTick = 0; // The current branch of the state machine.

      var status = SUSPENDED; // The current point of interest for the state machine branch.

      var step = aff; // Successful step

      var fail = null; // Failure step

      var interrupt = null; // Asynchronous interrupt
      // Stack of continuations for the current fiber.

      var bhead = null;
      var btail = null; // Stack of attempts and finalizers for error recovery. Every `Cons` is also
      // tagged with current `interrupt` state. We use this to track which items
      // should be ignored or evaluated as a result of a kill.

      var attempts = null; // A special state is needed for Bracket, because it cannot be killed. When
      // we enter a bracket acquisition or finalizer, we increment the counter,
      // and then decrement once complete.

      var bracketCount = 0; // Each join gets a new id so they can be revoked.

      var joinId = 0;
      var joins = null;
      var rethrow = true; // Each invocation of `run` requires a tick. When an asynchronous effect is
      // resolved, we must check that the local tick coincides with the fiber
      // tick before resuming. This prevents multiple async continuations from
      // accidentally resuming the same fiber. A common example may be invoking
      // the provided callback in `makeAff` more than once, but it may also be an
      // async effect resuming after the fiber was already cancelled.

      function _run(localRunTick) {
        var tmp, result, attempt;

        while (true) {
          tmp = null;
          result = null;
          attempt = null;

          switch (status) {
            case STEP_BIND:
              status = CONTINUE;
              step = bhead(step);

              if (btail === null) {
                bhead = null;
              } else {
                bhead = btail._1;
                btail = btail._2;
              }

              break;

            case STEP_RESULT:
              if (util.isLeft(step)) {
                status = RETURN;
                fail = step;
                step = null;
              } else if (bhead === null) {
                status = RETURN;
              } else {
                status = STEP_BIND;
                step = util.fromRight(step);
              }

              break;

            case CONTINUE:
              switch (step.tag) {
                case BIND:
                  if (bhead) {
                    btail = new Aff(CONS, bhead, btail);
                  }

                  bhead = step._2;
                  status = CONTINUE;
                  step = step._1;
                  break;

                case PURE:
                  if (bhead === null) {
                    status = RETURN;
                    step = util.right(step._1);
                  } else {
                    status = STEP_BIND;
                    step = step._1;
                  }

                  break;

                case SYNC:
                  status = STEP_RESULT;
                  step = runSync(util.left, util.right, step._1);
                  break;

                case ASYNC:
                  status = PENDING;
                  step = runAsync(util.left, step._1, function (result) {
                    return function () {
                      if (runTick !== localRunTick) {
                        return;
                      }

                      runTick++;
                      Scheduler.enqueue(function () {
                        // It's possible to interrupt the fiber between enqueuing and
                        // resuming, so we need to check that the runTick is still
                        // valid.
                        if (runTick !== localRunTick + 1) {
                          return;
                        }

                        status = STEP_RESULT;
                        step = result;

                        _run(runTick);
                      });
                    };
                  });
                  return;

                case THROW:
                  status = RETURN;
                  fail = util.left(step._1);
                  step = null;
                  break;
                // Enqueue the Catch so that we can call the error handler later on
                // in case of an exception.

                case CATCH:
                  if (bhead === null) {
                    attempts = new Aff(CONS, step, attempts, interrupt);
                  } else {
                    attempts = new Aff(CONS, step, new Aff(CONS, new Aff(RESUME, bhead, btail), attempts, interrupt), interrupt);
                  }

                  bhead = null;
                  btail = null;
                  status = CONTINUE;
                  step = step._1;
                  break;
                // Enqueue the Bracket so that we can call the appropriate handlers
                // after resource acquisition.

                case BRACKET:
                  bracketCount++;

                  if (bhead === null) {
                    attempts = new Aff(CONS, step, attempts, interrupt);
                  } else {
                    attempts = new Aff(CONS, step, new Aff(CONS, new Aff(RESUME, bhead, btail), attempts, interrupt), interrupt);
                  }

                  bhead = null;
                  btail = null;
                  status = CONTINUE;
                  step = step._1;
                  break;

                case FORK:
                  status = STEP_RESULT;
                  tmp = Fiber(util, supervisor, step._2);

                  if (supervisor) {
                    supervisor.register(tmp);
                  }

                  if (step._1) {
                    tmp.run();
                  }

                  step = util.right(tmp);
                  break;

                case SEQ:
                  status = CONTINUE;
                  step = sequential(util, supervisor, step._1);
                  break;
              }

              break;

            case RETURN:
              bhead = null;
              btail = null; // If the current stack has returned, and we have no other stacks to
              // resume or finalizers to run, the fiber has halted and we can
              // invoke all join callbacks. Otherwise we need to resume.

              if (attempts === null) {
                status = COMPLETED;
                step = interrupt || fail || step;
              } else {
                // The interrupt status for the enqueued item.
                tmp = attempts._3;
                attempt = attempts._1;
                attempts = attempts._2;

                switch (attempt.tag) {
                  // We cannot recover from an interrupt. Otherwise we should
                  // continue stepping, or run the exception handler if an exception
                  // was raised.
                  case CATCH:
                    // We should compare the interrupt status as well because we
                    // only want it to apply if there has been an interrupt since
                    // enqueuing the catch.
                    if (interrupt && interrupt !== tmp) {
                      status = RETURN;
                    } else if (fail) {
                      status = CONTINUE;
                      step = attempt._2(util.fromLeft(fail));
                      fail = null;
                    }

                    break;
                  // We cannot resume from an interrupt or exception.

                  case RESUME:
                    // As with Catch, we only want to ignore in the case of an
                    // interrupt since enqueing the item.
                    if (interrupt && interrupt !== tmp || fail) {
                      status = RETURN;
                    } else {
                      bhead = attempt._1;
                      btail = attempt._2;
                      status = STEP_BIND;
                      step = util.fromRight(step);
                    }

                    break;
                  // If we have a bracket, we should enqueue the handlers,
                  // and continue with the success branch only if the fiber has
                  // not been interrupted. If the bracket acquisition failed, we
                  // should not run either.

                  case BRACKET:
                    bracketCount--;

                    if (fail === null) {
                      result = util.fromRight(step); // We need to enqueue the Release with the same interrupt
                      // status as the Bracket that is initiating it.

                      attempts = new Aff(CONS, new Aff(RELEASE, attempt._2, result), attempts, tmp); // We should only coninue as long as the interrupt status has not changed or
                      // we are currently within a non-interruptable finalizer.

                      if (interrupt === tmp || bracketCount > 0) {
                        status = CONTINUE;
                        step = attempt._3(result);
                      }
                    }

                    break;
                  // Enqueue the appropriate handler. We increase the bracket count
                  // because it should not be cancelled.

                  case RELEASE:
                    bracketCount++;
                    attempts = new Aff(CONS, new Aff(FINALIZED, step, fail), attempts, interrupt);
                    status = CONTINUE; // It has only been killed if the interrupt status has changed
                    // since we enqueued the item.

                    if (interrupt && interrupt !== tmp) {
                      step = attempt._1.killed(util.fromLeft(interrupt))(attempt._2);
                    } else if (fail) {
                      step = attempt._1.failed(util.fromLeft(fail))(attempt._2);
                    } else {
                      step = attempt._1.completed(util.fromRight(step))(attempt._2);
                    }

                    fail = null;
                    break;

                  case FINALIZER:
                    bracketCount++;
                    attempts = new Aff(CONS, new Aff(FINALIZED, step, fail), attempts, interrupt);
                    status = CONTINUE;
                    step = attempt._1;
                    break;

                  case FINALIZED:
                    bracketCount--;
                    status = RETURN;
                    step = attempt._1;
                    fail = attempt._2;
                    break;
                }
              }

              break;

            case COMPLETED:
              for (var k in joins) {
                if (joins.hasOwnProperty(k)) {
                  rethrow = rethrow && joins[k].rethrow;
                  runEff(joins[k].handler(step));
                }
              }

              joins = null; // If we have an interrupt and a fail, then the thread threw while
              // running finalizers. This should always rethrow in a fresh stack.

              if (interrupt && fail) {
                setTimeout(function () {
                  throw util.fromLeft(fail);
                }, 0); // If we have an unhandled exception, and no other fiber has joined
                // then we need to throw the exception in a fresh stack.
              } else if (util.isLeft(step) && rethrow) {
                setTimeout(function () {
                  // Guard on reathrow because a completely synchronous fiber can
                  // still have an observer which was added after-the-fact.
                  if (rethrow) {
                    throw util.fromLeft(step);
                  }
                }, 0);
              }

              return;

            case SUSPENDED:
              status = CONTINUE;
              break;

            case PENDING:
              return;
          }
        }
      }

      function onComplete(join) {
        return function () {
          if (status === COMPLETED) {
            rethrow = rethrow && join.rethrow;
            join.handler(step)();
            return function () {};
          }

          var jid = joinId++;
          joins = joins || {};
          joins[jid] = join;
          return function () {
            if (joins !== null) {
              delete joins[jid];
            }
          };
        };
      }

      function kill(error, cb) {
        return function () {
          if (status === COMPLETED) {
            cb(util.right(void 0))();
            return function () {};
          }

          var canceler = onComplete({
            rethrow: false,
            handler: function handler()
            /* unused */
            {
              return cb(util.right(void 0));
            }
          })();

          switch (status) {
            case SUSPENDED:
              interrupt = util.left(error);
              status = COMPLETED;
              step = interrupt;

              _run(runTick);

              break;

            case PENDING:
              if (interrupt === null) {
                interrupt = util.left(error);
              }

              if (bracketCount === 0) {
                if (status === PENDING) {
                  attempts = new Aff(CONS, new Aff(FINALIZER, step(error)), attempts, interrupt);
                }

                status = RETURN;
                step = null;
                fail = null;

                _run(++runTick);
              }

              break;

            default:
              if (interrupt === null) {
                interrupt = util.left(error);
              }

              if (bracketCount === 0) {
                status = RETURN;
                step = null;
                fail = null;
              }

          }

          return canceler;
        };
      }

      function join(cb) {
        return function () {
          var canceler = onComplete({
            rethrow: false,
            handler: cb
          })();

          if (status === SUSPENDED) {
            _run(runTick);
          }

          return canceler;
        };
      }

      return {
        kill: kill,
        join: join,
        onComplete: onComplete,
        isSuspended: function isSuspended() {
          return status === SUSPENDED;
        },
        run: function run() {
          if (status === SUSPENDED) {
            if (!Scheduler.isDraining()) {
              Scheduler.enqueue(function () {
                _run(runTick);
              });
            } else {
              _run(runTick);
            }
          }
        }
      };
    }

    function runPar(util, supervisor, par, cb) {
      // Table of all forked fibers.
      var fiberId = 0;
      var fibers = {}; // Table of currently running cancelers, as a product of `Alt` behavior.

      var killId = 0;
      var kills = {}; // Error used for early cancelation on Alt branches.

      var early = new Error("[ParAff] Early exit"); // Error used to kill the entire tree.

      var interrupt = null; // The root pointer of the tree.

      var root = EMPTY; // Walks a tree, invoking all the cancelers. Returns the table of pending
      // cancellation fibers.

      function kill(error, par, cb) {
        var step = par;
        var head = null;
        var tail = null;
        var count = 0;
        var kills = {};
        var tmp, kid;

        loop: while (true) {
          tmp = null;

          switch (step.tag) {
            case FORKED:
              if (step._3 === EMPTY) {
                tmp = fibers[step._1];
                kills[count++] = tmp.kill(error, function (result) {
                  return function () {
                    count--;

                    if (count === 0) {
                      cb(result)();
                    }
                  };
                });
              } // Terminal case.


              if (head === null) {
                break loop;
              } // Go down the right side of the tree.


              step = head._2;

              if (tail === null) {
                head = null;
              } else {
                head = tail._1;
                tail = tail._2;
              }

              break;

            case MAP:
              step = step._2;
              break;

            case APPLY:
            case ALT:
              if (head) {
                tail = new Aff(CONS, head, tail);
              }

              head = step;
              step = step._1;
              break;
          }
        }

        if (count === 0) {
          cb(util.right(void 0))();
        } else {
          // Run the cancelation effects. We alias `count` because it's mutable.
          kid = 0;
          tmp = count;

          for (; kid < tmp; kid++) {
            kills[kid] = kills[kid]();
          }
        }

        return kills;
      } // When a fiber resolves, we need to bubble back up the tree with the
      // result, computing the applicative nodes.


      function join(result, head, tail) {
        var fail, step, lhs, rhs, tmp, kid;

        if (util.isLeft(result)) {
          fail = result;
          step = null;
        } else {
          step = result;
          fail = null;
        }

        loop: while (true) {
          lhs = null;
          rhs = null;
          tmp = null;
          kid = null; // We should never continue if the entire tree has been interrupted.

          if (interrupt !== null) {
            return;
          } // We've made it all the way to the root of the tree, which means
          // the tree has fully evaluated.


          if (head === null) {
            cb(fail || step)();
            return;
          } // The tree has already been computed, so we shouldn't try to do it
          // again. This should never happen.
          // TODO: Remove this?


          if (head._3 !== EMPTY) {
            return;
          }

          switch (head.tag) {
            case MAP:
              if (fail === null) {
                head._3 = util.right(head._1(util.fromRight(step)));
                step = head._3;
              } else {
                head._3 = fail;
              }

              break;

            case APPLY:
              lhs = head._1._3;
              rhs = head._2._3; // If we have a failure we should kill the other side because we
              // can't possible yield a result anymore.

              if (fail) {
                head._3 = fail;
                tmp = true;
                kid = killId++;
                kills[kid] = kill(early, fail === lhs ? head._2 : head._1, function ()
                /* unused */
                {
                  return function () {
                    delete kills[kid];

                    if (tmp) {
                      tmp = false;
                    } else if (tail === null) {
                      join(fail, null, null);
                    } else {
                      join(fail, tail._1, tail._2);
                    }
                  };
                });

                if (tmp) {
                  tmp = false;
                  return;
                }
              } else if (lhs === EMPTY || rhs === EMPTY) {
                // We can only proceed if both sides have resolved.
                return;
              } else {
                step = util.right(util.fromRight(lhs)(util.fromRight(rhs)));
                head._3 = step;
              }

              break;

            case ALT:
              lhs = head._1._3;
              rhs = head._2._3; // We can only proceed if both have resolved or we have a success

              if (lhs === EMPTY && util.isLeft(rhs) || rhs === EMPTY && util.isLeft(lhs)) {
                return;
              } // If both sides resolve with an error, we should continue with the
              // first error


              if (lhs !== EMPTY && util.isLeft(lhs) && rhs !== EMPTY && util.isLeft(rhs)) {
                fail = step === lhs ? rhs : lhs;
                step = null;
                head._3 = fail;
              } else {
                head._3 = step;
                tmp = true;
                kid = killId++; // Once a side has resolved, we need to cancel the side that is still
                // pending before we can continue.

                kills[kid] = kill(early, step === lhs ? head._2 : head._1, function ()
                /* unused */
                {
                  return function () {
                    delete kills[kid];

                    if (tmp) {
                      tmp = false;
                    } else if (tail === null) {
                      join(step, null, null);
                    } else {
                      join(step, tail._1, tail._2);
                    }
                  };
                });

                if (tmp) {
                  tmp = false;
                  return;
                }
              }

              break;
          }

          if (tail === null) {
            head = null;
          } else {
            head = tail._1;
            tail = tail._2;
          }
        }
      }

      function resolve(fiber) {
        return function (result) {
          return function () {
            delete fibers[fiber._1];
            fiber._3 = result;
            join(result, fiber._2._1, fiber._2._2);
          };
        };
      } // Walks the applicative tree, substituting non-applicative nodes with
      // `FORKED` nodes. In this tree, all applicative nodes use the `_3` slot
      // as a mutable slot for memoization. In an unresolved state, the `_3`
      // slot is `EMPTY`. In the cases of `ALT` and `APPLY`, we always walk
      // the left side first, because both operations are left-associative. As
      // we `RETURN` from those branches, we then walk the right side.


      function run() {
        var status = CONTINUE;
        var step = par;
        var head = null;
        var tail = null;
        var tmp, fid;

        loop: while (true) {
          tmp = null;
          fid = null;

          switch (status) {
            case CONTINUE:
              switch (step.tag) {
                case MAP:
                  if (head) {
                    tail = new Aff(CONS, head, tail);
                  }

                  head = new Aff(MAP, step._1, EMPTY, EMPTY);
                  step = step._2;
                  break;

                case APPLY:
                  if (head) {
                    tail = new Aff(CONS, head, tail);
                  }

                  head = new Aff(APPLY, EMPTY, step._2, EMPTY);
                  step = step._1;
                  break;

                case ALT:
                  if (head) {
                    tail = new Aff(CONS, head, tail);
                  }

                  head = new Aff(ALT, EMPTY, step._2, EMPTY);
                  step = step._1;
                  break;

                default:
                  // When we hit a leaf value, we suspend the stack in the `FORKED`.
                  // When the fiber resolves, it can bubble back up the tree.
                  fid = fiberId++;
                  status = RETURN;
                  tmp = step;
                  step = new Aff(FORKED, fid, new Aff(CONS, head, tail), EMPTY);
                  tmp = Fiber(util, supervisor, tmp);
                  tmp.onComplete({
                    rethrow: false,
                    handler: resolve(step)
                  })();
                  fibers[fid] = tmp;

                  if (supervisor) {
                    supervisor.register(tmp);
                  }

              }

              break;

            case RETURN:
              // Terminal case, we are back at the root.
              if (head === null) {
                break loop;
              } // If we are done with the right side, we need to continue down the
              // left. Otherwise we should continue up the stack.


              if (head._1 === EMPTY) {
                head._1 = step;
                status = CONTINUE;
                step = head._2;
                head._2 = EMPTY;
              } else {
                head._2 = step;
                step = head;

                if (tail === null) {
                  head = null;
                } else {
                  head = tail._1;
                  tail = tail._2;
                }
              }

          }
        } // Keep a reference to the tree root so it can be cancelled.


        root = step;

        for (fid = 0; fid < fiberId; fid++) {
          fibers[fid].run();
        }
      } // Cancels the entire tree. If there are already subtrees being canceled,
      // we need to first cancel those joins. We will then add fresh joins for
      // all pending branches including those that were in the process of being
      // canceled.


      function cancel(error, cb) {
        interrupt = util.left(error);
        var innerKills;

        for (var kid in kills) {
          if (kills.hasOwnProperty(kid)) {
            innerKills = kills[kid];

            for (kid in innerKills) {
              if (innerKills.hasOwnProperty(kid)) {
                innerKills[kid]();
              }
            }
          }
        }

        kills = null;
        var newKills = kill(error, root, cb);
        return function (killError) {
          return new Aff(ASYNC, function (killCb) {
            return function () {
              for (var kid in newKills) {
                if (newKills.hasOwnProperty(kid)) {
                  newKills[kid]();
                }
              }

              return nonCanceler;
            };
          });
        };
      }

      run();
      return function (killError) {
        return new Aff(ASYNC, function (killCb) {
          return function () {
            return cancel(killError, killCb);
          };
        });
      };
    }

    function sequential(util, supervisor, par) {
      return new Aff(ASYNC, function (cb) {
        return function () {
          return runPar(util, supervisor, par, cb);
        };
      });
    }

    Aff.EMPTY = EMPTY;
    Aff.Pure = AffCtr(PURE);
    Aff.Throw = AffCtr(THROW);
    Aff.Catch = AffCtr(CATCH);
    Aff.Sync = AffCtr(SYNC);
    Aff.Async = AffCtr(ASYNC);
    Aff.Bind = AffCtr(BIND);
    Aff.Bracket = AffCtr(BRACKET);
    Aff.Fork = AffCtr(FORK);
    Aff.Seq = AffCtr(SEQ);
    Aff.ParMap = AffCtr(MAP);
    Aff.ParApply = AffCtr(APPLY);
    Aff.ParAlt = AffCtr(ALT);
    Aff.Fiber = Fiber;
    Aff.Supervisor = Supervisor;
    Aff.Scheduler = Scheduler;
    Aff.nonCanceler = nonCanceler;
    return Aff;
  }();

  exports._pure = Aff.Pure;
  exports._throwError = Aff.Throw;

  exports._catchError = function (aff) {
    return function (k) {
      return Aff.Catch(aff, k);
    };
  };

  exports._map = function (f) {
    return function (aff) {
      if (aff.tag === Aff.Pure.tag) {
        return Aff.Pure(f(aff._1));
      } else {
        return Aff.Bind(aff, function (value) {
          return Aff.Pure(f(value));
        });
      }
    };
  };

  exports._bind = function (aff) {
    return function (k) {
      return Aff.Bind(aff, k);
    };
  };

  exports._liftEffect = Aff.Sync;

  exports._parAffMap = function (f) {
    return function (aff) {
      return Aff.ParMap(f, aff);
    };
  };

  exports._parAffApply = function (aff1) {
    return function (aff2) {
      return Aff.ParApply(aff1, aff2);
    };
  };

  exports.makeAff = Aff.Async;

  exports._makeFiber = function (util, aff) {
    return function () {
      return Aff.Fiber(util, null, aff);
    };
  };

  exports._sequential = Aff.Seq;
})(PS["Effect.Aff"] = PS["Effect.Aff"] || {});

(function (exports) {
  "use strict";

  exports.runFn2 = function (fn) {
    return function (a) {
      return function (b) {
        return fn(a, b);
      };
    };
  };

  exports.runFn4 = function (fn) {
    return function (a) {
      return function (b) {
        return function (c) {
          return function (d) {
            return fn(a, b, c, d);
          };
        };
      };
    };
  };
})(PS["Data.Function.Uncurried"] = PS["Data.Function.Uncurried"] || {});

(function (exports) {
  // Generated by purs version 0.12.3
  "use strict";

  var $foreign = PS["Data.Function.Uncurried"];
  var Data_Unit = PS["Data.Unit"];
  exports["runFn2"] = $foreign.runFn2;
  exports["runFn4"] = $foreign.runFn4;
})(PS["Data.Function.Uncurried"] = PS["Data.Function.Uncurried"] || {});

(function (exports) {
  // Generated by purs version 0.12.3
  "use strict";

  var Control_Category = PS["Control.Category"];
  var Control_Semigroupoid = PS["Control.Semigroupoid"];
  var Data_Eq = PS["Data.Eq"];
  var Data_EuclideanRing = PS["Data.EuclideanRing"];
  var Data_Monoid = PS["Data.Monoid"];
  var Data_Newtype = PS["Data.Newtype"];
  var Data_Ord = PS["Data.Ord"];
  var Data_Ring = PS["Data.Ring"];
  var Data_Semigroup = PS["Data.Semigroup"];
  var Data_Semiring = PS["Data.Semiring"];
  var Data_Show = PS["Data.Show"];
  var Prelude = PS["Prelude"];

  var Milliseconds = function Milliseconds(x) {
    return x;
  };

  var newtypeMilliseconds = new Data_Newtype.Newtype(function (n) {
    return n;
  }, Milliseconds);
  exports["Milliseconds"] = Milliseconds;
  exports["newtypeMilliseconds"] = newtypeMilliseconds;
})(PS["Data.Time.Duration"] = PS["Data.Time.Duration"] || {});

(function (exports) {
  "use strict";

  exports.unsafePerformEffect = function (f) {
    return f();
  };
})(PS["Effect.Unsafe"] = PS["Effect.Unsafe"] || {});

(function (exports) {
  // Generated by purs version 0.12.3
  "use strict";

  var $foreign = PS["Effect.Unsafe"];
  var Effect = PS["Effect"];
  exports["unsafePerformEffect"] = $foreign.unsafePerformEffect;
})(PS["Effect.Unsafe"] = PS["Effect.Unsafe"] || {});

(function (exports) {
  "use strict"; // module Unsafe.Coerce

  exports.unsafeCoerce = function (x) {
    return x;
  };
})(PS["Unsafe.Coerce"] = PS["Unsafe.Coerce"] || {});

(function (exports) {
  // Generated by purs version 0.12.3
  "use strict";

  var $foreign = PS["Unsafe.Coerce"];
  exports["unsafeCoerce"] = $foreign.unsafeCoerce;
})(PS["Unsafe.Coerce"] = PS["Unsafe.Coerce"] || {});

(function (exports) {
  // Generated by purs version 0.12.3
  "use strict";

  var $foreign = PS["Effect.Aff"];
  var Control_Alt = PS["Control.Alt"];
  var Control_Alternative = PS["Control.Alternative"];
  var Control_Applicative = PS["Control.Applicative"];
  var Control_Apply = PS["Control.Apply"];
  var Control_Bind = PS["Control.Bind"];
  var Control_Lazy = PS["Control.Lazy"];
  var Control_Monad = PS["Control.Monad"];
  var Control_Monad_Error_Class = PS["Control.Monad.Error.Class"];
  var Control_Monad_Rec_Class = PS["Control.Monad.Rec.Class"];
  var Control_Parallel = PS["Control.Parallel"];
  var Control_Parallel_Class = PS["Control.Parallel.Class"];
  var Control_Plus = PS["Control.Plus"];
  var Control_Semigroupoid = PS["Control.Semigroupoid"];
  var Data_Either = PS["Data.Either"];
  var Data_Foldable = PS["Data.Foldable"];
  var Data_Function = PS["Data.Function"];
  var Data_Function_Uncurried = PS["Data.Function.Uncurried"];
  var Data_Functor = PS["Data.Functor"];
  var Data_Monoid = PS["Data.Monoid"];
  var Data_Newtype = PS["Data.Newtype"];
  var Data_Semigroup = PS["Data.Semigroup"];
  var Data_Time_Duration = PS["Data.Time.Duration"];
  var Data_Unit = PS["Data.Unit"];
  var Effect = PS["Effect"];
  var Effect_Class = PS["Effect.Class"];
  var Effect_Exception = PS["Effect.Exception"];
  var Effect_Unsafe = PS["Effect.Unsafe"];
  var Partial_Unsafe = PS["Partial.Unsafe"];
  var Prelude = PS["Prelude"];
  var Unsafe_Coerce = PS["Unsafe.Coerce"];
  var functorParAff = new Data_Functor.Functor($foreign["_parAffMap"]);
  var functorAff = new Data_Functor.Functor($foreign["_map"]);

  var ffiUtil = function () {
    var unsafeFromRight = function unsafeFromRight(v) {
      if (v instanceof Data_Either.Right) {
        return v.value0;
      }

      ;

      if (v instanceof Data_Either.Left) {
        return Partial_Unsafe.unsafeCrashWith("unsafeFromRight: Left");
      }

      ;
      throw new Error("Failed pattern match at Effect.Aff (line 400, column 21 - line 402, column 31): " + [v.constructor.name]);
    };

    var unsafeFromLeft = function unsafeFromLeft(v) {
      if (v instanceof Data_Either.Left) {
        return v.value0;
      }

      ;

      if (v instanceof Data_Either.Right) {
        return Partial_Unsafe.unsafeCrashWith("unsafeFromLeft: Right");
      }

      ;
      throw new Error("Failed pattern match at Effect.Aff (line 395, column 20 - line 399, column 3): " + [v.constructor.name]);
    };

    var isLeft = function isLeft(v) {
      if (v instanceof Data_Either.Left) {
        return true;
      }

      ;

      if (v instanceof Data_Either.Right) {
        return false;
      }

      ;
      throw new Error("Failed pattern match at Effect.Aff (line 390, column 12 - line 392, column 20): " + [v.constructor.name]);
    };

    return {
      isLeft: isLeft,
      fromLeft: unsafeFromLeft,
      fromRight: unsafeFromRight,
      left: Data_Either.Left.create,
      right: Data_Either.Right.create
    };
  }();

  var makeFiber = function makeFiber(aff) {
    return $foreign["_makeFiber"](ffiUtil, aff);
  };

  var launchAff = function launchAff(aff) {
    return function __do() {
      var v = makeFiber(aff)();
      v.run();
      return v;
    };
  };

  var applyParAff = new Control_Apply.Apply(function () {
    return functorParAff;
  }, $foreign["_parAffApply"]);
  var monadAff = new Control_Monad.Monad(function () {
    return applicativeAff;
  }, function () {
    return bindAff;
  });
  var bindAff = new Control_Bind.Bind(function () {
    return applyAff;
  }, $foreign["_bind"]);
  var applyAff = new Control_Apply.Apply(function () {
    return functorAff;
  }, Control_Monad.ap(monadAff));
  var applicativeAff = new Control_Applicative.Applicative(function () {
    return applyAff;
  }, $foreign["_pure"]);
  var monadEffectAff = new Effect_Class.MonadEffect(function () {
    return monadAff;
  }, $foreign["_liftEffect"]);
  var monadThrowAff = new Control_Monad_Error_Class.MonadThrow(function () {
    return monadAff;
  }, $foreign["_throwError"]);
  var monadErrorAff = new Control_Monad_Error_Class.MonadError(function () {
    return monadThrowAff;
  }, $foreign["_catchError"]);

  var runAff = function runAff(k) {
    return function (aff) {
      return launchAff(Control_Bind.bindFlipped(bindAff)(function ($52) {
        return Effect_Class.liftEffect(monadEffectAff)(k($52));
      })(Control_Monad_Error_Class["try"](monadErrorAff)(aff)));
    };
  };

  var runAff_ = function runAff_(k) {
    return function (aff) {
      return Data_Functor["void"](Effect.functorEffect)(runAff(k)(aff));
    };
  };

  var parallelAff = new Control_Parallel_Class.Parallel(function () {
    return applicativeParAff;
  }, function () {
    return monadAff;
  }, Unsafe_Coerce.unsafeCoerce, $foreign["_sequential"]);
  var applicativeParAff = new Control_Applicative.Applicative(function () {
    return applyParAff;
  }, function ($54) {
    return Control_Parallel_Class.parallel(parallelAff)(Control_Applicative.pure(applicativeAff)($54));
  });
  var semigroupCanceler = new Data_Semigroup.Semigroup(function (v) {
    return function (v1) {
      return function (err) {
        return Control_Parallel.parSequence_(parallelAff)(Data_Foldable.foldableArray)([v(err), v1(err)]);
      };
    };
  });
  var nonCanceler = Data_Function["const"](Control_Applicative.pure(applicativeAff)(Data_Unit.unit));
  var monoidCanceler = new Data_Monoid.Monoid(function () {
    return semigroupCanceler;
  }, nonCanceler);
  exports["launchAff"] = launchAff;
  exports["runAff"] = runAff;
  exports["runAff_"] = runAff_;
  exports["nonCanceler"] = nonCanceler;
  exports["functorAff"] = functorAff;
  exports["applyAff"] = applyAff;
  exports["applicativeAff"] = applicativeAff;
  exports["bindAff"] = bindAff;
  exports["monadAff"] = monadAff;
  exports["monadThrowAff"] = monadThrowAff;
  exports["monadErrorAff"] = monadErrorAff;
  exports["monadEffectAff"] = monadEffectAff;
  exports["functorParAff"] = functorParAff;
  exports["applyParAff"] = applyParAff;
  exports["applicativeParAff"] = applicativeParAff;
  exports["parallelAff"] = parallelAff;
  exports["semigroupCanceler"] = semigroupCanceler;
  exports["monoidCanceler"] = monoidCanceler;
  exports["makeAff"] = $foreign.makeAff;
})(PS["Effect.Aff"] = PS["Effect.Aff"] || {});

(function (exports) {
  "use strict";

  exports.mkEffectFn1 = function mkEffectFn1(fn) {
    return function (x) {
      return fn(x)();
    };
  };

  exports.runEffectFn3 = function runEffectFn3(fn) {
    return function (a) {
      return function (b) {
        return function (c) {
          return function () {
            return fn(a, b, c);
          };
        };
      };
    };
  };
})(PS["Effect.Uncurried"] = PS["Effect.Uncurried"] || {});

(function (exports) {
  // Generated by purs version 0.12.3
  "use strict";

  var $foreign = PS["Effect.Uncurried"];
  var Effect = PS["Effect"];
  exports["mkEffectFn1"] = $foreign.mkEffectFn1;
  exports["runEffectFn3"] = $foreign.runEffectFn3;
})(PS["Effect.Uncurried"] = PS["Effect.Uncurried"] || {});

(function (exports) {
  "use strict";

  exports.unsafeFromForeign = function (value) {
    return value;
  };

  exports.tagOf = function (value) {
    return Object.prototype.toString.call(value).slice(8, -1);
  };
})(PS["Foreign"] = PS["Foreign"] || {});

(function (exports) {
  "use strict";

  exports.fromNumberImpl = function (just) {
    return function (nothing) {
      return function (n) {
        /* jshint bitwise: false */
        return (n | 0) === n ? just(n) : nothing;
      };
    };
  };

  exports.toNumber = function (n) {
    return n;
  };

  exports.fromStringAsImpl = function (just) {
    return function (nothing) {
      return function (radix) {
        var digits;

        if (radix < 11) {
          digits = "[0-" + (radix - 1).toString() + "]";
        } else if (radix === 11) {
          digits = "[0-9a]";
        } else {
          digits = "[0-9a-" + String.fromCharCode(86 + radix) + "]";
        }

        var pattern = new RegExp("^[\\+\\-]?" + digits + "+$", "i");
        return function (s) {
          /* jshint bitwise: false */
          if (pattern.test(s)) {
            var i = parseInt(s, radix);
            return (i | 0) === i ? just(i) : nothing;
          } else {
            return nothing;
          }
        };
      };
    };
  };
})(PS["Data.Int"] = PS["Data.Int"] || {});

(function (exports) {
  /* globals exports */
  "use strict";

  exports.infinity = Infinity;
  exports.readFloat = parseFloat;
})(PS["Global"] = PS["Global"] || {});

(function (exports) {
  // Generated by purs version 0.12.3
  "use strict";

  var $foreign = PS["Global"];
  exports["infinity"] = $foreign.infinity;
  exports["readFloat"] = $foreign.readFloat;
})(PS["Global"] = PS["Global"] || {});

(function (exports) {
  "use strict";

  exports.floor = Math.floor;
  exports.round = Math.round;
})(PS["Math"] = PS["Math"] || {});

(function (exports) {
  // Generated by purs version 0.12.3
  "use strict";

  var $foreign = PS["Math"];
  exports["floor"] = $foreign.floor;
  exports["round"] = $foreign.round;
})(PS["Math"] = PS["Math"] || {});

(function (exports) {
  // Generated by purs version 0.12.3
  "use strict";

  var $foreign = PS["Data.Int"];
  var Control_Category = PS["Control.Category"];
  var Control_Semigroupoid = PS["Control.Semigroupoid"];
  var Data_Boolean = PS["Data.Boolean"];
  var Data_Bounded = PS["Data.Bounded"];
  var Data_CommutativeRing = PS["Data.CommutativeRing"];
  var Data_DivisionRing = PS["Data.DivisionRing"];
  var Data_Eq = PS["Data.Eq"];
  var Data_EuclideanRing = PS["Data.EuclideanRing"];
  var Data_HeytingAlgebra = PS["Data.HeytingAlgebra"];
  var Data_Int_Bits = PS["Data.Int.Bits"];
  var Data_Maybe = PS["Data.Maybe"];
  var Data_Ord = PS["Data.Ord"];
  var Data_Ordering = PS["Data.Ordering"];
  var Data_Ring = PS["Data.Ring"];
  var Data_Semiring = PS["Data.Semiring"];
  var Data_Show = PS["Data.Show"];
  var Global = PS["Global"];
  var $$Math = PS["Math"];
  var Prelude = PS["Prelude"];
  var fromStringAs = $foreign.fromStringAsImpl(Data_Maybe.Just.create)(Data_Maybe.Nothing.value);
  var fromString = fromStringAs(10);
  var fromNumber = $foreign.fromNumberImpl(Data_Maybe.Just.create)(Data_Maybe.Nothing.value);

  var unsafeClamp = function unsafeClamp(x) {
    if (x === Global.infinity) {
      return 0;
    }

    ;

    if (x === -Global.infinity) {
      return 0;
    }

    ;

    if (x >= $foreign.toNumber(Data_Bounded.top(Data_Bounded.boundedInt))) {
      return Data_Bounded.top(Data_Bounded.boundedInt);
    }

    ;

    if (x <= $foreign.toNumber(Data_Bounded.bottom(Data_Bounded.boundedInt))) {
      return Data_Bounded.bottom(Data_Bounded.boundedInt);
    }

    ;

    if (Data_Boolean.otherwise) {
      return Data_Maybe.fromMaybe(0)(fromNumber(x));
    }

    ;
    throw new Error("Failed pattern match at Data.Int (line 66, column 1 - line 66, column 29): " + [x.constructor.name]);
  };

  var round = function round($23) {
    return unsafeClamp($$Math.round($23));
  };

  var floor = function floor($24) {
    return unsafeClamp($$Math.floor($24));
  };

  var decimal = 10;
  exports["fromNumber"] = fromNumber;
  exports["floor"] = floor;
  exports["round"] = round;
  exports["fromString"] = fromString;
  exports["decimal"] = decimal;
  exports["fromStringAs"] = fromStringAs;
  exports["toNumber"] = $foreign.toNumber;
})(PS["Data.Int"] = PS["Data.Int"] || {});

(function (exports) {
  // Generated by purs version 0.12.3
  "use strict";

  var Control_Alt = PS["Control.Alt"];
  var Control_Alternative = PS["Control.Alternative"];
  var Control_Applicative = PS["Control.Applicative"];
  var Control_Apply = PS["Control.Apply"];
  var Control_Bind = PS["Control.Bind"];
  var Control_Category = PS["Control.Category"];
  var Control_Lazy = PS["Control.Lazy"];
  var Control_Monad_Rec_Class = PS["Control.Monad.Rec.Class"];
  var Control_Semigroupoid = PS["Control.Semigroupoid"];
  var Data_Bifunctor = PS["Data.Bifunctor"];
  var Data_Boolean = PS["Data.Boolean"];
  var Data_Eq = PS["Data.Eq"];
  var Data_Foldable = PS["Data.Foldable"];
  var Data_Function = PS["Data.Function"];
  var Data_Functor = PS["Data.Functor"];
  var Data_FunctorWithIndex = PS["Data.FunctorWithIndex"];
  var Data_HeytingAlgebra = PS["Data.HeytingAlgebra"];
  var Data_List_Types = PS["Data.List.Types"];
  var Data_Maybe = PS["Data.Maybe"];
  var Data_Newtype = PS["Data.Newtype"];
  var Data_NonEmpty = PS["Data.NonEmpty"];
  var Data_Ord = PS["Data.Ord"];
  var Data_Ordering = PS["Data.Ordering"];
  var Data_Ring = PS["Data.Ring"];
  var Data_Semigroup = PS["Data.Semigroup"];
  var Data_Semiring = PS["Data.Semiring"];
  var Data_Show = PS["Data.Show"];
  var Data_Traversable = PS["Data.Traversable"];
  var Data_Tuple = PS["Data.Tuple"];
  var Data_Unfoldable = PS["Data.Unfoldable"];
  var Data_Unit = PS["Data.Unit"];
  var Prelude = PS["Prelude"];

  var uncons = function uncons(v) {
    if (v instanceof Data_List_Types.Nil) {
      return Data_Maybe.Nothing.value;
    }

    ;

    if (v instanceof Data_List_Types.Cons) {
      return new Data_Maybe.Just({
        head: v.value0,
        tail: v.value1
      });
    }

    ;
    throw new Error("Failed pattern match at Data.List (line 259, column 1 - line 259, column 66): " + [v.constructor.name]);
  };

  var span = function span(v) {
    return function (v1) {
      if (v1 instanceof Data_List_Types.Cons && v(v1.value0)) {
        var v2 = span(v)(v1.value1);
        return {
          init: new Data_List_Types.Cons(v1.value0, v2.init),
          rest: v2.rest
        };
      }

      ;
      return {
        init: Data_List_Types.Nil.value,
        rest: v1
      };
    };
  };

  var reverse = function () {
    var go = function go($copy_acc) {
      return function ($copy_v) {
        var $tco_var_acc = $copy_acc;
        var $tco_done = false;
        var $tco_result;

        function $tco_loop(acc, v) {
          if (v instanceof Data_List_Types.Nil) {
            $tco_done = true;
            return acc;
          }

          ;

          if (v instanceof Data_List_Types.Cons) {
            $tco_var_acc = new Data_List_Types.Cons(v.value0, acc);
            $copy_v = v.value1;
            return;
          }

          ;
          throw new Error("Failed pattern match at Data.List (line 368, column 3 - line 368, column 19): " + [acc.constructor.name, v.constructor.name]);
        }

        ;

        while (!$tco_done) {
          $tco_result = $tco_loop($tco_var_acc, $copy_v);
        }

        ;
        return $tco_result;
      };
    };

    return go(Data_List_Types.Nil.value);
  }();

  var take = function () {
    var go = function go($copy_acc) {
      return function ($copy_v) {
        return function ($copy_v1) {
          var $tco_var_acc = $copy_acc;
          var $tco_var_v = $copy_v;
          var $tco_done = false;
          var $tco_result;

          function $tco_loop(acc, v, v1) {
            if (v < 1) {
              $tco_done = true;
              return reverse(acc);
            }

            ;

            if (v1 instanceof Data_List_Types.Nil) {
              $tco_done = true;
              return reverse(acc);
            }

            ;

            if (v1 instanceof Data_List_Types.Cons) {
              $tco_var_acc = new Data_List_Types.Cons(v1.value0, acc);
              $tco_var_v = v - 1 | 0;
              $copy_v1 = v1.value1;
              return;
            }

            ;
            throw new Error("Failed pattern match at Data.List (line 520, column 3 - line 520, column 35): " + [acc.constructor.name, v.constructor.name, v1.constructor.name]);
          }

          ;

          while (!$tco_done) {
            $tco_result = $tco_loop($tco_var_acc, $tco_var_v, $copy_v1);
          }

          ;
          return $tco_result;
        };
      };
    };

    return go(Data_List_Types.Nil.value);
  }();

  var takeWhile = function takeWhile(p) {
    var go = function go($copy_acc) {
      return function ($copy_v) {
        var $tco_var_acc = $copy_acc;
        var $tco_done = false;
        var $tco_result;

        function $tco_loop(acc, v) {
          if (v instanceof Data_List_Types.Cons && p(v.value0)) {
            $tco_var_acc = new Data_List_Types.Cons(v.value0, acc);
            $copy_v = v.value1;
            return;
          }

          ;
          $tco_done = true;
          return reverse(acc);
        }

        ;

        while (!$tco_done) {
          $tco_result = $tco_loop($tco_var_acc, $copy_v);
        }

        ;
        return $tco_result;
      };
    };

    return go(Data_List_Types.Nil.value);
  };

  var unsnoc = function unsnoc(lst) {
    var go = function go($copy_v) {
      return function ($copy_acc) {
        var $tco_var_v = $copy_v;
        var $tco_done = false;
        var $tco_result;

        function $tco_loop(v, acc) {
          if (v instanceof Data_List_Types.Nil) {
            $tco_done = true;
            return Data_Maybe.Nothing.value;
          }

          ;

          if (v instanceof Data_List_Types.Cons && v.value1 instanceof Data_List_Types.Nil) {
            $tco_done = true;
            return new Data_Maybe.Just({
              revInit: acc,
              last: v.value0
            });
          }

          ;

          if (v instanceof Data_List_Types.Cons) {
            $tco_var_v = v.value1;
            $copy_acc = new Data_List_Types.Cons(v.value0, acc);
            return;
          }

          ;
          throw new Error("Failed pattern match at Data.List (line 270, column 3 - line 270, column 23): " + [v.constructor.name, acc.constructor.name]);
        }

        ;

        while (!$tco_done) {
          $tco_result = $tco_loop($tco_var_v, $copy_acc);
        }

        ;
        return $tco_result;
      };
    };

    return Data_Functor.map(Data_Maybe.functorMaybe)(function (h) {
      return {
        init: reverse(h.revInit),
        last: h.last
      };
    })(go(lst)(Data_List_Types.Nil.value));
  };

  var zipWith = function zipWith(f) {
    return function (xs) {
      return function (ys) {
        var go = function go($copy_v) {
          return function ($copy_v1) {
            return function ($copy_acc) {
              var $tco_var_v = $copy_v;
              var $tco_var_v1 = $copy_v1;
              var $tco_done = false;
              var $tco_result;

              function $tco_loop(v, v1, acc) {
                if (v instanceof Data_List_Types.Nil) {
                  $tco_done = true;
                  return acc;
                }

                ;

                if (v1 instanceof Data_List_Types.Nil) {
                  $tco_done = true;
                  return acc;
                }

                ;

                if (v instanceof Data_List_Types.Cons && v1 instanceof Data_List_Types.Cons) {
                  $tco_var_v = v.value1;
                  $tco_var_v1 = v1.value1;
                  $copy_acc = new Data_List_Types.Cons(f(v.value0)(v1.value0), acc);
                  return;
                }

                ;
                throw new Error("Failed pattern match at Data.List (line 718, column 3 - line 718, column 21): " + [v.constructor.name, v1.constructor.name, acc.constructor.name]);
              }

              ;

              while (!$tco_done) {
                $tco_result = $tco_loop($tco_var_v, $tco_var_v1, $copy_acc);
              }

              ;
              return $tco_result;
            };
          };
        };

        return reverse(go(xs)(ys)(Data_List_Types.Nil.value));
      };
    };
  };

  var zip = zipWith(Data_Tuple.Tuple.create);

  var $$null = function $$null(v) {
    if (v instanceof Data_List_Types.Nil) {
      return true;
    }

    ;
    return false;
  };

  var mapMaybe = function mapMaybe(f) {
    var go = function go($copy_acc) {
      return function ($copy_v) {
        var $tco_var_acc = $copy_acc;
        var $tco_done = false;
        var $tco_result;

        function $tco_loop(acc, v) {
          if (v instanceof Data_List_Types.Nil) {
            $tco_done = true;
            return reverse(acc);
          }

          ;

          if (v instanceof Data_List_Types.Cons) {
            var v1 = f(v.value0);

            if (v1 instanceof Data_Maybe.Nothing) {
              $tco_var_acc = acc;
              $copy_v = v.value1;
              return;
            }

            ;

            if (v1 instanceof Data_Maybe.Just) {
              $tco_var_acc = new Data_List_Types.Cons(v1.value0, acc);
              $copy_v = v.value1;
              return;
            }

            ;
            throw new Error("Failed pattern match at Data.List (line 419, column 5 - line 421, column 32): " + [v1.constructor.name]);
          }

          ;
          throw new Error("Failed pattern match at Data.List (line 417, column 3 - line 417, column 27): " + [acc.constructor.name, v.constructor.name]);
        }

        ;

        while (!$tco_done) {
          $tco_result = $tco_loop($tco_var_acc, $copy_v);
        }

        ;
        return $tco_result;
      };
    };

    return go(Data_List_Types.Nil.value);
  };

  var some = function some(dictAlternative) {
    return function (dictLazy) {
      return function (v) {
        return Control_Apply.apply(dictAlternative.Applicative0().Apply0())(Data_Functor.map(dictAlternative.Plus1().Alt0().Functor0())(Data_List_Types.Cons.create)(v))(Control_Lazy.defer(dictLazy)(function (v1) {
          return many(dictAlternative)(dictLazy)(v);
        }));
      };
    };
  };

  var many = function many(dictAlternative) {
    return function (dictLazy) {
      return function (v) {
        return Control_Alt.alt(dictAlternative.Plus1().Alt0())(some(dictAlternative)(dictLazy)(v))(Control_Applicative.pure(dictAlternative.Applicative0())(Data_List_Types.Nil.value));
      };
    };
  };

  var length = Data_Foldable.foldl(Data_List_Types.foldableList)(function (acc) {
    return function (v) {
      return acc + 1 | 0;
    };
  })(0);

  var last = function last($copy_v) {
    var $tco_done = false;
    var $tco_result;

    function $tco_loop(v) {
      if (v instanceof Data_List_Types.Cons && v.value1 instanceof Data_List_Types.Nil) {
        $tco_done = true;
        return new Data_Maybe.Just(v.value0);
      }

      ;

      if (v instanceof Data_List_Types.Cons) {
        $copy_v = v.value1;
        return;
      }

      ;
      $tco_done = true;
      return Data_Maybe.Nothing.value;
    }

    ;

    while (!$tco_done) {
      $tco_result = $tco_loop($copy_v);
    }

    ;
    return $tco_result;
  };

  var insertAt = function insertAt(v) {
    return function (v1) {
      return function (v2) {
        if (v === 0) {
          return new Data_Maybe.Just(new Data_List_Types.Cons(v1, v2));
        }

        ;

        if (v2 instanceof Data_List_Types.Cons) {
          return Data_Functor.map(Data_Maybe.functorMaybe)(function (v3) {
            return new Data_List_Types.Cons(v2.value0, v3);
          })(insertAt(v - 1 | 0)(v1)(v2.value1));
        }

        ;
        return Data_Maybe.Nothing.value;
      };
    };
  };

  var init = function init(lst) {
    return Data_Functor.map(Data_Maybe.functorMaybe)(function (v) {
      return v.init;
    })(unsnoc(lst));
  };

  var head = function head(v) {
    if (v instanceof Data_List_Types.Nil) {
      return Data_Maybe.Nothing.value;
    }

    ;

    if (v instanceof Data_List_Types.Cons) {
      return new Data_Maybe.Just(v.value0);
    }

    ;
    throw new Error("Failed pattern match at Data.List (line 230, column 1 - line 230, column 22): " + [v.constructor.name]);
  };

  var fromFoldable = function fromFoldable(dictFoldable) {
    return Data_Foldable.foldr(dictFoldable)(Data_List_Types.Cons.create)(Data_List_Types.Nil.value);
  };

  var findIndex = function findIndex(fn) {
    var go = function go($copy_v) {
      return function ($copy_v1) {
        var $tco_var_v = $copy_v;
        var $tco_done = false;
        var $tco_result;

        function $tco_loop(v, v1) {
          if (v1 instanceof Data_List_Types.Cons) {
            if (fn(v1.value0)) {
              $tco_done = true;
              return new Data_Maybe.Just(v);
            }

            ;

            if (Data_Boolean.otherwise) {
              $tco_var_v = v + 1 | 0;
              $copy_v1 = v1.value1;
              return;
            }

            ;
          }

          ;

          if (v1 instanceof Data_List_Types.Nil) {
            $tco_done = true;
            return Data_Maybe.Nothing.value;
          }

          ;
          throw new Error("Failed pattern match at Data.List (line 301, column 3 - line 301, column 35): " + [v.constructor.name, v1.constructor.name]);
        }

        ;

        while (!$tco_done) {
          $tco_result = $tco_loop($tco_var_v, $copy_v1);
        }

        ;
        return $tco_result;
      };
    };

    return go(0);
  };

  var filter = function filter(p) {
    var go = function go($copy_acc) {
      return function ($copy_v) {
        var $tco_var_acc = $copy_acc;
        var $tco_done = false;
        var $tco_result;

        function $tco_loop(acc, v) {
          if (v instanceof Data_List_Types.Nil) {
            $tco_done = true;
            return reverse(acc);
          }

          ;

          if (v instanceof Data_List_Types.Cons) {
            if (p(v.value0)) {
              $tco_var_acc = new Data_List_Types.Cons(v.value0, acc);
              $copy_v = v.value1;
              return;
            }

            ;

            if (Data_Boolean.otherwise) {
              $tco_var_acc = acc;
              $copy_v = v.value1;
              return;
            }

            ;
          }

          ;
          throw new Error("Failed pattern match at Data.List (line 390, column 3 - line 390, column 27): " + [acc.constructor.name, v.constructor.name]);
        }

        ;

        while (!$tco_done) {
          $tco_result = $tco_loop($tco_var_acc, $copy_v);
        }

        ;
        return $tco_result;
      };
    };

    return go(Data_List_Types.Nil.value);
  };

  var elemIndex = function elemIndex(dictEq) {
    return function (x) {
      return findIndex(function (v) {
        return Data_Eq.eq(dictEq)(v)(x);
      });
    };
  };

  var dropWhile = function dropWhile(p) {
    var go = function go($copy_v) {
      var $tco_done = false;
      var $tco_result;

      function $tco_loop(v) {
        if (v instanceof Data_List_Types.Cons && p(v.value0)) {
          $copy_v = v.value1;
          return;
        }

        ;
        $tco_done = true;
        return v;
      }

      ;

      while (!$tco_done) {
        $tco_result = $tco_loop($copy_v);
      }

      ;
      return $tco_result;
    };

    return go;
  };

  var drop = function drop($copy_v) {
    return function ($copy_v1) {
      var $tco_var_v = $copy_v;
      var $tco_done = false;
      var $tco_result;

      function $tco_loop(v, v1) {
        if (v < 1) {
          $tco_done = true;
          return v1;
        }

        ;

        if (v1 instanceof Data_List_Types.Nil) {
          $tco_done = true;
          return Data_List_Types.Nil.value;
        }

        ;

        if (v1 instanceof Data_List_Types.Cons) {
          $tco_var_v = v - 1 | 0;
          $copy_v1 = v1.value1;
          return;
        }

        ;
        throw new Error("Failed pattern match at Data.List (line 543, column 1 - line 543, column 42): " + [v.constructor.name, v1.constructor.name]);
      }

      ;

      while (!$tco_done) {
        $tco_result = $tco_loop($tco_var_v, $copy_v1);
      }

      ;
      return $tco_result;
    };
  };

  var deleteAt = function deleteAt(v) {
    return function (v1) {
      if (v === 0 && v1 instanceof Data_List_Types.Cons) {
        return new Data_Maybe.Just(v1.value1);
      }

      ;

      if (v1 instanceof Data_List_Types.Cons) {
        return Data_Functor.map(Data_Maybe.functorMaybe)(function (v2) {
          return new Data_List_Types.Cons(v1.value0, v2);
        })(deleteAt(v - 1 | 0)(v1.value1));
      }

      ;
      return Data_Maybe.Nothing.value;
    };
  };

  exports["fromFoldable"] = fromFoldable;
  exports["some"] = some;
  exports["many"] = many;
  exports["null"] = $$null;
  exports["length"] = length;
  exports["head"] = head;
  exports["last"] = last;
  exports["init"] = init;
  exports["uncons"] = uncons;
  exports["unsnoc"] = unsnoc;
  exports["elemIndex"] = elemIndex;
  exports["findIndex"] = findIndex;
  exports["insertAt"] = insertAt;
  exports["deleteAt"] = deleteAt;
  exports["reverse"] = reverse;
  exports["filter"] = filter;
  exports["mapMaybe"] = mapMaybe;
  exports["take"] = take;
  exports["takeWhile"] = takeWhile;
  exports["drop"] = drop;
  exports["dropWhile"] = dropWhile;
  exports["span"] = span;
  exports["zipWith"] = zipWith;
  exports["zip"] = zip;
})(PS["Data.List"] = PS["Data.List"] || {});

(function (exports) {
  // Generated by purs version 0.12.3
  "use strict";

  var Control_Bind = PS["Control.Bind"];
  var Control_Category = PS["Control.Category"];
  var Control_Semigroupoid = PS["Control.Semigroupoid"];
  var Data_Boolean = PS["Data.Boolean"];
  var Data_Eq = PS["Data.Eq"];
  var Data_Foldable = PS["Data.Foldable"];
  var Data_Function = PS["Data.Function"];
  var Data_Functor = PS["Data.Functor"];
  var Data_FunctorWithIndex = PS["Data.FunctorWithIndex"];
  var Data_List = PS["Data.List"];
  var Data_List_Types = PS["Data.List.Types"];
  var Data_Maybe = PS["Data.Maybe"];
  var Data_NonEmpty = PS["Data.NonEmpty"];
  var Data_Ord = PS["Data.Ord"];
  var Data_Ring = PS["Data.Ring"];
  var Data_Semigroup = PS["Data.Semigroup"];
  var Data_Semigroup_Foldable = PS["Data.Semigroup.Foldable"];
  var Data_Semigroup_Traversable = PS["Data.Semigroup.Traversable"];
  var Data_Semiring = PS["Data.Semiring"];
  var Data_Traversable = PS["Data.Traversable"];
  var Data_Tuple = PS["Data.Tuple"];
  var Data_Unfoldable = PS["Data.Unfoldable"];
  var Partial_Unsafe = PS["Partial.Unsafe"];
  var Prelude = PS["Prelude"];

  var singleton = function singleton($165) {
    return Data_List_Types.NonEmptyList(Data_NonEmpty.singleton(Data_List_Types.plusList)($165));
  };

  exports["singleton"] = singleton;
})(PS["Data.List.NonEmpty"] = PS["Data.List.NonEmpty"] || {});

(function (exports) {
  "use strict";

  exports.fromCharArray = function (a) {
    return a.join("");
  };

  exports.toCharArray = function (s) {
    return s.split("");
  };

  exports.singleton = function (c) {
    return c;
  };

  exports._charAt = function (just) {
    return function (nothing) {
      return function (i) {
        return function (s) {
          return i >= 0 && i < s.length ? just(s.charAt(i)) : nothing;
        };
      };
    };
  };

  exports.length = function (s) {
    return s.length;
  };

  exports._indexOf = function (just) {
    return function (nothing) {
      return function (x) {
        return function (s) {
          var i = s.indexOf(x);
          return i === -1 ? nothing : just(i);
        };
      };
    };
  };

  exports.take = function (n) {
    return function (s) {
      return s.substr(0, n);
    };
  };

  exports.drop = function (n) {
    return function (s) {
      return s.substring(n);
    };
  };
})(PS["Data.String.CodeUnits"] = PS["Data.String.CodeUnits"] || {});

(function (exports) {
  // Generated by purs version 0.12.3
  "use strict";

  var Data_Eq = PS["Data.Eq"];
  var Data_Newtype = PS["Data.Newtype"];
  var Data_Ord = PS["Data.Ord"];
  var Data_Semigroup = PS["Data.Semigroup"];
  var Data_Show = PS["Data.Show"];
  var Prelude = PS["Prelude"];

  var Pattern = function Pattern(x) {
    return x;
  };

  var newtypePattern = new Data_Newtype.Newtype(function (n) {
    return n;
  }, Pattern);
  exports["Pattern"] = Pattern;
  exports["newtypePattern"] = newtypePattern;
})(PS["Data.String.Pattern"] = PS["Data.String.Pattern"] || {});

(function (exports) {
  "use strict";

  exports.charAt = function (i) {
    return function (s) {
      if (i >= 0 && i < s.length) return s.charAt(i);
      throw new Error("Data.String.Unsafe.charAt: Invalid index.");
    };
  };
})(PS["Data.String.Unsafe"] = PS["Data.String.Unsafe"] || {});

(function (exports) {
  // Generated by purs version 0.12.3
  "use strict";

  var $foreign = PS["Data.String.Unsafe"];
  exports["charAt"] = $foreign.charAt;
})(PS["Data.String.Unsafe"] = PS["Data.String.Unsafe"] || {});

(function (exports) {
  // Generated by purs version 0.12.3
  "use strict";

  var $foreign = PS["Data.String.CodeUnits"];
  var Control_Semigroupoid = PS["Control.Semigroupoid"];
  var Data_Boolean = PS["Data.Boolean"];
  var Data_Eq = PS["Data.Eq"];
  var Data_Function = PS["Data.Function"];
  var Data_HeytingAlgebra = PS["Data.HeytingAlgebra"];
  var Data_Maybe = PS["Data.Maybe"];
  var Data_Ord = PS["Data.Ord"];
  var Data_Ring = PS["Data.Ring"];
  var Data_Semiring = PS["Data.Semiring"];
  var Data_String_Pattern = PS["Data.String.Pattern"];
  var Data_String_Unsafe = PS["Data.String.Unsafe"];
  var Prelude = PS["Prelude"];

  var uncons = function uncons(v) {
    if (v === "") {
      return Data_Maybe.Nothing.value;
    }

    ;
    return new Data_Maybe.Just({
      head: Data_String_Unsafe.charAt(0)(v),
      tail: $foreign.drop(1)(v)
    });
  };

  var indexOf = $foreign["_indexOf"](Data_Maybe.Just.create)(Data_Maybe.Nothing.value);

  var contains = function contains(pat) {
    return function ($16) {
      return Data_Maybe.isJust(indexOf(pat)($16));
    };
  };

  var charAt = $foreign["_charAt"](Data_Maybe.Just.create)(Data_Maybe.Nothing.value);
  exports["contains"] = contains;
  exports["charAt"] = charAt;
  exports["uncons"] = uncons;
  exports["indexOf"] = indexOf;
  exports["singleton"] = $foreign.singleton;
  exports["fromCharArray"] = $foreign.fromCharArray;
  exports["toCharArray"] = $foreign.toCharArray;
  exports["length"] = $foreign.length;
  exports["take"] = $foreign.take;
  exports["drop"] = $foreign.drop;
})(PS["Data.String.CodeUnits"] = PS["Data.String.CodeUnits"] || {});

(function (exports) {
  // Generated by purs version 0.12.3
  "use strict";

  var $foreign = PS["Foreign"];
  var Control_Applicative = PS["Control.Applicative"];
  var Control_Monad_Error_Class = PS["Control.Monad.Error.Class"];
  var Control_Monad_Except = PS["Control.Monad.Except"];
  var Control_Monad_Except_Trans = PS["Control.Monad.Except.Trans"];
  var Control_Semigroupoid = PS["Control.Semigroupoid"];
  var Data_Boolean = PS["Data.Boolean"];
  var Data_Either = PS["Data.Either"];
  var Data_Eq = PS["Data.Eq"];
  var Data_Function = PS["Data.Function"];
  var Data_HeytingAlgebra = PS["Data.HeytingAlgebra"];
  var Data_Identity = PS["Data.Identity"];
  var Data_Int = PS["Data.Int"];
  var Data_List_NonEmpty = PS["Data.List.NonEmpty"];
  var Data_Maybe = PS["Data.Maybe"];
  var Data_Ord = PS["Data.Ord"];
  var Data_Ordering = PS["Data.Ordering"];
  var Data_Semigroup = PS["Data.Semigroup"];
  var Data_Show = PS["Data.Show"];
  var Data_String_CodeUnits = PS["Data.String.CodeUnits"];
  var Prelude = PS["Prelude"];

  var ForeignError = function () {
    function ForeignError(value0) {
      this.value0 = value0;
    }

    ;

    ForeignError.create = function (value0) {
      return new ForeignError(value0);
    };

    return ForeignError;
  }();

  var TypeMismatch = function () {
    function TypeMismatch(value0, value1) {
      this.value0 = value0;
      this.value1 = value1;
    }

    ;

    TypeMismatch.create = function (value0) {
      return function (value1) {
        return new TypeMismatch(value0, value1);
      };
    };

    return TypeMismatch;
  }();

  var ErrorAtIndex = function () {
    function ErrorAtIndex(value0, value1) {
      this.value0 = value0;
      this.value1 = value1;
    }

    ;

    ErrorAtIndex.create = function (value0) {
      return function (value1) {
        return new ErrorAtIndex(value0, value1);
      };
    };

    return ErrorAtIndex;
  }();

  var ErrorAtProperty = function () {
    function ErrorAtProperty(value0, value1) {
      this.value0 = value0;
      this.value1 = value1;
    }

    ;

    ErrorAtProperty.create = function (value0) {
      return function (value1) {
        return new ErrorAtProperty(value0, value1);
      };
    };

    return ErrorAtProperty;
  }();

  var showForeignError = new Data_Show.Show(function (v) {
    if (v instanceof ForeignError) {
      return "(ForeignError " + (Data_Show.show(Data_Show.showString)(v.value0) + ")");
    }

    ;

    if (v instanceof ErrorAtIndex) {
      return "(ErrorAtIndex " + (Data_Show.show(Data_Show.showInt)(v.value0) + (" " + (Data_Show.show(showForeignError)(v.value1) + ")")));
    }

    ;

    if (v instanceof ErrorAtProperty) {
      return "(ErrorAtProperty " + (Data_Show.show(Data_Show.showString)(v.value0) + (" " + (Data_Show.show(showForeignError)(v.value1) + ")")));
    }

    ;

    if (v instanceof TypeMismatch) {
      return "(TypeMismatch " + (Data_Show.show(Data_Show.showString)(v.value0) + (" " + (Data_Show.show(Data_Show.showString)(v.value1) + ")")));
    }

    ;
    throw new Error("Failed pattern match at Foreign (line 63, column 1 - line 63, column 47): " + [v.constructor.name]);
  });

  var fail = function fail($107) {
    return Control_Monad_Error_Class.throwError(Control_Monad_Except_Trans.monadThrowExceptT(Data_Identity.monadIdentity))(Data_List_NonEmpty.singleton($107));
  };

  var unsafeReadTagged = function unsafeReadTagged(tag) {
    return function (value) {
      if ($foreign.tagOf(value) === tag) {
        return Control_Applicative.pure(Control_Monad_Except_Trans.applicativeExceptT(Data_Identity.monadIdentity))($foreign.unsafeFromForeign(value));
      }

      ;

      if (Data_Boolean.otherwise) {
        return fail(new TypeMismatch(tag, $foreign.tagOf(value)));
      }

      ;
      throw new Error("Failed pattern match at Foreign (line 106, column 1 - line 106, column 55): " + [tag.constructor.name, value.constructor.name]);
    };
  };

  var readString = unsafeReadTagged("String");
  exports["ForeignError"] = ForeignError;
  exports["TypeMismatch"] = TypeMismatch;
  exports["ErrorAtIndex"] = ErrorAtIndex;
  exports["ErrorAtProperty"] = ErrorAtProperty;
  exports["unsafeReadTagged"] = unsafeReadTagged;
  exports["readString"] = readString;
  exports["fail"] = fail;
  exports["showForeignError"] = showForeignError;
})(PS["Foreign"] = PS["Foreign"] || {});

(function (exports) {
  // Generated by purs version 0.12.3
  "use strict";

  var $foreign = PS["Control.Promise"];
  var Control_Alt = PS["Control.Alt"];
  var Control_Bind = PS["Control.Bind"];
  var Control_Category = PS["Control.Category"];
  var Control_Monad_Except = PS["Control.Monad.Except"];
  var Control_Monad_Except_Trans = PS["Control.Monad.Except.Trans"];
  var Control_Semigroupoid = PS["Control.Semigroupoid"];
  var Data_Either = PS["Data.Either"];
  var Data_Function = PS["Data.Function"];
  var Data_Functor = PS["Data.Functor"];
  var Data_Identity = PS["Data.Identity"];
  var Data_List_Types = PS["Data.List.Types"];
  var Data_Monoid = PS["Data.Monoid"];
  var Effect = PS["Effect"];
  var Effect_Aff = PS["Effect.Aff"];
  var Effect_Class = PS["Effect.Class"];
  var Effect_Exception = PS["Effect.Exception"];
  var Effect_Uncurried = PS["Effect.Uncurried"];
  var Foreign = PS["Foreign"];
  var Prelude = PS["Prelude"];

  var coerce = function coerce(fn) {
    return Data_Either.either(function (v) {
      return Effect_Exception.error("Promise failed, couldn't extract JS Error or String");
    })(Control_Category.identity(Control_Category.categoryFn))(Control_Monad_Except.runExcept(Control_Alt.alt(Control_Monad_Except_Trans.altExceptT(Data_List_Types.semigroupNonEmptyList)(Data_Identity.monadIdentity))(Foreign.unsafeReadTagged("Error")(fn))(Data_Functor.map(Control_Monad_Except_Trans.functorExceptT(Data_Identity.functorIdentity))(Effect_Exception.error)(Foreign.readString(fn)))));
  };

  var toAff = function toAff(p) {
    return Effect_Aff.makeAff(function (cb) {
      return Data_Functor.voidRight(Effect.functorEffect)(Data_Monoid.mempty(Effect_Aff.monoidCanceler))($foreign.thenImpl(p)(function ($1) {
        return cb(Data_Either.Left.create(coerce($1)))();
      })(function ($2) {
        return cb(Data_Either.Right.create($2))();
      }));
    });
  };

  var toAffE = function toAffE(f) {
    return Control_Bind.bind(Effect_Aff.bindAff)(Effect_Class.liftEffect(Effect_Aff.monadEffectAff)(f))(toAff);
  };

  exports["toAff"] = toAff;
  exports["toAffE"] = toAffE;
})(PS["Control.Promise"] = PS["Control.Promise"] || {});

(function (exports) {
  "use strict";

  function id(x) {
    return x;
  }

  exports.fromBoolean = id;
  exports.fromNumber = id;
  exports.fromString = id;
  exports.fromObject = id;

  exports.stringify = function (j) {
    return JSON.stringify(j);
  };

  var objToString = Object.prototype.toString;

  function isArray(a) {
    return objToString.call(a) === "[object Array]";
  }

  exports._caseJson = function (isNull, isBool, isNum, isStr, isArr, isObj, j) {
    if (j == null) return isNull();else if (typeof j === "boolean") return isBool(j);else if (typeof j === "number") return isNum(j);else if (typeof j === "string") return isStr(j);else if (objToString.call(j) === "[object Array]") return isArr(j);else return isObj(j);
  };
})(PS["Data.Argonaut.Core"] = PS["Data.Argonaut.Core"] || {});

(function (exports) {
  "use strict";

  exports._copyST = function (m) {
    return function () {
      var r = {};

      for (var k in m) {
        if (hasOwnProperty.call(m, k)) {
          r[k] = m[k];
        }
      }

      return r;
    };
  };

  exports.empty = {};

  exports.runST = function (f) {
    return f();
  };

  exports._lookup = function (no, yes, k, m) {
    return k in m ? yes(m[k]) : no;
  };

  function toArrayWithKey(f) {
    return function (m) {
      var r = [];

      for (var k in m) {
        if (hasOwnProperty.call(m, k)) {
          r.push(f(k)(m[k]));
        }
      }

      return r;
    };
  }
})(PS["Foreign.Object"] = PS["Foreign.Object"] || {});

(function (exports) {
  "use strict"; //------------------------------------------------------------------------------
  // Array size ------------------------------------------------------------------
  //------------------------------------------------------------------------------

  exports.length = function (xs) {
    return xs.length;
  }; //------------------------------------------------------------------------------
  // Extending arrays ------------------------------------------------------------
  //------------------------------------------------------------------------------


  exports.cons = function (e) {
    return function (l) {
      return [e].concat(l);
    };
  };

  exports.snoc = function (l) {
    return function (e) {
      var l1 = l.slice();
      l1.push(e);
      return l1;
    };
  }; //------------------------------------------------------------------------------
  // Non-indexed reads -----------------------------------------------------------
  //------------------------------------------------------------------------------


  exports["uncons'"] = function (empty) {
    return function (next) {
      return function (xs) {
        return xs.length === 0 ? empty({}) : next(xs[0])(xs.slice(1));
      };
    };
  }; //------------------------------------------------------------------------------
  // Indexed operations ----------------------------------------------------------
  //------------------------------------------------------------------------------


  exports.indexImpl = function (just) {
    return function (nothing) {
      return function (xs) {
        return function (i) {
          return i < 0 || i >= xs.length ? nothing : just(xs[i]);
        };
      };
    };
  };

  exports.concat = function (xss) {
    if (xss.length <= 10000) {
      // This method is faster, but it crashes on big arrays.
      // So we use it when can and fallback to simple variant otherwise.
      return Array.prototype.concat.apply([], xss);
    }

    var result = [];

    for (var i = 0, l = xss.length; i < l; i++) {
      var xs = xss[i];

      for (var j = 0, m = xs.length; j < m; j++) {
        result.push(xs[j]);
      }
    }

    return result;
  }; //------------------------------------------------------------------------------
  // Subarrays -------------------------------------------------------------------
  //------------------------------------------------------------------------------


  exports.slice = function (s) {
    return function (e) {
      return function (l) {
        return l.slice(s, e);
      };
    };
  };

  exports.take = function (n) {
    return function (l) {
      return n < 1 ? [] : l.slice(0, n);
    };
  };

  exports.drop = function (n) {
    return function (l) {
      return n < 1 ? l : l.slice(n);
    };
  };
})(PS["Data.Array"] = PS["Data.Array"] || {});

(function (exports) {
  // Generated by purs version 0.12.3
  "use strict";

  var $foreign = PS["Data.Array.NonEmpty.Internal"];
  var Control_Alt = PS["Control.Alt"];
  var Control_Applicative = PS["Control.Applicative"];
  var Control_Apply = PS["Control.Apply"];
  var Control_Bind = PS["Control.Bind"];
  var Control_Monad = PS["Control.Monad"];
  var Data_Eq = PS["Data.Eq"];
  var Data_Foldable = PS["Data.Foldable"];
  var Data_FoldableWithIndex = PS["Data.FoldableWithIndex"];
  var Data_Functor = PS["Data.Functor"];
  var Data_FunctorWithIndex = PS["Data.FunctorWithIndex"];
  var Data_Ord = PS["Data.Ord"];
  var Data_Semigroup = PS["Data.Semigroup"];
  var Data_Semigroup_Foldable = PS["Data.Semigroup.Foldable"];
  var Data_Semigroup_Traversable = PS["Data.Semigroup.Traversable"];
  var Data_Show = PS["Data.Show"];
  var Data_Traversable = PS["Data.Traversable"];
  var Data_TraversableWithIndex = PS["Data.TraversableWithIndex"];
  var Data_Unfoldable1 = PS["Data.Unfoldable1"];
  var Prelude = PS["Prelude"];
  var semigroupNonEmptyArray = Data_Semigroup.semigroupArray;
  var functorNonEmptyArray = Data_Functor.functorArray;
  var foldableNonEmptyArray = Data_Foldable.foldableArray;
  exports["semigroupNonEmptyArray"] = semigroupNonEmptyArray;
  exports["functorNonEmptyArray"] = functorNonEmptyArray;
  exports["foldableNonEmptyArray"] = foldableNonEmptyArray;
})(PS["Data.Array.NonEmpty.Internal"] = PS["Data.Array.NonEmpty.Internal"] || {});

(function (exports) {
  // Generated by purs version 0.12.3
  "use strict";

  var $foreign = PS["Data.Array"];
  var Control_Alt = PS["Control.Alt"];
  var Control_Alternative = PS["Control.Alternative"];
  var Control_Applicative = PS["Control.Applicative"];
  var Control_Apply = PS["Control.Apply"];
  var Control_Bind = PS["Control.Bind"];
  var Control_Category = PS["Control.Category"];
  var Control_Lazy = PS["Control.Lazy"];
  var Control_Monad_Rec_Class = PS["Control.Monad.Rec.Class"];
  var Control_Monad_ST = PS["Control.Monad.ST"];
  var Control_Monad_ST_Internal = PS["Control.Monad.ST.Internal"];
  var Control_Semigroupoid = PS["Control.Semigroupoid"];
  var Data_Array_NonEmpty_Internal = PS["Data.Array.NonEmpty.Internal"];
  var Data_Array_ST = PS["Data.Array.ST"];
  var Data_Array_ST_Iterator = PS["Data.Array.ST.Iterator"];
  var Data_Boolean = PS["Data.Boolean"];
  var Data_Eq = PS["Data.Eq"];
  var Data_Foldable = PS["Data.Foldable"];
  var Data_Function = PS["Data.Function"];
  var Data_Functor = PS["Data.Functor"];
  var Data_HeytingAlgebra = PS["Data.HeytingAlgebra"];
  var Data_Maybe = PS["Data.Maybe"];
  var Data_Ord = PS["Data.Ord"];
  var Data_Ordering = PS["Data.Ordering"];
  var Data_Ring = PS["Data.Ring"];
  var Data_Semigroup = PS["Data.Semigroup"];
  var Data_Semiring = PS["Data.Semiring"];
  var Data_Traversable = PS["Data.Traversable"];
  var Data_Tuple = PS["Data.Tuple"];
  var Data_Unfoldable = PS["Data.Unfoldable"];
  var Partial_Unsafe = PS["Partial.Unsafe"];
  var Prelude = PS["Prelude"];
  var Unsafe_Coerce = PS["Unsafe.Coerce"];
  var uncons = $foreign["uncons'"](Data_Function["const"](Data_Maybe.Nothing.value))(function (x) {
    return function (xs) {
      return new Data_Maybe.Just({
        head: x,
        tail: xs
      });
    };
  });
  var tail = $foreign["uncons'"](Data_Function["const"](Data_Maybe.Nothing.value))(function (v) {
    return function (xs) {
      return new Data_Maybe.Just(xs);
    };
  });

  var singleton = function singleton(a) {
    return [a];
  };

  var $$null = function $$null(xs) {
    return $foreign.length(xs) === 0;
  };

  var some = function some(dictAlternative) {
    return function (dictLazy) {
      return function (v) {
        return Control_Apply.apply(dictAlternative.Applicative0().Apply0())(Data_Functor.map(dictAlternative.Plus1().Alt0().Functor0())($foreign.cons)(v))(Control_Lazy.defer(dictLazy)(function (v1) {
          return many(dictAlternative)(dictLazy)(v);
        }));
      };
    };
  };

  var many = function many(dictAlternative) {
    return function (dictLazy) {
      return function (v) {
        return Control_Alt.alt(dictAlternative.Plus1().Alt0())(some(dictAlternative)(dictLazy)(v))(Control_Applicative.pure(dictAlternative.Applicative0())([]));
      };
    };
  };

  var init = function init(xs) {
    if ($$null(xs)) {
      return Data_Maybe.Nothing.value;
    }

    ;

    if (Data_Boolean.otherwise) {
      return new Data_Maybe.Just($foreign.slice(0)($foreign.length(xs) - 1 | 0)(xs));
    }

    ;
    throw new Error("Failed pattern match at Data.Array (line 323, column 1 - line 323, column 45): " + [xs.constructor.name]);
  };

  var index = $foreign.indexImpl(Data_Maybe.Just.create)(Data_Maybe.Nothing.value);

  var last = function last(xs) {
    return index(xs)($foreign.length(xs) - 1 | 0);
  };

  var head = function head(xs) {
    return index(xs)(0);
  };

  exports["singleton"] = singleton;
  exports["some"] = some;
  exports["many"] = many;
  exports["head"] = head;
  exports["last"] = last;
  exports["tail"] = tail;
  exports["init"] = init;
  exports["uncons"] = uncons;
  exports["index"] = index;
  exports["length"] = $foreign.length;
  exports["cons"] = $foreign.cons;
  exports["snoc"] = $foreign.snoc;
  exports["take"] = $foreign.take;
  exports["drop"] = $foreign.drop;
})(PS["Data.Array"] = PS["Data.Array"] || {});

(function (exports) {
  "use strict";

  exports["new"] = function () {
    return {};
  };

  exports.poke = function (k) {
    return function (v) {
      return function (m) {
        return function () {
          m[k] = v;
          return m;
        };
      };
    };
  };
})(PS["Foreign.Object.ST"] = PS["Foreign.Object.ST"] || {});

(function (exports) {
  // Generated by purs version 0.12.3
  "use strict";

  var $foreign = PS["Foreign.Object.ST"];
  var Control_Monad_ST = PS["Control.Monad.ST"];
  var Data_Maybe = PS["Data.Maybe"];
  exports["new"] = $foreign["new"];
  exports["poke"] = $foreign.poke;
})(PS["Foreign.Object.ST"] = PS["Foreign.Object.ST"] || {});

(function (exports) {
  // Generated by purs version 0.12.3
  "use strict";

  var Type_Data_Boolean = PS["Type.Data.Boolean"];
  var Type_Data_Symbol = PS["Type.Data.Symbol"];
  var Type_Equality = PS["Type.Equality"];

  var RLProxy = function () {
    function RLProxy() {}

    ;
    RLProxy.value = new RLProxy();
    return RLProxy;
  }();

  exports["RLProxy"] = RLProxy;
})(PS["Type.Row"] = PS["Type.Row"] || {});

(function (exports) {
  // Generated by purs version 0.12.3
  "use strict";

  var Type_Equality = PS["Type.Equality"];
  var Type_Row = PS["Type.Row"];
  var Homogeneous = {};
  var HomogeneousRowList = {};
  var homogeneousRowListNil = HomogeneousRowList;

  var homogeneousRowListCons = function homogeneousRowListCons(dictHomogeneousRowList) {
    return function (dictTypeEquals) {
      return HomogeneousRowList;
    };
  };

  var homogeneous = function homogeneous(dictRowToList) {
    return function (dictHomogeneousRowList) {
      return Homogeneous;
    };
  };

  exports["Homogeneous"] = Homogeneous;
  exports["HomogeneousRowList"] = HomogeneousRowList;
  exports["homogeneous"] = homogeneous;
  exports["homogeneousRowListCons"] = homogeneousRowListCons;
  exports["homogeneousRowListNil"] = homogeneousRowListNil;
})(PS["Type.Row.Homogeneous"] = PS["Type.Row.Homogeneous"] || {});

(function (exports) {
  // Generated by purs version 0.12.3
  "use strict";

  var $foreign = PS["Foreign.Object"];
  var Control_Applicative = PS["Control.Applicative"];
  var Control_Apply = PS["Control.Apply"];
  var Control_Bind = PS["Control.Bind"];
  var Control_Category = PS["Control.Category"];
  var Control_Monad_ST = PS["Control.Monad.ST"];
  var Control_Monad_ST_Internal = PS["Control.Monad.ST.Internal"];
  var Control_Semigroupoid = PS["Control.Semigroupoid"];
  var Data_Array = PS["Data.Array"];
  var Data_Eq = PS["Data.Eq"];
  var Data_Foldable = PS["Data.Foldable"];
  var Data_FoldableWithIndex = PS["Data.FoldableWithIndex"];
  var Data_Function = PS["Data.Function"];
  var Data_Function_Uncurried = PS["Data.Function.Uncurried"];
  var Data_Functor = PS["Data.Functor"];
  var Data_FunctorWithIndex = PS["Data.FunctorWithIndex"];
  var Data_HeytingAlgebra = PS["Data.HeytingAlgebra"];
  var Data_Maybe = PS["Data.Maybe"];
  var Data_Monoid = PS["Data.Monoid"];
  var Data_Ord = PS["Data.Ord"];
  var Data_Semigroup = PS["Data.Semigroup"];
  var Data_Show = PS["Data.Show"];
  var Data_Traversable = PS["Data.Traversable"];
  var Data_TraversableWithIndex = PS["Data.TraversableWithIndex"];
  var Data_Tuple = PS["Data.Tuple"];
  var Data_Unfoldable = PS["Data.Unfoldable"];
  var Foreign_Object_ST = PS["Foreign.Object.ST"];
  var Prelude = PS["Prelude"];
  var Type_Row_Homogeneous = PS["Type.Row.Homogeneous"];
  var Unsafe_Coerce = PS["Unsafe.Coerce"];
  var thawST = $foreign["_copyST"];

  var singleton = function singleton(k) {
    return function (v) {
      return $foreign.runST(Control_Bind.bindFlipped(Control_Monad_ST_Internal.bindST)(Foreign_Object_ST.poke(k)(v))(Foreign_Object_ST["new"]));
    };
  };

  var mutate = function mutate(f) {
    return function (m) {
      return $foreign.runST(function __do() {
        var v = thawST(m)();
        var v1 = f(v)();
        return v;
      });
    };
  };

  var lookup = Data_Function_Uncurried.runFn4($foreign["_lookup"])(Data_Maybe.Nothing.value)(Data_Maybe.Just.create);

  var insert = function insert(k) {
    return function (v) {
      return mutate(Foreign_Object_ST.poke(k)(v));
    };
  };

  exports["singleton"] = singleton;
  exports["insert"] = insert;
  exports["lookup"] = lookup;
  exports["thawST"] = thawST;
  exports["empty"] = $foreign.empty;
})(PS["Foreign.Object"] = PS["Foreign.Object"] || {});

(function (exports) {
  // Generated by purs version 0.12.3
  "use strict";

  var $foreign = PS["Data.Argonaut.Core"];
  var Data_Eq = PS["Data.Eq"];
  var Data_Function = PS["Data.Function"];
  var Data_Function_Uncurried = PS["Data.Function.Uncurried"];
  var Data_Maybe = PS["Data.Maybe"];
  var Data_Ord = PS["Data.Ord"];
  var Data_Ordering = PS["Data.Ordering"];
  var Foreign_Object = PS["Foreign.Object"];
  var Prelude = PS["Prelude"];

  var verbJsonType = function verbJsonType(def) {
    return function (f) {
      return function (g) {
        return g(def)(f);
      };
    };
  };

  var isJsonType = verbJsonType(false)(Data_Function["const"](true));

  var caseJsonNull = function caseJsonNull(d) {
    return function (f) {
      return function (j) {
        return $foreign["_caseJson"](f, Data_Function["const"](d), Data_Function["const"](d), Data_Function["const"](d), Data_Function["const"](d), Data_Function["const"](d), j);
      };
    };
  };

  var isNull = isJsonType(caseJsonNull);

  var caseJson = function caseJson(a) {
    return function (b) {
      return function (c) {
        return function (d) {
          return function (e) {
            return function (f) {
              return function (json) {
                return $foreign["_caseJson"](a, b, c, d, e, f, json);
              };
            };
          };
        };
      };
    };
  };

  exports["caseJson"] = caseJson;
  exports["caseJsonNull"] = caseJsonNull;
  exports["isNull"] = isNull;
  exports["fromBoolean"] = $foreign.fromBoolean;
  exports["fromNumber"] = $foreign.fromNumber;
  exports["fromString"] = $foreign.fromString;
  exports["fromObject"] = $foreign.fromObject;
  exports["stringify"] = $foreign.stringify;
})(PS["Data.Argonaut.Core"] = PS["Data.Argonaut.Core"] || {});

(function (exports) {
  // Generated by purs version 0.12.3
  "use strict";

  var Control_Alternative = PS["Control.Alternative"];
  var Control_Bind = PS["Control.Bind"];
  var Control_Lazy = PS["Control.Lazy"];
  var Control_Monad_Rec_Class = PS["Control.Monad.Rec.Class"];
  var Control_Semigroupoid = PS["Control.Semigroupoid"];
  var Data_Array = PS["Data.Array"];
  var Data_Array_NonEmpty_Internal = PS["Data.Array.NonEmpty.Internal"];
  var Data_Bifunctor = PS["Data.Bifunctor"];
  var Data_Boolean = PS["Data.Boolean"];
  var Data_Eq = PS["Data.Eq"];
  var Data_Foldable = PS["Data.Foldable"];
  var Data_Function = PS["Data.Function"];
  var Data_Functor = PS["Data.Functor"];
  var Data_Maybe = PS["Data.Maybe"];
  var Data_NonEmpty = PS["Data.NonEmpty"];
  var Data_Ord = PS["Data.Ord"];
  var Data_Ring = PS["Data.Ring"];
  var Data_Semigroup = PS["Data.Semigroup"];
  var Data_Semigroup_Foldable = PS["Data.Semigroup.Foldable"];
  var Data_Semiring = PS["Data.Semiring"];
  var Data_Tuple = PS["Data.Tuple"];
  var Data_Unfoldable = PS["Data.Unfoldable"];
  var Data_Unfoldable1 = PS["Data.Unfoldable1"];
  var Partial_Unsafe = PS["Partial.Unsafe"];
  var Prelude = PS["Prelude"];
  var Unsafe_Coerce = PS["Unsafe.Coerce"];
  var unsafeFromArray = Unsafe_Coerce.unsafeCoerce;
  var toArray = Unsafe_Coerce.unsafeCoerce;

  var snoc$prime = function snoc$prime(xs) {
    return function (x) {
      return unsafeFromArray(Data_Array.snoc(xs)(x));
    };
  };

  var snoc = function snoc(xs) {
    return function (x) {
      return unsafeFromArray(Data_Array.snoc(toArray(xs))(x));
    };
  };

  var singleton = function singleton($44) {
    return unsafeFromArray(Data_Array.singleton($44));
  };

  var fromArray = function fromArray(xs) {
    if (Data_Array.length(xs) > 0) {
      return new Data_Maybe.Just(unsafeFromArray(xs));
    }

    ;

    if (Data_Boolean.otherwise) {
      return Data_Maybe.Nothing.value;
    }

    ;
    throw new Error("Failed pattern match at Data.Array.NonEmpty (line 134, column 1 - line 134, column 58): " + [xs.constructor.name]);
  };

  var cons$prime = function cons$prime(x) {
    return function (xs) {
      return unsafeFromArray(Data_Array.cons(x)(xs));
    };
  };

  var adaptMaybe = function adaptMaybe(f) {
    return function ($52) {
      return Data_Maybe.fromJust()(f(toArray($52)));
    };
  };

  var head = adaptMaybe(Data_Array.head);
  var init = adaptMaybe(Data_Array.init);
  var last = adaptMaybe(Data_Array.last);
  var tail = adaptMaybe(Data_Array.tail);

  var adaptAny = function adaptAny(f) {
    return function ($54) {
      return f(toArray($54));
    };
  };

  var length = adaptAny(Data_Array.length);

  var unsafeAdapt = function unsafeAdapt(f) {
    return function ($55) {
      return unsafeFromArray(adaptAny(f)($55));
    };
  };

  var cons = function cons(x) {
    return unsafeAdapt(Data_Array.cons(x));
  };

  exports["fromArray"] = fromArray;
  exports["toArray"] = toArray;
  exports["singleton"] = singleton;
  exports["length"] = length;
  exports["cons"] = cons;
  exports["cons'"] = cons$prime;
  exports["snoc"] = snoc;
  exports["snoc'"] = snoc$prime;
  exports["head"] = head;
  exports["last"] = last;
  exports["tail"] = tail;
  exports["init"] = init;
})(PS["Data.Array.NonEmpty"] = PS["Data.Array.NonEmpty"] || {});

(function (exports) {
  "use strict";

  exports.toCharCode = function (c) {
    return c.charCodeAt(0);
  };

  exports.fromCharCode = function (c) {
    return String.fromCharCode(c);
  };
})(PS["Data.Enum"] = PS["Data.Enum"] || {});

(function (exports) {
  // Generated by purs version 0.12.3
  "use strict";

  var $foreign = PS["Data.Enum"];
  var Control_Apply = PS["Control.Apply"];
  var Control_Bind = PS["Control.Bind"];
  var Control_MonadPlus = PS["Control.MonadPlus"];
  var Control_MonadZero = PS["Control.MonadZero"];
  var Control_Semigroupoid = PS["Control.Semigroupoid"];
  var Data_Boolean = PS["Data.Boolean"];
  var Data_Bounded = PS["Data.Bounded"];
  var Data_Either = PS["Data.Either"];
  var Data_Eq = PS["Data.Eq"];
  var Data_Function = PS["Data.Function"];
  var Data_Functor = PS["Data.Functor"];
  var Data_HeytingAlgebra = PS["Data.HeytingAlgebra"];
  var Data_Maybe = PS["Data.Maybe"];
  var Data_Newtype = PS["Data.Newtype"];
  var Data_Ord = PS["Data.Ord"];
  var Data_Ordering = PS["Data.Ordering"];
  var Data_Ring = PS["Data.Ring"];
  var Data_Semigroup = PS["Data.Semigroup"];
  var Data_Semiring = PS["Data.Semiring"];
  var Data_Show = PS["Data.Show"];
  var Data_Tuple = PS["Data.Tuple"];
  var Data_Unfoldable = PS["Data.Unfoldable"];
  var Data_Unfoldable1 = PS["Data.Unfoldable1"];
  var Data_Unit = PS["Data.Unit"];
  var Partial_Unsafe = PS["Partial.Unsafe"];
  var Prelude = PS["Prelude"];

  var Enum = function Enum(Ord0, pred, succ) {
    this.Ord0 = Ord0;
    this.pred = pred;
    this.succ = succ;
  };

  var BoundedEnum = function BoundedEnum(Bounded0, Enum1, cardinality, fromEnum, toEnum) {
    this.Bounded0 = Bounded0;
    this.Enum1 = Enum1;
    this.cardinality = cardinality;
    this.fromEnum = fromEnum;
    this.toEnum = toEnum;
  };

  var toEnum = function toEnum(dict) {
    return dict.toEnum;
  };

  var succ = function succ(dict) {
    return dict.succ;
  };

  var pred = function pred(dict) {
    return dict.pred;
  };

  var fromEnum = function fromEnum(dict) {
    return dict.fromEnum;
  };

  var toEnumWithDefaults = function toEnumWithDefaults(dictBoundedEnum) {
    return function (low) {
      return function (high) {
        return function (x) {
          var v = toEnum(dictBoundedEnum)(x);

          if (v instanceof Data_Maybe.Just) {
            return v.value0;
          }

          ;

          if (v instanceof Data_Maybe.Nothing) {
            var $54 = x < fromEnum(dictBoundedEnum)(Data_Bounded.bottom(dictBoundedEnum.Bounded0()));

            if ($54) {
              return low;
            }

            ;
            return high;
          }

          ;
          throw new Error("Failed pattern match at Data.Enum (line 158, column 33 - line 160, column 62): " + [v.constructor.name]);
        };
      };
    };
  };

  var defaultSucc = function defaultSucc(toEnum$prime) {
    return function (fromEnum$prime) {
      return function (a) {
        return toEnum$prime(fromEnum$prime(a) + 1 | 0);
      };
    };
  };

  var defaultPred = function defaultPred(toEnum$prime) {
    return function (fromEnum$prime) {
      return function (a) {
        return toEnum$prime(fromEnum$prime(a) - 1 | 0);
      };
    };
  };

  var charToEnum = function charToEnum(v) {
    if (v >= Data_Bounded.bottom(Data_Bounded.boundedInt) && v <= Data_Bounded.top(Data_Bounded.boundedInt)) {
      return new Data_Maybe.Just($foreign.fromCharCode(v));
    }

    ;
    return Data_Maybe.Nothing.value;
  };

  var enumChar = new Enum(function () {
    return Data_Ord.ordChar;
  }, defaultPred(charToEnum)($foreign.toCharCode), defaultSucc(charToEnum)($foreign.toCharCode));

  var cardinality = function cardinality(dict) {
    return dict.cardinality;
  };

  var boundedEnumChar = new BoundedEnum(function () {
    return Data_Bounded.boundedChar;
  }, function () {
    return enumChar;
  }, $foreign.toCharCode(Data_Bounded.top(Data_Bounded.boundedChar)) - $foreign.toCharCode(Data_Bounded.bottom(Data_Bounded.boundedChar)) | 0, $foreign.toCharCode, charToEnum);
  exports["Enum"] = Enum;
  exports["succ"] = succ;
  exports["pred"] = pred;
  exports["BoundedEnum"] = BoundedEnum;
  exports["cardinality"] = cardinality;
  exports["toEnum"] = toEnum;
  exports["fromEnum"] = fromEnum;
  exports["toEnumWithDefaults"] = toEnumWithDefaults;
  exports["defaultSucc"] = defaultSucc;
  exports["defaultPred"] = defaultPred;
  exports["enumChar"] = enumChar;
  exports["boundedEnumChar"] = boundedEnumChar;
})(PS["Data.Enum"] = PS["Data.Enum"] || {});

(function (exports) {
  // Generated by purs version 0.12.3
  "use strict";

  var Data_Enum = PS["Data.Enum"];
  var Data_Maybe = PS["Data.Maybe"];
  var toCharCode = Data_Enum.fromEnum(Data_Enum.boundedEnumChar);
  var fromCharCode = Data_Enum.toEnum(Data_Enum.boundedEnumChar);
  exports["toCharCode"] = toCharCode;
  exports["fromCharCode"] = fromCharCode;
})(PS["Data.Char"] = PS["Data.Char"] || {});

(function (exports) {
  "use strict";

  var createDate = function createDate(y, m, d) {
    var date = new Date(Date.UTC(y, m, d));

    if (y >= 0 && y < 100) {
      date.setUTCFullYear(y);
    }

    return date;
  };

  exports.canonicalDateImpl = function (ctor, y, m, d) {
    var date = createDate(y, m - 1, d);
    return ctor(date.getUTCFullYear())(date.getUTCMonth() + 1)(date.getUTCDate());
  };

  exports.calcWeekday = function (y, m, d) {
    return createDate(y, m - 1, d).getUTCDay();
  };
})(PS["Data.Date"] = PS["Data.Date"] || {});

(function (exports) {
  // Generated by purs version 0.12.3
  "use strict";

  var Control_Semigroupoid = PS["Control.Semigroupoid"];
  var Data_Boolean = PS["Data.Boolean"];
  var Data_Bounded = PS["Data.Bounded"];
  var Data_Enum = PS["Data.Enum"];
  var Data_Eq = PS["Data.Eq"];
  var Data_HeytingAlgebra = PS["Data.HeytingAlgebra"];
  var Data_Maybe = PS["Data.Maybe"];
  var Data_Ord = PS["Data.Ord"];
  var Data_Ordering = PS["Data.Ordering"];
  var Data_Ring = PS["Data.Ring"];
  var Data_Semigroup = PS["Data.Semigroup"];
  var Data_Semiring = PS["Data.Semiring"];
  var Data_Show = PS["Data.Show"];
  var Prelude = PS["Prelude"];

  var Monday = function () {
    function Monday() {}

    ;
    Monday.value = new Monday();
    return Monday;
  }();

  var Tuesday = function () {
    function Tuesday() {}

    ;
    Tuesday.value = new Tuesday();
    return Tuesday;
  }();

  var Wednesday = function () {
    function Wednesday() {}

    ;
    Wednesday.value = new Wednesday();
    return Wednesday;
  }();

  var Thursday = function () {
    function Thursday() {}

    ;
    Thursday.value = new Thursday();
    return Thursday;
  }();

  var Friday = function () {
    function Friday() {}

    ;
    Friday.value = new Friday();
    return Friday;
  }();

  var Saturday = function () {
    function Saturday() {}

    ;
    Saturday.value = new Saturday();
    return Saturday;
  }();

  var Sunday = function () {
    function Sunday() {}

    ;
    Sunday.value = new Sunday();
    return Sunday;
  }();

  var January = function () {
    function January() {}

    ;
    January.value = new January();
    return January;
  }();

  var February = function () {
    function February() {}

    ;
    February.value = new February();
    return February;
  }();

  var March = function () {
    function March() {}

    ;
    March.value = new March();
    return March;
  }();

  var April = function () {
    function April() {}

    ;
    April.value = new April();
    return April;
  }();

  var May = function () {
    function May() {}

    ;
    May.value = new May();
    return May;
  }();

  var June = function () {
    function June() {}

    ;
    June.value = new June();
    return June;
  }();

  var July = function () {
    function July() {}

    ;
    July.value = new July();
    return July;
  }();

  var August = function () {
    function August() {}

    ;
    August.value = new August();
    return August;
  }();

  var September = function () {
    function September() {}

    ;
    September.value = new September();
    return September;
  }();

  var October = function () {
    function October() {}

    ;
    October.value = new October();
    return October;
  }();

  var November = function () {
    function November() {}

    ;
    November.value = new November();
    return November;
  }();

  var December = function () {
    function December() {}

    ;
    December.value = new December();
    return December;
  }();

  var showWeekday = new Data_Show.Show(function (v) {
    if (v instanceof Monday) {
      return "Monday";
    }

    ;

    if (v instanceof Tuesday) {
      return "Tuesday";
    }

    ;

    if (v instanceof Wednesday) {
      return "Wednesday";
    }

    ;

    if (v instanceof Thursday) {
      return "Thursday";
    }

    ;

    if (v instanceof Friday) {
      return "Friday";
    }

    ;

    if (v instanceof Saturday) {
      return "Saturday";
    }

    ;

    if (v instanceof Sunday) {
      return "Sunday";
    }

    ;
    throw new Error("Failed pattern match at Data.Date.Component (line 184, column 1 - line 184, column 37): " + [v.constructor.name]);
  });
  var showMonth = new Data_Show.Show(function (v) {
    if (v instanceof January) {
      return "January";
    }

    ;

    if (v instanceof February) {
      return "February";
    }

    ;

    if (v instanceof March) {
      return "March";
    }

    ;

    if (v instanceof April) {
      return "April";
    }

    ;

    if (v instanceof May) {
      return "May";
    }

    ;

    if (v instanceof June) {
      return "June";
    }

    ;

    if (v instanceof July) {
      return "July";
    }

    ;

    if (v instanceof August) {
      return "August";
    }

    ;

    if (v instanceof September) {
      return "September";
    }

    ;

    if (v instanceof October) {
      return "October";
    }

    ;

    if (v instanceof November) {
      return "November";
    }

    ;

    if (v instanceof December) {
      return "December";
    }

    ;
    throw new Error("Failed pattern match at Data.Date.Component (line 101, column 1 - line 101, column 33): " + [v.constructor.name]);
  });
  var ordYear = Data_Ord.ordInt;
  var ordDay = Data_Ord.ordInt;
  var eqWeekday = new Data_Eq.Eq(function (x) {
    return function (y) {
      if (x instanceof Monday && y instanceof Monday) {
        return true;
      }

      ;

      if (x instanceof Tuesday && y instanceof Tuesday) {
        return true;
      }

      ;

      if (x instanceof Wednesday && y instanceof Wednesday) {
        return true;
      }

      ;

      if (x instanceof Thursday && y instanceof Thursday) {
        return true;
      }

      ;

      if (x instanceof Friday && y instanceof Friday) {
        return true;
      }

      ;

      if (x instanceof Saturday && y instanceof Saturday) {
        return true;
      }

      ;

      if (x instanceof Sunday && y instanceof Sunday) {
        return true;
      }

      ;
      return false;
    };
  });
  var ordWeekday = new Data_Ord.Ord(function () {
    return eqWeekday;
  }, function (x) {
    return function (y) {
      if (x instanceof Monday && y instanceof Monday) {
        return Data_Ordering.EQ.value;
      }

      ;

      if (x instanceof Monday) {
        return Data_Ordering.LT.value;
      }

      ;

      if (y instanceof Monday) {
        return Data_Ordering.GT.value;
      }

      ;

      if (x instanceof Tuesday && y instanceof Tuesday) {
        return Data_Ordering.EQ.value;
      }

      ;

      if (x instanceof Tuesday) {
        return Data_Ordering.LT.value;
      }

      ;

      if (y instanceof Tuesday) {
        return Data_Ordering.GT.value;
      }

      ;

      if (x instanceof Wednesday && y instanceof Wednesday) {
        return Data_Ordering.EQ.value;
      }

      ;

      if (x instanceof Wednesday) {
        return Data_Ordering.LT.value;
      }

      ;

      if (y instanceof Wednesday) {
        return Data_Ordering.GT.value;
      }

      ;

      if (x instanceof Thursday && y instanceof Thursday) {
        return Data_Ordering.EQ.value;
      }

      ;

      if (x instanceof Thursday) {
        return Data_Ordering.LT.value;
      }

      ;

      if (y instanceof Thursday) {
        return Data_Ordering.GT.value;
      }

      ;

      if (x instanceof Friday && y instanceof Friday) {
        return Data_Ordering.EQ.value;
      }

      ;

      if (x instanceof Friday) {
        return Data_Ordering.LT.value;
      }

      ;

      if (y instanceof Friday) {
        return Data_Ordering.GT.value;
      }

      ;

      if (x instanceof Saturday && y instanceof Saturday) {
        return Data_Ordering.EQ.value;
      }

      ;

      if (x instanceof Saturday) {
        return Data_Ordering.LT.value;
      }

      ;

      if (y instanceof Saturday) {
        return Data_Ordering.GT.value;
      }

      ;

      if (x instanceof Sunday && y instanceof Sunday) {
        return Data_Ordering.EQ.value;
      }

      ;
      throw new Error("Failed pattern match at Data.Date.Component (line 154, column 8 - line 154, column 42): " + [x.constructor.name, y.constructor.name]);
    };
  });
  var eqMonth = new Data_Eq.Eq(function (x) {
    return function (y) {
      if (x instanceof January && y instanceof January) {
        return true;
      }

      ;

      if (x instanceof February && y instanceof February) {
        return true;
      }

      ;

      if (x instanceof March && y instanceof March) {
        return true;
      }

      ;

      if (x instanceof April && y instanceof April) {
        return true;
      }

      ;

      if (x instanceof May && y instanceof May) {
        return true;
      }

      ;

      if (x instanceof June && y instanceof June) {
        return true;
      }

      ;

      if (x instanceof July && y instanceof July) {
        return true;
      }

      ;

      if (x instanceof August && y instanceof August) {
        return true;
      }

      ;

      if (x instanceof September && y instanceof September) {
        return true;
      }

      ;

      if (x instanceof October && y instanceof October) {
        return true;
      }

      ;

      if (x instanceof November && y instanceof November) {
        return true;
      }

      ;

      if (x instanceof December && y instanceof December) {
        return true;
      }

      ;
      return false;
    };
  });
  var ordMonth = new Data_Ord.Ord(function () {
    return eqMonth;
  }, function (x) {
    return function (y) {
      if (x instanceof January && y instanceof January) {
        return Data_Ordering.EQ.value;
      }

      ;

      if (x instanceof January) {
        return Data_Ordering.LT.value;
      }

      ;

      if (y instanceof January) {
        return Data_Ordering.GT.value;
      }

      ;

      if (x instanceof February && y instanceof February) {
        return Data_Ordering.EQ.value;
      }

      ;

      if (x instanceof February) {
        return Data_Ordering.LT.value;
      }

      ;

      if (y instanceof February) {
        return Data_Ordering.GT.value;
      }

      ;

      if (x instanceof March && y instanceof March) {
        return Data_Ordering.EQ.value;
      }

      ;

      if (x instanceof March) {
        return Data_Ordering.LT.value;
      }

      ;

      if (y instanceof March) {
        return Data_Ordering.GT.value;
      }

      ;

      if (x instanceof April && y instanceof April) {
        return Data_Ordering.EQ.value;
      }

      ;

      if (x instanceof April) {
        return Data_Ordering.LT.value;
      }

      ;

      if (y instanceof April) {
        return Data_Ordering.GT.value;
      }

      ;

      if (x instanceof May && y instanceof May) {
        return Data_Ordering.EQ.value;
      }

      ;

      if (x instanceof May) {
        return Data_Ordering.LT.value;
      }

      ;

      if (y instanceof May) {
        return Data_Ordering.GT.value;
      }

      ;

      if (x instanceof June && y instanceof June) {
        return Data_Ordering.EQ.value;
      }

      ;

      if (x instanceof June) {
        return Data_Ordering.LT.value;
      }

      ;

      if (y instanceof June) {
        return Data_Ordering.GT.value;
      }

      ;

      if (x instanceof July && y instanceof July) {
        return Data_Ordering.EQ.value;
      }

      ;

      if (x instanceof July) {
        return Data_Ordering.LT.value;
      }

      ;

      if (y instanceof July) {
        return Data_Ordering.GT.value;
      }

      ;

      if (x instanceof August && y instanceof August) {
        return Data_Ordering.EQ.value;
      }

      ;

      if (x instanceof August) {
        return Data_Ordering.LT.value;
      }

      ;

      if (y instanceof August) {
        return Data_Ordering.GT.value;
      }

      ;

      if (x instanceof September && y instanceof September) {
        return Data_Ordering.EQ.value;
      }

      ;

      if (x instanceof September) {
        return Data_Ordering.LT.value;
      }

      ;

      if (y instanceof September) {
        return Data_Ordering.GT.value;
      }

      ;

      if (x instanceof October && y instanceof October) {
        return Data_Ordering.EQ.value;
      }

      ;

      if (x instanceof October) {
        return Data_Ordering.LT.value;
      }

      ;

      if (y instanceof October) {
        return Data_Ordering.GT.value;
      }

      ;

      if (x instanceof November && y instanceof November) {
        return Data_Ordering.EQ.value;
      }

      ;

      if (x instanceof November) {
        return Data_Ordering.LT.value;
      }

      ;

      if (y instanceof November) {
        return Data_Ordering.GT.value;
      }

      ;

      if (x instanceof December && y instanceof December) {
        return Data_Ordering.EQ.value;
      }

      ;
      throw new Error("Failed pattern match at Data.Date.Component (line 61, column 8 - line 61, column 38): " + [x.constructor.name, y.constructor.name]);
    };
  });
  var boundedYear = new Data_Bounded.Bounded(function () {
    return ordYear;
  }, -271820 | 0, 275759);
  var boundedWeekday = new Data_Bounded.Bounded(function () {
    return ordWeekday;
  }, Monday.value, Sunday.value);
  var boundedMonth = new Data_Bounded.Bounded(function () {
    return ordMonth;
  }, January.value, December.value);
  var boundedEnumYear = new Data_Enum.BoundedEnum(function () {
    return boundedYear;
  }, function () {
    return enumYear;
  }, 547580, function (v) {
    return v;
  }, function (n) {
    if (n >= (-271820 | 0) && n <= 275759) {
      return new Data_Maybe.Just(n);
    }

    ;

    if (Data_Boolean.otherwise) {
      return Data_Maybe.Nothing.value;
    }

    ;
    throw new Error("Failed pattern match at Data.Date.Component (line 35, column 1 - line 35, column 45): " + [n.constructor.name]);
  });
  var enumYear = new Data_Enum.Enum(function () {
    return ordYear;
  }, function ($46) {
    return Data_Enum.toEnum(boundedEnumYear)(function (v) {
      return v - 1 | 0;
    }(Data_Enum.fromEnum(boundedEnumYear)($46)));
  }, function ($47) {
    return Data_Enum.toEnum(boundedEnumYear)(function (v) {
      return v + 1 | 0;
    }(Data_Enum.fromEnum(boundedEnumYear)($47)));
  });
  var boundedEnumWeekday = new Data_Enum.BoundedEnum(function () {
    return boundedWeekday;
  }, function () {
    return enumWeekday;
  }, 7, function (v) {
    if (v instanceof Monday) {
      return 1;
    }

    ;

    if (v instanceof Tuesday) {
      return 2;
    }

    ;

    if (v instanceof Wednesday) {
      return 3;
    }

    ;

    if (v instanceof Thursday) {
      return 4;
    }

    ;

    if (v instanceof Friday) {
      return 5;
    }

    ;

    if (v instanceof Saturday) {
      return 6;
    }

    ;

    if (v instanceof Sunday) {
      return 7;
    }

    ;
    throw new Error("Failed pattern match at Data.Date.Component (line 175, column 14 - line 184, column 1): " + [v.constructor.name]);
  }, function (v) {
    if (v === 1) {
      return new Data_Maybe.Just(Monday.value);
    }

    ;

    if (v === 2) {
      return new Data_Maybe.Just(Tuesday.value);
    }

    ;

    if (v === 3) {
      return new Data_Maybe.Just(Wednesday.value);
    }

    ;

    if (v === 4) {
      return new Data_Maybe.Just(Thursday.value);
    }

    ;

    if (v === 5) {
      return new Data_Maybe.Just(Friday.value);
    }

    ;

    if (v === 6) {
      return new Data_Maybe.Just(Saturday.value);
    }

    ;

    if (v === 7) {
      return new Data_Maybe.Just(Sunday.value);
    }

    ;
    return Data_Maybe.Nothing.value;
  });
  var enumWeekday = new Data_Enum.Enum(function () {
    return ordWeekday;
  }, function ($48) {
    return Data_Enum.toEnum(boundedEnumWeekday)(function (v) {
      return v - 1 | 0;
    }(Data_Enum.fromEnum(boundedEnumWeekday)($48)));
  }, function ($49) {
    return Data_Enum.toEnum(boundedEnumWeekday)(function (v) {
      return v + 1 | 0;
    }(Data_Enum.fromEnum(boundedEnumWeekday)($49)));
  });
  var boundedEnumMonth = new Data_Enum.BoundedEnum(function () {
    return boundedMonth;
  }, function () {
    return enumMonth;
  }, 12, function (v) {
    if (v instanceof January) {
      return 1;
    }

    ;

    if (v instanceof February) {
      return 2;
    }

    ;

    if (v instanceof March) {
      return 3;
    }

    ;

    if (v instanceof April) {
      return 4;
    }

    ;

    if (v instanceof May) {
      return 5;
    }

    ;

    if (v instanceof June) {
      return 6;
    }

    ;

    if (v instanceof July) {
      return 7;
    }

    ;

    if (v instanceof August) {
      return 8;
    }

    ;

    if (v instanceof September) {
      return 9;
    }

    ;

    if (v instanceof October) {
      return 10;
    }

    ;

    if (v instanceof November) {
      return 11;
    }

    ;

    if (v instanceof December) {
      return 12;
    }

    ;
    throw new Error("Failed pattern match at Data.Date.Component (line 87, column 14 - line 101, column 1): " + [v.constructor.name]);
  }, function (v) {
    if (v === 1) {
      return new Data_Maybe.Just(January.value);
    }

    ;

    if (v === 2) {
      return new Data_Maybe.Just(February.value);
    }

    ;

    if (v === 3) {
      return new Data_Maybe.Just(March.value);
    }

    ;

    if (v === 4) {
      return new Data_Maybe.Just(April.value);
    }

    ;

    if (v === 5) {
      return new Data_Maybe.Just(May.value);
    }

    ;

    if (v === 6) {
      return new Data_Maybe.Just(June.value);
    }

    ;

    if (v === 7) {
      return new Data_Maybe.Just(July.value);
    }

    ;

    if (v === 8) {
      return new Data_Maybe.Just(August.value);
    }

    ;

    if (v === 9) {
      return new Data_Maybe.Just(September.value);
    }

    ;

    if (v === 10) {
      return new Data_Maybe.Just(October.value);
    }

    ;

    if (v === 11) {
      return new Data_Maybe.Just(November.value);
    }

    ;

    if (v === 12) {
      return new Data_Maybe.Just(December.value);
    }

    ;
    return Data_Maybe.Nothing.value;
  });
  var enumMonth = new Data_Enum.Enum(function () {
    return ordMonth;
  }, function ($50) {
    return Data_Enum.toEnum(boundedEnumMonth)(function (v) {
      return v - 1 | 0;
    }(Data_Enum.fromEnum(boundedEnumMonth)($50)));
  }, function ($51) {
    return Data_Enum.toEnum(boundedEnumMonth)(function (v) {
      return v + 1 | 0;
    }(Data_Enum.fromEnum(boundedEnumMonth)($51)));
  });
  var boundedDay = new Data_Bounded.Bounded(function () {
    return ordDay;
  }, 1, 31);
  var boundedEnumDay = new Data_Enum.BoundedEnum(function () {
    return boundedDay;
  }, function () {
    return enumDay;
  }, 31, function (v) {
    return v;
  }, function (n) {
    if (n >= 1 && n <= 31) {
      return new Data_Maybe.Just(n);
    }

    ;

    if (Data_Boolean.otherwise) {
      return Data_Maybe.Nothing.value;
    }

    ;
    throw new Error("Failed pattern match at Data.Date.Component (line 133, column 1 - line 133, column 43): " + [n.constructor.name]);
  });
  var enumDay = new Data_Enum.Enum(function () {
    return ordDay;
  }, function ($52) {
    return Data_Enum.toEnum(boundedEnumDay)(function (v) {
      return v - 1 | 0;
    }(Data_Enum.fromEnum(boundedEnumDay)($52)));
  }, function ($53) {
    return Data_Enum.toEnum(boundedEnumDay)(function (v) {
      return v + 1 | 0;
    }(Data_Enum.fromEnum(boundedEnumDay)($53)));
  });
  exports["January"] = January;
  exports["February"] = February;
  exports["March"] = March;
  exports["April"] = April;
  exports["May"] = May;
  exports["June"] = June;
  exports["July"] = July;
  exports["August"] = August;
  exports["September"] = September;
  exports["October"] = October;
  exports["November"] = November;
  exports["December"] = December;
  exports["Monday"] = Monday;
  exports["Tuesday"] = Tuesday;
  exports["Wednesday"] = Wednesday;
  exports["Thursday"] = Thursday;
  exports["Friday"] = Friday;
  exports["Saturday"] = Saturday;
  exports["Sunday"] = Sunday;
  exports["ordYear"] = ordYear;
  exports["boundedYear"] = boundedYear;
  exports["enumYear"] = enumYear;
  exports["boundedEnumYear"] = boundedEnumYear;
  exports["eqMonth"] = eqMonth;
  exports["ordMonth"] = ordMonth;
  exports["boundedMonth"] = boundedMonth;
  exports["enumMonth"] = enumMonth;
  exports["boundedEnumMonth"] = boundedEnumMonth;
  exports["showMonth"] = showMonth;
  exports["ordDay"] = ordDay;
  exports["boundedDay"] = boundedDay;
  exports["enumDay"] = enumDay;
  exports["boundedEnumDay"] = boundedEnumDay;
  exports["eqWeekday"] = eqWeekday;
  exports["ordWeekday"] = ordWeekday;
  exports["boundedWeekday"] = boundedWeekday;
  exports["enumWeekday"] = enumWeekday;
  exports["boundedEnumWeekday"] = boundedEnumWeekday;
  exports["showWeekday"] = showWeekday;
})(PS["Data.Date.Component"] = PS["Data.Date.Component"] || {});

(function (exports) {
  // Generated by purs version 0.12.3
  "use strict";

  var $foreign = PS["Data.Date"];
  var Control_Applicative = PS["Control.Applicative"];
  var Control_Apply = PS["Control.Apply"];
  var Control_Bind = PS["Control.Bind"];
  var Control_Semigroupoid = PS["Control.Semigroupoid"];
  var Data_Boolean = PS["Data.Boolean"];
  var Data_Bounded = PS["Data.Bounded"];
  var Data_Date_Component = PS["Data.Date.Component"];
  var Data_Enum = PS["Data.Enum"];
  var Data_Eq = PS["Data.Eq"];
  var Data_EuclideanRing = PS["Data.EuclideanRing"];
  var Data_Function = PS["Data.Function"];
  var Data_Function_Uncurried = PS["Data.Function.Uncurried"];
  var Data_Functor = PS["Data.Functor"];
  var Data_HeytingAlgebra = PS["Data.HeytingAlgebra"];
  var Data_Int = PS["Data.Int"];
  var Data_Maybe = PS["Data.Maybe"];
  var Data_Ord = PS["Data.Ord"];
  var Data_Ordering = PS["Data.Ordering"];
  var Data_Ring = PS["Data.Ring"];
  var Data_Semigroup = PS["Data.Semigroup"];
  var Data_Semiring = PS["Data.Semiring"];
  var Data_Show = PS["Data.Show"];
  var Data_Time_Duration = PS["Data.Time.Duration"];
  var Partial_Unsafe = PS["Partial.Unsafe"];
  var Prelude = PS["Prelude"];

  var $$Date = function () {
    function $$Date(value0, value1, value2) {
      this.value0 = value0;
      this.value1 = value1;
      this.value2 = value2;
    }

    ;

    $$Date.create = function (value0) {
      return function (value1) {
        return function (value2) {
          return new $$Date(value0, value1, value2);
        };
      };
    };

    return $$Date;
  }();

  var year = function year(v) {
    return v.value0;
  };

  var weekday = function weekday(v) {
    var n = $foreign.calcWeekday(v.value0, Data_Enum.fromEnum(Data_Date_Component.boundedEnumMonth)(v.value1), v.value2);
    var $41 = n === 0;

    if ($41) {
      return Data_Maybe.fromJust()(Data_Enum.toEnum(Data_Date_Component.boundedEnumWeekday)(7));
    }

    ;
    return Data_Maybe.fromJust()(Data_Enum.toEnum(Data_Date_Component.boundedEnumWeekday)(n));
  };

  var month = function month(v) {
    return v.value1;
  };

  var day = function day(v) {
    return v.value2;
  };

  var canonicalDate = function canonicalDate(y) {
    return function (m) {
      return function (d) {
        var mkDate = function mkDate(y$prime) {
          return function (m$prime) {
            return function (d$prime) {
              return new $$Date(y$prime, Data_Maybe.fromJust()(Data_Enum.toEnum(Data_Date_Component.boundedEnumMonth)(m$prime)), d$prime);
            };
          };
        };

        return $foreign.canonicalDateImpl(mkDate, y, Data_Enum.fromEnum(Data_Date_Component.boundedEnumMonth)(m), d);
      };
    };
  };

  exports["canonicalDate"] = canonicalDate;
  exports["year"] = year;
  exports["month"] = month;
  exports["day"] = day;
  exports["weekday"] = weekday;
})(PS["Data.Date"] = PS["Data.Date"] || {});

(function (exports) {
  // Generated by purs version 0.12.3
  "use strict";

  var Control_Semigroupoid = PS["Control.Semigroupoid"];
  var Data_Boolean = PS["Data.Boolean"];
  var Data_Bounded = PS["Data.Bounded"];
  var Data_Enum = PS["Data.Enum"];
  var Data_Eq = PS["Data.Eq"];
  var Data_HeytingAlgebra = PS["Data.HeytingAlgebra"];
  var Data_Maybe = PS["Data.Maybe"];
  var Data_Ord = PS["Data.Ord"];
  var Data_Ring = PS["Data.Ring"];
  var Data_Semigroup = PS["Data.Semigroup"];
  var Data_Semiring = PS["Data.Semiring"];
  var Data_Show = PS["Data.Show"];
  var Prelude = PS["Prelude"];
  var ordSecond = Data_Ord.ordInt;
  var ordMinute = Data_Ord.ordInt;
  var ordMillisecond = Data_Ord.ordInt;
  var ordHour = Data_Ord.ordInt;
  var eqSecond = Data_Eq.eqInt;
  var eqMinute = Data_Eq.eqInt;
  var eqMillisecond = Data_Eq.eqInt;
  var eqHour = Data_Eq.eqInt;
  var boundedSecond = new Data_Bounded.Bounded(function () {
    return ordSecond;
  }, 0, 59);
  var boundedMinute = new Data_Bounded.Bounded(function () {
    return ordMinute;
  }, 0, 59);
  var boundedMillisecond = new Data_Bounded.Bounded(function () {
    return ordMillisecond;
  }, 0, 999);
  var boundedHour = new Data_Bounded.Bounded(function () {
    return ordHour;
  }, 0, 23);
  var boundedEnumSecond = new Data_Enum.BoundedEnum(function () {
    return boundedSecond;
  }, function () {
    return enumSecond;
  }, 60, function (v) {
    return v;
  }, function (n) {
    if (n >= 0 && n <= 59) {
      return new Data_Maybe.Just(n);
    }

    ;

    if (Data_Boolean.otherwise) {
      return Data_Maybe.Nothing.value;
    }

    ;
    throw new Error("Failed pattern match at Data.Time.Component (line 90, column 1 - line 90, column 49): " + [n.constructor.name]);
  });
  var enumSecond = new Data_Enum.Enum(function () {
    return ordSecond;
  }, function ($28) {
    return Data_Enum.toEnum(boundedEnumSecond)(function (v) {
      return v - 1 | 0;
    }(Data_Enum.fromEnum(boundedEnumSecond)($28)));
  }, function ($29) {
    return Data_Enum.toEnum(boundedEnumSecond)(function (v) {
      return v + 1 | 0;
    }(Data_Enum.fromEnum(boundedEnumSecond)($29)));
  });
  var boundedEnumMinute = new Data_Enum.BoundedEnum(function () {
    return boundedMinute;
  }, function () {
    return enumMinute;
  }, 60, function (v) {
    return v;
  }, function (n) {
    if (n >= 0 && n <= 59) {
      return new Data_Maybe.Just(n);
    }

    ;

    if (Data_Boolean.otherwise) {
      return Data_Maybe.Nothing.value;
    }

    ;
    throw new Error("Failed pattern match at Data.Time.Component (line 61, column 1 - line 61, column 49): " + [n.constructor.name]);
  });
  var enumMinute = new Data_Enum.Enum(function () {
    return ordMinute;
  }, function ($30) {
    return Data_Enum.toEnum(boundedEnumMinute)(function (v) {
      return v - 1 | 0;
    }(Data_Enum.fromEnum(boundedEnumMinute)($30)));
  }, function ($31) {
    return Data_Enum.toEnum(boundedEnumMinute)(function (v) {
      return v + 1 | 0;
    }(Data_Enum.fromEnum(boundedEnumMinute)($31)));
  });
  var boundedEnumMillisecond = new Data_Enum.BoundedEnum(function () {
    return boundedMillisecond;
  }, function () {
    return enumMillisecond;
  }, 1000, function (v) {
    return v;
  }, function (n) {
    if (n >= 0 && n <= 999) {
      return new Data_Maybe.Just(n);
    }

    ;

    if (Data_Boolean.otherwise) {
      return Data_Maybe.Nothing.value;
    }

    ;
    throw new Error("Failed pattern match at Data.Time.Component (line 120, column 1 - line 120, column 59): " + [n.constructor.name]);
  });
  var enumMillisecond = new Data_Enum.Enum(function () {
    return ordMillisecond;
  }, function ($32) {
    return Data_Enum.toEnum(boundedEnumMillisecond)(function (v) {
      return v - 1 | 0;
    }(Data_Enum.fromEnum(boundedEnumMillisecond)($32)));
  }, function ($33) {
    return Data_Enum.toEnum(boundedEnumMillisecond)(function (v) {
      return v + 1 | 0;
    }(Data_Enum.fromEnum(boundedEnumMillisecond)($33)));
  });
  var boundedEnumHour = new Data_Enum.BoundedEnum(function () {
    return boundedHour;
  }, function () {
    return enumHour;
  }, 24, function (v) {
    return v;
  }, function (n) {
    if (n >= 0 && n <= 23) {
      return new Data_Maybe.Just(n);
    }

    ;

    if (Data_Boolean.otherwise) {
      return Data_Maybe.Nothing.value;
    }

    ;
    throw new Error("Failed pattern match at Data.Time.Component (line 32, column 1 - line 32, column 45): " + [n.constructor.name]);
  });
  var enumHour = new Data_Enum.Enum(function () {
    return ordHour;
  }, function ($34) {
    return Data_Enum.toEnum(boundedEnumHour)(function (v) {
      return v - 1 | 0;
    }(Data_Enum.fromEnum(boundedEnumHour)($34)));
  }, function ($35) {
    return Data_Enum.toEnum(boundedEnumHour)(function (v) {
      return v + 1 | 0;
    }(Data_Enum.fromEnum(boundedEnumHour)($35)));
  });
  exports["eqHour"] = eqHour;
  exports["ordHour"] = ordHour;
  exports["boundedHour"] = boundedHour;
  exports["enumHour"] = enumHour;
  exports["boundedEnumHour"] = boundedEnumHour;
  exports["eqMinute"] = eqMinute;
  exports["ordMinute"] = ordMinute;
  exports["boundedMinute"] = boundedMinute;
  exports["enumMinute"] = enumMinute;
  exports["boundedEnumMinute"] = boundedEnumMinute;
  exports["eqSecond"] = eqSecond;
  exports["ordSecond"] = ordSecond;
  exports["boundedSecond"] = boundedSecond;
  exports["enumSecond"] = enumSecond;
  exports["boundedEnumSecond"] = boundedEnumSecond;
  exports["eqMillisecond"] = eqMillisecond;
  exports["ordMillisecond"] = ordMillisecond;
  exports["boundedMillisecond"] = boundedMillisecond;
  exports["enumMillisecond"] = enumMillisecond;
  exports["boundedEnumMillisecond"] = boundedEnumMillisecond;
})(PS["Data.Time.Component"] = PS["Data.Time.Component"] || {});

(function (exports) {
  // Generated by purs version 0.12.3
  "use strict";

  var Control_Apply = PS["Control.Apply"];
  var Data_Bounded = PS["Data.Bounded"];
  var Data_Enum = PS["Data.Enum"];
  var Data_Eq = PS["Data.Eq"];
  var Data_EuclideanRing = PS["Data.EuclideanRing"];
  var Data_Function = PS["Data.Function"];
  var Data_Functor = PS["Data.Functor"];
  var Data_HeytingAlgebra = PS["Data.HeytingAlgebra"];
  var Data_Int = PS["Data.Int"];
  var Data_Maybe = PS["Data.Maybe"];
  var Data_Newtype = PS["Data.Newtype"];
  var Data_Ord = PS["Data.Ord"];
  var Data_Ordering = PS["Data.Ordering"];
  var Data_Ring = PS["Data.Ring"];
  var Data_Semigroup = PS["Data.Semigroup"];
  var Data_Semiring = PS["Data.Semiring"];
  var Data_Show = PS["Data.Show"];
  var Data_Time_Component = PS["Data.Time.Component"];
  var Data_Time_Duration = PS["Data.Time.Duration"];
  var Data_Tuple = PS["Data.Tuple"];
  var $$Math = PS["Math"];
  var Partial_Unsafe = PS["Partial.Unsafe"];
  var Prelude = PS["Prelude"];

  var Time = function () {
    function Time(value0, value1, value2, value3) {
      this.value0 = value0;
      this.value1 = value1;
      this.value2 = value2;
      this.value3 = value3;
    }

    ;

    Time.create = function (value0) {
      return function (value1) {
        return function (value2) {
          return function (value3) {
            return new Time(value0, value1, value2, value3);
          };
        };
      };
    };

    return Time;
  }();

  var second = function second(v) {
    return v.value2;
  };

  var minute = function minute(v) {
    return v.value1;
  };

  var millisecond = function millisecond(v) {
    return v.value3;
  };

  var hour = function hour(v) {
    return v.value0;
  };

  var eqTime = new Data_Eq.Eq(function (x) {
    return function (y) {
      return Data_Eq.eq(Data_Time_Component.eqHour)(x.value0)(y.value0) && Data_Eq.eq(Data_Time_Component.eqMinute)(x.value1)(y.value1) && Data_Eq.eq(Data_Time_Component.eqSecond)(x.value2)(y.value2) && Data_Eq.eq(Data_Time_Component.eqMillisecond)(x.value3)(y.value3);
    };
  });
  var ordTime = new Data_Ord.Ord(function () {
    return eqTime;
  }, function (x) {
    return function (y) {
      var v = Data_Ord.compare(Data_Time_Component.ordHour)(x.value0)(y.value0);

      if (v instanceof Data_Ordering.LT) {
        return Data_Ordering.LT.value;
      }

      ;

      if (v instanceof Data_Ordering.GT) {
        return Data_Ordering.GT.value;
      }

      ;
      var v1 = Data_Ord.compare(Data_Time_Component.ordMinute)(x.value1)(y.value1);

      if (v1 instanceof Data_Ordering.LT) {
        return Data_Ordering.LT.value;
      }

      ;

      if (v1 instanceof Data_Ordering.GT) {
        return Data_Ordering.GT.value;
      }

      ;
      var v2 = Data_Ord.compare(Data_Time_Component.ordSecond)(x.value2)(y.value2);

      if (v2 instanceof Data_Ordering.LT) {
        return Data_Ordering.LT.value;
      }

      ;

      if (v2 instanceof Data_Ordering.GT) {
        return Data_Ordering.GT.value;
      }

      ;
      return Data_Ord.compare(Data_Time_Component.ordMillisecond)(x.value3)(y.value3);
    };
  });
  var boundedTime = new Data_Bounded.Bounded(function () {
    return ordTime;
  }, new Time(Data_Bounded.bottom(Data_Time_Component.boundedHour), Data_Bounded.bottom(Data_Time_Component.boundedMinute), Data_Bounded.bottom(Data_Time_Component.boundedSecond), Data_Bounded.bottom(Data_Time_Component.boundedMillisecond)), new Time(Data_Bounded.top(Data_Time_Component.boundedHour), Data_Bounded.top(Data_Time_Component.boundedMinute), Data_Bounded.top(Data_Time_Component.boundedSecond), Data_Bounded.top(Data_Time_Component.boundedMillisecond)));
  exports["Time"] = Time;
  exports["hour"] = hour;
  exports["minute"] = minute;
  exports["second"] = second;
  exports["millisecond"] = millisecond;
  exports["eqTime"] = eqTime;
  exports["ordTime"] = ordTime;
  exports["boundedTime"] = boundedTime;
})(PS["Data.Time"] = PS["Data.Time"] || {});

(function (exports) {
  // Generated by purs version 0.12.3
  "use strict";

  var $foreign = PS["Data.DateTime"];
  var Control_Apply = PS["Control.Apply"];
  var Control_Bind = PS["Control.Bind"];
  var Data_Bounded = PS["Data.Bounded"];
  var Data_Date = PS["Data.Date"];
  var Data_Date_Component = PS["Data.Date.Component"];
  var Data_Enum = PS["Data.Enum"];
  var Data_Eq = PS["Data.Eq"];
  var Data_Function = PS["Data.Function"];
  var Data_Function_Uncurried = PS["Data.Function.Uncurried"];
  var Data_Functor = PS["Data.Functor"];
  var Data_HeytingAlgebra = PS["Data.HeytingAlgebra"];
  var Data_Maybe = PS["Data.Maybe"];
  var Data_Ord = PS["Data.Ord"];
  var Data_Ordering = PS["Data.Ordering"];
  var Data_Semigroup = PS["Data.Semigroup"];
  var Data_Show = PS["Data.Show"];
  var Data_Time = PS["Data.Time"];
  var Data_Time_Component = PS["Data.Time.Component"];
  var Data_Time_Duration = PS["Data.Time.Duration"];
  var Prelude = PS["Prelude"];

  var DateTime = function () {
    function DateTime(value0, value1) {
      this.value0 = value0;
      this.value1 = value1;
    }

    ;

    DateTime.create = function (value0) {
      return function (value1) {
        return new DateTime(value0, value1);
      };
    };

    return DateTime;
  }();

  var date = function date(v) {
    return v.value0;
  };

  exports["DateTime"] = DateTime;
  exports["date"] = date;
})(PS["Data.DateTime"] = PS["Data.DateTime"] || {});

(function (exports) {
  "use strict";

  var createDateTime = function createDateTime(y, m, d, h, mi, s, ms) {
    var dateTime = new Date(Date.UTC(y, m, d, h, mi, s, ms));

    if (y >= 0 && y < 100) {
      dateTime.setUTCFullYear(y);
    }

    return dateTime;
  };

  exports.fromDateTimeImpl = function (y, mo, d, h, mi, s, ms) {
    return createDateTime(y, mo - 1, d, h, mi, s, ms).getTime();
  };
})(PS["Data.DateTime.Instant"] = PS["Data.DateTime.Instant"] || {});

(function (exports) {
  // Generated by purs version 0.12.3
  "use strict";

  var $foreign = PS["Data.DateTime.Instant"];
  var Data_Boolean = PS["Data.Boolean"];
  var Data_Bounded = PS["Data.Bounded"];
  var Data_Date = PS["Data.Date"];
  var Data_Date_Component = PS["Data.Date.Component"];
  var Data_DateTime = PS["Data.DateTime"];
  var Data_Enum = PS["Data.Enum"];
  var Data_Eq = PS["Data.Eq"];
  var Data_Function_Uncurried = PS["Data.Function.Uncurried"];
  var Data_HeytingAlgebra = PS["Data.HeytingAlgebra"];
  var Data_Maybe = PS["Data.Maybe"];
  var Data_Ord = PS["Data.Ord"];
  var Data_Ring = PS["Data.Ring"];
  var Data_Semigroup = PS["Data.Semigroup"];
  var Data_Show = PS["Data.Show"];
  var Data_Time = PS["Data.Time"];
  var Data_Time_Component = PS["Data.Time.Component"];
  var Data_Time_Duration = PS["Data.Time.Duration"];
  var Partial_Unsafe = PS["Partial.Unsafe"];
  var Prelude = PS["Prelude"];

  var unInstant = function unInstant(v) {
    return v;
  };

  var fromDateTime = function fromDateTime(v) {
    return $foreign.fromDateTimeImpl(Data_Date.year(v.value0), Data_Enum.fromEnum(Data_Date_Component.boundedEnumMonth)(Data_Date.month(v.value0)), Data_Date.day(v.value0), Data_Time.hour(v.value1), Data_Time.minute(v.value1), Data_Time.second(v.value1), Data_Time.millisecond(v.value1));
  };

  exports["unInstant"] = unInstant;
  exports["fromDateTime"] = fromDateTime;
})(PS["Data.DateTime.Instant"] = PS["Data.DateTime.Instant"] || {});

(function (exports) {
  // Generated by purs version 0.12.3
  "use strict";

  var Data_Maybe = PS["Data.Maybe"];

  var Inl = function () {
    function Inl(value0) {
      this.value0 = value0;
    }

    ;

    Inl.create = function (value0) {
      return new Inl(value0);
    };

    return Inl;
  }();

  var Inr = function () {
    function Inr(value0) {
      this.value0 = value0;
    }

    ;

    Inr.create = function (value0) {
      return new Inr(value0);
    };

    return Inr;
  }();

  var NoArguments = function () {
    function NoArguments() {}

    ;
    NoArguments.value = new NoArguments();
    return NoArguments;
  }();

  var Constructor = function Constructor(x) {
    return x;
  };

  var Argument = function Argument(x) {
    return x;
  };

  var Generic = function Generic(from, to) {
    this.from = from;
    this.to = to;
  };

  var to = function to(dict) {
    return dict.to;
  };

  var from = function from(dict) {
    return dict.from;
  };

  exports["Generic"] = Generic;
  exports["to"] = to;
  exports["from"] = from;
  exports["NoArguments"] = NoArguments;
  exports["Inl"] = Inl;
  exports["Inr"] = Inr;
  exports["Constructor"] = Constructor;
  exports["Argument"] = Argument;
})(PS["Data.Generic.Rep"] = PS["Data.Generic.Rep"] || {});

(function (exports) {
  // Generated by purs version 0.12.3
  "use strict";

  var Data_Boolean = PS["Data.Boolean"];
  var Data_Char = PS["Data.Char"];
  var Data_Eq = PS["Data.Eq"];
  var Data_Function = PS["Data.Function"];
  var Data_Generic_Rep = PS["Data.Generic.Rep"];
  var Data_HeytingAlgebra = PS["Data.HeytingAlgebra"];
  var Data_Maybe = PS["Data.Maybe"];
  var Data_Ord = PS["Data.Ord"];
  var Data_Ring = PS["Data.Ring"];
  var Data_Semigroup = PS["Data.Semigroup"];
  var Data_Semiring = PS["Data.Semiring"];
  var Data_Show = PS["Data.Show"];
  var Partial_Unsafe = PS["Partial.Unsafe"];
  var Prelude = PS["Prelude"];

  var Digit = function Digit(x) {
    return x;
  };

  var toInt = function toInt(v) {
    return v;
  };

  var toChar = function toChar(v) {
    return Data_Maybe.fromJust()(Data_Char.fromCharCode(v + 48 | 0));
  };

  var fromInt = function fromInt(c) {
    if (0 <= c && c <= 9) {
      return new Data_Maybe.Just(c);
    }

    ;

    if (Data_Boolean.otherwise) {
      return Data_Maybe.Nothing.value;
    }

    ;
    throw new Error("Failed pattern match at Data.Digit (line 27, column 1 - line 27, column 30): " + [c.constructor.name]);
  };

  var fromChar = function fromChar(c) {
    var code = Data_Char.toCharCode(c);
    var $16 = code >= 48 && code <= 57;

    if ($16) {
      return new Data_Maybe.Just(Digit(code - 48 | 0));
    }

    ;
    return Data_Maybe.Nothing.value;
  };

  var eqDigit = new Data_Eq.Eq(function (v) {
    return function (v1) {
      return v === v1;
    };
  });
  var ordDigit = new Data_Ord.Ord(function () {
    return eqDigit;
  }, function (v) {
    return function (v1) {
      return Data_Ord.compare(Data_Ord.ordInt)(v)(v1);
    };
  });
  var _zero = 0;
  var _one = 1;
  exports["fromInt"] = fromInt;
  exports["toInt"] = toInt;
  exports["fromChar"] = fromChar;
  exports["toChar"] = toChar;
  exports["_zero"] = _zero;
  exports["_one"] = _one;
  exports["eqDigit"] = eqDigit;
  exports["ordDigit"] = ordDigit;
})(PS["Data.Digit"] = PS["Data.Digit"] || {});

(function (exports) {
  "use strict";

  exports.split = function (sep) {
    return function (s) {
      return s.split(sep);
    };
  };

  exports.toLower = function (s) {
    return s.toLowerCase();
  };

  exports.joinWith = function (s) {
    return function (xs) {
      return xs.join(s);
    };
  };
})(PS["Data.String.Common"] = PS["Data.String.Common"] || {});

(function (exports) {
  // Generated by purs version 0.12.3
  "use strict";

  var $foreign = PS["Data.String.Common"];
  var Data_Eq = PS["Data.Eq"];
  var Data_Ordering = PS["Data.Ordering"];
  var Data_String_Pattern = PS["Data.String.Pattern"];
  var Prelude = PS["Prelude"];

  var $$null = function $$null(s) {
    return s === "";
  };

  exports["null"] = $$null;
  exports["split"] = $foreign.split;
  exports["toLower"] = $foreign.toLower;
  exports["joinWith"] = $foreign.joinWith;
})(PS["Data.String.Common"] = PS["Data.String.Common"] || {});

(function (exports) {
  // Generated by purs version 0.12.3
  "use strict";

  var Data_Eq = PS["Data.Eq"];
  var Data_EuclideanRing = PS["Data.EuclideanRing"];
  var Data_Foldable = PS["Data.Foldable"];
  var Data_HeytingAlgebra = PS["Data.HeytingAlgebra"];
  var Data_Newtype = PS["Data.Newtype"];
  var Data_Ord = PS["Data.Ord"];
  var Data_Ordering = PS["Data.Ordering"];
  var Data_Ring = PS["Data.Ring"];
  var Data_Semigroup = PS["Data.Semigroup"];
  var Data_Semiring = PS["Data.Semiring"];
  var Data_Show = PS["Data.Show"];
  var Data_String = PS["Data.String"];
  var Data_String_Common = PS["Data.String.Common"];
  var Data_String_Pattern = PS["Data.String.Pattern"];
  var Prelude = PS["Prelude"];

  var updatePosString = function updatePosString(pos$prime) {
    return function (str) {
      var updatePosChar = function updatePosChar(v) {
        return function (c) {
          if (c === "\x0a") {
            return {
              line: v.line + 1 | 0,
              column: 1
            };
          }

          ;

          if (c === "\x0d") {
            return {
              line: v.line + 1 | 0,
              column: 1
            };
          }

          ;

          if (c === "\x09") {
            return {
              line: v.line,
              column: (v.column + 8 | 0) - Data_EuclideanRing.mod(Data_EuclideanRing.euclideanRingInt)(v.column - 1 | 0)(8) | 0
            };
          }

          ;
          return {
            line: v.line,
            column: v.column + 1 | 0
          };
        };
      };

      return Data_Foldable.foldl(Data_Foldable.foldableArray)(updatePosChar)(pos$prime)(Data_String_Common.split(Data_Newtype.wrap(Data_String_Pattern.newtypePattern)(""))(str));
    };
  };

  var initialPos = {
    line: 1,
    column: 1
  };
  exports["initialPos"] = initialPos;
  exports["updatePosString"] = updatePosString;
})(PS["Text.Parsing.Parser.Pos"] = PS["Text.Parsing.Parser.Pos"] || {});

(function (exports) {
  // Generated by purs version 0.12.3
  "use strict";

  var Control_Alt = PS["Control.Alt"];
  var Control_Alternative = PS["Control.Alternative"];
  var Control_Applicative = PS["Control.Applicative"];
  var Control_Apply = PS["Control.Apply"];
  var Control_Bind = PS["Control.Bind"];
  var Control_Lazy = PS["Control.Lazy"];
  var Control_Monad = PS["Control.Monad"];
  var Control_Monad_Error_Class = PS["Control.Monad.Error.Class"];
  var Control_Monad_Except = PS["Control.Monad.Except"];
  var Control_Monad_Except_Trans = PS["Control.Monad.Except.Trans"];
  var Control_Monad_Rec_Class = PS["Control.Monad.Rec.Class"];
  var Control_Monad_State = PS["Control.Monad.State"];
  var Control_Monad_State_Class = PS["Control.Monad.State.Class"];
  var Control_Monad_State_Trans = PS["Control.Monad.State.Trans"];
  var Control_Monad_Trans_Class = PS["Control.Monad.Trans.Class"];
  var Control_MonadPlus = PS["Control.MonadPlus"];
  var Control_MonadZero = PS["Control.MonadZero"];
  var Control_Plus = PS["Control.Plus"];
  var Control_Semigroupoid = PS["Control.Semigroupoid"];
  var Data_Either = PS["Data.Either"];
  var Data_Eq = PS["Data.Eq"];
  var Data_Functor = PS["Data.Functor"];
  var Data_HeytingAlgebra = PS["Data.HeytingAlgebra"];
  var Data_Identity = PS["Data.Identity"];
  var Data_Monoid = PS["Data.Monoid"];
  var Data_Newtype = PS["Data.Newtype"];
  var Data_Ord = PS["Data.Ord"];
  var Data_Ordering = PS["Data.Ordering"];
  var Data_Semigroup = PS["Data.Semigroup"];
  var Data_Show = PS["Data.Show"];
  var Data_Tuple = PS["Data.Tuple"];
  var Prelude = PS["Prelude"];
  var Text_Parsing_Parser_Pos = PS["Text.Parsing.Parser.Pos"];

  var ParseState = function () {
    function ParseState(value0, value1, value2) {
      this.value0 = value0;
      this.value1 = value1;
      this.value2 = value2;
    }

    ;

    ParseState.create = function (value0) {
      return function (value1) {
        return function (value2) {
          return new ParseState(value0, value1, value2);
        };
      };
    };

    return ParseState;
  }();

  var ParseError = function () {
    function ParseError(value0, value1) {
      this.value0 = value0;
      this.value1 = value1;
    }

    ;

    ParseError.create = function (value0) {
      return function (value1) {
        return new ParseError(value0, value1);
      };
    };

    return ParseError;
  }();

  var ParserT = function ParserT(x) {
    return x;
  };

  var parseErrorPosition = function parseErrorPosition(v) {
    return v.value1;
  };

  var parseErrorMessage = function parseErrorMessage(v) {
    return v.value0;
  };

  var newtypeParserT = new Data_Newtype.Newtype(function (n) {
    return n;
  }, ParserT);

  var runParserT = function runParserT(dictMonad) {
    return function (s) {
      return function (p) {
        var initialState = new ParseState(s, Text_Parsing_Parser_Pos.initialPos, false);
        return Control_Monad_State_Trans.evalStateT(dictMonad.Bind1().Apply0().Functor0())(Control_Monad_Except_Trans.runExceptT(Data_Newtype.unwrap(newtypeParserT)(p)))(initialState);
      };
    };
  };

  var runParser = function runParser(s) {
    return function ($90) {
      return Data_Newtype.unwrap(Data_Identity.newtypeIdentity)(runParserT(Data_Identity.monadIdentity)(s)($90));
    };
  };

  var monadThrowParserT = function monadThrowParserT(dictMonad) {
    return Control_Monad_Except_Trans.monadThrowExceptT(Control_Monad_State_Trans.monadStateT(dictMonad));
  };

  var monadStateParserT = function monadStateParserT(dictMonad) {
    return Control_Monad_Except_Trans.monadStateExceptT(Control_Monad_State_Trans.monadStateStateT(dictMonad));
  };

  var position = function position(dictMonad) {
    return Control_Monad_State_Class.gets(monadStateParserT(dictMonad))(function (v) {
      return v.value1;
    });
  };

  var lazyParserT = new Control_Lazy.Lazy(function (f) {
    return Control_Lazy.defer(Control_Monad_State_Trans.lazyStateT)(function ($93) {
      return Control_Monad_Except_Trans.runExceptT(Data_Newtype.unwrap(newtypeParserT)(f($93)));
    });
  });

  var functorParserT = function functorParserT(dictFunctor) {
    return Control_Monad_Except_Trans.functorExceptT(Control_Monad_State_Trans.functorStateT(dictFunctor));
  };

  var failWithPosition = function failWithPosition(dictMonad) {
    return function (message) {
      return function (pos) {
        return Control_Monad_Error_Class.throwError(monadThrowParserT(dictMonad))(new ParseError(message, pos));
      };
    };
  };

  var bindParserT = function bindParserT(dictMonad) {
    return Control_Monad_Except_Trans.bindExceptT(Control_Monad_State_Trans.monadStateT(dictMonad));
  };

  var fail = function fail(dictMonad) {
    return function (message) {
      return Control_Bind.bindFlipped(bindParserT(dictMonad))(failWithPosition(dictMonad)(message))(position(dictMonad));
    };
  };

  var applyParserT = function applyParserT(dictMonad) {
    return Control_Monad_Except_Trans.applyExceptT(Control_Monad_State_Trans.monadStateT(dictMonad));
  };

  var applicativeParserT = function applicativeParserT(dictMonad) {
    return Control_Monad_Except_Trans.applicativeExceptT(Control_Monad_State_Trans.monadStateT(dictMonad));
  };

  var altParserT = function altParserT(dictMonad) {
    return new Control_Alt.Alt(function () {
      return functorParserT(dictMonad.Bind1().Apply0().Functor0());
    }, function (p1) {
      return function (p2) {
        return ParserT(Control_Monad_Except_Trans.ExceptT(Control_Monad_State_Trans.StateT(function (v) {
          return Control_Bind.bind(dictMonad.Bind1())(Control_Monad_State_Trans.runStateT(Control_Monad_Except_Trans.runExceptT(Data_Newtype.unwrap(newtypeParserT)(p1)))(new ParseState(v.value0, v.value1, false)))(function (v1) {
            if (v1.value0 instanceof Data_Either.Left && !v1.value1.value2) {
              return Control_Monad_State_Trans.runStateT(Control_Monad_Except_Trans.runExceptT(Data_Newtype.unwrap(newtypeParserT)(p2)))(v);
            }

            ;
            return Control_Applicative.pure(dictMonad.Applicative0())(new Data_Tuple.Tuple(v1.value0, v1.value1));
          });
        })));
      };
    });
  };

  var plusParserT = function plusParserT(dictMonad) {
    return new Control_Plus.Plus(function () {
      return altParserT(dictMonad);
    }, fail(dictMonad)("No alternative"));
  };

  var alternativeParserT = function alternativeParserT(dictMonad) {
    return new Control_Alternative.Alternative(function () {
      return applicativeParserT(dictMonad);
    }, function () {
      return plusParserT(dictMonad);
    });
  };

  exports["ParseError"] = ParseError;
  exports["parseErrorMessage"] = parseErrorMessage;
  exports["parseErrorPosition"] = parseErrorPosition;
  exports["ParseState"] = ParseState;
  exports["ParserT"] = ParserT;
  exports["runParser"] = runParser;
  exports["runParserT"] = runParserT;
  exports["position"] = position;
  exports["fail"] = fail;
  exports["failWithPosition"] = failWithPosition;
  exports["newtypeParserT"] = newtypeParserT;
  exports["lazyParserT"] = lazyParserT;
  exports["functorParserT"] = functorParserT;
  exports["applyParserT"] = applyParserT;
  exports["applicativeParserT"] = applicativeParserT;
  exports["bindParserT"] = bindParserT;
  exports["monadStateParserT"] = monadStateParserT;
  exports["monadThrowParserT"] = monadThrowParserT;
  exports["altParserT"] = altParserT;
  exports["plusParserT"] = plusParserT;
  exports["alternativeParserT"] = alternativeParserT;
})(PS["Text.Parsing.Parser"] = PS["Text.Parsing.Parser"] || {});

(function (exports) {
  // Generated by purs version 0.12.3
  "use strict";

  var Control_Alt = PS["Control.Alt"];
  var Control_Applicative = PS["Control.Applicative"];
  var Control_Apply = PS["Control.Apply"];
  var Control_Bind = PS["Control.Bind"];
  var Control_Monad_Except = PS["Control.Monad.Except"];
  var Control_Monad_Except_Trans = PS["Control.Monad.Except.Trans"];
  var Control_Monad_State = PS["Control.Monad.State"];
  var Control_Monad_State_Trans = PS["Control.Monad.State.Trans"];
  var Control_Plus = PS["Control.Plus"];
  var Control_Semigroupoid = PS["Control.Semigroupoid"];
  var Data_Either = PS["Data.Either"];
  var Data_Foldable = PS["Data.Foldable"];
  var Data_Function = PS["Data.Function"];
  var Data_Functor = PS["Data.Functor"];
  var Data_List = PS["Data.List"];
  var Data_List_Types = PS["Data.List.Types"];
  var Data_Maybe = PS["Data.Maybe"];
  var Data_Newtype = PS["Data.Newtype"];
  var Data_Semigroup = PS["Data.Semigroup"];
  var Data_Tuple = PS["Data.Tuple"];
  var Data_Unit = PS["Data.Unit"];
  var Prelude = PS["Prelude"];
  var Text_Parsing_Parser = PS["Text.Parsing.Parser"];

  var withErrorMessage = function withErrorMessage(dictMonad) {
    return function (p) {
      return function (msg) {
        return Control_Alt.alt(Text_Parsing_Parser.altParserT(dictMonad))(p)(Text_Parsing_Parser.fail(dictMonad)("Expected " + msg));
      };
    };
  };

  var tryRethrow = function tryRethrow(dictMonad) {
    return function (p) {
      return Text_Parsing_Parser.ParserT(Control_Monad_Except_Trans.ExceptT(Control_Monad_State_Trans.StateT(function (v) {
        return Control_Bind.bind(dictMonad.Bind1())(Control_Monad_State_Trans.runStateT(Control_Monad_Except_Trans.runExceptT(Data_Newtype.unwrap(Text_Parsing_Parser.newtypeParserT)(p)))(v))(function (v1) {
          if (v1.value0 instanceof Data_Either.Left) {
            return Control_Applicative.pure(dictMonad.Applicative0())(new Data_Tuple.Tuple(new Data_Either.Left(new Text_Parsing_Parser.ParseError(v1.value0.value0.value0, v.value1)), new Text_Parsing_Parser.ParseState(v1.value1.value0, v1.value1.value1, v.value2)));
          }

          ;
          return Control_Applicative.pure(dictMonad.Applicative0())(new Data_Tuple.Tuple(v1.value0, v1.value1));
        });
      })));
    };
  };

  var $$try = function $$try(dictMonad) {
    return function (p) {
      return Text_Parsing_Parser.ParserT(Control_Monad_Except_Trans.ExceptT(Control_Monad_State_Trans.StateT(function (v) {
        return Control_Bind.bind(dictMonad.Bind1())(Control_Monad_State_Trans.runStateT(Control_Monad_Except_Trans.runExceptT(Data_Newtype.unwrap(Text_Parsing_Parser.newtypeParserT)(p)))(v))(function (v1) {
          if (v1.value0 instanceof Data_Either.Left) {
            return Control_Applicative.pure(dictMonad.Applicative0())(new Data_Tuple.Tuple(v1.value0, new Text_Parsing_Parser.ParseState(v1.value1.value0, v1.value1.value1, v.value2)));
          }

          ;
          return Control_Applicative.pure(dictMonad.Applicative0())(new Data_Tuple.Tuple(v1.value0, v1.value1));
        });
      })));
    };
  };

  var choice = function choice(dictFoldable) {
    return function (dictMonad) {
      return Data_Foldable.foldl(dictFoldable)(Control_Alt.alt(Text_Parsing_Parser.altParserT(dictMonad)))(Control_Plus.empty(Text_Parsing_Parser.plusParserT(dictMonad)));
    };
  };

  exports["withErrorMessage"] = withErrorMessage;
  exports["try"] = $$try;
  exports["tryRethrow"] = tryRethrow;
  exports["choice"] = choice;
})(PS["Text.Parsing.Parser.Combinators"] = PS["Text.Parsing.Parser.Combinators"] || {});

(function (exports) {
  "use strict";
  /* global Symbol */

  var hasArrayFrom = typeof Array.from === "function";
  var hasStringIterator = typeof Symbol !== "undefined" && Symbol != null && typeof Symbol.iterator !== "undefined" && typeof String.prototype[Symbol.iterator] === "function";
  var hasFromCodePoint = typeof String.prototype.fromCodePoint === "function";
  var hasCodePointAt = typeof String.prototype.codePointAt === "function";

  exports._unsafeCodePointAt0 = function (fallback) {
    return hasCodePointAt ? function (str) {
      return str.codePointAt(0);
    } : fallback;
  };

  exports._singleton = function (fallback) {
    return hasFromCodePoint ? String.fromCodePoint : fallback;
  };

  exports._take = function (fallback) {
    return function (n) {
      if (hasStringIterator) {
        return function (str) {
          var accum = "";
          var iter = str[Symbol.iterator]();

          for (var i = 0; i < n; ++i) {
            var o = iter.next();
            if (o.done) return accum;
            accum += o.value;
          }

          return accum;
        };
      }

      return fallback(n);
    };
  };

  exports._toCodePointArray = function (fallback) {
    return function (unsafeCodePointAt0) {
      if (hasArrayFrom) {
        return function (str) {
          return Array.from(str, unsafeCodePointAt0);
        };
      }

      return fallback;
    };
  };
})(PS["Data.String.CodePoints"] = PS["Data.String.CodePoints"] || {});

(function (exports) {
  // Generated by purs version 0.12.3
  "use strict";

  var $foreign = PS["Data.String.CodePoints"];
  var Control_Semigroupoid = PS["Control.Semigroupoid"];
  var Data_Array = PS["Data.Array"];
  var Data_Boolean = PS["Data.Boolean"];
  var Data_Bounded = PS["Data.Bounded"];
  var Data_Enum = PS["Data.Enum"];
  var Data_Eq = PS["Data.Eq"];
  var Data_EuclideanRing = PS["Data.EuclideanRing"];
  var Data_Functor = PS["Data.Functor"];
  var Data_HeytingAlgebra = PS["Data.HeytingAlgebra"];
  var Data_Int = PS["Data.Int"];
  var Data_Maybe = PS["Data.Maybe"];
  var Data_Ord = PS["Data.Ord"];
  var Data_Ring = PS["Data.Ring"];
  var Data_Semigroup = PS["Data.Semigroup"];
  var Data_Semiring = PS["Data.Semiring"];
  var Data_Show = PS["Data.Show"];
  var Data_String_CodeUnits = PS["Data.String.CodeUnits"];
  var Data_String_Common = PS["Data.String.Common"];
  var Data_String_Pattern = PS["Data.String.Pattern"];
  var Data_String_Unsafe = PS["Data.String.Unsafe"];
  var Data_Tuple = PS["Data.Tuple"];
  var Data_Unfoldable = PS["Data.Unfoldable"];
  var Prelude = PS["Prelude"];

  var unsurrogate = function unsurrogate(lead) {
    return function (trail) {
      return (((lead - 55296 | 0) * 1024 | 0) + (trail - 56320 | 0) | 0) + 65536 | 0;
    };
  };

  var isTrail = function isTrail(cu) {
    return 56320 <= cu && cu <= 57343;
  };

  var isLead = function isLead(cu) {
    return 55296 <= cu && cu <= 56319;
  };

  var uncons = function uncons(s) {
    var v = Data_String_CodeUnits.length(s);

    if (v === 0) {
      return Data_Maybe.Nothing.value;
    }

    ;

    if (v === 1) {
      return new Data_Maybe.Just({
        head: Data_Enum.fromEnum(Data_Enum.boundedEnumChar)(Data_String_Unsafe.charAt(0)(s)),
        tail: ""
      });
    }

    ;
    var cu1 = Data_Enum.fromEnum(Data_Enum.boundedEnumChar)(Data_String_Unsafe.charAt(1)(s));
    var cu0 = Data_Enum.fromEnum(Data_Enum.boundedEnumChar)(Data_String_Unsafe.charAt(0)(s));
    var $21 = isLead(cu0) && isTrail(cu1);

    if ($21) {
      return new Data_Maybe.Just({
        head: unsurrogate(cu0)(cu1),
        tail: Data_String_CodeUnits.drop(2)(s)
      });
    }

    ;
    return new Data_Maybe.Just({
      head: cu0,
      tail: Data_String_CodeUnits.drop(1)(s)
    });
  };

  var unconsButWithTuple = function unconsButWithTuple(s) {
    return Data_Functor.map(Data_Maybe.functorMaybe)(function (v) {
      return new Data_Tuple.Tuple(v.head, v.tail);
    })(uncons(s));
  };

  var toCodePointArrayFallback = function toCodePointArrayFallback(s) {
    return Data_Unfoldable.unfoldr(Data_Unfoldable.unfoldableArray)(unconsButWithTuple)(s);
  };

  var unsafeCodePointAt0Fallback = function unsafeCodePointAt0Fallback(s) {
    var cu0 = Data_Enum.fromEnum(Data_Enum.boundedEnumChar)(Data_String_Unsafe.charAt(0)(s));
    var $25 = isLead(cu0) && Data_String_CodeUnits.length(s) > 1;

    if ($25) {
      var cu1 = Data_Enum.fromEnum(Data_Enum.boundedEnumChar)(Data_String_Unsafe.charAt(1)(s));
      var $26 = isTrail(cu1);

      if ($26) {
        return unsurrogate(cu0)(cu1);
      }

      ;
      return cu0;
    }

    ;
    return cu0;
  };

  var unsafeCodePointAt0 = $foreign["_unsafeCodePointAt0"](unsafeCodePointAt0Fallback);
  var toCodePointArray = $foreign["_toCodePointArray"](toCodePointArrayFallback)(unsafeCodePointAt0);

  var length = function length($52) {
    return Data_Array.length(toCodePointArray($52));
  };

  var indexOf = function indexOf(p) {
    return function (s) {
      return Data_Functor.map(Data_Maybe.functorMaybe)(function (i) {
        return length(Data_String_CodeUnits.take(i)(s));
      })(Data_String_CodeUnits.indexOf(p)(s));
    };
  };

  var fromCharCode = function fromCharCode($53) {
    return Data_String_CodeUnits.singleton(Data_Enum.toEnumWithDefaults(Data_Enum.boundedEnumChar)(Data_Bounded.bottom(Data_Bounded.boundedChar))(Data_Bounded.top(Data_Bounded.boundedChar))($53));
  };

  var singletonFallback = function singletonFallback(v) {
    if (v <= 65535) {
      return fromCharCode(v);
    }

    ;
    var lead = Data_EuclideanRing.div(Data_EuclideanRing.euclideanRingInt)(v - 65536 | 0)(1024) + 55296 | 0;
    var trail = Data_EuclideanRing.mod(Data_EuclideanRing.euclideanRingInt)(v - 65536 | 0)(1024) + 56320 | 0;
    return fromCharCode(lead) + fromCharCode(trail);
  };

  var singleton = $foreign["_singleton"](singletonFallback);

  var takeFallback = function takeFallback(n) {
    return function (v) {
      if (n < 1) {
        return "";
      }

      ;
      var v1 = uncons(v);

      if (v1 instanceof Data_Maybe.Just) {
        return singleton(v1.value0.head) + takeFallback(n - 1 | 0)(v1.value0.tail);
      }

      ;
      return v;
    };
  };

  var take = $foreign["_take"](takeFallback);

  var splitAt = function splitAt(i) {
    return function (s) {
      var before = take(i)(s);
      return {
        before: before,
        after: Data_String_CodeUnits.drop(Data_String_CodeUnits.length(before))(s)
      };
    };
  };

  var drop = function drop(n) {
    return function (s) {
      return Data_String_CodeUnits.drop(Data_String_CodeUnits.length(take(n)(s)))(s);
    };
  };

  exports["singleton"] = singleton;
  exports["toCodePointArray"] = toCodePointArray;
  exports["uncons"] = uncons;
  exports["length"] = length;
  exports["indexOf"] = indexOf;
  exports["take"] = take;
  exports["drop"] = drop;
  exports["splitAt"] = splitAt;
})(PS["Data.String.CodePoints"] = PS["Data.String.CodePoints"] || {});

(function (exports) {
  // Generated by purs version 0.12.3
  "use strict";

  var Control_Applicative = PS["Control.Applicative"];
  var Control_Bind = PS["Control.Bind"];
  var Control_Monad_State = PS["Control.Monad.State"];
  var Control_Monad_State_Class = PS["Control.Monad.State.Class"];
  var Data_Array = PS["Data.Array"];
  var Data_Eq = PS["Data.Eq"];
  var Data_Foldable = PS["Data.Foldable"];
  var Data_Function = PS["Data.Function"];
  var Data_Functor = PS["Data.Functor"];
  var Data_HeytingAlgebra = PS["Data.HeytingAlgebra"];
  var Data_Maybe = PS["Data.Maybe"];
  var Data_Newtype = PS["Data.Newtype"];
  var Data_Semigroup = PS["Data.Semigroup"];
  var Data_Show = PS["Data.Show"];
  var Data_String = PS["Data.String"];
  var Data_String_CodePoints = PS["Data.String.CodePoints"];
  var Data_String_CodeUnits = PS["Data.String.CodeUnits"];
  var Data_String_Common = PS["Data.String.Common"];
  var Data_String_Pattern = PS["Data.String.Pattern"];
  var Prelude = PS["Prelude"];
  var Text_Parsing_Parser = PS["Text.Parsing.Parser"];
  var Text_Parsing_Parser_Combinators = PS["Text.Parsing.Parser.Combinators"];
  var Text_Parsing_Parser_Pos = PS["Text.Parsing.Parser.Pos"];

  var StringLike = function StringLike(drop, indexOf, $$null, uncons) {
    this.drop = drop;
    this.indexOf = indexOf;
    this["null"] = $$null;
    this.uncons = uncons;
  };

  var uncons = function uncons(dict) {
    return dict.uncons;
  };

  var stringLikeString = new StringLike(Data_String_CodePoints.drop, Data_String_CodePoints.indexOf, Data_String_Common["null"], Data_String_CodeUnits.uncons);

  var $$null = function $$null(dict) {
    return dict["null"];
  };

  var indexOf = function indexOf(dict) {
    return dict.indexOf;
  };

  var eof = function eof(dictStringLike) {
    return function (dictMonad) {
      return Control_Bind.bind(Text_Parsing_Parser.bindParserT(dictMonad))(Control_Monad_State_Class.gets(Text_Parsing_Parser.monadStateParserT(dictMonad))(function (v) {
        return v.value0;
      }))(function (v) {
        return Control_Applicative.unless(Text_Parsing_Parser.applicativeParserT(dictMonad))($$null(dictStringLike)(v))(Text_Parsing_Parser.fail(dictMonad)("Expected EOF"));
      });
    };
  };

  var drop = function drop(dict) {
    return dict.drop;
  };

  var string = function string(dictStringLike) {
    return function (dictMonad) {
      return function (str) {
        return Control_Bind.bind(Text_Parsing_Parser.bindParserT(dictMonad))(Control_Monad_State_Class.gets(Text_Parsing_Parser.monadStateParserT(dictMonad))(function (v) {
          return v.value0;
        }))(function (v) {
          var v1 = indexOf(dictStringLike)(Data_Newtype.wrap(Data_String_Pattern.newtypePattern)(str))(v);

          if (v1 instanceof Data_Maybe.Just && v1.value0 === 0) {
            return Control_Bind.discard(Control_Bind.discardUnit)(Text_Parsing_Parser.bindParserT(dictMonad))(Control_Monad_State_Class.modify_(Text_Parsing_Parser.monadStateParserT(dictMonad))(function (v2) {
              return new Text_Parsing_Parser.ParseState(drop(dictStringLike)(Data_String_CodePoints.length(str))(v), Text_Parsing_Parser_Pos.updatePosString(v2.value1)(str), true);
            }))(function () {
              return Control_Applicative.pure(Text_Parsing_Parser.applicativeParserT(dictMonad))(str);
            });
          }

          ;
          return Text_Parsing_Parser.fail(dictMonad)("Expected " + Data_Show.show(Data_Show.showString)(str));
        });
      };
    };
  };

  var anyChar = function anyChar(dictStringLike) {
    return function (dictMonad) {
      return Control_Bind.bind(Text_Parsing_Parser.bindParserT(dictMonad))(Control_Monad_State_Class.gets(Text_Parsing_Parser.monadStateParserT(dictMonad))(function (v) {
        return v.value0;
      }))(function (v) {
        var v1 = uncons(dictStringLike)(v);

        if (v1 instanceof Data_Maybe.Nothing) {
          return Text_Parsing_Parser.fail(dictMonad)("Unexpected EOF");
        }

        ;

        if (v1 instanceof Data_Maybe.Just) {
          return Control_Bind.discard(Control_Bind.discardUnit)(Text_Parsing_Parser.bindParserT(dictMonad))(Control_Monad_State_Class.modify_(Text_Parsing_Parser.monadStateParserT(dictMonad))(function (v2) {
            return new Text_Parsing_Parser.ParseState(v1.value0.tail, Text_Parsing_Parser_Pos.updatePosString(v2.value1)(Data_String_CodeUnits.singleton(v1.value0.head)), true);
          }))(function () {
            return Control_Applicative.pure(Text_Parsing_Parser.applicativeParserT(dictMonad))(v1.value0.head);
          });
        }

        ;
        throw new Error("Failed pattern match at Text.Parsing.Parser.String (line 56, column 3 - line 63, column 16): " + [v1.constructor.name]);
      });
    };
  };

  var satisfy = function satisfy(dictStringLike) {
    return function (dictMonad) {
      return function (f) {
        return Text_Parsing_Parser_Combinators.tryRethrow(dictMonad)(Control_Bind.bind(Text_Parsing_Parser.bindParserT(dictMonad))(anyChar(dictStringLike)(dictMonad))(function (v) {
          var $61 = f(v);

          if ($61) {
            return Control_Applicative.pure(Text_Parsing_Parser.applicativeParserT(dictMonad))(v);
          }

          ;
          return Text_Parsing_Parser.fail(dictMonad)("Character '" + (Data_String_CodeUnits.singleton(v) + "' did not satisfy predicate"));
        }));
      };
    };
  };

  var noneOf = function noneOf(dictStringLike) {
    return function (dictMonad) {
      return function (ss) {
        return Text_Parsing_Parser_Combinators.withErrorMessage(dictMonad)(satisfy(dictStringLike)(dictMonad)(Data_Function.flip(Data_Foldable.notElem(Data_Foldable.foldableArray)(Data_Eq.eqChar))(ss)))("none of " + Data_Show.show(Data_Show.showArray(Data_Show.showChar))(ss));
      };
    };
  };

  exports["drop"] = drop;
  exports["indexOf"] = indexOf;
  exports["uncons"] = uncons;
  exports["StringLike"] = StringLike;
  exports["eof"] = eof;
  exports["string"] = string;
  exports["anyChar"] = anyChar;
  exports["satisfy"] = satisfy;
  exports["noneOf"] = noneOf;
  exports["stringLikeString"] = stringLikeString;
})(PS["Text.Parsing.Parser.String"] = PS["Text.Parsing.Parser.String"] || {});

(function (exports) {
  // Generated by purs version 0.12.3
  "use strict";

  var Control_Apply = PS["Control.Apply"];
  var Data_Bifunctor = PS["Data.Bifunctor"];
  var Data_Either = PS["Data.Either"];
  var Data_Foldable = PS["Data.Foldable"];
  var Data_Function = PS["Data.Function"];
  var Data_Functor = PS["Data.Functor"];
  var Data_Identity = PS["Data.Identity"];
  var Data_Semigroup = PS["Data.Semigroup"];
  var Data_Show = PS["Data.Show"];
  var Data_Tuple = PS["Data.Tuple"];
  var Prelude = PS["Prelude"];
  var Text_Parsing_Parser = PS["Text.Parsing.Parser"];
  var Text_Parsing_Parser_Combinators = PS["Text.Parsing.Parser.Combinators"];
  var Text_Parsing_Parser_Pos = PS["Text.Parsing.Parser.Pos"];
  var Text_Parsing_Parser_String = PS["Text.Parsing.Parser.String"];

  var printPosition = function printPosition(v) {
    return "(line " + (Data_Show.show(Data_Show.showInt)(v.line) + (", col " + (Data_Show.show(Data_Show.showInt)(v.column) + ")")));
  };

  var printError = function printError(err) {
    return Text_Parsing_Parser.parseErrorMessage(err) + (" " + printPosition(Text_Parsing_Parser.parseErrorPosition(err)));
  };

  var runP = function runP(dictStringLike) {
    return function (p) {
      return function (s) {
        return Data_Bifunctor.lmap(Data_Either.bifunctorEither)(printError)(Text_Parsing_Parser.runParser(s)(Control_Apply.applyFirst(Text_Parsing_Parser.applyParserT(Data_Identity.monadIdentity))(p)(Text_Parsing_Parser_String.eof(dictStringLike)(Data_Identity.monadIdentity))));
      };
    };
  };

  var oneOfAs = function oneOfAs(dictFunctor) {
    return function (dictFoldable) {
      return function (dictMonad) {
        return function (p) {
          return function (xs) {
            return Text_Parsing_Parser_Combinators.choice(dictFoldable)(dictMonad)(Data_Functor.map(dictFunctor)(function (v) {
              return Data_Functor.voidLeft(Text_Parsing_Parser.functorParserT(dictMonad.Bind1().Apply0().Functor0()))(p(v.value0))(v.value1);
            })(xs));
          };
        };
      };
    };
  };

  exports["oneOfAs"] = oneOfAs;
  exports["runP"] = runP;
})(PS["Data.Formatter.Parser.Utils"] = PS["Data.Formatter.Parser.Utils"] || {});

(function (exports) {
  // Generated by purs version 0.12.3
  "use strict";

  var Data_Foldable = PS["Data.Foldable"];
  var Data_Generic_Rep = PS["Data.Generic.Rep"];
  var Data_Monoid = PS["Data.Monoid"];
  var Data_Semigroup = PS["Data.Semigroup"];
  var Data_Show = PS["Data.Show"];
  var Data_Symbol = PS["Data.Symbol"];
  var Prelude = PS["Prelude"];

  var GenericShow = function GenericShow(genericShow$prime) {
    this["genericShow'"] = genericShow$prime;
  };

  var GenericShowArgs = function GenericShowArgs(genericShowArgs) {
    this.genericShowArgs = genericShowArgs;
  };

  var genericShowArgsNoArguments = new GenericShowArgs(function (v) {
    return [];
  });

  var genericShowArgsArgument = function genericShowArgsArgument(dictShow) {
    return new GenericShowArgs(function (v) {
      return [Data_Show.show(dictShow)(v)];
    });
  };

  var genericShowArgs = function genericShowArgs(dict) {
    return dict.genericShowArgs;
  };

  var genericShowConstructor = function genericShowConstructor(dictGenericShowArgs) {
    return function (dictIsSymbol) {
      return new GenericShow(function (v) {
        var ctor = Data_Symbol.reflectSymbol(dictIsSymbol)(Data_Symbol.SProxy.value);
        var v1 = genericShowArgs(dictGenericShowArgs)(v);

        if (v1.length === 0) {
          return ctor;
        }

        ;
        return "(" + (Data_Foldable.intercalate(Data_Foldable.foldableArray)(Data_Monoid.monoidString)(" ")(Data_Semigroup.append(Data_Semigroup.semigroupArray)([ctor])(v1)) + ")");
      });
    };
  };

  var genericShow$prime = function genericShow$prime(dict) {
    return dict["genericShow'"];
  };

  var genericShowSum = function genericShowSum(dictGenericShow) {
    return function (dictGenericShow1) {
      return new GenericShow(function (v) {
        if (v instanceof Data_Generic_Rep.Inl) {
          return genericShow$prime(dictGenericShow)(v.value0);
        }

        ;

        if (v instanceof Data_Generic_Rep.Inr) {
          return genericShow$prime(dictGenericShow1)(v.value0);
        }

        ;
        throw new Error("Failed pattern match at Data.Generic.Rep.Show (line 26, column 1 - line 26, column 83): " + [v.constructor.name]);
      });
    };
  };

  var genericShow = function genericShow(dictGeneric) {
    return function (dictGenericShow) {
      return function (x) {
        return genericShow$prime(dictGenericShow)(Data_Generic_Rep.from(dictGeneric)(x));
      };
    };
  };

  exports["GenericShow"] = GenericShow;
  exports["genericShow"] = genericShow;
  exports["GenericShowArgs"] = GenericShowArgs;
  exports["genericShowArgs"] = genericShowArgs;
  exports["genericShowArgsNoArguments"] = genericShowArgsNoArguments;
  exports["genericShowSum"] = genericShowSum;
  exports["genericShowConstructor"] = genericShowConstructor;
  exports["genericShowArgsArgument"] = genericShowArgsArgument;
})(PS["Data.Generic.Rep.Show"] = PS["Data.Generic.Rep.Show"] || {});

(function (exports) {
  // Generated by purs version 0.12.3
  "use strict";

  var Control_Alt = PS["Control.Alt"];
  var Control_Alternative = PS["Control.Alternative"];
  var Control_Applicative = PS["Control.Applicative"];
  var Control_Apply = PS["Control.Apply"];
  var Control_Bind = PS["Control.Bind"];
  var Control_Category = PS["Control.Category"];
  var Control_Lazy = PS["Control.Lazy"];
  var Control_Monad_Reader_Class = PS["Control.Monad.Reader.Class"];
  var Control_Monad_Reader_Trans = PS["Control.Monad.Reader.Trans"];
  var Control_Monad_State = PS["Control.Monad.State"];
  var Control_Monad_State_Class = PS["Control.Monad.State.Class"];
  var Control_Monad_State_Trans = PS["Control.Monad.State.Trans"];
  var Control_Monad_Trans_Class = PS["Control.Monad.Trans.Class"];
  var Control_Semigroupoid = PS["Control.Semigroupoid"];
  var Data_Array = PS["Data.Array"];
  var Data_Boolean = PS["Data.Boolean"];
  var Data_Date = PS["Data.Date"];
  var Data_Date_Component = PS["Data.Date.Component"];
  var Data_DateTime = PS["Data.DateTime"];
  var Data_DateTime_Instant = PS["Data.DateTime.Instant"];
  var Data_Either = PS["Data.Either"];
  var Data_Enum = PS["Data.Enum"];
  var Data_Eq = PS["Data.Eq"];
  var Data_EuclideanRing = PS["Data.EuclideanRing"];
  var Data_Foldable = PS["Data.Foldable"];
  var Data_Formatter_Internal = PS["Data.Formatter.Internal"];
  var Data_Formatter_Parser_Number = PS["Data.Formatter.Parser.Number"];
  var Data_Formatter_Parser_Utils = PS["Data.Formatter.Parser.Utils"];
  var Data_Function = PS["Data.Function"];
  var Data_Functor = PS["Data.Functor"];
  var Data_Generic_Rep = PS["Data.Generic.Rep"];
  var Data_Generic_Rep_Show = PS["Data.Generic.Rep.Show"];
  var Data_HeytingAlgebra = PS["Data.HeytingAlgebra"];
  var Data_Identity = PS["Data.Identity"];
  var Data_Int = PS["Data.Int"];
  var Data_List = PS["Data.List"];
  var Data_List_Types = PS["Data.List.Types"];
  var Data_Maybe = PS["Data.Maybe"];
  var Data_Monoid = PS["Data.Monoid"];
  var Data_Newtype = PS["Data.Newtype"];
  var Data_Ord = PS["Data.Ord"];
  var Data_Ordering = PS["Data.Ordering"];
  var Data_Ring = PS["Data.Ring"];
  var Data_Semigroup = PS["Data.Semigroup"];
  var Data_Semiring = PS["Data.Semiring"];
  var Data_Show = PS["Data.Show"];
  var Data_String = PS["Data.String"];
  var Data_String_CodePoints = PS["Data.String.CodePoints"];
  var Data_String_CodeUnits = PS["Data.String.CodeUnits"];
  var Data_Symbol = PS["Data.Symbol"];
  var Data_Time = PS["Data.Time"];
  var Data_Time_Component = PS["Data.Time.Component"];
  var Data_Time_Duration = PS["Data.Time.Duration"];
  var Data_Tuple = PS["Data.Tuple"];
  var Data_Unit = PS["Data.Unit"];
  var Prelude = PS["Prelude"];
  var Text_Parsing_Parser = PS["Text.Parsing.Parser"];
  var Text_Parsing_Parser_Combinators = PS["Text.Parsing.Parser.Combinators"];
  var Text_Parsing_Parser_String = PS["Text.Parsing.Parser.String"];

  var YearFull = function () {
    function YearFull() {}

    ;
    YearFull.value = new YearFull();
    return YearFull;
  }();

  var YearTwoDigits = function () {
    function YearTwoDigits() {}

    ;
    YearTwoDigits.value = new YearTwoDigits();
    return YearTwoDigits;
  }();

  var YearAbsolute = function () {
    function YearAbsolute() {}

    ;
    YearAbsolute.value = new YearAbsolute();
    return YearAbsolute;
  }();

  var MonthFull = function () {
    function MonthFull() {}

    ;
    MonthFull.value = new MonthFull();
    return MonthFull;
  }();

  var MonthShort = function () {
    function MonthShort() {}

    ;
    MonthShort.value = new MonthShort();
    return MonthShort;
  }();

  var MonthTwoDigits = function () {
    function MonthTwoDigits() {}

    ;
    MonthTwoDigits.value = new MonthTwoDigits();
    return MonthTwoDigits;
  }();

  var DayOfMonthTwoDigits = function () {
    function DayOfMonthTwoDigits() {}

    ;
    DayOfMonthTwoDigits.value = new DayOfMonthTwoDigits();
    return DayOfMonthTwoDigits;
  }();

  var DayOfMonth = function () {
    function DayOfMonth() {}

    ;
    DayOfMonth.value = new DayOfMonth();
    return DayOfMonth;
  }();

  var UnixTimestamp = function () {
    function UnixTimestamp() {}

    ;
    UnixTimestamp.value = new UnixTimestamp();
    return UnixTimestamp;
  }();

  var DayOfWeek = function () {
    function DayOfWeek() {}

    ;
    DayOfWeek.value = new DayOfWeek();
    return DayOfWeek;
  }();

  var DayOfWeekName = function () {
    function DayOfWeekName() {}

    ;
    DayOfWeekName.value = new DayOfWeekName();
    return DayOfWeekName;
  }();

  var DayOfWeekNameShort = function () {
    function DayOfWeekNameShort() {}

    ;
    DayOfWeekNameShort.value = new DayOfWeekNameShort();
    return DayOfWeekNameShort;
  }();

  var Hours24 = function () {
    function Hours24() {}

    ;
    Hours24.value = new Hours24();
    return Hours24;
  }();

  var Hours12 = function () {
    function Hours12() {}

    ;
    Hours12.value = new Hours12();
    return Hours12;
  }();

  var Meridiem = function () {
    function Meridiem() {}

    ;
    Meridiem.value = new Meridiem();
    return Meridiem;
  }();

  var Minutes = function () {
    function Minutes() {}

    ;
    Minutes.value = new Minutes();
    return Minutes;
  }();

  var MinutesTwoDigits = function () {
    function MinutesTwoDigits() {}

    ;
    MinutesTwoDigits.value = new MinutesTwoDigits();
    return MinutesTwoDigits;
  }();

  var Seconds = function () {
    function Seconds() {}

    ;
    Seconds.value = new Seconds();
    return Seconds;
  }();

  var SecondsTwoDigits = function () {
    function SecondsTwoDigits() {}

    ;
    SecondsTwoDigits.value = new SecondsTwoDigits();
    return SecondsTwoDigits;
  }();

  var Milliseconds = function () {
    function Milliseconds() {}

    ;
    Milliseconds.value = new Milliseconds();
    return Milliseconds;
  }();

  var MillisecondsShort = function () {
    function MillisecondsShort() {}

    ;
    MillisecondsShort.value = new MillisecondsShort();
    return MillisecondsShort;
  }();

  var MillisecondsTwoDigits = function () {
    function MillisecondsTwoDigits() {}

    ;
    MillisecondsTwoDigits.value = new MillisecondsTwoDigits();
    return MillisecondsTwoDigits;
  }();

  var Placeholder = function () {
    function Placeholder(value0) {
      this.value0 = value0;
    }

    ;

    Placeholder.create = function (value0) {
      return new Placeholder(value0);
    };

    return Placeholder;
  }();

  var printShortMonth = function printShortMonth(v) {
    if (v instanceof Data_Date_Component.January) {
      return "Jan";
    }

    ;

    if (v instanceof Data_Date_Component.February) {
      return "Feb";
    }

    ;

    if (v instanceof Data_Date_Component.March) {
      return "Mar";
    }

    ;

    if (v instanceof Data_Date_Component.April) {
      return "Apr";
    }

    ;

    if (v instanceof Data_Date_Component.May) {
      return "May";
    }

    ;

    if (v instanceof Data_Date_Component.June) {
      return "Jun";
    }

    ;

    if (v instanceof Data_Date_Component.July) {
      return "Jul";
    }

    ;

    if (v instanceof Data_Date_Component.August) {
      return "Aug";
    }

    ;

    if (v instanceof Data_Date_Component.September) {
      return "Sep";
    }

    ;

    if (v instanceof Data_Date_Component.October) {
      return "Oct";
    }

    ;

    if (v instanceof Data_Date_Component.November) {
      return "Nov";
    }

    ;

    if (v instanceof Data_Date_Component.December) {
      return "Dec";
    }

    ;
    throw new Error("Failed pattern match at Data.Formatter.DateTime (line 482, column 19 - line 494, column 16): " + [v.constructor.name]);
  };

  var placeholderContent = Data_Functor.mapFlipped(Text_Parsing_Parser.functorParserT(Data_Identity.functorIdentity))(Data_Array.some(Text_Parsing_Parser.alternativeParserT(Data_Identity.monadIdentity))(Text_Parsing_Parser.lazyParserT)(Text_Parsing_Parser_String.noneOf(Text_Parsing_Parser_String.stringLikeString)(Data_Identity.monadIdentity)(Data_String_CodeUnits.toCharArray("YMDEHhamsS"))))(Data_String_CodeUnits.fromCharArray);

  var padSingleDigit = function padSingleDigit(i) {
    if (i < 0) {
      return "-" + padSingleDigit(-i | 0);
    }

    ;

    if (i < 10) {
      return "0" + Data_Show.show(Data_Show.showInt)(i);
    }

    ;

    if (Data_Boolean.otherwise) {
      return Data_Show.show(Data_Show.showInt)(i);
    }

    ;
    throw new Error("Failed pattern match at Data.Formatter.DateTime (line 192, column 1 - line 192, column 30): " + [i.constructor.name]);
  };

  var padQuadrupleDigit = function padQuadrupleDigit(i) {
    if (i < 0) {
      return "-" + padQuadrupleDigit(-i | 0);
    }

    ;

    if (i < 10) {
      return "000" + Data_Show.show(Data_Show.showInt)(i);
    }

    ;

    if (i < 100) {
      return "00" + Data_Show.show(Data_Show.showInt)(i);
    }

    ;

    if (i < 1000) {
      return "0" + Data_Show.show(Data_Show.showInt)(i);
    }

    ;

    if (Data_Boolean.otherwise) {
      return Data_Show.show(Data_Show.showInt)(i);
    }

    ;
    throw new Error("Failed pattern match at Data.Formatter.DateTime (line 205, column 1 - line 205, column 33): " + [i.constructor.name]);
  };

  var padDoubleDigit = function padDoubleDigit(i) {
    if (i < 0) {
      return "-" + padDoubleDigit(-i | 0);
    }

    ;

    if (i < 10) {
      return "00" + Data_Show.show(Data_Show.showInt)(i);
    }

    ;

    if (i < 100) {
      return "0" + Data_Show.show(Data_Show.showInt)(i);
    }

    ;

    if (Data_Boolean.otherwise) {
      return Data_Show.show(Data_Show.showInt)(i);
    }

    ;
    throw new Error("Failed pattern match at Data.Formatter.DateTime (line 198, column 1 - line 198, column 30): " + [i.constructor.name]);
  };

  var formatterCommandParser = Control_Alt.alt(Text_Parsing_Parser.altParserT(Data_Identity.monadIdentity))(Data_Formatter_Parser_Utils.oneOfAs(Data_Functor.functorArray)(Data_Foldable.foldableArray)(Data_Identity.monadIdentity)(function ($450) {
    return Text_Parsing_Parser_Combinators["try"](Data_Identity.monadIdentity)(Text_Parsing_Parser_String.string(Text_Parsing_Parser_String.stringLikeString)(Data_Identity.monadIdentity)($450));
  })([new Data_Tuple.Tuple("YYYY", YearFull.value), new Data_Tuple.Tuple("YY", YearTwoDigits.value), new Data_Tuple.Tuple("Y", YearAbsolute.value), new Data_Tuple.Tuple("MMMM", MonthFull.value), new Data_Tuple.Tuple("MMM", MonthShort.value), new Data_Tuple.Tuple("MM", MonthTwoDigits.value), new Data_Tuple.Tuple("DD", DayOfMonthTwoDigits.value), new Data_Tuple.Tuple("D", DayOfMonth.value), new Data_Tuple.Tuple("E", DayOfWeek.value), new Data_Tuple.Tuple("X", UnixTimestamp.value), new Data_Tuple.Tuple("dddd", DayOfWeekName.value), new Data_Tuple.Tuple("ddd", DayOfWeekNameShort.value), new Data_Tuple.Tuple("HH", Hours24.value), new Data_Tuple.Tuple("hh", Hours12.value), new Data_Tuple.Tuple("a", Meridiem.value), new Data_Tuple.Tuple("mm", MinutesTwoDigits.value), new Data_Tuple.Tuple("m", Minutes.value), new Data_Tuple.Tuple("ss", SecondsTwoDigits.value), new Data_Tuple.Tuple("s", Seconds.value), new Data_Tuple.Tuple("SSS", Milliseconds.value), new Data_Tuple.Tuple("SS", MillisecondsTwoDigits.value), new Data_Tuple.Tuple("S", MillisecondsShort.value)]))(Data_Functor.map(Text_Parsing_Parser.functorParserT(Data_Identity.functorIdentity))(Placeholder.create)(placeholderContent));

  var formatYearTwoDigits = function formatYearTwoDigits(i) {
    var dateString = Data_Show.show(Data_Show.showInt)(Data_Ord.abs(Data_Ord.ordInt)(Data_Ring.ringInt)(i));
    var dateLength = Data_String_CodePoints.length(dateString);

    if (dateLength === 1) {
      return "0" + dateString;
    }

    ;

    if (dateLength === 2) {
      return dateString;
    }

    ;
    return Data_String_CodePoints.drop(dateLength - 2 | 0)(dateString);
  };

  var formatParser = Data_List.some(Text_Parsing_Parser.alternativeParserT(Data_Identity.monadIdentity))(Text_Parsing_Parser.lazyParserT)(formatterCommandParser);
  var parseFormatString = Data_Formatter_Parser_Utils.runP(Text_Parsing_Parser_String.stringLikeString)(formatParser);

  var fix12 = function fix12(h) {
    var $401 = h === 0;

    if ($401) {
      return 12;
    }

    ;
    return h;
  };

  var formatCommand = function formatCommand(v) {
    return function (v1) {
      if (v1 instanceof YearFull) {
        return padQuadrupleDigit(Data_Enum.fromEnum(Data_Date_Component.boundedEnumYear)(Data_Date.year(v.value0)));
      }

      ;

      if (v1 instanceof YearTwoDigits) {
        return formatYearTwoDigits(Data_Enum.fromEnum(Data_Date_Component.boundedEnumYear)(Data_Date.year(v.value0)));
      }

      ;

      if (v1 instanceof YearAbsolute) {
        return Data_Show.show(Data_Show.showInt)(Data_Enum.fromEnum(Data_Date_Component.boundedEnumYear)(Data_Date.year(v.value0)));
      }

      ;

      if (v1 instanceof MonthFull) {
        return Data_Show.show(Data_Date_Component.showMonth)(Data_Date.month(v.value0));
      }

      ;

      if (v1 instanceof MonthShort) {
        return printShortMonth(Data_Date.month(v.value0));
      }

      ;

      if (v1 instanceof MonthTwoDigits) {
        return padSingleDigit(Data_Enum.fromEnum(Data_Date_Component.boundedEnumMonth)(Data_Date.month(v.value0)));
      }

      ;

      if (v1 instanceof DayOfMonthTwoDigits) {
        return padSingleDigit(Data_Enum.fromEnum(Data_Date_Component.boundedEnumDay)(Data_Date.day(v.value0)));
      }

      ;

      if (v1 instanceof DayOfMonth) {
        return Data_Show.show(Data_Show.showInt)(Data_Enum.fromEnum(Data_Date_Component.boundedEnumDay)(Data_Date.day(v.value0)));
      }

      ;

      if (v1 instanceof UnixTimestamp) {
        return Data_Show.show(Data_Show.showInt)(Data_Int.floor(function (v2) {
          return v2 / 1000.0;
        }(Data_Newtype.unwrap(Data_Time_Duration.newtypeMilliseconds)(Data_DateTime_Instant.unInstant(Data_DateTime_Instant.fromDateTime(v))))));
      }

      ;

      if (v1 instanceof DayOfWeek) {
        return Data_Show.show(Data_Show.showInt)(Data_Enum.fromEnum(Data_Date_Component.boundedEnumWeekday)(Data_Date.weekday(v.value0)));
      }

      ;

      if (v1 instanceof DayOfWeekName) {
        return Data_Show.show(Data_Date_Component.showWeekday)(Data_Date.weekday(v.value0));
      }

      ;

      if (v1 instanceof DayOfWeekNameShort) {
        return Data_String_CodePoints.take(3)(Data_Show.show(Data_Date_Component.showWeekday)(Data_Date.weekday(v.value0)));
      }

      ;

      if (v1 instanceof Hours24) {
        return padSingleDigit(Data_Enum.fromEnum(Data_Time_Component.boundedEnumHour)(Data_Time.hour(v.value1)));
      }

      ;

      if (v1 instanceof Hours12) {
        return padSingleDigit(fix12(Data_EuclideanRing.mod(Data_EuclideanRing.euclideanRingInt)(Data_Enum.fromEnum(Data_Time_Component.boundedEnumHour)(Data_Time.hour(v.value1)))(12)));
      }

      ;

      if (v1 instanceof Meridiem) {
        var $404 = Data_Enum.fromEnum(Data_Time_Component.boundedEnumHour)(Data_Time.hour(v.value1)) >= 12;

        if ($404) {
          return "PM";
        }

        ;
        return "AM";
      }

      ;

      if (v1 instanceof Minutes) {
        return Data_Show.show(Data_Show.showInt)(Data_Enum.fromEnum(Data_Time_Component.boundedEnumMinute)(Data_Time.minute(v.value1)));
      }

      ;

      if (v1 instanceof MinutesTwoDigits) {
        return padSingleDigit(Data_Enum.fromEnum(Data_Time_Component.boundedEnumMinute)(Data_Time.minute(v.value1)));
      }

      ;

      if (v1 instanceof Seconds) {
        return Data_Show.show(Data_Show.showInt)(Data_Enum.fromEnum(Data_Time_Component.boundedEnumSecond)(Data_Time.second(v.value1)));
      }

      ;

      if (v1 instanceof SecondsTwoDigits) {
        return padSingleDigit(Data_Enum.fromEnum(Data_Time_Component.boundedEnumSecond)(Data_Time.second(v.value1)));
      }

      ;

      if (v1 instanceof Milliseconds) {
        return padDoubleDigit(Data_Enum.fromEnum(Data_Time_Component.boundedEnumMillisecond)(Data_Time.millisecond(v.value1)));
      }

      ;

      if (v1 instanceof MillisecondsShort) {
        return Data_Show.show(Data_Show.showInt)(function (v2) {
          return Data_EuclideanRing.div(Data_EuclideanRing.euclideanRingInt)(v2)(100);
        }(Data_Enum.fromEnum(Data_Time_Component.boundedEnumMillisecond)(Data_Time.millisecond(v.value1))));
      }

      ;

      if (v1 instanceof MillisecondsTwoDigits) {
        return padSingleDigit(function (v2) {
          return Data_EuclideanRing.div(Data_EuclideanRing.euclideanRingInt)(v2)(10);
        }(Data_Enum.fromEnum(Data_Time_Component.boundedEnumMillisecond)(Data_Time.millisecond(v.value1))));
      }

      ;

      if (v1 instanceof Placeholder) {
        return v1.value0;
      }

      ;
      throw new Error("Failed pattern match at Data.Formatter.DateTime (line 167, column 38 - line 190, column 20): " + [v1.constructor.name]);
    };
  };

  var format = function format(f) {
    return function (d) {
      return Data_Foldable.foldMap(Data_List_Types.foldableList)(Data_Monoid.monoidString)(formatCommand(d))(f);
    };
  };

  var formatDateTime = function formatDateTime(pattern) {
    return function (datetime) {
      return Data_Functor.mapFlipped(Data_Either.functorEither)(parseFormatString(pattern))(function (v) {
        return format(v)(datetime);
      });
    };
  };

  exports["YearFull"] = YearFull;
  exports["YearTwoDigits"] = YearTwoDigits;
  exports["YearAbsolute"] = YearAbsolute;
  exports["MonthFull"] = MonthFull;
  exports["MonthShort"] = MonthShort;
  exports["MonthTwoDigits"] = MonthTwoDigits;
  exports["DayOfMonthTwoDigits"] = DayOfMonthTwoDigits;
  exports["DayOfMonth"] = DayOfMonth;
  exports["UnixTimestamp"] = UnixTimestamp;
  exports["DayOfWeek"] = DayOfWeek;
  exports["DayOfWeekName"] = DayOfWeekName;
  exports["DayOfWeekNameShort"] = DayOfWeekNameShort;
  exports["Hours24"] = Hours24;
  exports["Hours12"] = Hours12;
  exports["Meridiem"] = Meridiem;
  exports["Minutes"] = Minutes;
  exports["MinutesTwoDigits"] = MinutesTwoDigits;
  exports["Seconds"] = Seconds;
  exports["SecondsTwoDigits"] = SecondsTwoDigits;
  exports["Milliseconds"] = Milliseconds;
  exports["MillisecondsShort"] = MillisecondsShort;
  exports["MillisecondsTwoDigits"] = MillisecondsTwoDigits;
  exports["Placeholder"] = Placeholder;
  exports["parseFormatString"] = parseFormatString;
  exports["format"] = format;
  exports["formatDateTime"] = formatDateTime;
})(PS["Data.Formatter.DateTime"] = PS["Data.Formatter.DateTime"] || {});

(function (exports) {
  // Generated by purs version 0.12.3
  "use strict";

  var Data_Bounded = PS["Data.Bounded"];
  var Data_Generic_Rep = PS["Data.Generic.Rep"];

  var GenericBottom = function GenericBottom(genericBottom$prime) {
    this["genericBottom'"] = genericBottom$prime;
  };

  var GenericTop = function GenericTop(genericTop$prime) {
    this["genericTop'"] = genericTop$prime;
  };

  var genericTopNoArguments = new GenericTop(Data_Generic_Rep.NoArguments.value);

  var genericTop$prime = function genericTop$prime(dict) {
    return dict["genericTop'"];
  };

  var genericTopConstructor = function genericTopConstructor(dictGenericTop) {
    return new GenericTop(genericTop$prime(dictGenericTop));
  };

  var genericTopSum = function genericTopSum(dictGenericTop) {
    return new GenericTop(new Data_Generic_Rep.Inr(genericTop$prime(dictGenericTop)));
  };

  var genericTop = function genericTop(dictGeneric) {
    return function (dictGenericTop) {
      return Data_Generic_Rep.to(dictGeneric)(genericTop$prime(dictGenericTop));
    };
  };

  var genericBottomNoArguments = new GenericBottom(Data_Generic_Rep.NoArguments.value);

  var genericBottom$prime = function genericBottom$prime(dict) {
    return dict["genericBottom'"];
  };

  var genericBottomConstructor = function genericBottomConstructor(dictGenericBottom) {
    return new GenericBottom(genericBottom$prime(dictGenericBottom));
  };

  var genericBottomSum = function genericBottomSum(dictGenericBottom) {
    return new GenericBottom(new Data_Generic_Rep.Inl(genericBottom$prime(dictGenericBottom)));
  };

  var genericBottom = function genericBottom(dictGeneric) {
    return function (dictGenericBottom) {
      return Data_Generic_Rep.to(dictGeneric)(genericBottom$prime(dictGenericBottom));
    };
  };

  exports["GenericBottom"] = GenericBottom;
  exports["genericBottom"] = genericBottom;
  exports["GenericTop"] = GenericTop;
  exports["genericTop"] = genericTop;
  exports["genericBottomNoArguments"] = genericBottomNoArguments;
  exports["genericBottomSum"] = genericBottomSum;
  exports["genericBottomConstructor"] = genericBottomConstructor;
  exports["genericTopNoArguments"] = genericTopNoArguments;
  exports["genericTopSum"] = genericTopSum;
  exports["genericTopConstructor"] = genericTopConstructor;
})(PS["Data.Generic.Rep.Bounded"] = PS["Data.Generic.Rep.Bounded"] || {});

(function (exports) {
  // Generated by purs version 0.12.3
  "use strict";

  var Data_Eq = PS["Data.Eq"];
  var Data_Generic_Rep = PS["Data.Generic.Rep"];
  var Data_HeytingAlgebra = PS["Data.HeytingAlgebra"];
  var Prelude = PS["Prelude"];

  var GenericEq = function GenericEq(genericEq$prime) {
    this["genericEq'"] = genericEq$prime;
  };

  var genericEqNoArguments = new GenericEq(function (v) {
    return function (v1) {
      return true;
    };
  });

  var genericEq$prime = function genericEq$prime(dict) {
    return dict["genericEq'"];
  };

  var genericEqConstructor = function genericEqConstructor(dictGenericEq) {
    return new GenericEq(function (v) {
      return function (v1) {
        return genericEq$prime(dictGenericEq)(v)(v1);
      };
    });
  };

  var genericEqSum = function genericEqSum(dictGenericEq) {
    return function (dictGenericEq1) {
      return new GenericEq(function (v) {
        return function (v1) {
          if (v instanceof Data_Generic_Rep.Inl && v1 instanceof Data_Generic_Rep.Inl) {
            return genericEq$prime(dictGenericEq)(v.value0)(v1.value0);
          }

          ;

          if (v instanceof Data_Generic_Rep.Inr && v1 instanceof Data_Generic_Rep.Inr) {
            return genericEq$prime(dictGenericEq1)(v.value0)(v1.value0);
          }

          ;
          return false;
        };
      });
    };
  };

  var genericEq = function genericEq(dictGeneric) {
    return function (dictGenericEq) {
      return function (x) {
        return function (y) {
          return genericEq$prime(dictGenericEq)(Data_Generic_Rep.from(dictGeneric)(x))(Data_Generic_Rep.from(dictGeneric)(y));
        };
      };
    };
  };

  exports["GenericEq"] = GenericEq;
  exports["genericEq"] = genericEq;
  exports["genericEqNoArguments"] = genericEqNoArguments;
  exports["genericEqSum"] = genericEqSum;
  exports["genericEqConstructor"] = genericEqConstructor;
})(PS["Data.Generic.Rep.Eq"] = PS["Data.Generic.Rep.Eq"] || {});

(function (exports) {
  // Generated by purs version 0.12.3
  "use strict";

  var Control_Semigroupoid = PS["Control.Semigroupoid"];
  var Effect_Exception = PS["Effect.Exception"];
  var Effect_Unsafe = PS["Effect.Unsafe"];

  var unsafeThrowException = function unsafeThrowException($0) {
    return Effect_Unsafe.unsafePerformEffect(Effect_Exception.throwException($0));
  };

  var unsafeThrow = function unsafeThrow($1) {
    return unsafeThrowException(Effect_Exception.error($1));
  };

  exports["unsafeThrowException"] = unsafeThrowException;
  exports["unsafeThrow"] = unsafeThrow;
})(PS["Effect.Exception.Unsafe"] = PS["Effect.Exception.Unsafe"] || {});

(function (exports) {
  // Generated by purs version 0.12.3
  "use strict";

  var Control_Applicative = PS["Control.Applicative"];
  var Control_Bind = PS["Control.Bind"];
  var Control_Semigroupoid = PS["Control.Semigroupoid"];
  var Data_Boolean = PS["Data.Boolean"];
  var Data_Digit = PS["Data.Digit"];
  var Data_Eq = PS["Data.Eq"];
  var Data_EuclideanRing = PS["Data.EuclideanRing"];
  var Data_Foldable = PS["Data.Foldable"];
  var Data_Function = PS["Data.Function"];
  var Data_Functor = PS["Data.Functor"];
  var Data_Generic_Rep = PS["Data.Generic.Rep"];
  var Data_HeytingAlgebra = PS["Data.HeytingAlgebra"];
  var Data_Int = PS["Data.Int"];
  var Data_List = PS["Data.List"];
  var Data_List_Types = PS["Data.List.Types"];
  var Data_Maybe = PS["Data.Maybe"];
  var Data_Monoid = PS["Data.Monoid"];
  var Data_Ord = PS["Data.Ord"];
  var Data_Ordering = PS["Data.Ordering"];
  var Data_Ring = PS["Data.Ring"];
  var Data_Semigroup = PS["Data.Semigroup"];
  var Data_Semiring = PS["Data.Semiring"];
  var Data_Show = PS["Data.Show"];
  var Data_String = PS["Data.String"];
  var Data_String_CodeUnits = PS["Data.String.CodeUnits"];
  var Data_String_Pattern = PS["Data.String.Pattern"];
  var Data_Traversable = PS["Data.Traversable"];
  var Data_Tuple = PS["Data.Tuple"];
  var Data_Unfoldable = PS["Data.Unfoldable"];
  var Effect_Exception_Unsafe = PS["Effect.Exception.Unsafe"];
  var Global = PS["Global"];
  var Partial_Unsafe = PS["Partial.Unsafe"];
  var Prelude = PS["Prelude"];
  var Test_QuickCheck_Arbitrary = PS["Test.QuickCheck.Arbitrary"];
  var Test_QuickCheck_Gen = PS["Test.QuickCheck.Gen"];

  var Plus = function () {
    function Plus() {}

    ;
    Plus.value = new Plus();
    return Plus;
  }();

  var Minus = function () {
    function Minus() {}

    ;
    Minus.value = new Minus();
    return Minus;
  }();

  var Float = function () {
    function Float() {}

    ;
    Float.value = new Float();
    return Float;
  }();

  var Integral = function () {
    function Integral() {}

    ;
    Integral.value = new Integral();
    return Integral;
  }();

  var Scientific = function () {
    function Scientific() {}

    ;
    Scientific.value = new Scientific();
    return Scientific;
  }();

  var zeroHugeNum = {
    digits: new Data_List_Types.Cons(Data_Digit["_zero"], new Data_List_Types.Cons(Data_Digit["_zero"], Data_List_Types.Nil.value)),
    decimal: 1,
    sign: Plus.value
  };
  var unsafeRemoveFrontZeroes = Data_List.dropWhile(function (v) {
    return Data_Eq.eq(Data_Digit.eqDigit)(v)(Data_Digit["_zero"]);
  });

  var trivialFraction = function trivialFraction(v) {
    var decimals = Data_List.reverse(Data_List.drop(v.decimal)(v.digits));
    var meaty = Data_List.dropWhile(function (v1) {
      return Data_Eq.eq(Data_Digit.eqDigit)(v1)(Data_Digit["_zero"]);
    })(decimals);
    return Data_List["null"](meaty);
  };

  var toString = function toString(v) {
    var sign = function () {
      if (v.sign instanceof Plus) {
        return Data_Monoid.mempty(Data_List_Types.monoidList);
      }

      ;

      if (v.sign instanceof Minus) {
        return Control_Applicative.pure(Data_List_Types.applicativeList)("-");
      }

      ;
      throw new Error("Failed pattern match at Data.HugeNum (line 182, column 14 - line 184, column 49): " + [v.sign.constructor.name]);
    }();

    var charray = Data_Functor.map(Data_List_Types.functorList)(Data_Digit.toChar)(v.digits);
    var numray = Data_Maybe.fromJust()(Data_List.insertAt(v.decimal)(".")(charray));
    return Data_Foldable.foldMap(Data_List_Types.foldableList)(Data_Monoid.monoidString)(Data_String_CodeUnits.singleton)(Data_Semigroup.append(Data_List_Types.semigroupList)(sign)(numray));
  };

  var timesSign = function timesSign(v) {
    return function (v1) {
      if (v instanceof Plus && v1 instanceof Plus) {
        return Plus.value;
      }

      ;

      if (v instanceof Minus && v1 instanceof Minus) {
        return Plus.value;
      }

      ;
      return Minus.value;
    };
  };

  var takeMeatyParts = function takeMeatyParts(arr) {
    return Data_List.reverse(Data_List.dropWhile(function (v) {
      return Data_Eq.eq(Data_Digit.eqDigit)(v)(Data_Digit["_zero"]);
    })(Data_List.reverse(arr)));
  };

  var timesPowOfTen = function timesPowOfTen(n) {
    return function (v) {
      var newDecimal = v.decimal + n | 0;

      var newZeroesLength = function () {
        var $99 = newDecimal >= Data_List.length(takeMeatyParts(v.digits));

        if ($99) {
          return newDecimal - Data_List.length(takeMeatyParts(v.digits)) | 0;
        }

        ;
        return 0;
      }();

      var newZeroes = Data_Unfoldable.replicate(Data_List_Types.unfoldableList)(newZeroesLength)(Data_Digit["_zero"]);
      var z = {
        digits: Data_Semigroup.append(Data_List_Types.semigroupList)(v.digits)(newZeroes),
        sign: v.sign,
        decimal: newDecimal
      };
      return z;
    };
  };

  var smallEnough = function smallEnough(v) {
    if (v.decimal === 1 && Data_List.length(v.digits) === 2) {
      return true;
    }

    ;
    return false;
  };

  var rec = function rec(v) {
    return v;
  };

  var parseScientific = function parseScientific(n) {
    var split = Data_List.span(function (v) {
      return v !== "e";
    })(Data_List.fromFoldable(Data_Foldable.foldableArray)(Data_String_CodeUnits.toCharArray(Data_Show.show(Data_Show.showNumber)(n))));
    var signSplit = Data_Maybe.fromJust()(Data_List.uncons(Data_List.drop(1)(split.rest)));

    var sign = function () {
      var $104 = n < 0;

      if ($104) {
        return Minus.value;
      }

      ;
      return Plus.value;
    }();

    var exponent = Data_Int.round(Global.readFloat(Data_Foldable.foldMap(Data_List_Types.foldableList)(Data_Monoid.monoidString)(Data_String_CodeUnits.singleton)(signSplit.tail)));

    var expSign = function () {
      if (signSplit.head === "+") {
        return Plus.value;
      }

      ;
      return Minus.value;
    }();

    var base = Data_List.filter(function (v) {
      return v !== ".";
    })(split.init);
    var z = {
      exponent: exponent,
      expSign: expSign,
      base: base,
      sign: sign
    };
    return z;
  };

  var parsePlusPlus = function parsePlusPlus(exp) {
    return function (base) {
      var zeroCardinality = (exp - Data_List.length(base) | 0) + 2 | 0;
      var zeroes = Data_Unfoldable.replicate(Data_List_Types.unfoldableList)(zeroCardinality)(Data_Digit["_zero"]);
      var digits = Data_Semigroup.append(Data_List_Types.semigroupList)(Data_List.mapMaybe(Data_Digit.fromChar)(base))(zeroes);
      var decimal = 1 + exp | 0;
      var r = {
        digits: digits,
        decimal: decimal,
        sign: Plus.value
      };
      return r;
    };
  };

  var parsePlusMinus = function parsePlusMinus(exp) {
    return function (base) {
      var r = parsePlusPlus(exp)(base);
      return {
        digits: Data_Semigroup.append(Data_List_Types.semigroupList)(r.digits)(Control_Applicative.pure(Data_List_Types.applicativeList)(Data_Digit["_zero"])),
        decimal: r.decimal,
        sign: Minus.value
      };
    };
  };

  var parseNumber = function parseNumber(n) {
    if (Data_String_CodeUnits.contains("e")(Data_Show.show(Data_Show.showNumber)(n))) {
      return Scientific.value;
    }

    ;

    if (Data_String_CodeUnits.contains(".")(Data_Show.show(Data_Show.showNumber)(n))) {
      return Float.value;
    }

    ;

    if (Data_Boolean.otherwise) {
      return Integral.value;
    }

    ;
    throw new Error("Failed pattern match at Data.HugeNum (line 212, column 1 - line 212, column 37): " + [n.constructor.name]);
  };

  var parseMinusPlus = function parseMinusPlus(exp) {
    return function (base) {
      var zeroes = Data_Unfoldable.replicate(Data_List_Types.unfoldableList)(exp)(Data_Digit["_zero"]);
      var digits = Data_Semigroup.append(Data_List_Types.semigroupList)(zeroes)(Data_List.mapMaybe(Data_Digit.fromChar)(base));
      var r = {
        digits: digits,
        decimal: 1,
        sign: Plus.value
      };
      return r;
    };
  };

  var parseMinusMinus = function parseMinusMinus(exp) {
    return function (base) {
      var r = parseMinusPlus(exp)(base);
      return {
        digits: r.digits,
        decimal: r.decimal,
        sign: Minus.value
      };
    };
  };

  var scientificToHugeNum = function scientificToHugeNum(n) {
    var parsed = parseScientific(n);

    var r = function () {
      if (parsed.expSign instanceof Plus) {
        if (parsed.sign instanceof Plus) {
          return parsePlusPlus(parsed.exponent)(parsed.base);
        }

        ;
        return parsePlusMinus(parsed.exponent)(parsed.base);
      }

      ;

      if (parsed.sign instanceof Plus) {
        return parseMinusPlus(parsed.exponent)(parsed.base);
      }

      ;
      return parseMinusMinus(parsed.exponent)(parsed.base);
    }();

    return r;
  };

  var oneHugeNum = {
    digits: new Data_List_Types.Cons(Data_Digit["_one"], new Data_List_Types.Cons(Data_Digit["_zero"], Data_List_Types.Nil.value)),
    decimal: 1,
    sign: Plus.value
  };

  var neg = function neg(v) {
    if (v.sign instanceof Minus) {
      return {
        digits: v.digits,
        decimal: v.decimal,
        sign: Plus.value
      };
    }

    ;
    return {
      digits: v.digits,
      decimal: v.decimal,
      sign: Minus.value
    };
  };

  var meatyDecimals = function meatyDecimals(v) {
    var decimals = Data_List.reverse(Data_List.drop(v.decimal)(v.digits));
    var meaty = Data_List.dropWhile(function (v1) {
      return Data_Eq.eq(Data_Digit.eqDigit)(v1)(Data_Digit["_zero"]);
    })(decimals);
    return Data_List.length(meaty);
  };

  var makeHugeInteger$prime = function makeHugeInteger$prime(v) {
    var digits = Data_Semigroup.append(Data_List_Types.semigroupList)(unsafeRemoveFrontZeroes(v.digits))(Control_Applicative.pure(Data_List_Types.applicativeList)(Data_Digit["_zero"]));
    var decimal = Data_List.length(digits) - 1 | 0;
    var z = {
      digits: digits,
      decimal: decimal,
      sign: v.sign
    };
    return z;
  };

  var lessPrecise = function lessPrecise(v) {
    return function (v1) {
      return Data_List.length(Data_List.drop(v.decimal)(v.digits)) < Data_List.length(Data_List.drop(v1.decimal)(v1.digits));
    };
  };

  var isZero = function isZero(v) {
    return Data_Foldable.all(Data_List_Types.foldableList)(Data_HeytingAlgebra.heytingAlgebraBoolean)(function (v1) {
      return Data_Eq.eq(Data_Digit.eqDigit)(v1)(Data_Digit["_zero"]);
    })(v.digits);
  };

  var isPositive = function isPositive(v) {
    if (v.sign instanceof Plus) {
      return true;
    }

    ;
    return false;
  };

  var isNegative = function isNegative(v) {
    if (v.sign instanceof Minus) {
      return true;
    }

    ;
    return false;
  };

  var isHugeInteger = function isHugeInteger(v) {
    return Data_Foldable.all(Data_List_Types.foldableList)(Data_HeytingAlgebra.heytingAlgebraBoolean)(function (v1) {
      return Data_Eq.eq(Data_Digit.eqDigit)(v1)(Data_Digit["_zero"]);
    })(Data_List.drop(v.decimal)(v.digits));
  };

  var makeHugeInteger = function makeHugeInteger(r) {
    var $124 = isHugeInteger(r);

    if ($124) {
      return r;
    }

    ;
    return makeHugeInteger$prime(r);
  };

  var integralToHugeNum = function integralToHugeNum(n) {
    var sign = function () {
      var $125 = n < 0;

      if ($125) {
        return Minus.value;
      }

      ;
      return Plus.value;
    }();

    var integral = Data_List.fromFoldable(Data_Foldable.foldableArray)(Data_String_CodeUnits.toCharArray(Data_Show.show(Data_Show.showNumber)(n)));
    var fractional = Control_Applicative.pure(Data_List_Types.applicativeList)(Data_Digit["_zero"]);

    var decimal = function () {
      if (sign instanceof Minus) {
        return Data_List.length(integral) - 1 | 0;
      }

      ;
      return Data_List.length(integral);
    }();

    return {
      digits: Data_Semigroup.append(Data_List_Types.semigroupList)(Data_List.mapMaybe(Data_Digit.fromChar)(integral))(fractional),
      decimal: decimal,
      sign: sign
    };
  };

  var integerPart = function integerPart(v) {
    return {
      digits: Data_Semigroup.append(Data_List_Types.semigroupList)(Data_List.take(v.decimal)(v.digits))(Control_Applicative.pure(Data_List_Types.applicativeList)(Data_Digit["_zero"])),
      decimal: v.decimal,
      sign: v.sign
    };
  };

  var fromString = function fromString(s) {
    var charlist = Data_List.fromFoldable(Data_Foldable.foldableArray)(Data_String_CodeUnits.toCharArray(s));
    return Control_Bind.bind(Data_Maybe.bindMaybe)(Data_List.head(charlist))(function (v) {
      var signDigits = function () {
        if (v === "-") {
          return {
            sign: Minus.value,
            digits: Data_List.drop(1)(charlist)
          };
        }

        ;
        return {
          sign: Plus.value,
          digits: charlist
        };
      }();

      return Control_Bind.bind(Data_Maybe.bindMaybe)(Data_List.elemIndex(Data_Eq.eqChar)(".")(signDigits.digits))(function (v1) {
        return Control_Bind.bind(Data_Maybe.bindMaybe)(Data_List.deleteAt(v1)(signDigits.digits))(function (v2) {
          return Control_Bind.bind(Data_Maybe.bindMaybe)(Data_Traversable.sequence(Data_List_Types.traversableList)(Data_Maybe.applicativeMaybe)(Data_Functor.map(Data_List_Types.functorList)(Data_Digit.fromChar)(v2)))(function (v3) {
            return Control_Applicative.pure(Data_Maybe.applicativeMaybe)({
              sign: signDigits.sign,
              decimal: v1,
              digits: v3
            });
          });
        });
      });
    });
  };

  var floatToHugeNum = function floatToHugeNum(n) {
    var sign = function () {
      var $140 = n < 0;

      if ($140) {
        return Minus.value;
      }

      ;
      return Plus.value;
    }();

    var pos = n >= 0;

    var split = function () {
      if (pos) {
        return Data_List.span(function (v) {
          return v !== ".";
        })(Data_List.fromFoldable(Data_Foldable.foldableArray)(Data_String_CodeUnits.toCharArray(Data_Show.show(Data_Show.showNumber)(n))));
      }

      ;
      return Data_List.span(function (v) {
        return v !== ".";
      })(Data_List.drop(1)(Data_List.fromFoldable(Data_Foldable.foldableArray)(Data_String_CodeUnits.toCharArray(Data_Show.show(Data_Show.showNumber)(n)))));
    }();

    var small = Data_List.drop(1)(split.rest);
    var decimal = Data_List.length(split.init);
    var digits = Data_List.mapMaybe(Data_Digit.fromChar)(Data_Semigroup.append(Data_List_Types.semigroupList)(split.init)(small));
    var r = {
      digits: digits,
      decimal: decimal,
      sign: sign
    };
    return r;
  };

  var fromNumber = function fromNumber(n) {
    var v = parseNumber(n);

    if (v instanceof Float) {
      return floatToHugeNum(n);
    }

    ;

    if (v instanceof Scientific) {
      return scientificToHugeNum(n);
    }

    ;

    if (v instanceof Integral) {
      return integralToHugeNum(n);
    }

    ;
    throw new Error("Failed pattern match at Data.HugeNum (line 291, column 16 - line 294, column 52): " + [v.constructor.name]);
  };

  var eqSign = new Data_Eq.Eq(function (v) {
    return function (v1) {
      if (v instanceof Plus && v1 instanceof Plus) {
        return true;
      }

      ;

      if (v instanceof Minus && v1 instanceof Minus) {
        return true;
      }

      ;
      return false;
    };
  });
  var ordSign = new Data_Ord.Ord(function () {
    return eqSign;
  }, function (v) {
    return function (v1) {
      if (v instanceof Plus && v1 instanceof Minus) {
        return Data_Ordering.GT.value;
      }

      ;

      if (v instanceof Minus && v1 instanceof Plus) {
        return Data_Ordering.LT.value;
      }

      ;
      return Data_Ordering.EQ.value;
    };
  });

  var strictlyEqual = function strictlyEqual(v) {
    return function (v1) {
      return v.decimal === v1.decimal && Data_Eq.eq(Data_List_Types.eqList(Data_Digit.eqDigit))(v.digits)(v1.digits) && Data_Eq.eq(eqSign)(v.sign)(v1.sign);
    };
  };

  var dropZeroes = function () {
    var dropIntegralZeroes = function dropIntegralZeroes(v) {
      var integralPart = Data_List.take(v.decimal)(v.digits);
      var zeroes = Data_List.takeWhile(function (v1) {
        return Data_Eq.eq(Data_Digit.eqDigit)(v1)(Data_Digit["_zero"]);
      })(integralPart);

      var digits = function () {
        var $150 = Data_List.length(integralPart) === Data_List.length(zeroes);

        if ($150) {
          return Control_Applicative.pure(Data_List_Types.applicativeList)(Data_Digit["_zero"]);
        }

        ;
        return Data_List.drop(Data_List.length(zeroes))(integralPart);
      }();

      var decimal = Data_List.length(digits);
      var z = {
        digits: Data_Semigroup.append(Data_List_Types.semigroupList)(digits)(Data_List.drop(v.decimal)(v.digits)),
        decimal: decimal,
        sign: v.sign
      };
      return z;
    };

    var dropFractionalZeroes = function dropFractionalZeroes(v) {
      var fractionalDigits = Data_List.reverse(Data_List.drop(v.decimal)(v.digits));
      var meatyFraction = Data_List.dropWhile(function (v1) {
        return Data_Eq.eq(Data_Digit.eqDigit)(v1)(Data_Digit["_zero"]);
      })(fractionalDigits);
      var digits = Data_List.reverse(meatyFraction);
      var z = {
        digits: Data_Semigroup.append(Data_List_Types.semigroupList)(Data_List.take(v.decimal)(v.digits))(function () {
          var $152 = Data_List["null"](digits);

          if ($152) {
            return Control_Applicative.pure(Data_List_Types.applicativeList)(Data_Digit["_zero"]);
          }

          ;
          return digits;
        }()),
        decimal: v.decimal,
        sign: v.sign
      };
      return z;
    };

    return function ($217) {
      return dropIntegralZeroes(dropFractionalZeroes($217));
    };
  }();

  var eqHugeNum = new Data_Eq.Eq(function (x) {
    return function (y) {
      if (isZero(x) && isZero(y)) {
        return true;
      }

      ;

      if (Data_Boolean.otherwise) {
        return strictlyEqual(dropZeroes(x))(dropZeroes(y));
      }

      ;
      throw new Error("Failed pattern match at Data.HugeNum (line 83, column 1 - line 83, column 33): " + [x.constructor.name, y.constructor.name]);
    };
  });

  var digitwiseSubtract = function digitwiseSubtract(v) {
    return function (v1) {
      var tint = Data_Digit.toInt(v1.value0) - Data_Digit.toInt(v.value1) | 0;
      var bint = Data_Digit.toInt(v1.value1);
      var diff$prime = tint - bint | 0;
      var diff = Data_Maybe.fromJust()(Data_Digit.fromInt(function () {
        var $157 = diff$prime < 0;

        if ($157) {
          return diff$prime + 10 | 0;
        }

        ;
        return diff$prime;
      }()));

      var spill = function () {
        var $158 = diff$prime < 0;

        if ($158) {
          return Data_Digit["_one"];
        }

        ;
        return Data_Digit["_zero"];
      }();

      return new Data_Tuple.Tuple(new Data_List_Types.Cons(diff, v.value0), spill);
    };
  };

  var digitwiseAdd = function digitwiseAdd(v) {
    return function (v1) {
      var tint = Data_Digit.toInt(v1.value0) + Data_Digit.toInt(v.value1) | 0;
      var bint = Data_Digit.toInt(v1.value1);
      var summ$prime = tint + bint | 0;

      var spill = function () {
        var $165 = summ$prime > 9;

        if ($165) {
          return Data_Digit["_one"];
        }

        ;
        return Data_Digit["_zero"];
      }();

      var summ = Data_Maybe.fromJust()(Data_Digit.fromInt(function () {
        var $166 = summ$prime > 9;

        if ($166) {
          return summ$prime - 10 | 0;
        }

        ;
        return summ$prime;
      }()));
      return new Data_Tuple.Tuple(new Data_List_Types.Cons(summ, v.value0), spill);
    };
  };

  var equivalize = function () {
    var integralize = function integralize(v) {
      if (Data_List.length(Data_List.take(v.fst.decimal)(v.fst.digits)) === Data_List.length(Data_List.take(v.snd.decimal)(v.snd.digits))) {
        return {
          fst: v.fst,
          snd: v.snd
        };
      }

      ;

      if (Data_Boolean.otherwise) {
        var lesser = rec(Data_Ord.min(ordHugeNum)(v.fst)(v.snd));
        var greater = rec(Data_Ord.max(ordHugeNum)(v.fst)(v.snd));
        var zeroesLength = Data_List.length(Data_List.take(greater.decimal)(greater.digits)) - Data_List.length(Data_List.take(lesser.decimal)(lesser.digits)) | 0;
        var zeroes = Data_Unfoldable.replicate(Data_List_Types.unfoldableList)(zeroesLength)(Data_Digit["_zero"]);
        var lesser$prime = {
          digits: Data_Semigroup.append(Data_List_Types.semigroupList)(zeroes)(lesser.digits),
          decimal: greater.decimal,
          sign: lesser.sign
        };
        var z = {
          fst: lesser$prime,
          snd: greater
        };
        return z;
      }

      ;
      throw new Error("Failed pattern match at Data.HugeNum (line 158, column 3 - line 166, column 57): " + [v.constructor.name]);
    };

    var fractionalize = function fractionalize(v) {
      if (Data_List.length(Data_List.drop(v.fst.decimal)(v.fst.digits)) === Data_List.length(Data_List.drop(v.snd.decimal)(v.snd.digits))) {
        return {
          fst: v.fst,
          snd: v.snd
        };
      }

      ;

      if (Data_Boolean.otherwise) {
        var test = lessPrecise(v.fst)(v.snd);

        var lesser = function () {
          if (test) {
            return v.fst;
          }

          ;
          return v.snd;
        }();

        var greater = function () {
          if (test) {
            return v.snd;
          }

          ;
          return v.fst;
        }();

        var lesserDecimal = Data_List.length(Data_List.drop(greater.decimal)(greater.digits)) - Data_List.length(Data_List.drop(lesser.decimal)(lesser.digits)) | 0;
        var zeroes = Data_Unfoldable.replicate(Data_List_Types.unfoldableList)(lesserDecimal)(Data_Digit["_zero"]);
        var lesser$prime = {
          digits: Data_Semigroup.append(Data_List_Types.semigroupList)(lesser.digits)(zeroes),
          decimal: lesser.decimal,
          sign: lesser.sign
        };
        var z = {
          fst: lesser$prime,
          snd: greater
        };
        return z;
      }

      ;
      throw new Error("Failed pattern match at Data.HugeNum (line 148, column 3 - line 157, column 57): " + [v.constructor.name]);
    };

    return function ($218) {
      return integralize(fractionalize($218));
    };
  }();

  var compareHugeNum = function compareHugeNum(v) {
    return function (v1) {
      if (Data_Ord.lessThan(ordSign)(v.sign)(v1.sign)) {
        return Data_Ordering.LT.value;
      }

      ;

      if (Data_Ord.greaterThan(ordSign)(v.sign)(v1.sign)) {
        return Data_Ordering.GT.value;
      }

      ;

      if (v.decimal > v1.decimal) {
        return Data_Ordering.GT.value;
      }

      ;

      if (v.decimal < v1.decimal) {
        return Data_Ordering.LT.value;
      }

      ;

      if (Data_Eq.eq(eqHugeNum)(v)(v1)) {
        return Data_Ordering.EQ.value;
      }

      ;

      if (Data_Boolean.otherwise) {
        var r = equivalize({
          fst: v,
          snd: v1
        });
        var s = rec(r.fst);
        var m = rec(r.snd);

        var x$prime = function () {
          var $181 = Data_Eq.eq(eqHugeNum)(v)(r.fst);

          if ($181) {
            return s;
          }

          ;
          return m;
        }();

        var y$prime = function () {
          var $182 = Data_Eq.eq(eqHugeNum)(v1)(r.fst);

          if ($182) {
            return s;
          }

          ;
          return m;
        }();

        var z = function () {
          var v2 = Data_Ord.compare(Data_List_Types.ordList(Data_Digit.ordDigit))(Data_List.take(v.decimal)(v.digits))(Data_List.take(v.decimal)(v1.digits));

          if (v2 instanceof Data_Ordering.EQ) {
            return Data_Ord.compare(Data_List_Types.ordList(Data_Digit.ordDigit))(Data_List.drop(v.decimal)(x$prime.digits))(Data_List.drop(v.decimal)(y$prime.digits));
          }

          ;
          return v2;
        }();

        return z;
      }

      ;
      throw new Error("Failed pattern match at Data.HugeNum (line 109, column 1 - line 109, column 49): " + [v.constructor.name, v1.constructor.name]);
    };
  };

  var ordHugeNum = new Data_Ord.Ord(function () {
    return eqHugeNum;
  }, compareHugeNum);

  var arrayToHugeNum = function arrayToHugeNum(xs) {
    return {
      sign: Plus.value,
      digits: Data_Semigroup.append(Data_List_Types.semigroupList)(xs)(Control_Applicative.pure(Data_List_Types.applicativeList)(Data_Digit["_zero"])),
      decimal: Data_List.length(xs)
    };
  };

  var adjustDigitsForDecimal = function adjustDigitsForDecimal(decimal) {
    return function (digits) {
      var go = function go($copy_n) {
        return function ($copy_ds) {
          var $tco_var_n = $copy_n;
          var $tco_done = false;
          var $tco_result;

          function $tco_loop(n, ds) {
            if (n <= 0) {
              $tco_done = true;
              return ds;
            }

            ;

            if (Data_Boolean.otherwise) {
              $tco_var_n = n - 1 | 0;
              $copy_ds = new Data_List_Types.Cons(Data_Digit["_zero"], ds);
              return;
            }

            ;
            throw new Error("Failed pattern match at Data.HugeNum (line 388, column 3 - line 390, column 42): " + [n.constructor.name, ds.constructor.name]);
          }

          ;

          while (!$tco_done) {
            $tco_result = $tco_loop($tco_var_n, $copy_ds);
          }

          ;
          return $tco_result;
        };
      };

      return go((decimal - Data_List.length(digits) | 0) + 1 | 0)(digits);
    };
  };

  var adjustDecimalForTriviality = function adjustDecimalForTriviality(h1) {
    return function (h2) {
      return function (v) {
        var digitsLength = Data_List.length(v.digits) - 1 | 0;
        var digits$prime = Data_List.take(digitsLength)(v.digits);
        var decimalMod = meatyDecimals(h1) + meatyDecimals(h2) | 0;
        var digits = Data_Semigroup.append(Data_List_Types.semigroupList)(Data_Unfoldable.replicate(Data_List_Types.unfoldableList)((decimalMod - digitsLength | 0) + 1 | 0)(Data_Digit["_zero"]))(digits$prime);
        var decimal = Data_List.length(Data_List.drop(decimalMod)(Data_List.reverse(digits)));
        var r = {
          digits: digits,
          decimal: decimal,
          sign: Plus.value
        };
        return dropZeroes(r);
      };
    };
  };

  var adjustDecimalForFrontZeroes = function adjustDecimalForFrontZeroes(xs) {
    return function (oldDec) {
      var newDigits$prime = unsafeRemoveFrontZeroes(Data_List.take(oldDec)(xs));

      var newDigits = function () {
        var $191 = Data_List["null"](newDigits$prime);

        if ($191) {
          return Control_Applicative.pure(Data_List_Types.applicativeList)(Data_Digit["_zero"]);
        }

        ;
        return newDigits$prime;
      }();

      return Data_List.length(newDigits);
    };
  };

  var addPlusPlus = function addPlusPlus(x) {
    return function (y) {
      var eqv = equivalize({
        fst: x,
        snd: y
      });
      var r1 = rec(eqv.fst);
      var r2 = rec(eqv.snd);
      var r = Data_List.zip(Data_List.reverse(r1.digits))(Data_List.reverse(r2.digits));
      var digits$prime$prime = Data_Foldable.foldl(Data_List_Types.foldableList)(digitwiseAdd)(new Data_Tuple.Tuple(Data_Monoid.mempty(Data_List_Types.monoidList), Data_Digit["_zero"]))(r);
      var spill = Data_Tuple.snd(digits$prime$prime);
      var digits$prime = Data_Tuple.fst(digits$prime$prime);
      var digits = unsafeRemoveFrontZeroes(new Data_List_Types.Cons(spill, digits$prime));
      var decimal = adjustDecimalForFrontZeroes(new Data_List_Types.Cons(spill, digits$prime))(r1.decimal + 1 | 0);
      var z = {
        digits: adjustDigitsForDecimal(decimal)(digits),
        decimal: decimal,
        sign: Plus.value
      };
      return dropZeroes(z);
    };
  };

  var addPlusMinus = function addPlusMinus(x) {
    return function (y) {
      var eqv = equivalize({
        fst: x,
        snd: y
      });
      var r1 = rec(Data_Ord.min(ordHugeNum)(eqv.fst)(eqv.snd));
      var r2 = rec(Data_Ord.max(ordHugeNum)(eqv.fst)(eqv.snd));
      var r = Data_List.zip(Data_List.reverse(r2.digits))(Data_List.reverse(r1.digits));
      var digits$prime$prime = Data_Foldable.foldl(Data_List_Types.foldableList)(digitwiseSubtract)(new Data_Tuple.Tuple(Data_Monoid.mempty(Data_List_Types.monoidList), Data_Digit["_zero"]))(r);
      var fractionalDigits = Data_List.drop(r1.decimal)(Data_Tuple.fst(digits$prime$prime));
      var integralDigits$prime$prime = Data_List.take(r1.decimal)(Data_Tuple.fst(digits$prime$prime));
      var integralDigits$prime = unsafeRemoveFrontZeroes(integralDigits$prime$prime);

      var integralDigits = function () {
        var $192 = Data_List["null"](integralDigits$prime);

        if ($192) {
          return Control_Applicative.pure(Data_List_Types.applicativeList)(Data_Digit["_zero"]);
        }

        ;
        return integralDigits$prime;
      }();

      var digits = Data_Semigroup.append(Data_List_Types.semigroupList)(integralDigits)(fractionalDigits);
      var decimal = adjustDecimalForFrontZeroes(Data_Tuple.fst(digits$prime$prime))(r1.decimal);
      var z = {
        digits: digits,
        decimal: decimal,
        sign: Plus.value
      };
      return z;
    };
  };

  var addMinusMinus = function addMinusMinus(x) {
    return function (y) {
      var z = rec(addPlusPlus(x)(y));
      return {
        digits: z.digits,
        decimal: z.decimal,
        sign: Minus.value
      };
    };
  };

  var abs = function abs(v) {
    return {
      digits: v.digits,
      decimal: v.decimal,
      sign: Plus.value
    };
  };

  var getPowForKRep = function getPowForKRep(x) {
    return function (y) {
      return function (v) {
        return v - 1 | 0;
      }(function (v) {
        return v.decimal;
      }(rec(Data_Ord.min(ordHugeNum)(abs(x))(abs(y)))));
    };
  };

  var plus = function plus(x) {
    return function (y) {
      if (isZero(x)) {
        return y;
      }

      ;

      if (isZero(y)) {
        return x;
      }

      ;

      if (isPositive(x) && isPositive(y)) {
        return addPlusPlus(x)(y);
      }

      ;

      if (isNegative(x) && isNegative(y)) {
        return addMinusMinus(x)(y);
      }

      ;

      if (Data_Boolean.otherwise) {
        var lesserMag = Data_Ord.min(ordHugeNum)(abs(x))(abs(y));
        var lesser = Data_Ord.min(ordHugeNum)(x)(y);
        var greaterMag = Data_Ord.max(ordHugeNum)(abs(x))(abs(y));
        var greater = Data_Ord.max(ordHugeNum)(x)(y);

        var z = function () {
          var $196 = Data_Eq.eq(eqHugeNum)(greater)(greaterMag);

          if ($196) {
            return addPlusMinus(greater)(lesser);
          }

          ;
          return neg(addPlusMinus(greaterMag)(lesserMag));
        }();

        return z;
      }

      ;
      throw new Error("Failed pattern match at Data.HugeNum (line 440, column 1 - line 440, column 38): " + [x.constructor.name, y.constructor.name]);
    };
  };

  var scale = function () {
    var go = function go($copy_x) {
      return function ($copy_v) {
        return function ($copy_v1) {
          var $tco_var_x = $copy_x;
          var $tco_var_v = $copy_v;
          var $tco_done = false;
          var $tco_result;

          function $tco_loop(x, v, v1) {
            if (v === 0) {
              $tco_done = true;
              return x;
            }

            ;
            $tco_var_x = plus(x)(v1);
            $tco_var_v = v - 1 | 0;
            $copy_v1 = v1;
            return;
          }

          ;

          while (!$tco_done) {
            $tco_result = $tco_loop($tco_var_x, $tco_var_v, $copy_v1);
          }

          ;
          return $tco_result;
        };
      };
    };

    return go(zeroHugeNum);
  }();

  var multSmallNum = function multSmallNum(v) {
    return function (r2) {
      var v1 = Data_List.uncons(v.digits);

      if (v1 instanceof Data_Maybe.Just) {
        var $203 = Data_Eq.eq(Data_Digit.eqDigit)(v1.value0.head)(Data_Digit["_zero"]);

        if ($203) {
          return zeroHugeNum;
        }

        ;
        return scale(Data_Digit.toInt(v1.value0.head))(r2);
      }

      ;
      return Effect_Exception_Unsafe.unsafeThrow("Error: The impossible happened");
    };
  };

  var toKRep = function toKRep(exp) {
    return function (v) {
      var coeff = Data_List.take(v.decimal - exp | 0)(v.digits);
      var bm = new Data_List_Types.Cons(Data_Digit["_one"], Data_Unfoldable.replicate(Data_List_Types.unfoldableList)(exp)(Data_Digit["_zero"]));
      var prod = Data_Semigroup.append(Data_List_Types.semigroupList)(coeff)(Data_List.drop(1)(bm));
      var leftSummand = arrayToHugeNum(prod);
      var k = plus(v)(neg(leftSummand));
      var z = {
        exp: exp,
        coeff: coeff,
        "const": k
      };
      return z;
    };
  };

  var times = function times(r1) {
    return function (r2) {
      if (Data_Eq.eq(eqSign)(timesSign(function (v) {
        return v.sign;
      }(rec(r1)))(function (v) {
        return v.sign;
      }(rec(r2))))(Minus.value)) {
        return neg(times(abs(r1))(abs(r2)));
      }

      ;

      if (!trivialFraction(r1) || !trivialFraction(r2)) {
        return adjustDecimalForTriviality(r1)(r2)(times(makeHugeInteger(r1))(makeHugeInteger(r2)));
      }

      ;

      if (smallEnough(r1)) {
        return multSmallNum(r1)(r2);
      }

      ;

      if (smallEnough(r2)) {
        return multSmallNum(r2)(r1);
      }

      ;

      if (Data_Boolean.otherwise) {
        var exp = getPowForKRep(r1)(r2);
        var k1 = toKRep(exp)(r1);
        var k2 = toKRep(exp)(r2);
        var z0 = times(k1["const"])(k2["const"]);
        var z2 = times(arrayToHugeNum(k1.coeff))(arrayToHugeNum(k2.coeff));

        var z1 = function () {
          var rightFactor = plus(arrayToHugeNum(k2.coeff))(k2["const"]);
          var leftFactor = plus(arrayToHugeNum(k1.coeff))(k1["const"]);
          var prod = times(leftFactor)(rightFactor);
          return plus(plus(prod)(neg(z2)))(neg(z0));
        }();

        var z1Bm = timesPowOfTen(exp)(z1);
        var z2B2m = timesPowOfTen(2 * exp | 0)(z2);
        var z = plus(plus(z2B2m)(z1Bm))(z0);
        return z;
      }

      ;
      throw new Error("Failed pattern match at Data.HugeNum (line 512, column 1 - line 512, column 39): " + [r1.constructor.name, r2.constructor.name]);
    };
  };

  var semiringHugeNum = new Data_Semiring.Semiring(plus, times, oneHugeNum, zeroHugeNum);

  var ceil = function ceil(h) {
    if (isNegative(h)) {
      return integerPart(h);
    }

    ;

    if (isZero(h)) {
      return Data_Semiring.zero(semiringHugeNum);
    }

    ;

    if (Data_Boolean.otherwise) {
      return Data_Semiring.add(semiringHugeNum)(integerPart(h))(Data_Semiring.one(semiringHugeNum));
    }

    ;
    throw new Error("Failed pattern match at Data.HugeNum (line 324, column 1 - line 324, column 27): " + [h.constructor.name]);
  };

  var ringHugeNum = new Data_Ring.Ring(function () {
    return semiringHugeNum;
  }, function (r1) {
    return function (r2) {
      return Data_Semiring.add(semiringHugeNum)(r1)(neg(r2));
    };
  });

  var floor = function floor(h) {
    if (isPositive(h)) {
      return integerPart(h);
    }

    ;

    if (isZero(h)) {
      return Data_Semiring.zero(semiringHugeNum);
    }

    ;

    if (Data_Boolean.otherwise) {
      return Data_Ring.sub(ringHugeNum)(integerPart(h))(Data_Semiring.one(semiringHugeNum));
    }

    ;
    throw new Error("Failed pattern match at Data.HugeNum (line 318, column 1 - line 318, column 28): " + [h.constructor.name]);
  };

  var round = function round(h) {
    if (Data_Ord.lessThan(ordHugeNum)(abs(Data_Ring.sub(ringHugeNum)(h)(floor(h))))(abs(Data_Ring.sub(ringHugeNum)(ceil(h))(h)))) {
      return floor(h);
    }

    ;

    if (Data_Boolean.otherwise) {
      return ceil(h);
    }

    ;
    throw new Error("Failed pattern match at Data.HugeNum (line 330, column 1 - line 330, column 28): " + [h.constructor.name]);
  };

  exports["fromString"] = fromString;
  exports["fromNumber"] = fromNumber;
  exports["toString"] = toString;
  exports["integerPart"] = integerPart;
  exports["abs"] = abs;
  exports["neg"] = neg;
  exports["isNegative"] = isNegative;
  exports["isPositive"] = isPositive;
  exports["isZero"] = isZero;
  exports["floor"] = floor;
  exports["ceil"] = ceil;
  exports["round"] = round;
  exports["eqHugeNum"] = eqHugeNum;
  exports["ordHugeNum"] = ordHugeNum;
  exports["semiringHugeNum"] = semiringHugeNum;
  exports["ringHugeNum"] = ringHugeNum;
})(PS["Data.HugeNum"] = PS["Data.HugeNum"] || {});

(function (exports) {
  exports.numberBitsToInt = function (x) {
    return x | 0;
  };

  var Long = require("long");

  function RadixChecker(radix) {
    var digits;

    if (radix < 11) {
      digits = "[0-" + (radix - 1).toString() + "]";
    } else if (radix === 11) {
      digits = "[0-9a]";
    } else {
      digits = "[0-9a-" + String.fromCharCode(86 + radix) + "]";
    }

    this.baseRE = new RegExp("^" + digits + "+$", "i");
    this.maxNegSignedBase = Long.MIN_VALUE.toString(radix).substring(1);
    this.maxPosSignedBase = Long.MAX_VALUE.toString(radix);
    this.maxUnsignedBase = Long.MAX_UNSIGNED_VALUE.toString(radix);
  }

  function hasValidDigits(isNegative, isUnsigned, base, radix) {
    radixCheckers[radix] = radixCheckers[radix] || new RadixChecker(radix);
    var rc = radixCheckers[radix];
    var maxBase;

    if (isUnsigned) {
      maxBase = rc.maxUnsignedBase;
    } else if (isNegative) {
      maxBase = rc.maxNegSignedBase;
    } else {
      maxBase = rc.maxPosSignedBase;
    }

    return (base.length < maxBase.length || base.length === maxBase.length && base <= maxBase) && rc.baseRE.test(base);
  }

  exports._safeReadLong = function (s, isUnsigned, radix) {
    var isNegative = s.startsWith("-");
    var hasPositivePrefix = s.startsWith("+");
    var base = isNegative || hasPositivePrefix ? s.substring(1) : s; // remove preceding zeros

    var lastNdx = 0;

    while (lastNdx < base.length - 1 && base[lastNdx] === "0") {
      lastNdx++;
    }

    base = base.substring(lastNdx);
    var retVal = null;

    if ( // long.js return 0 for empty string
    base !== "" && ( // long.js coerces negative values to their unsigned counterparts
    !isNegative || !isUnsigned || base === "0") // Invalid characters / overflow
    && hasValidDigits(isNegative, isUnsigned, base, radix)) {
      try {
        var signPrefix = isNegative ? "-" : ""; // long.js may throw errors

        retVal = Long.fromString(signPrefix + base, isUnsigned, radix);
      } catch (err) {}
    }

    return retVal;
  };

  exports.isWholeNumber = function (n) {
    return Number.isInteger(n);
  };
})(PS["Data.Long.Internal"] = PS["Data.Long.Internal"] || {});

(function (exports) {
  var L = require("long"); // Constants


  exports.zero = L.ZERO;
  exports.one = L.ONE;
  exports.maxValue = L.MAX_VALUE;
  exports.minValue = L.MIN_VALUE;
  exports.fromNumber = L.fromNumber; // Methods

  exports.add = function (l) {
    return l.add.bind(l);
  };

  exports.compare = function (l) {
    return l.compare.bind(l);
  };

  exports.equals = function (l) {
    return l.equals.bind(l);
  };

  exports.getHighBits = function (l) {
    return l.getHighBits();
  };

  exports.getLowBits = function (l) {
    return l.getLowBits();
  };

  exports.multiply = function (l) {
    return l.multiply.bind(l);
  };

  exports.toNumber = function (l) {
    return l.toNumber();
  };

  exports.toString = function (l) {
    return l.toString.bind(l);
  };
})(PS["Data.Long.FFI"] = PS["Data.Long.FFI"] || {});

(function (exports) {
  // Generated by purs version 0.12.3
  "use strict";

  var $foreign = PS["Data.Long.FFI"];
  var Data_Eq = PS["Data.Eq"];
  var Data_Function_Uncurried = PS["Data.Function.Uncurried"];
  var Data_HeytingAlgebra = PS["Data.HeytingAlgebra"];
  var Data_Int = PS["Data.Int"];
  var Data_Semigroup = PS["Data.Semigroup"];
  var Data_Show = PS["Data.Show"];
  var Effect_Uncurried = PS["Effect.Uncurried"];
  var Foreign = PS["Foreign"];
  var Prelude = PS["Prelude"];
  exports["zero"] = $foreign.zero;
  exports["one"] = $foreign.one;
  exports["maxValue"] = $foreign.maxValue;
  exports["minValue"] = $foreign.minValue;
  exports["fromNumber"] = $foreign.fromNumber;
  exports["add"] = $foreign.add;
  exports["compare"] = $foreign.compare;
  exports["equals"] = $foreign.equals;
  exports["getHighBits"] = $foreign.getHighBits;
  exports["getLowBits"] = $foreign.getLowBits;
  exports["multiply"] = $foreign.multiply;
  exports["toNumber"] = $foreign.toNumber;
  exports["toString"] = $foreign.toString;
})(PS["Data.Long.FFI"] = PS["Data.Long.FFI"] || {});

(function (exports) {
  "use strict";

  exports.nullable = function (a, r, f) {
    return a == null ? r : f(a);
  };
})(PS["Data.Nullable"] = PS["Data.Nullable"] || {});

(function (exports) {
  // Generated by purs version 0.12.3
  "use strict";

  var $foreign = PS["Data.Nullable"];
  var Control_Semigroupoid = PS["Control.Semigroupoid"];
  var Data_Eq = PS["Data.Eq"];
  var Data_Function = PS["Data.Function"];
  var Data_Function_Uncurried = PS["Data.Function.Uncurried"];
  var Data_Maybe = PS["Data.Maybe"];
  var Data_Ord = PS["Data.Ord"];
  var Data_Show = PS["Data.Show"];
  var Prelude = PS["Prelude"];

  var toMaybe = function toMaybe(n) {
    return $foreign.nullable(n, Data_Maybe.Nothing.value, Data_Maybe.Just.create);
  };

  exports["toMaybe"] = toMaybe;
})(PS["Data.Nullable"] = PS["Data.Nullable"] || {});

(function (exports) {
  // Generated by purs version 0.12.3
  "use strict";

  var $foreign = PS["Data.Long.Internal"];
  var Control_Apply = PS["Control.Apply"];
  var Control_Semigroupoid = PS["Control.Semigroupoid"];
  var Data_Boolean = PS["Data.Boolean"];
  var Data_Bounded = PS["Data.Bounded"];
  var Data_CommutativeRing = PS["Data.CommutativeRing"];
  var Data_Eq = PS["Data.Eq"];
  var Data_EuclideanRing = PS["Data.EuclideanRing"];
  var Data_Function = PS["Data.Function"];
  var Data_Function_Uncurried = PS["Data.Function.Uncurried"];
  var Data_Functor = PS["Data.Functor"];
  var Data_HeytingAlgebra = PS["Data.HeytingAlgebra"];
  var Data_Int = PS["Data.Int"];
  var Data_Long_FFI = PS["Data.Long.FFI"];
  var Data_Maybe = PS["Data.Maybe"];
  var Data_Nullable = PS["Data.Nullable"];
  var Data_Ord = PS["Data.Ord"];
  var Data_Ordering = PS["Data.Ordering"];
  var Data_Ring = PS["Data.Ring"];
  var Data_Semiring = PS["Data.Semiring"];
  var Data_Show = PS["Data.Show"];
  var Prelude = PS["Prelude"];
  var Test_QuickCheck = PS["Test.QuickCheck"];
  var Test_QuickCheck_Arbitrary = PS["Test.QuickCheck.Arbitrary"];
  var Test_QuickCheck_Gen = PS["Test.QuickCheck.Gen"];

  var SignProxy = function () {
    function SignProxy() {}

    ;
    SignProxy.value = new SignProxy();
    return SignProxy;
  }();

  var Long$prime = function Long$prime(x) {
    return x;
  };

  var SInfo = function SInfo(ffiSignedness, toInt) {
    this.ffiSignedness = ffiSignedness;
    this.toInt = toInt;
  };

  var toStringAs = function toStringAs(r) {
    return function (v) {
      return Data_Long_FFI.toString(v)(r);
    };
  };

  var toString = toStringAs(Data_Int.decimal);

  var toNumber = function toNumber(v) {
    return Data_Long_FFI.toNumber(v);
  };

  var toInt = function toInt(dict) {
    return dict.toInt;
  };

  var semiringLong$primeSigned = new Data_Semiring.Semiring(function (v) {
    return function (v1) {
      return Long$prime(Data_Long_FFI.add(v)(v1));
    };
  }, function (v) {
    return function (v1) {
      return Long$prime(Data_Long_FFI.multiply(v)(v1));
    };
  }, Long$prime(Data_Long_FFI.one), Long$prime(Data_Long_FFI.zero));

  var safeReadLong = function safeReadLong(s) {
    return function (isSigned) {
      return function (radix) {
        return Data_Nullable.toMaybe($foreign["_safeReadLong"](s, isSigned, radix));
      };
    };
  };

  var lowBits = function lowBits(v) {
    return $foreign.numberBitsToInt(Data_Long_FFI.getLowBits(v));
  };

  var isNumberInLongRange = function isNumberInLongRange(dictBounded) {
    return function (p) {
      return function (n) {
        var longTopValueN = toNumber(Data_Bounded.top(dictBounded));
        var longBottomValueN = toNumber(Data_Bounded.bottom(dictBounded));
        return longBottomValueN <= n && n <= longTopValueN;
      };
    };
  };

  var highBits = function highBits(v) {
    return $foreign.numberBitsToInt(Data_Long_FFI.getHighBits(v));
  };

  var infoSigned = new SInfo(function (v) {
    return false;
  }, function (l) {
    var low = lowBits(l);
    var high = highBits(l);
    var $93 = high === 0 && low >= 0 || high === (-1 | 0) && low < 0;

    if ($93) {
      return new Data_Maybe.Just(low);
    }

    ;
    return Data_Maybe.Nothing.value;
  });

  var ffiSignedness = function ffiSignedness(dict) {
    return dict.ffiSignedness;
  };

  var fromNumber = function fromNumber(dictSInfo) {
    return function (dictBounded) {
      return function (n) {
        var isValidNumber = $foreign.isWholeNumber(n) && isNumberInLongRange(dictBounded)(SignProxy.value)(n);

        if (isValidNumber) {
          return Data_Maybe.Just.create(Long$prime(Data_Long_FFI.fromNumber(n, ffiSignedness(dictSInfo)(SignProxy.value))));
        }

        ;
        return Data_Maybe.Nothing.value;
      };
    };
  };

  var fromStringAs = function fromStringAs(dictSInfo) {
    return function (radix) {
      return function (s) {
        return Data_Functor.map(Data_Maybe.functorMaybe)(Long$prime)(safeReadLong(s)(ffiSignedness(dictSInfo)(SignProxy.value))(radix));
      };
    };
  };

  var fromString = function fromString(dictSInfo) {
    return fromStringAs(dictSInfo)(Data_Int.decimal);
  };

  var eqLong$prime = new Data_Eq.Eq(function (v) {
    return function (v1) {
      return Data_Long_FFI.equals(v)(v1);
    };
  });
  var ordLong$prime = new Data_Ord.Ord(function () {
    return eqLong$prime;
  }, function (v) {
    return function (v1) {
      var v2 = Data_Long_FFI.compare(v)(v1);

      if (v2 === 0) {
        return Data_Ordering.EQ.value;
      }

      ;

      if (v2 > 0) {
        return Data_Ordering.GT.value;
      }

      ;
      return Data_Ordering.LT.value;
    };
  });
  var boundedLong$primeSigned = new Data_Bounded.Bounded(function () {
    return ordLong$prime;
  }, Long$prime(Data_Long_FFI.minValue), Long$prime(Data_Long_FFI.maxValue));
  exports["SInfo"] = SInfo;
  exports["ffiSignedness"] = ffiSignedness;
  exports["toInt"] = toInt;
  exports["SignProxy"] = SignProxy;
  exports["fromNumber"] = fromNumber;
  exports["fromString"] = fromString;
  exports["fromStringAs"] = fromStringAs;
  exports["highBits"] = highBits;
  exports["lowBits"] = lowBits;
  exports["toString"] = toString;
  exports["toStringAs"] = toStringAs;
  exports["toNumber"] = toNumber;
  exports["infoSigned"] = infoSigned;
  exports["boundedLong'Signed"] = boundedLong$primeSigned;
  exports["semiringLong'Signed"] = semiringLong$primeSigned;
})(PS["Data.Long.Internal"] = PS["Data.Long.Internal"] || {});

(function (exports) {
  // Generated by purs version 0.12.3
  "use strict";

  var Data_Int = PS["Data.Int"];
  var Data_Long_Internal = PS["Data.Long.Internal"];
  var Data_Maybe = PS["Data.Maybe"];
  var toString = Data_Long_Internal.toString;
  var toInt = Data_Long_Internal.toInt(Data_Long_Internal.infoSigned);
  var fromString = Data_Long_Internal.fromString(Data_Long_Internal.infoSigned);
  var fromNumber = Data_Long_Internal.fromNumber(Data_Long_Internal.infoSigned)(Data_Long_Internal["boundedLong'Signed"]);
  exports["fromNumber"] = fromNumber;
  exports["fromString"] = fromString;
  exports["toInt"] = toInt;
  exports["toString"] = toString;
})(PS["Data.Long"] = PS["Data.Long"] || {});

(function (exports) {
  // Generated by purs version 0.12.3
  "use strict";

  var Control_Applicative = PS["Control.Applicative"];
  var Control_Apply = PS["Control.Apply"];
  var Control_Category = PS["Control.Category"];
  var Control_Semigroupoid = PS["Control.Semigroupoid"];
  var Data_Eq = PS["Data.Eq"];
  var Data_Foldable = PS["Data.Foldable"];
  var Data_FoldableWithIndex = PS["Data.FoldableWithIndex"];
  var Data_Function = PS["Data.Function"];
  var Data_Functor = PS["Data.Functor"];
  var Data_FunctorWithIndex = PS["Data.FunctorWithIndex"];
  var Data_HeytingAlgebra = PS["Data.HeytingAlgebra"];
  var Data_List = PS["Data.List"];
  var Data_List_Lazy = PS["Data.List.Lazy"];
  var Data_List_Lazy_Types = PS["Data.List.Lazy.Types"];
  var Data_List_Types = PS["Data.List.Types"];
  var Data_Maybe = PS["Data.Maybe"];
  var Data_Monoid = PS["Data.Monoid"];
  var Data_Ord = PS["Data.Ord"];
  var Data_Ordering = PS["Data.Ordering"];
  var Data_Semigroup = PS["Data.Semigroup"];
  var Data_Semiring = PS["Data.Semiring"];
  var Data_Show = PS["Data.Show"];
  var Data_Traversable = PS["Data.Traversable"];
  var Data_TraversableWithIndex = PS["Data.TraversableWithIndex"];
  var Data_Tuple = PS["Data.Tuple"];
  var Data_Unfoldable = PS["Data.Unfoldable"];
  var Partial_Unsafe = PS["Partial.Unsafe"];
  var Prelude = PS["Prelude"];

  var Leaf = function () {
    function Leaf() {}

    ;
    Leaf.value = new Leaf();
    return Leaf;
  }();

  var Two = function () {
    function Two(value0, value1, value2, value3) {
      this.value0 = value0;
      this.value1 = value1;
      this.value2 = value2;
      this.value3 = value3;
    }

    ;

    Two.create = function (value0) {
      return function (value1) {
        return function (value2) {
          return function (value3) {
            return new Two(value0, value1, value2, value3);
          };
        };
      };
    };

    return Two;
  }();

  var Three = function () {
    function Three(value0, value1, value2, value3, value4, value5, value6) {
      this.value0 = value0;
      this.value1 = value1;
      this.value2 = value2;
      this.value3 = value3;
      this.value4 = value4;
      this.value5 = value5;
      this.value6 = value6;
    }

    ;

    Three.create = function (value0) {
      return function (value1) {
        return function (value2) {
          return function (value3) {
            return function (value4) {
              return function (value5) {
                return function (value6) {
                  return new Three(value0, value1, value2, value3, value4, value5, value6);
                };
              };
            };
          };
        };
      };
    };

    return Three;
  }();

  var TwoLeft = function () {
    function TwoLeft(value0, value1, value2) {
      this.value0 = value0;
      this.value1 = value1;
      this.value2 = value2;
    }

    ;

    TwoLeft.create = function (value0) {
      return function (value1) {
        return function (value2) {
          return new TwoLeft(value0, value1, value2);
        };
      };
    };

    return TwoLeft;
  }();

  var TwoRight = function () {
    function TwoRight(value0, value1, value2) {
      this.value0 = value0;
      this.value1 = value1;
      this.value2 = value2;
    }

    ;

    TwoRight.create = function (value0) {
      return function (value1) {
        return function (value2) {
          return new TwoRight(value0, value1, value2);
        };
      };
    };

    return TwoRight;
  }();

  var ThreeLeft = function () {
    function ThreeLeft(value0, value1, value2, value3, value4, value5) {
      this.value0 = value0;
      this.value1 = value1;
      this.value2 = value2;
      this.value3 = value3;
      this.value4 = value4;
      this.value5 = value5;
    }

    ;

    ThreeLeft.create = function (value0) {
      return function (value1) {
        return function (value2) {
          return function (value3) {
            return function (value4) {
              return function (value5) {
                return new ThreeLeft(value0, value1, value2, value3, value4, value5);
              };
            };
          };
        };
      };
    };

    return ThreeLeft;
  }();

  var ThreeMiddle = function () {
    function ThreeMiddle(value0, value1, value2, value3, value4, value5) {
      this.value0 = value0;
      this.value1 = value1;
      this.value2 = value2;
      this.value3 = value3;
      this.value4 = value4;
      this.value5 = value5;
    }

    ;

    ThreeMiddle.create = function (value0) {
      return function (value1) {
        return function (value2) {
          return function (value3) {
            return function (value4) {
              return function (value5) {
                return new ThreeMiddle(value0, value1, value2, value3, value4, value5);
              };
            };
          };
        };
      };
    };

    return ThreeMiddle;
  }();

  var ThreeRight = function () {
    function ThreeRight(value0, value1, value2, value3, value4, value5) {
      this.value0 = value0;
      this.value1 = value1;
      this.value2 = value2;
      this.value3 = value3;
      this.value4 = value4;
      this.value5 = value5;
    }

    ;

    ThreeRight.create = function (value0) {
      return function (value1) {
        return function (value2) {
          return function (value3) {
            return function (value4) {
              return function (value5) {
                return new ThreeRight(value0, value1, value2, value3, value4, value5);
              };
            };
          };
        };
      };
    };

    return ThreeRight;
  }();

  var KickUp = function () {
    function KickUp(value0, value1, value2, value3) {
      this.value0 = value0;
      this.value1 = value1;
      this.value2 = value2;
      this.value3 = value3;
    }

    ;

    KickUp.create = function (value0) {
      return function (value1) {
        return function (value2) {
          return function (value3) {
            return new KickUp(value0, value1, value2, value3);
          };
        };
      };
    };

    return KickUp;
  }();

  var values = function values(v) {
    if (v instanceof Leaf) {
      return Data_List_Types.Nil.value;
    }

    ;

    if (v instanceof Two) {
      return Data_Semigroup.append(Data_List_Types.semigroupList)(values(v.value0))(Data_Semigroup.append(Data_List_Types.semigroupList)(Control_Applicative.pure(Data_List_Types.applicativeList)(v.value2))(values(v.value3)));
    }

    ;

    if (v instanceof Three) {
      return Data_Semigroup.append(Data_List_Types.semigroupList)(values(v.value0))(Data_Semigroup.append(Data_List_Types.semigroupList)(Control_Applicative.pure(Data_List_Types.applicativeList)(v.value2))(Data_Semigroup.append(Data_List_Types.semigroupList)(values(v.value3))(Data_Semigroup.append(Data_List_Types.semigroupList)(Control_Applicative.pure(Data_List_Types.applicativeList)(v.value5))(values(v.value6)))));
    }

    ;
    throw new Error("Failed pattern match at Data.Map.Internal (line 612, column 1 - line 612, column 40): " + [v.constructor.name]);
  };

  var fromZipper = function fromZipper($copy_dictOrd) {
    return function ($copy_v) {
      return function ($copy_tree) {
        var $tco_var_dictOrd = $copy_dictOrd;
        var $tco_var_v = $copy_v;
        var $tco_done = false;
        var $tco_result;

        function $tco_loop(dictOrd, v, tree) {
          if (v instanceof Data_List_Types.Nil) {
            $tco_done = true;
            return tree;
          }

          ;

          if (v instanceof Data_List_Types.Cons) {
            if (v.value0 instanceof TwoLeft) {
              $tco_var_dictOrd = dictOrd;
              $tco_var_v = v.value1;
              $copy_tree = new Two(tree, v.value0.value0, v.value0.value1, v.value0.value2);
              return;
            }

            ;

            if (v.value0 instanceof TwoRight) {
              $tco_var_dictOrd = dictOrd;
              $tco_var_v = v.value1;
              $copy_tree = new Two(v.value0.value0, v.value0.value1, v.value0.value2, tree);
              return;
            }

            ;

            if (v.value0 instanceof ThreeLeft) {
              $tco_var_dictOrd = dictOrd;
              $tco_var_v = v.value1;
              $copy_tree = new Three(tree, v.value0.value0, v.value0.value1, v.value0.value2, v.value0.value3, v.value0.value4, v.value0.value5);
              return;
            }

            ;

            if (v.value0 instanceof ThreeMiddle) {
              $tco_var_dictOrd = dictOrd;
              $tco_var_v = v.value1;
              $copy_tree = new Three(v.value0.value0, v.value0.value1, v.value0.value2, tree, v.value0.value3, v.value0.value4, v.value0.value5);
              return;
            }

            ;

            if (v.value0 instanceof ThreeRight) {
              $tco_var_dictOrd = dictOrd;
              $tco_var_v = v.value1;
              $copy_tree = new Three(v.value0.value0, v.value0.value1, v.value0.value2, v.value0.value3, v.value0.value4, v.value0.value5, tree);
              return;
            }

            ;
            throw new Error("Failed pattern match at Data.Map.Internal (line 418, column 3 - line 423, column 88): " + [v.value0.constructor.name]);
          }

          ;
          throw new Error("Failed pattern match at Data.Map.Internal (line 415, column 1 - line 415, column 80): " + [v.constructor.name, tree.constructor.name]);
        }

        ;

        while (!$tco_done) {
          $tco_result = $tco_loop($tco_var_dictOrd, $tco_var_v, $copy_tree);
        }

        ;
        return $tco_result;
      };
    };
  };

  var insert = function insert(dictOrd) {
    return function (k) {
      return function (v) {
        var up = function up($copy_v1) {
          return function ($copy_v2) {
            var $tco_var_v1 = $copy_v1;
            var $tco_done = false;
            var $tco_result;

            function $tco_loop(v1, v2) {
              if (v1 instanceof Data_List_Types.Nil) {
                $tco_done = true;
                return new Two(v2.value0, v2.value1, v2.value2, v2.value3);
              }

              ;

              if (v1 instanceof Data_List_Types.Cons) {
                if (v1.value0 instanceof TwoLeft) {
                  $tco_done = true;
                  return fromZipper(dictOrd)(v1.value1)(new Three(v2.value0, v2.value1, v2.value2, v2.value3, v1.value0.value0, v1.value0.value1, v1.value0.value2));
                }

                ;

                if (v1.value0 instanceof TwoRight) {
                  $tco_done = true;
                  return fromZipper(dictOrd)(v1.value1)(new Three(v1.value0.value0, v1.value0.value1, v1.value0.value2, v2.value0, v2.value1, v2.value2, v2.value3));
                }

                ;

                if (v1.value0 instanceof ThreeLeft) {
                  $tco_var_v1 = v1.value1;
                  $copy_v2 = new KickUp(new Two(v2.value0, v2.value1, v2.value2, v2.value3), v1.value0.value0, v1.value0.value1, new Two(v1.value0.value2, v1.value0.value3, v1.value0.value4, v1.value0.value5));
                  return;
                }

                ;

                if (v1.value0 instanceof ThreeMiddle) {
                  $tco_var_v1 = v1.value1;
                  $copy_v2 = new KickUp(new Two(v1.value0.value0, v1.value0.value1, v1.value0.value2, v2.value0), v2.value1, v2.value2, new Two(v2.value3, v1.value0.value3, v1.value0.value4, v1.value0.value5));
                  return;
                }

                ;

                if (v1.value0 instanceof ThreeRight) {
                  $tco_var_v1 = v1.value1;
                  $copy_v2 = new KickUp(new Two(v1.value0.value0, v1.value0.value1, v1.value0.value2, v1.value0.value3), v1.value0.value4, v1.value0.value5, new Two(v2.value0, v2.value1, v2.value2, v2.value3));
                  return;
                }

                ;
                throw new Error("Failed pattern match at Data.Map.Internal (line 454, column 5 - line 459, column 108): " + [v1.value0.constructor.name, v2.constructor.name]);
              }

              ;
              throw new Error("Failed pattern match at Data.Map.Internal (line 451, column 3 - line 451, column 56): " + [v1.constructor.name, v2.constructor.name]);
            }

            ;

            while (!$tco_done) {
              $tco_result = $tco_loop($tco_var_v1, $copy_v2);
            }

            ;
            return $tco_result;
          };
        };

        var comp = Data_Ord.compare(dictOrd);

        var down = function down($copy_ctx) {
          return function ($copy_v1) {
            var $tco_var_ctx = $copy_ctx;
            var $tco_done = false;
            var $tco_result;

            function $tco_loop(ctx, v1) {
              if (v1 instanceof Leaf) {
                $tco_done = true;
                return up(ctx)(new KickUp(Leaf.value, k, v, Leaf.value));
              }

              ;

              if (v1 instanceof Two) {
                var v2 = comp(k)(v1.value1);

                if (v2 instanceof Data_Ordering.EQ) {
                  $tco_done = true;
                  return fromZipper(dictOrd)(ctx)(new Two(v1.value0, k, v, v1.value3));
                }

                ;

                if (v2 instanceof Data_Ordering.LT) {
                  $tco_var_ctx = new Data_List_Types.Cons(new TwoLeft(v1.value1, v1.value2, v1.value3), ctx);
                  $copy_v1 = v1.value0;
                  return;
                }

                ;
                $tco_var_ctx = new Data_List_Types.Cons(new TwoRight(v1.value0, v1.value1, v1.value2), ctx);
                $copy_v1 = v1.value3;
                return;
              }

              ;

              if (v1 instanceof Three) {
                var v3 = comp(k)(v1.value1);

                if (v3 instanceof Data_Ordering.EQ) {
                  $tco_done = true;
                  return fromZipper(dictOrd)(ctx)(new Three(v1.value0, k, v, v1.value3, v1.value4, v1.value5, v1.value6));
                }

                ;
                var v4 = comp(k)(v1.value4);

                if (v4 instanceof Data_Ordering.EQ) {
                  $tco_done = true;
                  return fromZipper(dictOrd)(ctx)(new Three(v1.value0, v1.value1, v1.value2, v1.value3, k, v, v1.value6));
                }

                ;

                if (v3 instanceof Data_Ordering.LT) {
                  $tco_var_ctx = new Data_List_Types.Cons(new ThreeLeft(v1.value1, v1.value2, v1.value3, v1.value4, v1.value5, v1.value6), ctx);
                  $copy_v1 = v1.value0;
                  return;
                }

                ;

                if (v3 instanceof Data_Ordering.GT && v4 instanceof Data_Ordering.LT) {
                  $tco_var_ctx = new Data_List_Types.Cons(new ThreeMiddle(v1.value0, v1.value1, v1.value2, v1.value4, v1.value5, v1.value6), ctx);
                  $copy_v1 = v1.value3;
                  return;
                }

                ;
                $tco_var_ctx = new Data_List_Types.Cons(new ThreeRight(v1.value0, v1.value1, v1.value2, v1.value3, v1.value4, v1.value5), ctx);
                $copy_v1 = v1.value6;
                return;
              }

              ;
              throw new Error("Failed pattern match at Data.Map.Internal (line 434, column 3 - line 434, column 55): " + [ctx.constructor.name, v1.constructor.name]);
            }

            ;

            while (!$tco_done) {
              $tco_result = $tco_loop($tco_var_ctx, $copy_v1);
            }

            ;
            return $tco_result;
          };
        };

        return down(Data_List_Types.Nil.value);
      };
    };
  };

  var pop = function pop(dictOrd) {
    return function (k) {
      var up = function up($copy_ctxs) {
        return function ($copy_tree) {
          var $tco_var_ctxs = $copy_ctxs;
          var $tco_done = false;
          var $tco_result;

          function $tco_loop(ctxs, tree) {
            if (ctxs instanceof Data_List_Types.Nil) {
              $tco_done = true;
              return tree;
            }

            ;

            if (ctxs instanceof Data_List_Types.Cons) {
              if (ctxs.value0 instanceof TwoLeft && ctxs.value0.value2 instanceof Leaf && tree instanceof Leaf) {
                $tco_done = true;
                return fromZipper(dictOrd)(ctxs.value1)(new Two(Leaf.value, ctxs.value0.value0, ctxs.value0.value1, Leaf.value));
              }

              ;

              if (ctxs.value0 instanceof TwoRight && ctxs.value0.value0 instanceof Leaf && tree instanceof Leaf) {
                $tco_done = true;
                return fromZipper(dictOrd)(ctxs.value1)(new Two(Leaf.value, ctxs.value0.value1, ctxs.value0.value2, Leaf.value));
              }

              ;

              if (ctxs.value0 instanceof TwoLeft && ctxs.value0.value2 instanceof Two) {
                $tco_var_ctxs = ctxs.value1;
                $copy_tree = new Three(tree, ctxs.value0.value0, ctxs.value0.value1, ctxs.value0.value2.value0, ctxs.value0.value2.value1, ctxs.value0.value2.value2, ctxs.value0.value2.value3);
                return;
              }

              ;

              if (ctxs.value0 instanceof TwoRight && ctxs.value0.value0 instanceof Two) {
                $tco_var_ctxs = ctxs.value1;
                $copy_tree = new Three(ctxs.value0.value0.value0, ctxs.value0.value0.value1, ctxs.value0.value0.value2, ctxs.value0.value0.value3, ctxs.value0.value1, ctxs.value0.value2, tree);
                return;
              }

              ;

              if (ctxs.value0 instanceof TwoLeft && ctxs.value0.value2 instanceof Three) {
                $tco_done = true;
                return fromZipper(dictOrd)(ctxs.value1)(new Two(new Two(tree, ctxs.value0.value0, ctxs.value0.value1, ctxs.value0.value2.value0), ctxs.value0.value2.value1, ctxs.value0.value2.value2, new Two(ctxs.value0.value2.value3, ctxs.value0.value2.value4, ctxs.value0.value2.value5, ctxs.value0.value2.value6)));
              }

              ;

              if (ctxs.value0 instanceof TwoRight && ctxs.value0.value0 instanceof Three) {
                $tco_done = true;
                return fromZipper(dictOrd)(ctxs.value1)(new Two(new Two(ctxs.value0.value0.value0, ctxs.value0.value0.value1, ctxs.value0.value0.value2, ctxs.value0.value0.value3), ctxs.value0.value0.value4, ctxs.value0.value0.value5, new Two(ctxs.value0.value0.value6, ctxs.value0.value1, ctxs.value0.value2, tree)));
              }

              ;

              if (ctxs.value0 instanceof ThreeLeft && ctxs.value0.value2 instanceof Leaf && ctxs.value0.value5 instanceof Leaf && tree instanceof Leaf) {
                $tco_done = true;
                return fromZipper(dictOrd)(ctxs.value1)(new Three(Leaf.value, ctxs.value0.value0, ctxs.value0.value1, Leaf.value, ctxs.value0.value3, ctxs.value0.value4, Leaf.value));
              }

              ;

              if (ctxs.value0 instanceof ThreeMiddle && ctxs.value0.value0 instanceof Leaf && ctxs.value0.value5 instanceof Leaf && tree instanceof Leaf) {
                $tco_done = true;
                return fromZipper(dictOrd)(ctxs.value1)(new Three(Leaf.value, ctxs.value0.value1, ctxs.value0.value2, Leaf.value, ctxs.value0.value3, ctxs.value0.value4, Leaf.value));
              }

              ;

              if (ctxs.value0 instanceof ThreeRight && ctxs.value0.value0 instanceof Leaf && ctxs.value0.value3 instanceof Leaf && tree instanceof Leaf) {
                $tco_done = true;
                return fromZipper(dictOrd)(ctxs.value1)(new Three(Leaf.value, ctxs.value0.value1, ctxs.value0.value2, Leaf.value, ctxs.value0.value4, ctxs.value0.value5, Leaf.value));
              }

              ;

              if (ctxs.value0 instanceof ThreeLeft && ctxs.value0.value2 instanceof Two) {
                $tco_done = true;
                return fromZipper(dictOrd)(ctxs.value1)(new Two(new Three(tree, ctxs.value0.value0, ctxs.value0.value1, ctxs.value0.value2.value0, ctxs.value0.value2.value1, ctxs.value0.value2.value2, ctxs.value0.value2.value3), ctxs.value0.value3, ctxs.value0.value4, ctxs.value0.value5));
              }

              ;

              if (ctxs.value0 instanceof ThreeMiddle && ctxs.value0.value0 instanceof Two) {
                $tco_done = true;
                return fromZipper(dictOrd)(ctxs.value1)(new Two(new Three(ctxs.value0.value0.value0, ctxs.value0.value0.value1, ctxs.value0.value0.value2, ctxs.value0.value0.value3, ctxs.value0.value1, ctxs.value0.value2, tree), ctxs.value0.value3, ctxs.value0.value4, ctxs.value0.value5));
              }

              ;

              if (ctxs.value0 instanceof ThreeMiddle && ctxs.value0.value5 instanceof Two) {
                $tco_done = true;
                return fromZipper(dictOrd)(ctxs.value1)(new Two(ctxs.value0.value0, ctxs.value0.value1, ctxs.value0.value2, new Three(tree, ctxs.value0.value3, ctxs.value0.value4, ctxs.value0.value5.value0, ctxs.value0.value5.value1, ctxs.value0.value5.value2, ctxs.value0.value5.value3)));
              }

              ;

              if (ctxs.value0 instanceof ThreeRight && ctxs.value0.value3 instanceof Two) {
                $tco_done = true;
                return fromZipper(dictOrd)(ctxs.value1)(new Two(ctxs.value0.value0, ctxs.value0.value1, ctxs.value0.value2, new Three(ctxs.value0.value3.value0, ctxs.value0.value3.value1, ctxs.value0.value3.value2, ctxs.value0.value3.value3, ctxs.value0.value4, ctxs.value0.value5, tree)));
              }

              ;

              if (ctxs.value0 instanceof ThreeLeft && ctxs.value0.value2 instanceof Three) {
                $tco_done = true;
                return fromZipper(dictOrd)(ctxs.value1)(new Three(new Two(tree, ctxs.value0.value0, ctxs.value0.value1, ctxs.value0.value2.value0), ctxs.value0.value2.value1, ctxs.value0.value2.value2, new Two(ctxs.value0.value2.value3, ctxs.value0.value2.value4, ctxs.value0.value2.value5, ctxs.value0.value2.value6), ctxs.value0.value3, ctxs.value0.value4, ctxs.value0.value5));
              }

              ;

              if (ctxs.value0 instanceof ThreeMiddle && ctxs.value0.value0 instanceof Three) {
                $tco_done = true;
                return fromZipper(dictOrd)(ctxs.value1)(new Three(new Two(ctxs.value0.value0.value0, ctxs.value0.value0.value1, ctxs.value0.value0.value2, ctxs.value0.value0.value3), ctxs.value0.value0.value4, ctxs.value0.value0.value5, new Two(ctxs.value0.value0.value6, ctxs.value0.value1, ctxs.value0.value2, tree), ctxs.value0.value3, ctxs.value0.value4, ctxs.value0.value5));
              }

              ;

              if (ctxs.value0 instanceof ThreeMiddle && ctxs.value0.value5 instanceof Three) {
                $tco_done = true;
                return fromZipper(dictOrd)(ctxs.value1)(new Three(ctxs.value0.value0, ctxs.value0.value1, ctxs.value0.value2, new Two(tree, ctxs.value0.value3, ctxs.value0.value4, ctxs.value0.value5.value0), ctxs.value0.value5.value1, ctxs.value0.value5.value2, new Two(ctxs.value0.value5.value3, ctxs.value0.value5.value4, ctxs.value0.value5.value5, ctxs.value0.value5.value6)));
              }

              ;

              if (ctxs.value0 instanceof ThreeRight && ctxs.value0.value3 instanceof Three) {
                $tco_done = true;
                return fromZipper(dictOrd)(ctxs.value1)(new Three(ctxs.value0.value0, ctxs.value0.value1, ctxs.value0.value2, new Two(ctxs.value0.value3.value0, ctxs.value0.value3.value1, ctxs.value0.value3.value2, ctxs.value0.value3.value3), ctxs.value0.value3.value4, ctxs.value0.value3.value5, new Two(ctxs.value0.value3.value6, ctxs.value0.value4, ctxs.value0.value5, tree)));
              }

              ;
              throw new Error("Failed pattern match at Data.Map.Internal (line 511, column 9 - line 528, column 136): " + [ctxs.value0.constructor.name, tree.constructor.name]);
            }

            ;
            throw new Error("Failed pattern match at Data.Map.Internal (line 508, column 5 - line 528, column 136): " + [ctxs.constructor.name]);
          }

          ;

          while (!$tco_done) {
            $tco_result = $tco_loop($tco_var_ctxs, $copy_tree);
          }

          ;
          return $tco_result;
        };
      };

      var removeMaxNode = function removeMaxNode($copy_ctx) {
        return function ($copy_m) {
          var $tco_var_ctx = $copy_ctx;
          var $tco_done = false;
          var $tco_result;

          function $tco_loop(ctx, m) {
            if (m instanceof Two && m.value0 instanceof Leaf && m.value3 instanceof Leaf) {
              $tco_done = true;
              return up(ctx)(Leaf.value);
            }

            ;

            if (m instanceof Two) {
              $tco_var_ctx = new Data_List_Types.Cons(new TwoRight(m.value0, m.value1, m.value2), ctx);
              $copy_m = m.value3;
              return;
            }

            ;

            if (m instanceof Three && m.value0 instanceof Leaf && m.value3 instanceof Leaf && m.value6 instanceof Leaf) {
              $tco_done = true;
              return up(new Data_List_Types.Cons(new TwoRight(Leaf.value, m.value1, m.value2), ctx))(Leaf.value);
            }

            ;

            if (m instanceof Three) {
              $tco_var_ctx = new Data_List_Types.Cons(new ThreeRight(m.value0, m.value1, m.value2, m.value3, m.value4, m.value5), ctx);
              $copy_m = m.value6;
              return;
            }

            ;
            throw new Error("Failed pattern match at Data.Map.Internal (line 540, column 5 - line 544, column 107): " + [m.constructor.name]);
          }

          ;

          while (!$tco_done) {
            $tco_result = $tco_loop($tco_var_ctx, $copy_m);
          }

          ;
          return $tco_result;
        };
      };

      var maxNode = function maxNode($copy_m) {
        var $tco_done = false;
        var $tco_result;

        function $tco_loop(m) {
          if (m instanceof Two && m.value3 instanceof Leaf) {
            $tco_done = true;
            return {
              key: m.value1,
              value: m.value2
            };
          }

          ;

          if (m instanceof Two) {
            $copy_m = m.value3;
            return;
          }

          ;

          if (m instanceof Three && m.value6 instanceof Leaf) {
            $tco_done = true;
            return {
              key: m.value4,
              value: m.value5
            };
          }

          ;

          if (m instanceof Three) {
            $copy_m = m.value6;
            return;
          }

          ;
          throw new Error("Failed pattern match at Data.Map.Internal (line 531, column 33 - line 535, column 45): " + [m.constructor.name]);
        }

        ;

        while (!$tco_done) {
          $tco_result = $tco_loop($copy_m);
        }

        ;
        return $tco_result;
      };

      var comp = Data_Ord.compare(dictOrd);

      var down = function down($copy_ctx) {
        return function ($copy_m) {
          var $tco_var_ctx = $copy_ctx;
          var $tco_done = false;
          var $tco_result;

          function $tco_loop(ctx, m) {
            if (m instanceof Leaf) {
              $tco_done = true;
              return Data_Maybe.Nothing.value;
            }

            ;

            if (m instanceof Two) {
              var v = comp(k)(m.value1);

              if (m.value3 instanceof Leaf && v instanceof Data_Ordering.EQ) {
                $tco_done = true;
                return new Data_Maybe.Just(new Data_Tuple.Tuple(m.value2, up(ctx)(Leaf.value)));
              }

              ;

              if (v instanceof Data_Ordering.EQ) {
                var max = maxNode(m.value0);
                $tco_done = true;
                return new Data_Maybe.Just(new Data_Tuple.Tuple(m.value2, removeMaxNode(new Data_List_Types.Cons(new TwoLeft(max.key, max.value, m.value3), ctx))(m.value0)));
              }

              ;

              if (v instanceof Data_Ordering.LT) {
                $tco_var_ctx = new Data_List_Types.Cons(new TwoLeft(m.value1, m.value2, m.value3), ctx);
                $copy_m = m.value0;
                return;
              }

              ;
              $tco_var_ctx = new Data_List_Types.Cons(new TwoRight(m.value0, m.value1, m.value2), ctx);
              $copy_m = m.value3;
              return;
            }

            ;

            if (m instanceof Three) {
              var leaves = function () {
                if (m.value0 instanceof Leaf && m.value3 instanceof Leaf && m.value6 instanceof Leaf) {
                  return true;
                }

                ;
                return false;
              }();

              var v = comp(k)(m.value4);
              var v3 = comp(k)(m.value1);

              if (leaves && v3 instanceof Data_Ordering.EQ) {
                $tco_done = true;
                return new Data_Maybe.Just(new Data_Tuple.Tuple(m.value2, fromZipper(dictOrd)(ctx)(new Two(Leaf.value, m.value4, m.value5, Leaf.value))));
              }

              ;

              if (leaves && v instanceof Data_Ordering.EQ) {
                $tco_done = true;
                return new Data_Maybe.Just(new Data_Tuple.Tuple(m.value5, fromZipper(dictOrd)(ctx)(new Two(Leaf.value, m.value1, m.value2, Leaf.value))));
              }

              ;

              if (v3 instanceof Data_Ordering.EQ) {
                var max = maxNode(m.value0);
                $tco_done = true;
                return new Data_Maybe.Just(new Data_Tuple.Tuple(m.value2, removeMaxNode(new Data_List_Types.Cons(new ThreeLeft(max.key, max.value, m.value3, m.value4, m.value5, m.value6), ctx))(m.value0)));
              }

              ;

              if (v instanceof Data_Ordering.EQ) {
                var max = maxNode(m.value3);
                $tco_done = true;
                return new Data_Maybe.Just(new Data_Tuple.Tuple(m.value5, removeMaxNode(new Data_List_Types.Cons(new ThreeMiddle(m.value0, m.value1, m.value2, max.key, max.value, m.value6), ctx))(m.value3)));
              }

              ;

              if (v3 instanceof Data_Ordering.LT) {
                $tco_var_ctx = new Data_List_Types.Cons(new ThreeLeft(m.value1, m.value2, m.value3, m.value4, m.value5, m.value6), ctx);
                $copy_m = m.value0;
                return;
              }

              ;

              if (v3 instanceof Data_Ordering.GT && v instanceof Data_Ordering.LT) {
                $tco_var_ctx = new Data_List_Types.Cons(new ThreeMiddle(m.value0, m.value1, m.value2, m.value4, m.value5, m.value6), ctx);
                $copy_m = m.value3;
                return;
              }

              ;
              $tco_var_ctx = new Data_List_Types.Cons(new ThreeRight(m.value0, m.value1, m.value2, m.value3, m.value4, m.value5), ctx);
              $copy_m = m.value6;
              return;
            }

            ;
            throw new Error("Failed pattern match at Data.Map.Internal (line 481, column 34 - line 504, column 80): " + [m.constructor.name]);
          }

          ;

          while (!$tco_done) {
            $tco_result = $tco_loop($tco_var_ctx, $copy_m);
          }

          ;
          return $tco_result;
        };
      };

      return down(Data_List_Types.Nil.value);
    };
  };

  var foldableMap = new Data_Foldable.Foldable(function (dictMonoid) {
    return function (f) {
      return function (m) {
        return Data_Foldable.foldMap(Data_List_Types.foldableList)(dictMonoid)(f)(values(m));
      };
    };
  }, function (f) {
    return function (z) {
      return function (m) {
        return Data_Foldable.foldl(Data_List_Types.foldableList)(f)(z)(values(m));
      };
    };
  }, function (f) {
    return function (z) {
      return function (m) {
        return Data_Foldable.foldr(Data_List_Types.foldableList)(f)(z)(values(m));
      };
    };
  });
  var empty = Leaf.value;

  var $$delete = function $$delete(dictOrd) {
    return function (k) {
      return function (m) {
        return Data_Maybe.maybe(m)(Data_Tuple.snd)(pop(dictOrd)(k)(m));
      };
    };
  };

  exports["empty"] = empty;
  exports["insert"] = insert;
  exports["delete"] = $$delete;
  exports["pop"] = pop;
  exports["values"] = values;
  exports["foldableMap"] = foldableMap;
})(PS["Data.Map.Internal"] = PS["Data.Map.Internal"] || {});

(function (exports) {
  // Generated by purs version 0.12.3
  "use strict";

  var Control_Category = PS["Control.Category"];
  var Control_Semigroupoid = PS["Control.Semigroupoid"];
  var Data_Newtype = PS["Data.Newtype"];
  var Prelude = PS["Prelude"];

  var Profunctor = function Profunctor(dimap) {
    this.dimap = dimap;
  };

  var dimap = function dimap(dict) {
    return dict.dimap;
  };

  exports["dimap"] = dimap;
  exports["Profunctor"] = Profunctor;
})(PS["Data.Profunctor"] = PS["Data.Profunctor"] || {});

(function (exports) {
  "use strict";

  exports.log = function (s) {
    return function () {
      console.log(s);
      return {};
    };
  };
})(PS["Effect.Console"] = PS["Effect.Console"] || {});

(function (exports) {
  // Generated by purs version 0.12.3
  "use strict";

  var $foreign = PS["Effect.Console"];
  var Data_Show = PS["Data.Show"];
  var Data_Unit = PS["Data.Unit"];
  var Effect = PS["Effect"];

  var logShow = function logShow(dictShow) {
    return function (a) {
      return $foreign.log(Data_Show.show(dictShow)(a));
    };
  };

  exports["logShow"] = logShow;
  exports["log"] = $foreign.log;
})(PS["Effect.Console"] = PS["Effect.Console"] || {});

(function (exports) {
  // Generated by purs version 0.12.3
  "use strict";

  var Control_Semigroupoid = PS["Control.Semigroupoid"];
  var Data_Function = PS["Data.Function"];
  var Data_Show = PS["Data.Show"];
  var Data_Unit = PS["Data.Unit"];
  var Effect_Class = PS["Effect.Class"];
  var Effect_Console = PS["Effect.Console"];

  var logShow = function logShow(dictMonadEffect) {
    return function (dictShow) {
      return function ($18) {
        return Effect_Class.liftEffect(dictMonadEffect)(Effect_Console.logShow(dictShow)($18));
      };
    };
  };

  exports["logShow"] = logShow;
})(PS["Effect.Class.Console"] = PS["Effect.Class.Console"] || {});

(function (exports) {
  /* globals exports, JSON */
  "use strict";

  exports.unsafeDecodeURIComponent = decodeURIComponent;
  exports.unsafeEncodeURIComponent = encodeURIComponent;
})(PS["Global.Unsafe"] = PS["Global.Unsafe"] || {});

(function (exports) {
  // Generated by purs version 0.12.3
  "use strict";

  var $foreign = PS["Global.Unsafe"];
  exports["unsafeDecodeURIComponent"] = $foreign.unsafeDecodeURIComponent;
  exports["unsafeEncodeURIComponent"] = $foreign.unsafeEncodeURIComponent;
})(PS["Global.Unsafe"] = PS["Global.Unsafe"] || {});

(function (exports) {
  exports._fetch = function (fetchImpl) {
    return function (url) {
      return function (options) {
        return function () {
          return fetchImpl(url, options).catch(function (e) {
            // we have to wrap node-fetch's non-Error error :(
            throw new Error(e);
          });
        };
      };
    };
  };

  exports.jsonImpl = function (response) {
    return function () {
      return response.json().catch(function (e) {
        throw new Error(e);
      });
    };
  };

  exports.fromRecordImpl = function (r) {
    return r;
  };
})(PS["Milkis"] = PS["Milkis"] || {});

(function (exports) {
  // Generated by purs version 0.12.3
  "use strict";

  var $foreign = PS["Milkis"];
  var Control_Promise = PS["Control.Promise"];
  var Data_ArrayBuffer_Types = PS["Data.ArrayBuffer.Types"];
  var Data_Function = PS["Data.Function"];
  var Data_Newtype = PS["Data.Newtype"];
  var Data_Show = PS["Data.Show"];
  var Effect = PS["Effect"];
  var Effect_Aff = PS["Effect.Aff"];
  var Foreign = PS["Foreign"];
  var Foreign_Object = PS["Foreign.Object"];
  var Milkis_Impl = PS["Milkis.Impl"];
  var Prelude = PS["Prelude"];
  var Type_Row = PS["Type.Row"];
  var Type_Row_Homogeneous = PS["Type.Row.Homogeneous"];
  var Unsafe_Coerce = PS["Unsafe.Coerce"];

  var URL = function URL(x) {
    return x;
  };

  var postMethod = "POST";

  var json = function json(res) {
    return Control_Promise.toAffE($foreign.jsonImpl(res));
  };

  var getMethod = "GET";

  var fromRecord = function fromRecord(dictHomogeneous) {
    return $foreign.fromRecordImpl;
  };

  var makeHeaders = function makeHeaders(dictHomogeneous) {
    return fromRecord(dictHomogeneous);
  };

  var fetch = function fetch(impl) {
    return function (dictUnion) {
      return function (url) {
        return function (opts) {
          return Control_Promise.toAffE($foreign["_fetch"](impl)(url)(opts));
        };
      };
    };
  };

  exports["URL"] = URL;
  exports["getMethod"] = getMethod;
  exports["postMethod"] = postMethod;
  exports["fetch"] = fetch;
  exports["json"] = json;
  exports["makeHeaders"] = makeHeaders;
})(PS["Milkis"] = PS["Milkis"] || {});

(function (exports) {
  exports.windowFetch = window.fetch;
})(PS["Milkis.Impl.Window"] = PS["Milkis.Impl.Window"] || {});

(function (exports) {
  // Generated by purs version 0.12.3
  "use strict";

  var $foreign = PS["Milkis.Impl.Window"];
  var Milkis_Impl = PS["Milkis.Impl"];
  exports["windowFetch"] = $foreign.windowFetch;
})(PS["Milkis.Impl.Window"] = PS["Milkis.Impl.Window"] || {});

(function (exports) {
  var Typography = require('antd').Typography;

  exports._title = Typography.Title;
  exports._text = Typography.Text;
})(PS["Optimus.Common.Client.Antd.Typography"] = PS["Optimus.Common.Client.Antd.Typography"] || {});

(function (exports) {
  "use strict";

  var React = require("react");

  var Fragment = React.Fragment || "div";

  exports.createComponent = function () {
    // Begin component prototype functions
    // (`this`-dependent, defined outside `createComponent`
    // for a slight performance boost)
    function toSelf() {
      var instance = this;

      var setStateThen = function setStateThen(update) {
        return function (effects) {
          return function () {
            instance.setState(function (state) {
              return {
                $$state: update(state.$$state)
              };
            }, effects);
          };
        };
      };

      var self = {
        props: instance.props.$$props,
        state: instance.state === null ? null : instance.state.$$state,
        setState: function setState(update) {
          return setStateThen(update)(undefined);
        },
        setStateThen: setStateThen,
        instance_: instance
      };
      return self;
    }

    function componentDidMount() {
      var didMount = this.$$spec.didMount;

      if (didMount !== undefined) {
        didMount(this.toSelf())();
      }
    }

    function shouldComponentUpdate(nextProps, nextState) {
      var shouldUpdate = this.$$spec.shouldUpdate;
      return shouldUpdate === undefined ? true : shouldUpdate(this.toSelf())({
        nextProps: nextProps.$$props,
        nextState: nextState === null ? null : nextState.$$state
      });
    }

    function componentDidUpdate(prevProps, prevState) {
      var didUpdate = this.$$spec.didUpdate;

      if (didUpdate !== undefined) {
        didUpdate(this.toSelf())({
          prevProps: prevProps.$$props,
          prevState: prevState === null ? null : prevState.$$state
        })();
      }
    }

    function componentWillUnmount() {
      this.$$mounted = false;
      var willUnmount = this.$$spec.willUnmount;

      if (willUnmount !== undefined) {
        willUnmount(this.toSelf())();
      }
    }

    function render() {
      return this.$$spec.render(this.toSelf());
    } // End component prototype functions


    return function (displayName) {
      var Component = function constructor(props) {
        this.$$mounted = true;
        this.$$spec = props.$$spec;
        this.state = // React may optimize components with no state,
        // so we leave state null if it was left as
        // the default value.
        this.$$spec.initialState === undefined ? null : {
          $$state: this.$$spec.initialState
        };
        return this;
      };

      Component.displayName = displayName;
      Component.prototype = Object.create(React.Component.prototype);
      Component.prototype.constructor = Component;
      Component.prototype.toSelf = toSelf;
      Component.prototype.shouldComponentUpdate = shouldComponentUpdate;
      Component.prototype.componentDidMount = componentDidMount;
      Component.prototype.componentDidUpdate = componentDidUpdate;
      Component.prototype.componentWillUnmount = componentWillUnmount;
      Component.prototype.render = render;
      return Component;
    };
  }();

  exports.make = function (_unionDict) {
    return function ($$type) {
      return function ($$spec) {
        var $$specPadded = {
          initialState: $$spec.initialState,
          render: $$spec.render,
          didMount: $$spec.didMount,
          shouldUpdate: $$spec.shouldUpdate,
          didUpdate: $$spec.didUpdate,
          willUnmount: $$spec.willUnmount
        };
        return function ($$props) {
          var props = {
            $$props: $$props,
            $$spec: $$specPadded
          };
          return React.createElement($$type, props);
        };
      };
    };
  };

  exports.empty = null;

  exports.keyed_ = function (key, child) {
    return React.createElement(Fragment, {
      key: key
    }, child);
  };

  exports.element_ = function (component, props) {
    return React.createElement.apply(null, [component, props].concat(props && props.children || null));
  };

  exports.fragment = function (children) {
    return React.createElement.apply(null, [Fragment, {}].concat(children));
  };
})(PS["React.Basic"] = PS["React.Basic"] || {});

(function (exports) {
  // Generated by purs version 0.12.3
  "use strict";

  var $foreign = PS["React.Basic"];
  var Data_Function_Uncurried = PS["Data.Function.Uncurried"];
  var Data_Monoid = PS["Data.Monoid"];
  var Data_Nullable = PS["Data.Nullable"];
  var Data_Semigroup = PS["Data.Semigroup"];
  var Data_Unit = PS["Data.Unit"];
  var Effect = PS["Effect"];
  var Effect_Uncurried = PS["Effect.Uncurried"];
  var Prelude = PS["Prelude"];
  var Type_Row = PS["Type.Row"];
  var semigroupJSX = new Data_Semigroup.Semigroup(function (a) {
    return function (b) {
      return $foreign.fragment([a, b]);
    };
  });
  var monoidJSX = new Data_Monoid.Monoid(function () {
    return semigroupJSX;
  }, $foreign.empty);

  var makeStateless = function makeStateless(component) {
    return function (_render) {
      return $foreign.make()(component)({
        initialState: Data_Unit.unit,
        render: function render(self) {
          return _render(self.props);
        }
      });
    };
  };

  var keyed = Data_Function_Uncurried.runFn2($foreign.keyed_);
  var element = Data_Function_Uncurried.runFn2($foreign.element_);
  exports["makeStateless"] = makeStateless;
  exports["keyed"] = keyed;
  exports["element"] = element;
  exports["semigroupJSX"] = semigroupJSX;
  exports["monoidJSX"] = monoidJSX;
  exports["createComponent"] = $foreign.createComponent;
  exports["make"] = $foreign.make;
  exports["fragment"] = $foreign.fragment;
})(PS["React.Basic"] = PS["React.Basic"] || {});

(function (exports) {
  // Generated by purs version 0.12.3
  "use strict";

  var $foreign = PS["Optimus.Common.Client.Antd.Typography"];
  var Effect = PS["Effect"];
  var Prelude = PS["Prelude"];
  var React_Basic = PS["React.Basic"];

  var title = function title(dictUnion) {
    return React_Basic.element($foreign["_title"]);
  };

  var title_ = function title_(children) {
    return title()({
      children: children
    });
  };

  var text = function text(dictUnion) {
    return React_Basic.element($foreign["_text"]);
  };

  exports["title"] = title;
  exports["title_"] = title_;
  exports["text"] = text;
})(PS["Optimus.Common.Client.Antd.Typography"] = PS["Optimus.Common.Client.Antd.Typography"] || {});

(function (exports) {
  "use strict";

  var ReactDOM = require("react-dom");

  exports.render_ = function (jsx, node, callback) {
    ReactDOM.render(jsx, node, callback);
  };
})(PS["React.Basic.DOM"] = PS["React.Basic.DOM"] || {});

(function (exports) {
  "use strict";

  exports.unsafeUnionFn = function (r1, r2) {
    var copy = {};

    for (var k1 in r2) {
      if ({}.hasOwnProperty.call(r2, k1)) {
        copy[k1] = r2[k1];
      }
    }

    for (var k2 in r1) {
      if ({}.hasOwnProperty.call(r1, k2)) {
        copy[k2] = r1[k2];
      }
    }

    return copy;
  };
})(PS["Record.Unsafe.Union"] = PS["Record.Unsafe.Union"] || {});

(function (exports) {
  // Generated by purs version 0.12.3
  "use strict";

  var $foreign = PS["Record.Unsafe.Union"];
  var Data_Function_Uncurried = PS["Data.Function.Uncurried"];
  exports["unsafeUnionFn"] = $foreign.unsafeUnionFn;
})(PS["Record.Unsafe.Union"] = PS["Record.Unsafe.Union"] || {});

(function (exports) {
  // Generated by purs version 0.12.3
  "use strict";

  var Data_Eq = PS["Data.Eq"];
  var Data_Function_Uncurried = PS["Data.Function.Uncurried"];
  var Data_HeytingAlgebra = PS["Data.HeytingAlgebra"];
  var Data_Symbol = PS["Data.Symbol"];
  var Prelude = PS["Prelude"];
  var Record_Unsafe = PS["Record.Unsafe"];
  var Record_Unsafe_Union = PS["Record.Unsafe.Union"];
  var Type_Row = PS["Type.Row"];
  var Unsafe_Coerce = PS["Unsafe.Coerce"];

  var set = function set(dictIsSymbol) {
    return function (dictCons) {
      return function (dictCons1) {
        return function (l) {
          return function (b) {
            return function (r) {
              return Record_Unsafe.unsafeSet(Data_Symbol.reflectSymbol(dictIsSymbol)(l))(b)(r);
            };
          };
        };
      };
    };
  };

  var merge = function merge(dictUnion) {
    return function (dictNub) {
      return function (l) {
        return function (r) {
          return Record_Unsafe_Union.unsafeUnionFn(l, r);
        };
      };
    };
  };

  var insert = function insert(dictIsSymbol) {
    return function (dictLacks) {
      return function (dictCons) {
        return function (l) {
          return function (a) {
            return function (r) {
              return Record_Unsafe.unsafeSet(Data_Symbol.reflectSymbol(dictIsSymbol)(l))(a)(r);
            };
          };
        };
      };
    };
  };

  var get = function get(dictIsSymbol) {
    return function (dictCons) {
      return function (l) {
        return function (r) {
          return Record_Unsafe.unsafeGet(Data_Symbol.reflectSymbol(dictIsSymbol)(l))(r);
        };
      };
    };
  };

  var $$delete = function $$delete(dictIsSymbol) {
    return function (dictLacks) {
      return function (dictCons) {
        return function (l) {
          return function (r) {
            return Record_Unsafe.unsafeDelete(Data_Symbol.reflectSymbol(dictIsSymbol)(l))(r);
          };
        };
      };
    };
  };

  exports["get"] = get;
  exports["set"] = set;
  exports["insert"] = insert;
  exports["delete"] = $$delete;
  exports["merge"] = merge;
})(PS["Record"] = PS["Record"] || {});

(function (exports) {
  // Generated by purs version 0.12.3
  "use strict";

  var Control_Category = PS["Control.Category"];
  var Control_Semigroupoid = PS["Control.Semigroupoid"];
  var Data_Function = PS["Data.Function"];
  var Data_Symbol = PS["Data.Symbol"];
  var Effect = PS["Effect"];
  var Effect_Uncurried = PS["Effect.Uncurried"];
  var Prelude = PS["Prelude"];
  var Record = PS["Record"];
  var Type_Row = PS["Type.Row"];

  var EventFn = function EventFn(x) {
    return x;
  };

  var Merge = function Merge(mergeImpl) {
    this.mergeImpl = mergeImpl;
  };

  var unsafeEventFn = EventFn;
  var semigroupoidBuilder = Control_Semigroupoid.semigroupoidFn;
  var mergeNil = new Merge(function (v) {
    return function (v1) {
      return function (v2) {
        return {};
      };
    };
  });

  var mergeImpl = function mergeImpl(dict) {
    return dict.mergeImpl;
  };

  var mergeCons = function mergeCons(dictIsSymbol) {
    return function (dictCons) {
      return function (dictCons1) {
        return function (dictLacks) {
          return function (dictLacks1) {
            return function (dictMerge) {
              return new Merge(function (v) {
                return function (fns) {
                  return function (a) {
                    var v1 = mergeImpl(dictMerge)(Type_Row.RLProxy.value)(Record["delete"](dictIsSymbol)(dictLacks)(dictCons)(Data_Symbol.SProxy.value)(fns));
                    var v2 = Record.get(dictIsSymbol)(dictCons)(Data_Symbol.SProxy.value)(fns);
                    return Record.insert(dictIsSymbol)(dictLacks1)(dictCons1)(Data_Symbol.SProxy.value)(v2(a))(v1(a));
                  };
                };
              });
            };
          };
        };
      };
    };
  };

  var merge = function merge(dictRowToList) {
    return function (dictMerge) {
      return mergeImpl(dictMerge)(Type_Row.RLProxy.value);
    };
  };

  var handler_ = function handler_($21) {
    return Effect_Uncurried.mkEffectFn1(Data_Function["const"]($21));
  };

  var handler = function handler(v) {
    return function (cb) {
      return function ($22) {
        return cb(v($22))();
      };
    };
  };

  var categoryBuilder = Control_Category.categoryFn;
  exports["unsafeEventFn"] = unsafeEventFn;
  exports["handler"] = handler;
  exports["handler_"] = handler_;
  exports["merge"] = merge;
  exports["Merge"] = Merge;
  exports["mergeImpl"] = mergeImpl;
  exports["semigroupoidBuilder"] = semigroupoidBuilder;
  exports["categoryBuilder"] = categoryBuilder;
  exports["mergeNil"] = mergeNil;
  exports["mergeCons"] = mergeCons;
})(PS["React.Basic.Events"] = PS["React.Basic.Events"] || {});

(function (exports) {
  // Generated by purs version 0.12.3
  "use strict";

  var React_Basic = PS["React.Basic"];
  var React_Basic_Events = PS["React.Basic.Events"];
  var Unsafe_Coerce = PS["Unsafe.Coerce"];
  var unsafeCreateDOMComponent = Unsafe_Coerce.unsafeCoerce;
  exports["unsafeCreateDOMComponent"] = unsafeCreateDOMComponent;
})(PS["React.Basic.DOM.Internal"] = PS["React.Basic.DOM.Internal"] || {});

(function (exports) {
  // Generated by purs version 0.12.3
  "use strict";

  var React_Basic = PS["React.Basic"];
  var React_Basic_DOM_Internal = PS["React.Basic.DOM.Internal"];
  var React_Basic_Events = PS["React.Basic.Events"];

  var span = function span(dictUnion) {
    return React_Basic.element(React_Basic_DOM_Internal.unsafeCreateDOMComponent("span"));
  };

  var span_ = function span_(children) {
    return span()({
      children: children
    });
  };

  var label = function label(dictUnion) {
    return React_Basic.element(React_Basic_DOM_Internal.unsafeCreateDOMComponent("label"));
  };

  var label_ = function label_(children) {
    return label()({
      children: children
    });
  };

  var img = function img(dictUnion) {
    return React_Basic.element(React_Basic_DOM_Internal.unsafeCreateDOMComponent("img"));
  };

  var form = function form(dictUnion) {
    return React_Basic.element(React_Basic_DOM_Internal.unsafeCreateDOMComponent("form"));
  };

  var div = function div(dictUnion) {
    return React_Basic.element(React_Basic_DOM_Internal.unsafeCreateDOMComponent("div"));
  };

  var div_ = function div_(children) {
    return div()({
      children: children
    });
  };

  var br = function br(dictUnion) {
    return React_Basic.element(React_Basic_DOM_Internal.unsafeCreateDOMComponent("br"));
  };

  var a = function a(dictUnion) {
    return React_Basic.element(React_Basic_DOM_Internal.unsafeCreateDOMComponent("a"));
  };

  exports["a"] = a;
  exports["br"] = br;
  exports["div"] = div;
  exports["div_"] = div_;
  exports["form"] = form;
  exports["img"] = img;
  exports["label"] = label;
  exports["label_"] = label_;
  exports["span"] = span;
  exports["span_"] = span_;
})(PS["React.Basic.DOM.Generated"] = PS["React.Basic.DOM.Generated"] || {});

(function (exports) {
  // Generated by purs version 0.12.3
  "use strict";

  var $foreign = PS["React.Basic.DOM"];
  var Control_Applicative = PS["Control.Applicative"];
  var Control_Bind = PS["Control.Bind"];
  var Data_Either = PS["Data.Either"];
  var Data_Function_Uncurried = PS["Data.Function.Uncurried"];
  var Data_Maybe = PS["Data.Maybe"];
  var Data_Nullable = PS["Data.Nullable"];
  var Data_Unit = PS["Data.Unit"];
  var Effect = PS["Effect"];
  var Effect_Exception = PS["Effect.Exception"];
  var Effect_Uncurried = PS["Effect.Uncurried"];
  var Prelude = PS["Prelude"];
  var React_Basic = PS["React.Basic"];
  var React_Basic_DOM_Generated = PS["React.Basic.DOM.Generated"];
  var React_Basic_DOM_Internal = PS["React.Basic.DOM.Internal"];
  var Unsafe_Coerce = PS["Unsafe.Coerce"];
  var Web_DOM = PS["Web.DOM"];
  var text = Unsafe_Coerce.unsafeCoerce;
  var render$prime = Effect_Uncurried.runEffectFn3($foreign.render_);

  var render = function render(jsx) {
    return function (node) {
      return render$prime(jsx)(node)(Control_Applicative.pure(Effect.applicativeEffect)(Data_Unit.unit));
    };
  };

  var css = Unsafe_Coerce.unsafeCoerce;
  exports["render"] = render;
  exports["text"] = text;
  exports["css"] = css;
})(PS["React.Basic.DOM"] = PS["React.Basic.DOM"] || {});

(function (exports) {
  // Generated by purs version 0.12.3
  "use strict";

  var Optimus_Common_Client_Antd = PS["Optimus.Common.Client.Antd"];
  var Optimus_Common_Client_Antd_Typography = PS["Optimus.Common.Client.Antd.Typography"];
  var React_Basic = PS["React.Basic"];
  var React_Basic_DOM = PS["React.Basic.DOM"];
  var React_Basic_DOM_Generated = PS["React.Basic.DOM.Generated"];

  var content = function content(v) {
    return React_Basic_DOM_Generated.div()({
      className: "mw-xl mx-auto",
      children: [Optimus_Common_Client_Antd_Typography.title()({
        className: "px-3 pt-2",
        level: 2,
        children: [React_Basic_DOM.text(v.title)]
      }), React_Basic_DOM_Generated.div()({
        children: v.children
      })]
    });
  };

  exports["content"] = content;
})(PS["Optimus.Client.Component.Content"] = PS["Optimus.Client.Component.Content"] || {});

(function (exports) {
  exports._row = require('antd').Row;
  exports._col = require('antd').Col;
})(PS["Optimus.Common.Client.Antd.Grid"] = PS["Optimus.Common.Client.Antd.Grid"] || {});

(function (exports) {
  // Generated by purs version 0.12.3
  "use strict";

  var $foreign = PS["Optimus.Common.Client.Antd.Grid"];
  var React_Basic = PS["React.Basic"];
  var React_Basic_DOM = PS["React.Basic.DOM"];

  var row = function row(dictUnion) {
    return React_Basic.element($foreign["_row"]);
  };

  var row_ = function row_(children) {
    return row()({
      children: children
    });
  };

  var col = function col(dictUnion) {
    return React_Basic.element($foreign["_col"]);
  };

  exports["row"] = row;
  exports["row_"] = row_;
  exports["col"] = col;
})(PS["Optimus.Common.Client.Antd.Grid"] = PS["Optimus.Common.Client.Antd.Grid"] || {});

(function (exports) {
  // Generated by purs version 0.12.3
  "use strict";

  var Data_EuclideanRing = PS["Data.EuclideanRing"];
  var Data_Functor = PS["Data.Functor"];
  var Data_Semigroup = PS["Data.Semigroup"];
  var Data_Semiring = PS["Data.Semiring"];
  var Data_Show = PS["Data.Show"];
  var Optimus_Common_Client_Antd = PS["Optimus.Common.Client.Antd"];
  var Optimus_Common_Client_Antd_Grid = PS["Optimus.Common.Client.Antd.Grid"];
  var Prelude = PS["Prelude"];
  var React_Basic = PS["React.Basic"];

  var grid = function grid(v) {
    var wrapCol = function wrapCol(item) {
      return Optimus_Common_Client_Antd_Grid.col()({
        xs: Data_EuclideanRing.div(Data_EuclideanRing.euclideanRingInt)(24)(v.rowItemCount.xs),
        sm: Data_EuclideanRing.div(Data_EuclideanRing.euclideanRingInt)(24)(v.rowItemCount.sm),
        md: Data_EuclideanRing.div(Data_EuclideanRing.euclideanRingInt)(24)(v.rowItemCount.md),
        lg: Data_EuclideanRing.div(Data_EuclideanRing.euclideanRingInt)(24)(v.rowItemCount.lg),
        xl: Data_EuclideanRing.div(Data_EuclideanRing.euclideanRingInt)(24)(v.rowItemCount.xl),
        className: "mb-" + Data_Show.show(Data_Show.showInt)(v.gutter),
        children: [item]
      });
    };

    return Optimus_Common_Client_Antd_Grid.row()({
      className: v.className,
      gutter: v.gutter * 4 | 0,
      children: Data_Functor.mapFlipped(Data_Functor.functorArray)(v.items)(wrapCol)
    });
  };

  exports["grid"] = grid;
})(PS["Optimus.Client.Component.Grid"] = PS["Optimus.Client.Component.Grid"] || {});

(function (exports) {
  // Generated by purs version 0.12.3
  "use strict";

  var Type_Prelude = PS["Type.Prelude"];

  var ConsPrefix = function ConsPrefix(consPrefix) {
    this.consPrefix = consPrefix;
  };

  var consPrefix = function consPrefix(dict) {
    return dict.consPrefix;
  };

  exports["ConsPrefix"] = ConsPrefix;
  exports["consPrefix"] = consPrefix;
})(PS["ZRPC.ConsPrefix"] = PS["ZRPC.ConsPrefix"] || {});

(function (exports) {
  "use strict";

  exports.copyRecord = function (rec) {
    var copy = {};

    for (var key in rec) {
      if ({}.hasOwnProperty.call(rec, key)) {
        copy[key] = rec[key];
      }
    }

    return copy;
  };

  exports.unsafeInsert = function (l) {
    return function (a) {
      return function (rec) {
        rec[l] = a;
        return rec;
      };
    };
  };
})(PS["Record.Builder"] = PS["Record.Builder"] || {});

(function (exports) {
  // Generated by purs version 0.12.3
  "use strict";

  var $foreign = PS["Record.Builder"];
  var Control_Category = PS["Control.Category"];
  var Control_Semigroupoid = PS["Control.Semigroupoid"];
  var Data_Function_Uncurried = PS["Data.Function.Uncurried"];
  var Data_Symbol = PS["Data.Symbol"];
  var Prelude = PS["Prelude"];
  var Record_Unsafe_Union = PS["Record.Unsafe.Union"];
  var Type_Row = PS["Type.Row"];
  var Unsafe_Coerce = PS["Unsafe.Coerce"];
  var semigroupoidBuilder = Control_Semigroupoid.semigroupoidFn;

  var insert = function insert(dictCons) {
    return function (dictLacks) {
      return function (dictIsSymbol) {
        return function (l) {
          return function (a) {
            return function (r1) {
              return $foreign.unsafeInsert(Data_Symbol.reflectSymbol(dictIsSymbol)(l))(a)(r1);
            };
          };
        };
      };
    };
  };

  var categoryBuilder = Control_Category.categoryFn;

  var build = function build(v) {
    return function (r1) {
      return v($foreign.copyRecord(r1));
    };
  };

  exports["build"] = build;
  exports["insert"] = insert;
  exports["semigroupoidBuilder"] = semigroupoidBuilder;
  exports["categoryBuilder"] = categoryBuilder;
})(PS["Record.Builder"] = PS["Record.Builder"] || {});

(function (exports) {
  // Generated by purs version 0.12.3
  "use strict";

  var Control_Applicative = PS["Control.Applicative"];
  var Control_Apply = PS["Control.Apply"];
  var Control_Bind = PS["Control.Bind"];
  var Control_Monad = PS["Control.Monad"];
  var Data_BooleanAlgebra = PS["Data.BooleanAlgebra"];
  var Data_Bounded = PS["Data.Bounded"];
  var Data_CommutativeRing = PS["Data.CommutativeRing"];
  var Data_Eq = PS["Data.Eq"];
  var Data_Functor = PS["Data.Functor"];
  var Data_HeytingAlgebra = PS["Data.HeytingAlgebra"];
  var Data_Ord = PS["Data.Ord"];
  var Data_Ordering = PS["Data.Ordering"];
  var Data_Ring = PS["Data.Ring"];
  var Data_Semigroup = PS["Data.Semigroup"];
  var Data_Semiring = PS["Data.Semiring"];
  var Data_Show = PS["Data.Show"];
  var Prelude = PS["Prelude"];

  var $$Proxy = function () {
    function $$Proxy() {}

    ;
    $$Proxy.value = new $$Proxy();
    return $$Proxy;
  }();

  exports["Proxy"] = $$Proxy;
})(PS["Type.Proxy"] = PS["Type.Proxy"] || {});

(function (exports) {
  // Generated by purs version 0.12.3
  "use strict";

  var Data_Bounded = PS["Data.Bounded"];
  var Data_Date = PS["Data.Date"];
  var Data_DateTime = PS["Data.DateTime"];
  var Data_Eq = PS["Data.Eq"];
  var Data_Function = PS["Data.Function"];
  var Data_Maybe = PS["Data.Maybe"];
  var Data_Ord = PS["Data.Ord"];
  var Data_Semigroup = PS["Data.Semigroup"];
  var Data_Show = PS["Data.Show"];
  var Data_Time = PS["Data.Time"];
  var Data_Time_Duration = PS["Data.Time.Duration"];
  var Prelude = PS["Prelude"];
  var Unsafe_Coerce = PS["Unsafe.Coerce"];

  var toUTCDateTime = function toUTCDateTime(v) {
    return v;
  };

  var localDateTime = function localDateTime(d) {
    return function (t) {
      return new Data_DateTime.DateTime(d, t);
    };
  };

  exports["localDateTime"] = localDateTime;
  exports["toUTCDateTime"] = toUTCDateTime;
})(PS["ZRPC.Types.LocalDateTime"] = PS["ZRPC.Types.LocalDateTime"] || {});

(function (exports) {
  // Generated by purs version 0.12.3
  "use strict";

  var Data_Eq = PS["Data.Eq"];
  var Data_Long = PS["Data.Long"];
  var Data_Long_Internal = PS["Data.Long.Internal"];
  var Data_Monoid = PS["Data.Monoid"];
  var Data_Newtype = PS["Data.Newtype"];
  var Data_Ord = PS["Data.Ord"];
  var Data_Semigroup = PS["Data.Semigroup"];
  var Data_Semiring = PS["Data.Semiring"];
  var Data_Show = PS["Data.Show"];
  var Prelude = PS["Prelude"];

  var Millis = function Millis(x) {
    return x;
  };

  var showMillis = new Data_Show.Show(function (v) {
    return Data_Long.toString(v) + "ms";
  });
  var newtypeMillis = new Data_Newtype.Newtype(function (n) {
    return n;
  }, Millis);
  var semigroupMillis = new Data_Semigroup.Semigroup(Data_Newtype.over2(newtypeMillis)(newtypeMillis)(Millis)(Data_Semiring.add(Data_Long_Internal["semiringLong'Signed"])));
  var monoidMillis = new Data_Monoid.Monoid(function () {
    return semigroupMillis;
  }, Data_Newtype.wrap(newtypeMillis)(Data_Semiring.zero(Data_Long_Internal["semiringLong'Signed"])));
  exports["Millis"] = Millis;
  exports["newtypeMillis"] = newtypeMillis;
  exports["showMillis"] = showMillis;
  exports["semigroupMillis"] = semigroupMillis;
  exports["monoidMillis"] = monoidMillis;
})(PS["ZRPC.Types.Millis"] = PS["ZRPC.Types.Millis"] || {});

(function (exports) {
  // Generated by purs version 0.12.3
  "use strict";

  var Control_Alt = PS["Control.Alt"];
  var Control_Applicative = PS["Control.Applicative"];
  var Control_Apply = PS["Control.Apply"];
  var Control_Bind = PS["Control.Bind"];
  var Control_Category = PS["Control.Category"];
  var Control_Monad_Except = PS["Control.Monad.Except"];
  var Control_Monad_Except_Trans = PS["Control.Monad.Except.Trans"];
  var Control_Semigroupoid = PS["Control.Semigroupoid"];
  var Data_Argonaut_Core = PS["Data.Argonaut.Core"];
  var Data_Boolean = PS["Data.Boolean"];
  var Data_Bounded = PS["Data.Bounded"];
  var Data_Date = PS["Data.Date"];
  var Data_Date_Component = PS["Data.Date.Component"];
  var Data_DateTime = PS["Data.DateTime"];
  var Data_Either = PS["Data.Either"];
  var Data_Enum = PS["Data.Enum"];
  var Data_Eq = PS["Data.Eq"];
  var Data_Function = PS["Data.Function"];
  var Data_Functor = PS["Data.Functor"];
  var Data_Generic_Rep = PS["Data.Generic.Rep"];
  var Data_HugeNum = PS["Data.HugeNum"];
  var Data_Identity = PS["Data.Identity"];
  var Data_Int = PS["Data.Int"];
  var Data_List_Types = PS["Data.List.Types"];
  var Data_Long = PS["Data.Long"];
  var Data_Maybe = PS["Data.Maybe"];
  var Data_Semigroup = PS["Data.Semigroup"];
  var Data_String = PS["Data.String"];
  var Data_String_Common = PS["Data.String.Common"];
  var Data_String_Pattern = PS["Data.String.Pattern"];
  var Data_Symbol = PS["Data.Symbol"];
  var Data_Time = PS["Data.Time"];
  var Data_Time_Component = PS["Data.Time.Component"];
  var Data_Traversable = PS["Data.Traversable"];
  var Data_Unit = PS["Data.Unit"];
  var Foreign = PS["Foreign"];
  var Foreign_Object = PS["Foreign.Object"];
  var Prelude = PS["Prelude"];
  var Record_Builder = PS["Record.Builder"];
  var Type_Data_RowList = PS["Type.Data.RowList"];
  var Type_Proxy = PS["Type.Proxy"];
  var ZRPC_ConsPrefix = PS["ZRPC.ConsPrefix"];
  var ZRPC_Types_LocalDateTime = PS["ZRPC.Types.LocalDateTime"];
  var ZRPC_Types_Millis = PS["ZRPC.Types.Millis"];
  var ZRPC_Types_ZJson = PS["ZRPC.Types.ZJson"];

  var ValF = function ValF(x) {
    return x;
  };

  var Null = function () {
    function Null() {}

    ;
    Null.value = new Null();
    return Null;
  }();

  var Tag = function Tag(tag) {
    this.tag = tag;
  };

  var ZRPCRead = function ZRPCRead(zrpcRead) {
    this.zrpcRead = zrpcRead;
  };

  var ZRPCReadField = function ZRPCReadField(zrpcReadField) {
    this.zrpcReadField = zrpcReadField;
  };

  var ZRPCReadFields = function ZRPCReadFields(zrpcReadFields) {
    this.zrpcReadFields = zrpcReadFields;
  };

  var ZRPCReadRep = function ZRPCReadRep(zrpcReadRep) {
    this.zrpcReadRep = zrpcReadRep;
  };

  var zrpcReadUnit = new ZRPCRead(function (v) {
    return Control_Applicative.pure(Control_Monad_Except_Trans.applicativeExceptT(Data_Identity.monadIdentity))(Data_Unit.unit);
  });

  var zrpcReadRep = function zrpcReadRep(dict) {
    return dict.zrpcReadRep;
  };

  var zrpcReadFieldsNil = new ZRPCReadFields(function (v) {
    return function (v1) {
      return Control_Applicative.pure(Control_Monad_Except_Trans.applicativeExceptT(Data_Identity.monadIdentity))(Control_Category.identity(Record_Builder.categoryBuilder));
    };
  });

  var zrpcReadFields = function zrpcReadFields(dict) {
    return dict.zrpcReadFields;
  };

  var zrpcReadField = function zrpcReadField(dict) {
    return dict.zrpcReadField;
  };

  var zrpcRead = function zrpcRead(dict) {
    return dict.zrpcRead;
  };

  var unValF = function unValF(v) {
    return v;
  };

  var typeExpE = function typeExpE(exp) {
    return function (act) {
      return Foreign.fail(new Foreign.TypeMismatch(exp, act));
    };
  };

  var tagString = new Tag(function (v) {
    return "String";
  });
  var tagObject = new Tag(function (v) {
    return "Object";
  });
  var tagNumber = new Tag(function (v) {
    return "Number";
  });
  var tagNull = new Tag(function (v) {
    return "Null";
  });
  var tagLong = new Tag(function (v) {
    return "Long";
  });
  var tagInt = new Tag(function (v) {
    return "Int";
  });
  var tagHugeNum = new Tag(function (v) {
    return "BigDecimal";
  });
  var tagDateTime = new Tag(function (v) {
    return "DateTime";
  });
  var tagDate = new Tag(function (v) {
    return "LocalDate";
  });
  var tagBoolean = new Tag(function (v) {
    return "Boolean";
  });
  var tagArray = new Tag(function (v) {
    return "Array";
  });

  var tag = function tag(dict) {
    return dict.tag;
  };

  var readRepSum = function readRepSum(dictZRPCReadRep) {
    return function (dictZRPCReadRep1) {
      return new ZRPCReadRep(function (f) {
        return function (name) {
          return Control_Alt.alt(Control_Monad_Except_Trans.altExceptT(Data_List_Types.semigroupNonEmptyList)(Data_Identity.monadIdentity))(Data_Functor.map(Control_Monad_Except_Trans.functorExceptT(Data_Identity.functorIdentity))(Data_Generic_Rep.Inl.create)(zrpcReadRep(dictZRPCReadRep)(f)(name)))(Data_Functor.map(Control_Monad_Except_Trans.functorExceptT(Data_Identity.functorIdentity))(Data_Generic_Rep.Inr.create)(zrpcReadRep(dictZRPCReadRep1)(f)(name)));
        };
      });
    };
  };

  var readPropWith$prime = function readPropWith$prime(name) {
    return function (r) {
      return function (o) {
        var v = Foreign_Object.lookup(name)(o);
        return Control_Monad_Except.withExcept(Data_Functor.map(Data_List_Types.functorNonEmptyList)(Foreign.ErrorAtProperty.create(name)))(r(v));
      };
    };
  };

  var parseTimeParts = function parseTimeParts(h$prime) {
    return function (m$prime) {
      return function (s$prime) {
        return function (msM) {
          var msM$prime = function () {
            if (msM instanceof Data_Maybe.Just) {
              return Control_Bind.bind(Data_Maybe.bindMaybe)(Data_Int.fromString(msM.value0))(Data_Enum.toEnum(Data_Time_Component.boundedEnumMillisecond));
            }

            ;

            if (msM instanceof Data_Maybe.Nothing) {
              return Control_Applicative.pure(Data_Maybe.applicativeMaybe)(Data_Bounded.bottom(Data_Time_Component.boundedMillisecond));
            }

            ;
            throw new Error("Failed pattern match at ZRPC.ZRPCRead (line 318, column 12 - line 320, column 29): " + [msM.constructor.name]);
          }();

          return Control_Apply.apply(Data_Maybe.applyMaybe)(Control_Apply.apply(Data_Maybe.applyMaybe)(Control_Apply.apply(Data_Maybe.applyMaybe)(Data_Functor.map(Data_Maybe.functorMaybe)(Data_Time.Time.create)(Control_Bind.bind(Data_Maybe.bindMaybe)(Data_Int.fromString(h$prime))(Data_Enum.toEnum(Data_Time_Component.boundedEnumHour))))(Control_Bind.bind(Data_Maybe.bindMaybe)(Data_Int.fromString(m$prime))(Data_Enum.toEnum(Data_Time_Component.boundedEnumMinute))))(Control_Bind.bind(Data_Maybe.bindMaybe)(Data_Int.fromString(s$prime))(Data_Enum.toEnum(Data_Time_Component.boundedEnumSecond))))(msM$prime);
        };
      };
    };
  };

  var stringToTime = function stringToTime(s) {
    var v = Data_String_Common.split(":")(s);

    if (v.length === 3) {
      var v1 = Data_String_Common.split(".")(v[2]);

      if (v1.length === 2) {
        return parseTimeParts(v[0])(v[1])(v1[0])(new Data_Maybe.Just(v1[1]));
      }

      ;
      return parseTimeParts(v[0])(v[1])(v[2])(Data_Maybe.Nothing.value);
    }

    ;
    return Data_Maybe.Nothing.value;
  };

  var parseDateParts = function parseDateParts(y$prime) {
    return function (m$prime) {
      return function (d$prime) {
        return Control_Apply.apply(Data_Maybe.applyMaybe)(Control_Apply.apply(Data_Maybe.applyMaybe)(Data_Functor.map(Data_Maybe.functorMaybe)(Data_Date.canonicalDate)(Control_Bind.bind(Data_Maybe.bindMaybe)(Data_Int.fromString(y$prime))(Data_Enum.toEnum(Data_Date_Component.boundedEnumYear))))(Control_Bind.bind(Data_Maybe.bindMaybe)(Data_Int.fromString(m$prime))(Data_Enum.toEnum(Data_Date_Component.boundedEnumMonth))))(Control_Bind.bind(Data_Maybe.bindMaybe)(Data_Int.fromString(d$prime))(Data_Enum.toEnum(Data_Date_Component.boundedEnumDay)));
      };
    };
  };

  var stringToDate = function stringToDate(s) {
    var v = Data_String_Common.split("-")(s);

    if (v.length === 3) {
      return parseDateParts(v[0])(v[1])(v[2]);
    }

    ;
    return Data_Maybe.Nothing.value;
  };

  var stringToLocalDateTime = function stringToLocalDateTime(s) {
    var v = Data_String_Common.split("T")(s);

    if (v.length === 2) {
      return Control_Apply.apply(Data_Maybe.applyMaybe)(Data_Functor.map(Data_Maybe.functorMaybe)(ZRPC_Types_LocalDateTime.localDateTime)(stringToDate(v[0])))(stringToTime(v[1]));
    }

    ;
    return Data_Maybe.Nothing.value;
  };

  var stringToDateTime = function stringToDateTime(s) {
    var v = Data_String_Common.split("Z")(s);

    if (v.length === 2 && v[1] === "") {
      return Data_Functor.map(Data_Maybe.functorMaybe)(ZRPC_Types_LocalDateTime.toUTCDateTime)(stringToLocalDateTime(v[0]));
    }

    ;
    return Data_Maybe.Nothing.value;
  };

  var nullAsMaybe = function nullAsMaybe(j) {
    var $89 = Data_Argonaut_Core.isNull(j);

    if ($89) {
      return Data_Maybe.Nothing.value;
    }

    ;
    return new Data_Maybe.Just(j);
  };

  var zrpcReadField$prime = function zrpcReadField$prime(dictZRPCReadField) {
    return function ($115) {
      return zrpcReadField(dictZRPCReadField)(nullAsMaybe($115));
    };
  };

  var missingError = new Foreign.ForeignError("missing");

  var readPropWith = function readPropWith(name) {
    return function (r) {
      return readPropWith$prime(name)(function (x) {
        if (x instanceof Data_Maybe.Just) {
          return r(x.value0);
        }

        ;

        if (x instanceof Data_Maybe.Nothing) {
          return Foreign.fail(new Foreign.ErrorAtProperty(name, missingError));
        }

        ;
        throw new Error("Failed pattern match at ZRPC.ZRPCRead (line 377, column 28 - line 380, column 47): " + [x.constructor.name]);
      });
    };
  };

  var zrpcReadFieldId = function zrpcReadFieldId(dictZRPCRead) {
    return new ZRPCReadField(function (v) {
      if (v instanceof Data_Maybe.Just) {
        return zrpcRead(dictZRPCRead)(v.value0);
      }

      ;

      if (v instanceof Data_Maybe.Nothing) {
        return Foreign.fail(missingError);
      }

      ;
      throw new Error("Failed pattern match at ZRPC.ZRPCRead (line 222, column 6 - line 222, column 65): " + [v.constructor.name]);
    });
  };

  var functorValF = Control_Monad_Except_Trans.functorExceptT(Data_Identity.functorIdentity);

  var failUnknownType = function failUnknownType(s) {
    return Foreign.fail(Foreign.ForeignError.create("unknown type: " + s));
  };

  var readRepNoArgs = function readRepNoArgs(dictIsSymbol) {
    return new ZRPCReadRep(function (v) {
      return function (name) {
        if (name === Data_Symbol.reflectSymbol(dictIsSymbol)(Data_Symbol.SProxy.value)) {
          return Control_Applicative.pure(Control_Monad_Except_Trans.applicativeExceptT(Data_Identity.monadIdentity))(Data_Generic_Rep.NoArguments.value);
        }

        ;

        if (Data_Boolean.otherwise) {
          return failUnknownType(name);
        }

        ;
        throw new Error("Failed pattern match at ZRPC.ZRPCRead (line 270, column 1 - line 270, column 86): " + [v.constructor.name, name.constructor.name]);
      };
    });
  };

  var readRepRecArg = function readRepRecArg(dictIsSymbol) {
    return function (dictZRPCRead) {
      return new ZRPCReadRep(function (f) {
        return function (name) {
          if (name === Data_Symbol.reflectSymbol(dictIsSymbol)(Data_Symbol.SProxy.value)) {
            return Data_Functor.map(Control_Monad_Except_Trans.functorExceptT(Data_Identity.functorIdentity))(function ($116) {
              return Data_Generic_Rep.Constructor(Data_Generic_Rep.Argument($116));
            })(zrpcRead(dictZRPCRead)(f));
          }

          ;

          if (Data_Boolean.otherwise) {
            return failUnknownType(name);
          }

          ;
          throw new Error("Failed pattern match at ZRPC.ZRPCRead (line 275, column 1 - line 275, column 105): " + [f.constructor.name, name.constructor.name]);
        };
      });
    };
  };

  var failInvalidFormat = function failInvalidFormat(s) {
    return Foreign.fail(Foreign.ForeignError.create("not a valid " + (s + " format")));
  };

  var noteFormat = function noteFormat(dictTag) {
    return function (m) {
      if (m instanceof Data_Maybe.Just) {
        return Control_Applicative.pure(Control_Monad_Except_Trans.applicativeExceptT(Data_Identity.monadIdentity))(m.value0);
      }

      ;

      if (m instanceof Data_Maybe.Nothing) {
        return failInvalidFormat(tag(dictTag)(Type_Proxy["Proxy"].value));
      }

      ;
      throw new Error("Failed pattern match at ZRPC.ZRPCRead (line 349, column 16 - line 351, column 39): " + [m.constructor.name]);
    };
  };

  var readFormat = function readFormat(dictZRPCRead) {
    return function (dictTag) {
      return function (r) {
        return Control_Bind.composeKleisli(Control_Monad_Except_Trans.bindExceptT(Data_Identity.monadIdentity))(zrpcRead(dictZRPCRead))(function ($117) {
          return noteFormat(dictTag)(r($117));
        });
      };
    };
  };

  var caseJson$prime = function caseJson$prime(h) {
    return Data_Argonaut_Core.caseJson(h.jreadNull)(h.jreadBoolean)(h.jreadNumber)(h.jreadString)(h.jreadArray)(h.jreadObject);
  };

  var bh = function bh(dictTag) {
    var exp = tag(dictTag)(Type_Proxy["Proxy"].value);

    var e = function e(dictTag1) {
      return function (v) {
        return typeExpE(exp)(tag(dictTag1)(Type_Proxy["Proxy"].value));
      };
    };

    return {
      jreadNull: function jreadNull(v) {
        return e(tagNull)(Null.value);
      },
      jreadBoolean: e(tagBoolean),
      jreadNumber: e(tagNumber),
      jreadString: e(tagString),
      jreadArray: e(tagArray),
      jreadObject: e(tagObject)
    };
  };

  var readArray = caseJson$prime({
    jreadNull: bh(tagArray).jreadNull,
    jreadBoolean: bh(tagArray).jreadBoolean,
    jreadNumber: bh(tagArray).jreadNumber,
    jreadString: bh(tagArray).jreadString,
    jreadArray: Control_Applicative.pure(Control_Monad_Except_Trans.applicativeExceptT(Data_Identity.monadIdentity)),
    jreadObject: bh(tagArray).jreadObject
  });

  var zrpcReadArray = function zrpcReadArray(dictZRPCReadField) {
    return new ZRPCRead(function (f) {
      return Control_Bind.bind(Control_Monad_Except_Trans.bindExceptT(Data_Identity.monadIdentity))(readArray(f))(Data_Traversable.traverse(Data_Traversable.traversableArray)(Control_Monad_Except_Trans.applicativeExceptT(Data_Identity.monadIdentity))(zrpcReadField$prime(dictZRPCReadField)));
    });
  };

  var readNull = caseJson$prime({
    jreadNull: function jreadNull(v1) {
      return Control_Applicative.pure(Control_Monad_Except_Trans.applicativeExceptT(Data_Identity.monadIdentity))(Null.value);
    },
    jreadBoolean: bh(tagNull).jreadBoolean,
    jreadNumber: bh(tagNull).jreadNumber,
    jreadString: bh(tagNull).jreadString,
    jreadArray: bh(tagNull).jreadArray,
    jreadObject: bh(tagNull).jreadObject
  });

  var zrpcReadFieldMaybe = function zrpcReadFieldMaybe(dictZRPCRead) {
    return new ZRPCReadField(function (v) {
      if (v instanceof Data_Maybe.Just) {
        return Control_Alt.alt(Control_Monad_Except_Trans.altExceptT(Data_List_Types.semigroupNonEmptyList)(Data_Identity.monadIdentity))(Data_Functor.map(Control_Monad_Except_Trans.functorExceptT(Data_Identity.functorIdentity))(Data_Maybe.Just.create)(zrpcRead(dictZRPCRead)(v.value0)))(Data_Functor.voidLeft(Control_Monad_Except_Trans.functorExceptT(Data_Identity.functorIdentity))(readNull(v.value0))(Data_Maybe.Nothing.value));
      }

      ;

      if (v instanceof Data_Maybe.Nothing) {
        return Control_Applicative.pure(Control_Monad_Except_Trans.applicativeExceptT(Data_Identity.monadIdentity))(Data_Maybe.Nothing.value);
      }

      ;
      throw new Error("Failed pattern match at ZRPC.ZRPCRead (line 219, column 1 - line 219, column 71): " + [v.constructor.name]);
    });
  };

  var readObject = caseJson$prime({
    jreadNull: bh(tagObject).jreadNull,
    jreadBoolean: bh(tagObject).jreadBoolean,
    jreadNumber: bh(tagObject).jreadNumber,
    jreadString: bh(tagObject).jreadString,
    jreadArray: bh(tagObject).jreadArray,
    jreadObject: Control_Applicative.pure(Control_Monad_Except_Trans.applicativeExceptT(Data_Identity.monadIdentity))
  });

  var readRecord = function readRecord(dictRowToList) {
    return function (dictZRPCReadFields) {
      return new ZRPCRead(function (f) {
        return Data_Functor.mapFlipped(Control_Monad_Except_Trans.functorExceptT(Data_Identity.functorIdentity))(Control_Bind.bind(Control_Monad_Except_Trans.bindExceptT(Data_Identity.monadIdentity))(readObject(f))(zrpcReadFields(dictZRPCReadFields)(Type_Data_RowList.RLProxy.value)))(Data_Function.flip(Record_Builder.build)({}));
      });
    };
  };

  var zrpcReadEither = function zrpcReadEither(dictZRPCReadField) {
    return function (dictZRPCReadField1) {
      return new ZRPCRead(function (f) {
        var readRight = function readRight(o) {
          return Data_Functor.map(Control_Monad_Except_Trans.functorExceptT(Data_Identity.functorIdentity))(Data_Either.Right.create)(readPropWith("Right")(zrpcReadField$prime(dictZRPCReadField1))(o));
        };

        var readLeft = function readLeft(o) {
          return Data_Functor.map(Control_Monad_Except_Trans.functorExceptT(Data_Identity.functorIdentity))(Data_Either.Left.create)(readPropWith("Left")(zrpcReadField$prime(dictZRPCReadField))(o));
        };

        return Control_Bind.bind(Control_Monad_Except_Trans.bindExceptT(Data_Identity.monadIdentity))(readObject(f))(function (o) {
          return Control_Alt.alt(Control_Monad_Except_Trans.altExceptT(Data_List_Types.semigroupNonEmptyList)(Data_Identity.monadIdentity))(readRight(o))(readLeft(o));
        });
      });
    };
  };

  var zrpcReadBoolean = new ZRPCRead(caseJson$prime({
    jreadNull: bh(tagBoolean).jreadNull,
    jreadBoolean: Control_Applicative.pure(Control_Monad_Except_Trans.applicativeExceptT(Data_Identity.monadIdentity)),
    jreadNumber: bh(tagBoolean).jreadNumber,
    jreadString: bh(tagBoolean).jreadString,
    jreadArray: bh(tagBoolean).jreadArray,
    jreadObject: bh(tagBoolean).jreadObject
  }));
  var zrpcReadNumber = new ZRPCRead(caseJson$prime({
    jreadNull: bh(tagNumber).jreadNull,
    jreadBoolean: bh(tagNumber).jreadBoolean,
    jreadNumber: Control_Applicative.pure(Control_Monad_Except_Trans.applicativeExceptT(Data_Identity.monadIdentity)),
    jreadString: bh(tagNumber).jreadString,
    jreadArray: bh(tagNumber).jreadArray,
    jreadObject: bh(tagNumber).jreadObject
  }));
  var zrpcReadInt = new ZRPCRead(readFormat(zrpcReadNumber)(tagInt)(Data_Int.fromNumber));
  var zrpcReadString = new ZRPCRead(caseJson$prime({
    jreadNull: bh(tagString).jreadNull,
    jreadBoolean: bh(tagString).jreadBoolean,
    jreadNumber: bh(tagString).jreadNumber,
    jreadString: Control_Applicative.pure(Control_Monad_Except_Trans.applicativeExceptT(Data_Identity.monadIdentity)),
    jreadArray: bh(tagString).jreadArray,
    jreadObject: bh(tagString).jreadObject
  }));

  var genericZRPCRead = function genericZRPCRead(dictConsPrefix) {
    return function (dictGeneric) {
      return function (dictZRPCReadRep) {
        return function (f) {
          return Control_Bind.bind(Control_Monad_Except_Trans.bindExceptT(Data_Identity.monadIdentity))(readObject(f))(function (v) {
            return Control_Bind.bind(Control_Monad_Except_Trans.bindExceptT(Data_Identity.monadIdentity))(readPropWith("type")(zrpcRead(zrpcReadString))(v))(function (v1) {
              var tpName = ZRPC_ConsPrefix.consPrefix(dictConsPrefix)(Type_Proxy["Proxy"].value) + v1;
              return Data_Functor.map(Control_Monad_Except_Trans.functorExceptT(Data_Identity.functorIdentity))(Data_Generic_Rep.to(dictGeneric))(zrpcReadRep(dictZRPCReadRep)(f)(tpName));
            });
          });
        };
      };
    };
  };

  var zrpcReadDate = new ZRPCRead(readFormat(zrpcReadString)(tagDate)(stringToDate));
  var zrpcReadDateTime = new ZRPCRead(readFormat(zrpcReadString)(tagDateTime)(stringToDateTime));
  var zrpcReadHugeNum = new ZRPCRead(function (f) {
    return Control_Alt.alt(Control_Monad_Except_Trans.altExceptT(Data_List_Types.semigroupNonEmptyList)(Data_Identity.monadIdentity))(Data_Functor.map(Control_Monad_Except_Trans.functorExceptT(Data_Identity.functorIdentity))(Data_HugeNum.fromNumber)(zrpcRead(zrpcReadNumber)(f)))(readFormat(zrpcReadString)(tagHugeNum)(Data_HugeNum.fromString)(f));
  });
  var zrpcReadLong = new ZRPCRead(function (f) {
    return Control_Alt.alt(Control_Monad_Except_Trans.altExceptT(Data_List_Types.semigroupNonEmptyList)(Data_Identity.monadIdentity))(readFormat(zrpcReadNumber)(tagLong)(Data_Long.fromNumber)(f))(readFormat(zrpcReadString)(tagLong)(Data_Long.fromString)(f));
  });
  var zrpcReadMillis = new ZRPCRead(function ($118) {
    return Data_Functor.map(Control_Monad_Except_Trans.functorExceptT(Data_Identity.functorIdentity))(ZRPC_Types_Millis.Millis)(zrpcRead(zrpcReadLong)($118));
  });
  var applyValF = new Control_Apply.Apply(function () {
    return functorValF;
  }, function (v) {
    return function (v1) {
      return ValF(Control_Monad_Except_Trans.except(Data_Identity.applicativeIdentity)(function () {
        var v2 = Control_Monad_Except.runExcept(v1);
        var v3 = Control_Monad_Except.runExcept(v);

        if (v3 instanceof Data_Either.Right && v2 instanceof Data_Either.Right) {
          return Data_Either.Right.create(v3.value0(v2.value0));
        }

        ;

        if (v3 instanceof Data_Either.Left && v2 instanceof Data_Either.Left) {
          return new Data_Either.Left(Data_Semigroup.append(Data_List_Types.semigroupNonEmptyList)(v3.value0)(v2.value0));
        }

        ;

        if (v2 instanceof Data_Either.Left) {
          return new Data_Either.Left(v2.value0);
        }

        ;

        if (v3 instanceof Data_Either.Left) {
          return new Data_Either.Left(v3.value0);
        }

        ;
        throw new Error("Failed pattern match at ZRPC.ZRPCRead (line 406, column 50 - line 410, column 32): " + [v3.constructor.name, v2.constructor.name]);
      }()));
    };
  });

  var zrpcReadFieldsCons = function zrpcReadFieldsCons(dictIsSymbol) {
    return function (dictZRPCReadField) {
      return function (dictZRPCReadFields) {
        return function (dictLacks) {
          return function (dictCons) {
            return new ZRPCReadFields(function (v) {
              return function (o) {
                var rest = ValF(zrpcReadFields(dictZRPCReadFields)(Type_Data_RowList.RLProxy.value)(o));
                var name = Data_Symbol.reflectSymbol(dictIsSymbol)(Data_Symbol.SProxy.value);
                var v1 = readPropWith$prime(name)(zrpcReadField(dictZRPCReadField))(o);
                var first = ValF(Data_Functor.map(Control_Monad_Except_Trans.functorExceptT(Data_Identity.functorIdentity))(Record_Builder.insert(dictCons)(dictLacks)(dictIsSymbol)(Data_Symbol.SProxy.value))(v1));
                return unValF(Control_Apply.apply(applyValF)(Data_Functor.map(functorValF)(Control_Semigroupoid.compose(Record_Builder.semigroupoidBuilder))(first))(rest));
              };
            });
          };
        };
      };
    };
  };

  exports["ZRPCRead"] = ZRPCRead;
  exports["zrpcRead"] = zrpcRead;
  exports["genericZRPCRead"] = genericZRPCRead;
  exports["ZRPCReadFields"] = ZRPCReadFields;
  exports["zrpcReadFields"] = zrpcReadFields;
  exports["ZRPCReadField"] = ZRPCReadField;
  exports["zrpcReadField"] = zrpcReadField;
  exports["ZRPCReadRep"] = ZRPCReadRep;
  exports["zrpcReadRep"] = zrpcReadRep;
  exports["zrpcReadUnit"] = zrpcReadUnit;
  exports["zrpcReadBoolean"] = zrpcReadBoolean;
  exports["zrpcReadNumber"] = zrpcReadNumber;
  exports["zrpcReadString"] = zrpcReadString;
  exports["zrpcReadInt"] = zrpcReadInt;
  exports["zrpcReadLong"] = zrpcReadLong;
  exports["zrpcReadHugeNum"] = zrpcReadHugeNum;
  exports["zrpcReadDate"] = zrpcReadDate;
  exports["zrpcReadDateTime"] = zrpcReadDateTime;
  exports["zrpcReadMillis"] = zrpcReadMillis;
  exports["zrpcReadArray"] = zrpcReadArray;
  exports["zrpcReadEither"] = zrpcReadEither;
  exports["readRecord"] = readRecord;
  exports["zrpcReadFieldMaybe"] = zrpcReadFieldMaybe;
  exports["zrpcReadFieldId"] = zrpcReadFieldId;
  exports["zrpcReadFieldsNil"] = zrpcReadFieldsNil;
  exports["zrpcReadFieldsCons"] = zrpcReadFieldsCons;
  exports["readRepNoArgs"] = readRepNoArgs;
  exports["readRepRecArg"] = readRepRecArg;
  exports["readRepSum"] = readRepSum;
})(PS["ZRPC.ZRPCRead"] = PS["ZRPC.ZRPCRead"] || {});

(function (exports) {
  // Generated by purs version 0.12.3
  "use strict";

  var Control_Category = PS["Control.Category"];
  var Control_Semigroupoid = PS["Control.Semigroupoid"];
  var Data_Argonaut_Core = PS["Data.Argonaut.Core"];
  var Data_Date = PS["Data.Date"];
  var Data_Date_Component = PS["Data.Date.Component"];
  var Data_DateTime = PS["Data.DateTime"];
  var Data_Either = PS["Data.Either"];
  var Data_Enum = PS["Data.Enum"];
  var Data_Eq = PS["Data.Eq"];
  var Data_Function = PS["Data.Function"];
  var Data_Functor = PS["Data.Functor"];
  var Data_Generic_Rep = PS["Data.Generic.Rep"];
  var Data_HugeNum = PS["Data.HugeNum"];
  var Data_Int = PS["Data.Int"];
  var Data_Long = PS["Data.Long"];
  var Data_Maybe = PS["Data.Maybe"];
  var Data_Monoid = PS["Data.Monoid"];
  var Data_Newtype = PS["Data.Newtype"];
  var Data_Ord = PS["Data.Ord"];
  var Data_Ring = PS["Data.Ring"];
  var Data_Semigroup = PS["Data.Semigroup"];
  var Data_Show = PS["Data.Show"];
  var Data_String = PS["Data.String"];
  var Data_String_CodePoints = PS["Data.String.CodePoints"];
  var Data_Symbol = PS["Data.Symbol"];
  var Data_Time = PS["Data.Time"];
  var Data_Time_Component = PS["Data.Time.Component"];
  var Foreign_Object = PS["Foreign.Object"];
  var Prelude = PS["Prelude"];
  var Record = PS["Record"];
  var Type_Data_RowList = PS["Type.Data.RowList"];
  var Type_Proxy = PS["Type.Proxy"];
  var ZRPC_ConsPrefix = PS["ZRPC.ConsPrefix"];
  var ZRPC_Types_LocalDateTime = PS["ZRPC.Types.LocalDateTime"];
  var ZRPC_Types_Millis = PS["ZRPC.Types.Millis"];
  var ZRPC_Types_ZJson = PS["ZRPC.Types.ZJson"];

  var ZRPCWrite = function ZRPCWrite(zrpcWrite) {
    this.zrpcWrite = zrpcWrite;
  };

  var ZRPCWriteField = function ZRPCWriteField(zrpcWriteField) {
    this.zrpcWriteField = zrpcWriteField;
  };

  var ZRPCWriteFields = function ZRPCWriteFields(zrpcWriteFields) {
    this.zrpcWriteFields = zrpcWriteFields;
  };

  var ZRPCWriteRep = function ZRPCWriteRep(zrpcWriteRep) {
    this.zrpcWriteRep = zrpcWriteRep;
  };

  var zrpcWriteUnit = new ZRPCWrite(function (v) {
    return Data_Argonaut_Core.fromObject(Foreign_Object.empty);
  });
  var zrpcWriteString = new ZRPCWrite(Data_Argonaut_Core.fromString);

  var zrpcWriteRep = function zrpcWriteRep(dict) {
    return dict.zrpcWriteRep;
  };

  var zrpcWriteInt = new ZRPCWrite(function ($56) {
    return Data_Argonaut_Core.fromNumber(Data_Int.toNumber($56));
  });
  var zrpcWriteFieldsNil = new ZRPCWriteFields(function (v) {
    return function (v1) {
      return Control_Category.identity(Control_Category.categoryFn);
    };
  });

  var zrpcWriteFields = function zrpcWriteFields(dict) {
    return dict.zrpcWriteFields;
  };

  var zrpcWriteRec = function zrpcWriteRec(dictRowToList) {
    return function (dictZRPCWriteFields) {
      return new ZRPCWrite(function (r) {
        var o = zrpcWriteFields(dictZRPCWriteFields)(Type_Data_RowList.RLProxy.value)(r)(Foreign_Object.empty);
        return Data_Argonaut_Core.fromObject(o);
      });
    };
  };

  var zrpcWriteField = function zrpcWriteField(dict) {
    return dict.zrpcWriteField;
  };

  var zrpcWriteFieldsCons = function zrpcWriteFieldsCons(dictIsSymbol) {
    return function (dictZRPCWriteField) {
      return function (dictZRPCWriteFields) {
        return function (dictCons) {
          return new ZRPCWriteFields(function (v) {
            return function (r) {
              return function (prev) {
                var v1 = Record.get(dictIsSymbol)(dictCons)(Data_Symbol.SProxy.value)(r);
                var k = Data_Symbol.reflectSymbol(dictIsSymbol)(Data_Symbol.SProxy.value);

                var next = function () {
                  var v2 = zrpcWriteField(dictZRPCWriteField)(v1);

                  if (v2 instanceof Data_Maybe.Just) {
                    return Foreign_Object.insert(k)(v2.value0)(prev);
                  }

                  ;

                  if (v2 instanceof Data_Maybe.Nothing) {
                    return prev;
                  }

                  ;
                  throw new Error("Failed pattern match at ZRPC.ZRPCWrite (line 157, column 14 - line 159, column 24): " + [v2.constructor.name]);
                }();

                return zrpcWriteFields(dictZRPCWriteFields)(Type_Data_RowList.RLProxy.value)(r)(next);
              };
            };
          });
        };
      };
    };
  };

  var zrpcWriteBoolean = new ZRPCWrite(Data_Argonaut_Core.fromBoolean);

  var zrpcWrite = function zrpcWrite(dict) {
    return dict.zrpcWrite;
  };

  var zrpcWriteFieldId = function zrpcWriteFieldId(dictZRPCWrite) {
    return new ZRPCWriteField(function ($57) {
      return Data_Maybe.Just.create(zrpcWrite(dictZRPCWrite)($57));
    });
  };

  var zrpcWriteFieldMaybe = function zrpcWriteFieldMaybe(dictZRPCWrite) {
    return new ZRPCWriteField(function (a) {
      return Data_Functor.map(Data_Maybe.functorMaybe)(zrpcWrite(dictZRPCWrite))(a);
    });
  };

  var zrpcWriteLong = new ZRPCWrite(function (v) {
    var v1 = Data_Long.toInt(v);

    if (v1 instanceof Data_Maybe.Just) {
      return zrpcWrite(zrpcWriteInt)(v1.value0);
    }

    ;

    if (v1 instanceof Data_Maybe.Nothing) {
      return zrpcWrite(zrpcWriteString)(Data_Long.toString(v));
    }

    ;
    throw new Error("Failed pattern match at ZRPC.ZRPCWrite (line 64, column 17 - line 66, column 43): " + [v1.constructor.name]);
  });
  var zrpcWriteMillis = new ZRPCWrite(function (v) {
    return zrpcWrite(zrpcWriteLong)(v);
  });

  var writeRepSum = function writeRepSum(dictZRPCWriteRep) {
    return function (dictZRPCWriteRep1) {
      return new ZRPCWriteRep(function (prefix) {
        return function (v) {
          if (v instanceof Data_Generic_Rep.Inl) {
            return zrpcWriteRep(dictZRPCWriteRep)(prefix)(v.value0);
          }

          ;

          if (v instanceof Data_Generic_Rep.Inr) {
            return zrpcWriteRep(dictZRPCWriteRep1)(prefix)(v.value0);
          }

          ;
          throw new Error("Failed pattern match at ZRPC.ZRPCWrite (line 184, column 1 - line 184, column 83): " + [prefix.constructor.name, v.constructor.name]);
        };
      });
    };
  };

  var unionTypeName = function unionTypeName(dictIsSymbol) {
    return function (prefix) {
      return function (sp) {
        var prefixLen = Data_String_CodePoints.length(prefix);
        var consName = Data_Symbol.reflectSymbol(dictIsSymbol)(sp);
        var p = Data_String_CodePoints.splitAt(prefixLen)(consName);
        var $48 = p.before === prefix;

        if ($48) {
          return p.after;
        }

        ;
        return consName;
      };
    };
  };

  var unionTypeObject = function unionTypeObject(dictIsSymbol) {
    return function (prefix) {
      return function (v) {
        return Foreign_Object.singleton("type")(Data_Argonaut_Core.fromString(unionTypeName(dictIsSymbol)(prefix)(Data_Symbol.SProxy.value)));
      };
    };
  };

  var writeRepNoArgs = function writeRepNoArgs(dictIsSymbol) {
    return new ZRPCWriteRep(function (prefix) {
      return function (v) {
        return unionTypeObject(dictIsSymbol)(prefix)(Data_Symbol.SProxy.value);
      };
    });
  };

  var writeRepRecArg = function writeRepRecArg(dictIsSymbol) {
    return function (dictRowToList) {
      return function (dictZRPCWriteFields) {
        return function (dictLacks) {
          return new ZRPCWriteRep(function (prefix) {
            return function (v) {
              var init = unionTypeObject(dictIsSymbol)(prefix)(Data_Symbol.SProxy.value);
              return zrpcWriteFields(dictZRPCWriteFields)(Type_Data_RowList.RLProxy.value)(v)(init);
            };
          });
        };
      };
    };
  };

  var genericZRPCWrite = function genericZRPCWrite(dictConsPrefix) {
    return function (dictGeneric) {
      return function (dictZRPCWriteRep) {
        return function ($61) {
          return Data_Argonaut_Core.fromObject(zrpcWriteRep(dictZRPCWriteRep)(ZRPC_ConsPrefix.consPrefix(dictConsPrefix)(Type_Proxy["Proxy"].value))(Data_Generic_Rep.from(dictGeneric)($61)));
        };
      };
    };
  };

  exports["ZRPCWrite"] = ZRPCWrite;
  exports["zrpcWrite"] = zrpcWrite;
  exports["genericZRPCWrite"] = genericZRPCWrite;
  exports["ZRPCWriteField"] = ZRPCWriteField;
  exports["zrpcWriteField"] = zrpcWriteField;
  exports["ZRPCWriteFields"] = ZRPCWriteFields;
  exports["zrpcWriteFields"] = zrpcWriteFields;
  exports["ZRPCWriteRep"] = ZRPCWriteRep;
  exports["zrpcWriteRep"] = zrpcWriteRep;
  exports["zrpcWriteUnit"] = zrpcWriteUnit;
  exports["zrpcWriteBoolean"] = zrpcWriteBoolean;
  exports["zrpcWriteInt"] = zrpcWriteInt;
  exports["zrpcWriteString"] = zrpcWriteString;
  exports["zrpcWriteLong"] = zrpcWriteLong;
  exports["zrpcWriteMillis"] = zrpcWriteMillis;
  exports["zrpcWriteRec"] = zrpcWriteRec;
  exports["zrpcWriteFieldMaybe"] = zrpcWriteFieldMaybe;
  exports["zrpcWriteFieldId"] = zrpcWriteFieldId;
  exports["zrpcWriteFieldsNil"] = zrpcWriteFieldsNil;
  exports["zrpcWriteFieldsCons"] = zrpcWriteFieldsCons;
  exports["writeRepNoArgs"] = writeRepNoArgs;
  exports["writeRepRecArg"] = writeRepRecArg;
  exports["writeRepSum"] = writeRepSum;
})(PS["ZRPC.ZRPCWrite"] = PS["ZRPC.ZRPCWrite"] || {});

(function (exports) {
  // Generated by purs version 0.12.3
  "use strict";

  var Data_Either = PS["Data.Either"];
  var Data_Generic_Rep = PS["Data.Generic.Rep"];
  var Data_Generic_Rep_Show = PS["Data.Generic.Rep.Show"];
  var Data_Show = PS["Data.Show"];
  var Data_Symbol = PS["Data.Symbol"];
  var ZRPC = PS["ZRPC"];
  var ZRPC_ConsPrefix = PS["ZRPC.ConsPrefix"];
  var ZRPC_ZRPCRead = PS["ZRPC.ZRPCRead"];
  var ZRPC_ZRPCWrite = PS["ZRPC.ZRPCWrite"];

  var Unauthorized = function () {
    function Unauthorized() {}

    ;
    Unauthorized.value = new Unauthorized();
    return Unauthorized;
  }();

  var UnknownError = function () {
    function UnknownError() {}

    ;
    UnknownError.value = new UnknownError();
    return UnknownError;
  }();

  var BadRequest = function () {
    function BadRequest() {}

    ;
    BadRequest.value = new BadRequest();
    return BadRequest;
  }();

  var NotFound = function () {
    function NotFound() {}

    ;
    NotFound.value = new NotFound();
    return NotFound;
  }();

  var InternalError = function () {
    function InternalError() {}

    ;
    InternalError.value = new InternalError();
    return InternalError;
  }();

  var genericResErr = new Data_Generic_Rep.Generic(function (x) {
    if (x instanceof Unauthorized) {
      return new Data_Generic_Rep.Inl(Data_Generic_Rep.NoArguments.value);
    }

    ;

    if (x instanceof UnknownError) {
      return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inl(Data_Generic_Rep.NoArguments.value));
    }

    ;

    if (x instanceof BadRequest) {
      return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inl(Data_Generic_Rep.NoArguments.value)));
    }

    ;

    if (x instanceof NotFound) {
      return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inl(Data_Generic_Rep.NoArguments.value))));
    }

    ;

    if (x instanceof InternalError) {
      return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(Data_Generic_Rep.NoArguments.value))));
    }

    ;
    throw new Error("Failed pattern match at Optimus.Common.Bridge (line 15, column 8 - line 15, column 52): " + [x.constructor.name]);
  }, function (x) {
    if (x instanceof Data_Generic_Rep.Inl) {
      return Unauthorized.value;
    }

    ;

    if (x instanceof Data_Generic_Rep.Inr && x.value0 instanceof Data_Generic_Rep.Inl) {
      return UnknownError.value;
    }

    ;

    if (x instanceof Data_Generic_Rep.Inr && x.value0 instanceof Data_Generic_Rep.Inr && x.value0.value0 instanceof Data_Generic_Rep.Inl) {
      return BadRequest.value;
    }

    ;

    if (x instanceof Data_Generic_Rep.Inr && x.value0 instanceof Data_Generic_Rep.Inr && x.value0.value0 instanceof Data_Generic_Rep.Inr && x.value0.value0.value0 instanceof Data_Generic_Rep.Inl) {
      return NotFound.value;
    }

    ;

    if (x instanceof Data_Generic_Rep.Inr && x.value0 instanceof Data_Generic_Rep.Inr && x.value0.value0 instanceof Data_Generic_Rep.Inr && x.value0.value0.value0 instanceof Data_Generic_Rep.Inr) {
      return InternalError.value;
    }

    ;
    throw new Error("Failed pattern match at Optimus.Common.Bridge (line 15, column 8 - line 15, column 52): " + [x.constructor.name]);
  });
  var showResErr = new Data_Show.Show(function (x) {
    return Data_Generic_Rep_Show.genericShow(genericResErr)(Data_Generic_Rep_Show.genericShowSum(Data_Generic_Rep_Show.genericShowConstructor(Data_Generic_Rep_Show.genericShowArgsNoArguments)(new Data_Symbol.IsSymbol(function () {
      return "Unauthorized";
    })))(Data_Generic_Rep_Show.genericShowSum(Data_Generic_Rep_Show.genericShowConstructor(Data_Generic_Rep_Show.genericShowArgsNoArguments)(new Data_Symbol.IsSymbol(function () {
      return "UnknownError";
    })))(Data_Generic_Rep_Show.genericShowSum(Data_Generic_Rep_Show.genericShowConstructor(Data_Generic_Rep_Show.genericShowArgsNoArguments)(new Data_Symbol.IsSymbol(function () {
      return "BadRequest";
    })))(Data_Generic_Rep_Show.genericShowSum(Data_Generic_Rep_Show.genericShowConstructor(Data_Generic_Rep_Show.genericShowArgsNoArguments)(new Data_Symbol.IsSymbol(function () {
      return "NotFound";
    })))(Data_Generic_Rep_Show.genericShowConstructor(Data_Generic_Rep_Show.genericShowArgsNoArguments)(new Data_Symbol.IsSymbol(function () {
      return "InternalError";
    })))))))(x);
  });
  var consPrefixResErr = new ZRPC_ConsPrefix.ConsPrefix(function (v) {
    return "";
  });
  var zrpcReadResErr = new ZRPC_ZRPCRead.ZRPCRead(ZRPC_ZRPCRead.genericZRPCRead(consPrefixResErr)(genericResErr)(ZRPC_ZRPCRead.readRepSum(ZRPC_ZRPCRead.readRepNoArgs(new Data_Symbol.IsSymbol(function () {
    return "Unauthorized";
  })))(ZRPC_ZRPCRead.readRepSum(ZRPC_ZRPCRead.readRepNoArgs(new Data_Symbol.IsSymbol(function () {
    return "UnknownError";
  })))(ZRPC_ZRPCRead.readRepSum(ZRPC_ZRPCRead.readRepNoArgs(new Data_Symbol.IsSymbol(function () {
    return "BadRequest";
  })))(ZRPC_ZRPCRead.readRepSum(ZRPC_ZRPCRead.readRepNoArgs(new Data_Symbol.IsSymbol(function () {
    return "NotFound";
  })))(ZRPC_ZRPCRead.readRepNoArgs(new Data_Symbol.IsSymbol(function () {
    return "InternalError";
  }))))))));
  exports["Unauthorized"] = Unauthorized;
  exports["UnknownError"] = UnknownError;
  exports["BadRequest"] = BadRequest;
  exports["NotFound"] = NotFound;
  exports["InternalError"] = InternalError;
  exports["genericResErr"] = genericResErr;
  exports["consPrefixResErr"] = consPrefixResErr;
  exports["zrpcReadResErr"] = zrpcReadResErr;
  exports["showResErr"] = showResErr;
})(PS["Optimus.Common.Bridge"] = PS["Optimus.Common.Bridge"] || {});

(function (exports) {
  var Alert = require('antd').Alert;

  exports._alert = Alert;
})(PS["Optimus.Common.Client.Antd.Alert"] = PS["Optimus.Common.Client.Antd.Alert"] || {});

(function (exports) {
  // Generated by purs version 0.12.3
  "use strict";

  var $foreign = PS["Optimus.Common.Client.Antd.Alert"];
  var Effect = PS["Effect"];
  var Prelude = PS["Prelude"];
  var React_Basic = PS["React.Basic"];
  var React_Basic_Events = PS["React.Basic.Events"];

  var alert = function alert(dictUnion) {
    return React_Basic.element($foreign["_alert"]);
  };

  exports["alert"] = alert;
})(PS["Optimus.Common.Client.Antd.Alert"] = PS["Optimus.Common.Client.Antd.Alert"] || {});

(function (exports) {
  var Breadcrumb = require('antd').Breadcrumb;

  exports._breadcrumb = Breadcrumb;
  exports._breadcrumbItem = Breadcrumb.Item;
})(PS["Optimus.Common.Client.Antd.Breadcrumb"] = PS["Optimus.Common.Client.Antd.Breadcrumb"] || {});

(function (exports) {
  // Generated by purs version 0.12.3
  "use strict";

  var $foreign = PS["Optimus.Common.Client.Antd.Breadcrumb"];
  var React_Basic = PS["React.Basic"];

  var breadcrumbItem = function breadcrumbItem(children) {
    return React_Basic.element($foreign["_breadcrumbItem"])({
      children: children
    });
  };

  var breadcrumb = function breadcrumb(dictUnion) {
    return React_Basic.element($foreign["_breadcrumb"]);
  };

  exports["breadcrumb"] = breadcrumb;
  exports["breadcrumbItem"] = breadcrumbItem;
})(PS["Optimus.Common.Client.Antd.Breadcrumb"] = PS["Optimus.Common.Client.Antd.Breadcrumb"] || {});

(function (exports) {
  var Button = require('antd').Button;

  exports._button = Button;
})(PS["Optimus.Common.Client.Antd.Button"] = PS["Optimus.Common.Client.Antd.Button"] || {});

(function (exports) {
  // Generated by purs version 0.12.3
  "use strict";

  var $foreign = PS["Optimus.Common.Client.Antd.Button"];
  var React_Basic = PS["React.Basic"];
  var React_Basic_Events = PS["React.Basic.Events"];

  var button = function button(dictUnion) {
    return React_Basic.element($foreign["_button"]);
  };

  exports["button"] = button;
})(PS["Optimus.Common.Client.Antd.Button"] = PS["Optimus.Common.Client.Antd.Button"] || {});

(function (exports) {
  var Card = require('antd').Card;

  exports._card = Card;
})(PS["Optimus.Common.Client.Antd.Card"] = PS["Optimus.Common.Client.Antd.Card"] || {});

(function (exports) {
  // Generated by purs version 0.12.3
  "use strict";

  var $foreign = PS["Optimus.Common.Client.Antd.Card"];
  var Effect = PS["Effect"];
  var Prelude = PS["Prelude"];
  var React_Basic = PS["React.Basic"];
  var React_Basic_DOM = PS["React.Basic.DOM"];

  var card = function card(dictUnion) {
    return React_Basic.element($foreign["_card"]);
  };

  exports["card"] = card;
})(PS["Optimus.Common.Client.Antd.Card"] = PS["Optimus.Common.Client.Antd.Card"] || {});

(function (exports) {
  var Dropdown = require('antd').Dropdown;

  exports._dropdown = Dropdown;
})(PS["Optimus.Common.Client.Antd.Dropdown"] = PS["Optimus.Common.Client.Antd.Dropdown"] || {});

(function (exports) {
  // Generated by purs version 0.12.3
  "use strict";

  var $foreign = PS["Optimus.Common.Client.Antd.Dropdown"];
  var Effect = PS["Effect"];
  var Prelude = PS["Prelude"];
  var React_Basic = PS["React.Basic"];
  var React_Basic_DOM = PS["React.Basic.DOM"];

  var dropdown = function dropdown(dictUnion) {
    return React_Basic.element($foreign["_dropdown"]);
  };

  exports["dropdown"] = dropdown;
})(PS["Optimus.Common.Client.Antd.Dropdown"] = PS["Optimus.Common.Client.Antd.Dropdown"] || {});

(function (exports) {
  var Icon = require('antd').Icon;

  exports._icon = Icon;
})(PS["Optimus.Common.Client.Antd.Icon"] = PS["Optimus.Common.Client.Antd.Icon"] || {});

(function (exports) {
  // Generated by purs version 0.12.3
  "use strict";

  var $foreign = PS["Optimus.Common.Client.Antd.Icon"];
  var React_Basic = PS["React.Basic"];
  var React_Basic_DOM = PS["React.Basic.DOM"];

  var icon = function icon(dictUnion) {
    return React_Basic.element($foreign["_icon"]);
  };

  exports["icon"] = icon;
})(PS["Optimus.Common.Client.Antd.Icon"] = PS["Optimus.Common.Client.Antd.Icon"] || {});

(function (exports) {
  var Input = require('antd').Input;

  exports._input = Input;
  exports._inputSearch = Input.Search;
})(PS["Optimus.Common.Client.Antd.Input"] = PS["Optimus.Common.Client.Antd.Input"] || {});

(function (exports) {
  // Generated by purs version 0.12.3
  "use strict";

  var $foreign = PS["Optimus.Common.Client.Antd.Input"];
  var Effect_Uncurried = PS["Effect.Uncurried"];
  var Prelude = PS["Prelude"];
  var React_Basic = PS["React.Basic"];
  var React_Basic_Events = PS["React.Basic.Events"];

  var inputSearch = function inputSearch(dictUnion) {
    return function (dictUnion1) {
      return React_Basic.element($foreign["_inputSearch"]);
    };
  };

  var input = function input(dictUnion) {
    return React_Basic.element($foreign["_input"]);
  };

  exports["input"] = input;
  exports["inputSearch"] = inputSearch;
})(PS["Optimus.Common.Client.Antd.Input"] = PS["Optimus.Common.Client.Antd.Input"] || {});

(function (exports) {
  var InputNumber = require('antd').InputNumber;

  exports._inputNumber = InputNumber;
})(PS["Optimus.Common.Client.Antd.InputNumber"] = PS["Optimus.Common.Client.Antd.InputNumber"] || {});

(function (exports) {
  // Generated by purs version 0.12.3
  "use strict";

  var $foreign = PS["Optimus.Common.Client.Antd.InputNumber"];
  var Data_Function_Uncurried = PS["Data.Function.Uncurried"];
  var Effect_Uncurried = PS["Effect.Uncurried"];
  var Prelude = PS["Prelude"];
  var React_Basic = PS["React.Basic"];

  var inputNumber = function inputNumber(dictUnion) {
    return React_Basic.element($foreign["_inputNumber"]);
  };

  exports["inputNumber"] = inputNumber;
})(PS["Optimus.Common.Client.Antd.InputNumber"] = PS["Optimus.Common.Client.Antd.InputNumber"] || {});

(function (exports) {
  var Layout = require('antd').Layout;

  exports._layout = Layout;
  exports._header = Layout.Header;
  exports._content = Layout.Content;
  exports._footer = Layout.Footer;
})(PS["Optimus.Common.Client.Antd.Layout"] = PS["Optimus.Common.Client.Antd.Layout"] || {});

(function (exports) {
  // Generated by purs version 0.12.3
  "use strict";

  var $foreign = PS["Optimus.Common.Client.Antd.Layout"];
  var Effect_Uncurried = PS["Effect.Uncurried"];
  var Prelude = PS["Prelude"];
  var React_Basic = PS["React.Basic"];

  var layout = function layout(dictUnion) {
    return React_Basic.element($foreign["_layout"]);
  };

  var header = function header(dictUnion) {
    return React_Basic.element($foreign["_header"]);
  };

  var footer = function footer(dictUnion) {
    return React_Basic.element($foreign["_footer"]);
  };

  var content = function content(dictUnion) {
    return React_Basic.element($foreign["_content"]);
  };

  exports["layout"] = layout;
  exports["header"] = header;
  exports["content"] = content;
  exports["footer"] = footer;
})(PS["Optimus.Common.Client.Antd.Layout"] = PS["Optimus.Common.Client.Antd.Layout"] || {});

(function (exports) {
  var Menu = require('antd').Menu;

  exports._menu = Menu;
  exports._menuItem = Menu.Item;
})(PS["Optimus.Common.Client.Antd.Menu"] = PS["Optimus.Common.Client.Antd.Menu"] || {});

(function (exports) {
  // Generated by purs version 0.12.3
  "use strict";

  var $foreign = PS["Optimus.Common.Client.Antd.Menu"];
  var Effect = PS["Effect"];
  var Effect_Uncurried = PS["Effect.Uncurried"];
  var Prelude = PS["Prelude"];
  var React_Basic = PS["React.Basic"];
  var React_Basic_DOM = PS["React.Basic.DOM"];

  var menuItem = function menuItem(dictUnion) {
    return React_Basic.element($foreign["_menuItem"]);
  };

  var menu = function menu(dictUnion) {
    return React_Basic.element($foreign["_menu"]);
  };

  exports["menu"] = menu;
  exports["menuItem"] = menuItem;
})(PS["Optimus.Common.Client.Antd.Menu"] = PS["Optimus.Common.Client.Antd.Menu"] || {});

(function (exports) {
  var message = require('antd').message;

  exports.message = {
    success: function success(m) {
      return function () {
        message.success(m);
      };
    },
    warning: function warning(m) {
      return function () {
        message.warning(m);
      };
    },
    error: function error(m) {
      return function () {
        message.error(m);
      };
    }
  };
})(PS["Optimus.Common.Client.Antd.Message"] = PS["Optimus.Common.Client.Antd.Message"] || {});

(function (exports) {
  // Generated by purs version 0.12.3
  "use strict";

  var $foreign = PS["Optimus.Common.Client.Antd.Message"];
  var Effect = PS["Effect"];
  var Prelude = PS["Prelude"];
  exports["message"] = $foreign.message;
})(PS["Optimus.Common.Client.Antd.Message"] = PS["Optimus.Common.Client.Antd.Message"] || {});

(function (exports) {
  var notification = require('antd').notification;

  function getNotif(key) {
    return function (unionDict) {
      return function (c) {
        return function () {
          notification[key](c);
        };
      };
    };
  }

  exports.notification = {
    success: getNotif('success'),
    error: getNotif('error'),
    info: getNotif('info'),
    warning: getNotif('warning'),
    warn: getNotif('warn'),
    open: getNotif('open'),
    close: notification.close,
    destroy: notification.destroy
  };
})(PS["Optimus.Common.Client.Antd.Notification"] = PS["Optimus.Common.Client.Antd.Notification"] || {});

(function (exports) {
  // Generated by purs version 0.12.3
  "use strict";

  var $foreign = PS["Optimus.Common.Client.Antd.Notification"];
  var Effect = PS["Effect"];
  var Effect_Uncurried = PS["Effect.Uncurried"];
  var Prelude = PS["Prelude"];
  var React_Basic = PS["React.Basic"];
  var React_Basic_DOM = PS["React.Basic.DOM"];
  exports["notification"] = $foreign.notification;
})(PS["Optimus.Common.Client.Antd.Notification"] = PS["Optimus.Common.Client.Antd.Notification"] || {});

(function (exports) {
  var Radio = require('antd').Radio;

  var RadioGroup = Radio.Group;
  exports._radio = Radio;
})(PS["Optimus.Common.Client.Antd.Radio"] = PS["Optimus.Common.Client.Antd.Radio"] || {});

(function (exports) {
  // Generated by purs version 0.12.3
  "use strict";

  var $foreign = PS["Optimus.Common.Client.Antd.Radio"];
  var React_Basic = PS["React.Basic"];
  var React_Basic_Events = PS["React.Basic.Events"];

  var radio = function radio(dictUnion) {
    return React_Basic.element($foreign["_radio"]);
  };

  exports["radio"] = radio;
})(PS["Optimus.Common.Client.Antd.Radio"] = PS["Optimus.Common.Client.Antd.Radio"] || {});

(function (exports) {
  var Select = require('antd').Select;

  exports._select = Select;
  exports._option = Select.Option;
})(PS["Optimus.Common.Client.Antd.Select"] = PS["Optimus.Common.Client.Antd.Select"] || {});

(function (exports) {
  // Generated by purs version 0.12.3
  "use strict";

  var $foreign = PS["Optimus.Common.Client.Antd.Select"];
  var Effect = PS["Effect"];
  var Effect_Uncurried = PS["Effect.Uncurried"];
  var Prelude = PS["Prelude"];
  var React_Basic = PS["React.Basic"];
  var React_Basic_DOM = PS["React.Basic.DOM"];

  var select = function select(dictUnion) {
    return React_Basic.element($foreign["_select"]);
  };

  var option = function option(dictUnion) {
    return React_Basic.element($foreign["_option"]);
  };

  exports["select"] = select;
  exports["option"] = option;
})(PS["Optimus.Common.Client.Antd.Select"] = PS["Optimus.Common.Client.Antd.Select"] || {});

(function (exports) {
  var Table = require('antd').Table;

  exports._table = Table;
})(PS["Optimus.Common.Client.Antd.Table"] = PS["Optimus.Common.Client.Antd.Table"] || {});

(function (exports) {
  // Generated by purs version 0.12.3
  "use strict";

  var $foreign = PS["Optimus.Common.Client.Antd.Table"];
  var Data_Function_Uncurried = PS["Data.Function.Uncurried"];
  var React_Basic = PS["React.Basic"];
  var React_Basic_Events = PS["React.Basic.Events"];

  var table = function table(dictUnion) {
    return React_Basic.element($foreign["_table"]);
  };

  exports["table"] = table;
})(PS["Optimus.Common.Client.Antd.Table"] = PS["Optimus.Common.Client.Antd.Table"] || {});

(function (exports) {
  exports.baseUrl = process.env.API_URL;
  var clientConfig = {
    ZAP: ['cs.staging.zap.com.ph', 'id.staging.zap.com.ph', 'cs.zap.com.ph', 'id.zap.com.ph'],
    FreshOptions: []
  };
  var flat = Object.keys(clientConfig).reduce(function (acc, key) {
    var urls = clientConfig[key];
    var newObj = {};
    urls.forEach(function (url) {
      newObj[url] = key;
    });
    return Object.assign(acc, newObj);
  }, {});
  exports.siloId = flat[window.location.host] || 'ZAP';
})(PS["Optimus.Common.Client.Constants"] = PS["Optimus.Common.Client.Constants"] || {});

(function (exports) {
  // Generated by purs version 0.12.3
  "use strict";

  var $foreign = PS["Optimus.Common.Client.Constants"];
  exports["baseUrl"] = $foreign.baseUrl;
  exports["siloId"] = $foreign.siloId;
})(PS["Optimus.Common.Client.Constants"] = PS["Optimus.Common.Client.Constants"] || {});

(function (exports) {
  var qs = require("qs");

  exports.stringify = qs.stringify;
})(PS["Optimus.Common.Client.QS"] = PS["Optimus.Common.Client.QS"] || {});

(function (exports) {
  // Generated by purs version 0.12.3
  "use strict";

  var $foreign = PS["Optimus.Common.Client.QS"];
  exports["stringify"] = $foreign.stringify;
})(PS["Optimus.Common.Client.QS"] = PS["Optimus.Common.Client.QS"] || {});

(function (exports) {
  // Generated by purs version 0.12.3
  "use strict";

  var Data_Bounded = PS["Data.Bounded"];
  var Data_Date = PS["Data.Date"];
  var Data_DateTime = PS["Data.DateTime"];
  var Data_Either = PS["Data.Either"];
  var Data_Formatter_DateTime = PS["Data.Formatter.DateTime"];
  var Data_Function = PS["Data.Function"];
  var Data_Semigroup = PS["Data.Semigroup"];
  var Data_Time = PS["Data.Time"];
  var Optimus_Common_Client_Constants = PS["Optimus.Common.Client.Constants"];
  var Optimus_Common_Client_QS = PS["Optimus.Common.Client.QS"];
  var Partial_Unsafe = PS["Partial.Unsafe"];
  var Prelude = PS["Prelude"];
  var Record = PS["Record"];
  var dateFormatter = Data_Either.fromRight()(Data_Formatter_DateTime.parseFormatString("YYYY MMM DD"));

  var formatDate = function formatDate(d) {
    return Data_Formatter_DateTime.format(dateFormatter)(new Data_DateTime.DateTime(d, Data_Bounded.bottom(Data_Time.boundedTime)));
  };

  var addQueryParams = function addQueryParams(dictNub) {
    return function (path) {
      return function (r) {
        return path + ("?" + Optimus_Common_Client_QS.stringify(Record.merge()(dictNub)({
          siloId: Optimus_Common_Client_Constants.siloId
        })(r)));
      };
    };
  };

  exports["formatDate"] = formatDate;
  exports["addQueryParams"] = addQueryParams;
})(PS["Optimus.Common.Client.Utils"] = PS["Optimus.Common.Client.Utils"] || {});

(function (exports) {
  /* global window */
  "use strict";

  exports.window = function () {
    return window;
  };
})(PS["Web.HTML"] = PS["Web.HTML"] || {});

(function (exports) {
  "use strict";

  exports.createTextNode = function (data) {
    return function (doc) {
      return function () {
        return doc.createTextNode(data);
      };
    };
  };
})(PS["Web.DOM.Document"] = PS["Web.DOM.Document"] || {});

(function (exports) {
  "use strict";

  exports._getElementById = function (id) {
    return function (node) {
      return function () {
        return node.getElementById(id);
      };
    };
  };
})(PS["Web.DOM.NonElementParentNode"] = PS["Web.DOM.NonElementParentNode"] || {});

(function (exports) {
  // Generated by purs version 0.12.3
  "use strict";

  var $foreign = PS["Web.DOM.NonElementParentNode"];
  var Control_Semigroupoid = PS["Control.Semigroupoid"];
  var Data_Functor = PS["Data.Functor"];
  var Data_Maybe = PS["Data.Maybe"];
  var Data_Nullable = PS["Data.Nullable"];
  var Effect = PS["Effect"];
  var Prelude = PS["Prelude"];
  var Web_DOM_Element = PS["Web.DOM.Element"];

  var getElementById = function getElementById(eid) {
    return function ($0) {
      return Data_Functor.map(Effect.functorEffect)(Data_Nullable.toMaybe)($foreign["_getElementById"](eid)($0));
    };
  };

  exports["getElementById"] = getElementById;
})(PS["Web.DOM.NonElementParentNode"] = PS["Web.DOM.NonElementParentNode"] || {});

(function (exports) {
  "use strict";

  exports.eventListener = function (fn) {
    return function () {
      return function (event) {
        return fn(event)();
      };
    };
  };

  exports.addEventListener = function (type) {
    return function (listener) {
      return function (useCapture) {
        return function (target) {
          return function () {
            return target.addEventListener(type, listener, useCapture);
          };
        };
      };
    };
  };
})(PS["Web.Event.EventTarget"] = PS["Web.Event.EventTarget"] || {});

(function (exports) {
  // Generated by purs version 0.12.3
  "use strict";

  var $foreign = PS["Web.Event.EventTarget"];
  var Effect = PS["Effect"];
  var Prelude = PS["Prelude"];
  var Web_Event_Event = PS["Web.Event.Event"];
  var Web_Event_Internal_Types = PS["Web.Event.Internal.Types"];
  exports["eventListener"] = $foreign.eventListener;
  exports["addEventListener"] = $foreign.addEventListener;
})(PS["Web.Event.EventTarget"] = PS["Web.Event.EventTarget"] || {});

(function (exports) {
  // Generated by purs version 0.12.3
  "use strict";

  var $foreign = PS["Web.DOM.Text"];
  var Data_Maybe = PS["Data.Maybe"];
  var Effect = PS["Effect"];
  var Unsafe_Coerce = PS["Unsafe.Coerce"];
  var Web_DOM_CharacterData = PS["Web.DOM.CharacterData"];
  var Web_DOM_ChildNode = PS["Web.DOM.ChildNode"];
  var Web_DOM_Internal_Types = PS["Web.DOM.Internal.Types"];
  var Web_DOM_NonDocumentTypeChildNode = PS["Web.DOM.NonDocumentTypeChildNode"];
  var Web_Event_EventTarget = PS["Web.Event.EventTarget"];
  var Web_Internal_FFI = PS["Web.Internal.FFI"];
  var toNode = Unsafe_Coerce.unsafeCoerce;
  exports["toNode"] = toNode;
})(PS["Web.DOM.Text"] = PS["Web.DOM.Text"] || {});

(function (exports) {
  // Generated by purs version 0.12.3
  "use strict";

  var $foreign = PS["Web.DOM.Document"];
  var Control_Semigroupoid = PS["Control.Semigroupoid"];
  var Data_Functor = PS["Data.Functor"];
  var Data_Maybe = PS["Data.Maybe"];
  var Data_Nullable = PS["Data.Nullable"];
  var Effect = PS["Effect"];
  var Prelude = PS["Prelude"];
  var Unsafe_Coerce = PS["Unsafe.Coerce"];
  var Web_DOM_Comment = PS["Web.DOM.Comment"];
  var Web_DOM_DocumentFragment = PS["Web.DOM.DocumentFragment"];
  var Web_DOM_DocumentType = PS["Web.DOM.DocumentType"];
  var Web_DOM_Element = PS["Web.DOM.Element"];
  var Web_DOM_HTMLCollection = PS["Web.DOM.HTMLCollection"];
  var Web_DOM_Internal_Types = PS["Web.DOM.Internal.Types"];
  var Web_DOM_NonElementParentNode = PS["Web.DOM.NonElementParentNode"];
  var Web_DOM_ParentNode = PS["Web.DOM.ParentNode"];
  var Web_DOM_ProcessingInstruction = PS["Web.DOM.ProcessingInstruction"];
  var Web_DOM_Text = PS["Web.DOM.Text"];
  var Web_Event_EventTarget = PS["Web.Event.EventTarget"];
  var Web_Internal_FFI = PS["Web.Internal.FFI"];
  exports["createTextNode"] = $foreign.createTextNode;
})(PS["Web.DOM.Document"] = PS["Web.DOM.Document"] || {});

(function (exports) {
  // Generated by purs version 0.12.3
  "use strict";

  var $foreign = PS["Web.HTML.HTMLDocument"];
  var Control_Semigroupoid = PS["Control.Semigroupoid"];
  var Data_Functor = PS["Data.Functor"];
  var Data_Maybe = PS["Data.Maybe"];
  var Data_Nullable = PS["Data.Nullable"];
  var Effect = PS["Effect"];
  var Prelude = PS["Prelude"];
  var Unsafe_Coerce = PS["Unsafe.Coerce"];
  var Web_DOM_Document = PS["Web.DOM.Document"];
  var Web_DOM_Internal_Types = PS["Web.DOM.Internal.Types"];
  var Web_DOM_NonElementParentNode = PS["Web.DOM.NonElementParentNode"];
  var Web_DOM_ParentNode = PS["Web.DOM.ParentNode"];
  var Web_Event_EventTarget = PS["Web.Event.EventTarget"];
  var Web_HTML_HTMLDocument_ReadyState = PS["Web.HTML.HTMLDocument.ReadyState"];
  var Web_HTML_HTMLElement = PS["Web.HTML.HTMLElement"];
  var Web_HTML_HTMLScriptElement = PS["Web.HTML.HTMLScriptElement"];
  var Web_Internal_FFI = PS["Web.Internal.FFI"];
  var toNonElementParentNode = Unsafe_Coerce.unsafeCoerce;
  var toDocument = Unsafe_Coerce.unsafeCoerce;
  exports["toDocument"] = toDocument;
  exports["toNonElementParentNode"] = toNonElementParentNode;
})(PS["Web.HTML.HTMLDocument"] = PS["Web.HTML.HTMLDocument"] || {});

(function (exports) {
  "use strict";

  exports.pushState = function (a) {
    return function (docTitle) {
      return function (url) {
        return function (history) {
          return function () {
            return history.pushState(a, docTitle, url);
          };
        };
      };
    };
  };

  exports.replaceState = function (a) {
    return function (docTitle) {
      return function (url) {
        return function (history) {
          return function () {
            return history.replaceState(a, docTitle, url);
          };
        };
      };
    };
  };

  exports.state = function (history) {
    return function () {
      return history.state;
    };
  };
})(PS["Web.HTML.History"] = PS["Web.HTML.History"] || {});

(function (exports) {
  // Generated by purs version 0.12.3
  "use strict";

  var $foreign = PS["Web.HTML.History"];
  var Data_Eq = PS["Data.Eq"];
  var Data_Newtype = PS["Data.Newtype"];
  var Data_Ord = PS["Data.Ord"];
  var Effect = PS["Effect"];
  var Foreign = PS["Foreign"];
  var Prelude = PS["Prelude"];
  exports["pushState"] = $foreign.pushState;
  exports["replaceState"] = $foreign.replaceState;
  exports["state"] = $foreign.state;
})(PS["Web.HTML.History"] = PS["Web.HTML.History"] || {});

(function (exports) {
  "use strict";

  exports.hash = function (location) {
    return function () {
      return location.hash;
    };
  }; // ----------------------------------------------------------------------------


  exports.hostname = function (location) {
    return function () {
      return location.hostname;
    };
  }; // ----------------------------------------------------------------------------


  exports.origin = function (location) {
    return function () {
      return location.origin;
    };
  }; // ----------------------------------------------------------------------------


  exports.pathname = function (location) {
    return function () {
      return location.pathname;
    };
  }; // ----------------------------------------------------------------------------


  exports.search = function (location) {
    return function () {
      return location.search;
    };
  };
})(PS["Web.HTML.Location"] = PS["Web.HTML.Location"] || {});

(function (exports) {
  // Generated by purs version 0.12.3
  "use strict";

  var $foreign = PS["Web.HTML.Location"];
  var Effect = PS["Effect"];
  var Prelude = PS["Prelude"];
  exports["hash"] = $foreign.hash;
  exports["hostname"] = $foreign.hostname;
  exports["origin"] = $foreign.origin;
  exports["pathname"] = $foreign.pathname;
  exports["search"] = $foreign.search;
})(PS["Web.HTML.Location"] = PS["Web.HTML.Location"] || {});

(function (exports) {
  "use strict";

  exports.document = function (window) {
    return function () {
      return window.document;
    };
  };

  exports.location = function (window) {
    return function () {
      return window.location;
    };
  };

  exports.history = function (window) {
    return function () {
      return window.history;
    };
  };

  exports.alert = function (str) {
    return function (window) {
      return function () {
        window.alert(str);
        return {};
      };
    };
  };

  exports.localStorage = function (window) {
    return function () {
      return window.localStorage;
    };
  };
})(PS["Web.HTML.Window"] = PS["Web.HTML.Window"] || {});

(function (exports) {
  "use strict";

  exports._getItem = function (key) {
    return function (storage) {
      return function () {
        return storage.getItem(key);
      };
    };
  };

  exports.setItem = function (key) {
    return function (value) {
      return function (storage) {
        return function () {
          storage.setItem(key, value);
        };
      };
    };
  };

  exports.removeItem = function (key) {
    return function (storage) {
      return function () {
        storage.removeItem(key);
      };
    };
  };
})(PS["Web.Storage.Storage"] = PS["Web.Storage.Storage"] || {});

(function (exports) {
  // Generated by purs version 0.12.3
  "use strict";

  var $foreign = PS["Web.Storage.Storage"];
  var Control_Semigroupoid = PS["Control.Semigroupoid"];
  var Data_Functor = PS["Data.Functor"];
  var Data_Maybe = PS["Data.Maybe"];
  var Data_Nullable = PS["Data.Nullable"];
  var Effect = PS["Effect"];
  var Prelude = PS["Prelude"];

  var getItem = function getItem(s) {
    return function ($1) {
      return Data_Functor.map(Effect.functorEffect)(Data_Nullable.toMaybe)($foreign["_getItem"](s)($1));
    };
  };

  exports["getItem"] = getItem;
  exports["setItem"] = $foreign.setItem;
  exports["removeItem"] = $foreign.removeItem;
})(PS["Web.Storage.Storage"] = PS["Web.Storage.Storage"] || {});

(function (exports) {
  // Generated by purs version 0.12.3
  "use strict";

  var $foreign = PS["Web.HTML.Window"];
  var Control_Semigroupoid = PS["Control.Semigroupoid"];
  var Data_Eq = PS["Data.Eq"];
  var Data_Functor = PS["Data.Functor"];
  var Data_Maybe = PS["Data.Maybe"];
  var Data_Newtype = PS["Data.Newtype"];
  var Data_Nullable = PS["Data.Nullable"];
  var Data_Ord = PS["Data.Ord"];
  var Effect = PS["Effect"];
  var Prelude = PS["Prelude"];
  var Unsafe_Coerce = PS["Unsafe.Coerce"];
  var Web_Event_EventTarget = PS["Web.Event.EventTarget"];
  var Web_HTML_HTMLDocument = PS["Web.HTML.HTMLDocument"];
  var Web_HTML_History = PS["Web.HTML.History"];
  var Web_HTML_Location = PS["Web.HTML.Location"];
  var Web_HTML_Navigator = PS["Web.HTML.Navigator"];
  var Web_Storage_Storage = PS["Web.Storage.Storage"];
  var toEventTarget = Unsafe_Coerce.unsafeCoerce;
  exports["toEventTarget"] = toEventTarget;
  exports["document"] = $foreign.document;
  exports["location"] = $foreign.location;
  exports["history"] = $foreign.history;
  exports["alert"] = $foreign.alert;
  exports["localStorage"] = $foreign.localStorage;
})(PS["Web.HTML.Window"] = PS["Web.HTML.Window"] || {});

(function (exports) {
  // Generated by purs version 0.12.3
  "use strict";

  var $foreign = PS["Web.HTML"];
  var Effect = PS["Effect"];
  var Web_HTML_HTMLAnchorElement = PS["Web.HTML.HTMLAnchorElement"];
  var Web_HTML_HTMLAreaElement = PS["Web.HTML.HTMLAreaElement"];
  var Web_HTML_HTMLAudioElement = PS["Web.HTML.HTMLAudioElement"];
  var Web_HTML_HTMLBRElement = PS["Web.HTML.HTMLBRElement"];
  var Web_HTML_HTMLBaseElement = PS["Web.HTML.HTMLBaseElement"];
  var Web_HTML_HTMLBodyElement = PS["Web.HTML.HTMLBodyElement"];
  var Web_HTML_HTMLButtonElement = PS["Web.HTML.HTMLButtonElement"];
  var Web_HTML_HTMLCanvasElement = PS["Web.HTML.HTMLCanvasElement"];
  var Web_HTML_HTMLDListElement = PS["Web.HTML.HTMLDListElement"];
  var Web_HTML_HTMLDataElement = PS["Web.HTML.HTMLDataElement"];
  var Web_HTML_HTMLDataListElement = PS["Web.HTML.HTMLDataListElement"];
  var Web_HTML_HTMLDivElement = PS["Web.HTML.HTMLDivElement"];
  var Web_HTML_HTMLDocument = PS["Web.HTML.HTMLDocument"];
  var Web_HTML_HTMLElement = PS["Web.HTML.HTMLElement"];
  var Web_HTML_HTMLEmbedElement = PS["Web.HTML.HTMLEmbedElement"];
  var Web_HTML_HTMLFieldSetElement = PS["Web.HTML.HTMLFieldSetElement"];
  var Web_HTML_HTMLFormElement = PS["Web.HTML.HTMLFormElement"];
  var Web_HTML_HTMLHRElement = PS["Web.HTML.HTMLHRElement"];
  var Web_HTML_HTMLHeadElement = PS["Web.HTML.HTMLHeadElement"];
  var Web_HTML_HTMLHeadingElement = PS["Web.HTML.HTMLHeadingElement"];
  var Web_HTML_HTMLIFrameElement = PS["Web.HTML.HTMLIFrameElement"];
  var Web_HTML_HTMLImageElement = PS["Web.HTML.HTMLImageElement"];
  var Web_HTML_HTMLInputElement = PS["Web.HTML.HTMLInputElement"];
  var Web_HTML_HTMLKeygenElement = PS["Web.HTML.HTMLKeygenElement"];
  var Web_HTML_HTMLLIElement = PS["Web.HTML.HTMLLIElement"];
  var Web_HTML_HTMLLabelElement = PS["Web.HTML.HTMLLabelElement"];
  var Web_HTML_HTMLLegendElement = PS["Web.HTML.HTMLLegendElement"];
  var Web_HTML_HTMLLinkElement = PS["Web.HTML.HTMLLinkElement"];
  var Web_HTML_HTMLMapElement = PS["Web.HTML.HTMLMapElement"];
  var Web_HTML_HTMLMediaElement = PS["Web.HTML.HTMLMediaElement"];
  var Web_HTML_HTMLMetaElement = PS["Web.HTML.HTMLMetaElement"];
  var Web_HTML_HTMLMeterElement = PS["Web.HTML.HTMLMeterElement"];
  var Web_HTML_HTMLModElement = PS["Web.HTML.HTMLModElement"];
  var Web_HTML_HTMLOListElement = PS["Web.HTML.HTMLOListElement"];
  var Web_HTML_HTMLObjectElement = PS["Web.HTML.HTMLObjectElement"];
  var Web_HTML_HTMLOptGroupElement = PS["Web.HTML.HTMLOptGroupElement"];
  var Web_HTML_HTMLOptionElement = PS["Web.HTML.HTMLOptionElement"];
  var Web_HTML_HTMLOutputElement = PS["Web.HTML.HTMLOutputElement"];
  var Web_HTML_HTMLParagraphElement = PS["Web.HTML.HTMLParagraphElement"];
  var Web_HTML_HTMLParamElement = PS["Web.HTML.HTMLParamElement"];
  var Web_HTML_HTMLPreElement = PS["Web.HTML.HTMLPreElement"];
  var Web_HTML_HTMLProgressElement = PS["Web.HTML.HTMLProgressElement"];
  var Web_HTML_HTMLQuoteElement = PS["Web.HTML.HTMLQuoteElement"];
  var Web_HTML_HTMLScriptElement = PS["Web.HTML.HTMLScriptElement"];
  var Web_HTML_HTMLSelectElement = PS["Web.HTML.HTMLSelectElement"];
  var Web_HTML_HTMLSourceElement = PS["Web.HTML.HTMLSourceElement"];
  var Web_HTML_HTMLSpanElement = PS["Web.HTML.HTMLSpanElement"];
  var Web_HTML_HTMLStyleElement = PS["Web.HTML.HTMLStyleElement"];
  var Web_HTML_HTMLTableCaptionElement = PS["Web.HTML.HTMLTableCaptionElement"];
  var Web_HTML_HTMLTableCellElement = PS["Web.HTML.HTMLTableCellElement"];
  var Web_HTML_HTMLTableColElement = PS["Web.HTML.HTMLTableColElement"];
  var Web_HTML_HTMLTableDataCellElement = PS["Web.HTML.HTMLTableDataCellElement"];
  var Web_HTML_HTMLTableElement = PS["Web.HTML.HTMLTableElement"];
  var Web_HTML_HTMLTableHeaderCellElement = PS["Web.HTML.HTMLTableHeaderCellElement"];
  var Web_HTML_HTMLTableRowElement = PS["Web.HTML.HTMLTableRowElement"];
  var Web_HTML_HTMLTableSectionElement = PS["Web.HTML.HTMLTableSectionElement"];
  var Web_HTML_HTMLTemplateElement = PS["Web.HTML.HTMLTemplateElement"];
  var Web_HTML_HTMLTextAreaElement = PS["Web.HTML.HTMLTextAreaElement"];
  var Web_HTML_HTMLTimeElement = PS["Web.HTML.HTMLTimeElement"];
  var Web_HTML_HTMLTitleElement = PS["Web.HTML.HTMLTitleElement"];
  var Web_HTML_HTMLTrackElement = PS["Web.HTML.HTMLTrackElement"];
  var Web_HTML_HTMLUListElement = PS["Web.HTML.HTMLUListElement"];
  var Web_HTML_HTMLVideoElement = PS["Web.HTML.HTMLVideoElement"];
  var Web_HTML_History = PS["Web.HTML.History"];
  var Web_HTML_Location = PS["Web.HTML.Location"];
  var Web_HTML_Navigator = PS["Web.HTML.Navigator"];
  var Web_HTML_Window = PS["Web.HTML.Window"];
  exports["window"] = $foreign.window;
})(PS["Web.HTML"] = PS["Web.HTML"] || {});

(function (exports) {
  // Generated by purs version 0.12.3
  "use strict";

  var Control_Applicative = PS["Control.Applicative"];
  var Control_Bind = PS["Control.Bind"];
  var Control_Monad_Error_Class = PS["Control.Monad.Error.Class"];
  var Control_Monad_Except = PS["Control.Monad.Except"];
  var Data_Argonaut_Core = PS["Data.Argonaut.Core"];
  var Data_Either = PS["Data.Either"];
  var Data_Function = PS["Data.Function"];
  var Data_List_Types = PS["Data.List.Types"];
  var Data_Maybe = PS["Data.Maybe"];
  var Data_Semigroup = PS["Data.Semigroup"];
  var Data_Show = PS["Data.Show"];
  var Effect = PS["Effect"];
  var Effect_Aff = PS["Effect.Aff"];
  var Effect_Class = PS["Effect.Class"];
  var Effect_Exception = PS["Effect.Exception"];
  var Foreign = PS["Foreign"];
  var Milkis = PS["Milkis"];
  var Milkis_Impl_Window = PS["Milkis.Impl.Window"];
  var Optimus_Common_Bridge = PS["Optimus.Common.Bridge"];
  var Optimus_Common_Client_Constants = PS["Optimus.Common.Client.Constants"];
  var Optimus_Common_Client_Utils = PS["Optimus.Common.Client.Utils"];
  var Prelude = PS["Prelude"];
  var Type_Equality = PS["Type.Equality"];
  var Type_Row_Homogeneous = PS["Type.Row.Homogeneous"];
  var Unsafe_Coerce = PS["Unsafe.Coerce"];
  var Web_HTML = PS["Web.HTML"];
  var Web_HTML_Window = PS["Web.HTML.Window"];
  var Web_Storage_Storage = PS["Web.Storage.Storage"];
  var ZRPC = PS["ZRPC"];
  var ZRPC_ZRPCRead = PS["ZRPC.ZRPCRead"];
  var ZRPC_ZRPCWrite = PS["ZRPC.ZRPCWrite"];
  var zjson = Milkis.json;

  var requireToken = function () {
    var getToken = Effect_Class.liftEffect(Effect_Aff.monadEffectAff)(Control_Bind.bind(Effect.bindEffect)(Control_Bind.bind(Effect.bindEffect)(Web_HTML.window)(Web_HTML_Window.localStorage))(Web_Storage_Storage.getItem("accessToken")));
    return Control_Bind.bind(Effect_Aff.bindAff)(getToken)(function (v) {
      if (v instanceof Data_Maybe.Just) {
        return Control_Applicative.pure(Effect_Aff.applicativeAff)(v.value0);
      }

      ;

      if (v instanceof Data_Maybe.Nothing) {
        return Control_Monad_Error_Class.throwError(Effect_Aff.monadThrowAff)(Effect_Exception.error("No access token"));
      }

      ;
      throw new Error("Failed pattern match at Optimus.Common.Client.ApiClient.Utils (line 62, column 29 - line 65, column 3): " + [v.constructor.name]);
    });
  }();

  var readJson = function readJson(dictZRPCRead) {
    return function (json) {
      var v = Control_Monad_Except.runExcept(ZRPC_ZRPCRead.zrpcRead(dictZRPCRead)(json));

      if (v instanceof Data_Either.Right) {
        return Control_Applicative.pure(Effect_Aff.applicativeAff)(v.value0);
      }

      ;

      if (v instanceof Data_Either.Left) {
        return Control_Monad_Error_Class.throwError(Effect_Aff.monadThrowAff)(Effect_Exception.error("Unable to read: " + Data_Show.show(Data_List_Types.showNonEmptyList(Foreign.showForeignError))(v.value0)));
      }

      ;
      throw new Error("Failed pattern match at Optimus.Common.Client.ApiClient.Utils (line 74, column 17 - line 76, column 64): " + [v.constructor.name]);
    };
  };

  var handleRes = function handleRes(v) {
    if (v instanceof Data_Either.Right) {
      return Control_Applicative.pure(Effect_Aff.applicativeAff)(v.value0);
    }

    ;

    if (v instanceof Data_Either.Left) {
      if (v.value0 instanceof Optimus_Common_Bridge.Unauthorized) {
        return Control_Monad_Error_Class.throwError(Effect_Aff.monadThrowAff)(Effect_Exception.error("Unauthorized."));
      }

      ;

      if (v.value0 instanceof Optimus_Common_Bridge.UnknownError) {
        return Control_Monad_Error_Class.throwError(Effect_Aff.monadThrowAff)(Effect_Exception.error("An unknown error has occured."));
      }

      ;

      if (v.value0 instanceof Optimus_Common_Bridge.NotFound) {
        return Control_Monad_Error_Class.throwError(Effect_Aff.monadThrowAff)(Effect_Exception.error("Not Found."));
      }

      ;

      if (v.value0 instanceof Optimus_Common_Bridge.InternalError) {
        return Control_Monad_Error_Class.throwError(Effect_Aff.monadThrowAff)(Effect_Exception.error("Server error."));
      }

      ;

      if (v.value0 instanceof Optimus_Common_Bridge.BadRequest) {
        return Control_Monad_Error_Class.throwError(Effect_Aff.monadThrowAff)(Effect_Exception.error("BadRequest"));
      }

      ;
      throw new Error("Failed pattern match at Optimus.Common.Client.ApiClient.Utils (line 53, column 24 - line 61, column 1): " + [v.value0.constructor.name]);
    }

    ;
    throw new Error("Failed pattern match at Optimus.Common.Client.ApiClient.Utils (line 51, column 1 - line 51, column 69): " + [v.constructor.name]);
  };

  var fetch = function fetch(dictUnion) {
    return Milkis.fetch(Milkis_Impl_Window.windowFetch)(dictUnion);
  };

  var apiUrl = function apiUrl(path) {
    return Milkis.URL(Optimus_Common_Client_Constants.baseUrl + path);
  };

  var getApi = function getApi(path) {
    return function (token) {
      return fetch()(apiUrl(path))({
        method: Milkis.getMethod,
        headers: Milkis.makeHeaders(Type_Row_Homogeneous.homogeneous()(Type_Row_Homogeneous.homogeneousRowListCons(Type_Row_Homogeneous.homogeneousRowListNil)(Type_Equality.refl)))({
          authorization: "Bearer " + token
        })
      });
    };
  };

  var getAuthorizedApi = function getAuthorizedApi(dictZRPCRead) {
    return function (dictNub) {
      return function (path) {
        return function (query) {
          return Control_Bind.bind(Effect_Aff.bindAff)(Control_Bind.bind(Effect_Aff.bindAff)(Control_Bind.bind(Effect_Aff.bindAff)(Control_Bind.bind(Effect_Aff.bindAff)(requireToken)(getApi(Optimus_Common_Client_Utils.addQueryParams(dictNub)(path)(query))))(zjson))(readJson(ZRPC_ZRPCRead.zrpcReadEither(ZRPC_ZRPCRead.zrpcReadFieldId(Optimus_Common_Bridge.zrpcReadResErr))(ZRPC_ZRPCRead.zrpcReadFieldId(dictZRPCRead)))))(handleRes);
        };
      };
    };
  };

  var postApi = function postApi(path) {
    return function (payloadJson) {
      return function (token) {
        return fetch()(apiUrl(path))({
          method: Milkis.postMethod,
          headers: Milkis.makeHeaders(Type_Row_Homogeneous.homogeneous()(Type_Row_Homogeneous.homogeneousRowListCons(Type_Row_Homogeneous.homogeneousRowListCons(Type_Row_Homogeneous.homogeneousRowListNil)(Type_Equality.refl))(Type_Equality.refl)))({
            authorization: "Bearer " + token,
            "Content-Type": "application/json"
          }),
          body: Data_Argonaut_Core.stringify(payloadJson)
        });
      };
    };
  };

  var postAuthorizedApi = function postAuthorizedApi(dictZRPCWrite) {
    return function (dictZRPCRead) {
      return function (dictNub) {
        return function (path) {
          return function (query) {
            return function (payload) {
              return Control_Bind.bind(Effect_Aff.bindAff)(Control_Bind.bind(Effect_Aff.bindAff)(Control_Bind.bind(Effect_Aff.bindAff)(requireToken)(postApi(Optimus_Common_Client_Utils.addQueryParams(dictNub)(path)(query))(ZRPC_ZRPCWrite.zrpcWrite(dictZRPCWrite)(payload))))(zjson))(readJson(dictZRPCRead));
            };
          };
        };
      };
    };
  };

  exports["getAuthorizedApi"] = getAuthorizedApi;
  exports["postAuthorizedApi"] = postAuthorizedApi;
  exports["handleRes"] = handleRes;
  exports["requireToken"] = requireToken;
  exports["zjson"] = zjson;
  exports["readJson"] = readJson;
  exports["getApi"] = getApi;
  exports["postApi"] = postApi;
  exports["fetch"] = fetch;
  exports["apiUrl"] = apiUrl;
})(PS["Optimus.Common.Client.ApiClient.Utils"] = PS["Optimus.Common.Client.ApiClient.Utils"] || {});

(function (exports) {
  exports.zapLogo = require('../../assets/zap_logo.png');
})(PS["Optimus.Common.Client.Assets"] = PS["Optimus.Common.Client.Assets"] || {});

(function (exports) {
  // Generated by purs version 0.12.3
  "use strict";

  var $foreign = PS["Optimus.Common.Client.Assets"];
  exports["zapLogo"] = $foreign.zapLogo;
})(PS["Optimus.Common.Client.Assets"] = PS["Optimus.Common.Client.Assets"] || {});

(function (exports) {
  var auth0 = require("auth0-js");

  exports.mkWebAuth = function (webConfig) {
    return function () {
      return new auth0.WebAuth(webConfig);
    };
  };

  exports._authorize = function (webAuth) {
    return function () {
      return webAuth.authorize();
    };
  };

  exports._parseHash = function (left) {
    return function (right) {
      return function (callback) {
        return function (webAuth) {
          return function () {
            webAuth.parseHash(function (err, res) {
              if (err) {
                return callback(left(err))();
              }

              return callback(right(res))();
            });
          };
        };
      };
    };
  };

  exports._logout = function (webAuth) {
    return function (logoutConfig) {
      return function () {
        return webAuth.logout(logoutConfig);
      };
    };
  };
})(PS["Optimus.Common.Client.Auth"] = PS["Optimus.Common.Client.Auth"] || {});

(function (exports) {
  exports.auth0ClientId = process.env.AUTH0_CLIENT_ID;
})(PS["Optimus.Common.Client.Env"] = PS["Optimus.Common.Client.Env"] || {});

(function (exports) {
  // Generated by purs version 0.12.3
  "use strict";

  var $foreign = PS["Optimus.Common.Client.Env"];
  exports["auth0ClientId"] = $foreign.auth0ClientId;
})(PS["Optimus.Common.Client.Env"] = PS["Optimus.Common.Client.Env"] || {});

(function (exports) {
  // Generated by purs version 0.12.3
  "use strict";

  var $foreign = PS["Optimus.Common.Client.Auth"];
  var Control_Applicative = PS["Control.Applicative"];
  var Control_Bind = PS["Control.Bind"];
  var Control_Monad_Error_Class = PS["Control.Monad.Error.Class"];
  var Control_Semigroupoid = PS["Control.Semigroupoid"];
  var Data_Either = PS["Data.Either"];
  var Data_Function = PS["Data.Function"];
  var Data_Functor = PS["Data.Functor"];
  var Data_Maybe = PS["Data.Maybe"];
  var Data_Semigroup = PS["Data.Semigroup"];
  var Effect = PS["Effect"];
  var Effect_Exception = PS["Effect.Exception"];
  var Foreign_Object = PS["Foreign.Object"];
  var Optimus_Common_Client_Env = PS["Optimus.Common.Client.Env"];
  var Prelude = PS["Prelude"];
  var Web_HTML = PS["Web.HTML"];
  var Web_HTML_Location = PS["Web.HTML.Location"];
  var Web_HTML_Window = PS["Web.HTML.Window"];
  var Web_Storage_Storage = PS["Web.Storage.Storage"];

  var requireAuth = function __do() {
    var $13 = Control_Bind.bind(Effect.bindEffect)(Control_Bind.bind(Effect.bindEffect)(Web_HTML.window)(Web_HTML_Window.localStorage))(Web_Storage_Storage.getItem("accessToken"))();
    return Data_Maybe.isJust($13);
  };

  var locOrigin = Control_Bind.bind(Effect.bindEffect)(Control_Bind.bind(Effect.bindEffect)(Web_HTML.window)(Web_HTML_Window.location))(Web_HTML_Location.origin);
  var logoutConfig = Data_Functor.mapFlipped(Effect.functorEffect)(locOrigin)(function (o) {
    return {
      returnTo: o + "/login",
      clientID: Optimus_Common_Client_Env.auth0ClientId
    };
  });
  var hostname$prime = Control_Bind.bind(Effect.bindEffect)(Control_Bind.bind(Effect.bindEffect)(Web_HTML.window)(Web_HTML_Window.location))(Web_HTML_Location.hostname);
  var auth0Configs = Foreign_Object.insert("id.staging.zap.com.ph")({
    auth0ClientId: "HBsoh3afj8tOKSXdJajqrBfgbpj5HOof",
    auth0Domain: "zap.auth0.com",
    auth0Audience: "http://localhost:3001"
  })(Foreign_Object.insert("id.zap.com.ph")({
    auth0ClientId: "m1rOE3jXe9t34zFV5JjoqTXpQzV2r6g9",
    auth0Domain: "zap.auth0.com",
    auth0Audience: "http://localhost:3001"
  })(Foreign_Object.insert("cs.staging.zap.com.ph")({
    auth0ClientId: "lWmdzepf265TDPO3sC7K77ItphyxM2ad",
    auth0Domain: "zap.auth0.com",
    auth0Audience: "http://localhost:3001"
  })(Foreign_Object.insert("cs.zap.com.ph")({
    auth0ClientId: "3cQoAhXgp7XPROBzcEQVuuk10Zgl5Ryu",
    auth0Domain: "zap.auth0.com",
    auth0Audience: "http://localhost:3001"
  })(Foreign_Object.empty))));

  var webConfig = function __do() {
    var v = locOrigin();
    var v1 = hostname$prime();
    var v2 = Foreign_Object.lookup(v1)(auth0Configs);

    if (v2 instanceof Data_Maybe.Just) {
      return {
        clientID: v2.value0.auth0ClientId,
        redirectUri: v + "/callback",
        responseType: "token id_token",
        scope: "openid profile",
        domain: v2.value0.auth0Domain,
        audience: v2.value0.auth0Audience
      };
    }

    ;

    if (v2 instanceof Data_Maybe.Nothing) {
      return Control_Monad_Error_Class.throwError(Control_Monad_Error_Class.monadThrowEffect)(Effect_Exception.error("No Auth COnfig Available for host"))();
    }

    ;
    throw new Error("Failed pattern match at Optimus.Common.Client.Auth (line 97, column 3 - line 107, column 71): " + [v2.constructor.name]);
  };

  var webAuth = Control_Bind.bind(Effect.bindEffect)(webConfig)($foreign.mkWebAuth);
  var authorize = Control_Bind.bind(Effect.bindEffect)(webAuth)($foreign["_authorize"]);

  var logout = function __do() {
    var v = Control_Bind.bind(Effect.bindEffect)(Web_HTML.window)(Web_HTML_Window.localStorage)();
    var v1 = webAuth();
    var v2 = logoutConfig();
    Web_Storage_Storage.removeItem("accessToken")(v)();
    return $foreign["_logout"](v1)(v2)();
  };

  var parseHash = function parseHash(cb) {
    return Control_Bind.bind(Effect.bindEffect)(webAuth)($foreign["_parseHash"](Data_Either.Left.create)(Data_Either.Right.create)(cb));
  };

  exports["authorize"] = authorize;
  exports["parseHash"] = parseHash;
  exports["logout"] = logout;
  exports["requireAuth"] = requireAuth;
})(PS["Optimus.Common.Client.Auth"] = PS["Optimus.Common.Client.Auth"] || {});

(function (exports) {
  var copy = require('copy-to-clipboard');

  exports._copy = copy;
})(PS["Optimus.Common.Client.Clipboard"] = PS["Optimus.Common.Client.Clipboard"] || {});

(function (exports) {
  // Generated by purs version 0.12.3
  "use strict";

  var $foreign = PS["Optimus.Common.Client.Clipboard"];
  var Effect = PS["Effect"];
  var Effect_Uncurried = PS["Effect.Uncurried"];
  var Prelude = PS["Prelude"];

  var copy = function copy(text) {
    return function () {
      return $foreign["_copy"](text);
    };
  };

  exports["copy"] = copy;
})(PS["Optimus.Common.Client.Clipboard"] = PS["Optimus.Common.Client.Clipboard"] || {});

(function (exports) {
  // Generated by purs version 0.12.3
  "use strict";

  var Data_Tuple = PS["Data.Tuple"];
  var emptyRouteState = {
    segments: [],
    params: [],
    hash: ""
  };
  exports["emptyRouteState"] = emptyRouteState;
})(PS["Routing.Duplex.Types"] = PS["Routing.Duplex.Types"] || {});

(function (exports) {
  // Generated by purs version 0.12.3
  "use strict";

  var Control_Alt = PS["Control.Alt"];
  var Control_Alternative = PS["Control.Alternative"];
  var Control_Applicative = PS["Control.Applicative"];
  var Control_Apply = PS["Control.Apply"];
  var Control_Lazy = PS["Control.Lazy"];
  var Control_Plus = PS["Control.Plus"];
  var Control_Semigroupoid = PS["Control.Semigroupoid"];
  var Data_Array = PS["Data.Array"];
  var Data_Array_NonEmpty = PS["Data.Array.NonEmpty"];
  var Data_Array_NonEmpty_Internal = PS["Data.Array.NonEmpty.Internal"];
  var Data_Bifunctor = PS["Data.Bifunctor"];
  var Data_Boolean = PS["Data.Boolean"];
  var Data_Either = PS["Data.Either"];
  var Data_Eq = PS["Data.Eq"];
  var Data_Foldable = PS["Data.Foldable"];
  var Data_Function = PS["Data.Function"];
  var Data_Functor = PS["Data.Functor"];
  var Data_Generic_Rep = PS["Data.Generic.Rep"];
  var Data_Generic_Rep_Show = PS["Data.Generic.Rep.Show"];
  var Data_HeytingAlgebra = PS["Data.HeytingAlgebra"];
  var Data_Int = PS["Data.Int"];
  var Data_Lazy = PS["Data.Lazy"];
  var Data_Maybe = PS["Data.Maybe"];
  var Data_Semigroup = PS["Data.Semigroup"];
  var Data_Semiring = PS["Data.Semiring"];
  var Data_Show = PS["Data.Show"];
  var Data_String = PS["Data.String"];
  var Data_String_CodeUnits = PS["Data.String.CodeUnits"];
  var Data_String_Common = PS["Data.String.Common"];
  var Data_String_Pattern = PS["Data.String.Pattern"];
  var Data_Symbol = PS["Data.Symbol"];
  var Data_Tuple = PS["Data.Tuple"];
  var Data_Unit = PS["Data.Unit"];
  var Global_Unsafe = PS["Global.Unsafe"];
  var Prelude = PS["Prelude"];
  var Routing_Duplex_Types = PS["Routing.Duplex.Types"];

  var Expected = function () {
    function Expected(value0, value1) {
      this.value0 = value0;
      this.value1 = value1;
    }

    ;

    Expected.create = function (value0) {
      return function (value1) {
        return new Expected(value0, value1);
      };
    };

    return Expected;
  }();

  var ExpectedEndOfPath = function () {
    function ExpectedEndOfPath(value0) {
      this.value0 = value0;
    }

    ;

    ExpectedEndOfPath.create = function (value0) {
      return new ExpectedEndOfPath(value0);
    };

    return ExpectedEndOfPath;
  }();

  var MissingParam = function () {
    function MissingParam(value0) {
      this.value0 = value0;
    }

    ;

    MissingParam.create = function (value0) {
      return new MissingParam(value0);
    };

    return MissingParam;
  }();

  var EndOfPath = function () {
    function EndOfPath() {}

    ;
    EndOfPath.value = new EndOfPath();
    return EndOfPath;
  }();

  var Fail = function () {
    function Fail(value0) {
      this.value0 = value0;
    }

    ;

    Fail.create = function (value0) {
      return new Fail(value0);
    };

    return Fail;
  }();

  var Success = function () {
    function Success(value0, value1) {
      this.value0 = value0;
      this.value1 = value1;
    }

    ;

    Success.create = function (value0) {
      return function (value1) {
        return new Success(value0, value1);
      };
    };

    return Success;
  }();

  var Alt = function () {
    function Alt(value0) {
      this.value0 = value0;
    }

    ;

    Alt.create = function (value0) {
      return new Alt(value0);
    };

    return Alt;
  }();

  var Chomp = function () {
    function Chomp(value0) {
      this.value0 = value0;
    }

    ;

    Chomp.create = function (value0) {
      return new Chomp(value0);
    };

    return Chomp;
  }();

  var Prefix = function () {
    function Prefix(value0, value1) {
      this.value0 = value0;
      this.value1 = value1;
    }

    ;

    Prefix.create = function (value0) {
      return function (value1) {
        return new Prefix(value0, value1);
      };
    };

    return Prefix;
  }();

  var take = new Chomp(function (state) {
    var v = Data_Array.uncons(state.segments);

    if (v instanceof Data_Maybe.Just) {
      return new Success({
        segments: v.value0.tail,
        params: state.params,
        hash: state.hash
      }, v.value0.head);
    }

    ;
    return new Fail(EndOfPath.value);
  });
  var prefix = Prefix.create;

  var parsePath = function () {
    var toRouteState = function toRouteState(v) {
      return {
        segments: v.value0.value0,
        params: v.value0.value1,
        hash: v.value1
      };
    };

    var splitNonEmpty = function splitNonEmpty(v) {
      return function (v1) {
        if (v1 === "") {
          return [];
        }

        ;
        return Data_String_Common.split(v)(v1);
      };
    };

    var splitSegments = function splitSegments($235) {
      return function (v) {
        if (v.length === 2 && v[0] === "" && v[1] === "") {
          return [""];
        }

        ;
        return Data_Functor.map(Data_Functor.functorArray)(Global_Unsafe.unsafeDecodeURIComponent)(v);
      }(splitNonEmpty("/")($235));
    };

    var splitAt = function splitAt(k) {
      return function (p) {
        return function (str) {
          var v = Data_String_CodeUnits.indexOf(p)(str);

          if (v instanceof Data_Maybe.Just) {
            return new Data_Tuple.Tuple(Data_String_CodeUnits.take(v.value0)(str), Data_String_CodeUnits.drop(v.value0 + Data_String_CodeUnits.length(p) | 0)(str));
          }

          ;

          if (v instanceof Data_Maybe.Nothing) {
            return k(str);
          }

          ;
          throw new Error("Failed pattern match at Routing.Duplex.Parser (line 183, column 5 - line 185, column 23): " + [v.constructor.name]);
        };
      };
    };

    var splitKeyValue = function splitKeyValue($236) {
      return Data_Bifunctor.bimap(Data_Tuple.bifunctorTuple)(Global_Unsafe.unsafeDecodeURIComponent)(Global_Unsafe.unsafeDecodeURIComponent)(splitAt(Data_Function.flip(Data_Tuple.Tuple.create)(""))("=")($236));
    };

    var splitParams = function splitParams($237) {
      return Data_Functor.map(Data_Functor.functorArray)(splitKeyValue)(splitNonEmpty("&")($237));
    };

    var splitPath = function splitPath($238) {
      return Data_Bifunctor.bimap(Data_Tuple.bifunctorTuple)(splitSegments)(splitParams)(splitAt(Data_Function.flip(Data_Tuple.Tuple.create)(""))("?")($238));
    };

    return function ($239) {
      return toRouteState(Data_Bifunctor.lmap(Data_Tuple.bifunctorTuple)(splitPath)(splitAt(Data_Function.flip(Data_Tuple.Tuple.create)(""))("#")($239)));
    };
  }();

  var param = function param(key) {
    return new Chomp(function (state) {
      var v = Data_Tuple.lookup(Data_Foldable.foldableArray)(Data_Eq.eqString)(key)(state.params);

      if (v instanceof Data_Maybe.Just) {
        return new Success(state, v.value0);
      }

      ;
      return Fail.create(new MissingParam(key));
    });
  };

  var hash = new Chomp(function (state) {
    return new Success(state, state.hash);
  });
  var functorRouteResult = new Data_Functor.Functor(function (f) {
    return function (m) {
      if (m instanceof Fail) {
        return new Fail(m.value0);
      }

      ;

      if (m instanceof Success) {
        return new Success(m.value0, f(m.value1));
      }

      ;
      throw new Error("Failed pattern match at Routing.Duplex.Parser (line 53, column 8 - line 53, column 58): " + [m.constructor.name]);
    };
  });
  var functorRouteParser = new Data_Functor.Functor(function (f) {
    return function (m) {
      if (m instanceof Alt) {
        return new Alt(Data_Functor.map(Data_Array_NonEmpty_Internal.functorNonEmptyArray)(Data_Functor.map(functorRouteParser)(f))(m.value0));
      }

      ;

      if (m instanceof Chomp) {
        return new Chomp(Data_Functor.map(Data_Functor.functorFn)(Data_Functor.map(functorRouteResult)(f))(m.value0));
      }

      ;

      if (m instanceof Prefix) {
        return new Prefix(m.value0, Data_Functor.map(functorRouteParser)(f)(m.value1));
      }

      ;
      throw new Error("Failed pattern match at Routing.Duplex.Parser (line 72, column 8 - line 72, column 58): " + [m.constructor.name]);
    };
  });
  var end = new Chomp(function (state) {
    var v = Data_Array.head(state.segments);

    if (v instanceof Data_Maybe.Nothing) {
      return new Success(state, Data_Unit.unit);
    }

    ;

    if (v instanceof Data_Maybe.Just) {
      return new Fail(new ExpectedEndOfPath(v.value0));
    }

    ;
    throw new Error("Failed pattern match at Routing.Duplex.Parser (line 254, column 3 - line 256, column 45): " + [v.constructor.name]);
  });

  var chompPrefix = function chompPrefix(pre) {
    return function (state) {
      var v = Data_Array.head(state.segments);

      if (v instanceof Data_Maybe.Just && pre === v.value0) {
        return new Success({
          segments: Data_Array.drop(1)(state.segments),
          params: state.params,
          hash: state.hash
        }, Data_Unit.unit);
      }

      ;

      if (v instanceof Data_Maybe.Just) {
        return Fail.create(new Expected(pre, v.value0));
      }

      ;
      return Fail.create(EndOfPath.value);
    };
  };

  var runRouteParser = function () {
    var goAlt = function goAlt(v) {
      return function (v1) {
        return function (v2) {
          if (v1 instanceof Fail) {
            return runRouteParser(v)(v2);
          }

          ;
          return v1;
        };
      };
    };

    var go = function go($copy_state) {
      return function ($copy_v) {
        var $tco_var_state = $copy_state;
        var $tco_done = false;
        var $tco_result;

        function $tco_loop(state, v) {
          if (v instanceof Alt) {
            $tco_done = true;
            return Data_Foldable.foldl(Data_Array_NonEmpty_Internal.foldableNonEmptyArray)(goAlt(state))(new Fail(EndOfPath.value))(v.value0);
          }

          ;

          if (v instanceof Chomp) {
            $tco_done = true;
            return v.value0(state);
          }

          ;

          if (v instanceof Prefix) {
            var v1 = chompPrefix(v.value0)(state);

            if (v1 instanceof Fail) {
              $tco_done = true;
              return new Fail(v1.value0);
            }

            ;

            if (v1 instanceof Success) {
              $tco_var_state = v1.value0;
              $copy_v = v.value1;
              return;
            }

            ;
            throw new Error("Failed pattern match at Routing.Duplex.Parser (line 149, column 7 - line 151, column 40): " + [v1.constructor.name]);
          }

          ;
          throw new Error("Failed pattern match at Routing.Duplex.Parser (line 145, column 14 - line 151, column 40): " + [v.constructor.name]);
        }

        ;

        while (!$tco_done) {
          $tco_result = $tco_loop($tco_var_state, $copy_v);
        }

        ;
        return $tco_result;
      };
    };

    return go;
  }();

  var run = function run(p) {
    return function ($241) {
      return function (v) {
        if (v instanceof Fail) {
          return new Data_Either.Left(v.value0);
        }

        ;

        if (v instanceof Success) {
          return new Data_Either.Right(v.value1);
        }

        ;
        throw new Error("Failed pattern match at Routing.Duplex.Parser (line 188, column 49 - line 190, column 29): " + [v.constructor.name]);
      }(Data_Function.flip(runRouteParser)(p)(parsePath($241)));
    };
  };

  var applyRouteParser = new Control_Apply.Apply(function () {
    return functorRouteParser;
  }, function (fx) {
    return function (x) {
      return new Chomp(function (state) {
        var v = runRouteParser(state)(fx);

        if (v instanceof Fail) {
          return new Fail(v.value0);
        }

        ;

        if (v instanceof Success) {
          return Data_Functor.map(functorRouteResult)(v.value1)(runRouteParser(v.value0)(x));
        }

        ;
        throw new Error("Failed pattern match at Routing.Duplex.Parser (line 76, column 5 - line 78, column 56): " + [v.constructor.name]);
      });
    };
  });
  var applicativeRouteParser = new Control_Applicative.Applicative(function () {
    return applyRouteParser;
  }, function ($242) {
    return Chomp.create(Data_Function.flip(Success.create)($242));
  });

  var altSnoc = function altSnoc(ls) {
    return function (v) {
      var v1 = function v1(v2) {
        return Data_Array_NonEmpty.snoc(ls)(v);
      };

      if (v instanceof Prefix) {
        var $196 = Data_Array_NonEmpty.last(ls);

        if ($196 instanceof Prefix) {
          var $197 = v.value0 === $196.value0;

          if ($197) {
            return Data_Array_NonEmpty["snoc'"](Data_Array_NonEmpty.init(ls))(new Prefix(v.value0, Control_Alt.alt(altRouteParser)($196.value1)(v.value1)));
          }

          ;
          return v1(true);
        }

        ;
        return v1(true);
      }

      ;
      return v1(true);
    };
  };

  var altRouteParser = new Control_Alt.Alt(function () {
    return functorRouteParser;
  }, function (v) {
    return function (v1) {
      if (v instanceof Alt && v1 instanceof Alt) {
        return new Alt(altAppend(v.value0)(v1.value0));
      }

      ;

      if (v instanceof Alt) {
        return new Alt(altSnoc(v.value0)(v1));
      }

      ;

      if (v1 instanceof Alt) {
        return new Alt(altCons(v)(v1.value0));
      }

      ;

      if (v instanceof Prefix && v1 instanceof Prefix && v.value0 === v1.value0) {
        return new Prefix(v.value0, Control_Alt.alt(altRouteParser)(v.value1)(v1.value1));
      }

      ;
      return new Alt(Data_Array_NonEmpty.cons(v)(Data_Array_NonEmpty.singleton(v1)));
    };
  });

  var altCons = function altCons(v) {
    return function (rs) {
      var v1 = function v1(v2) {
        return Data_Array_NonEmpty.cons(v)(rs);
      };

      if (v instanceof Prefix) {
        var $216 = Data_Array_NonEmpty.head(rs);

        if ($216 instanceof Prefix) {
          var $217 = v.value0 === $216.value0;

          if ($217) {
            return Data_Array_NonEmpty["cons'"](new Prefix(v.value0, Control_Alt.alt(altRouteParser)(v.value1)($216.value1)))(Data_Array_NonEmpty.tail(rs));
          }

          ;
          return v1(true);
        }

        ;
        return v1(true);
      }

      ;
      return v1(true);
    };
  };

  var altAppend = function altAppend($copy_ls) {
    return function ($copy_rs) {
      var $tco_var_ls = $copy_ls;
      var $tco_done = false;
      var $tco_result;

      function $tco_loop(ls, rs) {
        var v = function v(v1) {
          if (Data_Boolean.otherwise) {
            return Data_Semigroup.append(Data_Array_NonEmpty_Internal.semigroupNonEmptyArray)(ls)(rs);
          }

          ;
          throw new Error("Failed pattern match at Routing.Duplex.Parser (line 98, column 1 - line 101, column 32): " + [ls.constructor.name, rs.constructor.name]);
        };

        var $226 = Data_Array_NonEmpty.last(ls);

        if ($226 instanceof Prefix) {
          var $227 = Data_Array_NonEmpty.head(rs);

          if ($227 instanceof Prefix) {
            var $228 = $226.value0 === $227.value0;

            if ($228) {
              var rs$prime = Data_Array_NonEmpty["cons'"](new Prefix($226.value0, Control_Alt.alt(altRouteParser)($226.value1)($227.value1)))(Data_Array_NonEmpty.tail(rs));
              var v1 = Data_Array_NonEmpty.fromArray(Data_Array_NonEmpty.init(ls));

              if (v1 instanceof Data_Maybe.Just) {
                $tco_var_ls = v1.value0;
                $copy_rs = rs$prime;
                return;
              }

              ;

              if (v1 instanceof Data_Maybe.Nothing) {
                $tco_done = true;
                return rs$prime;
              }

              ;
              throw new Error("Failed pattern match at Routing.Duplex.Parser (line 110, column 9 - line 112, column 26): " + [v1.constructor.name]);
            }

            ;
            $tco_done = true;
            return v(true);
          }

          ;
          $tco_done = true;
          return v(true);
        }

        ;
        $tco_done = true;
        return v(true);
      }

      ;

      while (!$tco_done) {
        $tco_result = $tco_loop($tco_var_ls, $copy_rs);
      }

      ;
      return $tco_result;
    };
  };

  var $$default = function $$default($243) {
    return Data_Function.flip(Control_Alt.alt(altRouteParser))(Control_Applicative.pure(applicativeRouteParser)($243));
  };

  var optional = function optional($245) {
    return $$default(Data_Maybe.Nothing.value)(Data_Functor.map(functorRouteParser)(Data_Maybe.Just.create)($245));
  };

  exports["Expected"] = Expected;
  exports["ExpectedEndOfPath"] = ExpectedEndOfPath;
  exports["MissingParam"] = MissingParam;
  exports["EndOfPath"] = EndOfPath;
  exports["Fail"] = Fail;
  exports["Success"] = Success;
  exports["Alt"] = Alt;
  exports["Chomp"] = Chomp;
  exports["Prefix"] = Prefix;
  exports["runRouteParser"] = runRouteParser;
  exports["parsePath"] = parsePath;
  exports["run"] = run;
  exports["prefix"] = prefix;
  exports["take"] = take;
  exports["param"] = param;
  exports["optional"] = optional;
  exports["hash"] = hash;
  exports["end"] = end;
  exports["functorRouteResult"] = functorRouteResult;
  exports["functorRouteParser"] = functorRouteParser;
  exports["applyRouteParser"] = applyRouteParser;
  exports["applicativeRouteParser"] = applicativeRouteParser;
  exports["altRouteParser"] = altRouteParser;
})(PS["Routing.Duplex.Parser"] = PS["Routing.Duplex.Parser"] || {});

(function (exports) {
  // Generated by purs version 0.12.3
  "use strict";

  var Control_Category = PS["Control.Category"];
  var Control_Semigroupoid = PS["Control.Semigroupoid"];
  var Data_Array = PS["Data.Array"];
  var Data_Boolean = PS["Data.Boolean"];
  var Data_Function = PS["Data.Function"];
  var Data_Functor = PS["Data.Functor"];
  var Data_Monoid = PS["Data.Monoid"];
  var Data_Newtype = PS["Data.Newtype"];
  var Data_Semigroup = PS["Data.Semigroup"];
  var Data_String = PS["Data.String"];
  var Data_String_Common = PS["Data.String.Common"];
  var Data_Tuple = PS["Data.Tuple"];
  var Global_Unsafe = PS["Global.Unsafe"];
  var Prelude = PS["Prelude"];
  var Routing_Duplex_Types = PS["Routing.Duplex.Types"];

  var RoutePrinter = function RoutePrinter(x) {
    return x;
  };

  var semigroupRoutePrinter = new Data_Semigroup.Semigroup(function (v) {
    return function (v1) {
      return function ($27) {
        return v1(v($27));
      };
    };
  });

  var put = function put(str) {
    return function (state) {
      return {
        segments: Data_Array.snoc(state.segments)(str),
        params: state.params,
        hash: state.hash
      };
    };
  };

  var printPath = function printPath(v) {
    var printSegments = function printSegments(v1) {
      if (v1.length === 1 && v1[0] === "") {
        return "/";
      }

      ;
      return Data_String_Common.joinWith("/")(Data_Functor.map(Data_Functor.functorArray)(Global_Unsafe.unsafeEncodeURIComponent)(v1));
    };

    var printParam = function printParam(key) {
      return function (v1) {
        if (v1 === "") {
          return Global_Unsafe.unsafeEncodeURIComponent(key);
        }

        ;
        return Global_Unsafe.unsafeEncodeURIComponent(key) + ("=" + Global_Unsafe.unsafeEncodeURIComponent(v1));
      };
    };

    var printParams = function printParams(v1) {
      if (v1.length === 0) {
        return "";
      }

      ;
      return "?" + Data_String_Common.joinWith("&")(Data_Functor.map(Data_Functor.functorArray)(Data_Tuple.uncurry(printParam))(v1));
    };

    var printHash = function printHash(v1) {
      if (v1 === "") {
        return "";
      }

      ;
      return "#" + v1;
    };

    return printSegments(v.segments) + (printParams(v.params) + printHash(v.hash));
  };

  var param = function param(key) {
    return function (val) {
      return function (state) {
        return {
          segments: state.segments,
          params: Data_Array.cons(new Data_Tuple.Tuple(key, val))(state.params),
          hash: state.hash
        };
      };
    };
  };

  var newtypeRoutePrinter = new Data_Newtype.Newtype(function (n) {
    return n;
  }, RoutePrinter);

  var run = function run($28) {
    return printPath(Data_Newtype.unwrap(newtypeRoutePrinter)($28)(Routing_Duplex_Types.emptyRouteState));
  };

  var monoidRoutePRinter = new Data_Monoid.Monoid(function () {
    return semigroupRoutePrinter;
  }, Control_Category.identity(Control_Category.categoryFn));

  var hash = function hash(h) {
    return function (v) {
      return {
        segments: v.segments,
        params: v.params,
        hash: h
      };
    };
  };

  exports["RoutePrinter"] = RoutePrinter;
  exports["put"] = put;
  exports["param"] = param;
  exports["hash"] = hash;
  exports["run"] = run;
  exports["printPath"] = printPath;
  exports["newtypeRoutePrinter"] = newtypeRoutePrinter;
  exports["semigroupRoutePrinter"] = semigroupRoutePrinter;
  exports["monoidRoutePRinter"] = monoidRoutePRinter;
})(PS["Routing.Duplex.Printer"] = PS["Routing.Duplex.Printer"] || {});

(function (exports) {
  // Generated by purs version 0.12.3
  "use strict";

  var Control_Alt = PS["Control.Alt"];
  var Control_Alternative = PS["Control.Alternative"];
  var Control_Applicative = PS["Control.Applicative"];
  var Control_Apply = PS["Control.Apply"];
  var Control_Category = PS["Control.Category"];
  var Control_Semigroupoid = PS["Control.Semigroupoid"];
  var Data_Either = PS["Data.Either"];
  var Data_Foldable = PS["Data.Foldable"];
  var Data_Function = PS["Data.Function"];
  var Data_Functor = PS["Data.Functor"];
  var Data_Maybe = PS["Data.Maybe"];
  var Data_Monoid = PS["Data.Monoid"];
  var Data_Profunctor = PS["Data.Profunctor"];
  var Data_Semigroup = PS["Data.Semigroup"];
  var Data_Show = PS["Data.Show"];
  var Data_String = PS["Data.String"];
  var Data_String_Common = PS["Data.String.Common"];
  var Data_String_Pattern = PS["Data.String.Pattern"];
  var Data_Symbol = PS["Data.Symbol"];
  var Data_Unit = PS["Data.Unit"];
  var Prelude = PS["Prelude"];
  var Record = PS["Record"];
  var Routing_Duplex_Parser = PS["Routing.Duplex.Parser"];
  var Routing_Duplex_Printer = PS["Routing.Duplex.Printer"];
  var Type_Data_RowList = PS["Type.Data.RowList"];

  var RouteDuplex = function () {
    function RouteDuplex(value0, value1) {
      this.value0 = value0;
      this.value1 = value1;
    }

    ;

    RouteDuplex.create = function (value0) {
      return function (value1) {
        return new RouteDuplex(value0, value1);
      };
    };

    return RouteDuplex;
  }();

  var suffix = function suffix(v) {
    return function (s) {
      return new RouteDuplex(function (a) {
        return Data_Semigroup.append(Routing_Duplex_Printer.semigroupRoutePrinter)(v.value0(a))(Routing_Duplex_Printer.put(s));
      }, Control_Apply.applyFirst(Routing_Duplex_Parser.applyRouteParser)(v.value1)(Routing_Duplex_Parser.prefix(s)(Control_Applicative.pure(Routing_Duplex_Parser.applicativeRouteParser)(Data_Unit.unit))));
    };
  };

  var segment = new RouteDuplex(Routing_Duplex_Printer.put, Routing_Duplex_Parser.take);
  var record = new RouteDuplex(Data_Monoid.mempty(Data_Monoid.monoidFn(Routing_Duplex_Printer.monoidRoutePRinter)), Control_Applicative.pure(Routing_Duplex_Parser.applicativeRouteParser)({}));

  var prop = function prop(dictIsSymbol) {
    return function (dictCons) {
      return function (dictCons1) {
        return function (dictLacks) {
          return function (sym) {
            return function (v) {
              return function (v1) {
                return new RouteDuplex(function (r) {
                  return Data_Semigroup.append(Routing_Duplex_Printer.semigroupRoutePrinter)(v1.value0(r))(v.value0(Record.get(dictIsSymbol)(dictCons)(sym)(r)));
                }, Control_Apply.apply(Routing_Duplex_Parser.applyRouteParser)(Data_Functor.map(Routing_Duplex_Parser.functorRouteParser)(Data_Function.flip(Record.insert(dictIsSymbol)(dictLacks)(dictCons1)(sym)))(v1.value1))(v.value1));
              };
            };
          };
        };
      };
    };
  };

  var profunctorRouteDuplex = new Data_Profunctor.Profunctor(function (f) {
    return function (g) {
      return function (v) {
        return new RouteDuplex(function ($100) {
          return v.value0(f($100));
        }, Data_Functor.map(Routing_Duplex_Parser.functorRouteParser)(g)(v.value1));
      };
    };
  });

  var print = function print(v) {
    return function ($101) {
      return Routing_Duplex_Printer.run(v.value0($101));
    };
  };

  var prefix = function prefix(s) {
    return function (v) {
      return new RouteDuplex(function (a) {
        return Data_Semigroup.append(Routing_Duplex_Printer.semigroupRoutePrinter)(Routing_Duplex_Printer.put(s))(v.value0(a));
      }, Routing_Duplex_Parser.prefix(s)(v.value1));
    };
  };

  var path = function path($102) {
    return Data_Function.flip(Data_Foldable.foldr(Data_Foldable.foldableArray)(prefix))(Data_String_Common.split("/")($102));
  };

  var root = path("");

  var parse = function parse(v) {
    return Routing_Duplex_Parser.run(v.value1);
  };

  var param = function param(p) {
    return new RouteDuplex(Routing_Duplex_Printer.param(p), Routing_Duplex_Parser.param(p));
  };

  var optional = function optional(v) {
    return new RouteDuplex(Data_Foldable.foldMap(Data_Foldable.foldableMaybe)(Routing_Duplex_Printer.monoidRoutePRinter)(v.value0), Routing_Duplex_Parser.optional(v.value1));
  };

  var functorRouteDuplex = new Data_Functor.Functor(function (f) {
    return function (m) {
      return new RouteDuplex(m.value0, Data_Functor.map(Routing_Duplex_Parser.functorRouteParser)(f)(m.value1));
    };
  });

  var end = function end(v) {
    return new RouteDuplex(v.value0, Control_Apply.applyFirst(Routing_Duplex_Parser.applyRouteParser)(v.value1)(Routing_Duplex_Parser.end));
  };

  var applyRouteDuplex = new Control_Apply.Apply(function () {
    return functorRouteDuplex;
  }, function (v) {
    return function (v1) {
      return new RouteDuplex(Control_Apply.apply(Control_Apply.applyFn)(Data_Functor.map(Data_Functor.functorFn)(Data_Semigroup.append(Routing_Duplex_Printer.semigroupRoutePrinter))(v.value0))(v1.value0), Control_Apply.apply(Routing_Duplex_Parser.applyRouteParser)(v.value1)(v1.value1));
    };
  });
  var applicativeRouteDuplex = new Control_Applicative.Applicative(function () {
    return applyRouteDuplex;
  }, function ($104) {
    return RouteDuplex.create(Data_Function["const"](Data_Monoid.mempty(Routing_Duplex_Printer.monoidRoutePRinter)))(Control_Applicative.pure(Routing_Duplex_Parser.applicativeRouteParser)($104));
  });
  exports["RouteDuplex"] = RouteDuplex;
  exports["parse"] = parse;
  exports["print"] = print;
  exports["prefix"] = prefix;
  exports["suffix"] = suffix;
  exports["path"] = path;
  exports["root"] = root;
  exports["end"] = end;
  exports["segment"] = segment;
  exports["param"] = param;
  exports["optional"] = optional;
  exports["record"] = record;
  exports["prop"] = prop;
  exports["functorRouteDuplex"] = functorRouteDuplex;
  exports["applyRouteDuplex"] = applyRouteDuplex;
  exports["applicativeRouteDuplex"] = applicativeRouteDuplex;
  exports["profunctorRouteDuplex"] = profunctorRouteDuplex;
})(PS["Routing.Duplex"] = PS["Routing.Duplex"] || {});

(function (exports) {
  // Generated by purs version 0.12.3
  "use strict";

  var Control_Alt = PS["Control.Alt"];
  var Control_Applicative = PS["Control.Applicative"];
  var Control_Apply = PS["Control.Apply"];
  var Control_Category = PS["Control.Category"];
  var Control_Semigroupoid = PS["Control.Semigroupoid"];
  var Data_Function = PS["Data.Function"];
  var Data_Functor = PS["Data.Functor"];
  var Data_Generic_Rep = PS["Data.Generic.Rep"];
  var Data_Profunctor = PS["Data.Profunctor"];
  var Data_Semigroup = PS["Data.Semigroup"];
  var Data_Symbol = PS["Data.Symbol"];
  var Prelude = PS["Prelude"];
  var Record = PS["Record"];
  var Routing_Duplex = PS["Routing.Duplex"];
  var Routing_Duplex_Parser = PS["Routing.Duplex.Parser"];
  var Routing_Duplex_Printer = PS["Routing.Duplex.Printer"];

  var GRouteDuplex = function GRouteDuplex(gRouteDuplex) {
    this.gRouteDuplex = gRouteDuplex;
  };

  var GRouteDuplexCtr = function GRouteDuplexCtr(gRouteDuplexCtr) {
    this.gRouteDuplexCtr = gRouteDuplexCtr;
  };

  var noArgs = Control_Applicative.pure(Routing_Duplex.applicativeRouteDuplex)(Data_Generic_Rep.NoArguments.value);
  var gRouteNoArguments = new GRouteDuplexCtr(Control_Category.identity(Control_Category.categoryFn));

  var gRouteDuplexCtr = function gRouteDuplexCtr(dict) {
    return dict.gRouteDuplexCtr;
  };

  var gRouteDuplex = function gRouteDuplex(dict) {
    return dict.gRouteDuplex;
  };

  var gRouteSum = function gRouteSum(dictGRouteDuplex) {
    return function (dictGRouteDuplex1) {
      return new GRouteDuplex(function (r) {
        var v = gRouteDuplex(dictGRouteDuplex)(r);
        var v1 = gRouteDuplex(dictGRouteDuplex1)(r);

        var enc = function enc(v2) {
          if (v2 instanceof Data_Generic_Rep.Inl) {
            return v.value0(v2.value0);
          }

          ;

          if (v2 instanceof Data_Generic_Rep.Inr) {
            return v1.value0(v2.value0);
          }

          ;
          throw new Error("Failed pattern match at Routing.Duplex.Generic (line 32, column 11 - line 34, column 22): " + [v2.constructor.name]);
        };

        var dec = Control_Alt.alt(Routing_Duplex_Parser.altRouteParser)(Data_Functor.map(Routing_Duplex_Parser.functorRouteParser)(Data_Generic_Rep.Inl.create)(v.value1))(Data_Functor.map(Routing_Duplex_Parser.functorRouteParser)(Data_Generic_Rep.Inr.create)(v1.value1));
        return new Routing_Duplex.RouteDuplex(enc, dec);
      });
    };
  };

  var sum = function sum(dictGeneric) {
    return function (dictGRouteDuplex) {
      return function ($48) {
        return Data_Profunctor.dimap(Routing_Duplex.profunctorRouteDuplex)(Data_Generic_Rep.from(dictGeneric))(Data_Generic_Rep.to(dictGeneric))(gRouteDuplex(dictGRouteDuplex)($48));
      };
    };
  };

  var gRouteConstructor = function gRouteConstructor(dictIsSymbol) {
    return function (dictCons) {
      return function (dictGRouteDuplexCtr) {
        return new GRouteDuplex(function (r) {
          var v = Routing_Duplex.end(gRouteDuplexCtr(dictGRouteDuplexCtr)(Record.get(dictIsSymbol)(dictCons)(Data_Symbol.SProxy.value)(r)));

          var enc = function enc(v1) {
            return v.value0(v1);
          };

          var dec = Data_Functor.map(Routing_Duplex_Parser.functorRouteParser)(Data_Generic_Rep.Constructor)(v.value1);
          return new Routing_Duplex.RouteDuplex(enc, dec);
        });
      };
    };
  };

  var gRouteArgument = new GRouteDuplexCtr(Control_Category.identity(Control_Category.categoryFn));
  var gRouteAll = new GRouteDuplexCtr(function (v) {
    return new Routing_Duplex.RouteDuplex(function (v1) {
      return v.value0(v1);
    }, Data_Functor.map(Routing_Duplex_Parser.functorRouteParser)(Data_Generic_Rep.Argument)(v.value1));
  });
  exports["gRouteDuplex"] = gRouteDuplex;
  exports["gRouteDuplexCtr"] = gRouteDuplexCtr;
  exports["sum"] = sum;
  exports["GRouteDuplex"] = GRouteDuplex;
  exports["GRouteDuplexCtr"] = GRouteDuplexCtr;
  exports["noArgs"] = noArgs;
  exports["gRouteSum"] = gRouteSum;
  exports["gRouteConstructor"] = gRouteConstructor;
  exports["gRouteNoArguments"] = gRouteNoArguments;
  exports["gRouteArgument"] = gRouteArgument;
  exports["gRouteAll"] = gRouteAll;
})(PS["Routing.Duplex.Generic"] = PS["Routing.Duplex.Generic"] || {});

(function (exports) {
  // Generated by purs version 0.12.3
  "use strict";

  var Control_Applicative = PS["Control.Applicative"];
  var Control_Semigroupoid = PS["Control.Semigroupoid"];
  var Data_Function = PS["Data.Function"];
  var Data_Generic_Rep = PS["Data.Generic.Rep"];
  var Data_Unit = PS["Data.Unit"];
  var Prelude = PS["Prelude"];
  var Routing_Duplex = PS["Routing.Duplex"];
  var Routing_Duplex_Generic = PS["Routing.Duplex.Generic"];

  var GSep = function GSep(gsep) {
    this.gsep = gsep;
  };

  var gsepStringRoute = function gsepStringRoute(dictGRouteDuplexCtr) {
    return new GSep(function (a) {
      return function ($5) {
        return Routing_Duplex.prefix(a)(Routing_Duplex_Generic.gRouteDuplexCtr(dictGRouteDuplexCtr)($5));
      };
    });
  };

  var gsepRouteString = function gsepRouteString(dictGRouteDuplexCtr) {
    return new GSep(function ($6) {
      return Routing_Duplex.suffix(Routing_Duplex_Generic.gRouteDuplexCtr(dictGRouteDuplexCtr)($6));
    });
  };

  var gsep = function gsep(dict) {
    return dict.gsep;
  };

  exports["gsep"] = gsep;
  exports["GSep"] = GSep;
  exports["gsepStringRoute"] = gsepStringRoute;
  exports["gsepRouteString"] = gsepRouteString;
})(PS["Routing.Duplex.Generic.Syntax"] = PS["Routing.Duplex.Generic.Syntax"] || {});

(function (exports) {
  "use strict";

  exports.mutationObserver = function (cb) {
    return function () {
      return new MutationObserver(function (mr, mo) {
        return cb(mr)(mo)();
      });
    };
  };

  exports._observe = function (node) {
    return function (config) {
      return function (mo) {
        return function () {
          return mo.observe(node, config);
        };
      };
    };
  };
})(PS["Web.DOM.MutationObserver"] = PS["Web.DOM.MutationObserver"] || {});

(function (exports) {
  "use strict";

  var getEffProp = function getEffProp(name) {
    return function (node) {
      return function () {
        return node[name];
      };
    };
  };

  exports.nodeValue = getEffProp("nodeValue");

  exports.setNodeValue = function (value) {
    return function (node) {
      return function () {
        node.nodeValue = value;
        return {};
      };
    };
  };
})(PS["Web.DOM.Node"] = PS["Web.DOM.Node"] || {});

(function (exports) {
  // Generated by purs version 0.12.3
  "use strict";

  var $foreign = PS["Web.DOM.Node"];
  var Control_Semigroupoid = PS["Control.Semigroupoid"];
  var Data_Enum = PS["Data.Enum"];
  var Data_Functor = PS["Data.Functor"];
  var Data_Maybe = PS["Data.Maybe"];
  var Data_Nullable = PS["Data.Nullable"];
  var Effect = PS["Effect"];
  var Prelude = PS["Prelude"];
  var Unsafe_Coerce = PS["Unsafe.Coerce"];
  var Web_DOM_Document = PS["Web.DOM.Document"];
  var Web_DOM_Element = PS["Web.DOM.Element"];
  var Web_DOM_Internal_Types = PS["Web.DOM.Internal.Types"];
  var Web_DOM_NodeType = PS["Web.DOM.NodeType"];
  var Web_Event_EventTarget = PS["Web.Event.EventTarget"];
  var Web_Internal_FFI = PS["Web.Internal.FFI"];
  exports["setNodeValue"] = $foreign.setNodeValue;
})(PS["Web.DOM.Node"] = PS["Web.DOM.Node"] || {});

(function (exports) {
  // Generated by purs version 0.12.3
  "use strict";

  var $foreign = PS["Web.DOM.MutationObserver"];
  var Effect = PS["Effect"];
  var Prelude = PS["Prelude"];
  var Web_DOM_MutationRecord = PS["Web.DOM.MutationRecord"];
  var Web_DOM_Node = PS["Web.DOM.Node"];

  var observe = function observe(dictUnion) {
    return $foreign["_observe"];
  };

  exports["observe"] = observe;
  exports["mutationObserver"] = $foreign.mutationObserver;
})(PS["Web.DOM.MutationObserver"] = PS["Web.DOM.MutationObserver"] || {});

(function (exports) {
  // Generated by purs version 0.12.3
  "use strict";

  var Web_Event_Event = PS["Web.Event.Event"];
  var popstate = "popstate";
  exports["popstate"] = popstate;
})(PS["Web.HTML.Event.PopStateEvent.EventTypes"] = PS["Web.HTML.Event.PopStateEvent.EventTypes"] || {});

(function (exports) {
  // Generated by purs version 0.12.3
  "use strict";

  var Control_Applicative = PS["Control.Applicative"];
  var Control_Bind = PS["Control.Bind"];
  var Control_Semigroupoid = PS["Control.Semigroupoid"];
  var Data_Either = PS["Data.Either"];
  var Data_Foldable = PS["Data.Foldable"];
  var Data_Function = PS["Data.Function"];
  var Data_Functor = PS["Data.Functor"];
  var Data_Map = PS["Data.Map"];
  var Data_Map_Internal = PS["Data.Map.Internal"];
  var Data_Maybe = PS["Data.Maybe"];
  var Data_Ord = PS["Data.Ord"];
  var Data_Semigroup = PS["Data.Semigroup"];
  var Data_Semiring = PS["Data.Semiring"];
  var Data_Show = PS["Data.Show"];
  var Effect = PS["Effect"];
  var Effect_Ref = PS["Effect.Ref"];
  var Foreign = PS["Foreign"];
  var Prelude = PS["Prelude"];
  var Routing = PS["Routing"];
  var Routing_Match = PS["Routing.Match"];
  var Web_DOM_Document = PS["Web.DOM.Document"];
  var Web_DOM_MutationObserver = PS["Web.DOM.MutationObserver"];
  var Web_DOM_Node = PS["Web.DOM.Node"];
  var Web_DOM_Text = PS["Web.DOM.Text"];
  var Web_Event_EventTarget = PS["Web.Event.EventTarget"];
  var Web_HTML = PS["Web.HTML"];
  var Web_HTML_Event_PopStateEvent_EventTypes = PS["Web.HTML.Event.PopStateEvent.EventTypes"];
  var Web_HTML_HTMLDocument = PS["Web.HTML.HTMLDocument"];
  var Web_HTML_History = PS["Web.HTML.History"];
  var Web_HTML_Location = PS["Web.HTML.Location"];
  var Web_HTML_Window = PS["Web.HTML.Window"];

  var makeImmediate = function makeImmediate(run) {
    return function __do() {
      var v = function __do() {
        var $37 = Web_HTML.window();
        return Data_Functor.map(Effect.functorEffect)(Web_HTML_HTMLDocument.toDocument)(Web_HTML_Window.document($37))();
      }();

      var v1 = Effect_Ref["new"](new Data_Either.Right(0))();
      var v2 = Data_Functor.map(Effect.functorEffect)(Web_DOM_Text.toNode)(Web_DOM_Document.createTextNode("")(v))();
      var v3 = Web_DOM_MutationObserver.mutationObserver(function (v3) {
        return function (v4) {
          return function __do() {
            Effect_Ref.modify_(Data_Either.either(function ($38) {
              return Data_Either.Right.create(1 + $38 | 0);
            })(Data_Either.Right.create))(v1)();
            return run();
          };
        };
      })();
      Web_DOM_MutationObserver.observe()(v2)({
        characterData: true
      })(v3)();
      return Control_Bind.bind(Effect.bindEffect)(Effect_Ref.read(v1))(Data_Foldable.traverse_(Effect.applicativeEffect)(Data_Either.foldableEither)(function (tick) {
        return function __do() {
          Effect_Ref.write(new Data_Either.Left(tick + 1 | 0))(v1)();
          return Web_DOM_Node.setNodeValue(Data_Show.show(Data_Show.showInt)(tick))(v2)();
        };
      }));
    };
  };

  var makeInterface = function __do() {
    var v = Effect_Ref["new"](0)();
    var v1 = Effect_Ref["new"](Data_Map_Internal.empty)();

    var notify = function notify(ev) {
      return Control_Bind.bindFlipped(Effect.bindEffect)(Data_Foldable.traverse_(Effect.applicativeEffect)(Data_Map_Internal.foldableMap)(function (v2) {
        return v2(ev);
      }))(Effect_Ref.read(v1));
    };

    var locationState = function __do() {
      var v2 = Control_Bind.bind(Effect.bindEffect)(Web_HTML.window)(Web_HTML_Window.location)();
      var v3 = Control_Bind.bind(Effect.bindEffect)(Control_Bind.bind(Effect.bindEffect)(Web_HTML.window)(Web_HTML_Window.history))(Web_HTML_History.state)();
      var v4 = Web_HTML_Location.pathname(v2)();
      var v5 = Web_HTML_Location.search(v2)();
      var v6 = Web_HTML_Location.hash(v2)();
      var path = v4 + (v5 + v6);
      return {
        state: v3,
        pathname: v4,
        search: v5,
        hash: v6,
        path: path
      };
    };

    var listen = function listen(k) {
      return function __do() {
        var v2 = Effect_Ref.read(v)();
        Effect_Ref.write(v2 + 1 | 0)(v)();
        Effect_Ref.modify_(Data_Map_Internal.insert(Data_Ord.ordInt)(v2)(k))(v1)();
        return Effect_Ref.modify_(Data_Map_Internal["delete"](Data_Ord.ordInt)(v2))(v1);
      };
    };

    var v2 = makeImmediate(Control_Bind.bindFlipped(Effect.bindEffect)(notify)(locationState))();

    var stateFn = function stateFn(op) {
      return function (state) {
        return function (path) {
          return function __do() {
            Control_Bind.bind(Effect.bindEffect)(Control_Bind.bind(Effect.bindEffect)(Web_HTML.window)(Web_HTML_Window.history))(op(state)("")(path))();
            return v2();
          };
        };
      };
    };

    var v3 = Web_Event_EventTarget.eventListener(function (v3) {
      return Control_Bind.bindFlipped(Effect.bindEffect)(notify)(locationState);
    })();

    (function __do() {
      var $39 = Web_HTML.window();
      return Web_Event_EventTarget.addEventListener(Web_HTML_Event_PopStateEvent_EventTypes.popstate)(v3)(false)(Web_HTML_Window.toEventTarget($39))();
    })();

    return {
      pushState: stateFn(Web_HTML_History.pushState),
      replaceState: stateFn(Web_HTML_History.replaceState),
      locationState: locationState,
      listen: listen
    };
  };

  var foldLocations = function foldLocations(cb) {
    return function (init) {
      return function (psi) {
        return function __do() {
          var v = Control_Bind.bindFlipped(Effect.bindEffect)(Effect_Ref["new"])(Control_Bind.bindFlipped(Effect.bindEffect)(init)(psi.locationState))();
          return psi.listen(function (loc) {
            return Control_Bind.bindFlipped(Effect.bindEffect)(Data_Function.flip(Effect_Ref.write)(v))(Control_Bind.bindFlipped(Effect.bindEffect)(Data_Function.flip(cb)(loc))(Effect_Ref.read(v)));
          })();
        };
      };
    };
  };

  var foldPaths = function foldPaths(cb) {
    return function (init) {
      return foldLocations(function (a) {
        return function ($40) {
          return cb(a)(function (v) {
            return v.path;
          }($40));
        };
      })(function ($41) {
        return init(function (v) {
          return v.path;
        }($41));
      });
    };
  };

  var matchesWith = function matchesWith(dictFoldable) {
    return function (parser) {
      return function (cb) {
        var go = function go(a) {
          return function ($42) {
            return Data_Maybe.maybe(Control_Applicative.pure(Effect.applicativeEffect)(a))(function (b) {
              return Data_Functor.voidRight(Effect.functorEffect)(new Data_Maybe.Just(b))(cb(a)(b));
            })(Data_Foldable.indexl(dictFoldable)(0)(parser($42)));
          };
        };

        return foldPaths(go)(go(Data_Maybe.Nothing.value));
      };
    };
  };

  exports["makeInterface"] = makeInterface;
  exports["foldLocations"] = foldLocations;
  exports["foldPaths"] = foldPaths;
  exports["matchesWith"] = matchesWith;
})(PS["Routing.PushState"] = PS["Routing.PushState"] || {});

(function (exports) {
  // Generated by purs version 0.12.3
  "use strict";

  var Control_Semigroupoid = PS["Control.Semigroupoid"];
  var Data_Either = PS["Data.Either"];
  var Data_Function = PS["Data.Function"];
  var Data_Generic_Rep = PS["Data.Generic.Rep"];
  var Data_Maybe = PS["Data.Maybe"];
  var Data_Symbol = PS["Data.Symbol"];
  var Data_Unit = PS["Data.Unit"];
  var Effect = PS["Effect"];
  var Prelude = PS["Prelude"];
  var Routing_Duplex = PS["Routing.Duplex"];
  var Routing_Duplex_Generic = PS["Routing.Duplex.Generic"];
  var Routing_Duplex_Generic_Syntax = PS["Routing.Duplex.Generic.Syntax"];
  var Routing_PushState = PS["Routing.PushState"];
  var Unsafe_Coerce = PS["Unsafe.Coerce"];
  var ZRPC = PS["ZRPC"];
  var ZRPC_ZRPCWrite = PS["ZRPC.ZRPCWrite"];

  var UsersSearch = function () {
    function UsersSearch(value0) {
      this.value0 = value0;
    }

    ;

    UsersSearch.create = function (value0) {
      return new UsersSearch(value0);
    };

    return UsersSearch;
  }();

  var UsersOverview = function () {
    function UsersOverview(value0) {
      this.value0 = value0;
    }

    ;

    UsersOverview.create = function (value0) {
      return new UsersOverview(value0);
    };

    return UsersOverview;
  }();

  var UsersChangeEmail = function () {
    function UsersChangeEmail(value0) {
      this.value0 = value0;
    }

    ;

    UsersChangeEmail.create = function (value0) {
      return new UsersChangeEmail(value0);
    };

    return UsersChangeEmail;
  }();

  var UsersTags = function () {
    function UsersTags(value0) {
      this.value0 = value0;
    }

    ;

    UsersTags.create = function (value0) {
      return new UsersTags(value0);
    };

    return UsersTags;
  }();

  var HoldingSettings = function () {
    function HoldingSettings(value0) {
      this.value0 = value0;
    }

    ;

    HoldingSettings.create = function (value0) {
      return new HoldingSettings(value0);
    };

    return HoldingSettings;
  }();

  var MerchantSettings = function () {
    function MerchantSettings(value0) {
      this.value0 = value0;
    }

    ;

    MerchantSettings.create = function (value0) {
      return new MerchantSettings(value0);
    };

    return MerchantSettings;
  }();

  var BranchSettings = function () {
    function BranchSettings(value0) {
      this.value0 = value0;
    }

    ;

    BranchSettings.create = function (value0) {
      return new BranchSettings(value0);
    };

    return BranchSettings;
  }();

  var Users = function () {
    function Users(value0) {
      this.value0 = value0;
    }

    ;

    Users.create = function (value0) {
      return new Users(value0);
    };

    return Users;
  }();

  var Settings = function () {
    function Settings(value0) {
      this.value0 = value0;
    }

    ;

    Settings.create = function (value0) {
      return new Settings(value0);
    };

    return Settings;
  }();

  var Index = function () {
    function Index() {}

    ;
    Index.value = new Index();
    return Index;
  }();

  var Login = function () {
    function Login() {}

    ;
    Login.value = new Login();
    return Login;
  }();

  var Callback = function () {
    function Callback() {}

    ;
    Callback.value = new Callback();
    return Callback;
  }();

  var MRoute = function () {
    function MRoute(value0) {
      this.value0 = value0;
    }

    ;

    MRoute.create = function (value0) {
      return new MRoute(value0);
    };

    return MRoute;
  }();

  var genericUsersRoute = new Data_Generic_Rep.Generic(function (x) {
    if (x instanceof UsersSearch) {
      return new Data_Generic_Rep.Inl(x.value0);
    }

    ;

    if (x instanceof UsersOverview) {
      return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inl(x.value0));
    }

    ;

    if (x instanceof UsersChangeEmail) {
      return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inl(x.value0)));
    }

    ;

    if (x instanceof UsersTags) {
      return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(x.value0)));
    }

    ;
    throw new Error("Failed pattern match at Optimus.Modules.CustomerSupport.Client.Route (line 56, column 8 - line 56, column 58): " + [x.constructor.name]);
  }, function (x) {
    if (x instanceof Data_Generic_Rep.Inl) {
      return new UsersSearch(x.value0);
    }

    ;

    if (x instanceof Data_Generic_Rep.Inr && x.value0 instanceof Data_Generic_Rep.Inl) {
      return new UsersOverview(x.value0.value0);
    }

    ;

    if (x instanceof Data_Generic_Rep.Inr && x.value0 instanceof Data_Generic_Rep.Inr && x.value0.value0 instanceof Data_Generic_Rep.Inl) {
      return new UsersChangeEmail(x.value0.value0.value0);
    }

    ;

    if (x instanceof Data_Generic_Rep.Inr && x.value0 instanceof Data_Generic_Rep.Inr && x.value0.value0 instanceof Data_Generic_Rep.Inr) {
      return new UsersTags(x.value0.value0.value0);
    }

    ;
    throw new Error("Failed pattern match at Optimus.Modules.CustomerSupport.Client.Route (line 56, column 8 - line 56, column 58): " + [x.constructor.name]);
  });
  var usersRoute = Routing_Duplex.path("users")(Routing_Duplex_Generic.sum(genericUsersRoute)(Routing_Duplex_Generic.gRouteSum(Routing_Duplex_Generic.gRouteConstructor(new Data_Symbol.IsSymbol(function () {
    return "UsersSearch";
  }))()(Routing_Duplex_Generic.gRouteAll))(Routing_Duplex_Generic.gRouteSum(Routing_Duplex_Generic.gRouteConstructor(new Data_Symbol.IsSymbol(function () {
    return "UsersOverview";
  }))()(Routing_Duplex_Generic.gRouteAll))(Routing_Duplex_Generic.gRouteSum(Routing_Duplex_Generic.gRouteConstructor(new Data_Symbol.IsSymbol(function () {
    return "UsersChangeEmail";
  }))()(Routing_Duplex_Generic.gRouteArgument))(Routing_Duplex_Generic.gRouteConstructor(new Data_Symbol.IsSymbol(function () {
    return "UsersTags";
  }))()(Routing_Duplex_Generic.gRouteArgument)))))({
    UsersSearch: Routing_Duplex.prop(new Data_Symbol.IsSymbol(function () {
      return "query";
    }))()()()(Data_Symbol.SProxy.value)(Routing_Duplex.optional(Routing_Duplex.param("query")))(Routing_Duplex.record),
    UsersOverview: Routing_Duplex.segment,
    UsersChangeEmail: Routing_Duplex_Generic_Syntax.gsep(Routing_Duplex_Generic_Syntax.gsepRouteString(Routing_Duplex_Generic.gRouteAll))(Routing_Duplex.segment)("change-email"),
    UsersTags: Routing_Duplex_Generic_Syntax.gsep(Routing_Duplex_Generic_Syntax.gsepRouteString(Routing_Duplex_Generic.gRouteAll))(Routing_Duplex.segment)("tags")
  }));
  var genericSettingsRoute = new Data_Generic_Rep.Generic(function (x) {
    if (x instanceof HoldingSettings) {
      return new Data_Generic_Rep.Inl(x.value0);
    }

    ;

    if (x instanceof MerchantSettings) {
      return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inl(x.value0));
    }

    ;

    if (x instanceof BranchSettings) {
      return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(x.value0));
    }

    ;
    throw new Error("Failed pattern match at Optimus.Modules.CustomerSupport.Client.Route (line 57, column 8 - line 57, column 64): " + [x.constructor.name]);
  }, function (x) {
    if (x instanceof Data_Generic_Rep.Inl) {
      return new HoldingSettings(x.value0);
    }

    ;

    if (x instanceof Data_Generic_Rep.Inr && x.value0 instanceof Data_Generic_Rep.Inl) {
      return new MerchantSettings(x.value0.value0);
    }

    ;

    if (x instanceof Data_Generic_Rep.Inr && x.value0 instanceof Data_Generic_Rep.Inr) {
      return new BranchSettings(x.value0.value0);
    }

    ;
    throw new Error("Failed pattern match at Optimus.Modules.CustomerSupport.Client.Route (line 57, column 8 - line 57, column 64): " + [x.constructor.name]);
  });
  var settingsRoute = Routing_Duplex.path("settings")(Routing_Duplex_Generic.sum(genericSettingsRoute)(Routing_Duplex_Generic.gRouteSum(Routing_Duplex_Generic.gRouteConstructor(new Data_Symbol.IsSymbol(function () {
    return "HoldingSettings";
  }))()(Routing_Duplex_Generic.gRouteArgument))(Routing_Duplex_Generic.gRouteSum(Routing_Duplex_Generic.gRouteConstructor(new Data_Symbol.IsSymbol(function () {
    return "MerchantSettings";
  }))()(Routing_Duplex_Generic.gRouteArgument))(Routing_Duplex_Generic.gRouteConstructor(new Data_Symbol.IsSymbol(function () {
    return "BranchSettings";
  }))()(Routing_Duplex_Generic.gRouteArgument))))({
    HoldingSettings: Routing_Duplex_Generic_Syntax.gsep(Routing_Duplex_Generic_Syntax.gsepStringRoute(Routing_Duplex_Generic.gRouteAll))("holding")(Routing_Duplex.segment),
    MerchantSettings: Routing_Duplex_Generic_Syntax.gsep(Routing_Duplex_Generic_Syntax.gsepStringRoute(Routing_Duplex_Generic.gRouteAll))("merchant")(Routing_Duplex.segment),
    BranchSettings: Routing_Duplex_Generic_Syntax.gsep(Routing_Duplex_Generic_Syntax.gsepStringRoute(Routing_Duplex_Generic.gRouteAll))("branch")(Routing_Duplex.segment)
  }));
  var genericRoute = new Data_Generic_Rep.Generic(function (x) {
    if (x instanceof Index) {
      return new Data_Generic_Rep.Inl(Data_Generic_Rep.NoArguments.value);
    }

    ;

    if (x instanceof Login) {
      return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inl(Data_Generic_Rep.NoArguments.value));
    }

    ;

    if (x instanceof Callback) {
      return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inl(Data_Generic_Rep.NoArguments.value)));
    }

    ;

    if (x instanceof MRoute) {
      return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(x.value0)));
    }

    ;
    throw new Error("Failed pattern match at Optimus.Modules.CustomerSupport.Client.Route (line 53, column 8 - line 53, column 48): " + [x.constructor.name]);
  }, function (x) {
    if (x instanceof Data_Generic_Rep.Inl) {
      return Index.value;
    }

    ;

    if (x instanceof Data_Generic_Rep.Inr && x.value0 instanceof Data_Generic_Rep.Inl) {
      return Login.value;
    }

    ;

    if (x instanceof Data_Generic_Rep.Inr && x.value0 instanceof Data_Generic_Rep.Inr && x.value0.value0 instanceof Data_Generic_Rep.Inl) {
      return Callback.value;
    }

    ;

    if (x instanceof Data_Generic_Rep.Inr && x.value0 instanceof Data_Generic_Rep.Inr && x.value0.value0 instanceof Data_Generic_Rep.Inr) {
      return new MRoute(x.value0.value0.value0);
    }

    ;
    throw new Error("Failed pattern match at Optimus.Modules.CustomerSupport.Client.Route (line 53, column 8 - line 53, column 48): " + [x.constructor.name]);
  });
  var genericModuleRoute = new Data_Generic_Rep.Generic(function (x) {
    if (x instanceof Users) {
      return new Data_Generic_Rep.Inl(x.value0);
    }

    ;

    if (x instanceof Settings) {
      return new Data_Generic_Rep.Inr(x.value0);
    }

    ;
    throw new Error("Failed pattern match at Optimus.Modules.CustomerSupport.Client.Route (line 55, column 8 - line 55, column 60): " + [x.constructor.name]);
  }, function (x) {
    if (x instanceof Data_Generic_Rep.Inl) {
      return new Users(x.value0);
    }

    ;

    if (x instanceof Data_Generic_Rep.Inr) {
      return new Settings(x.value0);
    }

    ;
    throw new Error("Failed pattern match at Optimus.Modules.CustomerSupport.Client.Route (line 55, column 8 - line 55, column 60): " + [x.constructor.name]);
  });
  var moduleRoute = Routing_Duplex_Generic.sum(genericModuleRoute)(Routing_Duplex_Generic.gRouteSum(Routing_Duplex_Generic.gRouteConstructor(new Data_Symbol.IsSymbol(function () {
    return "Users";
  }))()(Routing_Duplex_Generic.gRouteAll))(Routing_Duplex_Generic.gRouteConstructor(new Data_Symbol.IsSymbol(function () {
    return "Settings";
  }))()(Routing_Duplex_Generic.gRouteAll)))({
    Users: usersRoute,
    Settings: settingsRoute
  });
  var route = Routing_Duplex.root(Routing_Duplex_Generic.sum(genericRoute)(Routing_Duplex_Generic.gRouteSum(Routing_Duplex_Generic.gRouteConstructor(new Data_Symbol.IsSymbol(function () {
    return "Index";
  }))()(Routing_Duplex_Generic.gRouteNoArguments))(Routing_Duplex_Generic.gRouteSum(Routing_Duplex_Generic.gRouteConstructor(new Data_Symbol.IsSymbol(function () {
    return "Login";
  }))()(Routing_Duplex_Generic.gRouteNoArguments))(Routing_Duplex_Generic.gRouteSum(Routing_Duplex_Generic.gRouteConstructor(new Data_Symbol.IsSymbol(function () {
    return "Callback";
  }))()(Routing_Duplex_Generic.gRouteNoArguments))(Routing_Duplex_Generic.gRouteConstructor(new Data_Symbol.IsSymbol(function () {
    return "MRoute";
  }))()(Routing_Duplex_Generic.gRouteAll)))))({
    Index: Routing_Duplex_Generic.noArgs,
    Login: Routing_Duplex_Generic_Syntax.gsep(Routing_Duplex_Generic_Syntax.gsepStringRoute(Routing_Duplex_Generic.gRouteNoArguments))("login")(Routing_Duplex_Generic.noArgs),
    Callback: Routing_Duplex_Generic_Syntax.gsep(Routing_Duplex_Generic_Syntax.gsepStringRoute(Routing_Duplex_Generic.gRouteNoArguments))("callback")(Routing_Duplex_Generic.noArgs),
    MRoute: moduleRoute
  }));

  var navToHistory = function navToHistory(nav) {
    var toHistory = function toHistory(f) {
      return function ($64) {
        return f(ZRPC_ZRPCWrite.zrpcWrite(ZRPC_ZRPCWrite.zrpcWriteUnit)(Data_Unit.unit))(Routing_Duplex.print(route)($64));
      };
    };

    return {
      push: toHistory(nav.pushState),
      replace: toHistory(nav.replaceState),
      location: nav.locationState,
      listener: function listener(cb) {
        return Routing_PushState.matchesWith(Data_Either.foldableEither)(Routing_Duplex.parse(route))(cb)(nav);
      }
    };
  };

  exports["Users"] = Users;
  exports["Settings"] = Settings;
  exports["Index"] = Index;
  exports["Login"] = Login;
  exports["Callback"] = Callback;
  exports["MRoute"] = MRoute;
  exports["UsersSearch"] = UsersSearch;
  exports["UsersOverview"] = UsersOverview;
  exports["UsersChangeEmail"] = UsersChangeEmail;
  exports["UsersTags"] = UsersTags;
  exports["HoldingSettings"] = HoldingSettings;
  exports["MerchantSettings"] = MerchantSettings;
  exports["BranchSettings"] = BranchSettings;
  exports["route"] = route;
  exports["navToHistory"] = navToHistory;
  exports["genericRoute"] = genericRoute;
  exports["genericModuleRoute"] = genericModuleRoute;
  exports["genericUsersRoute"] = genericUsersRoute;
  exports["genericSettingsRoute"] = genericSettingsRoute;
})(PS["Optimus.Modules.CustomerSupport.Client.Route"] = PS["Optimus.Modules.CustomerSupport.Client.Route"] || {});

(function (exports) {
  // Generated by purs version 0.12.3
  "use strict";

  var Control_Applicative = PS["Control.Applicative"];
  var Control_Bind = PS["Control.Bind"];
  var Control_Category = PS["Control.Category"];
  var Control_Semigroupoid = PS["Control.Semigroupoid"];
  var Data_Maybe = PS["Data.Maybe"];
  var Data_Nullable = PS["Data.Nullable"];
  var Effect = PS["Effect"];
  var Effect_Unsafe = PS["Effect.Unsafe"];
  var Prelude = PS["Prelude"];
  var React_Basic_Events = PS["React.Basic.Events"];
  var Unsafe_Coerce = PS["Unsafe.Coerce"];
  var Web_Event_Internal_Types = PS["Web.Event.Internal.Types"];
  var Web_File_FileList = PS["Web.File.FileList"];
  var targetValue = React_Basic_Events.unsafeEventFn(function (e) {
    return Data_Nullable.toMaybe(e.target.value);
  });
  var target = React_Basic_Events.unsafeEventFn(function (e) {
    return e.target;
  });
  var stopPropagation = React_Basic_Events.unsafeEventFn(function (e) {
    return Effect_Unsafe.unsafePerformEffect(function __do() {
      var v = e.stopPropagation();
      return e;
    });
  });
  var preventDefault = React_Basic_Events.unsafeEventFn(function (e) {
    return Effect_Unsafe.unsafePerformEffect(function __do() {
      var v = e.preventDefault();
      return e;
    });
  });

  var capture = function capture(eventFn) {
    return React_Basic_Events.handler(Control_Semigroupoid.composeFlipped(React_Basic_Events.semigroupoidBuilder)(preventDefault)(Control_Semigroupoid.composeFlipped(React_Basic_Events.semigroupoidBuilder)(stopPropagation)(eventFn)));
  };

  var capture_ = function capture_(cb) {
    return capture(Control_Category.identity(React_Basic_Events.categoryBuilder))(function (v) {
      return cb;
    });
  };

  exports["capture"] = capture;
  exports["capture_"] = capture_;
  exports["preventDefault"] = preventDefault;
  exports["stopPropagation"] = stopPropagation;
  exports["target"] = target;
  exports["targetValue"] = targetValue;
})(PS["React.Basic.DOM.Events"] = PS["React.Basic.DOM.Events"] || {});

(function (exports) {
  // Generated by purs version 0.12.3
  "use strict";

  var Control_Semigroupoid = PS["Control.Semigroupoid"];
  var Data_Array = PS["Data.Array"];
  var Data_Either = PS["Data.Either"];
  var Data_Function = PS["Data.Function"];
  var Data_Functor = PS["Data.Functor"];
  var Data_Maybe = PS["Data.Maybe"];
  var Data_Ord = PS["Data.Ord"];
  var Data_Semigroup = PS["Data.Semigroup"];
  var Data_Semiring = PS["Data.Semiring"];
  var Data_String = PS["Data.String"];
  var Data_String_Common = PS["Data.String.Common"];
  var Data_String_Pattern = PS["Data.String.Pattern"];
  var Optimus_Common_Client_Antd = PS["Optimus.Common.Client.Antd"];
  var Optimus_Common_Client_Antd_Breadcrumb = PS["Optimus.Common.Client.Antd.Breadcrumb"];
  var Optimus_Modules_CustomerSupport_Client_Route = PS["Optimus.Modules.CustomerSupport.Client.Route"];
  var Prelude = PS["Prelude"];
  var React_Basic = PS["React.Basic"];
  var React_Basic_DOM = PS["React.Basic.DOM"];
  var React_Basic_DOM_Events = PS["React.Basic.DOM.Events"];
  var React_Basic_DOM_Generated = PS["React.Basic.DOM.Generated"];
  var Routing_Duplex = PS["Routing.Duplex"];

  var pathToCrumbs = function pathToCrumbs(path) {
    var removeQueryPath = function removeQueryPath($8) {
      return Data_Maybe.fromMaybe("")(Data_Array.head(Data_String_Common.split("?")($8)));
    };

    var pathsToCrumb = function pathsToCrumb(ps) {
      var routeFromPaths = function routeFromPaths($9) {
        return function ($10) {
          return function (v) {
            if (v instanceof Data_Either.Right) {
              return new Data_Maybe.Just(v.value0);
            }

            ;
            return Data_Maybe.Nothing.value;
          }(Routing_Duplex.parse(Optimus_Modules_CustomerSupport_Client_Route.route)($10));
        }(function (v) {
          return "/" + v;
        }(Data_String_Common.joinWith("/")($9)));
      };

      return {
        name: Data_Maybe.fromMaybe("")(Data_Array.last(ps)),
        route: routeFromPaths(ps)
      };
    };

    var paths = Data_Maybe.fromMaybe([])(Data_Array.tail(Data_Functor.map(Data_Functor.functorArray)(removeQueryPath)(Data_String_Common.split("/")(path))));

    var go = function go($copy_i) {
      return function ($copy_ps) {
        return function ($copy_crumbs) {
          var $tco_var_i = $copy_i;
          var $tco_var_ps = $copy_ps;
          var $tco_done = false;
          var $tco_result;

          function $tco_loop(i, ps, crumbs) {
            var v = Data_Array.length(ps);

            if (v >= i) {
              $tco_var_i = i + 1 | 0;
              $tco_var_ps = ps;
              $copy_crumbs = Data_Semigroup.append(Data_Semigroup.semigroupArray)(crumbs)([pathsToCrumb(Data_Array.take(i)(ps))]);
              return;
            }

            ;
            $tco_done = true;
            return crumbs;
          }

          ;

          while (!$tco_done) {
            $tco_result = $tco_loop($tco_var_i, $tco_var_ps, $copy_crumbs);
          }

          ;
          return $tco_result;
        };
      };
    };

    return go(1)(paths)([]);
  };

  var breadcrumbs = function breadcrumbs(props) {
    var mkBCrumbs = function mkBCrumbs(crumb) {
      if (crumb.route instanceof Data_Maybe.Just) {
        return Optimus_Common_Client_Antd_Breadcrumb.breadcrumbItem([React_Basic_DOM_Generated.a()({
          onClick: React_Basic_DOM_Events.capture_(props.history.push(crumb.route.value0)),
          children: [React_Basic_DOM.text(crumb.name)]
        })]);
      }

      ;
      return Optimus_Common_Client_Antd_Breadcrumb.breadcrumbItem([React_Basic_DOM.text(crumb.name)]);
    };

    var crumbs = pathToCrumbs(Routing_Duplex.print(Optimus_Modules_CustomerSupport_Client_Route.route)(props.route));
    return React_Basic_DOM_Generated.div()({
      className: "px-3 pt-3",
      children: [Optimus_Common_Client_Antd_Breadcrumb.breadcrumb()({
        separator: "/",
        children: Data_Functor.map(Data_Functor.functorArray)(mkBCrumbs)(crumbs)
      })]
    });
  };

  exports["breadcrumbs"] = breadcrumbs;
  exports["pathToCrumbs"] = pathToCrumbs;
})(PS["Optimus.Common.Client.Components.Breadcrumb"] = PS["Optimus.Common.Client.Components.Breadcrumb"] || {});

(function (exports) {
  // Generated by purs version 0.12.3
  "use strict";

  var Optimus_Common_Client_Antd = PS["Optimus.Common.Client.Antd"];
  var Optimus_Common_Client_Antd_Card = PS["Optimus.Common.Client.Antd.Card"];
  var Optimus_Common_Client_Antd_Grid = PS["Optimus.Common.Client.Antd.Grid"];
  var React_Basic = PS["React.Basic"];

  var card = function card(children) {
    return Optimus_Common_Client_Antd_Grid.col()({
      className: "p-3",
      children: [Optimus_Common_Client_Antd_Card.card()({
        bordered: false,
        children: children
      })]
    });
  };

  exports["card"] = card;
})(PS["Optimus.Common.Client.Components.Card"] = PS["Optimus.Common.Client.Components.Card"] || {});

(function (exports) {
  // Generated by purs version 0.12.3
  "use strict";

  var Control_Applicative = PS["Control.Applicative"];
  var Data_Monoid = PS["Data.Monoid"];
  var Data_Unit = PS["Data.Unit"];
  var Effect = PS["Effect"];
  var Effect_Uncurried = PS["Effect.Uncurried"];
  var Optimus_Common_Client_Antd = PS["Optimus.Common.Client.Antd"];
  var Optimus_Common_Client_Antd_Dropdown = PS["Optimus.Common.Client.Antd.Dropdown"];
  var Optimus_Common_Client_Antd_Grid = PS["Optimus.Common.Client.Antd.Grid"];
  var Optimus_Common_Client_Antd_Icon = PS["Optimus.Common.Client.Antd.Icon"];
  var Optimus_Common_Client_Antd_Layout = PS["Optimus.Common.Client.Antd.Layout"];
  var Optimus_Common_Client_Antd_Menu = PS["Optimus.Common.Client.Antd.Menu"];
  var Optimus_Common_Client_Assets = PS["Optimus.Common.Client.Assets"];
  var Optimus_Common_Client_Auth = PS["Optimus.Common.Client.Auth"];
  var Prelude = PS["Prelude"];
  var React_Basic = PS["React.Basic"];
  var React_Basic_DOM = PS["React.Basic.DOM"];
  var React_Basic_DOM_Generated = PS["React.Basic.DOM.Generated"];

  var header = function () {
    var leftHeader = Optimus_Common_Client_Antd_Grid.col()({
      span: 10,
      children: [Data_Monoid.mempty(React_Basic.monoidJSX)]
    });

    var handleUserActionsClick = function handleUserActionsClick(v) {
      if (v.key === "logout") {
        return Optimus_Common_Client_Auth.logout;
      }

      ;
      return Control_Applicative.pure(Effect.applicativeEffect)(Data_Unit.unit);
    };

    var userActionsMenu = Optimus_Common_Client_Antd_Menu.menu()({
      onClick: Effect_Uncurried.mkEffectFn1(handleUserActionsClick),
      children: [Optimus_Common_Client_Antd_Menu.menuItem()({
        key: "logout",
        children: [React_Basic_DOM.text("Logout")]
      })]
    });
    var userActions = Optimus_Common_Client_Antd_Dropdown.dropdown()({
      overlay: userActionsMenu,
      trigger: ["click"],
      children: [Optimus_Common_Client_Antd_Icon.icon()({
        type: "user",
        style: React_Basic_DOM.css({
          fontSize: "20px"
        })
      })]
    });
    var rightHeader = Optimus_Common_Client_Antd_Grid.col()({
      className: "flex justify-end items-center",
      span: 10,
      children: [userActions]
    });
    var centerHeader = Optimus_Common_Client_Antd_Grid.col()({
      className: "flex justify-center items-center",
      span: 4,
      children: [React_Basic_DOM_Generated.img()({
        src: Optimus_Common_Client_Assets.zapLogo
      })]
    });
    return Optimus_Common_Client_Antd_Layout.header()({
      className: "header bg-white",
      children: [Optimus_Common_Client_Antd_Grid.row()({
        className: "flex justify-between h-100",
        children: [leftHeader, centerHeader, rightHeader]
      })]
    });
  }();

  exports["header"] = header;
})(PS["Optimus.Common.Client.Components.Header"] = PS["Optimus.Common.Client.Components.Header"] || {});

(function (exports) {
  // Generated by purs version 0.12.3
  "use strict";

  var Data_Eq = PS["Data.Eq"];
  var Data_Generic_Rep = PS["Data.Generic.Rep"];
  var Data_Generic_Rep_Show = PS["Data.Generic.Rep.Show"];
  var Data_HeytingAlgebra = PS["Data.HeytingAlgebra"];
  var Data_Maybe = PS["Data.Maybe"];
  var Data_Show = PS["Data.Show"];
  var Data_Symbol = PS["Data.Symbol"];
  var ZRPC = PS["ZRPC"];
  var ZRPC_Client = PS["ZRPC.Client"];
  var ZRPC_ConsPrefix = PS["ZRPC.ConsPrefix"];
  var ZRPC_ZRPCRead = PS["ZRPC.ZRPCRead"];
  var ZRPC_ZRPCWrite = PS["ZRPC.ZRPCWrite"];

  var SetEmailErrorInvalidEmail = function () {
    function SetEmailErrorInvalidEmail() {}

    ;
    SetEmailErrorInvalidEmail.value = new SetEmailErrorInvalidEmail();
    return SetEmailErrorInvalidEmail;
  }();

  var SetEmailErrorExistingEmail = function () {
    function SetEmailErrorExistingEmail() {}

    ;
    SetEmailErrorExistingEmail.value = new SetEmailErrorExistingEmail();
    return SetEmailErrorExistingEmail;
  }();

  var SetEmailErrorUnableToSetEmail = function () {
    function SetEmailErrorUnableToSetEmail() {}

    ;
    SetEmailErrorUnableToSetEmail.value = new SetEmailErrorUnableToSetEmail();
    return SetEmailErrorUnableToSetEmail;
  }();

  var GenderMale = function () {
    function GenderMale() {}

    ;
    GenderMale.value = new GenderMale();
    return GenderMale;
  }();

  var GenderFemale = function () {
    function GenderFemale() {}

    ;
    GenderFemale.value = new GenderFemale();
    return GenderFemale;
  }();

  var EmailConfirmed = function () {
    function EmailConfirmed(value0) {
      this.value0 = value0;
    }

    ;

    EmailConfirmed.create = function (value0) {
      return new EmailConfirmed(value0);
    };

    return EmailConfirmed;
  }();

  var EmailUnconfirmed = function () {
    function EmailUnconfirmed(value0) {
      this.value0 = value0;
    }

    ;

    EmailUnconfirmed.create = function (value0) {
      return new EmailUnconfirmed(value0);
    };

    return EmailUnconfirmed;
  }();

  var genericSetEmailError = new Data_Generic_Rep.Generic(function (x) {
    if (x instanceof SetEmailErrorInvalidEmail) {
      return new Data_Generic_Rep.Inl(Data_Generic_Rep.NoArguments.value);
    }

    ;

    if (x instanceof SetEmailErrorExistingEmail) {
      return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inl(Data_Generic_Rep.NoArguments.value));
    }

    ;

    if (x instanceof SetEmailErrorUnableToSetEmail) {
      return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(Data_Generic_Rep.NoArguments.value));
    }

    ;
    throw new Error("Failed pattern match at ZRPC.Clients.Zapper (line 261, column 8 - line 261, column 64): " + [x.constructor.name]);
  }, function (x) {
    if (x instanceof Data_Generic_Rep.Inl) {
      return SetEmailErrorInvalidEmail.value;
    }

    ;

    if (x instanceof Data_Generic_Rep.Inr && x.value0 instanceof Data_Generic_Rep.Inl) {
      return SetEmailErrorExistingEmail.value;
    }

    ;

    if (x instanceof Data_Generic_Rep.Inr && x.value0 instanceof Data_Generic_Rep.Inr) {
      return SetEmailErrorUnableToSetEmail.value;
    }

    ;
    throw new Error("Failed pattern match at ZRPC.Clients.Zapper (line 261, column 8 - line 261, column 64): " + [x.constructor.name]);
  });
  var genericGender = new Data_Generic_Rep.Generic(function (x) {
    if (x instanceof GenderMale) {
      return new Data_Generic_Rep.Inl(Data_Generic_Rep.NoArguments.value);
    }

    ;

    if (x instanceof GenderFemale) {
      return new Data_Generic_Rep.Inr(Data_Generic_Rep.NoArguments.value);
    }

    ;
    throw new Error("Failed pattern match at ZRPC.Clients.Zapper (line 118, column 8 - line 118, column 50): " + [x.constructor.name]);
  }, function (x) {
    if (x instanceof Data_Generic_Rep.Inl) {
      return GenderMale.value;
    }

    ;

    if (x instanceof Data_Generic_Rep.Inr) {
      return GenderFemale.value;
    }

    ;
    throw new Error("Failed pattern match at ZRPC.Clients.Zapper (line 118, column 8 - line 118, column 50): " + [x.constructor.name]);
  });
  var genericEmail = new Data_Generic_Rep.Generic(function (x) {
    if (x instanceof EmailConfirmed) {
      return new Data_Generic_Rep.Inl(x.value0);
    }

    ;

    if (x instanceof EmailUnconfirmed) {
      return new Data_Generic_Rep.Inr(x.value0);
    }

    ;
    throw new Error("Failed pattern match at ZRPC.Clients.Zapper (line 94, column 8 - line 94, column 48): " + [x.constructor.name]);
  }, function (x) {
    if (x instanceof Data_Generic_Rep.Inl) {
      return new EmailConfirmed(x.value0);
    }

    ;

    if (x instanceof Data_Generic_Rep.Inr) {
      return new EmailUnconfirmed(x.value0);
    }

    ;
    throw new Error("Failed pattern match at ZRPC.Clients.Zapper (line 94, column 8 - line 94, column 48): " + [x.constructor.name]);
  });
  var consPrefixSetEmailError = new ZRPC_ConsPrefix.ConsPrefix(function (v) {
    return "SetEmailError";
  });
  var zrpcReadSetEmailError = new ZRPC_ZRPCRead.ZRPCRead(function (x) {
    return ZRPC_ZRPCRead.genericZRPCRead(consPrefixSetEmailError)(genericSetEmailError)(ZRPC_ZRPCRead.readRepSum(ZRPC_ZRPCRead.readRepNoArgs(new Data_Symbol.IsSymbol(function () {
      return "SetEmailErrorInvalidEmail";
    })))(ZRPC_ZRPCRead.readRepSum(ZRPC_ZRPCRead.readRepNoArgs(new Data_Symbol.IsSymbol(function () {
      return "SetEmailErrorExistingEmail";
    })))(ZRPC_ZRPCRead.readRepNoArgs(new Data_Symbol.IsSymbol(function () {
      return "SetEmailErrorUnableToSetEmail";
    })))))(x);
  });
  var consPrefixGender = new ZRPC_ConsPrefix.ConsPrefix(function (v) {
    return "Gender";
  });
  var zrpcReadGender = new ZRPC_ZRPCRead.ZRPCRead(function (x) {
    return ZRPC_ZRPCRead.genericZRPCRead(consPrefixGender)(genericGender)(ZRPC_ZRPCRead.readRepSum(ZRPC_ZRPCRead.readRepNoArgs(new Data_Symbol.IsSymbol(function () {
      return "GenderMale";
    })))(ZRPC_ZRPCRead.readRepNoArgs(new Data_Symbol.IsSymbol(function () {
      return "GenderFemale";
    }))))(x);
  });
  var consPrefixEmail = new ZRPC_ConsPrefix.ConsPrefix(function (v) {
    return "Email";
  });
  var zrpcReadEmail = new ZRPC_ZRPCRead.ZRPCRead(function (x) {
    return ZRPC_ZRPCRead.genericZRPCRead(consPrefixEmail)(genericEmail)(ZRPC_ZRPCRead.readRepSum(ZRPC_ZRPCRead.readRepRecArg(new Data_Symbol.IsSymbol(function () {
      return "EmailConfirmed";
    }))(ZRPC_ZRPCRead.readRecord()(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
      return "value";
    }))(ZRPC_ZRPCRead.zrpcReadFieldMaybe(ZRPC_ZRPCRead.zrpcReadString))(ZRPC_ZRPCRead.zrpcReadFieldsNil)()())))(ZRPC_ZRPCRead.readRepRecArg(new Data_Symbol.IsSymbol(function () {
      return "EmailUnconfirmed";
    }))(ZRPC_ZRPCRead.readRecord()(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
      return "value";
    }))(ZRPC_ZRPCRead.zrpcReadFieldMaybe(ZRPC_ZRPCRead.zrpcReadString))(ZRPC_ZRPCRead.zrpcReadFieldsNil)()()))))(x);
  });
  exports["EmailConfirmed"] = EmailConfirmed;
  exports["EmailUnconfirmed"] = EmailUnconfirmed;
  exports["GenderMale"] = GenderMale;
  exports["GenderFemale"] = GenderFemale;
  exports["SetEmailErrorInvalidEmail"] = SetEmailErrorInvalidEmail;
  exports["SetEmailErrorExistingEmail"] = SetEmailErrorExistingEmail;
  exports["SetEmailErrorUnableToSetEmail"] = SetEmailErrorUnableToSetEmail;
  exports["genericEmail"] = genericEmail;
  exports["consPrefixEmail"] = consPrefixEmail;
  exports["zrpcReadEmail"] = zrpcReadEmail;
  exports["genericGender"] = genericGender;
  exports["consPrefixGender"] = consPrefixGender;
  exports["zrpcReadGender"] = zrpcReadGender;
  exports["genericSetEmailError"] = genericSetEmailError;
  exports["consPrefixSetEmailError"] = consPrefixSetEmailError;
  exports["zrpcReadSetEmailError"] = zrpcReadSetEmailError;
})(PS["ZRPC.Clients.Zapper"] = PS["ZRPC.Clients.Zapper"] || {});

(function (exports) {
  // Generated by purs version 0.12.3
  "use strict";

  var Control_Category = PS["Control.Category"];
  var Data_Array = PS["Data.Array"];
  var Data_Date = PS["Data.Date"];
  var Data_DateTime = PS["Data.DateTime"];
  var Data_Either = PS["Data.Either"];
  var Data_Formatter_DateTime = PS["Data.Formatter.DateTime"];
  var Data_Functor = PS["Data.Functor"];
  var Data_HugeNum = PS["Data.HugeNum"];
  var Data_Maybe = PS["Data.Maybe"];
  var Data_Show = PS["Data.Show"];
  var Data_String = PS["Data.String"];
  var Data_String_Common = PS["Data.String.Common"];
  var Optimus_Common_Client_Utils = PS["Optimus.Common.Client.Utils"];
  var Prelude = PS["Prelude"];
  var ZRPC_Clients_Zapper = PS["ZRPC.Clients.Zapper"];

  var InfoShow = function InfoShow(showInfo) {
    this.showInfo = showInfo;
  };

  var showInfo = function showInfo(dict) {
    return dict.showInfo;
  };

  var infoShowString = new InfoShow(function (a) {
    return a;
  });

  var infoShowMaybe = function infoShowMaybe(dictInfoShow) {
    return new InfoShow(function (v) {
      if (v instanceof Data_Maybe.Just) {
        return showInfo(dictInfoShow)(v.value0);
      }

      ;

      if (v instanceof Data_Maybe.Nothing) {
        return "-";
      }

      ;
      throw new Error("Failed pattern match at Optimus.Common.Client.InfoShow (line 42, column 1 - line 42, column 59): " + [v.constructor.name]);
    });
  };

  var infoShowHugeNum = new InfoShow(Data_HugeNum.toString);
  var infoShowGender = new InfoShow(function (v) {
    if (v instanceof ZRPC_Clients_Zapper.GenderFemale) {
      return "Female";
    }

    ;

    if (v instanceof ZRPC_Clients_Zapper.GenderMale) {
      return "Male";
    }

    ;
    throw new Error("Failed pattern match at Optimus.Common.Client.InfoShow (line 35, column 1 - line 35, column 43): " + [v.constructor.name]);
  });
  var infoShowDateTiem = new InfoShow(function (dt) {
    return Data_Either.either(Control_Category.identity(Control_Category.categoryFn))(Control_Category.identity(Control_Category.categoryFn))(Data_Formatter_DateTime.formatDateTime("MMM DD, YYYY")(dt));
  });
  var infoShowDate = new InfoShow(function (a) {
    return Optimus_Common_Client_Utils.formatDate(a);
  });
  var infoShowBoolean = new InfoShow(function (v) {
    if (v) {
      return "Yes";
    }

    ;

    if (!v) {
      return "No";
    }

    ;
    throw new Error("Failed pattern match at Optimus.Common.Client.InfoShow (line 28, column 1 - line 28, column 45): " + [v.constructor.name]);
  });

  var infoShowArray = function infoShowArray(dictInfoShow) {
    return new InfoShow(function (arr) {
      var v = Data_Array.head(arr);

      if (v instanceof Data_Maybe.Just) {
        return Data_String_Common.joinWith(", ")(Data_Functor.mapFlipped(Data_Functor.functorArray)(arr)(showInfo(dictInfoShow)));
      }

      ;
      return "-";
    });
  };

  exports["InfoShow"] = InfoShow;
  exports["showInfo"] = showInfo;
  exports["infoShowString"] = infoShowString;
  exports["infoShowBoolean"] = infoShowBoolean;
  exports["infoShowDate"] = infoShowDate;
  exports["infoShowGender"] = infoShowGender;
  exports["infoShowHugeNum"] = infoShowHugeNum;
  exports["infoShowMaybe"] = infoShowMaybe;
  exports["infoShowArray"] = infoShowArray;
  exports["infoShowDateTiem"] = infoShowDateTiem;
})(PS["Optimus.Common.Client.InfoShow"] = PS["Optimus.Common.Client.InfoShow"] || {});

(function (exports) {
  // Generated by purs version 0.12.3
  "use strict";

  var Control_Semigroupoid = PS["Control.Semigroupoid"];
  var Data_Functor = PS["Data.Functor"];
  var Optimus_Common_Client_Antd = PS["Optimus.Common.Client.Antd"];
  var Optimus_Common_Client_Antd_Grid = PS["Optimus.Common.Client.Antd.Grid"];
  var Optimus_Common_Client_Antd_Typography = PS["Optimus.Common.Client.Antd.Typography"];
  var Optimus_Common_Client_InfoShow = PS["Optimus.Common.Client.InfoShow"];
  var Prelude = PS["Prelude"];
  var React_Basic = PS["React.Basic"];
  var React_Basic_DOM = PS["React.Basic.DOM"];
  var React_Basic_DOM_Generated = PS["React.Basic.DOM.Generated"];

  var renderText = function renderText(dictInfoShow) {
    return function ($1) {
      return React_Basic_DOM.text(Optimus_Common_Client_InfoShow.showInfo(dictInfoShow)($1));
    };
  };

  var renderPair = function renderPair(p) {
    return Optimus_Common_Client_Antd_Grid.row()({
      type: "flex",
      justify: "center",
      children: [Optimus_Common_Client_Antd_Grid.col()({
        xs: 24,
        sm: 16,
        md: 12,
        lg: 8,
        children: [Optimus_Common_Client_Antd_Grid.row()({
          gutter: 16,
          className: "mb-1",
          children: [Optimus_Common_Client_Antd_Grid.col()({
            span: 8,
            children: [Optimus_Common_Client_Antd_Typography.text()({
              strong: true,
              children: [React_Basic_DOM.text(p.key)]
            })]
          }), Optimus_Common_Client_Antd_Grid.col()({
            className: "break-word",
            span: 16,
            children: [p.value]
          })]
        })]
      })]
    });
  };

  var ip = function ip(key) {
    return function (value) {
      return {
        key: key,
        value: value
      };
    };
  };

  var infoPanel = function infoPanel(ips) {
    return React_Basic_DOM_Generated.div_(Data_Functor.map(Data_Functor.functorArray)(renderPair)(ips));
  };

  exports["infoPanel"] = infoPanel;
  exports["ip"] = ip;
  exports["renderText"] = renderText;
})(PS["Optimus.Common.Client.Components.InfoPanel"] = PS["Optimus.Common.Client.Components.InfoPanel"] || {});

(function (exports) {
  // Generated by purs version 0.12.3
  "use strict";

  var Data_Unit = PS["Data.Unit"];
  var Optimus_Common_Client_Antd = PS["Optimus.Common.Client.Antd"];
  var Optimus_Common_Client_Antd_Layout = PS["Optimus.Common.Client.Antd.Layout"];
  var Optimus_Common_Client_Components_Breadcrumb = PS["Optimus.Common.Client.Components.Breadcrumb"];
  var Optimus_Common_Client_Components_Header = PS["Optimus.Common.Client.Components.Header"];
  var Optimus_Modules_CustomerSupport_Client_Route = PS["Optimus.Modules.CustomerSupport.Client.Route"];
  var Prelude = PS["Prelude"];
  var React_Basic = PS["React.Basic"];
  var React_Basic_DOM = PS["React.Basic.DOM"];
  var component = React_Basic.createComponent("Layout");

  var layout = function () {
    var footer = Optimus_Common_Client_Antd_Layout.footer()({
      className: "center",
      children: [React_Basic_DOM.text("Optimus \xa9 2019. Created by ZAP")]
    });

    var content = function content(v) {
      return Optimus_Common_Client_Antd_Layout.content()({
        className: "p-3",
        children: [Optimus_Common_Client_Components_Breadcrumb.breadcrumbs({
          route: v.route,
          history: v.history
        }), v.content]
      });
    };

    var render = function render(v) {
      return Optimus_Common_Client_Antd_Layout.layout()({
        className: "vh-100",
        children: [Optimus_Common_Client_Components_Header.header, content(v.props), footer]
      });
    };

    return React_Basic.make()(component)({
      initialState: Data_Unit.unit,
      render: render
    });
  }();

  exports["layout"] = layout;
})(PS["Optimus.Common.Client.Components.Layout"] = PS["Optimus.Common.Client.Components.Layout"] || {});

(function (exports) {
  // Generated by purs version 0.12.3
  "use strict";

  var Optimus_Common_Client_Antd = PS["Optimus.Common.Client.Antd"];
  var Optimus_Common_Client_Antd_Typography = PS["Optimus.Common.Client.Antd.Typography"];
  var React_Basic = PS["React.Basic"];
  var React_Basic_DOM = PS["React.Basic.DOM"];
  var React_Basic_DOM_Generated = PS["React.Basic.DOM.Generated"];

  var section = function section(header) {
    return function (body) {
      return React_Basic_DOM_Generated.div_([Optimus_Common_Client_Antd_Typography.title()({
        level: 3,
        ellipsis: true,
        children: [header]
      }), body]);
    };
  };

  exports["section"] = section;
})(PS["Optimus.Common.Client.Components.Section"] = PS["Optimus.Common.Client.Components.Section"] || {});

(function (exports) {
  // Generated by purs version 0.12.3
  "use strict";

  var Control_Semigroupoid = PS["Control.Semigroupoid"];
  var Data_Function = PS["Data.Function"];
  var Data_Function_Uncurried = PS["Data.Function.Uncurried"];
  var Data_Functor = PS["Data.Functor"];
  var Data_Show = PS["Data.Show"];
  var Effect = PS["Effect"];
  var Optimus_Common_Client_Antd = PS["Optimus.Common.Client.Antd"];
  var Optimus_Common_Client_Antd_Table = PS["Optimus.Common.Client.Antd.Table"];
  var Optimus_Common_Client_InfoShow = PS["Optimus.Common.Client.InfoShow"];
  var Prelude = PS["Prelude"];
  var React_Basic = PS["React.Basic"];
  var React_Basic_DOM = PS["React.Basic.DOM"];
  var React_Basic_Events = PS["React.Basic.Events"];

  var table = function table(v) {
    return function (cols) {
      return function (dataSource) {
        var mkColumn = function mkColumn(col) {
          return {
            title: col.title,
            key: col.title,
            render: function render(v1, r) {
              return col.showValue(r);
            }
          };
        };

        var columns = Data_Functor.mapFlipped(Data_Functor.functorArray)(cols)(mkColumn);
        return Optimus_Common_Client_Antd_Table.table()({
          dataSource: dataSource,
          columns: columns,
          pagination: false,
          loading: v.loading,
          rowKey: function rowKey(v1, i) {
            return Data_Show.show(Data_Show.showInt)(i);
          },
          onRow: function onRow(v1, i) {
            return {
              onClick: React_Basic_Events.handler_(v.onRowClick(v1))
            };
          }
        });
      };
    };
  };

  var scspec = function scspec(dictInfoShow) {
    return function (title) {
      return function (getter) {
        return {
          title: title,
          showValue: function showValue($9) {
            return React_Basic_DOM.text(Optimus_Common_Client_InfoShow.showInfo(dictInfoShow)(getter($9)));
          }
        };
      };
    };
  };

  var cspec = function cspec(title) {
    return function (toJSX) {
      return {
        title: title,
        showValue: toJSX
      };
    };
  };

  exports["table"] = table;
  exports["cspec"] = cspec;
  exports["scspec"] = scspec;
})(PS["Optimus.Common.Client.Components.Table"] = PS["Optimus.Common.Client.Components.Table"] || {});

(function (exports) {
  // Generated by purs version 0.12.3
  "use strict";

  var Optimus_Common_Client_Antd = PS["Optimus.Common.Client.Antd"];
  var Optimus_Common_Client_Antd_Icon = PS["Optimus.Common.Client.Antd.Icon"];
  var React_Basic = PS["React.Basic"];
  var React_Basic_DOM = PS["React.Basic.DOM"];
  var React_Basic_DOM_Generated = PS["React.Basic.DOM.Generated"];

  var render = function render(v) {
    return React_Basic_DOM_Generated.div()({
      className: "flex justify-center",
      children: [Optimus_Common_Client_Antd_Icon.icon()({
        className: "absolute top-50 fs-2",
        type: "loading"
      })]
    });
  };

  var component = React_Basic.createComponent("Loading");
  var loadingPage = React_Basic.makeStateless(component)(render)({});
  exports["component"] = component;
  exports["loadingPage"] = loadingPage;
  exports["render"] = render;
})(PS["Optimus.Common.Client.Pages.Loading"] = PS["Optimus.Common.Client.Pages.Loading"] || {});

(function (exports) {
  // Generated by purs version 0.12.3
  "use strict";

  var Control_Bind = PS["Control.Bind"];
  var Data_Either = PS["Data.Either"];
  var Data_Maybe = PS["Data.Maybe"];
  var Effect = PS["Effect"];
  var Effect_Aff = PS["Effect.Aff"];
  var Optimus_Common_Client_Pages_Loading = PS["Optimus.Common.Client.Pages.Loading"];
  var Optimus_Modules_CustomerSupport_Client_Route = PS["Optimus.Modules.CustomerSupport.Client.Route"];
  var Prelude = PS["Prelude"];
  var React_Basic = PS["React.Basic"];
  var Web_HTML = PS["Web.HTML"];
  var Web_HTML_Window = PS["Web.HTML.Window"];
  var component = React_Basic.createComponent("AsyncPage");

  var asyncPage = function () {
    var render = function render(v) {
      if (v.state.data instanceof Data_Maybe.Just) {
        return v.props.pageView({
          pgData: v.state.data.value0,
          history: v.props.history
        });
      }

      ;

      if (v.state.data instanceof Data_Maybe.Nothing) {
        return Optimus_Common_Client_Pages_Loading.loadingPage;
      }

      ;
      throw new Error("Failed pattern match at Optimus.Common.Client.Pages.AsyncPage (line 51, column 7 - line 53, column 20): " + [v.state.data.constructor.name]);
    };

    var handleRes = function handleRes(v) {
      return function (e) {
        if (e instanceof Data_Either.Right) {
          return v.setState(function (v1) {
            var $11 = {};

            for (var $12 in v1) {
              if ({}.hasOwnProperty.call(v1, $12)) {
                $11[$12] = v1[$12];
              }

              ;
            }

            ;
            $11.data = new Data_Maybe.Just(e.value0);
            return $11;
          });
        }

        ;

        if (e instanceof Data_Either.Left) {
          return Control_Bind.bind(Effect.bindEffect)(Web_HTML.window)(Web_HTML_Window.alert("ERROR"));
        }

        ;
        throw new Error("Failed pattern match at Optimus.Common.Client.Pages.AsyncPage (line 43, column 30 - line 48, column 5): " + [e.constructor.name]);
      };
    };

    var didMount = function didMount(self) {
      return Effect_Aff.runAff_(handleRes(self))(self.props.fetchData);
    };

    return React_Basic.make()(component)({
      initialState: {
        data: Data_Maybe.Nothing.value
      },
      didMount: didMount,
      render: render
    });
  }();

  exports["component"] = component;
  exports["asyncPage"] = asyncPage;
})(PS["Optimus.Common.Client.Pages.AsyncPage"] = PS["Optimus.Common.Client.Pages.AsyncPage"] || {});

(function (exports) {
  // Generated by purs version 0.12.3
  "use strict";

  var Control_Bind = PS["Control.Bind"];
  var Data_Either = PS["Data.Either"];
  var Data_Function = PS["Data.Function"];
  var Data_Maybe = PS["Data.Maybe"];
  var Data_Unit = PS["Data.Unit"];
  var Effect = PS["Effect"];
  var Optimus_Common_Client_Auth = PS["Optimus.Common.Client.Auth"];
  var Optimus_Modules_CustomerSupport_Client_Route = PS["Optimus.Modules.CustomerSupport.Client.Route"];
  var Prelude = PS["Prelude"];
  var React_Basic = PS["React.Basic"];
  var React_Basic_DOM = PS["React.Basic.DOM"];
  var React_Basic_DOM_Generated = PS["React.Basic.DOM.Generated"];
  var Web_HTML = PS["Web.HTML"];
  var Web_HTML_Window = PS["Web.HTML.Window"];
  var Web_Storage_Storage = PS["Web.Storage.Storage"];
  var component = React_Basic.createComponent("Callback");

  var callbackPage = function () {
    var render = function render(v) {
      return React_Basic_DOM_Generated.div_([React_Basic_DOM.text("loading...")]);
    };

    var didMount = function didMount(self) {
      return Optimus_Common_Client_Auth.parseHash(function (res) {
        if (res instanceof Data_Either.Left) {
          return self.props.history.replace(Optimus_Modules_CustomerSupport_Client_Route.Login.value);
        }

        ;

        if (res instanceof Data_Either.Right) {
          return function __do() {
            var v = Control_Bind.bind(Effect.bindEffect)(Web_HTML.window)(Web_HTML_Window.localStorage)();
            Web_Storage_Storage.setItem("accessToken")(res.value0.accessToken)(v)();
            var v1 = Optimus_Common_Client_Auth.requireAuth();

            if (v1) {
              return self.props.history.replace(Optimus_Modules_CustomerSupport_Client_Route.MRoute.create(Optimus_Modules_CustomerSupport_Client_Route.Users.create(new Optimus_Modules_CustomerSupport_Client_Route.UsersSearch({
                query: Data_Maybe.Nothing.value
              }))))();
            }

            ;
            return self.props.history.replace(Optimus_Modules_CustomerSupport_Client_Route.Login.value)();
          };
        }

        ;
        throw new Error("Failed pattern match at Optimus.Common.Client.Pages.Callback (line 30, column 7 - line 38, column 50): " + [res.constructor.name]);
      });
    };

    return React_Basic.make()(component)({
      initialState: Data_Unit.unit,
      didMount: didMount,
      render: render
    });
  }();

  exports["component"] = component;
  exports["callbackPage"] = callbackPage;
})(PS["Optimus.Common.Client.Pages.Callback"] = PS["Optimus.Common.Client.Pages.Callback"] || {});

(function (exports) {
  // Generated by purs version 0.12.3
  "use strict";

  var Control_Bind = PS["Control.Bind"];
  var Data_Function = PS["Data.Function"];
  var Data_Maybe = PS["Data.Maybe"];
  var Data_Monoid = PS["Data.Monoid"];
  var Data_Unit = PS["Data.Unit"];
  var Effect = PS["Effect"];
  var Optimus_Common_Client_Auth = PS["Optimus.Common.Client.Auth"];
  var Optimus_Modules_CustomerSupport_Client_Route = PS["Optimus.Modules.CustomerSupport.Client.Route"];
  var Prelude = PS["Prelude"];
  var React_Basic = PS["React.Basic"];

  var render = function render(v) {
    return Data_Monoid.mempty(React_Basic.monoidJSX);
  };

  var didMount = function didMount(self) {
    return function __do() {
      var v = Optimus_Common_Client_Auth.requireAuth();

      if (v) {
        return self.props.history.replace(Optimus_Modules_CustomerSupport_Client_Route.MRoute.create(Optimus_Modules_CustomerSupport_Client_Route.Users.create(new Optimus_Modules_CustomerSupport_Client_Route.UsersSearch({
          query: Data_Maybe.Nothing.value
        }))))();
      }

      ;
      return self.props.history.replace(Optimus_Modules_CustomerSupport_Client_Route.Login.value)();
    };
  };

  var component = React_Basic.createComponent("Index");
  var indexPage = React_Basic.make()(component)({
    initialState: Data_Unit.unit,
    didMount: didMount,
    render: render
  });
  exports["component"] = component;
  exports["indexPage"] = indexPage;
  exports["didMount"] = didMount;
  exports["render"] = render;
})(PS["Optimus.Common.Client.Pages.Index"] = PS["Optimus.Common.Client.Pages.Index"] || {});

(function (exports) {
  // Generated by purs version 0.12.3
  "use strict";

  var Optimus_Common_Client_Antd = PS["Optimus.Common.Client.Antd"];
  var Optimus_Common_Client_Antd_Button = PS["Optimus.Common.Client.Antd.Button"];
  var Optimus_Common_Client_Antd_Grid = PS["Optimus.Common.Client.Antd.Grid"];
  var Optimus_Common_Client_Antd_Typography = PS["Optimus.Common.Client.Antd.Typography"];
  var Optimus_Common_Client_Auth = PS["Optimus.Common.Client.Auth"];
  var React_Basic = PS["React.Basic"];
  var React_Basic_DOM = PS["React.Basic.DOM"];
  var React_Basic_DOM_Events = PS["React.Basic.DOM.Events"];
  var component = React_Basic.createComponent("Login");

  var loginPage = function () {
    var render = function render(v) {
      return Optimus_Common_Client_Antd_Grid.row()({
        type: "flex",
        justify: "center",
        children: [Optimus_Common_Client_Antd_Grid.col()({
          className: "center",
          style: React_Basic_DOM.css({
            marginTop: "100px"
          }),
          children: [Optimus_Common_Client_Antd_Typography.title_([React_Basic_DOM.text("Customer Support")]), Optimus_Common_Client_Antd_Button.button()({
            type: "primary",
            block: true,
            onClick: React_Basic_DOM_Events.capture_(Optimus_Common_Client_Auth.authorize),
            children: [React_Basic_DOM.text("Login")]
          })]
        })]
      });
    };

    return React_Basic.makeStateless(component)(render);
  }();

  exports["component"] = component;
  exports["loginPage"] = loginPage;
})(PS["Optimus.Common.Client.Pages.Login"] = PS["Optimus.Common.Client.Pages.Login"] || {});

(function (exports) {
  // Generated by purs version 0.12.3
  "use strict";

  var Data_Bounded = PS["Data.Bounded"];
  var Data_Eq = PS["Data.Eq"];
  var Data_Generic_Rep = PS["Data.Generic.Rep"];
  var Data_Generic_Rep_Bounded = PS["Data.Generic.Rep.Bounded"];
  var Data_Generic_Rep_Show = PS["Data.Generic.Rep.Show"];
  var Data_HeytingAlgebra = PS["Data.HeytingAlgebra"];
  var Data_HugeNum = PS["Data.HugeNum"];
  var Data_Ord = PS["Data.Ord"];
  var Data_Ordering = PS["Data.Ordering"];
  var Data_Show = PS["Data.Show"];
  var Data_Symbol = PS["Data.Symbol"];
  var Prelude = PS["Prelude"];
  var ZRPC = PS["ZRPC"];
  var ZRPC_Client = PS["ZRPC.Client"];
  var ZRPC_ConsPrefix = PS["ZRPC.ConsPrefix"];
  var ZRPC_ZRPCRead = PS["ZRPC.ZRPCRead"];
  var ZRPC_ZRPCWrite = PS["ZRPC.ZRPCWrite"];

  var TransactionAmountComputationModeUseSaleAmount = function () {
    function TransactionAmountComputationModeUseSaleAmount() {}

    ;
    TransactionAmountComputationModeUseSaleAmount.value = new TransactionAmountComputationModeUseSaleAmount();
    return TransactionAmountComputationModeUseSaleAmount;
  }();

  var TransactionAmountComputationModeDeriveFromSku = function () {
    function TransactionAmountComputationModeDeriveFromSku(value0) {
      this.value0 = value0;
    }

    ;

    TransactionAmountComputationModeDeriveFromSku.create = function (value0) {
      return new TransactionAmountComputationModeDeriveFromSku(value0);
    };

    return TransactionAmountComputationModeDeriveFromSku;
  }();

  var SetModeDefault = function () {
    function SetModeDefault() {}

    ;
    SetModeDefault.value = new SetModeDefault();
    return SetModeDefault;
  }();

  var SetModeFixed = function () {
    function SetModeFixed(value0) {
      this.value0 = value0;
    }

    ;

    SetModeFixed.create = function (value0) {
      return new SetModeFixed(value0);
    };

    return SetModeFixed;
  }();

  var RoundingModeHalfUp = function () {
    function RoundingModeHalfUp() {}

    ;
    RoundingModeHalfUp.value = new RoundingModeHalfUp();
    return RoundingModeHalfUp;
  }();

  var RoundingModeHalfDown = function () {
    function RoundingModeHalfDown() {}

    ;
    RoundingModeHalfDown.value = new RoundingModeHalfDown();
    return RoundingModeHalfDown;
  }();

  var RoundingModeFloor = function () {
    function RoundingModeFloor() {}

    ;
    RoundingModeFloor.value = new RoundingModeFloor();
    return RoundingModeFloor;
  }();

  var RoundingModeCeiling = function () {
    function RoundingModeCeiling() {}

    ;
    RoundingModeCeiling.value = new RoundingModeCeiling();
    return RoundingModeCeiling;
  }();

  var ProviderZap = function () {
    function ProviderZap() {}

    ;
    ProviderZap.value = new ProviderZap();
    return ProviderZap;
  }();

  var ProviderSilo = function () {
    function ProviderSilo() {}

    ;
    ProviderSilo.value = new ProviderSilo();
    return ProviderSilo;
  }();

  var ProviderHolding = function () {
    function ProviderHolding() {}

    ;
    ProviderHolding.value = new ProviderHolding();
    return ProviderHolding;
  }();

  var ProviderMerchant = function () {
    function ProviderMerchant() {}

    ;
    ProviderMerchant.value = new ProviderMerchant();
    return ProviderMerchant;
  }();

  var ProviderBranch = function () {
    function ProviderBranch() {}

    ;
    ProviderBranch.value = new ProviderBranch();
    return ProviderBranch;
  }();

  var LinkTagVerificationModeRequired = function () {
    function LinkTagVerificationModeRequired() {}

    ;
    LinkTagVerificationModeRequired.value = new LinkTagVerificationModeRequired();
    return LinkTagVerificationModeRequired;
  }();

  var LinkTagVerificationModeDisabled = function () {
    function LinkTagVerificationModeDisabled() {}

    ;
    LinkTagVerificationModeDisabled.value = new LinkTagVerificationModeDisabled();
    return LinkTagVerificationModeDisabled;
  }();

  var LinkTagVerificationModeOptional = function () {
    function LinkTagVerificationModeOptional() {}

    ;
    LinkTagVerificationModeOptional.value = new LinkTagVerificationModeOptional();
    return LinkTagVerificationModeOptional;
  }();

  var AuthorizationModePin = function () {
    function AuthorizationModePin() {}

    ;
    AuthorizationModePin.value = new AuthorizationModePin();
    return AuthorizationModePin;
  }();

  var AuthorizationModeOtp = function () {
    function AuthorizationModeOtp() {}

    ;
    AuthorizationModeOtp.value = new AuthorizationModeOtp();
    return AuthorizationModeOtp;
  }();

  var AuthorizationModePinOtp = function () {
    function AuthorizationModePinOtp() {}

    ;
    AuthorizationModePinOtp.value = new AuthorizationModePinOtp();
    return AuthorizationModePinOtp;
  }();

  var AuthorizationModeNoAuth = function () {
    function AuthorizationModeNoAuth() {}

    ;
    AuthorizationModeNoAuth.value = new AuthorizationModeNoAuth();
    return AuthorizationModeNoAuth;
  }();

  var genericTransactionAmountComputationMode = new Data_Generic_Rep.Generic(function (x) {
    if (x instanceof TransactionAmountComputationModeUseSaleAmount) {
      return new Data_Generic_Rep.Inl(Data_Generic_Rep.NoArguments.value);
    }

    ;

    if (x instanceof TransactionAmountComputationModeDeriveFromSku) {
      return new Data_Generic_Rep.Inr(x.value0);
    }

    ;
    throw new Error("Failed pattern match at ZRPC.Clients.Mercanti (line 209, column 8 - line 209, column 102): " + [x.constructor.name]);
  }, function (x) {
    if (x instanceof Data_Generic_Rep.Inl) {
      return TransactionAmountComputationModeUseSaleAmount.value;
    }

    ;

    if (x instanceof Data_Generic_Rep.Inr) {
      return new TransactionAmountComputationModeDeriveFromSku(x.value0);
    }

    ;
    throw new Error("Failed pattern match at ZRPC.Clients.Mercanti (line 209, column 8 - line 209, column 102): " + [x.constructor.name]);
  });
  var showTransactionAmountComputationMode = new Data_Show.Show(function (x) {
    return Data_Generic_Rep_Show.genericShow(genericTransactionAmountComputationMode)(Data_Generic_Rep_Show.genericShowSum(Data_Generic_Rep_Show.genericShowConstructor(Data_Generic_Rep_Show.genericShowArgsNoArguments)(new Data_Symbol.IsSymbol(function () {
      return "TransactionAmountComputationModeUseSaleAmount";
    })))(Data_Generic_Rep_Show.genericShowConstructor(Data_Generic_Rep_Show.genericShowArgsArgument(Data_Show.showRecord()(Data_Show.showRecordFieldsCons(new Data_Symbol.IsSymbol(function () {
      return "isDiscountedExcluded";
    }))(Data_Show.showRecordFieldsNil)(Data_Show.showBoolean))))(new Data_Symbol.IsSymbol(function () {
      return "TransactionAmountComputationModeDeriveFromSku";
    }))))(x);
  });
  var genericSetMode = new Data_Generic_Rep.Generic(function (x) {
    if (x instanceof SetModeDefault) {
      return new Data_Generic_Rep.Inl(Data_Generic_Rep.NoArguments.value);
    }

    ;

    if (x instanceof SetModeFixed) {
      return new Data_Generic_Rep.Inr(x.value0);
    }

    ;
    throw new Error("Failed pattern match at ZRPC.Clients.Mercanti (line 253, column 8 - line 253, column 56): " + [x.constructor.name]);
  }, function (x) {
    if (x instanceof Data_Generic_Rep.Inl) {
      return SetModeDefault.value;
    }

    ;

    if (x instanceof Data_Generic_Rep.Inr) {
      return new SetModeFixed(x.value0);
    }

    ;
    throw new Error("Failed pattern match at ZRPC.Clients.Mercanti (line 253, column 8 - line 253, column 56): " + [x.constructor.name]);
  });
  var genericRoundingMode = new Data_Generic_Rep.Generic(function (x) {
    if (x instanceof RoundingModeHalfUp) {
      return new Data_Generic_Rep.Inl(Data_Generic_Rep.NoArguments.value);
    }

    ;

    if (x instanceof RoundingModeHalfDown) {
      return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inl(Data_Generic_Rep.NoArguments.value));
    }

    ;

    if (x instanceof RoundingModeFloor) {
      return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inl(Data_Generic_Rep.NoArguments.value)));
    }

    ;

    if (x instanceof RoundingModeCeiling) {
      return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(Data_Generic_Rep.NoArguments.value)));
    }

    ;
    throw new Error("Failed pattern match at ZRPC.Clients.Mercanti (line 89, column 8 - line 89, column 62): " + [x.constructor.name]);
  }, function (x) {
    if (x instanceof Data_Generic_Rep.Inl) {
      return RoundingModeHalfUp.value;
    }

    ;

    if (x instanceof Data_Generic_Rep.Inr && x.value0 instanceof Data_Generic_Rep.Inl) {
      return RoundingModeHalfDown.value;
    }

    ;

    if (x instanceof Data_Generic_Rep.Inr && x.value0 instanceof Data_Generic_Rep.Inr && x.value0.value0 instanceof Data_Generic_Rep.Inl) {
      return RoundingModeFloor.value;
    }

    ;

    if (x instanceof Data_Generic_Rep.Inr && x.value0 instanceof Data_Generic_Rep.Inr && x.value0.value0 instanceof Data_Generic_Rep.Inr) {
      return RoundingModeCeiling.value;
    }

    ;
    throw new Error("Failed pattern match at ZRPC.Clients.Mercanti (line 89, column 8 - line 89, column 62): " + [x.constructor.name]);
  });
  var showRoundingMode = new Data_Show.Show(function (x) {
    return Data_Generic_Rep_Show.genericShow(genericRoundingMode)(Data_Generic_Rep_Show.genericShowSum(Data_Generic_Rep_Show.genericShowConstructor(Data_Generic_Rep_Show.genericShowArgsNoArguments)(new Data_Symbol.IsSymbol(function () {
      return "RoundingModeHalfUp";
    })))(Data_Generic_Rep_Show.genericShowSum(Data_Generic_Rep_Show.genericShowConstructor(Data_Generic_Rep_Show.genericShowArgsNoArguments)(new Data_Symbol.IsSymbol(function () {
      return "RoundingModeHalfDown";
    })))(Data_Generic_Rep_Show.genericShowSum(Data_Generic_Rep_Show.genericShowConstructor(Data_Generic_Rep_Show.genericShowArgsNoArguments)(new Data_Symbol.IsSymbol(function () {
      return "RoundingModeFloor";
    })))(Data_Generic_Rep_Show.genericShowConstructor(Data_Generic_Rep_Show.genericShowArgsNoArguments)(new Data_Symbol.IsSymbol(function () {
      return "RoundingModeCeiling";
    }))))))(x);
  });
  var genericProvider = new Data_Generic_Rep.Generic(function (x) {
    if (x instanceof ProviderZap) {
      return new Data_Generic_Rep.Inl(Data_Generic_Rep.NoArguments.value);
    }

    ;

    if (x instanceof ProviderSilo) {
      return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inl(Data_Generic_Rep.NoArguments.value));
    }

    ;

    if (x instanceof ProviderHolding) {
      return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inl(Data_Generic_Rep.NoArguments.value)));
    }

    ;

    if (x instanceof ProviderMerchant) {
      return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inl(Data_Generic_Rep.NoArguments.value))));
    }

    ;

    if (x instanceof ProviderBranch) {
      return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(Data_Generic_Rep.NoArguments.value))));
    }

    ;
    throw new Error("Failed pattern match at ZRPC.Clients.Mercanti (line 68, column 8 - line 68, column 54): " + [x.constructor.name]);
  }, function (x) {
    if (x instanceof Data_Generic_Rep.Inl) {
      return ProviderZap.value;
    }

    ;

    if (x instanceof Data_Generic_Rep.Inr && x.value0 instanceof Data_Generic_Rep.Inl) {
      return ProviderSilo.value;
    }

    ;

    if (x instanceof Data_Generic_Rep.Inr && x.value0 instanceof Data_Generic_Rep.Inr && x.value0.value0 instanceof Data_Generic_Rep.Inl) {
      return ProviderHolding.value;
    }

    ;

    if (x instanceof Data_Generic_Rep.Inr && x.value0 instanceof Data_Generic_Rep.Inr && x.value0.value0 instanceof Data_Generic_Rep.Inr && x.value0.value0.value0 instanceof Data_Generic_Rep.Inl) {
      return ProviderMerchant.value;
    }

    ;

    if (x instanceof Data_Generic_Rep.Inr && x.value0 instanceof Data_Generic_Rep.Inr && x.value0.value0 instanceof Data_Generic_Rep.Inr && x.value0.value0.value0 instanceof Data_Generic_Rep.Inr) {
      return ProviderBranch.value;
    }

    ;
    throw new Error("Failed pattern match at ZRPC.Clients.Mercanti (line 68, column 8 - line 68, column 54): " + [x.constructor.name]);
  });
  var showProvider = new Data_Show.Show(function (x) {
    return Data_Generic_Rep_Show.genericShow(genericProvider)(Data_Generic_Rep_Show.genericShowSum(Data_Generic_Rep_Show.genericShowConstructor(Data_Generic_Rep_Show.genericShowArgsNoArguments)(new Data_Symbol.IsSymbol(function () {
      return "ProviderZap";
    })))(Data_Generic_Rep_Show.genericShowSum(Data_Generic_Rep_Show.genericShowConstructor(Data_Generic_Rep_Show.genericShowArgsNoArguments)(new Data_Symbol.IsSymbol(function () {
      return "ProviderSilo";
    })))(Data_Generic_Rep_Show.genericShowSum(Data_Generic_Rep_Show.genericShowConstructor(Data_Generic_Rep_Show.genericShowArgsNoArguments)(new Data_Symbol.IsSymbol(function () {
      return "ProviderHolding";
    })))(Data_Generic_Rep_Show.genericShowSum(Data_Generic_Rep_Show.genericShowConstructor(Data_Generic_Rep_Show.genericShowArgsNoArguments)(new Data_Symbol.IsSymbol(function () {
      return "ProviderMerchant";
    })))(Data_Generic_Rep_Show.genericShowConstructor(Data_Generic_Rep_Show.genericShowArgsNoArguments)(new Data_Symbol.IsSymbol(function () {
      return "ProviderBranch";
    })))))))(x);
  });
  var genericLinkTagVerificationMode = new Data_Generic_Rep.Generic(function (x) {
    if (x instanceof LinkTagVerificationModeRequired) {
      return new Data_Generic_Rep.Inl(Data_Generic_Rep.NoArguments.value);
    }

    ;

    if (x instanceof LinkTagVerificationModeDisabled) {
      return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inl(Data_Generic_Rep.NoArguments.value));
    }

    ;

    if (x instanceof LinkTagVerificationModeOptional) {
      return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(Data_Generic_Rep.NoArguments.value));
    }

    ;
    throw new Error("Failed pattern match at ZRPC.Clients.Mercanti (line 124, column 8 - line 124, column 84): " + [x.constructor.name]);
  }, function (x) {
    if (x instanceof Data_Generic_Rep.Inl) {
      return LinkTagVerificationModeRequired.value;
    }

    ;

    if (x instanceof Data_Generic_Rep.Inr && x.value0 instanceof Data_Generic_Rep.Inl) {
      return LinkTagVerificationModeDisabled.value;
    }

    ;

    if (x instanceof Data_Generic_Rep.Inr && x.value0 instanceof Data_Generic_Rep.Inr) {
      return LinkTagVerificationModeOptional.value;
    }

    ;
    throw new Error("Failed pattern match at ZRPC.Clients.Mercanti (line 124, column 8 - line 124, column 84): " + [x.constructor.name]);
  });
  var showLinkTagVerificationMode = new Data_Show.Show(function (x) {
    return Data_Generic_Rep_Show.genericShow(genericLinkTagVerificationMode)(Data_Generic_Rep_Show.genericShowSum(Data_Generic_Rep_Show.genericShowConstructor(Data_Generic_Rep_Show.genericShowArgsNoArguments)(new Data_Symbol.IsSymbol(function () {
      return "LinkTagVerificationModeRequired";
    })))(Data_Generic_Rep_Show.genericShowSum(Data_Generic_Rep_Show.genericShowConstructor(Data_Generic_Rep_Show.genericShowArgsNoArguments)(new Data_Symbol.IsSymbol(function () {
      return "LinkTagVerificationModeDisabled";
    })))(Data_Generic_Rep_Show.genericShowConstructor(Data_Generic_Rep_Show.genericShowArgsNoArguments)(new Data_Symbol.IsSymbol(function () {
      return "LinkTagVerificationModeOptional";
    })))))(x);
  });
  var genericAuthorizationMode = new Data_Generic_Rep.Generic(function (x) {
    if (x instanceof AuthorizationModePin) {
      return new Data_Generic_Rep.Inl(Data_Generic_Rep.NoArguments.value);
    }

    ;

    if (x instanceof AuthorizationModeOtp) {
      return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inl(Data_Generic_Rep.NoArguments.value));
    }

    ;

    if (x instanceof AuthorizationModePinOtp) {
      return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inl(Data_Generic_Rep.NoArguments.value)));
    }

    ;

    if (x instanceof AuthorizationModeNoAuth) {
      return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(Data_Generic_Rep.NoArguments.value)));
    }

    ;
    throw new Error("Failed pattern match at ZRPC.Clients.Mercanti (line 149, column 8 - line 149, column 72): " + [x.constructor.name]);
  }, function (x) {
    if (x instanceof Data_Generic_Rep.Inl) {
      return AuthorizationModePin.value;
    }

    ;

    if (x instanceof Data_Generic_Rep.Inr && x.value0 instanceof Data_Generic_Rep.Inl) {
      return AuthorizationModeOtp.value;
    }

    ;

    if (x instanceof Data_Generic_Rep.Inr && x.value0 instanceof Data_Generic_Rep.Inr && x.value0.value0 instanceof Data_Generic_Rep.Inl) {
      return AuthorizationModePinOtp.value;
    }

    ;

    if (x instanceof Data_Generic_Rep.Inr && x.value0 instanceof Data_Generic_Rep.Inr && x.value0.value0 instanceof Data_Generic_Rep.Inr) {
      return AuthorizationModeNoAuth.value;
    }

    ;
    throw new Error("Failed pattern match at ZRPC.Clients.Mercanti (line 149, column 8 - line 149, column 72): " + [x.constructor.name]);
  });
  var showAuthorizationMode = new Data_Show.Show(function (x) {
    return Data_Generic_Rep_Show.genericShow(genericAuthorizationMode)(Data_Generic_Rep_Show.genericShowSum(Data_Generic_Rep_Show.genericShowConstructor(Data_Generic_Rep_Show.genericShowArgsNoArguments)(new Data_Symbol.IsSymbol(function () {
      return "AuthorizationModePin";
    })))(Data_Generic_Rep_Show.genericShowSum(Data_Generic_Rep_Show.genericShowConstructor(Data_Generic_Rep_Show.genericShowArgsNoArguments)(new Data_Symbol.IsSymbol(function () {
      return "AuthorizationModeOtp";
    })))(Data_Generic_Rep_Show.genericShowSum(Data_Generic_Rep_Show.genericShowConstructor(Data_Generic_Rep_Show.genericShowArgsNoArguments)(new Data_Symbol.IsSymbol(function () {
      return "AuthorizationModePinOtp";
    })))(Data_Generic_Rep_Show.genericShowConstructor(Data_Generic_Rep_Show.genericShowArgsNoArguments)(new Data_Symbol.IsSymbol(function () {
      return "AuthorizationModeNoAuth";
    }))))))(x);
  });
  var eqTransactionAmountComputationMode = new Data_Eq.Eq(function (x) {
    return function (y) {
      if (x instanceof TransactionAmountComputationModeUseSaleAmount && y instanceof TransactionAmountComputationModeUseSaleAmount) {
        return true;
      }

      ;

      if (x instanceof TransactionAmountComputationModeDeriveFromSku && y instanceof TransactionAmountComputationModeDeriveFromSku) {
        return x.value0.isDiscountedExcluded === y.value0.isDiscountedExcluded;
      }

      ;
      return false;
    };
  });
  var ordTransactionAmountComputationMode = new Data_Ord.Ord(function () {
    return eqTransactionAmountComputationMode;
  }, function (x) {
    return function (y) {
      if (x instanceof TransactionAmountComputationModeUseSaleAmount && y instanceof TransactionAmountComputationModeUseSaleAmount) {
        return Data_Ordering.EQ.value;
      }

      ;

      if (x instanceof TransactionAmountComputationModeUseSaleAmount) {
        return Data_Ordering.LT.value;
      }

      ;

      if (y instanceof TransactionAmountComputationModeUseSaleAmount) {
        return Data_Ordering.GT.value;
      }

      ;

      if (x instanceof TransactionAmountComputationModeDeriveFromSku && y instanceof TransactionAmountComputationModeDeriveFromSku) {
        return Data_Ord.compare(Data_Ord.ordBoolean)(x.value0.isDiscountedExcluded)(y.value0.isDiscountedExcluded);
      }

      ;
      throw new Error("Failed pattern match at ZRPC.Clients.Mercanti (line 210, column 8 - line 210, column 92): " + [x.constructor.name, y.constructor.name]);
    };
  });
  var eqRoundingMode = new Data_Eq.Eq(function (x) {
    return function (y) {
      if (x instanceof RoundingModeHalfUp && y instanceof RoundingModeHalfUp) {
        return true;
      }

      ;

      if (x instanceof RoundingModeHalfDown && y instanceof RoundingModeHalfDown) {
        return true;
      }

      ;

      if (x instanceof RoundingModeFloor && y instanceof RoundingModeFloor) {
        return true;
      }

      ;

      if (x instanceof RoundingModeCeiling && y instanceof RoundingModeCeiling) {
        return true;
      }

      ;
      return false;
    };
  });
  var ordRoundingMode = new Data_Ord.Ord(function () {
    return eqRoundingMode;
  }, function (x) {
    return function (y) {
      if (x instanceof RoundingModeHalfUp && y instanceof RoundingModeHalfUp) {
        return Data_Ordering.EQ.value;
      }

      ;

      if (x instanceof RoundingModeHalfUp) {
        return Data_Ordering.LT.value;
      }

      ;

      if (y instanceof RoundingModeHalfUp) {
        return Data_Ordering.GT.value;
      }

      ;

      if (x instanceof RoundingModeHalfDown && y instanceof RoundingModeHalfDown) {
        return Data_Ordering.EQ.value;
      }

      ;

      if (x instanceof RoundingModeHalfDown) {
        return Data_Ordering.LT.value;
      }

      ;

      if (y instanceof RoundingModeHalfDown) {
        return Data_Ordering.GT.value;
      }

      ;

      if (x instanceof RoundingModeFloor && y instanceof RoundingModeFloor) {
        return Data_Ordering.EQ.value;
      }

      ;

      if (x instanceof RoundingModeFloor) {
        return Data_Ordering.LT.value;
      }

      ;

      if (y instanceof RoundingModeFloor) {
        return Data_Ordering.GT.value;
      }

      ;

      if (x instanceof RoundingModeCeiling && y instanceof RoundingModeCeiling) {
        return Data_Ordering.EQ.value;
      }

      ;
      throw new Error("Failed pattern match at ZRPC.Clients.Mercanti (line 90, column 8 - line 90, column 52): " + [x.constructor.name, y.constructor.name]);
    };
  });
  var eqLinkTagVerificationMode = new Data_Eq.Eq(function (x) {
    return function (y) {
      if (x instanceof LinkTagVerificationModeRequired && y instanceof LinkTagVerificationModeRequired) {
        return true;
      }

      ;

      if (x instanceof LinkTagVerificationModeDisabled && y instanceof LinkTagVerificationModeDisabled) {
        return true;
      }

      ;

      if (x instanceof LinkTagVerificationModeOptional && y instanceof LinkTagVerificationModeOptional) {
        return true;
      }

      ;
      return false;
    };
  });
  var ordLinkTagVerificationMode = new Data_Ord.Ord(function () {
    return eqLinkTagVerificationMode;
  }, function (x) {
    return function (y) {
      if (x instanceof LinkTagVerificationModeRequired && y instanceof LinkTagVerificationModeRequired) {
        return Data_Ordering.EQ.value;
      }

      ;

      if (x instanceof LinkTagVerificationModeRequired) {
        return Data_Ordering.LT.value;
      }

      ;

      if (y instanceof LinkTagVerificationModeRequired) {
        return Data_Ordering.GT.value;
      }

      ;

      if (x instanceof LinkTagVerificationModeDisabled && y instanceof LinkTagVerificationModeDisabled) {
        return Data_Ordering.EQ.value;
      }

      ;

      if (x instanceof LinkTagVerificationModeDisabled) {
        return Data_Ordering.LT.value;
      }

      ;

      if (y instanceof LinkTagVerificationModeDisabled) {
        return Data_Ordering.GT.value;
      }

      ;

      if (x instanceof LinkTagVerificationModeOptional && y instanceof LinkTagVerificationModeOptional) {
        return Data_Ordering.EQ.value;
      }

      ;
      throw new Error("Failed pattern match at ZRPC.Clients.Mercanti (line 125, column 8 - line 125, column 74): " + [x.constructor.name, y.constructor.name]);
    };
  });
  var eqAuthorizationMode = new Data_Eq.Eq(function (x) {
    return function (y) {
      if (x instanceof AuthorizationModePin && y instanceof AuthorizationModePin) {
        return true;
      }

      ;

      if (x instanceof AuthorizationModeOtp && y instanceof AuthorizationModeOtp) {
        return true;
      }

      ;

      if (x instanceof AuthorizationModePinOtp && y instanceof AuthorizationModePinOtp) {
        return true;
      }

      ;

      if (x instanceof AuthorizationModeNoAuth && y instanceof AuthorizationModeNoAuth) {
        return true;
      }

      ;
      return false;
    };
  });
  var ordAuthorizationMode = new Data_Ord.Ord(function () {
    return eqAuthorizationMode;
  }, function (x) {
    return function (y) {
      if (x instanceof AuthorizationModePin && y instanceof AuthorizationModePin) {
        return Data_Ordering.EQ.value;
      }

      ;

      if (x instanceof AuthorizationModePin) {
        return Data_Ordering.LT.value;
      }

      ;

      if (y instanceof AuthorizationModePin) {
        return Data_Ordering.GT.value;
      }

      ;

      if (x instanceof AuthorizationModeOtp && y instanceof AuthorizationModeOtp) {
        return Data_Ordering.EQ.value;
      }

      ;

      if (x instanceof AuthorizationModeOtp) {
        return Data_Ordering.LT.value;
      }

      ;

      if (y instanceof AuthorizationModeOtp) {
        return Data_Ordering.GT.value;
      }

      ;

      if (x instanceof AuthorizationModePinOtp && y instanceof AuthorizationModePinOtp) {
        return Data_Ordering.EQ.value;
      }

      ;

      if (x instanceof AuthorizationModePinOtp) {
        return Data_Ordering.LT.value;
      }

      ;

      if (y instanceof AuthorizationModePinOtp) {
        return Data_Ordering.GT.value;
      }

      ;

      if (x instanceof AuthorizationModeNoAuth && y instanceof AuthorizationModeNoAuth) {
        return Data_Ordering.EQ.value;
      }

      ;
      throw new Error("Failed pattern match at ZRPC.Clients.Mercanti (line 150, column 8 - line 150, column 62): " + [x.constructor.name, y.constructor.name]);
    };
  });
  var consPrefixTransactionAmountComputationMode = new ZRPC_ConsPrefix.ConsPrefix(function (v) {
    return "TransactionAmountComputationMode";
  });
  var zrpcReadTransactionAmountComputationMode = new ZRPC_ZRPCRead.ZRPCRead(function (x) {
    return ZRPC_ZRPCRead.genericZRPCRead(consPrefixTransactionAmountComputationMode)(genericTransactionAmountComputationMode)(ZRPC_ZRPCRead.readRepSum(ZRPC_ZRPCRead.readRepNoArgs(new Data_Symbol.IsSymbol(function () {
      return "TransactionAmountComputationModeUseSaleAmount";
    })))(ZRPC_ZRPCRead.readRepRecArg(new Data_Symbol.IsSymbol(function () {
      return "TransactionAmountComputationModeDeriveFromSku";
    }))(ZRPC_ZRPCRead.readRecord()(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
      return "isDiscountedExcluded";
    }))(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_ZRPCRead.zrpcReadBoolean))(ZRPC_ZRPCRead.zrpcReadFieldsNil)()()))))(x);
  });
  var zrpcWriteTransactionAmountComputationMode = new ZRPC_ZRPCWrite.ZRPCWrite(function (x) {
    return ZRPC_ZRPCWrite.genericZRPCWrite(consPrefixTransactionAmountComputationMode)(genericTransactionAmountComputationMode)(ZRPC_ZRPCWrite.writeRepSum(ZRPC_ZRPCWrite.writeRepNoArgs(new Data_Symbol.IsSymbol(function () {
      return "TransactionAmountComputationModeUseSaleAmount";
    })))(ZRPC_ZRPCWrite.writeRepRecArg(new Data_Symbol.IsSymbol(function () {
      return "TransactionAmountComputationModeDeriveFromSku";
    }))()(ZRPC_ZRPCWrite.zrpcWriteFieldsCons(new Data_Symbol.IsSymbol(function () {
      return "isDiscountedExcluded";
    }))(ZRPC_ZRPCWrite.zrpcWriteFieldId(ZRPC_ZRPCWrite.zrpcWriteBoolean))(ZRPC_ZRPCWrite.zrpcWriteFieldsNil)())()))(x);
  });
  var consPrefixSetMode = new ZRPC_ConsPrefix.ConsPrefix(function (v) {
    return "SetMode";
  });

  var zrpcWriteSetMode = function zrpcWriteSetMode(dictZRPCWrite) {
    return new ZRPC_ZRPCWrite.ZRPCWrite(function (x) {
      return ZRPC_ZRPCWrite.genericZRPCWrite(consPrefixSetMode)(genericSetMode)(ZRPC_ZRPCWrite.writeRepSum(ZRPC_ZRPCWrite.writeRepNoArgs(new Data_Symbol.IsSymbol(function () {
        return "SetModeDefault";
      })))(ZRPC_ZRPCWrite.writeRepRecArg(new Data_Symbol.IsSymbol(function () {
        return "SetModeFixed";
      }))()(ZRPC_ZRPCWrite.zrpcWriteFieldsCons(new Data_Symbol.IsSymbol(function () {
        return "value";
      }))(ZRPC_ZRPCWrite.zrpcWriteFieldId(dictZRPCWrite))(ZRPC_ZRPCWrite.zrpcWriteFieldsNil)())()))(x);
    });
  };

  var consPrefixRoundingMode = new ZRPC_ConsPrefix.ConsPrefix(function (v) {
    return "RoundingMode";
  });
  var zrpcReadRoundingMode = new ZRPC_ZRPCRead.ZRPCRead(function (x) {
    return ZRPC_ZRPCRead.genericZRPCRead(consPrefixRoundingMode)(genericRoundingMode)(ZRPC_ZRPCRead.readRepSum(ZRPC_ZRPCRead.readRepNoArgs(new Data_Symbol.IsSymbol(function () {
      return "RoundingModeHalfUp";
    })))(ZRPC_ZRPCRead.readRepSum(ZRPC_ZRPCRead.readRepNoArgs(new Data_Symbol.IsSymbol(function () {
      return "RoundingModeHalfDown";
    })))(ZRPC_ZRPCRead.readRepSum(ZRPC_ZRPCRead.readRepNoArgs(new Data_Symbol.IsSymbol(function () {
      return "RoundingModeFloor";
    })))(ZRPC_ZRPCRead.readRepNoArgs(new Data_Symbol.IsSymbol(function () {
      return "RoundingModeCeiling";
    }))))))(x);
  });
  var zrpcWriteRoundingMode = new ZRPC_ZRPCWrite.ZRPCWrite(function (x) {
    return ZRPC_ZRPCWrite.genericZRPCWrite(consPrefixRoundingMode)(genericRoundingMode)(ZRPC_ZRPCWrite.writeRepSum(ZRPC_ZRPCWrite.writeRepNoArgs(new Data_Symbol.IsSymbol(function () {
      return "RoundingModeHalfUp";
    })))(ZRPC_ZRPCWrite.writeRepSum(ZRPC_ZRPCWrite.writeRepNoArgs(new Data_Symbol.IsSymbol(function () {
      return "RoundingModeHalfDown";
    })))(ZRPC_ZRPCWrite.writeRepSum(ZRPC_ZRPCWrite.writeRepNoArgs(new Data_Symbol.IsSymbol(function () {
      return "RoundingModeFloor";
    })))(ZRPC_ZRPCWrite.writeRepNoArgs(new Data_Symbol.IsSymbol(function () {
      return "RoundingModeCeiling";
    }))))))(x);
  });
  var consPrefixProvider = new ZRPC_ConsPrefix.ConsPrefix(function (v) {
    return "Provider";
  });
  var zrpcReadProvider = new ZRPC_ZRPCRead.ZRPCRead(function (x) {
    return ZRPC_ZRPCRead.genericZRPCRead(consPrefixProvider)(genericProvider)(ZRPC_ZRPCRead.readRepSum(ZRPC_ZRPCRead.readRepNoArgs(new Data_Symbol.IsSymbol(function () {
      return "ProviderZap";
    })))(ZRPC_ZRPCRead.readRepSum(ZRPC_ZRPCRead.readRepNoArgs(new Data_Symbol.IsSymbol(function () {
      return "ProviderSilo";
    })))(ZRPC_ZRPCRead.readRepSum(ZRPC_ZRPCRead.readRepNoArgs(new Data_Symbol.IsSymbol(function () {
      return "ProviderHolding";
    })))(ZRPC_ZRPCRead.readRepSum(ZRPC_ZRPCRead.readRepNoArgs(new Data_Symbol.IsSymbol(function () {
      return "ProviderMerchant";
    })))(ZRPC_ZRPCRead.readRepNoArgs(new Data_Symbol.IsSymbol(function () {
      return "ProviderBranch";
    })))))))(x);
  });
  var consPrefixLinkTagVerificationMode = new ZRPC_ConsPrefix.ConsPrefix(function (v) {
    return "LinkTagVerificationMode";
  });
  var zrpcReadLinkTagVerificationMode = new ZRPC_ZRPCRead.ZRPCRead(function (x) {
    return ZRPC_ZRPCRead.genericZRPCRead(consPrefixLinkTagVerificationMode)(genericLinkTagVerificationMode)(ZRPC_ZRPCRead.readRepSum(ZRPC_ZRPCRead.readRepNoArgs(new Data_Symbol.IsSymbol(function () {
      return "LinkTagVerificationModeRequired";
    })))(ZRPC_ZRPCRead.readRepSum(ZRPC_ZRPCRead.readRepNoArgs(new Data_Symbol.IsSymbol(function () {
      return "LinkTagVerificationModeDisabled";
    })))(ZRPC_ZRPCRead.readRepNoArgs(new Data_Symbol.IsSymbol(function () {
      return "LinkTagVerificationModeOptional";
    })))))(x);
  });
  var zrpcWriteLinkTagVerificationMode = new ZRPC_ZRPCWrite.ZRPCWrite(function (x) {
    return ZRPC_ZRPCWrite.genericZRPCWrite(consPrefixLinkTagVerificationMode)(genericLinkTagVerificationMode)(ZRPC_ZRPCWrite.writeRepSum(ZRPC_ZRPCWrite.writeRepNoArgs(new Data_Symbol.IsSymbol(function () {
      return "LinkTagVerificationModeRequired";
    })))(ZRPC_ZRPCWrite.writeRepSum(ZRPC_ZRPCWrite.writeRepNoArgs(new Data_Symbol.IsSymbol(function () {
      return "LinkTagVerificationModeDisabled";
    })))(ZRPC_ZRPCWrite.writeRepNoArgs(new Data_Symbol.IsSymbol(function () {
      return "LinkTagVerificationModeOptional";
    })))))(x);
  });
  var consPrefixAuthorizationMode = new ZRPC_ConsPrefix.ConsPrefix(function (v) {
    return "AuthorizationMode";
  });
  var zrpcReadAuthorizationMode = new ZRPC_ZRPCRead.ZRPCRead(function (x) {
    return ZRPC_ZRPCRead.genericZRPCRead(consPrefixAuthorizationMode)(genericAuthorizationMode)(ZRPC_ZRPCRead.readRepSum(ZRPC_ZRPCRead.readRepNoArgs(new Data_Symbol.IsSymbol(function () {
      return "AuthorizationModePin";
    })))(ZRPC_ZRPCRead.readRepSum(ZRPC_ZRPCRead.readRepNoArgs(new Data_Symbol.IsSymbol(function () {
      return "AuthorizationModeOtp";
    })))(ZRPC_ZRPCRead.readRepSum(ZRPC_ZRPCRead.readRepNoArgs(new Data_Symbol.IsSymbol(function () {
      return "AuthorizationModePinOtp";
    })))(ZRPC_ZRPCRead.readRepNoArgs(new Data_Symbol.IsSymbol(function () {
      return "AuthorizationModeNoAuth";
    }))))))(x);
  });
  var zrpcWriteAuthorizationMode = new ZRPC_ZRPCWrite.ZRPCWrite(function (x) {
    return ZRPC_ZRPCWrite.genericZRPCWrite(consPrefixAuthorizationMode)(genericAuthorizationMode)(ZRPC_ZRPCWrite.writeRepSum(ZRPC_ZRPCWrite.writeRepNoArgs(new Data_Symbol.IsSymbol(function () {
      return "AuthorizationModePin";
    })))(ZRPC_ZRPCWrite.writeRepSum(ZRPC_ZRPCWrite.writeRepNoArgs(new Data_Symbol.IsSymbol(function () {
      return "AuthorizationModeOtp";
    })))(ZRPC_ZRPCWrite.writeRepSum(ZRPC_ZRPCWrite.writeRepNoArgs(new Data_Symbol.IsSymbol(function () {
      return "AuthorizationModePinOtp";
    })))(ZRPC_ZRPCWrite.writeRepNoArgs(new Data_Symbol.IsSymbol(function () {
      return "AuthorizationModeNoAuth";
    }))))))(x);
  });
  var boundedTransactionAmountComputationMode = new Data_Bounded.Bounded(function () {
    return ordTransactionAmountComputationMode;
  }, Data_Generic_Rep_Bounded.genericBottom(genericTransactionAmountComputationMode)(Data_Generic_Rep_Bounded.genericBottomSum(Data_Generic_Rep_Bounded.genericBottomConstructor(Data_Generic_Rep_Bounded.genericBottomNoArguments))), new TransactionAmountComputationModeDeriveFromSku({
    isDiscountedExcluded: false
  }));
  var boundedRoundingMode = new Data_Bounded.Bounded(function () {
    return ordRoundingMode;
  }, Data_Generic_Rep_Bounded.genericBottom(genericRoundingMode)(Data_Generic_Rep_Bounded.genericBottomSum(Data_Generic_Rep_Bounded.genericBottomConstructor(Data_Generic_Rep_Bounded.genericBottomNoArguments))), Data_Generic_Rep_Bounded.genericTop(genericRoundingMode)(Data_Generic_Rep_Bounded.genericTopSum(Data_Generic_Rep_Bounded.genericTopSum(Data_Generic_Rep_Bounded.genericTopSum(Data_Generic_Rep_Bounded.genericTopConstructor(Data_Generic_Rep_Bounded.genericTopNoArguments))))));
  var boundedLinkTagVerificationMode = new Data_Bounded.Bounded(function () {
    return ordLinkTagVerificationMode;
  }, Data_Generic_Rep_Bounded.genericBottom(genericLinkTagVerificationMode)(Data_Generic_Rep_Bounded.genericBottomSum(Data_Generic_Rep_Bounded.genericBottomConstructor(Data_Generic_Rep_Bounded.genericBottomNoArguments))), Data_Generic_Rep_Bounded.genericTop(genericLinkTagVerificationMode)(Data_Generic_Rep_Bounded.genericTopSum(Data_Generic_Rep_Bounded.genericTopSum(Data_Generic_Rep_Bounded.genericTopConstructor(Data_Generic_Rep_Bounded.genericTopNoArguments)))));
  var boundedAuthorizationMode = new Data_Bounded.Bounded(function () {
    return ordAuthorizationMode;
  }, Data_Generic_Rep_Bounded.genericBottom(genericAuthorizationMode)(Data_Generic_Rep_Bounded.genericBottomSum(Data_Generic_Rep_Bounded.genericBottomConstructor(Data_Generic_Rep_Bounded.genericBottomNoArguments))), Data_Generic_Rep_Bounded.genericTop(genericAuthorizationMode)(Data_Generic_Rep_Bounded.genericTopSum(Data_Generic_Rep_Bounded.genericTopSum(Data_Generic_Rep_Bounded.genericTopSum(Data_Generic_Rep_Bounded.genericTopConstructor(Data_Generic_Rep_Bounded.genericTopNoArguments))))));
  exports["ProviderZap"] = ProviderZap;
  exports["ProviderSilo"] = ProviderSilo;
  exports["ProviderHolding"] = ProviderHolding;
  exports["ProviderMerchant"] = ProviderMerchant;
  exports["ProviderBranch"] = ProviderBranch;
  exports["RoundingModeHalfUp"] = RoundingModeHalfUp;
  exports["RoundingModeHalfDown"] = RoundingModeHalfDown;
  exports["RoundingModeFloor"] = RoundingModeFloor;
  exports["RoundingModeCeiling"] = RoundingModeCeiling;
  exports["LinkTagVerificationModeRequired"] = LinkTagVerificationModeRequired;
  exports["LinkTagVerificationModeDisabled"] = LinkTagVerificationModeDisabled;
  exports["LinkTagVerificationModeOptional"] = LinkTagVerificationModeOptional;
  exports["AuthorizationModePin"] = AuthorizationModePin;
  exports["AuthorizationModeOtp"] = AuthorizationModeOtp;
  exports["AuthorizationModePinOtp"] = AuthorizationModePinOtp;
  exports["AuthorizationModeNoAuth"] = AuthorizationModeNoAuth;
  exports["TransactionAmountComputationModeUseSaleAmount"] = TransactionAmountComputationModeUseSaleAmount;
  exports["TransactionAmountComputationModeDeriveFromSku"] = TransactionAmountComputationModeDeriveFromSku;
  exports["SetModeDefault"] = SetModeDefault;
  exports["SetModeFixed"] = SetModeFixed;
  exports["genericProvider"] = genericProvider;
  exports["consPrefixProvider"] = consPrefixProvider;
  exports["showProvider"] = showProvider;
  exports["zrpcReadProvider"] = zrpcReadProvider;
  exports["eqRoundingMode"] = eqRoundingMode;
  exports["genericRoundingMode"] = genericRoundingMode;
  exports["ordRoundingMode"] = ordRoundingMode;
  exports["boundedRoundingMode"] = boundedRoundingMode;
  exports["consPrefixRoundingMode"] = consPrefixRoundingMode;
  exports["showRoundingMode"] = showRoundingMode;
  exports["zrpcReadRoundingMode"] = zrpcReadRoundingMode;
  exports["zrpcWriteRoundingMode"] = zrpcWriteRoundingMode;
  exports["eqLinkTagVerificationMode"] = eqLinkTagVerificationMode;
  exports["genericLinkTagVerificationMode"] = genericLinkTagVerificationMode;
  exports["ordLinkTagVerificationMode"] = ordLinkTagVerificationMode;
  exports["boundedLinkTagVerificationMode"] = boundedLinkTagVerificationMode;
  exports["consPrefixLinkTagVerificationMode"] = consPrefixLinkTagVerificationMode;
  exports["showLinkTagVerificationMode"] = showLinkTagVerificationMode;
  exports["zrpcReadLinkTagVerificationMode"] = zrpcReadLinkTagVerificationMode;
  exports["zrpcWriteLinkTagVerificationMode"] = zrpcWriteLinkTagVerificationMode;
  exports["eqAuthorizationMode"] = eqAuthorizationMode;
  exports["genericAuthorizationMode"] = genericAuthorizationMode;
  exports["ordAuthorizationMode"] = ordAuthorizationMode;
  exports["boundedAuthorizationMode"] = boundedAuthorizationMode;
  exports["consPrefixAuthorizationMode"] = consPrefixAuthorizationMode;
  exports["showAuthorizationMode"] = showAuthorizationMode;
  exports["zrpcReadAuthorizationMode"] = zrpcReadAuthorizationMode;
  exports["zrpcWriteAuthorizationMode"] = zrpcWriteAuthorizationMode;
  exports["eqTransactionAmountComputationMode"] = eqTransactionAmountComputationMode;
  exports["genericTransactionAmountComputationMode"] = genericTransactionAmountComputationMode;
  exports["ordTransactionAmountComputationMode"] = ordTransactionAmountComputationMode;
  exports["boundedTransactionAmountComputationMode"] = boundedTransactionAmountComputationMode;
  exports["consPrefixTransactionAmountComputationMode"] = consPrefixTransactionAmountComputationMode;
  exports["showTransactionAmountComputationMode"] = showTransactionAmountComputationMode;
  exports["zrpcReadTransactionAmountComputationMode"] = zrpcReadTransactionAmountComputationMode;
  exports["zrpcWriteTransactionAmountComputationMode"] = zrpcWriteTransactionAmountComputationMode;
  exports["genericSetMode"] = genericSetMode;
  exports["consPrefixSetMode"] = consPrefixSetMode;
  exports["zrpcWriteSetMode"] = zrpcWriteSetMode;
})(PS["ZRPC.Clients.Mercanti"] = PS["ZRPC.Clients.Mercanti"] || {});

(function (exports) {
  // Generated by purs version 0.12.3
  "use strict";

  var Data_Eq = PS["Data.Eq"];
  var Data_Function = PS["Data.Function"];
  var Data_Generic_Rep = PS["Data.Generic.Rep"];
  var Data_Generic_Rep_Eq = PS["Data.Generic.Rep.Eq"];
  var Data_Generic_Rep_Show = PS["Data.Generic.Rep.Show"];
  var Data_Maybe = PS["Data.Maybe"];
  var Data_Show = PS["Data.Show"];
  var Data_Symbol = PS["Data.Symbol"];
  var Prelude = PS["Prelude"];
  var Record = PS["Record"];
  var Record_Builder = PS["Record.Builder"];
  var Type_Data_Boolean = PS["Type.Data.Boolean"];
  var Type_Row = PS["Type.Row"];
  var Unsafe_Coerce = PS["Unsafe.Coerce"];
  var ZRPC_Clients_Mercanti = PS["ZRPC.Clients.Mercanti"];

  var Default = function () {
    function Default() {}

    ;
    Default.value = new Default();
    return Default;
  }();

  var Fixed = function () {
    function Fixed() {}

    ;
    Fixed.value = new Fixed();
    return Fixed;
  }();

  var RaToRb = function RaToRb(recordAToRecordB) {
    this.recordAToRecordB = recordAToRecordB;
  };

  var RaToRbRL = function RaToRbRL(recordAToRecordBRL) {
    this.recordAToRecordBRL = recordAToRecordBRL;
  };

  var recordAToRecordBRL = function recordAToRecordBRL(dict) {
    return dict.recordAToRecordBRL;
  };

  var recordAToRecordB = function recordAToRecordB(dict) {
    return dict.recordAToRecordB;
  };

  var raToRbRecord = function raToRbRecord(dictRowToList) {
    return function (dictRaToRbRL) {
      return new RaToRb(recordAToRecordBRL(dictRaToRbRL)(Type_Row.RLProxy.value));
    };
  };

  var raToRbRLNil = new RaToRbRL(function (v) {
    return function (v1) {
      return {};
    };
  });

  var raToRbRLConsSLtoSFS = function raToRbRLConsSLtoSFS(dictRaToRbRL) {
    return function (dictIsSymbol) {
      return function (dictCons) {
        return function (dictLacks) {
          return function (dictCons1) {
            return new RaToRbRL(function (v) {
              return function (p) {
                var rest = recordAToRecordBRL(dictRaToRbRL)(Type_Row.RLProxy.value)(p);

                var convert = function convert(v1) {
                  return {
                    selected: function () {
                      if (v1.fixed instanceof Data_Maybe.Just) {
                        return Fixed.value;
                      }

                      ;

                      if (v1.fixed instanceof Data_Maybe.Nothing) {
                        return Default.value;
                      }

                      ;
                      throw new Error("Failed pattern match at Optimus.Modules.Tmp.Client.Components.Settings.Utils (line 86, column 17 - line 88, column 37): " + [v1.fixed.constructor.name]);
                    }(),
                    "default": v1["default"],
                    fixed: v1.fixed
                  };
                };

                var sfs = convert(Record.get(dictIsSymbol)(dictCons)(Data_Symbol.SProxy.value)(p));
                return Record_Builder.build(Record_Builder.insert(dictCons1)(dictLacks)(dictIsSymbol)(Data_Symbol.SProxy.value)(sfs))(rest);
              };
            });
          };
        };
      };
    };
  };

  var settingSLToSFS = function settingSLToSFS(sl) {
    return recordAToRecordB(raToRbRecord()(raToRbRLConsSLtoSFS(raToRbRLConsSLtoSFS(raToRbRLConsSLtoSFS(raToRbRLConsSLtoSFS(raToRbRLConsSLtoSFS(raToRbRLConsSLtoSFS(raToRbRLConsSLtoSFS(raToRbRLConsSLtoSFS(raToRbRLConsSLtoSFS(raToRbRLConsSLtoSFS(raToRbRLConsSLtoSFS(raToRbRLConsSLtoSFS(raToRbRLConsSLtoSFS(raToRbRLConsSLtoSFS(raToRbRLConsSLtoSFS(raToRbRLConsSLtoSFS(raToRbRLConsSLtoSFS(raToRbRLConsSLtoSFS(raToRbRLConsSLtoSFS(raToRbRLConsSLtoSFS(raToRbRLConsSLtoSFS(raToRbRLConsSLtoSFS(raToRbRLConsSLtoSFS(raToRbRLConsSLtoSFS(raToRbRLConsSLtoSFS(raToRbRLConsSLtoSFS(raToRbRLConsSLtoSFS(raToRbRLConsSLtoSFS(raToRbRLNil)(new Data_Symbol.IsSymbol(function () {
      return "voidAuthenticationMode";
    }))()()())(new Data_Symbol.IsSymbol(function () {
      return "transactionCurrency";
    }))()()())(new Data_Symbol.IsSymbol(function () {
      return "transactionAmountComputationMode";
    }))()()())(new Data_Symbol.IsSymbol(function () {
      return "timeZone";
    }))()()())(new Data_Symbol.IsSymbol(function () {
      return "senderEmail";
    }))()()())(new Data_Symbol.IsSymbol(function () {
      return "scalingMethod";
    }))()()())(new Data_Symbol.IsSymbol(function () {
      return "qrValidityDuration";
    }))()()())(new Data_Symbol.IsSymbol(function () {
      return "qrScanCountLimit";
    }))()()())(new Data_Symbol.IsSymbol(function () {
      return "qrScanCooldown";
    }))()()())(new Data_Symbol.IsSymbol(function () {
      return "posSetUp";
    }))()()())(new Data_Symbol.IsSymbol(function () {
      return "phoneNumberPrefix";
    }))()()())(new Data_Symbol.IsSymbol(function () {
      return "linkTagVerificationMode";
    }))()()())(new Data_Symbol.IsSymbol(function () {
      return "isRedeemSmsReceiptEnabled";
    }))()()())(new Data_Symbol.IsSymbol(function () {
      return "isRedeemEmailReceiptEnabled";
    }))()()())(new Data_Symbol.IsSymbol(function () {
      return "isQuickRedeemEnabled";
    }))()()())(new Data_Symbol.IsSymbol(function () {
      return "isProfileOnRedeemRequired";
    }))()()())(new Data_Symbol.IsSymbol(function () {
      return "isProfileOnEarnRequired";
    }))()()())(new Data_Symbol.IsSymbol(function () {
      return "isPayInCashEnabled";
    }))()()())(new Data_Symbol.IsSymbol(function () {
      return "isLinkCardOnRegistrationRequired";
    }))()()())(new Data_Symbol.IsSymbol(function () {
      return "isIdMethodByUnverifiedTagAllowed";
    }))()()())(new Data_Symbol.IsSymbol(function () {
      return "isEarnSmsReceiptEnabled";
    }))()()())(new Data_Symbol.IsSymbol(function () {
      return "isEarnEmailReceiptEnabled";
    }))()()())(new Data_Symbol.IsSymbol(function () {
      return "isClearedVoidable";
    }))()()())(new Data_Symbol.IsSymbol(function () {
      return "hasCashbackOnRedemptionOverflow";
    }))()()())(new Data_Symbol.IsSymbol(function () {
      return "defaultLocation";
    }))()()())(new Data_Symbol.IsSymbol(function () {
      return "countryAlpha2";
    }))()()())(new Data_Symbol.IsSymbol(function () {
      return "clearingPeriod";
    }))()()())(new Data_Symbol.IsSymbol(function () {
      return "checkBalanceAuthenticationMode";
    }))()()()))(sl);
  };

  var raToRbRLConsSFSToSU = function raToRbRLConsSFSToSU(dictRaToRbRL) {
    return function (dictIsSymbol) {
      return function (dictCons) {
        return function (dictLacks) {
          return function (dictCons1) {
            return new RaToRbRL(function (v) {
              return function (r) {
                var rest = recordAToRecordBRL(dictRaToRbRL)(Type_Row.RLProxy.value)(r);

                var convert = function convert(v1) {
                  if (v1.selected instanceof Default) {
                    return new Data_Maybe.Just(ZRPC_Clients_Mercanti.SetModeDefault.value);
                  }

                  ;

                  if (v1.selected instanceof Fixed) {
                    if (v1.fixed instanceof Data_Maybe.Just) {
                      return new Data_Maybe.Just(new ZRPC_Clients_Mercanti.SetModeFixed({
                        value: v1.fixed.value0
                      }));
                    }

                    ;

                    if (v1.fixed instanceof Data_Maybe.Nothing) {
                      return Data_Maybe.Nothing.value;
                    }

                    ;
                    throw new Error("Failed pattern match at Optimus.Modules.Tmp.Client.Components.Settings.Utils (line 111, column 20 - line 113, column 31): " + [v1.fixed.constructor.name]);
                  }

                  ;
                  throw new Error("Failed pattern match at Optimus.Modules.Tmp.Client.Components.Settings.Utils (line 109, column 43 - line 113, column 31): " + [v1.selected.constructor.name]);
                };

                var um = convert(Record.get(dictIsSymbol)(dictCons)(Data_Symbol.SProxy.value)(r));
                return Record_Builder.build(Record_Builder.insert(dictCons1)(dictLacks)(dictIsSymbol)(Data_Symbol.SProxy.value)(um))(rest);
              };
            });
          };
        };
      };
    };
  };

  var settingSFSToSU = function settingSFSToSU(sfs) {
    return recordAToRecordB(raToRbRecord()(raToRbRLConsSFSToSU(raToRbRLConsSFSToSU(raToRbRLConsSFSToSU(raToRbRLConsSFSToSU(raToRbRLConsSFSToSU(raToRbRLConsSFSToSU(raToRbRLConsSFSToSU(raToRbRLConsSFSToSU(raToRbRLConsSFSToSU(raToRbRLConsSFSToSU(raToRbRLConsSFSToSU(raToRbRLConsSFSToSU(raToRbRLConsSFSToSU(raToRbRLConsSFSToSU(raToRbRLConsSFSToSU(raToRbRLConsSFSToSU(raToRbRLConsSFSToSU(raToRbRLConsSFSToSU(raToRbRLConsSFSToSU(raToRbRLConsSFSToSU(raToRbRLConsSFSToSU(raToRbRLConsSFSToSU(raToRbRLConsSFSToSU(raToRbRLConsSFSToSU(raToRbRLConsSFSToSU(raToRbRLConsSFSToSU(raToRbRLConsSFSToSU(raToRbRLConsSFSToSU(raToRbRLNil)(new Data_Symbol.IsSymbol(function () {
      return "voidAuthenticationMode";
    }))()()())(new Data_Symbol.IsSymbol(function () {
      return "transactionCurrency";
    }))()()())(new Data_Symbol.IsSymbol(function () {
      return "transactionAmountComputationMode";
    }))()()())(new Data_Symbol.IsSymbol(function () {
      return "timeZone";
    }))()()())(new Data_Symbol.IsSymbol(function () {
      return "senderEmail";
    }))()()())(new Data_Symbol.IsSymbol(function () {
      return "scalingMethod";
    }))()()())(new Data_Symbol.IsSymbol(function () {
      return "qrValidityDuration";
    }))()()())(new Data_Symbol.IsSymbol(function () {
      return "qrScanCountLimit";
    }))()()())(new Data_Symbol.IsSymbol(function () {
      return "qrScanCooldown";
    }))()()())(new Data_Symbol.IsSymbol(function () {
      return "posSetUp";
    }))()()())(new Data_Symbol.IsSymbol(function () {
      return "phoneNumberPrefix";
    }))()()())(new Data_Symbol.IsSymbol(function () {
      return "linkTagVerificationMode";
    }))()()())(new Data_Symbol.IsSymbol(function () {
      return "isRedeemSmsReceiptEnabled";
    }))()()())(new Data_Symbol.IsSymbol(function () {
      return "isRedeemEmailReceiptEnabled";
    }))()()())(new Data_Symbol.IsSymbol(function () {
      return "isQuickRedeemEnabled";
    }))()()())(new Data_Symbol.IsSymbol(function () {
      return "isProfileOnRedeemRequired";
    }))()()())(new Data_Symbol.IsSymbol(function () {
      return "isProfileOnEarnRequired";
    }))()()())(new Data_Symbol.IsSymbol(function () {
      return "isPayInCashEnabled";
    }))()()())(new Data_Symbol.IsSymbol(function () {
      return "isLinkCardOnRegistrationRequired";
    }))()()())(new Data_Symbol.IsSymbol(function () {
      return "isIdMethodByUnverifiedTagAllowed";
    }))()()())(new Data_Symbol.IsSymbol(function () {
      return "isEarnSmsReceiptEnabled";
    }))()()())(new Data_Symbol.IsSymbol(function () {
      return "isEarnEmailReceiptEnabled";
    }))()()())(new Data_Symbol.IsSymbol(function () {
      return "isClearedVoidable";
    }))()()())(new Data_Symbol.IsSymbol(function () {
      return "hasCashbackOnRedemptionOverflow";
    }))()()())(new Data_Symbol.IsSymbol(function () {
      return "defaultLocation";
    }))()()())(new Data_Symbol.IsSymbol(function () {
      return "countryAlpha2";
    }))()()())(new Data_Symbol.IsSymbol(function () {
      return "clearingPeriod";
    }))()()())(new Data_Symbol.IsSymbol(function () {
      return "checkBalanceAuthenticationMode";
    }))()()()))(sfs);
  };

  var genericSettingSelection = new Data_Generic_Rep.Generic(function (x) {
    if (x instanceof Default) {
      return new Data_Generic_Rep.Inl(Data_Generic_Rep.NoArguments.value);
    }

    ;

    if (x instanceof Fixed) {
      return new Data_Generic_Rep.Inr(Data_Generic_Rep.NoArguments.value);
    }

    ;
    throw new Error("Failed pattern match at Optimus.Modules.Tmp.Client.Components.Settings.Utils (line 37, column 8 - line 37, column 72): " + [x.constructor.name]);
  }, function (x) {
    if (x instanceof Data_Generic_Rep.Inl) {
      return Default.value;
    }

    ;

    if (x instanceof Data_Generic_Rep.Inr) {
      return Fixed.value;
    }

    ;
    throw new Error("Failed pattern match at Optimus.Modules.Tmp.Client.Components.Settings.Utils (line 37, column 8 - line 37, column 72): " + [x.constructor.name]);
  });
  var settingSelectionEq = new Data_Eq.Eq(function (x) {
    return Data_Generic_Rep_Eq.genericEq(genericSettingSelection)(Data_Generic_Rep_Eq.genericEqSum(Data_Generic_Rep_Eq.genericEqConstructor(Data_Generic_Rep_Eq.genericEqNoArguments))(Data_Generic_Rep_Eq.genericEqConstructor(Data_Generic_Rep_Eq.genericEqNoArguments)))(x);
  });
  var settingSelectionShow = new Data_Show.Show(function (x) {
    return Data_Generic_Rep_Show.genericShow(genericSettingSelection)(Data_Generic_Rep_Show.genericShowSum(Data_Generic_Rep_Show.genericShowConstructor(Data_Generic_Rep_Show.genericShowArgsNoArguments)(new Data_Symbol.IsSymbol(function () {
      return "Default";
    })))(Data_Generic_Rep_Show.genericShowConstructor(Data_Generic_Rep_Show.genericShowArgsNoArguments)(new Data_Symbol.IsSymbol(function () {
      return "Fixed";
    }))))(x);
  });
  exports["RaToRbRL"] = RaToRbRL;
  exports["RaToRb"] = RaToRb;
  exports["recordAToRecordB"] = recordAToRecordB;
  exports["recordAToRecordBRL"] = recordAToRecordBRL;
  exports["settingSLToSFS"] = settingSLToSFS;
  exports["settingSFSToSU"] = settingSFSToSU;
  exports["Default"] = Default;
  exports["Fixed"] = Fixed;
  exports["genericSettingSelection"] = genericSettingSelection;
  exports["settingSelectionShow"] = settingSelectionShow;
  exports["settingSelectionEq"] = settingSelectionEq;
  exports["raToRbRecord"] = raToRbRecord;
  exports["raToRbRLNil"] = raToRbRLNil;
  exports["raToRbRLConsSFSToSU"] = raToRbRLConsSFSToSU;
})(PS["Optimus.Modules.Tmp.Client.Components.Settings.Utils"] = PS["Optimus.Modules.Tmp.Client.Components.Settings.Utils"] || {});

(function (exports) {
  // Generated by purs version 0.12.3
  "use strict";

  var Control_Plus = PS["Control.Plus"];
  var Data_Bounded = PS["Data.Bounded"];
  var Data_Maybe = PS["Data.Maybe"];
  var Data_Monoid = PS["Data.Monoid"];
  var Data_Symbol = PS["Data.Symbol"];
  var Optimus_Modules_Tmp_Client_Components_Settings_Utils = PS["Optimus.Modules.Tmp.Client.Components.Settings.Utils"];
  var Prelude = PS["Prelude"];
  var Record_Builder = PS["Record.Builder"];
  var Type_Data_Row = PS["Type.Data.Row"];
  var Type_Data_RowList = PS["Type.Data.RowList"];
  var ZRPC_Clients_Mercanti = PS["ZRPC.Clients.Mercanti"];
  var ZRPC_Types_Millis = PS["ZRPC.Types.Millis"];

  var Default = function Default($$default) {
    this["default"] = $$default;
  };

  var Defaults = function Defaults(defaults) {
    this.defaults = defaults;
  };

  var DefaultsRL = function DefaultsRL(defaultsRL) {
    this.defaultsRL = defaultsRL;
  };

  var defaultsRLNil = new DefaultsRL(function (v) {
    return Data_Monoid.mempty(Data_Monoid.monoidRecord()(Data_Monoid.monoidRecordNil));
  });

  var defaultsRL = function defaultsRL(dict) {
    return dict.defaultsRL;
  };

  var defaultsRecord = function defaultsRecord(dictRowToList) {
    return function (dictDefaultsRL) {
      return new Defaults(function (v) {
        return defaultsRL(dictDefaultsRL)(Type_Data_RowList.RLProxy.value);
      });
    };
  };

  var defaults = function defaults(dict) {
    return dict.defaults;
  };

  var defaultTransactionAmountComputationMode = new Default(Data_Bounded.top(ZRPC_Clients_Mercanti.boundedTransactionAmountComputationMode));
  var defaultString = new Default(Data_Monoid.mempty(Data_Monoid.monoidString));
  var defaultRoundingMode = new Default(Data_Bounded.top(ZRPC_Clients_Mercanti.boundedRoundingMode));

  var defaultRecord = function defaultRecord(dictDefaults) {
    return new Default(defaults(dictDefaults)(Type_Data_Row.RProxy.value));
  };

  var defaultMillis = new Default(Data_Monoid.mempty(ZRPC_Types_Millis.monoidMillis));
  var defaultMaybe = new Default(Control_Plus.empty(Data_Maybe.plusMaybe));
  var defaultLinkTagVM = new Default(Data_Bounded.top(ZRPC_Clients_Mercanti.boundedLinkTagVerificationMode));
  var defaultInt = new Default(0);
  var defaultBoolean = new Default(false);
  var defaultAuthMode = new Default(Data_Bounded.top(ZRPC_Clients_Mercanti.boundedAuthorizationMode));

  var $$default = function $$default(dict) {
    return dict["default"];
  };

  var defaultSFS = function defaultSFS(dictDefault) {
    return new Default({
      selected: Optimus_Modules_Tmp_Client_Components_Settings_Utils.Default.value,
      "default": {
        value: $$default(dictDefault),
        provider: ZRPC_Clients_Mercanti.ProviderZap.value
      },
      fixed: Data_Maybe.Nothing.value
    });
  };

  var defaultsRLCons = function defaultsRLCons(dictIsSymbol) {
    return function (dictDefault) {
      return function (dictDefaultsRL) {
        return function (dictLacks) {
          return function (dictCons) {
            return new DefaultsRL(function (v) {
              var value = $$default(dictDefault);
              var rest = defaultsRL(dictDefaultsRL)(Type_Data_RowList.RLProxy.value);
              return Record_Builder.build(Record_Builder.insert(dictCons)(dictLacks)(dictIsSymbol)(Data_Symbol.SProxy.value)(value))(rest);
            });
          };
        };
      };
    };
  };

  exports["Default"] = Default;
  exports["default"] = $$default;
  exports["Defaults"] = Defaults;
  exports["defaults"] = defaults;
  exports["DefaultsRL"] = DefaultsRL;
  exports["defaultsRL"] = defaultsRL;
  exports["defaultString"] = defaultString;
  exports["defaultBoolean"] = defaultBoolean;
  exports["defaultInt"] = defaultInt;
  exports["defaultSFS"] = defaultSFS;
  exports["defaultMaybe"] = defaultMaybe;
  exports["defaultRoundingMode"] = defaultRoundingMode;
  exports["defaultRecord"] = defaultRecord;
  exports["defaultMillis"] = defaultMillis;
  exports["defaultLinkTagVM"] = defaultLinkTagVM;
  exports["defaultAuthMode"] = defaultAuthMode;
  exports["defaultTransactionAmountComputationMode"] = defaultTransactionAmountComputationMode;
  exports["defaultsRecord"] = defaultsRecord;
  exports["defaultsRLNil"] = defaultsRLNil;
  exports["defaultsRLCons"] = defaultsRLCons;
})(PS["Optimus.Common.Utils.Default"] = PS["Optimus.Common.Utils.Default"] || {});

(function (exports) {
  // Generated by purs version 0.12.3
  "use strict";

  var Data_Semigroup = PS["Data.Semigroup"];
  var Data_Symbol = PS["Data.Symbol"];
  var Effect_Aff = PS["Effect.Aff"];
  var Optimus_Common_Bridge = PS["Optimus.Common.Bridge"];
  var Optimus_Common_Client_ApiClient_Utils = PS["Optimus.Common.Client.ApiClient.Utils"];
  var Optimus_Modules_CustomerSupport_Shared_Users = PS["Optimus.Modules.CustomerSupport.Shared.Users"];
  var Prelude = PS["Prelude"];
  var ZRPC_Clients_Zapper = PS["ZRPC.Clients.Zapper"];
  var ZRPC_ZRPCRead = PS["ZRPC.ZRPCRead"];
  var ZRPC_ZRPCWrite = PS["ZRPC.ZRPCWrite"];

  var postChangeEmail = function postChangeEmail(id) {
    return Optimus_Common_Client_ApiClient_Utils.postAuthorizedApi(ZRPC_ZRPCWrite.zrpcWriteRec()(ZRPC_ZRPCWrite.zrpcWriteFieldsCons(new Data_Symbol.IsSymbol(function () {
      return "newEmail";
    }))(ZRPC_ZRPCWrite.zrpcWriteFieldId(ZRPC_ZRPCWrite.zrpcWriteString))(ZRPC_ZRPCWrite.zrpcWriteFieldsNil)()))(ZRPC_ZRPCRead.zrpcReadEither(ZRPC_ZRPCRead.zrpcReadFieldId(Optimus_Common_Bridge.zrpcReadResErr))(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_ZRPCRead.zrpcReadEither(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_Clients_Zapper.zrpcReadSetEmailError))(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_ZRPCRead.readRecord()(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
      return "email";
    }))(ZRPC_ZRPCRead.zrpcReadFieldMaybe(ZRPC_Clients_Zapper.zrpcReadEmail))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
      return "zapperId";
    }))(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_ZRPCRead.zrpcReadString))(ZRPC_ZRPCRead.zrpcReadFieldsNil)()())()()))))))()("/users/" + (id + "/change-email"))({});
  };

  var getUserTags = function getUserTags(id) {
    return Optimus_Common_Client_ApiClient_Utils.getAuthorizedApi(ZRPC_ZRPCRead.zrpcReadArray(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_ZRPCRead.readRecord()(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
      return "barcode";
    }))(ZRPC_ZRPCRead.zrpcReadFieldMaybe(ZRPC_ZRPCRead.zrpcReadString))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
      return "code";
    }))(ZRPC_ZRPCRead.zrpcReadFieldMaybe(ZRPC_ZRPCRead.zrpcReadString))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
      return "holding";
    }))(ZRPC_ZRPCRead.zrpcReadFieldMaybe(ZRPC_ZRPCRead.readRecord()(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
      return "id";
    }))(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_ZRPCRead.zrpcReadInt))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
      return "name";
    }))(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_ZRPCRead.zrpcReadString))(ZRPC_ZRPCRead.zrpcReadFieldsNil)()())()())))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
      return "id";
    }))(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_ZRPCRead.zrpcReadString))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
      return "label";
    }))(ZRPC_ZRPCRead.zrpcReadFieldMaybe(ZRPC_ZRPCRead.zrpcReadString))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
      return "linkedAt";
    }))(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_ZRPCRead.zrpcReadDateTime))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
      return "magstripe";
    }))(ZRPC_ZRPCRead.zrpcReadFieldMaybe(ZRPC_ZRPCRead.zrpcReadString))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
      return "uuid";
    }))(ZRPC_ZRPCRead.zrpcReadFieldMaybe(ZRPC_ZRPCRead.zrpcReadString))(ZRPC_ZRPCRead.zrpcReadFieldsNil)()())()())()())()())()())()())()())()()))))()("/users/" + (id + "/tags"))({});
  };

  var getUserSearch = function getUserSearch(query) {
    return Optimus_Common_Client_ApiClient_Utils.getAuthorizedApi(ZRPC_ZRPCRead.zrpcReadArray(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_ZRPCRead.readRecord()(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
      return "active";
    }))(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_ZRPCRead.zrpcReadBoolean))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
      return "dateRegistered";
    }))(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_ZRPCRead.zrpcReadDateTime))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
      return "email";
    }))(ZRPC_ZRPCRead.zrpcReadFieldMaybe(ZRPC_ZRPCRead.zrpcReadString))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
      return "firstName";
    }))(ZRPC_ZRPCRead.zrpcReadFieldMaybe(ZRPC_ZRPCRead.zrpcReadString))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
      return "lastName";
    }))(ZRPC_ZRPCRead.zrpcReadFieldMaybe(ZRPC_ZRPCRead.zrpcReadString))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
      return "mobile";
    }))(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_ZRPCRead.zrpcReadString))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
      return "tags";
    }))(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_ZRPCRead.zrpcReadArray(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_ZRPCRead.zrpcReadString))))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
      return "zapperId";
    }))(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_ZRPCRead.zrpcReadString))(ZRPC_ZRPCRead.zrpcReadFieldsNil)()())()())()())()())()())()())()())()()))))()("/users")({
      query: query
    });
  };

  var getUserById = function getUserById(id) {
    return Optimus_Common_Client_ApiClient_Utils.getAuthorizedApi(ZRPC_ZRPCRead.readRecord()(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
      return "avatar";
    }))(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_ZRPCRead.zrpcReadString))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
      return "birthday";
    }))(ZRPC_ZRPCRead.zrpcReadFieldMaybe(ZRPC_ZRPCRead.zrpcReadDate))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
      return "currencyBalances";
    }))(ZRPC_ZRPCRead.zrpcReadFieldMaybe(ZRPC_ZRPCRead.zrpcReadArray(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_ZRPCRead.readRecord()(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
      return "id";
    }))(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_ZRPCRead.zrpcReadInt))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
      return "name";
    }))(ZRPC_ZRPCRead.zrpcReadFieldMaybe(ZRPC_ZRPCRead.zrpcReadString))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
      return "pendingEarn";
    }))(ZRPC_ZRPCRead.zrpcReadFieldMaybe(ZRPC_ZRPCRead.zrpcReadHugeNum))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
      return "redeemable";
    }))(ZRPC_ZRPCRead.zrpcReadFieldMaybe(ZRPC_ZRPCRead.zrpcReadHugeNum))(ZRPC_ZRPCRead.zrpcReadFieldsNil)()())()())()())()())))))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
      return "email";
    }))(ZRPC_ZRPCRead.zrpcReadFieldMaybe(ZRPC_ZRPCRead.readRecord()(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
      return "isVerified";
    }))(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_ZRPCRead.zrpcReadBoolean))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
      return "value";
    }))(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_ZRPCRead.zrpcReadString))(ZRPC_ZRPCRead.zrpcReadFieldsNil)()())()())))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
      return "firstName";
    }))(ZRPC_ZRPCRead.zrpcReadFieldMaybe(ZRPC_ZRPCRead.zrpcReadString))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
      return "gender";
    }))(ZRPC_ZRPCRead.zrpcReadFieldMaybe(ZRPC_Clients_Zapper.zrpcReadGender))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
      return "lastName";
    }))(ZRPC_ZRPCRead.zrpcReadFieldMaybe(ZRPC_ZRPCRead.zrpcReadString))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
      return "location";
    }))(ZRPC_ZRPCRead.zrpcReadFieldMaybe(ZRPC_ZRPCRead.zrpcReadString))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
      return "mobile";
    }))(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_ZRPCRead.zrpcReadString))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
      return "tags";
    }))(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_ZRPCRead.zrpcReadArray(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_ZRPCRead.readRecord()(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
      return "barcode";
    }))(ZRPC_ZRPCRead.zrpcReadFieldMaybe(ZRPC_ZRPCRead.zrpcReadString))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
      return "code";
    }))(ZRPC_ZRPCRead.zrpcReadFieldMaybe(ZRPC_ZRPCRead.zrpcReadString))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
      return "holding";
    }))(ZRPC_ZRPCRead.zrpcReadFieldMaybe(ZRPC_ZRPCRead.readRecord()(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
      return "id";
    }))(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_ZRPCRead.zrpcReadInt))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
      return "name";
    }))(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_ZRPCRead.zrpcReadString))(ZRPC_ZRPCRead.zrpcReadFieldsNil)()())()())))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
      return "id";
    }))(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_ZRPCRead.zrpcReadString))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
      return "label";
    }))(ZRPC_ZRPCRead.zrpcReadFieldMaybe(ZRPC_ZRPCRead.zrpcReadString))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
      return "linkedAt";
    }))(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_ZRPCRead.zrpcReadDateTime))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
      return "magstripe";
    }))(ZRPC_ZRPCRead.zrpcReadFieldMaybe(ZRPC_ZRPCRead.zrpcReadString))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
      return "uuid";
    }))(ZRPC_ZRPCRead.zrpcReadFieldMaybe(ZRPC_ZRPCRead.zrpcReadString))(ZRPC_ZRPCRead.zrpcReadFieldsNil)()())()())()())()())()())()())()())()())))))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
      return "userKey";
    }))(ZRPC_ZRPCRead.zrpcReadFieldMaybe(ZRPC_ZRPCRead.zrpcReadString))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
      return "zapperId";
    }))(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_ZRPCRead.zrpcReadString))(ZRPC_ZRPCRead.zrpcReadFieldsNil)()())()())()())()())()())()())()())()())()())()())()())()()))()("/users/" + id)({});
  };

  var getChangeEmail = function getChangeEmail(id) {
    return Optimus_Common_Client_ApiClient_Utils.getAuthorizedApi(ZRPC_ZRPCRead.readRecord()(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
      return "email";
    }))(ZRPC_ZRPCRead.zrpcReadFieldMaybe(ZRPC_Clients_Zapper.zrpcReadEmail))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
      return "zapperId";
    }))(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_ZRPCRead.zrpcReadString))(ZRPC_ZRPCRead.zrpcReadFieldsNil)()())()()))()("/users/" + (id + "/change-email"))({});
  };

  exports["getUserById"] = getUserById;
  exports["getUserSearch"] = getUserSearch;
  exports["getChangeEmail"] = getChangeEmail;
  exports["postChangeEmail"] = postChangeEmail;
  exports["getUserTags"] = getUserTags;
})(PS["Optimus.Modules.CustomerSupport.Client.ApiClient.Users"] = PS["Optimus.Modules.CustomerSupport.Client.ApiClient.Users"] || {});

(function (exports) {
  // Generated by purs version 0.12.3
  "use strict";

  var Control_Bind = PS["Control.Bind"];
  var Data_Array = PS["Data.Array"];
  var Data_Either = PS["Data.Either"];
  var Data_Function = PS["Data.Function"];
  var Data_Maybe = PS["Data.Maybe"];
  var Data_Monoid = PS["Data.Monoid"];
  var Data_Symbol = PS["Data.Symbol"];
  var Effect = PS["Effect"];
  var Effect_Aff = PS["Effect.Aff"];
  var Optimus_Common_Client_Antd = PS["Optimus.Common.Client.Antd"];
  var Optimus_Common_Client_Antd_Alert = PS["Optimus.Common.Client.Antd.Alert"];
  var Optimus_Common_Client_Antd_Button = PS["Optimus.Common.Client.Antd.Button"];
  var Optimus_Common_Client_Antd_Card = PS["Optimus.Common.Client.Antd.Card"];
  var Optimus_Common_Client_Antd_Grid = PS["Optimus.Common.Client.Antd.Grid"];
  var Optimus_Common_Client_Antd_Icon = PS["Optimus.Common.Client.Antd.Icon"];
  var Optimus_Common_Client_Antd_Input = PS["Optimus.Common.Client.Antd.Input"];
  var Optimus_Common_Client_Antd_Notification = PS["Optimus.Common.Client.Antd.Notification"];
  var Optimus_Common_Client_Antd_Typography = PS["Optimus.Common.Client.Antd.Typography"];
  var Optimus_Common_Utils_Default = PS["Optimus.Common.Utils.Default"];
  var Optimus_Modules_CustomerSupport_Client_ApiClient_Users = PS["Optimus.Modules.CustomerSupport.Client.ApiClient.Users"];
  var Optimus_Modules_CustomerSupport_Shared_Users = PS["Optimus.Modules.CustomerSupport.Shared.Users"];
  var Prelude = PS["Prelude"];
  var React_Basic = PS["React.Basic"];
  var React_Basic_DOM = PS["React.Basic.DOM"];
  var React_Basic_DOM_Events = PS["React.Basic.DOM.Events"];
  var React_Basic_DOM_Generated = PS["React.Basic.DOM.Generated"];
  var React_Basic_Events = PS["React.Basic.Events"];
  var Record = PS["Record"];
  var ZRPC_Clients_Zapper = PS["ZRPC.Clients.Zapper"];

  var OtherErr = function () {
    function OtherErr() {}

    ;
    OtherErr.value = new OtherErr();
    return OtherErr;
  }();

  var Z = function () {
    function Z(value0) {
      this.value0 = value0;
    }

    ;

    Z.create = function (value0) {
      return new Z(value0);
    };

    return Z;
  }();

  var FSInit = function () {
    function FSInit() {}

    ;
    FSInit.value = new FSInit();
    return FSInit;
  }();

  var FSLoading = function () {
    function FSLoading() {}

    ;
    FSLoading.value = new FSLoading();
    return FSLoading;
  }();

  var FSSuccess = function () {
    function FSSuccess() {}

    ;
    FSSuccess.value = new FSSuccess();
    return FSSuccess;
  }();

  var FSFail = function () {
    function FSFail(value0) {
      this.value0 = value0;
    }

    ;

    FSFail.create = function (value0) {
      return new FSFail(value0);
    };

    return FSFail;
  }();

  var DSNone = function () {
    function DSNone() {}

    ;
    DSNone.value = new DSNone();
    return DSNone;
  }();

  var DSLoading = function () {
    function DSLoading() {}

    ;
    DSLoading.value = new DSLoading();
    return DSLoading;
  }();

  var DSSuccess = function () {
    function DSSuccess(value0) {
      this.value0 = value0;
    }

    ;

    DSSuccess.create = function (value0) {
      return new DSSuccess(value0);
    };

    return DSSuccess;
  }();

  var component = React_Basic.createComponent("ChangeUserEmail");

  var changeEmailPage = function () {
    var onSubmit = function onSubmit(self) {
      var handleRes = function handleRes(v) {
        if (v instanceof Data_Either.Right && v.value0 instanceof Data_Either.Right) {
          if (v.value0.value0 instanceof Data_Either.Left) {
            return self.setState(function (v1) {
              return {
                formState: FSFail.create(new Z(v.value0.value0.value0)),
                newEmail: v1.newEmail,
                dataState: v1.dataState
              };
            });
          }

          ;

          if (v.value0.value0 instanceof Data_Either.Right) {
            return self.setState(function (v1) {
              return {
                formState: FSSuccess.value,
                newEmail: "",
                dataState: new DSSuccess(v.value0.value0.value0)
              };
            });
          }

          ;
          throw new Error("Failed pattern match at Optimus.Modules.CustomerSupport.Client.Pages.ChangeEmail (line 76, column 39 - line 78, column 111): " + [v.value0.value0.constructor.name]);
        }

        ;
        return self.setState(function (v1) {
          return {
            formState: new FSFail(OtherErr.value),
            newEmail: v1.newEmail,
            dataState: v1.dataState
          };
        });
      };

      return React_Basic_DOM_Events.capture_(function __do() {
        self.setState(function (v) {
          return {
            formState: FSLoading.value,
            newEmail: v.newEmail,
            dataState: v.dataState
          };
        })();
        return Effect_Aff.runAff_(handleRes)(Optimus_Modules_CustomerSupport_Client_ApiClient_Users.postChangeEmail(self.props.zapperId)({
          newEmail: self.state.newEmail
        }))();
      });
    };

    var onFetchEmail = function onFetchEmail(self) {
      return function __do() {
        self.setState(function (v) {
          var $19 = {};

          for (var $20 in v) {
            if ({}.hasOwnProperty.call(v, $20)) {
              $19[$20] = v[$20];
            }

            ;
          }

          ;
          $19.dataState = DSLoading.value;
          return $19;
        })();
        return Effect_Aff.runAff_(function (v) {
          if (v instanceof Data_Either.Right) {
            return self.setState(function (v1) {
              var $23 = {};

              for (var $24 in v1) {
                if ({}.hasOwnProperty.call(v1, $24)) {
                  $23[$24] = v1[$24];
                }

                ;
              }

              ;
              $23.dataState = new DSSuccess(v.value0);
              return $23;
            });
          }

          ;

          if (v instanceof Data_Either.Left) {
            return function __do() {
              Optimus_Common_Client_Antd_Notification.notification.error()({
                message: React_Basic_DOM.text("Zapper Email"),
                description: React_Basic_DOM.text("Failed to load current email")
              })();
              return self.setState(function (v1) {
                var $27 = {};

                for (var $28 in v1) {
                  if ({}.hasOwnProperty.call(v1, $28)) {
                    $27[$28] = v1[$28];
                  }

                  ;
                }

                ;
                $27.dataState = DSNone.value;
                return $27;
              })();
            };
          }

          ;
          throw new Error("Failed pattern match at Optimus.Modules.CustomerSupport.Client.Pages.ChangeEmail (line 52, column 16 - line 57, column 51): " + [v.constructor.name]);
        })(Optimus_Modules_CustomerSupport_Client_ApiClient_Users.getChangeEmail(self.props.zapperId))();
      };
    };

    var onEmailChange = function onEmailChange(self) {
      return React_Basic_Events.handler(React_Basic_Events.merge()(React_Basic_Events.mergeCons(new Data_Symbol.IsSymbol(function () {
        return "targetValue";
      }))()()()()(React_Basic_Events.mergeNil))({
        targetValue: React_Basic_DOM_Events.targetValue
      }))(function (v) {
        return self.setState(function (v1) {
          return {
            newEmail: Data_Maybe.fromMaybe(Optimus_Common_Utils_Default["default"](Optimus_Common_Utils_Default.defaultString))(v.targetValue),
            dataState: v1.dataState,
            formState: v1.formState
          };
        });
      });
    };

    var render = function render(self) {
      var pair = function pair(key) {
        return function (value) {
          return Optimus_Common_Client_Antd_Grid.row()({
            type: "flex",
            className: "justify-center items-center mb-2",
            children: [Optimus_Common_Client_Antd_Grid.col()({
              xs: 24,
              sm: 6,
              className: "ant-form-item-label",
              children: [React_Basic_DOM_Generated.label_([Optimus_Common_Client_Antd_Typography.text()({
                strong: true,
                children: [React_Basic_DOM.text(key)]
              })])]
            }), Optimus_Common_Client_Antd_Grid.col()({
              xs: 24,
              sm: 18,
              children: [value]
            })]
          });
        };
      };

      var getEmail = function getEmail(v) {
        if (v instanceof DSSuccess) {
          return Data_Maybe.fromMaybe(Optimus_Common_Utils_Default["default"](Optimus_Common_Utils_Default.defaultString))(Control_Bind.bind(Data_Maybe.bindMaybe)(v.value0.email)(function (v1) {
            if (v1 instanceof ZRPC_Clients_Zapper.EmailConfirmed) {
              return v1.value0.value;
            }

            ;

            if (v1 instanceof ZRPC_Clients_Zapper.EmailUnconfirmed) {
              return v1.value0.value;
            }

            ;
            throw new Error("Failed pattern match at Optimus.Modules.CustomerSupport.Client.Pages.ChangeEmail (line 141, column 17 - line 143, column 63): " + [v1.constructor.name]);
          }));
        }

        ;
        return "-";
      };

      var alertDisplay = function alertDisplay(formS) {
        var commonProps = {
          showIcon: true,
          className: "mb-2"
        };

        if (formS instanceof FSLoading) {
          return Data_Monoid.mempty(React_Basic.monoidJSX);
        }

        ;

        if (formS instanceof FSInit) {
          return Data_Monoid.mempty(React_Basic.monoidJSX);
        }

        ;

        if (formS instanceof FSSuccess) {
          return Optimus_Common_Client_Antd_Alert.alert()(Record.merge()()(commonProps)({
            type: "success",
            message: React_Basic_DOM.text("Change Email Success")
          }));
        }

        ;

        if (formS instanceof FSFail) {
          return Optimus_Common_Client_Antd_Alert.alert()(Record.merge()()(commonProps)({
            type: "error",
            description: React_Basic_DOM.text(function () {
              if (formS.value0 instanceof Z && formS.value0.value0 instanceof ZRPC_Clients_Zapper.SetEmailErrorExistingEmail) {
                return "Email provided already taken.";
              }

              ;

              if (formS.value0 instanceof Z && formS.value0.value0 instanceof ZRPC_Clients_Zapper.SetEmailErrorInvalidEmail) {
                return "Email provided is invalid.";
              }

              ;

              if (formS.value0 instanceof Z && formS.value0.value0 instanceof ZRPC_Clients_Zapper.SetEmailErrorUnableToSetEmail) {
                return "Unable to set new email.";
              }

              ;

              if (formS.value0 instanceof OtherErr) {
                return "Unknown Error has occured.";
              }

              ;
              throw new Error("Failed pattern match at Optimus.Modules.CustomerSupport.Client.Pages.ChangeEmail (line 162, column 43 - line 167, column 19): " + [formS.value0.constructor.name]);
            }()),
            message: React_Basic_DOM.text("Change Email Failure")
          }));
        }

        ;
        throw new Error("Failed pattern match at Optimus.Modules.CustomerSupport.Client.Pages.ChangeEmail (line 149, column 13 - line 168, column 20): " + [formS.constructor.name]);
      };

      return Optimus_Common_Client_Antd_Grid.row()({
        type: "flex",
        justify: "center",
        children: [Optimus_Common_Client_Antd_Card.card()({
          title: "Change Email",
          className: "w-100 mt-3 mw-md",
          children: Data_Array.singleton(function () {
            if (self.state.dataState instanceof DSLoading) {
              return React_Basic_DOM_Generated.div()({
                className: "flex justify-center",
                children: Data_Array.singleton(Optimus_Common_Client_Antd_Icon.icon()({
                  type: "loading",
                  className: "fs-2"
                }))
              });
            }

            ;
            return React_Basic_DOM_Generated.form()({
              onSubmit: onSubmit(self),
              children: [alertDisplay(self.state.formState), pair("Current Email")(Optimus_Common_Client_Antd_Typography.text()({
                className: "pl-1",
                children: [React_Basic_DOM.text(getEmail(self.state.dataState))]
              })), pair("New Email")(Optimus_Common_Client_Antd_Input.input()({
                value: self.state.newEmail,
                onChange: onEmailChange(self)
              })), Optimus_Common_Client_Antd_Grid.row()({
                className: "justify-end mt-4",
                type: "flex",
                children: Data_Array.singleton(Optimus_Common_Client_Antd_Button.button()({
                  htmlType: "submit",
                  type: "primary",
                  loading: function () {
                    if (self.state.formState instanceof FSLoading) {
                      return true;
                    }

                    ;
                    return false;
                  }(),
                  children: [React_Basic_DOM.text("Submit")]
                }))
              })]
            });
          }())
        })]
      });
    };

    var initialState = {
      newEmail: "",
      formState: FSInit.value,
      dataState: DSNone.value
    };

    var didMount = function didMount(self) {
      return onFetchEmail(self);
    };

    return React_Basic.make()(component)({
      initialState: initialState,
      render: render,
      didMount: didMount
    });
  }();

  exports["changeEmailPage"] = changeEmailPage;
})(PS["Optimus.Modules.CustomerSupport.Client.Pages.ChangeEmail"] = PS["Optimus.Modules.CustomerSupport.Client.Pages.ChangeEmail"] || {});

(function (exports) {
  // Generated by purs version 0.12.3
  "use strict";

  var Control_Applicative = PS["Control.Applicative"];
  var Control_Semigroupoid = PS["Control.Semigroupoid"];
  var Data_DateTime = PS["Data.DateTime"];
  var Data_Function = PS["Data.Function"];
  var Data_Functor = PS["Data.Functor"];
  var Data_Maybe = PS["Data.Maybe"];
  var Data_Unit = PS["Data.Unit"];
  var Effect = PS["Effect"];
  var Optimus_Client_Component_Content = PS["Optimus.Client.Component.Content"];
  var Optimus_Common_Client_Antd = PS["Optimus.Common.Client.Antd"];
  var Optimus_Common_Client_Antd_Button = PS["Optimus.Common.Client.Antd.Button"];
  var Optimus_Common_Client_Antd_Grid = PS["Optimus.Common.Client.Antd.Grid"];
  var Optimus_Common_Client_Antd_Icon = PS["Optimus.Common.Client.Antd.Icon"];
  var Optimus_Common_Client_Components_Card = PS["Optimus.Common.Client.Components.Card"];
  var Optimus_Common_Client_Components_InfoPanel = PS["Optimus.Common.Client.Components.InfoPanel"];
  var Optimus_Common_Client_Components_Section = PS["Optimus.Common.Client.Components.Section"];
  var Optimus_Common_Client_Components_Table = PS["Optimus.Common.Client.Components.Table"];
  var Optimus_Common_Client_InfoShow = PS["Optimus.Common.Client.InfoShow"];
  var Optimus_Common_Client_Pages_AsyncPage = PS["Optimus.Common.Client.Pages.AsyncPage"];
  var Optimus_Modules_CustomerSupport_Client_Route = PS["Optimus.Modules.CustomerSupport.Client.Route"];
  var Optimus_Modules_CustomerSupport_Shared_Users = PS["Optimus.Modules.CustomerSupport.Shared.Users"];
  var Prelude = PS["Prelude"];
  var React_Basic = PS["React.Basic"];
  var React_Basic_DOM = PS["React.Basic.DOM"];
  var React_Basic_DOM_Events = PS["React.Basic.DOM.Events"];
  var React_Basic_DOM_Generated = PS["React.Basic.DOM.Generated"];
  var React_Basic_Events = PS["React.Basic.Events"];

  var render = function render(v) {
    var tags = Optimus_Common_Client_Components_Card.card([Optimus_Common_Client_Components_Section.section(React_Basic.fragment([Optimus_Common_Client_Components_InfoPanel.renderText(Optimus_Common_Client_InfoShow.infoShowString)("Tags"), React_Basic_DOM_Generated.a()({
      className: "fs-75 ml-2 underline",
      onClick: React_Basic_Events.handler_(v.history.push(Optimus_Modules_CustomerSupport_Client_Route.MRoute.create(Optimus_Modules_CustomerSupport_Client_Route.Users.create(new Optimus_Modules_CustomerSupport_Client_Route.UsersTags(v.pgData.zapperId))))),
      children: [React_Basic_DOM.text("View All")]
    })]))(Optimus_Common_Client_Components_Table.table({
      onRowClick: function onRowClick(v1) {
        return Control_Applicative.pure(Effect.applicativeEffect)(Data_Unit.unit);
      },
      loading: false
    })([Optimus_Common_Client_Components_Table.scspec(Optimus_Common_Client_InfoShow.infoShowDate)("Date Linked")(function ($13) {
      return Data_DateTime.date(function (v1) {
        return v1.linkedAt;
      }($13));
    }), Optimus_Common_Client_Components_Table.scspec(Optimus_Common_Client_InfoShow.infoShowMaybe(Optimus_Common_Client_InfoShow.infoShowString))("ZAP Code")(function (v1) {
      return v1.code;
    }), Optimus_Common_Client_Components_Table.scspec(Optimus_Common_Client_InfoShow.infoShowMaybe(Optimus_Common_Client_InfoShow.infoShowString))("Holding")(function (u) {
      return Data_Functor.mapFlipped(Data_Maybe.functorMaybe)(u.holding)(function (v1) {
        return v1.name;
      });
    }), Optimus_Common_Client_Components_Table.scspec(Optimus_Common_Client_InfoShow.infoShowMaybe(Optimus_Common_Client_InfoShow.infoShowString))("Label")(function (v1) {
      return v1.label;
    })])(v.pgData.tags))]);
    var suspendButton = Optimus_Common_Client_Antd_Button.button()({
      block: true,
      children: [React_Basic_DOM.text("Suspend")]
    });
    var profile = Optimus_Common_Client_Components_Card.card([Optimus_Common_Client_Components_Section.section(Optimus_Common_Client_Components_InfoPanel.renderText(Optimus_Common_Client_InfoShow.infoShowString)("Profile"))(Optimus_Common_Client_Components_InfoPanel.infoPanel([Optimus_Common_Client_Components_InfoPanel.ip("Mobile")(Optimus_Common_Client_Components_InfoPanel.renderText(Optimus_Common_Client_InfoShow.infoShowString)(v.pgData.mobile)), Optimus_Common_Client_Components_InfoPanel.ip("Email")(React_Basic_DOM_Generated.div_([Optimus_Common_Client_Components_InfoPanel.renderText(Optimus_Common_Client_InfoShow.infoShowMaybe(Optimus_Common_Client_InfoShow.infoShowString))(Data_Functor.mapFlipped(Data_Maybe.functorMaybe)(v.pgData.email)(function (v1) {
      return v1.value;
    })), React_Basic_DOM_Generated.a()({
      onClick: React_Basic_DOM_Events.capture_(v.history.push(Optimus_Modules_CustomerSupport_Client_Route.MRoute.create(Optimus_Modules_CustomerSupport_Client_Route.Users.create(new Optimus_Modules_CustomerSupport_Client_Route.UsersChangeEmail(v.pgData.zapperId))))),
      children: [Optimus_Common_Client_Antd_Icon.icon()({
        className: "ml-1",
        type: "edit"
      })]
    })])), Optimus_Common_Client_Components_InfoPanel.ip("Verified")(Optimus_Common_Client_Components_InfoPanel.renderText(Optimus_Common_Client_InfoShow.infoShowMaybe(Optimus_Common_Client_InfoShow.infoShowBoolean))(Data_Functor.mapFlipped(Data_Maybe.functorMaybe)(v.pgData.email)(function (v1) {
      return v1.isVerified;
    }))), Optimus_Common_Client_Components_InfoPanel.ip("Gender")(Optimus_Common_Client_Components_InfoPanel.renderText(Optimus_Common_Client_InfoShow.infoShowMaybe(Optimus_Common_Client_InfoShow.infoShowGender))(v.pgData.gender)), Optimus_Common_Client_Components_InfoPanel.ip("Birthday")(Optimus_Common_Client_Components_InfoPanel.renderText(Optimus_Common_Client_InfoShow.infoShowMaybe(Optimus_Common_Client_InfoShow.infoShowDate))(v.pgData.birthday)), Optimus_Common_Client_Components_InfoPanel.ip("Location")(Optimus_Common_Client_Components_InfoPanel.renderText(Optimus_Common_Client_InfoShow.infoShowMaybe(Optimus_Common_Client_InfoShow.infoShowString))(v.pgData.location))]))]);
    var deactivateButton = Optimus_Common_Client_Antd_Button.button()({
      block: true,
      children: [React_Basic_DOM.text("Deactivate")]
    });
    var details = Optimus_Common_Client_Components_Card.card([React_Basic_DOM_Generated.div()({
      className: "center mb-3",
      children: [React_Basic_DOM_Generated.img()({
        src: v.pgData.avatar,
        width: "100px"
      })]
    }), Optimus_Common_Client_Components_InfoPanel.infoPanel([Optimus_Common_Client_Components_InfoPanel.ip("First Name")(Optimus_Common_Client_Components_InfoPanel.renderText(Optimus_Common_Client_InfoShow.infoShowMaybe(Optimus_Common_Client_InfoShow.infoShowString))(v.pgData.firstName)), Optimus_Common_Client_Components_InfoPanel.ip("Last Name")(Optimus_Common_Client_Components_InfoPanel.renderText(Optimus_Common_Client_InfoShow.infoShowMaybe(Optimus_Common_Client_InfoShow.infoShowString))(v.pgData.lastName)), Optimus_Common_Client_Components_InfoPanel.ip("ID")(Optimus_Common_Client_Components_InfoPanel.renderText(Optimus_Common_Client_InfoShow.infoShowString)(v.pgData.zapperId))]), Optimus_Common_Client_Antd_Grid.row()({
      className: "mt-3",
      gutter: 8,
      type: "flex",
      justify: "center",
      children: [Optimus_Common_Client_Antd_Grid.col()({
        className: "mb-3",
        xs: 24,
        sm: 8,
        md: 6,
        lg: 4,
        children: [suspendButton]
      }), Optimus_Common_Client_Antd_Grid.col()({
        xs: 24,
        sm: 8,
        md: 6,
        lg: 4,
        children: [deactivateButton]
      })]
    })]);
    var currencyBalances = Optimus_Common_Client_Components_Card.card([Optimus_Common_Client_Components_Section.section(Optimus_Common_Client_Components_InfoPanel.renderText(Optimus_Common_Client_InfoShow.infoShowString)("Currency Balances"))(Optimus_Common_Client_Components_Table.table({
      onRowClick: function onRowClick(v1) {
        return Control_Applicative.pure(Effect.applicativeEffect)(Data_Unit.unit);
      },
      loading: false
    })([Optimus_Common_Client_Components_Table.scspec(Optimus_Common_Client_InfoShow.infoShowMaybe(Optimus_Common_Client_InfoShow.infoShowString))("Currency Name")(function (v1) {
      return v1.name;
    }), Optimus_Common_Client_Components_Table.scspec(Optimus_Common_Client_InfoShow.infoShowMaybe(Optimus_Common_Client_InfoShow.infoShowHugeNum))("Redeemable")(function (v1) {
      return v1.redeemable;
    }), Optimus_Common_Client_Components_Table.scspec(Optimus_Common_Client_InfoShow.infoShowMaybe(Optimus_Common_Client_InfoShow.infoShowHugeNum))("Pending")(function (v1) {
      return v1.pendingEarn;
    })])(Data_Maybe.fromMaybe([])(v.pgData.currencyBalances)))]);
    return Optimus_Client_Component_Content.content({
      title: "User Overview",
      children: [Optimus_Common_Client_Antd_Grid.row_([details]), Optimus_Common_Client_Antd_Grid.row_([profile]), Optimus_Common_Client_Antd_Grid.row_([tags]), Optimus_Common_Client_Antd_Grid.row_([currencyBalances])]
    });
  };

  var component = React_Basic.createComponent("User");
  var overviewPage = React_Basic.makeStateless(component)(render);
  exports["overviewPage"] = overviewPage;
})(PS["Optimus.Modules.CustomerSupport.Client.Pages.Overview"] = PS["Optimus.Modules.CustomerSupport.Client.Pages.Overview"] || {});

(function (exports) {
  // Generated by purs version 0.12.3
  "use strict";

  var Control_Applicative = PS["Control.Applicative"];
  var Control_Bind = PS["Control.Bind"];
  var Data_Either = PS["Data.Either"];
  var Data_Eq = PS["Data.Eq"];
  var Data_Function = PS["Data.Function"];
  var Data_HeytingAlgebra = PS["Data.HeytingAlgebra"];
  var Data_Maybe = PS["Data.Maybe"];
  var Data_Semigroup = PS["Data.Semigroup"];
  var Data_Unit = PS["Data.Unit"];
  var Effect = PS["Effect"];
  var Effect_Aff = PS["Effect.Aff"];
  var Effect_Uncurried = PS["Effect.Uncurried"];
  var Optimus_Client_Component_Content = PS["Optimus.Client.Component.Content"];
  var Optimus_Common_Client_Antd = PS["Optimus.Common.Client.Antd"];
  var Optimus_Common_Client_Antd_Grid = PS["Optimus.Common.Client.Antd.Grid"];
  var Optimus_Common_Client_Antd_Input = PS["Optimus.Common.Client.Antd.Input"];
  var Optimus_Common_Client_Antd_Message = PS["Optimus.Common.Client.Antd.Message"];
  var Optimus_Common_Client_Clipboard = PS["Optimus.Common.Client.Clipboard"];
  var Optimus_Common_Client_Components_Card = PS["Optimus.Common.Client.Components.Card"];
  var Optimus_Common_Client_Components_Table = PS["Optimus.Common.Client.Components.Table"];
  var Optimus_Common_Client_InfoShow = PS["Optimus.Common.Client.InfoShow"];
  var Optimus_Common_Utils_Default = PS["Optimus.Common.Utils.Default"];
  var Optimus_Modules_CustomerSupport_Client_ApiClient_Users = PS["Optimus.Modules.CustomerSupport.Client.ApiClient.Users"];
  var Optimus_Modules_CustomerSupport_Client_Route = PS["Optimus.Modules.CustomerSupport.Client.Route"];
  var Optimus_Modules_CustomerSupport_Shared_Users = PS["Optimus.Modules.CustomerSupport.Shared.Users"];
  var Prelude = PS["Prelude"];
  var React_Basic = PS["React.Basic"];
  var React_Basic_DOM = PS["React.Basic.DOM"];
  var React_Basic_DOM_Events = PS["React.Basic.DOM.Events"];
  var React_Basic_DOM_Generated = PS["React.Basic.DOM.Generated"];

  var Loading = function () {
    function Loading() {}

    ;
    Loading.value = new Loading();
    return Loading;
  }();

  var Success = function () {
    function Success(value0) {
      this.value0 = value0;
    }

    ;

    Success.create = function (value0) {
      return new Success(value0);
    };

    return Success;
  }();

  var Failed = function () {
    function Failed() {}

    ;
    Failed.value = new Failed();
    return Failed;
  }();

  var Init = function () {
    function Init() {}

    ;
    Init.value = new Init();
    return Init;
  }();

  var component = React_Basic.createComponent("SearchUsersPage");

  var searchPage = function searchPage(props) {
    var onSubmitSearch = function onSubmitSearch(self) {
      return function (query) {
        return self.props.history.push(Optimus_Modules_CustomerSupport_Client_Route.MRoute.create(Optimus_Modules_CustomerSupport_Client_Route.Users.create(new Optimus_Modules_CustomerSupport_Client_Route.UsersSearch({
          query: new Data_Maybe.Just(query)
        }))));
      };
    };

    var render = function render(self) {
      return Optimus_Client_Component_Content.content({
        title: "User Search",
        children: [Optimus_Common_Client_Components_Card.card([Optimus_Common_Client_Antd_Grid.row_([React_Basic.keyed(Data_Maybe.fromMaybe(Optimus_Common_Utils_Default["default"](Optimus_Common_Utils_Default.defaultString))(self.props.query))(Optimus_Common_Client_Antd_Input.inputSearch()()({
          allowClear: true,
          defaultValue: Data_Maybe.fromMaybe(Optimus_Common_Utils_Default["default"](Optimus_Common_Utils_Default.defaultString))(self.props.query),
          enterButton: true,
          onSearch: Effect_Uncurried.mkEffectFn1(onSubmitSearch(self))
        }))]), React_Basic_DOM_Generated.br()({}), Optimus_Common_Client_Antd_Grid.row_([function () {
          if (self.state instanceof Failed) {
            return React_Basic_DOM.text("FAILED");
          }

          ;
          return React_Basic.fragment([Optimus_Common_Client_Components_Table.table({
            onRowClick: function onRowClick(v) {
              return self.props.history.push(Optimus_Modules_CustomerSupport_Client_Route.MRoute.create(Optimus_Modules_CustomerSupport_Client_Route.Users.create(new Optimus_Modules_CustomerSupport_Client_Route.UsersOverview(v.zapperId))));
            },
            loading: function () {
              if (self.state instanceof Loading) {
                return true;
              }

              ;
              return false;
            }()
          })([Optimus_Common_Client_Components_Table.scspec(Optimus_Common_Client_InfoShow.infoShowDateTiem)("Date Registered")(function (v) {
            return v.dateRegistered;
          }), Optimus_Common_Client_Components_Table.scspec(Optimus_Common_Client_InfoShow.infoShowBoolean)("Active")(function (v) {
            return v.active;
          }), Optimus_Common_Client_Components_Table.scspec(Optimus_Common_Client_InfoShow.infoShowString)("Mobile Number")(function (v) {
            return v.mobile;
          }), Optimus_Common_Client_Components_Table.scspec(Optimus_Common_Client_InfoShow.infoShowMaybe(Optimus_Common_Client_InfoShow.infoShowString))("Email")(function (v) {
            return v.email;
          }), Optimus_Common_Client_Components_Table.scspec(Optimus_Common_Client_InfoShow.infoShowMaybe(Optimus_Common_Client_InfoShow.infoShowString))("First Name")(function (v) {
            return v.firstName;
          }), Optimus_Common_Client_Components_Table.scspec(Optimus_Common_Client_InfoShow.infoShowMaybe(Optimus_Common_Client_InfoShow.infoShowString))("Last Name")(function (v) {
            return v.lastName;
          }), Optimus_Common_Client_Components_Table.scspec(Optimus_Common_Client_InfoShow.infoShowArray(Optimus_Common_Client_InfoShow.infoShowString))("Tags")(function (v) {
            return v.tags;
          }), Optimus_Common_Client_Components_Table.cspec("Actions")(function (v) {
            return React_Basic_DOM_Generated.a()({
              onClick: React_Basic_DOM_Events.capture_(function __do() {
                Optimus_Common_Client_Clipboard.copy(v.zapperId)();
                return Optimus_Common_Client_Antd_Message.message.success("Successfully copied Zapper ID: " + v.zapperId)();
              }),
              children: [React_Basic_DOM.text("Copy Zapper ID")]
            });
          })])(function () {
            if (self.state instanceof Success) {
              return self.state.value0;
            }

            ;
            return [];
          }())]);
        }()])])]
      });
    };

    var handleUserSearch = function handleUserSearch(v) {
      if (v.query instanceof Data_Maybe.Just) {
        return function __do() {
          v.setState(function (s) {
            return Loading.value;
          })();
          return Effect_Aff.runAff_(function (v1) {
            if (v1 instanceof Data_Either.Right) {
              return v.setState(function (s) {
                return new Success(v1.value0);
              });
            }

            ;
            return v.setState(function (s) {
              return Failed.value;
            });
          })(Optimus_Modules_CustomerSupport_Client_ApiClient_Users.getUserSearch(v.query.value0))();
        };
      }

      ;
      return Control_Applicative.pure(Effect.applicativeEffect)(Data_Unit.unit);
    };

    var didUpdate = function didUpdate(self) {
      return function (v) {
        var $26 = !Data_Eq.eq(Data_Maybe.eqMaybe(Data_Eq.eqString))(v.prevProps.query)(self.props.query);

        if ($26) {
          return handleUserSearch({
            query: self.props.query,
            setState: self.setState
          });
        }

        ;
        return Control_Applicative.pure(Effect.applicativeEffect)(Data_Unit.unit);
      };
    };

    var didMount = function didMount(self) {
      return handleUserSearch({
        query: self.props.query,
        setState: self.setState
      });
    };

    return React_Basic.make()(component)({
      initialState: Init.value,
      didMount: didMount,
      didUpdate: didUpdate,
      render: render
    })(props);
  };

  exports["searchPage"] = searchPage;
})(PS["Optimus.Modules.CustomerSupport.Client.Pages.Search"] = PS["Optimus.Modules.CustomerSupport.Client.Pages.Search"] || {});

(function (exports) {
  // Generated by purs version 0.12.3
  "use strict";

  var Data_Array = PS["Data.Array"];
  var Data_Function = PS["Data.Function"];
  var Data_Functor = PS["Data.Functor"];
  var Data_Maybe = PS["Data.Maybe"];
  var Data_Monoid = PS["Data.Monoid"];
  var Data_Semigroup = PS["Data.Semigroup"];
  var Optimus_Client_Component_Content = PS["Optimus.Client.Component.Content"];
  var Optimus_Client_Component_Grid = PS["Optimus.Client.Component.Grid"];
  var Optimus_Common_Client_Antd = PS["Optimus.Common.Client.Antd"];
  var Optimus_Common_Client_Antd_Card = PS["Optimus.Common.Client.Antd.Card"];
  var Optimus_Common_Client_Antd_Grid = PS["Optimus.Common.Client.Antd.Grid"];
  var Optimus_Common_Client_Antd_Typography = PS["Optimus.Common.Client.Antd.Typography"];
  var Optimus_Common_Client_Components_InfoPanel = PS["Optimus.Common.Client.Components.InfoPanel"];
  var Optimus_Common_Client_InfoShow = PS["Optimus.Common.Client.InfoShow"];
  var Optimus_Common_Client_Pages_AsyncPage = PS["Optimus.Common.Client.Pages.AsyncPage"];
  var Optimus_Modules_CustomerSupport_Shared_Users = PS["Optimus.Modules.CustomerSupport.Shared.Users"];
  var Prelude = PS["Prelude"];
  var React_Basic = PS["React.Basic"];
  var React_Basic_DOM = PS["React.Basic.DOM"];
  var React_Basic_DOM_Generated = PS["React.Basic.DOM.Generated"];
  var component = React_Basic.createComponent("UserTags");

  var tagsPage = function () {
    var tagInfoPair = function tagInfoPair(key) {
      return function (value) {
        return Optimus_Common_Client_Antd_Grid.row()({
          type: "flex",
          className: "justify-center items-center mb-1",
          children: [Optimus_Common_Client_Antd_Grid.col()({
            xs: 10,
            className: "right-align nowrap line-height-5 pr-2",
            children: Data_Array.singleton(React_Basic_DOM_Generated.label_(Data_Array.singleton(Optimus_Common_Client_Antd_Typography.text()({
              strong: true,
              children: [React_Basic_DOM.text(key + ":")]
            }))))
          }), Optimus_Common_Client_Antd_Grid.col()({
            xs: 14,
            children: Data_Array.singleton(value)
          })]
        });
      };
    };

    var renderCard = function renderCard(v) {
      return Optimus_Common_Client_Antd_Card.card()({
        title: Data_Maybe.fromMaybe("(No Code)")(v.code),
        children: [tagInfoPair("Date Linked")(Optimus_Common_Client_Components_InfoPanel.renderText(Optimus_Common_Client_InfoShow.infoShowDateTiem)(v.linkedAt)), tagInfoPair("UUID")(Optimus_Common_Client_Components_InfoPanel.renderText(Optimus_Common_Client_InfoShow.infoShowMaybe(Optimus_Common_Client_InfoShow.infoShowString))(v.uuid)), tagInfoPair("Barcode")(Optimus_Common_Client_Components_InfoPanel.renderText(Optimus_Common_Client_InfoShow.infoShowMaybe(Optimus_Common_Client_InfoShow.infoShowString))(v.barcode)), tagInfoPair("Magstripe")(Optimus_Common_Client_Components_InfoPanel.renderText(Optimus_Common_Client_InfoShow.infoShowMaybe(Optimus_Common_Client_InfoShow.infoShowString))(v.magstripe)), tagInfoPair("Holding")(Optimus_Common_Client_Components_InfoPanel.renderText(Optimus_Common_Client_InfoShow.infoShowMaybe(Optimus_Common_Client_InfoShow.infoShowString))(Data_Functor.mapFlipped(Data_Maybe.functorMaybe)(v.holding)(function (v1) {
          return v1.name;
        }))), tagInfoPair("Label")(Optimus_Common_Client_Components_InfoPanel.renderText(Optimus_Common_Client_InfoShow.infoShowMaybe(Optimus_Common_Client_InfoShow.infoShowString))(v.label))]
      });
    };

    var render = function render(props) {
      return Optimus_Client_Component_Content.content({
        title: "Tags",
        children: Data_Array.singleton(Optimus_Client_Component_Grid.grid({
          gutter: 2,
          className: "mw-100",
          rowItemCount: {
            xs: 1,
            sm: 2,
            md: 3,
            lg: 3,
            xl: 4
          },
          items: Data_Functor.mapFlipped(Data_Functor.functorArray)(props.pgData)(renderCard)
        }))
      });
    };

    return React_Basic.makeStateless(component)(render);
  }();

  exports["tagsPage"] = tagsPage;
})(PS["Optimus.Modules.CustomerSupport.Client.Pages.Tags"] = PS["Optimus.Modules.CustomerSupport.Client.Pages.Tags"] || {});

(function (exports) {
  // Generated by purs version 0.12.3
  "use strict";

  var Control_Semigroupoid = PS["Control.Semigroupoid"];
  var Data_Either = PS["Data.Either"];
  var Data_Function = PS["Data.Function"];
  var Data_Generic_Rep = PS["Data.Generic.Rep"];
  var Data_Generic_Rep_Show = PS["Data.Generic.Rep.Show"];
  var Data_Show = PS["Data.Show"];
  var Data_String = PS["Data.String"];
  var Data_String_Common = PS["Data.String.Common"];
  var Data_Symbol = PS["Data.Symbol"];
  var Optimus_Common_Bridge = PS["Optimus.Common.Bridge"];
  var Prelude = PS["Prelude"];
  var ZRPC = PS["ZRPC"];
  var ZRPC_Clients_Mercanti = PS["ZRPC.Clients.Mercanti"];
  var ZRPC_ConsPrefix = PS["ZRPC.ConsPrefix"];
  var ZRPC_ZRPCRead = PS["ZRPC.ZRPCRead"];
  var ZRPC_ZRPCWrite = PS["ZRPC.ZRPCWrite"];

  var SaveSettingsFailed = function () {
    function SaveSettingsFailed() {}

    ;
    SaveSettingsFailed.value = new SaveSettingsFailed();
    return SaveSettingsFailed;
  }();

  var Holding = function () {
    function Holding() {}

    ;
    Holding.value = new Holding();
    return Holding;
  }();

  var Merchant = function () {
    function Merchant() {}

    ;
    Merchant.value = new Merchant();
    return Merchant;
  }();

  var Branch = function () {
    function Branch() {}

    ;
    Branch.value = new Branch();
    return Branch;
  }();

  var genericSettingsErr = new Data_Generic_Rep.Generic(function (x) {
    return Data_Generic_Rep.NoArguments.value;
  }, function (x) {
    return SaveSettingsFailed.value;
  });
  var showSettingsErr = new Data_Show.Show(function (x) {
    return Data_Generic_Rep_Show.genericShow(genericSettingsErr)(Data_Generic_Rep_Show.genericShowConstructor(Data_Generic_Rep_Show.genericShowArgsNoArguments)(new Data_Symbol.IsSymbol(function () {
      return "SaveSettingsFailed";
    })))(x);
  });
  var genericEntity = new Data_Generic_Rep.Generic(function (x) {
    if (x instanceof Holding) {
      return new Data_Generic_Rep.Inl(Data_Generic_Rep.NoArguments.value);
    }

    ;

    if (x instanceof Merchant) {
      return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inl(Data_Generic_Rep.NoArguments.value));
    }

    ;

    if (x instanceof Branch) {
      return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(Data_Generic_Rep.NoArguments.value));
    }

    ;
    throw new Error("Failed pattern match at Optimus.Modules.Tmp.Shared.Settings (line 15, column 8 - line 15, column 50): " + [x.constructor.name]);
  }, function (x) {
    if (x instanceof Data_Generic_Rep.Inl) {
      return Holding.value;
    }

    ;

    if (x instanceof Data_Generic_Rep.Inr && x.value0 instanceof Data_Generic_Rep.Inl) {
      return Merchant.value;
    }

    ;

    if (x instanceof Data_Generic_Rep.Inr && x.value0 instanceof Data_Generic_Rep.Inr) {
      return Branch.value;
    }

    ;
    throw new Error("Failed pattern match at Optimus.Modules.Tmp.Shared.Settings (line 15, column 8 - line 15, column 50): " + [x.constructor.name]);
  });
  var showEntity = new Data_Show.Show(function (x) {
    return Data_String_Common.toLower(Data_Generic_Rep_Show.genericShow(genericEntity)(Data_Generic_Rep_Show.genericShowSum(Data_Generic_Rep_Show.genericShowConstructor(Data_Generic_Rep_Show.genericShowArgsNoArguments)(new Data_Symbol.IsSymbol(function () {
      return "Holding";
    })))(Data_Generic_Rep_Show.genericShowSum(Data_Generic_Rep_Show.genericShowConstructor(Data_Generic_Rep_Show.genericShowArgsNoArguments)(new Data_Symbol.IsSymbol(function () {
      return "Merchant";
    })))(Data_Generic_Rep_Show.genericShowConstructor(Data_Generic_Rep_Show.genericShowArgsNoArguments)(new Data_Symbol.IsSymbol(function () {
      return "Branch";
    })))))(x));
  });
  var consPrefixSettingsErr = new ZRPC_ConsPrefix.ConsPrefix(function (v) {
    return "";
  });
  var zrpcReadSettingsErr = new ZRPC_ZRPCRead.ZRPCRead(ZRPC_ZRPCRead.genericZRPCRead(consPrefixSettingsErr)(genericSettingsErr)(ZRPC_ZRPCRead.readRepNoArgs(new Data_Symbol.IsSymbol(function () {
    return "SaveSettingsFailed";
  }))));
  exports["Holding"] = Holding;
  exports["Merchant"] = Merchant;
  exports["Branch"] = Branch;
  exports["SaveSettingsFailed"] = SaveSettingsFailed;
  exports["genericEntity"] = genericEntity;
  exports["showEntity"] = showEntity;
  exports["genericSettingsErr"] = genericSettingsErr;
  exports["consPrefixSettingsErr"] = consPrefixSettingsErr;
  exports["zrpcReadSettingsErr"] = zrpcReadSettingsErr;
  exports["showSettingsErr"] = showSettingsErr;
})(PS["Optimus.Modules.Tmp.Shared.Settings"] = PS["Optimus.Modules.Tmp.Shared.Settings"] || {});

(function (exports) {
  // Generated by purs version 0.12.3
  "use strict";

  var Data_Semigroup = PS["Data.Semigroup"];
  var Data_Show = PS["Data.Show"];
  var Data_Symbol = PS["Data.Symbol"];
  var Effect_Aff = PS["Effect.Aff"];
  var Optimus_Common_Bridge = PS["Optimus.Common.Bridge"];
  var Optimus_Common_Client_ApiClient_Utils = PS["Optimus.Common.Client.ApiClient.Utils"];
  var Optimus_Common_Client_Utils = PS["Optimus.Common.Client.Utils"];
  var Optimus_Modules_Tmp_Shared_Settings = PS["Optimus.Modules.Tmp.Shared.Settings"];
  var Prelude = PS["Prelude"];
  var ZRPC_Clients_Mercanti = PS["ZRPC.Clients.Mercanti"];
  var ZRPC_ZRPCRead = PS["ZRPC.ZRPCRead"];
  var ZRPC_ZRPCWrite = PS["ZRPC.ZRPCWrite"];

  var settingsUrl = function settingsUrl(entity) {
    return function (id) {
      return "/tmp/settings/" + (Data_Show.show(Optimus_Modules_Tmp_Shared_Settings.showEntity)(entity) + ("/" + id));
    };
  };

  var updateSettings = function updateSettings(entity) {
    return function (id) {
      return Optimus_Common_Client_ApiClient_Utils.postAuthorizedApi(ZRPC_ZRPCWrite.zrpcWriteRec()(ZRPC_ZRPCWrite.zrpcWriteFieldsCons(new Data_Symbol.IsSymbol(function () {
        return "settings";
      }))(ZRPC_ZRPCWrite.zrpcWriteFieldId(ZRPC_ZRPCWrite.zrpcWriteRec()(ZRPC_ZRPCWrite.zrpcWriteFieldsCons(new Data_Symbol.IsSymbol(function () {
        return "checkBalanceAuthenticationMode";
      }))(ZRPC_ZRPCWrite.zrpcWriteFieldMaybe(ZRPC_Clients_Mercanti.zrpcWriteSetMode(ZRPC_Clients_Mercanti.zrpcWriteAuthorizationMode)))(ZRPC_ZRPCWrite.zrpcWriteFieldsCons(new Data_Symbol.IsSymbol(function () {
        return "clearingPeriod";
      }))(ZRPC_ZRPCWrite.zrpcWriteFieldMaybe(ZRPC_Clients_Mercanti.zrpcWriteSetMode(ZRPC_ZRPCWrite.zrpcWriteMillis)))(ZRPC_ZRPCWrite.zrpcWriteFieldsCons(new Data_Symbol.IsSymbol(function () {
        return "countryAlpha2";
      }))(ZRPC_ZRPCWrite.zrpcWriteFieldMaybe(ZRPC_Clients_Mercanti.zrpcWriteSetMode(ZRPC_ZRPCWrite.zrpcWriteString)))(ZRPC_ZRPCWrite.zrpcWriteFieldsCons(new Data_Symbol.IsSymbol(function () {
        return "defaultLocation";
      }))(ZRPC_ZRPCWrite.zrpcWriteFieldMaybe(ZRPC_Clients_Mercanti.zrpcWriteSetMode(ZRPC_ZRPCWrite.zrpcWriteString)))(ZRPC_ZRPCWrite.zrpcWriteFieldsCons(new Data_Symbol.IsSymbol(function () {
        return "hasCashbackOnRedemptionOverflow";
      }))(ZRPC_ZRPCWrite.zrpcWriteFieldMaybe(ZRPC_Clients_Mercanti.zrpcWriteSetMode(ZRPC_ZRPCWrite.zrpcWriteBoolean)))(ZRPC_ZRPCWrite.zrpcWriteFieldsCons(new Data_Symbol.IsSymbol(function () {
        return "isClearedVoidable";
      }))(ZRPC_ZRPCWrite.zrpcWriteFieldMaybe(ZRPC_Clients_Mercanti.zrpcWriteSetMode(ZRPC_ZRPCWrite.zrpcWriteBoolean)))(ZRPC_ZRPCWrite.zrpcWriteFieldsCons(new Data_Symbol.IsSymbol(function () {
        return "isEarnEmailReceiptEnabled";
      }))(ZRPC_ZRPCWrite.zrpcWriteFieldMaybe(ZRPC_Clients_Mercanti.zrpcWriteSetMode(ZRPC_ZRPCWrite.zrpcWriteBoolean)))(ZRPC_ZRPCWrite.zrpcWriteFieldsCons(new Data_Symbol.IsSymbol(function () {
        return "isEarnSmsReceiptEnabled";
      }))(ZRPC_ZRPCWrite.zrpcWriteFieldMaybe(ZRPC_Clients_Mercanti.zrpcWriteSetMode(ZRPC_ZRPCWrite.zrpcWriteBoolean)))(ZRPC_ZRPCWrite.zrpcWriteFieldsCons(new Data_Symbol.IsSymbol(function () {
        return "isIdMethodByUnverifiedTagAllowed";
      }))(ZRPC_ZRPCWrite.zrpcWriteFieldMaybe(ZRPC_Clients_Mercanti.zrpcWriteSetMode(ZRPC_ZRPCWrite.zrpcWriteBoolean)))(ZRPC_ZRPCWrite.zrpcWriteFieldsCons(new Data_Symbol.IsSymbol(function () {
        return "isLinkCardOnRegistrationRequired";
      }))(ZRPC_ZRPCWrite.zrpcWriteFieldMaybe(ZRPC_Clients_Mercanti.zrpcWriteSetMode(ZRPC_ZRPCWrite.zrpcWriteBoolean)))(ZRPC_ZRPCWrite.zrpcWriteFieldsCons(new Data_Symbol.IsSymbol(function () {
        return "isPayInCashEnabled";
      }))(ZRPC_ZRPCWrite.zrpcWriteFieldMaybe(ZRPC_Clients_Mercanti.zrpcWriteSetMode(ZRPC_ZRPCWrite.zrpcWriteBoolean)))(ZRPC_ZRPCWrite.zrpcWriteFieldsCons(new Data_Symbol.IsSymbol(function () {
        return "isProfileOnEarnRequired";
      }))(ZRPC_ZRPCWrite.zrpcWriteFieldMaybe(ZRPC_Clients_Mercanti.zrpcWriteSetMode(ZRPC_ZRPCWrite.zrpcWriteBoolean)))(ZRPC_ZRPCWrite.zrpcWriteFieldsCons(new Data_Symbol.IsSymbol(function () {
        return "isProfileOnRedeemRequired";
      }))(ZRPC_ZRPCWrite.zrpcWriteFieldMaybe(ZRPC_Clients_Mercanti.zrpcWriteSetMode(ZRPC_ZRPCWrite.zrpcWriteBoolean)))(ZRPC_ZRPCWrite.zrpcWriteFieldsCons(new Data_Symbol.IsSymbol(function () {
        return "isQuickRedeemEnabled";
      }))(ZRPC_ZRPCWrite.zrpcWriteFieldMaybe(ZRPC_Clients_Mercanti.zrpcWriteSetMode(ZRPC_ZRPCWrite.zrpcWriteBoolean)))(ZRPC_ZRPCWrite.zrpcWriteFieldsCons(new Data_Symbol.IsSymbol(function () {
        return "isRedeemEmailReceiptEnabled";
      }))(ZRPC_ZRPCWrite.zrpcWriteFieldMaybe(ZRPC_Clients_Mercanti.zrpcWriteSetMode(ZRPC_ZRPCWrite.zrpcWriteBoolean)))(ZRPC_ZRPCWrite.zrpcWriteFieldsCons(new Data_Symbol.IsSymbol(function () {
        return "isRedeemSmsReceiptEnabled";
      }))(ZRPC_ZRPCWrite.zrpcWriteFieldMaybe(ZRPC_Clients_Mercanti.zrpcWriteSetMode(ZRPC_ZRPCWrite.zrpcWriteBoolean)))(ZRPC_ZRPCWrite.zrpcWriteFieldsCons(new Data_Symbol.IsSymbol(function () {
        return "linkTagVerificationMode";
      }))(ZRPC_ZRPCWrite.zrpcWriteFieldMaybe(ZRPC_Clients_Mercanti.zrpcWriteSetMode(ZRPC_Clients_Mercanti.zrpcWriteLinkTagVerificationMode)))(ZRPC_ZRPCWrite.zrpcWriteFieldsCons(new Data_Symbol.IsSymbol(function () {
        return "phoneNumberPrefix";
      }))(ZRPC_ZRPCWrite.zrpcWriteFieldMaybe(ZRPC_Clients_Mercanti.zrpcWriteSetMode(ZRPC_ZRPCWrite.zrpcWriteString)))(ZRPC_ZRPCWrite.zrpcWriteFieldsCons(new Data_Symbol.IsSymbol(function () {
        return "posSetUp";
      }))(ZRPC_ZRPCWrite.zrpcWriteFieldMaybe(ZRPC_Clients_Mercanti.zrpcWriteSetMode(ZRPC_ZRPCWrite.zrpcWriteRec()(ZRPC_ZRPCWrite.zrpcWriteFieldsCons(new Data_Symbol.IsSymbol(function () {
        return "backgroundColor";
      }))(ZRPC_ZRPCWrite.zrpcWriteFieldId(ZRPC_ZRPCWrite.zrpcWriteString))(ZRPC_ZRPCWrite.zrpcWriteFieldsCons(new Data_Symbol.IsSymbol(function () {
        return "backgroundUrl";
      }))(ZRPC_ZRPCWrite.zrpcWriteFieldId(ZRPC_ZRPCWrite.zrpcWriteString))(ZRPC_ZRPCWrite.zrpcWriteFieldsCons(new Data_Symbol.IsSymbol(function () {
        return "foregroundColor";
      }))(ZRPC_ZRPCWrite.zrpcWriteFieldId(ZRPC_ZRPCWrite.zrpcWriteString))(ZRPC_ZRPCWrite.zrpcWriteFieldsCons(new Data_Symbol.IsSymbol(function () {
        return "logoUrl";
      }))(ZRPC_ZRPCWrite.zrpcWriteFieldId(ZRPC_ZRPCWrite.zrpcWriteString))(ZRPC_ZRPCWrite.zrpcWriteFieldsNil)())())())()))))(ZRPC_ZRPCWrite.zrpcWriteFieldsCons(new Data_Symbol.IsSymbol(function () {
        return "qrScanCooldown";
      }))(ZRPC_ZRPCWrite.zrpcWriteFieldMaybe(ZRPC_Clients_Mercanti.zrpcWriteSetMode(ZRPC_ZRPCWrite.zrpcWriteMillis)))(ZRPC_ZRPCWrite.zrpcWriteFieldsCons(new Data_Symbol.IsSymbol(function () {
        return "qrScanCountLimit";
      }))(ZRPC_ZRPCWrite.zrpcWriteFieldMaybe(ZRPC_Clients_Mercanti.zrpcWriteSetMode(ZRPC_ZRPCWrite.zrpcWriteInt)))(ZRPC_ZRPCWrite.zrpcWriteFieldsCons(new Data_Symbol.IsSymbol(function () {
        return "qrValidityDuration";
      }))(ZRPC_ZRPCWrite.zrpcWriteFieldMaybe(ZRPC_Clients_Mercanti.zrpcWriteSetMode(ZRPC_ZRPCWrite.zrpcWriteMillis)))(ZRPC_ZRPCWrite.zrpcWriteFieldsCons(new Data_Symbol.IsSymbol(function () {
        return "scalingMethod";
      }))(ZRPC_ZRPCWrite.zrpcWriteFieldMaybe(ZRPC_Clients_Mercanti.zrpcWriteSetMode(ZRPC_ZRPCWrite.zrpcWriteRec()(ZRPC_ZRPCWrite.zrpcWriteFieldsCons(new Data_Symbol.IsSymbol(function () {
        return "cashbackDecimal";
      }))(ZRPC_ZRPCWrite.zrpcWriteFieldId(ZRPC_ZRPCWrite.zrpcWriteInt))(ZRPC_ZRPCWrite.zrpcWriteFieldsCons(new Data_Symbol.IsSymbol(function () {
        return "roundingMode";
      }))(ZRPC_ZRPCWrite.zrpcWriteFieldId(ZRPC_Clients_Mercanti.zrpcWriteRoundingMode))(ZRPC_ZRPCWrite.zrpcWriteFieldsNil)())()))))(ZRPC_ZRPCWrite.zrpcWriteFieldsCons(new Data_Symbol.IsSymbol(function () {
        return "senderEmail";
      }))(ZRPC_ZRPCWrite.zrpcWriteFieldMaybe(ZRPC_Clients_Mercanti.zrpcWriteSetMode(ZRPC_ZRPCWrite.zrpcWriteString)))(ZRPC_ZRPCWrite.zrpcWriteFieldsCons(new Data_Symbol.IsSymbol(function () {
        return "timeZone";
      }))(ZRPC_ZRPCWrite.zrpcWriteFieldMaybe(ZRPC_Clients_Mercanti.zrpcWriteSetMode(ZRPC_ZRPCWrite.zrpcWriteString)))(ZRPC_ZRPCWrite.zrpcWriteFieldsCons(new Data_Symbol.IsSymbol(function () {
        return "transactionAmountComputationMode";
      }))(ZRPC_ZRPCWrite.zrpcWriteFieldMaybe(ZRPC_Clients_Mercanti.zrpcWriteSetMode(ZRPC_Clients_Mercanti.zrpcWriteTransactionAmountComputationMode)))(ZRPC_ZRPCWrite.zrpcWriteFieldsCons(new Data_Symbol.IsSymbol(function () {
        return "transactionCurrency";
      }))(ZRPC_ZRPCWrite.zrpcWriteFieldMaybe(ZRPC_Clients_Mercanti.zrpcWriteSetMode(ZRPC_ZRPCWrite.zrpcWriteInt)))(ZRPC_ZRPCWrite.zrpcWriteFieldsCons(new Data_Symbol.IsSymbol(function () {
        return "voidAuthenticationMode";
      }))(ZRPC_ZRPCWrite.zrpcWriteFieldMaybe(ZRPC_Clients_Mercanti.zrpcWriteSetMode(ZRPC_Clients_Mercanti.zrpcWriteAuthorizationMode)))(ZRPC_ZRPCWrite.zrpcWriteFieldsNil)())())())())())())())())())())())())())())())())())())())())())())())())())())())())))(ZRPC_ZRPCWrite.zrpcWriteFieldsNil)()))(ZRPC_ZRPCRead.zrpcReadEither(ZRPC_ZRPCRead.zrpcReadFieldId(Optimus_Common_Bridge.zrpcReadResErr))(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_ZRPCRead.zrpcReadEither(ZRPC_ZRPCRead.zrpcReadFieldId(Optimus_Modules_Tmp_Shared_Settings.zrpcReadSettingsErr))(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_ZRPCRead.zrpcReadUnit)))))()(settingsUrl(entity)(id))({});
    };
  };

  var updateBranchSettings = updateSettings(Optimus_Modules_Tmp_Shared_Settings.Branch.value);
  var updateHoldingSettings = updateSettings(Optimus_Modules_Tmp_Shared_Settings.Holding.value);
  var updateMerchantSettings = updateSettings(Optimus_Modules_Tmp_Shared_Settings.Merchant.value);

  var getSettings = function getSettings(entity) {
    return function (id) {
      return Optimus_Common_Client_ApiClient_Utils.getAuthorizedApi(ZRPC_ZRPCRead.readRecord()(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
        return "name";
      }))(ZRPC_ZRPCRead.zrpcReadFieldMaybe(ZRPC_ZRPCRead.zrpcReadString))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
        return "settings";
      }))(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_ZRPCRead.readRecord()(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
        return "checkBalanceAuthenticationMode";
      }))(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_ZRPCRead.readRecord()(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
        return "default";
      }))(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_ZRPCRead.readRecord()(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
        return "provider";
      }))(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_Clients_Mercanti.zrpcReadProvider))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
        return "value";
      }))(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_Clients_Mercanti.zrpcReadAuthorizationMode))(ZRPC_ZRPCRead.zrpcReadFieldsNil)()())()())))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
        return "fixed";
      }))(ZRPC_ZRPCRead.zrpcReadFieldMaybe(ZRPC_Clients_Mercanti.zrpcReadAuthorizationMode))(ZRPC_ZRPCRead.zrpcReadFieldsNil)()())()())))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
        return "clearingPeriod";
      }))(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_ZRPCRead.readRecord()(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
        return "default";
      }))(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_ZRPCRead.readRecord()(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
        return "provider";
      }))(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_Clients_Mercanti.zrpcReadProvider))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
        return "value";
      }))(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_ZRPCRead.zrpcReadMillis))(ZRPC_ZRPCRead.zrpcReadFieldsNil)()())()())))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
        return "fixed";
      }))(ZRPC_ZRPCRead.zrpcReadFieldMaybe(ZRPC_ZRPCRead.zrpcReadMillis))(ZRPC_ZRPCRead.zrpcReadFieldsNil)()())()())))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
        return "countryAlpha2";
      }))(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_ZRPCRead.readRecord()(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
        return "default";
      }))(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_ZRPCRead.readRecord()(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
        return "provider";
      }))(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_Clients_Mercanti.zrpcReadProvider))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
        return "value";
      }))(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_ZRPCRead.zrpcReadString))(ZRPC_ZRPCRead.zrpcReadFieldsNil)()())()())))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
        return "fixed";
      }))(ZRPC_ZRPCRead.zrpcReadFieldMaybe(ZRPC_ZRPCRead.zrpcReadString))(ZRPC_ZRPCRead.zrpcReadFieldsNil)()())()())))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
        return "defaultLocation";
      }))(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_ZRPCRead.readRecord()(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
        return "default";
      }))(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_ZRPCRead.readRecord()(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
        return "provider";
      }))(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_Clients_Mercanti.zrpcReadProvider))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
        return "value";
      }))(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_ZRPCRead.zrpcReadString))(ZRPC_ZRPCRead.zrpcReadFieldsNil)()())()())))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
        return "fixed";
      }))(ZRPC_ZRPCRead.zrpcReadFieldMaybe(ZRPC_ZRPCRead.zrpcReadString))(ZRPC_ZRPCRead.zrpcReadFieldsNil)()())()())))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
        return "hasCashbackOnRedemptionOverflow";
      }))(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_ZRPCRead.readRecord()(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
        return "default";
      }))(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_ZRPCRead.readRecord()(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
        return "provider";
      }))(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_Clients_Mercanti.zrpcReadProvider))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
        return "value";
      }))(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_ZRPCRead.zrpcReadBoolean))(ZRPC_ZRPCRead.zrpcReadFieldsNil)()())()())))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
        return "fixed";
      }))(ZRPC_ZRPCRead.zrpcReadFieldMaybe(ZRPC_ZRPCRead.zrpcReadBoolean))(ZRPC_ZRPCRead.zrpcReadFieldsNil)()())()())))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
        return "isClearedVoidable";
      }))(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_ZRPCRead.readRecord()(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
        return "default";
      }))(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_ZRPCRead.readRecord()(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
        return "provider";
      }))(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_Clients_Mercanti.zrpcReadProvider))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
        return "value";
      }))(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_ZRPCRead.zrpcReadBoolean))(ZRPC_ZRPCRead.zrpcReadFieldsNil)()())()())))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
        return "fixed";
      }))(ZRPC_ZRPCRead.zrpcReadFieldMaybe(ZRPC_ZRPCRead.zrpcReadBoolean))(ZRPC_ZRPCRead.zrpcReadFieldsNil)()())()())))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
        return "isEarnEmailReceiptEnabled";
      }))(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_ZRPCRead.readRecord()(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
        return "default";
      }))(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_ZRPCRead.readRecord()(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
        return "provider";
      }))(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_Clients_Mercanti.zrpcReadProvider))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
        return "value";
      }))(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_ZRPCRead.zrpcReadBoolean))(ZRPC_ZRPCRead.zrpcReadFieldsNil)()())()())))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
        return "fixed";
      }))(ZRPC_ZRPCRead.zrpcReadFieldMaybe(ZRPC_ZRPCRead.zrpcReadBoolean))(ZRPC_ZRPCRead.zrpcReadFieldsNil)()())()())))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
        return "isEarnSmsReceiptEnabled";
      }))(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_ZRPCRead.readRecord()(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
        return "default";
      }))(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_ZRPCRead.readRecord()(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
        return "provider";
      }))(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_Clients_Mercanti.zrpcReadProvider))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
        return "value";
      }))(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_ZRPCRead.zrpcReadBoolean))(ZRPC_ZRPCRead.zrpcReadFieldsNil)()())()())))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
        return "fixed";
      }))(ZRPC_ZRPCRead.zrpcReadFieldMaybe(ZRPC_ZRPCRead.zrpcReadBoolean))(ZRPC_ZRPCRead.zrpcReadFieldsNil)()())()())))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
        return "isIdMethodByUnverifiedTagAllowed";
      }))(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_ZRPCRead.readRecord()(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
        return "default";
      }))(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_ZRPCRead.readRecord()(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
        return "provider";
      }))(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_Clients_Mercanti.zrpcReadProvider))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
        return "value";
      }))(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_ZRPCRead.zrpcReadBoolean))(ZRPC_ZRPCRead.zrpcReadFieldsNil)()())()())))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
        return "fixed";
      }))(ZRPC_ZRPCRead.zrpcReadFieldMaybe(ZRPC_ZRPCRead.zrpcReadBoolean))(ZRPC_ZRPCRead.zrpcReadFieldsNil)()())()())))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
        return "isLinkCardOnRegistrationRequired";
      }))(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_ZRPCRead.readRecord()(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
        return "default";
      }))(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_ZRPCRead.readRecord()(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
        return "provider";
      }))(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_Clients_Mercanti.zrpcReadProvider))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
        return "value";
      }))(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_ZRPCRead.zrpcReadBoolean))(ZRPC_ZRPCRead.zrpcReadFieldsNil)()())()())))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
        return "fixed";
      }))(ZRPC_ZRPCRead.zrpcReadFieldMaybe(ZRPC_ZRPCRead.zrpcReadBoolean))(ZRPC_ZRPCRead.zrpcReadFieldsNil)()())()())))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
        return "isPayInCashEnabled";
      }))(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_ZRPCRead.readRecord()(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
        return "default";
      }))(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_ZRPCRead.readRecord()(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
        return "provider";
      }))(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_Clients_Mercanti.zrpcReadProvider))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
        return "value";
      }))(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_ZRPCRead.zrpcReadBoolean))(ZRPC_ZRPCRead.zrpcReadFieldsNil)()())()())))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
        return "fixed";
      }))(ZRPC_ZRPCRead.zrpcReadFieldMaybe(ZRPC_ZRPCRead.zrpcReadBoolean))(ZRPC_ZRPCRead.zrpcReadFieldsNil)()())()())))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
        return "isProfileOnEarnRequired";
      }))(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_ZRPCRead.readRecord()(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
        return "default";
      }))(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_ZRPCRead.readRecord()(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
        return "provider";
      }))(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_Clients_Mercanti.zrpcReadProvider))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
        return "value";
      }))(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_ZRPCRead.zrpcReadBoolean))(ZRPC_ZRPCRead.zrpcReadFieldsNil)()())()())))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
        return "fixed";
      }))(ZRPC_ZRPCRead.zrpcReadFieldMaybe(ZRPC_ZRPCRead.zrpcReadBoolean))(ZRPC_ZRPCRead.zrpcReadFieldsNil)()())()())))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
        return "isProfileOnRedeemRequired";
      }))(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_ZRPCRead.readRecord()(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
        return "default";
      }))(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_ZRPCRead.readRecord()(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
        return "provider";
      }))(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_Clients_Mercanti.zrpcReadProvider))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
        return "value";
      }))(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_ZRPCRead.zrpcReadBoolean))(ZRPC_ZRPCRead.zrpcReadFieldsNil)()())()())))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
        return "fixed";
      }))(ZRPC_ZRPCRead.zrpcReadFieldMaybe(ZRPC_ZRPCRead.zrpcReadBoolean))(ZRPC_ZRPCRead.zrpcReadFieldsNil)()())()())))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
        return "isQuickRedeemEnabled";
      }))(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_ZRPCRead.readRecord()(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
        return "default";
      }))(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_ZRPCRead.readRecord()(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
        return "provider";
      }))(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_Clients_Mercanti.zrpcReadProvider))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
        return "value";
      }))(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_ZRPCRead.zrpcReadBoolean))(ZRPC_ZRPCRead.zrpcReadFieldsNil)()())()())))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
        return "fixed";
      }))(ZRPC_ZRPCRead.zrpcReadFieldMaybe(ZRPC_ZRPCRead.zrpcReadBoolean))(ZRPC_ZRPCRead.zrpcReadFieldsNil)()())()())))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
        return "isRedeemEmailReceiptEnabled";
      }))(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_ZRPCRead.readRecord()(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
        return "default";
      }))(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_ZRPCRead.readRecord()(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
        return "provider";
      }))(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_Clients_Mercanti.zrpcReadProvider))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
        return "value";
      }))(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_ZRPCRead.zrpcReadBoolean))(ZRPC_ZRPCRead.zrpcReadFieldsNil)()())()())))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
        return "fixed";
      }))(ZRPC_ZRPCRead.zrpcReadFieldMaybe(ZRPC_ZRPCRead.zrpcReadBoolean))(ZRPC_ZRPCRead.zrpcReadFieldsNil)()())()())))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
        return "isRedeemSmsReceiptEnabled";
      }))(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_ZRPCRead.readRecord()(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
        return "default";
      }))(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_ZRPCRead.readRecord()(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
        return "provider";
      }))(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_Clients_Mercanti.zrpcReadProvider))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
        return "value";
      }))(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_ZRPCRead.zrpcReadBoolean))(ZRPC_ZRPCRead.zrpcReadFieldsNil)()())()())))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
        return "fixed";
      }))(ZRPC_ZRPCRead.zrpcReadFieldMaybe(ZRPC_ZRPCRead.zrpcReadBoolean))(ZRPC_ZRPCRead.zrpcReadFieldsNil)()())()())))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
        return "linkTagVerificationMode";
      }))(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_ZRPCRead.readRecord()(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
        return "default";
      }))(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_ZRPCRead.readRecord()(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
        return "provider";
      }))(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_Clients_Mercanti.zrpcReadProvider))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
        return "value";
      }))(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_Clients_Mercanti.zrpcReadLinkTagVerificationMode))(ZRPC_ZRPCRead.zrpcReadFieldsNil)()())()())))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
        return "fixed";
      }))(ZRPC_ZRPCRead.zrpcReadFieldMaybe(ZRPC_Clients_Mercanti.zrpcReadLinkTagVerificationMode))(ZRPC_ZRPCRead.zrpcReadFieldsNil)()())()())))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
        return "phoneNumberPrefix";
      }))(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_ZRPCRead.readRecord()(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
        return "default";
      }))(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_ZRPCRead.readRecord()(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
        return "provider";
      }))(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_Clients_Mercanti.zrpcReadProvider))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
        return "value";
      }))(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_ZRPCRead.zrpcReadString))(ZRPC_ZRPCRead.zrpcReadFieldsNil)()())()())))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
        return "fixed";
      }))(ZRPC_ZRPCRead.zrpcReadFieldMaybe(ZRPC_ZRPCRead.zrpcReadString))(ZRPC_ZRPCRead.zrpcReadFieldsNil)()())()())))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
        return "posSetUp";
      }))(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_ZRPCRead.readRecord()(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
        return "default";
      }))(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_ZRPCRead.readRecord()(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
        return "provider";
      }))(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_Clients_Mercanti.zrpcReadProvider))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
        return "value";
      }))(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_ZRPCRead.readRecord()(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
        return "backgroundColor";
      }))(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_ZRPCRead.zrpcReadString))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
        return "backgroundUrl";
      }))(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_ZRPCRead.zrpcReadString))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
        return "foregroundColor";
      }))(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_ZRPCRead.zrpcReadString))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
        return "logoUrl";
      }))(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_ZRPCRead.zrpcReadString))(ZRPC_ZRPCRead.zrpcReadFieldsNil)()())()())()())()())))(ZRPC_ZRPCRead.zrpcReadFieldsNil)()())()())))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
        return "fixed";
      }))(ZRPC_ZRPCRead.zrpcReadFieldMaybe(ZRPC_ZRPCRead.readRecord()(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
        return "backgroundColor";
      }))(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_ZRPCRead.zrpcReadString))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
        return "backgroundUrl";
      }))(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_ZRPCRead.zrpcReadString))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
        return "foregroundColor";
      }))(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_ZRPCRead.zrpcReadString))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
        return "logoUrl";
      }))(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_ZRPCRead.zrpcReadString))(ZRPC_ZRPCRead.zrpcReadFieldsNil)()())()())()())()())))(ZRPC_ZRPCRead.zrpcReadFieldsNil)()())()())))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
        return "qrScanCooldown";
      }))(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_ZRPCRead.readRecord()(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
        return "default";
      }))(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_ZRPCRead.readRecord()(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
        return "provider";
      }))(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_Clients_Mercanti.zrpcReadProvider))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
        return "value";
      }))(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_ZRPCRead.zrpcReadMillis))(ZRPC_ZRPCRead.zrpcReadFieldsNil)()())()())))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
        return "fixed";
      }))(ZRPC_ZRPCRead.zrpcReadFieldMaybe(ZRPC_ZRPCRead.zrpcReadMillis))(ZRPC_ZRPCRead.zrpcReadFieldsNil)()())()())))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
        return "qrScanCountLimit";
      }))(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_ZRPCRead.readRecord()(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
        return "default";
      }))(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_ZRPCRead.readRecord()(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
        return "provider";
      }))(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_Clients_Mercanti.zrpcReadProvider))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
        return "value";
      }))(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_ZRPCRead.zrpcReadInt))(ZRPC_ZRPCRead.zrpcReadFieldsNil)()())()())))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
        return "fixed";
      }))(ZRPC_ZRPCRead.zrpcReadFieldMaybe(ZRPC_ZRPCRead.zrpcReadInt))(ZRPC_ZRPCRead.zrpcReadFieldsNil)()())()())))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
        return "qrValidityDuration";
      }))(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_ZRPCRead.readRecord()(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
        return "default";
      }))(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_ZRPCRead.readRecord()(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
        return "provider";
      }))(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_Clients_Mercanti.zrpcReadProvider))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
        return "value";
      }))(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_ZRPCRead.zrpcReadMillis))(ZRPC_ZRPCRead.zrpcReadFieldsNil)()())()())))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
        return "fixed";
      }))(ZRPC_ZRPCRead.zrpcReadFieldMaybe(ZRPC_ZRPCRead.zrpcReadMillis))(ZRPC_ZRPCRead.zrpcReadFieldsNil)()())()())))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
        return "scalingMethod";
      }))(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_ZRPCRead.readRecord()(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
        return "default";
      }))(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_ZRPCRead.readRecord()(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
        return "provider";
      }))(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_Clients_Mercanti.zrpcReadProvider))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
        return "value";
      }))(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_ZRPCRead.readRecord()(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
        return "cashbackDecimal";
      }))(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_ZRPCRead.zrpcReadInt))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
        return "roundingMode";
      }))(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_Clients_Mercanti.zrpcReadRoundingMode))(ZRPC_ZRPCRead.zrpcReadFieldsNil)()())()())))(ZRPC_ZRPCRead.zrpcReadFieldsNil)()())()())))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
        return "fixed";
      }))(ZRPC_ZRPCRead.zrpcReadFieldMaybe(ZRPC_ZRPCRead.readRecord()(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
        return "cashbackDecimal";
      }))(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_ZRPCRead.zrpcReadInt))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
        return "roundingMode";
      }))(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_Clients_Mercanti.zrpcReadRoundingMode))(ZRPC_ZRPCRead.zrpcReadFieldsNil)()())()())))(ZRPC_ZRPCRead.zrpcReadFieldsNil)()())()())))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
        return "senderEmail";
      }))(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_ZRPCRead.readRecord()(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
        return "default";
      }))(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_ZRPCRead.readRecord()(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
        return "provider";
      }))(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_Clients_Mercanti.zrpcReadProvider))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
        return "value";
      }))(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_ZRPCRead.zrpcReadString))(ZRPC_ZRPCRead.zrpcReadFieldsNil)()())()())))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
        return "fixed";
      }))(ZRPC_ZRPCRead.zrpcReadFieldMaybe(ZRPC_ZRPCRead.zrpcReadString))(ZRPC_ZRPCRead.zrpcReadFieldsNil)()())()())))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
        return "timeZone";
      }))(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_ZRPCRead.readRecord()(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
        return "default";
      }))(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_ZRPCRead.readRecord()(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
        return "provider";
      }))(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_Clients_Mercanti.zrpcReadProvider))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
        return "value";
      }))(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_ZRPCRead.zrpcReadString))(ZRPC_ZRPCRead.zrpcReadFieldsNil)()())()())))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
        return "fixed";
      }))(ZRPC_ZRPCRead.zrpcReadFieldMaybe(ZRPC_ZRPCRead.zrpcReadString))(ZRPC_ZRPCRead.zrpcReadFieldsNil)()())()())))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
        return "transactionAmountComputationMode";
      }))(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_ZRPCRead.readRecord()(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
        return "default";
      }))(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_ZRPCRead.readRecord()(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
        return "provider";
      }))(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_Clients_Mercanti.zrpcReadProvider))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
        return "value";
      }))(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_Clients_Mercanti.zrpcReadTransactionAmountComputationMode))(ZRPC_ZRPCRead.zrpcReadFieldsNil)()())()())))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
        return "fixed";
      }))(ZRPC_ZRPCRead.zrpcReadFieldMaybe(ZRPC_Clients_Mercanti.zrpcReadTransactionAmountComputationMode))(ZRPC_ZRPCRead.zrpcReadFieldsNil)()())()())))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
        return "transactionCurrency";
      }))(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_ZRPCRead.readRecord()(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
        return "default";
      }))(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_ZRPCRead.readRecord()(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
        return "provider";
      }))(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_Clients_Mercanti.zrpcReadProvider))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
        return "value";
      }))(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_ZRPCRead.zrpcReadInt))(ZRPC_ZRPCRead.zrpcReadFieldsNil)()())()())))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
        return "fixed";
      }))(ZRPC_ZRPCRead.zrpcReadFieldMaybe(ZRPC_ZRPCRead.zrpcReadInt))(ZRPC_ZRPCRead.zrpcReadFieldsNil)()())()())))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
        return "voidAuthenticationMode";
      }))(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_ZRPCRead.readRecord()(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
        return "default";
      }))(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_ZRPCRead.readRecord()(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
        return "provider";
      }))(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_Clients_Mercanti.zrpcReadProvider))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
        return "value";
      }))(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_Clients_Mercanti.zrpcReadAuthorizationMode))(ZRPC_ZRPCRead.zrpcReadFieldsNil)()())()())))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
        return "fixed";
      }))(ZRPC_ZRPCRead.zrpcReadFieldMaybe(ZRPC_Clients_Mercanti.zrpcReadAuthorizationMode))(ZRPC_ZRPCRead.zrpcReadFieldsNil)()())()())))(ZRPC_ZRPCRead.zrpcReadFieldsNil)()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())))(ZRPC_ZRPCRead.zrpcReadFieldsNil)()())()()))()(settingsUrl(entity)(id))({});
    };
  };

  var getMerchantSettings = getSettings(Optimus_Modules_Tmp_Shared_Settings.Merchant.value);
  var getHoldingSettings = getSettings(Optimus_Modules_Tmp_Shared_Settings.Holding.value);
  var getBranchSettings = getSettings(Optimus_Modules_Tmp_Shared_Settings.Branch.value);
  exports["settingsUrl"] = settingsUrl;
  exports["getSettings"] = getSettings;
  exports["getHoldingSettings"] = getHoldingSettings;
  exports["getMerchantSettings"] = getMerchantSettings;
  exports["getBranchSettings"] = getBranchSettings;
  exports["updateSettings"] = updateSettings;
  exports["updateHoldingSettings"] = updateHoldingSettings;
  exports["updateMerchantSettings"] = updateMerchantSettings;
  exports["updateBranchSettings"] = updateBranchSettings;
})(PS["Optimus.Modules.Tmp.Client.ApiClient.Settings"] = PS["Optimus.Modules.Tmp.Client.ApiClient.Settings"] || {});

(function (exports) {
  // Generated by purs version 0.12.3
  "use strict";

  var Data_Function = PS["Data.Function"];
  var Data_Semigroup = PS["Data.Semigroup"];
  var Data_Show = PS["Data.Show"];
  var Data_Symbol = PS["Data.Symbol"];
  var Prelude = PS["Prelude"];
  var React_Basic = PS["React.Basic"];
  var React_Basic_DOM = PS["React.Basic.DOM"];
  var React_Basic_DOM_Generated = PS["React.Basic.DOM.Generated"];
  var Record = PS["Record"];
  var Type_Data_RowList = PS["Type.Data.RowList"];
  var ZRPC_Clients_Mercanti = PS["ZRPC.Clients.Mercanti"];
  var ZRPC_Types_Millis = PS["ZRPC.Types.Millis"];

  var ShowDefaultVal = function ShowDefaultVal(showDefaultVal) {
    this.showDefaultVal = showDefaultVal;
  };

  var ShowDefaultVals = function ShowDefaultVals(showDefaultVals) {
    this.showDefaultVals = showDefaultVals;
  };

  var ShowDefaultValsRL = function ShowDefaultValsRL(showDefaultValsRL) {
    this.showDefaultValsRL = showDefaultValsRL;
  };

  var showDefaultValsRLNil = new ShowDefaultValsRL(function (v) {
    return function (v1) {
      return React_Basic.fragment([]);
    };
  });

  var showDefaultValsRL = function showDefaultValsRL(dict) {
    return dict.showDefaultValsRL;
  };

  var showDefaultValsRecord = function showDefaultValsRecord(dictRowToList) {
    return function (dictShowDefaultValsRL) {
      return new ShowDefaultVals(function (r) {
        return showDefaultValsRL(dictShowDefaultValsRL)(Type_Data_RowList.RLProxy.value)(r);
      });
    };
  };

  var showDefaultVals = function showDefaultVals(dict) {
    return dict.showDefaultVals;
  };

  var showDefaultVal = function showDefaultVal(dict) {
    return dict.showDefaultVal;
  };

  var showDefaultValsRLCons = function showDefaultValsRLCons(dictIsSymbol) {
    return function (dictShowDefaultValsRL) {
      return function (dictCons) {
        return function (dictShowDefaultVal) {
          return new ShowDefaultValsRL(function (v) {
            return function (r) {
              var others = showDefaultValsRL(dictShowDefaultValsRL)(Type_Data_RowList.RLProxy.value)(r);
              var value = Record.get(dictIsSymbol)(dictCons)(Data_Symbol.SProxy.value)(r);
              return Data_Semigroup.append(React_Basic.semigroupJSX)(React_Basic_DOM_Generated.div_([React_Basic.fragment([React_Basic_DOM.text(Data_Symbol.reflectSymbol(dictIsSymbol)(Data_Symbol.SProxy.value) + ":")]), showDefaultVal(dictShowDefaultVal)(value)]))(others);
            };
          });
        };
      };
    };
  };

  var showDefaultRecord = function showDefaultRecord(dictShowDefaultVals) {
    return new ShowDefaultVal(showDefaultVals(dictShowDefaultVals));
  };

  var genShowDefault = function genShowDefault(dictShow) {
    return function (x) {
      return React_Basic_DOM_Generated.div_([React_Basic_DOM.text(Data_Show.show(dictShow)(x))]);
    };
  };

  var showDefaultBoolean = new ShowDefaultVal(genShowDefault(Data_Show.showBoolean));
  var showDefaultInt = new ShowDefaultVal(genShowDefault(Data_Show.showInt));
  var showDefaultRoundingMode = new ShowDefaultVal(genShowDefault(ZRPC_Clients_Mercanti.showRoundingMode));
  var showDefaultValAM = new ShowDefaultVal(genShowDefault(ZRPC_Clients_Mercanti.showAuthorizationMode));
  var showDefaultValLTVM = new ShowDefaultVal(genShowDefault(ZRPC_Clients_Mercanti.showLinkTagVerificationMode));
  var showDefaultValMillis = new ShowDefaultVal(genShowDefault(ZRPC_Types_Millis.showMillis));
  var showDefaultValString = new ShowDefaultVal(genShowDefault(Data_Show.showString));
  var showDefaultValTransactionAmountComputationMode = new ShowDefaultVal(genShowDefault(ZRPC_Clients_Mercanti.showTransactionAmountComputationMode));
  exports["ShowDefaultVal"] = ShowDefaultVal;
  exports["ShowDefaultVals"] = ShowDefaultVals;
  exports["ShowDefaultValsRL"] = ShowDefaultValsRL;
  exports["showDefaultVal"] = showDefaultVal;
  exports["showDefaultVals"] = showDefaultVals;
  exports["showDefaultValsRL"] = showDefaultValsRL;
  exports["showDefaultValString"] = showDefaultValString;
  exports["showDefaultBoolean"] = showDefaultBoolean;
  exports["showDefaultInt"] = showDefaultInt;
  exports["showDefaultRecord"] = showDefaultRecord;
  exports["showDefaultRoundingMode"] = showDefaultRoundingMode;
  exports["showDefaultValMillis"] = showDefaultValMillis;
  exports["showDefaultValLTVM"] = showDefaultValLTVM;
  exports["showDefaultValAM"] = showDefaultValAM;
  exports["showDefaultValTransactionAmountComputationMode"] = showDefaultValTransactionAmountComputationMode;
  exports["showDefaultValsRecord"] = showDefaultValsRecord;
  exports["showDefaultValsRLNil"] = showDefaultValsRLNil;
  exports["showDefaultValsRLCons"] = showDefaultValsRLCons;
})(PS["Optimus.Modules.Tmp.Client.Components.Settings.ShowDefaultVal"] = PS["Optimus.Modules.Tmp.Client.Components.Settings.ShowDefaultVal"] || {});

(function (exports) {
  // Generated by purs version 0.12.3
  "use strict";

  var Control_Alt = PS["Control.Alt"];
  var Control_Bind = PS["Control.Bind"];
  var Control_Semigroupoid = PS["Control.Semigroupoid"];
  var Data_Eq = PS["Data.Eq"];
  var Data_Foldable = PS["Data.Foldable"];
  var Data_Function = PS["Data.Function"];
  var Data_Functor = PS["Data.Functor"];
  var Data_Generic_Rep = PS["Data.Generic.Rep"];
  var Data_Maybe = PS["Data.Maybe"];
  var Data_Semigroup = PS["Data.Semigroup"];
  var Data_Symbol = PS["Data.Symbol"];
  var Effect = PS["Effect"];
  var Effect_Uncurried = PS["Effect.Uncurried"];
  var Optimus_Common_Client_Antd = PS["Optimus.Common.Client.Antd"];
  var Optimus_Common_Client_Antd_Select = PS["Optimus.Common.Client.Antd.Select"];
  var Optimus_Common_Utils_Default = PS["Optimus.Common.Utils.Default"];
  var Prelude = PS["Prelude"];
  var React_Basic = PS["React.Basic"];
  var React_Basic_DOM = PS["React.Basic.DOM"];
  var React_Basic_DOM_Events = PS["React.Basic.DOM.Events"];
  var React_Basic_DOM_Generated = PS["React.Basic.DOM.Generated"];
  var Type_Proxy = PS["Type.Proxy"];

  var SingleSelection = function SingleSelection(toSingleSelectionSpec) {
    this.toSingleSelectionSpec = toSingleSelectionSpec;
  };

  var toSingleSelectionSpec = function toSingleSelectionSpec(dict) {
    return dict.toSingleSelectionSpec;
  };

  var singleSelectionSum = function singleSelectionSum(dictSingleSelection) {
    return function (dictSingleSelection1) {
      return new SingleSelection(function (v) {
        var ro = toSingleSelectionSpec(dictSingleSelection1)(Type_Proxy["Proxy"].value);
        var lo = toSingleSelectionSpec(dictSingleSelection)(Type_Proxy["Proxy"].value);
        return {
          options: Data_Semigroup.append(Data_Semigroup.semigroupArray)(lo.options)(ro.options),
          reader: function reader(s) {
            return Control_Alt.alt(Data_Maybe.altMaybe)(Data_Functor.map(Data_Maybe.functorMaybe)(Data_Generic_Rep.Inl.create)(lo.reader(s)))(Data_Functor.map(Data_Maybe.functorMaybe)(Data_Generic_Rep.Inr.create)(ro.reader(s)));
          },
          writer: function writer(v1) {
            if (v1 instanceof Data_Generic_Rep.Inl) {
              return lo.writer(v1.value0);
            }

            ;

            if (v1 instanceof Data_Generic_Rep.Inr) {
              return ro.writer(v1.value0);
            }

            ;
            throw new Error("Failed pattern match at Optimus.Modules.Tmp.Client.Components.Settings.SingleSelection (line 44, column 13 - line 46, column 27): " + [v1.constructor.name]);
          }
        };
      });
    };
  };

  var singleSelectionConsArg = function singleSelectionConsArg(dictIsSymbol) {
    return function (dictDefault) {
      return new SingleSelection(function (v) {
        var option = Data_Symbol.reflectSymbol(dictIsSymbol)(Data_Symbol.SProxy.value);
        return {
          options: [option],
          reader: function reader(s) {
            var $16 = option === s;

            if ($16) {
              return Data_Maybe.Just.create(Optimus_Common_Utils_Default["default"](dictDefault));
            }

            ;
            return Data_Maybe.Nothing.value;
          },
          writer: function writer(rep) {
            return option;
          }
        };
      });
    };
  };

  var singleSelectionCons = function singleSelectionCons(dictIsSymbol) {
    return new SingleSelection(function (v) {
      var option = Data_Symbol.reflectSymbol(dictIsSymbol)(Data_Symbol.SProxy.value);
      return {
        options: [option],
        reader: function reader(s) {
          var $17 = option === s;

          if ($17) {
            return Data_Maybe.Just.create(Data_Generic_Rep.NoArguments.value);
          }

          ;
          return Data_Maybe.Nothing.value;
        },
        writer: function writer(rep) {
          return option;
        }
      };
    });
  };

  var genToSingleSelectionSpec = function genToSingleSelectionSpec(dictGeneric) {
    return function (dictSingleSelection) {
      return function (p) {
        return toSingleSelectionSpec(dictSingleSelection)(Type_Proxy["Proxy"].value);
      };
    };
  };

  var createSelect = function createSelect(dictGeneric) {
    return function (v) {
      return function (label) {
        return function (handler) {
          return function (spec) {
            return React_Basic_DOM_Generated.div_([React_Basic_DOM_Generated.label_([React_Basic_DOM.text(label)]), Optimus_Common_Client_Antd_Select.select()({
              children: Data_Functor.mapFlipped(Data_Functor.functorArray)(spec.options)(function (l) {
                return Optimus_Common_Client_Antd_Select.option()({
                  value: l,
                  children: [React_Basic_DOM.text(l)]
                });
              }),
              defaultValue: spec.writer(Data_Generic_Rep.from(dictGeneric)(v)),
              onChange: function onChange(newVal) {
                return Data_Foldable.for_(Effect.applicativeEffect)(Data_Foldable.foldableMaybe)(Data_Functor.mapFlipped(Data_Maybe.functorMaybe)(spec.reader(newVal))(Data_Generic_Rep.to(dictGeneric)))(handler)();
              }
            })]);
          };
        };
      };
    };
  };

  exports["SingleSelection"] = SingleSelection;
  exports["toSingleSelectionSpec"] = toSingleSelectionSpec;
  exports["genToSingleSelectionSpec"] = genToSingleSelectionSpec;
  exports["createSelect"] = createSelect;
  exports["singleSelectionCons"] = singleSelectionCons;
  exports["singleSelectionSum"] = singleSelectionSum;
  exports["singleSelectionConsArg"] = singleSelectionConsArg;
})(PS["Optimus.Modules.Tmp.Client.Components.Settings.SingleSelection"] = PS["Optimus.Modules.Tmp.Client.Components.Settings.SingleSelection"] || {});

(function (exports) {
  // Generated by purs version 0.12.3
  "use strict";

  var Control_Applicative = PS["Control.Applicative"];
  var Control_Semigroupoid = PS["Control.Semigroupoid"];
  var Data_Eq = PS["Data.Eq"];
  var Data_Foldable = PS["Data.Foldable"];
  var Data_Function = PS["Data.Function"];
  var Data_Functor = PS["Data.Functor"];
  var Data_Generic_Rep = PS["Data.Generic.Rep"];
  var Data_HeytingAlgebra = PS["Data.HeytingAlgebra"];
  var Data_Int = PS["Data.Int"];
  var Data_Long = PS["Data.Long"];
  var Data_Maybe = PS["Data.Maybe"];
  var Data_Semigroup = PS["Data.Semigroup"];
  var Data_Show = PS["Data.Show"];
  var Data_Symbol = PS["Data.Symbol"];
  var Effect = PS["Effect"];
  var Effect_Uncurried = PS["Effect.Uncurried"];
  var Optimus_Common_Client_Antd = PS["Optimus.Common.Client.Antd"];
  var Optimus_Common_Client_Antd_Grid = PS["Optimus.Common.Client.Antd.Grid"];
  var Optimus_Common_Client_Antd_Input = PS["Optimus.Common.Client.Antd.Input"];
  var Optimus_Common_Client_Antd_InputNumber = PS["Optimus.Common.Client.Antd.InputNumber"];
  var Optimus_Common_Client_Antd_Radio = PS["Optimus.Common.Client.Antd.Radio"];
  var Optimus_Common_Client_Components_Card = PS["Optimus.Common.Client.Components.Card"];
  var Optimus_Common_Client_Components_InfoPanel = PS["Optimus.Common.Client.Components.InfoPanel"];
  var Optimus_Common_Client_Components_Section = PS["Optimus.Common.Client.Components.Section"];
  var Optimus_Common_Client_InfoShow = PS["Optimus.Common.Client.InfoShow"];
  var Optimus_Common_Utils_Default = PS["Optimus.Common.Utils.Default"];
  var Optimus_Modules_Tmp_Client_Components_Settings_ShowDefaultVal = PS["Optimus.Modules.Tmp.Client.Components.Settings.ShowDefaultVal"];
  var Optimus_Modules_Tmp_Client_Components_Settings_SingleSelection = PS["Optimus.Modules.Tmp.Client.Components.Settings.SingleSelection"];
  var Optimus_Modules_Tmp_Client_Components_Settings_Utils = PS["Optimus.Modules.Tmp.Client.Components.Settings.Utils"];
  var Prelude = PS["Prelude"];
  var React_Basic = PS["React.Basic"];
  var React_Basic_DOM = PS["React.Basic.DOM"];
  var React_Basic_DOM_Events = PS["React.Basic.DOM.Events"];
  var React_Basic_DOM_Generated = PS["React.Basic.DOM.Generated"];
  var React_Basic_Events = PS["React.Basic.Events"];
  var Record = PS["Record"];
  var Type_Data_RowList = PS["Type.Data.RowList"];
  var Type_Proxy = PS["Type.Proxy"];
  var ZRPC_Clients_Mercanti = PS["ZRPC.Clients.Mercanti"];
  var ZRPC_Types_Millis = PS["ZRPC.Types.Millis"];

  var SettingInput = function SettingInput(Default0, toSettingInput) {
    this.Default0 = Default0;
    this.toSettingInput = toSettingInput;
  };

  var SettingInputs = function SettingInputs(toSettingInputs) {
    this.toSettingInputs = toSettingInputs;
  };

  var SettingInputsRL = function SettingInputsRL(toSettingInputsRL) {
    this.toSettingInputsRL = toSettingInputsRL;
  };

  var SingleSelectionFields = function SingleSelectionFields(toSingleSelectionFields) {
    this.toSingleSelectionFields = toSingleSelectionFields;
  };

  var toSingleSelectionFields = function toSingleSelectionFields(dict) {
    return dict.toSingleSelectionFields;
  };

  var toSettingInputsRL = function toSettingInputsRL(dict) {
    return dict.toSettingInputsRL;
  };

  var toSettingInputsRecord = function toSettingInputsRecord(dictRowToList) {
    return function (dictSettingInputsRL) {
      return new SettingInputs(function (r) {
        return toSettingInputsRL(dictSettingInputsRL)(Type_Data_RowList.RLProxy.value)(r);
      });
    };
  };

  var toSettingInputsNil = new SettingInputsRL(function (v) {
    return function (v1) {
      return function (r) {
        return [];
      };
    };
  });

  var toSettingInputs = function toSettingInputs(dict) {
    return dict.toSettingInputs;
  };

  var toSettingInputString = new SettingInput(function () {
    return Optimus_Common_Utils_Default.defaultString;
  }, function (v) {
    return function (label) {
      return function (handler) {
        return React_Basic_DOM_Generated.div_([React_Basic_DOM_Generated.label_([React_Basic_DOM.text(label)]), Optimus_Common_Client_Antd_Input.input()({
          value: v,
          onChange: React_Basic_DOM_Events.capture(React_Basic_Events.merge()(React_Basic_Events.mergeCons(new Data_Symbol.IsSymbol(function () {
            return "targetValue";
          }))()()()()(React_Basic_Events.mergeNil))({
            targetValue: React_Basic_DOM_Events.targetValue
          }))(function (v1) {
            return handler(Data_Maybe.fromMaybe(Optimus_Common_Utils_Default["default"](Optimus_Common_Utils_Default.defaultString))(v1.targetValue));
          })
        })]);
      };
    };
  });

  var toSettingInputRecord = function toSettingInputRecord(dictSettingInputs) {
    return function (dictDefaults) {
      return new SettingInput(function () {
        return Optimus_Common_Utils_Default.defaultRecord(dictDefaults);
      }, function (v) {
        return function (label) {
          return function (handler) {
            return React_Basic_DOM_Generated.div_([React_Basic_DOM_Generated.label_([React_Basic_DOM.text(label)]), React_Basic_DOM_Generated.div_(toSettingInputs(dictSettingInputs)(handler)(v))]);
          };
        };
      });
    };
  };

  var toSettingInputMillis = new SettingInput(function () {
    return Optimus_Common_Utils_Default.defaultMillis;
  }, function (v) {
    return function (label) {
      return function (handler) {
        return React_Basic_DOM_Generated.div_([React_Basic_DOM_Generated.label_([React_Basic_DOM.text(label)]), Optimus_Common_Client_Antd_InputNumber.inputNumber()({
          value: Data_Long.toString(v),
          onChange: function onChange($52) {
            return Data_Foldable.traverse_(Effect.applicativeEffect)(Data_Foldable.foldableMaybe)(handler)(Data_Functor.map(Data_Maybe.functorMaybe)(ZRPC_Types_Millis.Millis)(Data_Long.fromString($52)))();
          }
        }), React_Basic_DOM.text("ms")]);
      };
    };
  });
  var toSettingInputInt = new SettingInput(function () {
    return Optimus_Common_Utils_Default.defaultInt;
  }, function (v) {
    return function (label) {
      return function (handler) {
        return React_Basic_DOM_Generated.div_([React_Basic_DOM_Generated.label_([React_Basic_DOM.text(label)]), Optimus_Common_Client_Antd_InputNumber.inputNumber()({
          value: Data_Show.show(Data_Show.showInt)(v),
          onChange: function onChange($53) {
            return Data_Foldable.traverse_(Effect.applicativeEffect)(Data_Foldable.foldableMaybe)(handler)(Data_Int.fromString($53))();
          }
        })]);
      };
    };
  });
  var toSettingInputBool = new SettingInput(function () {
    return Optimus_Common_Utils_Default.defaultBoolean;
  }, function (v) {
    return function (label) {
      return function (handler) {
        return React_Basic_DOM_Generated.div_([React_Basic_DOM_Generated.label_([React_Basic_DOM.text(label)]), React_Basic_DOM_Generated.div_([React_Basic_DOM_Generated.div_([Optimus_Common_Client_Antd_Radio.radio()({
          checked: v,
          onChange: React_Basic_Events.handler_(handler(true)),
          children: [React_Basic_DOM.text("Yes")]
        })]), React_Basic_DOM_Generated.div_([Optimus_Common_Client_Antd_Radio.radio()({
          checked: !v,
          onChange: React_Basic_Events.handler_(handler(false)),
          children: [React_Basic_DOM.text("No")]
        })])])]);
      };
    };
  });

  var toSettingInput = function toSettingInput(dict) {
    return dict.toSettingInput;
  };

  var toSettingInputMaybe = function toSettingInputMaybe(dictDefault) {
    return function (dictSettingInput) {
      return new SettingInput(function () {
        return Optimus_Common_Utils_Default.defaultMaybe;
      }, function (v) {
        return function (label) {
          return function (handler) {
            if (v instanceof Data_Maybe.Just) {
              return toSettingInput(dictSettingInput)(v.value0)(label)(function ($54) {
                return handler(Control_Applicative.pure(Data_Maybe.applicativeMaybe)($54));
              });
            }

            ;

            if (v instanceof Data_Maybe.Nothing) {
              return toSettingInput(dictSettingInput)(Optimus_Common_Utils_Default["default"](dictDefault))(label)(function ($55) {
                return handler(Control_Applicative.pure(Data_Maybe.applicativeMaybe)($55));
              });
            }

            ;
            throw new Error("Failed pattern match at Optimus.Modules.Tmp.Client.Components.Settings.SettingInput (line 54, column 1 - line 54, column 87): " + [v.constructor.name, label.constructor.name, handler.constructor.name]);
          };
        };
      });
    };
  };

  var toSettingInputSettingFormState$prime = function toSettingInputSettingFormState$prime(dictSettingInput) {
    return function (dictShowDefaultVal) {
      return new SettingInput(function () {
        return Optimus_Common_Utils_Default.defaultSFS(dictSettingInput.Default0());
      }, function (v) {
        return function (label) {
          return function (handler) {
            var settingSelectionRadio = function settingSelectionRadio(ss) {
              return Optimus_Common_Client_Antd_Radio.radio()({
                checked: Data_Eq.eq(Optimus_Modules_Tmp_Client_Components_Settings_Utils.settingSelectionEq)(v.selected)(ss),
                onChange: React_Basic_Events.handler_(handler({
                  selected: ss,
                  "default": v["default"],
                  fixed: v.fixed
                })),
                value: Data_Show.show(Optimus_Modules_Tmp_Client_Components_Settings_Utils.settingSelectionShow)(ss),
                children: [React_Basic_DOM.text(Data_Show.show(Optimus_Modules_Tmp_Client_Components_Settings_Utils.settingSelectionShow)(ss))]
              });
            };

            return Optimus_Common_Client_Components_Card.card([Optimus_Common_Client_Components_Section.section(Optimus_Common_Client_Components_InfoPanel.renderText(Optimus_Common_Client_InfoShow.infoShowString)(label))(React_Basic_DOM_Generated.div_([Optimus_Common_Client_Antd_Grid.row_([settingSelectionRadio(Optimus_Modules_Tmp_Client_Components_Settings_Utils.Default.value), React_Basic_DOM_Generated.div_([React_Basic_DOM_Generated.span_([React_Basic_DOM.text("Provider:" + Data_Show.show(ZRPC_Clients_Mercanti.showProvider)(v["default"].provider))]), React_Basic_DOM_Generated.br()({}), Data_Semigroup.append(React_Basic.semigroupJSX)(React_Basic_DOM_Generated.span_([React_Basic_DOM.text("Value:")]))(React_Basic.fragment([Optimus_Modules_Tmp_Client_Components_Settings_ShowDefaultVal.showDefaultVal(dictShowDefaultVal)(v["default"].value)]))])]), Optimus_Common_Client_Antd_Grid.row_([settingSelectionRadio(Optimus_Modules_Tmp_Client_Components_Settings_Utils.Fixed.value), Optimus_Common_Client_Antd_Grid.row_([Optimus_Common_Client_Antd_Grid.col()({
              offset: 1,
              children: [toSettingInput(toSettingInputMaybe(dictSettingInput.Default0())(dictSettingInput))(v.fixed)(label)(function (newFixed) {
                return handler({
                  selected: v.selected,
                  "default": v["default"],
                  fixed: newFixed
                });
              })]
            })])])]))]);
          };
        };
      });
    };
  };

  var toSettingInputsRLConsSFS = function toSettingInputsRLConsSFS(dictIsSymbol) {
    return function (dictSettingInput) {
      return function (dictCons) {
        return function (dictSettingInputsRL) {
          return new SettingInputsRL(function (v) {
            return function (updater) {
              return function (r) {
                var tailInputCs = toSettingInputsRL(dictSettingInputsRL)(Type_Data_RowList.RLProxy.value)(updater)(r);
                var v1 = Record.get(dictIsSymbol)(dictCons)(Data_Symbol.SProxy.value)(r);

                var indivUpdater = function indivUpdater(newV) {
                  return updater(Record.set(dictIsSymbol)(dictCons)(dictCons)(Data_Symbol.SProxy.value)(newV)(r));
                };

                return Data_Semigroup.append(Data_Semigroup.semigroupArray)(tailInputCs)([toSettingInput(dictSettingInput)(v1)(Data_Symbol.reflectSymbol(dictIsSymbol)(Data_Symbol.SProxy.value))(indivUpdater)]);
              };
            };
          });
        };
      };
    };
  };

  var ssFieldsSum = function ssFieldsSum(dictSingleSelectionFields) {
    return function (dictSingleSelectionFields1) {
      return new SingleSelectionFields(function (v) {
        return function (handler) {
          if (v instanceof Data_Generic_Rep.Inl) {
            return toSingleSelectionFields(dictSingleSelectionFields)(v.value0)(function (newV) {
              return handler(new Data_Generic_Rep.Inl(newV));
            });
          }

          ;

          if (v instanceof Data_Generic_Rep.Inr) {
            return toSingleSelectionFields(dictSingleSelectionFields1)(v.value0)(function (newV) {
              return handler(new Data_Generic_Rep.Inr(newV));
            });
          }

          ;
          throw new Error("Failed pattern match at Optimus.Modules.Tmp.Client.Components.Settings.SettingInput (line 224, column 1 - line 227, column 39): " + [v.constructor.name, handler.constructor.name]);
        };
      });
    };
  };

  var ssFieldsConsNoArg = function ssFieldsConsNoArg(dictIsSymbol) {
    return new SingleSelectionFields(function (v) {
      return function (v1) {
        return React_Basic.fragment([]);
      };
    });
  };

  var ssFieldsConsArgR = function ssFieldsConsArgR(dictIsSymbol) {
    return function (dictDefaults) {
      return function (dictSettingInputs) {
        return new SingleSelectionFields(function (v) {
          return function (handler) {
            var inputs = toSettingInputs(dictSettingInputs)(function (newV) {
              return handler(newV);
            })(v);
            return React_Basic_DOM_Generated.div_(inputs);
          };
        });
      };
    };
  };

  var genSingleSelectionFields = function genSingleSelectionFields(dictGeneric) {
    return function (dictSingleSelectionFields) {
      return function (v) {
        return function (handler) {
          return toSingleSelectionFields(dictSingleSelectionFields)(Data_Generic_Rep.from(dictGeneric)(v))(function ($56) {
            return handler(Data_Generic_Rep.to(dictGeneric)($56));
          });
        };
      };
    };
  };

  var genSingleSelectionSI = function genSingleSelectionSI(dictGeneric) {
    return function (dictSingleSelection) {
      return function (dictSingleSelectionFields) {
        return function (v) {
          return function (label) {
            return function (handler) {
              var spec = Optimus_Modules_Tmp_Client_Components_Settings_SingleSelection.genToSingleSelectionSpec(dictGeneric)(dictSingleSelection)(Type_Proxy["Proxy"].value);
              var inputs = genSingleSelectionFields(dictGeneric)(dictSingleSelectionFields)(v)(handler);
              var dropdown = Optimus_Modules_Tmp_Client_Components_Settings_SingleSelection.createSelect(dictGeneric)(v)(label)(handler)(spec);
              return React_Basic_DOM_Generated.div_([dropdown, inputs]);
            };
          };
        };
      };
    };
  };

  var toSettingInputAuthorizationMode = new SettingInput(function () {
    return Optimus_Common_Utils_Default.defaultAuthMode;
  }, genSingleSelectionSI(ZRPC_Clients_Mercanti.genericAuthorizationMode)(Optimus_Modules_Tmp_Client_Components_Settings_SingleSelection.singleSelectionSum(Optimus_Modules_Tmp_Client_Components_Settings_SingleSelection.singleSelectionCons(new Data_Symbol.IsSymbol(function () {
    return "AuthorizationModePin";
  })))(Optimus_Modules_Tmp_Client_Components_Settings_SingleSelection.singleSelectionSum(Optimus_Modules_Tmp_Client_Components_Settings_SingleSelection.singleSelectionCons(new Data_Symbol.IsSymbol(function () {
    return "AuthorizationModeOtp";
  })))(Optimus_Modules_Tmp_Client_Components_Settings_SingleSelection.singleSelectionSum(Optimus_Modules_Tmp_Client_Components_Settings_SingleSelection.singleSelectionCons(new Data_Symbol.IsSymbol(function () {
    return "AuthorizationModePinOtp";
  })))(Optimus_Modules_Tmp_Client_Components_Settings_SingleSelection.singleSelectionCons(new Data_Symbol.IsSymbol(function () {
    return "AuthorizationModeNoAuth";
  }))))))(ssFieldsSum(ssFieldsConsNoArg(new Data_Symbol.IsSymbol(function () {
    return "AuthorizationModePin";
  })))(ssFieldsSum(ssFieldsConsNoArg(new Data_Symbol.IsSymbol(function () {
    return "AuthorizationModeOtp";
  })))(ssFieldsSum(ssFieldsConsNoArg(new Data_Symbol.IsSymbol(function () {
    return "AuthorizationModePinOtp";
  })))(ssFieldsConsNoArg(new Data_Symbol.IsSymbol(function () {
    return "AuthorizationModeNoAuth";
  })))))));
  var toSettingInputLinkTagVM = new SettingInput(function () {
    return Optimus_Common_Utils_Default.defaultLinkTagVM;
  }, genSingleSelectionSI(ZRPC_Clients_Mercanti.genericLinkTagVerificationMode)(Optimus_Modules_Tmp_Client_Components_Settings_SingleSelection.singleSelectionSum(Optimus_Modules_Tmp_Client_Components_Settings_SingleSelection.singleSelectionCons(new Data_Symbol.IsSymbol(function () {
    return "LinkTagVerificationModeRequired";
  })))(Optimus_Modules_Tmp_Client_Components_Settings_SingleSelection.singleSelectionSum(Optimus_Modules_Tmp_Client_Components_Settings_SingleSelection.singleSelectionCons(new Data_Symbol.IsSymbol(function () {
    return "LinkTagVerificationModeDisabled";
  })))(Optimus_Modules_Tmp_Client_Components_Settings_SingleSelection.singleSelectionCons(new Data_Symbol.IsSymbol(function () {
    return "LinkTagVerificationModeOptional";
  })))))(ssFieldsSum(ssFieldsConsNoArg(new Data_Symbol.IsSymbol(function () {
    return "LinkTagVerificationModeRequired";
  })))(ssFieldsSum(ssFieldsConsNoArg(new Data_Symbol.IsSymbol(function () {
    return "LinkTagVerificationModeDisabled";
  })))(ssFieldsConsNoArg(new Data_Symbol.IsSymbol(function () {
    return "LinkTagVerificationModeOptional";
  }))))));
  var toSettingInputRoundingMode = new SettingInput(function () {
    return Optimus_Common_Utils_Default.defaultRoundingMode;
  }, genSingleSelectionSI(ZRPC_Clients_Mercanti.genericRoundingMode)(Optimus_Modules_Tmp_Client_Components_Settings_SingleSelection.singleSelectionSum(Optimus_Modules_Tmp_Client_Components_Settings_SingleSelection.singleSelectionCons(new Data_Symbol.IsSymbol(function () {
    return "RoundingModeHalfUp";
  })))(Optimus_Modules_Tmp_Client_Components_Settings_SingleSelection.singleSelectionSum(Optimus_Modules_Tmp_Client_Components_Settings_SingleSelection.singleSelectionCons(new Data_Symbol.IsSymbol(function () {
    return "RoundingModeHalfDown";
  })))(Optimus_Modules_Tmp_Client_Components_Settings_SingleSelection.singleSelectionSum(Optimus_Modules_Tmp_Client_Components_Settings_SingleSelection.singleSelectionCons(new Data_Symbol.IsSymbol(function () {
    return "RoundingModeFloor";
  })))(Optimus_Modules_Tmp_Client_Components_Settings_SingleSelection.singleSelectionCons(new Data_Symbol.IsSymbol(function () {
    return "RoundingModeCeiling";
  }))))))(ssFieldsSum(ssFieldsConsNoArg(new Data_Symbol.IsSymbol(function () {
    return "RoundingModeHalfUp";
  })))(ssFieldsSum(ssFieldsConsNoArg(new Data_Symbol.IsSymbol(function () {
    return "RoundingModeHalfDown";
  })))(ssFieldsSum(ssFieldsConsNoArg(new Data_Symbol.IsSymbol(function () {
    return "RoundingModeFloor";
  })))(ssFieldsConsNoArg(new Data_Symbol.IsSymbol(function () {
    return "RoundingModeCeiling";
  })))))));
  var toSettingInputTransactionAmountComputationMode = new SettingInput(function () {
    return Optimus_Common_Utils_Default.defaultTransactionAmountComputationMode;
  }, genSingleSelectionSI(ZRPC_Clients_Mercanti.genericTransactionAmountComputationMode)(Optimus_Modules_Tmp_Client_Components_Settings_SingleSelection.singleSelectionSum(Optimus_Modules_Tmp_Client_Components_Settings_SingleSelection.singleSelectionCons(new Data_Symbol.IsSymbol(function () {
    return "TransactionAmountComputationModeUseSaleAmount";
  })))(Optimus_Modules_Tmp_Client_Components_Settings_SingleSelection.singleSelectionConsArg(new Data_Symbol.IsSymbol(function () {
    return "TransactionAmountComputationModeDeriveFromSku";
  }))(Optimus_Common_Utils_Default.defaultRecord(Optimus_Common_Utils_Default.defaultsRecord()(Optimus_Common_Utils_Default.defaultsRLCons(new Data_Symbol.IsSymbol(function () {
    return "isDiscountedExcluded";
  }))(Optimus_Common_Utils_Default.defaultBoolean)(Optimus_Common_Utils_Default.defaultsRLNil)()())))))(ssFieldsSum(ssFieldsConsNoArg(new Data_Symbol.IsSymbol(function () {
    return "TransactionAmountComputationModeUseSaleAmount";
  })))(ssFieldsConsArgR(new Data_Symbol.IsSymbol(function () {
    return "TransactionAmountComputationModeDeriveFromSku";
  }))(Optimus_Common_Utils_Default.defaultsRecord()(Optimus_Common_Utils_Default.defaultsRLCons(new Data_Symbol.IsSymbol(function () {
    return "isDiscountedExcluded";
  }))(Optimus_Common_Utils_Default.defaultBoolean)(Optimus_Common_Utils_Default.defaultsRLNil)()()))(toSettingInputsRecord()(toSettingInputsRLConsSFS(new Data_Symbol.IsSymbol(function () {
    return "isDiscountedExcluded";
  }))(toSettingInputBool)()(toSettingInputsNil))))));
  exports["SettingInput"] = SettingInput;
  exports["toSettingInput"] = toSettingInput;
  exports["SettingInputs"] = SettingInputs;
  exports["toSettingInputs"] = toSettingInputs;
  exports["SettingInputsRL"] = SettingInputsRL;
  exports["toSettingInputsRL"] = toSettingInputsRL;
  exports["toSettingInputMaybe"] = toSettingInputMaybe;
  exports["toSettingInputString"] = toSettingInputString;
  exports["toSettingInputInt"] = toSettingInputInt;
  exports["toSettingInputMillis"] = toSettingInputMillis;
  exports["toSettingInputBool"] = toSettingInputBool;
  exports["toSettingInputRecord"] = toSettingInputRecord;
  exports["toSettingInputRoundingMode"] = toSettingInputRoundingMode;
  exports["toSettingInputLinkTagVM"] = toSettingInputLinkTagVM;
  exports["toSettingInputAuthorizationMode"] = toSettingInputAuthorizationMode;
  exports["toSettingInputTransactionAmountComputationMode"] = toSettingInputTransactionAmountComputationMode;
  exports["toSettingInputsRecord"] = toSettingInputsRecord;
  exports["toSettingInputsNil"] = toSettingInputsNil;
  exports["toSettingInputsRLConsSFS"] = toSettingInputsRLConsSFS;
  exports["toSettingInputSettingFormState'"] = toSettingInputSettingFormState$prime;
})(PS["Optimus.Modules.Tmp.Client.Components.Settings.SettingInput"] = PS["Optimus.Modules.Tmp.Client.Components.Settings.SettingInput"] || {});

(function (exports) {
  // Generated by purs version 0.12.3
  "use strict";

  var Control_Semigroupoid = PS["Control.Semigroupoid"];
  var Data_Function = PS["Data.Function"];
  var Data_Symbol = PS["Data.Symbol"];
  var Effect = PS["Effect"];
  var Optimus_Common_Client_Antd = PS["Optimus.Common.Client.Antd"];
  var Optimus_Common_Client_Antd_Button = PS["Optimus.Common.Client.Antd.Button"];
  var Optimus_Common_Client_Antd_Grid = PS["Optimus.Common.Client.Antd.Grid"];
  var Optimus_Common_Utils_Default = PS["Optimus.Common.Utils.Default"];
  var Optimus_Modules_Tmp_Client_Components_Settings_SettingInput = PS["Optimus.Modules.Tmp.Client.Components.Settings.SettingInput"];
  var Optimus_Modules_Tmp_Client_Components_Settings_ShowDefaultVal = PS["Optimus.Modules.Tmp.Client.Components.Settings.ShowDefaultVal"];
  var Optimus_Modules_Tmp_Client_Components_Settings_Utils = PS["Optimus.Modules.Tmp.Client.Components.Settings.Utils"];
  var Prelude = PS["Prelude"];
  var React_Basic = PS["React.Basic"];
  var React_Basic_DOM = PS["React.Basic.DOM"];
  var React_Basic_DOM_Events = PS["React.Basic.DOM.Events"];
  var React_Basic_DOM_Generated = PS["React.Basic.DOM.Generated"];
  var Type_Data_Boolean = PS["Type.Data.Boolean"];
  var ZRPC_Clients_Mercanti = PS["ZRPC.Clients.Mercanti"];
  var component = React_Basic.createComponent("SettingsForm");

  var settingsForm = function settingsForm(props) {
    var render = function render(self) {
      return React_Basic_DOM_Generated.form()({
        children: [Optimus_Common_Client_Antd_Grid.row_(Optimus_Modules_Tmp_Client_Components_Settings_SettingInput.toSettingInputs(Optimus_Modules_Tmp_Client_Components_Settings_SettingInput.toSettingInputsRecord()(Optimus_Modules_Tmp_Client_Components_Settings_SettingInput.toSettingInputsRLConsSFS(new Data_Symbol.IsSymbol(function () {
          return "checkBalanceAuthenticationMode";
        }))(Optimus_Modules_Tmp_Client_Components_Settings_SettingInput["toSettingInputSettingFormState'"](Optimus_Modules_Tmp_Client_Components_Settings_SettingInput.toSettingInputAuthorizationMode)(Optimus_Modules_Tmp_Client_Components_Settings_ShowDefaultVal.showDefaultValAM))()(Optimus_Modules_Tmp_Client_Components_Settings_SettingInput.toSettingInputsRLConsSFS(new Data_Symbol.IsSymbol(function () {
          return "clearingPeriod";
        }))(Optimus_Modules_Tmp_Client_Components_Settings_SettingInput["toSettingInputSettingFormState'"](Optimus_Modules_Tmp_Client_Components_Settings_SettingInput.toSettingInputMillis)(Optimus_Modules_Tmp_Client_Components_Settings_ShowDefaultVal.showDefaultValMillis))()(Optimus_Modules_Tmp_Client_Components_Settings_SettingInput.toSettingInputsRLConsSFS(new Data_Symbol.IsSymbol(function () {
          return "countryAlpha2";
        }))(Optimus_Modules_Tmp_Client_Components_Settings_SettingInput["toSettingInputSettingFormState'"](Optimus_Modules_Tmp_Client_Components_Settings_SettingInput.toSettingInputString)(Optimus_Modules_Tmp_Client_Components_Settings_ShowDefaultVal.showDefaultValString))()(Optimus_Modules_Tmp_Client_Components_Settings_SettingInput.toSettingInputsRLConsSFS(new Data_Symbol.IsSymbol(function () {
          return "defaultLocation";
        }))(Optimus_Modules_Tmp_Client_Components_Settings_SettingInput["toSettingInputSettingFormState'"](Optimus_Modules_Tmp_Client_Components_Settings_SettingInput.toSettingInputString)(Optimus_Modules_Tmp_Client_Components_Settings_ShowDefaultVal.showDefaultValString))()(Optimus_Modules_Tmp_Client_Components_Settings_SettingInput.toSettingInputsRLConsSFS(new Data_Symbol.IsSymbol(function () {
          return "hasCashbackOnRedemptionOverflow";
        }))(Optimus_Modules_Tmp_Client_Components_Settings_SettingInput["toSettingInputSettingFormState'"](Optimus_Modules_Tmp_Client_Components_Settings_SettingInput.toSettingInputBool)(Optimus_Modules_Tmp_Client_Components_Settings_ShowDefaultVal.showDefaultBoolean))()(Optimus_Modules_Tmp_Client_Components_Settings_SettingInput.toSettingInputsRLConsSFS(new Data_Symbol.IsSymbol(function () {
          return "isClearedVoidable";
        }))(Optimus_Modules_Tmp_Client_Components_Settings_SettingInput["toSettingInputSettingFormState'"](Optimus_Modules_Tmp_Client_Components_Settings_SettingInput.toSettingInputBool)(Optimus_Modules_Tmp_Client_Components_Settings_ShowDefaultVal.showDefaultBoolean))()(Optimus_Modules_Tmp_Client_Components_Settings_SettingInput.toSettingInputsRLConsSFS(new Data_Symbol.IsSymbol(function () {
          return "isEarnEmailReceiptEnabled";
        }))(Optimus_Modules_Tmp_Client_Components_Settings_SettingInput["toSettingInputSettingFormState'"](Optimus_Modules_Tmp_Client_Components_Settings_SettingInput.toSettingInputBool)(Optimus_Modules_Tmp_Client_Components_Settings_ShowDefaultVal.showDefaultBoolean))()(Optimus_Modules_Tmp_Client_Components_Settings_SettingInput.toSettingInputsRLConsSFS(new Data_Symbol.IsSymbol(function () {
          return "isEarnSmsReceiptEnabled";
        }))(Optimus_Modules_Tmp_Client_Components_Settings_SettingInput["toSettingInputSettingFormState'"](Optimus_Modules_Tmp_Client_Components_Settings_SettingInput.toSettingInputBool)(Optimus_Modules_Tmp_Client_Components_Settings_ShowDefaultVal.showDefaultBoolean))()(Optimus_Modules_Tmp_Client_Components_Settings_SettingInput.toSettingInputsRLConsSFS(new Data_Symbol.IsSymbol(function () {
          return "isIdMethodByUnverifiedTagAllowed";
        }))(Optimus_Modules_Tmp_Client_Components_Settings_SettingInput["toSettingInputSettingFormState'"](Optimus_Modules_Tmp_Client_Components_Settings_SettingInput.toSettingInputBool)(Optimus_Modules_Tmp_Client_Components_Settings_ShowDefaultVal.showDefaultBoolean))()(Optimus_Modules_Tmp_Client_Components_Settings_SettingInput.toSettingInputsRLConsSFS(new Data_Symbol.IsSymbol(function () {
          return "isLinkCardOnRegistrationRequired";
        }))(Optimus_Modules_Tmp_Client_Components_Settings_SettingInput["toSettingInputSettingFormState'"](Optimus_Modules_Tmp_Client_Components_Settings_SettingInput.toSettingInputBool)(Optimus_Modules_Tmp_Client_Components_Settings_ShowDefaultVal.showDefaultBoolean))()(Optimus_Modules_Tmp_Client_Components_Settings_SettingInput.toSettingInputsRLConsSFS(new Data_Symbol.IsSymbol(function () {
          return "isPayInCashEnabled";
        }))(Optimus_Modules_Tmp_Client_Components_Settings_SettingInput["toSettingInputSettingFormState'"](Optimus_Modules_Tmp_Client_Components_Settings_SettingInput.toSettingInputBool)(Optimus_Modules_Tmp_Client_Components_Settings_ShowDefaultVal.showDefaultBoolean))()(Optimus_Modules_Tmp_Client_Components_Settings_SettingInput.toSettingInputsRLConsSFS(new Data_Symbol.IsSymbol(function () {
          return "isProfileOnEarnRequired";
        }))(Optimus_Modules_Tmp_Client_Components_Settings_SettingInput["toSettingInputSettingFormState'"](Optimus_Modules_Tmp_Client_Components_Settings_SettingInput.toSettingInputBool)(Optimus_Modules_Tmp_Client_Components_Settings_ShowDefaultVal.showDefaultBoolean))()(Optimus_Modules_Tmp_Client_Components_Settings_SettingInput.toSettingInputsRLConsSFS(new Data_Symbol.IsSymbol(function () {
          return "isProfileOnRedeemRequired";
        }))(Optimus_Modules_Tmp_Client_Components_Settings_SettingInput["toSettingInputSettingFormState'"](Optimus_Modules_Tmp_Client_Components_Settings_SettingInput.toSettingInputBool)(Optimus_Modules_Tmp_Client_Components_Settings_ShowDefaultVal.showDefaultBoolean))()(Optimus_Modules_Tmp_Client_Components_Settings_SettingInput.toSettingInputsRLConsSFS(new Data_Symbol.IsSymbol(function () {
          return "isQuickRedeemEnabled";
        }))(Optimus_Modules_Tmp_Client_Components_Settings_SettingInput["toSettingInputSettingFormState'"](Optimus_Modules_Tmp_Client_Components_Settings_SettingInput.toSettingInputBool)(Optimus_Modules_Tmp_Client_Components_Settings_ShowDefaultVal.showDefaultBoolean))()(Optimus_Modules_Tmp_Client_Components_Settings_SettingInput.toSettingInputsRLConsSFS(new Data_Symbol.IsSymbol(function () {
          return "isRedeemEmailReceiptEnabled";
        }))(Optimus_Modules_Tmp_Client_Components_Settings_SettingInput["toSettingInputSettingFormState'"](Optimus_Modules_Tmp_Client_Components_Settings_SettingInput.toSettingInputBool)(Optimus_Modules_Tmp_Client_Components_Settings_ShowDefaultVal.showDefaultBoolean))()(Optimus_Modules_Tmp_Client_Components_Settings_SettingInput.toSettingInputsRLConsSFS(new Data_Symbol.IsSymbol(function () {
          return "isRedeemSmsReceiptEnabled";
        }))(Optimus_Modules_Tmp_Client_Components_Settings_SettingInput["toSettingInputSettingFormState'"](Optimus_Modules_Tmp_Client_Components_Settings_SettingInput.toSettingInputBool)(Optimus_Modules_Tmp_Client_Components_Settings_ShowDefaultVal.showDefaultBoolean))()(Optimus_Modules_Tmp_Client_Components_Settings_SettingInput.toSettingInputsRLConsSFS(new Data_Symbol.IsSymbol(function () {
          return "linkTagVerificationMode";
        }))(Optimus_Modules_Tmp_Client_Components_Settings_SettingInput["toSettingInputSettingFormState'"](Optimus_Modules_Tmp_Client_Components_Settings_SettingInput.toSettingInputLinkTagVM)(Optimus_Modules_Tmp_Client_Components_Settings_ShowDefaultVal.showDefaultValLTVM))()(Optimus_Modules_Tmp_Client_Components_Settings_SettingInput.toSettingInputsRLConsSFS(new Data_Symbol.IsSymbol(function () {
          return "phoneNumberPrefix";
        }))(Optimus_Modules_Tmp_Client_Components_Settings_SettingInput["toSettingInputSettingFormState'"](Optimus_Modules_Tmp_Client_Components_Settings_SettingInput.toSettingInputString)(Optimus_Modules_Tmp_Client_Components_Settings_ShowDefaultVal.showDefaultValString))()(Optimus_Modules_Tmp_Client_Components_Settings_SettingInput.toSettingInputsRLConsSFS(new Data_Symbol.IsSymbol(function () {
          return "posSetUp";
        }))(Optimus_Modules_Tmp_Client_Components_Settings_SettingInput["toSettingInputSettingFormState'"](Optimus_Modules_Tmp_Client_Components_Settings_SettingInput.toSettingInputRecord(Optimus_Modules_Tmp_Client_Components_Settings_SettingInput.toSettingInputsRecord()(Optimus_Modules_Tmp_Client_Components_Settings_SettingInput.toSettingInputsRLConsSFS(new Data_Symbol.IsSymbol(function () {
          return "backgroundColor";
        }))(Optimus_Modules_Tmp_Client_Components_Settings_SettingInput.toSettingInputString)()(Optimus_Modules_Tmp_Client_Components_Settings_SettingInput.toSettingInputsRLConsSFS(new Data_Symbol.IsSymbol(function () {
          return "backgroundUrl";
        }))(Optimus_Modules_Tmp_Client_Components_Settings_SettingInput.toSettingInputString)()(Optimus_Modules_Tmp_Client_Components_Settings_SettingInput.toSettingInputsRLConsSFS(new Data_Symbol.IsSymbol(function () {
          return "foregroundColor";
        }))(Optimus_Modules_Tmp_Client_Components_Settings_SettingInput.toSettingInputString)()(Optimus_Modules_Tmp_Client_Components_Settings_SettingInput.toSettingInputsRLConsSFS(new Data_Symbol.IsSymbol(function () {
          return "logoUrl";
        }))(Optimus_Modules_Tmp_Client_Components_Settings_SettingInput.toSettingInputString)()(Optimus_Modules_Tmp_Client_Components_Settings_SettingInput.toSettingInputsNil))))))(Optimus_Common_Utils_Default.defaultsRecord()(Optimus_Common_Utils_Default.defaultsRLCons(new Data_Symbol.IsSymbol(function () {
          return "backgroundColor";
        }))(Optimus_Common_Utils_Default.defaultString)(Optimus_Common_Utils_Default.defaultsRLCons(new Data_Symbol.IsSymbol(function () {
          return "backgroundUrl";
        }))(Optimus_Common_Utils_Default.defaultString)(Optimus_Common_Utils_Default.defaultsRLCons(new Data_Symbol.IsSymbol(function () {
          return "foregroundColor";
        }))(Optimus_Common_Utils_Default.defaultString)(Optimus_Common_Utils_Default.defaultsRLCons(new Data_Symbol.IsSymbol(function () {
          return "logoUrl";
        }))(Optimus_Common_Utils_Default.defaultString)(Optimus_Common_Utils_Default.defaultsRLNil)()())()())()())()())))(Optimus_Modules_Tmp_Client_Components_Settings_ShowDefaultVal.showDefaultRecord(Optimus_Modules_Tmp_Client_Components_Settings_ShowDefaultVal.showDefaultValsRecord()(Optimus_Modules_Tmp_Client_Components_Settings_ShowDefaultVal.showDefaultValsRLCons(new Data_Symbol.IsSymbol(function () {
          return "backgroundColor";
        }))(Optimus_Modules_Tmp_Client_Components_Settings_ShowDefaultVal.showDefaultValsRLCons(new Data_Symbol.IsSymbol(function () {
          return "backgroundUrl";
        }))(Optimus_Modules_Tmp_Client_Components_Settings_ShowDefaultVal.showDefaultValsRLCons(new Data_Symbol.IsSymbol(function () {
          return "foregroundColor";
        }))(Optimus_Modules_Tmp_Client_Components_Settings_ShowDefaultVal.showDefaultValsRLCons(new Data_Symbol.IsSymbol(function () {
          return "logoUrl";
        }))(Optimus_Modules_Tmp_Client_Components_Settings_ShowDefaultVal.showDefaultValsRLNil)()(Optimus_Modules_Tmp_Client_Components_Settings_ShowDefaultVal.showDefaultValString))()(Optimus_Modules_Tmp_Client_Components_Settings_ShowDefaultVal.showDefaultValString))()(Optimus_Modules_Tmp_Client_Components_Settings_ShowDefaultVal.showDefaultValString))()(Optimus_Modules_Tmp_Client_Components_Settings_ShowDefaultVal.showDefaultValString)))))()(Optimus_Modules_Tmp_Client_Components_Settings_SettingInput.toSettingInputsRLConsSFS(new Data_Symbol.IsSymbol(function () {
          return "qrScanCooldown";
        }))(Optimus_Modules_Tmp_Client_Components_Settings_SettingInput["toSettingInputSettingFormState'"](Optimus_Modules_Tmp_Client_Components_Settings_SettingInput.toSettingInputMillis)(Optimus_Modules_Tmp_Client_Components_Settings_ShowDefaultVal.showDefaultValMillis))()(Optimus_Modules_Tmp_Client_Components_Settings_SettingInput.toSettingInputsRLConsSFS(new Data_Symbol.IsSymbol(function () {
          return "qrScanCountLimit";
        }))(Optimus_Modules_Tmp_Client_Components_Settings_SettingInput["toSettingInputSettingFormState'"](Optimus_Modules_Tmp_Client_Components_Settings_SettingInput.toSettingInputInt)(Optimus_Modules_Tmp_Client_Components_Settings_ShowDefaultVal.showDefaultInt))()(Optimus_Modules_Tmp_Client_Components_Settings_SettingInput.toSettingInputsRLConsSFS(new Data_Symbol.IsSymbol(function () {
          return "qrValidityDuration";
        }))(Optimus_Modules_Tmp_Client_Components_Settings_SettingInput["toSettingInputSettingFormState'"](Optimus_Modules_Tmp_Client_Components_Settings_SettingInput.toSettingInputMillis)(Optimus_Modules_Tmp_Client_Components_Settings_ShowDefaultVal.showDefaultValMillis))()(Optimus_Modules_Tmp_Client_Components_Settings_SettingInput.toSettingInputsRLConsSFS(new Data_Symbol.IsSymbol(function () {
          return "scalingMethod";
        }))(Optimus_Modules_Tmp_Client_Components_Settings_SettingInput["toSettingInputSettingFormState'"](Optimus_Modules_Tmp_Client_Components_Settings_SettingInput.toSettingInputRecord(Optimus_Modules_Tmp_Client_Components_Settings_SettingInput.toSettingInputsRecord()(Optimus_Modules_Tmp_Client_Components_Settings_SettingInput.toSettingInputsRLConsSFS(new Data_Symbol.IsSymbol(function () {
          return "cashbackDecimal";
        }))(Optimus_Modules_Tmp_Client_Components_Settings_SettingInput.toSettingInputInt)()(Optimus_Modules_Tmp_Client_Components_Settings_SettingInput.toSettingInputsRLConsSFS(new Data_Symbol.IsSymbol(function () {
          return "roundingMode";
        }))(Optimus_Modules_Tmp_Client_Components_Settings_SettingInput.toSettingInputRoundingMode)()(Optimus_Modules_Tmp_Client_Components_Settings_SettingInput.toSettingInputsNil))))(Optimus_Common_Utils_Default.defaultsRecord()(Optimus_Common_Utils_Default.defaultsRLCons(new Data_Symbol.IsSymbol(function () {
          return "cashbackDecimal";
        }))(Optimus_Common_Utils_Default.defaultInt)(Optimus_Common_Utils_Default.defaultsRLCons(new Data_Symbol.IsSymbol(function () {
          return "roundingMode";
        }))(Optimus_Common_Utils_Default.defaultRoundingMode)(Optimus_Common_Utils_Default.defaultsRLNil)()())()())))(Optimus_Modules_Tmp_Client_Components_Settings_ShowDefaultVal.showDefaultRecord(Optimus_Modules_Tmp_Client_Components_Settings_ShowDefaultVal.showDefaultValsRecord()(Optimus_Modules_Tmp_Client_Components_Settings_ShowDefaultVal.showDefaultValsRLCons(new Data_Symbol.IsSymbol(function () {
          return "cashbackDecimal";
        }))(Optimus_Modules_Tmp_Client_Components_Settings_ShowDefaultVal.showDefaultValsRLCons(new Data_Symbol.IsSymbol(function () {
          return "roundingMode";
        }))(Optimus_Modules_Tmp_Client_Components_Settings_ShowDefaultVal.showDefaultValsRLNil)()(Optimus_Modules_Tmp_Client_Components_Settings_ShowDefaultVal.showDefaultRoundingMode))()(Optimus_Modules_Tmp_Client_Components_Settings_ShowDefaultVal.showDefaultInt)))))()(Optimus_Modules_Tmp_Client_Components_Settings_SettingInput.toSettingInputsRLConsSFS(new Data_Symbol.IsSymbol(function () {
          return "senderEmail";
        }))(Optimus_Modules_Tmp_Client_Components_Settings_SettingInput["toSettingInputSettingFormState'"](Optimus_Modules_Tmp_Client_Components_Settings_SettingInput.toSettingInputString)(Optimus_Modules_Tmp_Client_Components_Settings_ShowDefaultVal.showDefaultValString))()(Optimus_Modules_Tmp_Client_Components_Settings_SettingInput.toSettingInputsRLConsSFS(new Data_Symbol.IsSymbol(function () {
          return "timeZone";
        }))(Optimus_Modules_Tmp_Client_Components_Settings_SettingInput["toSettingInputSettingFormState'"](Optimus_Modules_Tmp_Client_Components_Settings_SettingInput.toSettingInputString)(Optimus_Modules_Tmp_Client_Components_Settings_ShowDefaultVal.showDefaultValString))()(Optimus_Modules_Tmp_Client_Components_Settings_SettingInput.toSettingInputsRLConsSFS(new Data_Symbol.IsSymbol(function () {
          return "transactionAmountComputationMode";
        }))(Optimus_Modules_Tmp_Client_Components_Settings_SettingInput["toSettingInputSettingFormState'"](Optimus_Modules_Tmp_Client_Components_Settings_SettingInput.toSettingInputTransactionAmountComputationMode)(Optimus_Modules_Tmp_Client_Components_Settings_ShowDefaultVal.showDefaultValTransactionAmountComputationMode))()(Optimus_Modules_Tmp_Client_Components_Settings_SettingInput.toSettingInputsRLConsSFS(new Data_Symbol.IsSymbol(function () {
          return "transactionCurrency";
        }))(Optimus_Modules_Tmp_Client_Components_Settings_SettingInput["toSettingInputSettingFormState'"](Optimus_Modules_Tmp_Client_Components_Settings_SettingInput.toSettingInputInt)(Optimus_Modules_Tmp_Client_Components_Settings_ShowDefaultVal.showDefaultInt))()(Optimus_Modules_Tmp_Client_Components_Settings_SettingInput.toSettingInputsRLConsSFS(new Data_Symbol.IsSymbol(function () {
          return "voidAuthenticationMode";
        }))(Optimus_Modules_Tmp_Client_Components_Settings_SettingInput["toSettingInputSettingFormState'"](Optimus_Modules_Tmp_Client_Components_Settings_SettingInput.toSettingInputAuthorizationMode)(Optimus_Modules_Tmp_Client_Components_Settings_ShowDefaultVal.showDefaultValAM))()(Optimus_Modules_Tmp_Client_Components_Settings_SettingInput.toSettingInputsNil))))))))))))))))))))))))))))))(function (v) {
          return self.setState(function (s) {
            return {
              formData: v
            };
          });
        })(self.state.formData)), React_Basic_DOM_Generated.div()({
          className: "p-3",
          children: [Optimus_Common_Client_Antd_Button.button()({
            type: "primary",
            block: true,
            children: [React_Basic_DOM.text("Submit")],
            onClick: React_Basic_DOM_Events.capture_(self.props.onSubmit(Optimus_Modules_Tmp_Client_Components_Settings_Utils.settingSFSToSU(self.state.formData)))
          })]
        })]
      });
    };

    return React_Basic.make()(component)({
      initialState: {
        formData: Optimus_Modules_Tmp_Client_Components_Settings_Utils.settingSLToSFS(props.settingsLoad)
      },
      render: render
    })(props);
  };

  exports["settingsForm"] = settingsForm;
})(PS["Optimus.Modules.Tmp.Client.Components.Settings.Form"] = PS["Optimus.Modules.Tmp.Client.Components.Settings.Form"] || {});

(function (exports) {
  // Generated by purs version 0.12.3
  "use strict";

  var Control_Bind = PS["Control.Bind"];
  var Data_Either = PS["Data.Either"];
  var Data_Function = PS["Data.Function"];
  var Data_Maybe = PS["Data.Maybe"];
  var Data_Semigroup = PS["Data.Semigroup"];
  var Data_Unit = PS["Data.Unit"];
  var Effect = PS["Effect"];
  var Effect_Aff = PS["Effect.Aff"];
  var Effect_Class = PS["Effect.Class"];
  var Effect_Class_Console = PS["Effect.Class.Console"];
  var Effect_Exception = PS["Effect.Exception"];
  var Optimus_Client_Component_Content = PS["Optimus.Client.Component.Content"];
  var Optimus_Common_Bridge = PS["Optimus.Common.Bridge"];
  var Optimus_Common_Client_Pages_AsyncPage = PS["Optimus.Common.Client.Pages.AsyncPage"];
  var Optimus_Modules_CustomerSupport_Client_Route = PS["Optimus.Modules.CustomerSupport.Client.Route"];
  var Optimus_Modules_Tmp_Client_ApiClient_Settings = PS["Optimus.Modules.Tmp.Client.ApiClient.Settings"];
  var Optimus_Modules_Tmp_Client_Components_Settings_Form = PS["Optimus.Modules.Tmp.Client.Components.Settings.Form"];
  var Optimus_Modules_Tmp_Shared_Settings = PS["Optimus.Modules.Tmp.Shared.Settings"];
  var Prelude = PS["Prelude"];
  var React_Basic = PS["React.Basic"];
  var Web_HTML = PS["Web.HTML"];
  var Web_HTML_Window = PS["Web.HTML.Window"];
  var ZRPC_Clients_Mercanti = PS["ZRPC.Clients.Mercanti"];
  var settingsComp = React_Basic.createComponent("SettingsPage");

  var settingsPage = function settingsPage(updateSettings) {
    var render = function render(v) {
      var _onSubmit = function onSubmit(payload) {
        var handleRes = function handleRes(v1) {
          if (v1 instanceof Data_Either.Right && v1.value0 instanceof Data_Either.Right) {
            return Control_Bind.bind(Effect.bindEffect)(Web_HTML.window)(Web_HTML_Window.alert("Update Settings Success"));
          }

          ;
          return function __do() {
            Effect_Class_Console.logShow(Effect_Class.monadEffectEffect)(Data_Either.showEither(Effect_Exception.showError)(Data_Either.showEither(Optimus_Common_Bridge.showResErr)(Data_Either.showEither(Optimus_Modules_Tmp_Shared_Settings.showSettingsErr)(Data_Unit.showUnit))))(v1)();
            return Control_Bind.bind(Effect.bindEffect)(Web_HTML.window)(Web_HTML_Window.alert("Update Settings Failed"))();
          };
        };

        return Effect_Aff.runAff_(handleRes)(updateSettings(payload));
      };

      return Optimus_Client_Component_Content.content({
        title: "Settings: " + Data_Maybe.fromMaybe("(No Name)")(v.pgData.name),
        children: [Optimus_Modules_Tmp_Client_Components_Settings_Form.settingsForm({
          settingsLoad: v.pgData.settings,
          onSubmit: function onSubmit(settingsUpdate) {
            return _onSubmit({
              settings: settingsUpdate
            });
          }
        })]
      });
    };

    return React_Basic.makeStateless(settingsComp)(render);
  };

  var mkSettingsPage = function mkSettingsPage(getSettings) {
    return function (updateSettings) {
      return function (settingsId) {
        return function (history) {
          return Optimus_Common_Client_Pages_AsyncPage.asyncPage({
            fetchData: getSettings(settingsId),
            pageView: settingsPage(updateSettings(settingsId)),
            history: history
          });
        };
      };
    };
  };

  var merchantSettingsPage = mkSettingsPage(Optimus_Modules_Tmp_Client_ApiClient_Settings.getMerchantSettings)(Optimus_Modules_Tmp_Client_ApiClient_Settings.updateMerchantSettings);
  var holdingSettingsPage = mkSettingsPage(Optimus_Modules_Tmp_Client_ApiClient_Settings.getHoldingSettings)(Optimus_Modules_Tmp_Client_ApiClient_Settings.updateHoldingSettings);
  var branchSettingsPage = mkSettingsPage(Optimus_Modules_Tmp_Client_ApiClient_Settings.getBranchSettings)(Optimus_Modules_Tmp_Client_ApiClient_Settings.updateBranchSettings);
  exports["holdingSettingsPage"] = holdingSettingsPage;
  exports["merchantSettingsPage"] = merchantSettingsPage;
  exports["branchSettingsPage"] = branchSettingsPage;
})(PS["Optimus.Modules.Tmp.Client.Pages.Settings"] = PS["Optimus.Modules.Tmp.Client.Pages.Settings"] || {});

(function (exports) {
  // Generated by purs version 0.12.3
  "use strict";

  var Control_Applicative = PS["Control.Applicative"];
  var Control_Bind = PS["Control.Bind"];
  var Data_Function = PS["Data.Function"];
  var Data_Maybe = PS["Data.Maybe"];
  var Data_Unit = PS["Data.Unit"];
  var Effect = PS["Effect"];
  var Optimus_Common_Client_Components_Layout = PS["Optimus.Common.Client.Components.Layout"];
  var Optimus_Common_Client_Pages_AsyncPage = PS["Optimus.Common.Client.Pages.AsyncPage"];
  var Optimus_Common_Client_Pages_Callback = PS["Optimus.Common.Client.Pages.Callback"];
  var Optimus_Common_Client_Pages_Index = PS["Optimus.Common.Client.Pages.Index"];
  var Optimus_Common_Client_Pages_Login = PS["Optimus.Common.Client.Pages.Login"];
  var Optimus_Modules_CustomerSupport_Client_ApiClient_Users = PS["Optimus.Modules.CustomerSupport.Client.ApiClient.Users"];
  var Optimus_Modules_CustomerSupport_Client_Pages_ChangeEmail = PS["Optimus.Modules.CustomerSupport.Client.Pages.ChangeEmail"];
  var Optimus_Modules_CustomerSupport_Client_Pages_Overview = PS["Optimus.Modules.CustomerSupport.Client.Pages.Overview"];
  var Optimus_Modules_CustomerSupport_Client_Pages_Search = PS["Optimus.Modules.CustomerSupport.Client.Pages.Search"];
  var Optimus_Modules_CustomerSupport_Client_Pages_Tags = PS["Optimus.Modules.CustomerSupport.Client.Pages.Tags"];
  var Optimus_Modules_CustomerSupport_Client_Route = PS["Optimus.Modules.CustomerSupport.Client.Route"];
  var Optimus_Modules_Tmp_Client_Pages_Settings = PS["Optimus.Modules.Tmp.Client.Pages.Settings"];
  var Prelude = PS["Prelude"];
  var React_Basic = PS["React.Basic"];
  var React_Basic_DOM = PS["React.Basic.DOM"];

  var render = function render(self) {
    var handleUsersRoute = function handleUsersRoute(u) {
      if (u instanceof Optimus_Modules_CustomerSupport_Client_Route.UsersSearch) {
        return Optimus_Modules_CustomerSupport_Client_Pages_Search.searchPage({
          query: u.value0.query,
          history: self.props.history
        });
      }

      ;

      if (u instanceof Optimus_Modules_CustomerSupport_Client_Route.UsersOverview) {
        return React_Basic.keyed("UsersOverview")(Optimus_Common_Client_Pages_AsyncPage.asyncPage({
          fetchData: Optimus_Modules_CustomerSupport_Client_ApiClient_Users.getUserById(u.value0),
          pageView: Optimus_Modules_CustomerSupport_Client_Pages_Overview.overviewPage,
          history: self.props.history
        }));
      }

      ;

      if (u instanceof Optimus_Modules_CustomerSupport_Client_Route.UsersChangeEmail) {
        return Optimus_Modules_CustomerSupport_Client_Pages_ChangeEmail.changeEmailPage({
          zapperId: u.value0
        });
      }

      ;

      if (u instanceof Optimus_Modules_CustomerSupport_Client_Route.UsersTags) {
        return React_Basic.keyed("UserTags")(Optimus_Common_Client_Pages_AsyncPage.asyncPage({
          fetchData: Optimus_Modules_CustomerSupport_Client_ApiClient_Users.getUserTags(u.value0),
          pageView: Optimus_Modules_CustomerSupport_Client_Pages_Tags.tagsPage,
          history: self.props.history
        }));
      }

      ;
      throw new Error("Failed pattern match at Optimus.Modules.CustomerSupport.Client.App (line 72, column 26 - line 86, column 12): " + [u.constructor.name]);
    };

    var handleSettingsRoute = function handleSettingsRoute(s) {
      if (s instanceof Optimus_Modules_CustomerSupport_Client_Route.HoldingSettings) {
        return Optimus_Modules_Tmp_Client_Pages_Settings.holdingSettingsPage(s.value0)(self.props.history);
      }

      ;

      if (s instanceof Optimus_Modules_CustomerSupport_Client_Route.MerchantSettings) {
        return Optimus_Modules_Tmp_Client_Pages_Settings.merchantSettingsPage(s.value0)(self.props.history);
      }

      ;

      if (s instanceof Optimus_Modules_CustomerSupport_Client_Route.BranchSettings) {
        return Optimus_Modules_Tmp_Client_Pages_Settings.branchSettingsPage(s.value0)(self.props.history);
      }

      ;
      throw new Error("Failed pattern match at Optimus.Modules.CustomerSupport.Client.App (line 88, column 29 - line 91, column 77): " + [s.constructor.name]);
    };

    var handleModuleRoute = function handleModuleRoute(m) {
      if (m instanceof Optimus_Modules_CustomerSupport_Client_Route.Users) {
        return handleUsersRoute(m.value0);
      }

      ;

      if (m instanceof Optimus_Modules_CustomerSupport_Client_Route.Settings) {
        return handleSettingsRoute(m.value0);
      }

      ;
      throw new Error("Failed pattern match at Optimus.Modules.CustomerSupport.Client.App (line 68, column 27 - line 70, column 42): " + [m.constructor.name]);
    };

    if (self.state.route instanceof Data_Maybe.Nothing) {
      return React_Basic_DOM.text("This page does not exist.");
    }

    ;

    if (self.state.route instanceof Data_Maybe.Just) {
      if (self.state.route.value0 instanceof Optimus_Modules_CustomerSupport_Client_Route.Index) {
        return Optimus_Common_Client_Pages_Index.indexPage({
          history: self.props.history
        });
      }

      ;

      if (self.state.route.value0 instanceof Optimus_Modules_CustomerSupport_Client_Route.Login) {
        return Optimus_Common_Client_Pages_Login.loginPage({});
      }

      ;

      if (self.state.route.value0 instanceof Optimus_Modules_CustomerSupport_Client_Route.Callback) {
        return Optimus_Common_Client_Pages_Callback.callbackPage({
          history: self.props.history
        });
      }

      ;

      if (self.state.route.value0 instanceof Optimus_Modules_CustomerSupport_Client_Route.MRoute) {
        return Optimus_Common_Client_Components_Layout.layout({
          route: self.state.route.value0,
          history: self.props.history,
          content: handleModuleRoute(self.state.route.value0.value0)
        });
      }

      ;
      throw new Error("Failed pattern match at Optimus.Modules.CustomerSupport.Client.App (line 57, column 17 - line 66, column 10): " + [self.state.route.value0.constructor.name]);
    }

    ;
    throw new Error("Failed pattern match at Optimus.Modules.CustomerSupport.Client.App (line 55, column 15 - line 66, column 10): " + [self.state.route.constructor.name]);
  };

  var initialState = {
    route: Data_Maybe.Nothing.value
  };

  var didMount = function didMount(self) {
    var setRoute = function setRoute(v) {
      return function (r) {
        return self.setState(function (v1) {
          return {
            route: new Data_Maybe.Just(r)
          };
        });
      };
    };

    return function __do() {
      var v = self.props.history.listener(setRoute)();
      return Data_Unit.unit;
    };
  };

  var component = React_Basic.createComponent("App");
  var app = React_Basic.make()(component)({
    initialState: initialState,
    didMount: didMount,
    render: render
  });
  exports["app"] = app;
})(PS["Optimus.Modules.CustomerSupport.Client.App"] = PS["Optimus.Modules.CustomerSupport.Client.App"] || {});

(function (exports) {
  // Generated by purs version 0.12.3
  "use strict";

  var Control_Bind = PS["Control.Bind"];
  var Data_Maybe = PS["Data.Maybe"];
  var Effect = PS["Effect"];
  var Effect_Console = PS["Effect.Console"];
  var Effect_Exception = PS["Effect.Exception"];
  var Optimus_Modules_CustomerSupport_Client_App = PS["Optimus.Modules.CustomerSupport.Client.App"];
  var Optimus_Modules_CustomerSupport_Client_Route = PS["Optimus.Modules.CustomerSupport.Client.Route"];
  var Prelude = PS["Prelude"];
  var React_Basic_DOM = PS["React.Basic.DOM"];
  var Routing_PushState = PS["Routing.PushState"];
  var Web_DOM_NonElementParentNode = PS["Web.DOM.NonElementParentNode"];
  var Web_HTML = PS["Web.HTML"];
  var Web_HTML_HTMLDocument = PS["Web.HTML.HTMLDocument"];
  var Web_HTML_Window = PS["Web.HTML.Window"];

  var main = function __do() {
    var v = Web_HTML.window();
    var v1 = Web_HTML_Window.document(v)();
    var v2 = Web_DOM_NonElementParentNode.getElementById("root")(Web_HTML_HTMLDocument.toNonElementParentNode(v1))();
    var v3 = Routing_PushState.makeInterface();

    (function () {
      if (v2 instanceof Data_Maybe.Nothing) {
        return Effect_Exception["throw"]("Container element not found")();
      }

      ;

      if (v2 instanceof Data_Maybe.Just) {
        return React_Basic_DOM.render(Optimus_Modules_CustomerSupport_Client_App.app({
          history: Optimus_Modules_CustomerSupport_Client_Route.navToHistory(v3)
        }))(v2.value0)();
      }

      ;
      throw new Error("Failed pattern match at Optimus.Modules.CustomerSupport.Client.Main (line 24, column 3 - line 26, column 59): " + [v2.constructor.name]);
    })();

    return Effect_Console.log("Optimus Started!")();
  };

  exports["main"] = main;
})(PS["Optimus.Modules.CustomerSupport.Client.Main"] = PS["Optimus.Modules.CustomerSupport.Client.Main"] || {});

module.exports = PS;